import React, { useEffect, useState } from "react";
import "./ComponentWH.css";
import Select from "react-select";
import axios from "axios";
import { getOem, getToken, getUser } from "../../../../Utils/Common";
import Configs from "../../../../config";
import Swal from "sweetalert2";
import * as THREE from "three";

function HeaderChangeWH({
  position,
  widthPage,
  sizeRoom,
  setSizeRoom,
  warehouseData,
  setwarehouseData,
  actionActive,
  setActionActive,
  setrackData,
  rackData,
  switchBtn,
  setswitchBtn,
  functionComponent,
  setFunctionComponent,
  actionListRack,
  setActionListRack,
  dataOfWarehouse,
  setDataOfWarehouse,
  myStateRef,
  socketConnect,
  requestGenToken,
  SetPOSModel,
  xRef,
  zRef,
  lookAtRef,
  modelRef,
  viewOnly,
  whIdRef,
  positionRef,
  modelForkliftRef,
  NOT_USE_NOW,
  modelType
}) {
  const [warehouseDD, setWarehouseDD] = useState({
    data: [],
    select: null,
    all: [],
    supply_select: null,
  });
  const [systemConfig, setSystemConfig] = useState({
    data: {},
  });

  function handleStateWarehouseData(key, value, is_wh) {
    if (is_wh) {
      setwarehouseData({
        ...warehouseData,
        [key]: value,
      });
      warehouseData[key] = value;
    } else {
      setrackData({
        ...rackData,
        [key]: value,
      });
      rackData[key] = value;
    }
  }

  async function GetSystemConfig() {
    // systemConfig.data?.sc_name
    await axios({
      method: "get",
      url:
        Configs.API_URL_AIOI_Back + "/api/product_unit/GetSystemConfigDisplay",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const data = response.data.data;
        setSystemConfig({
          data: data,
        });
        systemConfig.data = data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  const IsLoadding = async () => {
    await Swal.fire({
      title: "System",
      text: `Loading...`,
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
  };

  async function GetWarehouseAPI(wh_id) {
    IsLoadding();
    let tempSearch = {
      warehouse_id: wh_id,
      company_id: getUser().com,
      oem_id: getOem(),
    };
    await axios
      .request({
        method: "POST",
        url: Configs.API_URL_AIOI_Back + "/api/warehouse/getWarehouse3DByID",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: tempSearch,
      })
      .then(async (response) => {
        
        // connect socket unai
        if (response.data.data.token_unai.status === 200) {
          await requestGenToken(response.data.data.token_unai);
          await socketConnect();
        }

        // console.log('response.data', response.data.data);
        const dataRes = response.data.data.warehouseData;

        const temp_fkl = {};
        // console.log('response.data.data.forklift_wh', response.data.data.forklift_wh);
        for (let item of response.data.data.forklift_wh) {
          const key_temp_fkl = `${item.fu_no}`;
          temp_fkl[key_temp_fkl] = { ...item };
          if (!temp_fkl.on_name_flk) {
            temp_fkl.on_name_flk = key_temp_fkl
          }
        }
        modelForkliftRef.current = temp_fkl;
        // console.log('modelForkliftRef.current', modelForkliftRef.current);

        if (dataRes.length == 0) {
          Swal.fire("ดึงข้อมูลไม่สำเร็จ", "ไม่พบ Warehouse นี้ในระบบ", "error");
          return;
        }

        await getLogProduct(dataRes[0].warehouse_id);

        handleStateWarehouseData(
          "unit",
          systemConfig.data?.sc_name || "cm.",
          true
        );
        handleStateWarehouseData("id", dataRes[0].warehouse_id, true);
        // handleStateWarehouseData('name', dataRes[0].warehouse_name || "", true);
        handleStateWarehouseData("unit", systemConfig.data?.sc_name || "cm.");

        setSizeRoom({
          width_input: dataRes[0].warehouse_width || 36,
          height_input: dataRes[0].warehouse_height || 24,
          depth_input: dataRes[0].warehouse_depth || 12,
          name_input: dataRes[0].warehouse_name || null,
          width: dataRes[0].warehouse_width || 36,
          height: dataRes[0].warehouse_height || 24,
          depth: dataRes[0].warehouse_depth || 12,
          name: dataRes[0].warehouse_name || null,
        });
        sizeRoom.width_input = dataRes[0].warehouse_width || 36;
        sizeRoom.height_input = dataRes[0].warehouse_height || 24;
        sizeRoom.depth_input = dataRes[0].warehouse_depth || 12;
        sizeRoom.name_input = dataRes[0].warehouse_name || null;
        sizeRoom.width = dataRes[0].warehouse_width || 36;
        sizeRoom.height = dataRes[0].warehouse_height || 24;
        sizeRoom.depth = dataRes[0].warehouse_depth || 12;
        sizeRoom.name = dataRes[0].warehouse_name || null;

        setswitchBtn({
          ...switchBtn,
          mode: "select",
        });
        switchBtn.mode = "select";
        clearRackInput();
        handleStateWarehouseData("rack_list", []);
        // console.log('rackData.rack_list', rackData.rack_list);
        const rack_list = await handleAddRack(dataRes[0], rackData.rack_list);
        myStateRef.current = [...rack_list];
        // console.log('myStateRef.current', myStateRef.current);
        setFunctionComponent({
          ...functionComponent,
          cancelAdd: cancelAdd,
          SaveWarehouse: SaveWarehouse,
        });
        functionComponent.cancelAdd = cancelAdd;
        functionComponent.SaveWarehouse = SaveWarehouse;
        // console.log('rack_list', rack_list);
        if (!actionActive.btn) {
          setActionActive({
            btn: "camera",
          });
          actionActive.btn = "camera";
        } else {
          // console.log('functionComponent.generateRoom', sizeRoom);
          if (functionComponent.generateRoom)
            await functionComponent.generateRoom(
              sizeRoom,
              rack_list,
              dataOfWarehouse.log_list,
              wh_id
            );
        }
        /* setTimeout(() => {
          SetPOSModel(sizeRoom);
        }, 500); */
        Swal.close();
        if (response.data.data.token_unai.status !== 200) {
          Swal.fire("ดึงข้อมูลจาก UNAI ไม่สำเร็จ", response.data.data.token_unai.message?.error, "warning");
        }
      })
      .catch((error) => {
        Swal.close();
        console.log("error", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "getWarehouse Error",
        });
      });
  }

  async function getLogProduct(wh_id) {
    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      type_aioi: modelType || null,
      warehouse_id: wh_id
    };

    await axios({
      method: "POST",
      url: `${Configs.API_URL}/api/logProduct/getStockProductInWarehouseUNAI`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        setDataOfWarehouse({
          ...dataOfWarehouse,
          log_list: [...response_data.data],
        });
        dataOfWarehouse.log_list = [...response_data.data];
        // console.log('dataLog', dataOfWarehouse.log_list);
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function handleAddRack(wh_data, rack_list) {
    // console.log('wh_data.rack', wh_data.rack);
    const arrTemp = [];
    const rackDataTempList = wh_data.rack;

    const obj_rack = {};
    let unit = rackData.unit == "mm." ? 0.01 : rackData.unit == "m." ? 1 : 0.1;
    for (let index = 0; index < rackDataTempList.length; index++) {
      const rackDataTemp = rackDataTempList[index];

      obj_rack[rackDataTemp.rack_id] = { actionList: [] };
      // if (index) continue;
      // console.log('rackDataTemp.rack_position', rackDataTemp.rack_position);
      arrTemp.push({
        rack_id: rackDataTemp.rack_id,
        rack_name: rackDataTemp.rack_name,
        width_rack: Number(rackDataTemp.rack_width || 1)/*  * unit */,
        height_rack: Number(rackDataTemp.rack_height || 1)/*  * unit */,
        depth_rack: Number(rackDataTemp.rack_depth || 1)/*  * unit */,

        row_rack: Number(rackDataTemp.rack_axis_x || 1), // axis x
        rack_axis_x: Number(rackDataTemp.rack_axis_x || 1),
        rack_position_format_x: Number(
          rackDataTemp.rack_position_format_x || 1
        ),
        rack_separate_char_x: rackDataTemp.rack_separate_char_x || "",

        col_rack: Number(rackDataTemp.rack_axis_y || 1), // axis y
        rack_axis_y: Number(rackDataTemp.rack_axis_y || 1),
        rack_position_format_y: Number(
          rackDataTemp.rack_position_format_y || 1
        ),
        rack_separate_char_y: rackDataTemp.rack_separate_char_y || "",

        use_rack_axis_z: rackDataTemp.rack_is_axis_z,
        split_rack_z: Number(rackDataTemp.rack_axis_z || 1), // axis z
        rack_axis_z: Number(rackDataTemp.rack_axis_z || 1),
        rack_position_format_z: Number(
          rackDataTemp.rack_position_format_z || 1
        ),
        rack_separate_char_z: rackDataTemp.rack_separate_char_z || "",

        position_list: rackDataTemp.tableArray,

        wh_width: Number(sizeRoom.width || 1),
        wh_height: Number(sizeRoom.height || 1),
        wh_depth: Number(sizeRoom.depth || 1),
        config_position: 0,
        position: rackDataTemp.rack_position
          ? rackDataTemp.rack_position
          : null,
        rotation: rackDataTemp.rack_rotation
          ? rackDataTemp.rack_rotation
          : null,
      });
    }
    // console.log('arrTemp', arrTemp);
    setrackData({
      ...rackData,
      rack_list: arrTemp,
    });
    rackData.rack_list = arrTemp;
    setActionListRack({ ...actionListRack, data: obj_rack });
    actionListRack.data = obj_rack;
    /* console.log('rackData', rackData);
    console.log('arrTemp', arrTemp); */
    return arrTemp;
  }

  function clearWarehousePrepareAdd() {
    if (switchBtn.mode == "add") return;

    const select_data = warehouseDD.select;
    console.log('select_data', select_data);
    setWarehouseDD({
      ...warehouseDD,
      supply_select: select_data,
      select: null,
    });
    warehouseDD.supply_select = select_data;
    warehouseDD.select = null;

    setSizeRoom({
      ...sizeRoom,
      width_input: "",
      height_input: "",
      depth_input: "",
      name_input: "",
      width: 0,
      height: 0,
      depth: 0,
      name: 0,
    });
    sizeRoom.width_input = "";
    sizeRoom.height_input = "";
    sizeRoom.depth_input = "";
    sizeRoom.name_input = "";
    sizeRoom.width = 0;
    sizeRoom.height = 0;
    sizeRoom.depth = 0;
    sizeRoom.name = 0;

    setrackData({
      ...rackData,
      rack_list: [],
    });
    rackData.rack_list = [];

    if (functionComponent.clearRoom) functionComponent.clearRoom();

    setFunctionComponent({
      ...functionComponent,
      cancelAdd: cancelAdd,
      SaveWarehouse: SaveWarehouse,
    });
    functionComponent.cancelAdd = cancelAdd;
    functionComponent.SaveWarehouse = SaveWarehouse;
  }

  function CheckErrorForSave(sizeRoom) {
    const error_list = [];
    const _size = sizeRoom;

    if (!_size.name_input?.toString().trim()) {
      error_list.push("กรุณากรอก Warehouse Name");
    }

    if (!_size.width_input?.toString().trim()) {
      error_list.push("กรุณากรอก Width");
    }

    if (!_size.depth_input?.toString().trim()) {
      error_list.push("กรุณากรอก Depth");
    }

    if (!_size.height_input?.toString().trim()) {
      error_list.push("กรุณากรอก Height");
    }

    return error_list;
  }

  async function SaveWarehouse(sizeRoom, mode) {
    Swal.fire({
      title: `ต้องการที่จะ${
        mode == "edit" ? "แก้ไข" : "บันทึก"
      }ข้อมูล ใช่หรือไม่`,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (
          Object.values(actionListRack.data).some(
            (item) => item.actionList.length > 0
          )
        ) {
          const error_list = await SaveRackAll(
            `ข้อมูล Rack มีการเปลี่ยนแปลง ต้องการบันทึกก่อนใช่หรือไม่ ?`
          );
          if (error_list && error_list.length > 0) {
            Swal.fire(
              `แก้ไข Rack ทั้งหมดไม่สำเร็จ`,
              error_list.join("<br />"),
              "error"
            );
            return;
          } else if (error_list && error_list.length == 0) {
            await ApiSaveAllRackByWHId(
              rackData.rack_list,
              warehouseData.id,
              true,
              true
            );
          }
        }

        const error_list = CheckErrorForSave(sizeRoom);
        if (error_list.length > 0) {
          Swal.fire(
            "บันทึกข้อมูลไม่สำเร็จ",
            error_list.join("<br />"),
            "error"
          );
          return;
        }

        await ApiSaveWarehouse(sizeRoom, mode);
      }
    });
  }

  async function ApiSaveWarehouse(sizeRoom, mode) {
    const data = {
      wh_id: mode === "edit" ? warehouseDD.select.value : undefined,
      sizeRoom,
      oem_id: getOem(),
      user: getUser().name + " " + getUser().surname,
      fup: getUser().fup,
      company_id: getUser().com,
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_AIOI_Back +
        `/api/warehouse/${
          mode == "edit" ? "editWarehouseOf3D" : "addWarehouseOf3D"
        }`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data,
    })
      .then(async function (response) {
        if (response.data.data && !response.data.data.error) {
          const res_data = response.data.data;
          // Call Api Get Room
          setWarehouseDD({
            ...warehouseDD,
            data: res_data.dropdown,
            select: res_data.select,
            all: res_data.all,
          });
          warehouseDD.data = res_data.dropdown;
          warehouseDD.select = res_data.select;
          warehouseDD.all = res_data.all;
          // **
          Swal.fire({
            icon: "success",
            title: "Save",
            showConfirmButton: false,
            timer: 1500,
          });

          await GetWarehouseAPI(res_data.select?.value);
        } else {
          Swal.fire("Error", response.data.data.error.message, "error");
        }
      })
      .catch(function (error) {
        Swal.fire("Error", error.response.data.message, "error");
      });
  }

  async function getWarehouse() {
    let tempSearch = {
      filter: null,
      company_id: getUser().com,
      oem_id: getOem(),
    };
    await axios
      .request({
        method: "POST",
        url: Configs.API_URL_AIOI_Back + "/api/warehouse/getWarehouse",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: tempSearch,
      })
      .then(async (response) => {
        const resList = response.data.data.map((el) => ({
          value: el.wh_id,
          label: el.wh_name,
        }));
        const select = resList.length > 0 ? resList[0] : null;

        setWarehouseDD({
          ...warehouseDD,
          data: resList,
          select: select,
          all: response.data.data,
        });
        warehouseDD.data = resList;
        warehouseDD.select = select;
        warehouseDD.all = response.data.data;
        await GetWarehouseAPI(whIdRef?.current || select?.value);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "getWarehouse Error",
        });
      });
  }

  async function cancelAdd() {
    // console.log('warehouseDD', warehouseDD)
    const select_data = warehouseDD.supply_select;
    // console.log('select_data.value', select_data);
    setWarehouseDD({
      ...warehouseDD,
      // supply_select: null,
      select: select_data,
    });
    // warehouseDD.supply_select = null;
    warehouseDD.select = select_data;
    if (select_data && select_data.value) {
      await GetWarehouseAPI(select_data.value);
    }
  }

  const filterOptions = (candidate, input) => {
    if (input) {
      return candidate.label
        ?.toString()
        .toLowerCase()
        .includes(input?.toString().toLowerCase());
    }
    return true;
  };

  function handleSwitchBtn(key) {
    setswitchBtn({
      ...switchBtn,
      [key]: true,
      [key == "wh" ? "rack" : "wh"]: false,
      mode: "add",
    });
  }

  async function SaveRackAll(title_swal) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: title_swal,
        icon: "info",
        showCancelButton: true,
        confirmButtonText: `ใช่`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `ไม่`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let error_list = [];
          const extendFloor = new THREE.Mesh(
            new THREE.BoxGeometry(
              sizeRoom.width,
              sizeRoom.height,
              sizeRoom.depth
            ),
            new THREE.MeshStandardMaterial({
              color: 0x57d762,
              transparent: true,
              opacity: 1,
              side: 1,
            })
          );
          // extendFloor.position.set(0, -0.3, 12);
          extendFloor.position.setX(sizeRoom.width / 2);
          extendFloor.position.setY(sizeRoom.height / 2 - 0.2);
          extendFloor.position.setZ(sizeRoom.depth / 2);
          const roomBoundingBox = new THREE.Box3().setFromObject(extendFloor);
          // console.log('rackData.rack_list', rackData.rack_list);
          // for (let index = 0; index < rackData.rack_list.length; index++) {
          //   const element = rackData.rack_list[index];
          // console.log('modelRef.current :>> ', myStateRef.current);
          for (let index = 0; index < myStateRef.current.length; index++) {
            const element = myStateRef.current[index];

            if (!element.model) {
              continue;
            } else {
              element.model.remove(...element.remove_child_group2);
            }

            element.position = {
              x: element.model.position.x,
              y: element.model.position.y,
              z: element.model.position.z,
            };
            element.rotation = {
              x: element.model.rotation.x,
              y: element.model.rotation.y,
              z: element.model.rotation.z,
              isEuler: element.model.rotation.isEuler,
            };

            const furnitureBoundingBox = new THREE.Box3().setFromObject(
              element.model
            );

            if (!roomBoundingBox.containsBox(furnitureBoundingBox)) {
              error_list.push(`Rack ${element.rack_name} overflow warehouse.`);
            } else {
              const _filterRackOther = myStateRef.current.filter(
                (el) => el.rack_id != element.rack_id && el.model
              );

              for (const otherRack of _filterRackOther) {
                const otherBoundingBox = new THREE.Box3().setFromObject(
                  otherRack.model
                );

                if (furnitureBoundingBox.intersectsBox(otherBoundingBox)) {
                  error_list.push(
                    `Rack ${element.rack_name} overlaps with Rack ${otherRack.rack_name}.`
                  );
                }
              }
            }
          }
          if (error_list.length > 0) {
            resolve(error_list);
          } else {
            resolve([]);
          }
        } else {
          resolve(null);
        }
      });
    });
  }

  async function SaveRackAll_supply(title_swal) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: title_swal,
        icon: "info",
        showCancelButton: true,
        confirmButtonText: `ใช่`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `ไม่`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let error_list = [];
          const extendFloor = new THREE.Mesh(
            new THREE.BoxGeometry(
              sizeRoom.width,
              sizeRoom.height,
              sizeRoom.depth
            ),
            new THREE.MeshStandardMaterial({
              color: 0x57d762,
              transparent: true,
              opacity: 1,
              side: 1,
            })
          );
          // extendFloor.position.set(0, -0.3, 12);
          extendFloor.position.setX(sizeRoom.width / 2);
          extendFloor.position.setY(sizeRoom.height / 2 - 0.2);
          extendFloor.position.setZ(sizeRoom.depth / 2);
          const roomBoundingBox = new THREE.Box3().setFromObject(extendFloor);
          // console.log('rackData.rack_list', rackData.rack_list);
          // for (let index = 0; index < rackData.rack_list.length; index++) {
          //   const element = rackData.rack_list[index];
          for (let index = 0; index < modelRef.current.length; index++) {
            const element = modelRef.current[index];

            if (!element.groupObj) {
              continue;
            }

            element.position = {
              x: element.groupObj.position.x,
              y: element.groupObj.position.y,
              z: element.groupObj.position.z,
            };
            element.rotation = {
              x: element.groupObj.rotation.x,
              y: element.groupObj.rotation.y,
              z: element.groupObj.rotation.z,
              isEuler: element.groupObj.rotation.isEuler,
            };

            const furnitureBoundingBox = new THREE.Box3().setFromObject(
              element.groupObj
            );

            if (!roomBoundingBox.containsBox(furnitureBoundingBox)) {
              error_list.push(`Rack ${element.rack_name} overflow warehouse.`);
            } else {
              const _filterRackOther = rackData.rack_list.filter(
                (el) => el.rack_id != element.rack_id && el.groupObj
              );

              for (const otherRack of _filterRackOther) {
                const otherBoundingBox = new THREE.Box3().setFromObject(
                  otherRack.groupObj
                );

                if (furnitureBoundingBox.intersectsBox(otherBoundingBox)) {
                  error_list.push(
                    `Rack ${element.rack_name} overlaps with Rack ${otherRack.rack_name}.`
                  );
                }
              }
            }
          }
          if (error_list.length > 0) {
            resolve(error_list);
          } else {
            resolve([]);
          }
        } else {
          resolve(null);
        }
      });
    });
  }

  async function ApiSaveAllRackByWHId(rack_list, wh_id, is_call_wh, is_swal) {
    // console.log('rack_list', rack_list)
    const data = {
      rack_list: rack_list,
      wh_id,
      // position_list: positionRack.data,
      oem_id: getOem(),
      user: getUser().name + " " + getUser().surname,
      fup: getUser().fup,
      company_id: getUser().com,
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_AIOI_Back + `/api/warehouse/editRackAllOfWarehouse3D`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then(async function (response) {
        if (response.data.data && !response.data.data.error) {
          if (!is_swal) {
            Swal.fire({
              icon: "success",
              title: "Edit",
              showConfirmButton: false,
              timer: 1500,
            });
          }
          clearRackInput();
          if (!is_call_wh) {
            GetWarehouseAPI(wh_id);
          }
        } else {
          Swal.fire("Error", response.data.data.error.message, "error");
        }
      })
      .catch(function (error) {
        console.log("error", error);
        Swal.fire("Error", error.response.data.message, "error");
      });
  }

  function clearRackInput(isCancel) {
    setrackData({
      ...rackData,
      rack_name_input: isCancel ? rackData.rack_name : "",
      width_input: isCancel ? rackData.width : 0,
      depth_input: isCancel ? rackData.depth : 0,
      height_input: isCancel ? rackData.height : 0,
      axis_x_input: isCancel ? rackData.axis_x : 0,
      axis_x_pos_input: isCancel ? rackData.axis_x_pos : 1,
      axis_x_chr_input: isCancel ? rackData.axis_x_chr : "",
      axis_y_input: isCancel ? rackData.axis_y : 0,
      axis_y_pos_input: isCancel ? rackData.axis_y_pos : 1,
      axis_y_chr_input: isCancel ? rackData.axis_y_chr : "",
      axis_z_input: isCancel ? rackData.axis_z : 0,
      axis_z_pos_input: isCancel ? rackData.axis_z_pos : 1,
      axis_z_chr_input: isCancel ? rackData.axis_z_chr : "",
    });
    rackData.rack_name_input = isCancel ? rackData.rack_name : "";
    rackData.width_input = isCancel ? rackData.width : 0;
    rackData.depth_input = isCancel ? rackData.depth : 0;
    rackData.height_input = isCancel ? rackData.height : 0;
    rackData.axis_x_input = isCancel ? rackData.axis_x : 0;
    rackData.axis_x_pos_input = isCancel ? rackData.axis_x_pos : 1;
    rackData.axis_x_chr_input = isCancel ? rackData.axis_x_chr : "";
    rackData.axis_y_input = isCancel ? rackData.axis_y : 0;
    rackData.axis_y_pos_input = isCancel ? rackData.axis_y_pos : 1;
    rackData.axis_y_chr_input = isCancel ? rackData.axis_y_chr : "";
    rackData.axis_z_input = isCancel ? rackData.axis_z : 0;
    rackData.axis_z_pos_input = isCancel ? rackData.axis_z_pos : 1;
    rackData.axis_z_chr_input = isCancel ? rackData.axis_z_chr : "";
  }

  function checkForkliftSelectPosition2() {
    let forklift = null;
    modelRef.current?.traverse(function (child) {
      if (child.name == "mesh_6") {
        const box = new THREE.Box3().setFromObject(child);

        const centerY = box.min.y + 1;
        box.expandByVector(new THREE.Vector3(0, -10, 0));
        // console.log('box1', box);
        const box2 = box.clone();
        box2.max.y = centerY;
        box2.min.y = centerY - 1;
        // console.log('box2', box2)
        forklift = box2;
      }
    });
    // console.log('forklift', forklift);
    if (!forklift) return;
    let isFound = false;
    let box_found = [];
    // console.log('myStateRef.current', myStateRef.current);
    for (let index = 0; index < myStateRef.current.length; index++) {
      const element = myStateRef.current[index];
      // console.log('element', element);
      if (!Array.isArray(element.position_list)) {
        continue;
      }
      for (
        let pos_index = 0;
        pos_index < element.position_list.length;
        pos_index++
      ) {
        const elementPOS = element.position_list[pos_index];
        if (!elementPOS.box) continue;

        const furnitureBoundingBox = new THREE.Box3().setFromObject(
          elementPOS.box
        );

        if (furnitureBoundingBox.intersectsBox(forklift)) {
          elementPOS.rack_id = element.rack_id;
          elementPOS.position_id = elementPOS.rp_id;
          box_found.push(elementPOS);
          isFound = true;
        }
      }
    }
    let box_fount_one = null;
    if (box_found.length > 1) {
      const forklift_pos = modelRef.current.position;
      let min_distance = Infinity;
      // console.log('box_found', box_found);
      box_found.forEach((el) => {
        const box_pos = el.box.position;
        const distance = forklift_pos.distanceTo(box_pos);
        // console.log('el', el, box_pos);
        if (distance < min_distance) {
          min_distance = distance;
          box_fount_one = el;
        }
      });
    } else if (box_found.length == 1) {
      box_fount_one = box_found[0];
    }
    if (!isFound) {
      Swal.fire("Not Found!!", "Product", "error");
      return null;
    } else {
      // console.log("box_fount_one", box_fount_one);
      // Swal.fire("Found!!", "Product", "success");
      return box_fount_one;
    }
  }

  function checkForkliftSelectPosition(fkl_name) {
    let forklift = null;
    modelForkliftRef.current[fkl_name]?.model?.traverse(function (child) {
      if (child.name == "mesh_6") {
        const box = new THREE.Box3().setFromObject(child);

        const centerY = box.min.y + 1;
        box.expandByVector(new THREE.Vector3(0, -10, 0));
        // console.log('box1', box);
        const box2 = box.clone();
        box2.max.y = centerY;
        box2.min.y = centerY - 1;
        // console.log('box2', box2)
        forklift = box2;
      }
    });
    // console.log('forklift', forklift);
    if (!forklift) return;
    let isFound = false;
    let box_found = [];
    // console.log('myStateRef.current', myStateRef.current);
    for (let index = 0; index < myStateRef.current.length; index++) {
      const element = myStateRef.current[index];
      // console.log('element', element);
      if (!Array.isArray(element.position_list)) {
        continue;
      }
      for (
        let pos_index = 0;
        pos_index < element.position_list.length;
        pos_index++
      ) {
        const elementPOS = element.position_list[pos_index];
        if (!elementPOS.box) continue;

        const furnitureBoundingBox = new THREE.Box3().setFromObject(
          elementPOS.box
        );

        if (furnitureBoundingBox.intersectsBox(forklift)) {
          elementPOS.rack_id = element.rack_id;
          elementPOS.position_id = elementPOS.rp_id;
          box_found.push(elementPOS);
          isFound = true;
        }
      }
    }
    let box_fount_one = null;
    if (box_found.length > 1) {
      const forklift_pos = modelForkliftRef.current[fkl_name]?.model.position;
      let min_distance = Infinity;
      // console.log('box_found', box_found);
      box_found.forEach((el) => {
        const box_pos = el.box.position;
        const distance = forklift_pos.distanceTo(box_pos);
        // console.log('el', el, box_pos);
        if (distance < min_distance) {
          min_distance = distance;
          box_fount_one = el;
        }
      });
    } else if (box_found.length == 1) {
      box_fount_one = box_found[0];
    }
    if (!isFound) {
      Swal.fire("Not Found!!", "Position rack is not found at on forklift position.", "error");
      return null;
    } else {
      // console.log("box_fount_one", box_fount_one);
      // Swal.fire("Found!!", "Product", "success");
      return box_fount_one;
    }
  }

  useEffect(async () => {
    await GetSystemConfig();
    await getWarehouse();
  }, []);

  useEffect(async () => {
    if (positionRef !== undefined)
      positionRef.current.checkForkliftSelectPosition = checkForkliftSelectPosition;
    setFunctionComponent({
      ...functionComponent,
      GetWarehouseAPI: GetWarehouseAPI,
    });
    functionComponent.GetWarehouseAPI = GetWarehouseAPI;
  }, [warehouseData.id]);

  return (
    <div
      style={{
        position: "static",
        zIndex: "999",
        ...position,
        gap: "5px",
      }}
      hidden={viewOnly}
    >
      <div
        className="hcwh-background pl-3"
        style={{ width: "100%" /* widthPage || 0*/, height: 'fit-content' }}
      >
        <div className="row">
          {/* <div className="col">
            
          </div> */}
          <div
            className="col-12 col-md-10 col-xl-8"
            style={{ gap: "10px" }}
          >
            <div className="row">
              <div className="col-12 col-md-auto col-xl-auto mt-2" style={{ display: 'flex', 'alignItems': 'center' }}>
                <div id="title" className="">
                <div className="monitor_title" style={{ marginLeft: '0px' }}>
                  <div className="title" style={{ marginLeft: '0px' }}>
                    <h1 style={{ margin: '0px' }}>
                      Warehouse <span>({actionActive.btn != "2D" ? "3D" : "2D"})</span> <h4 style={{ display: "inline" }}>({modelType === "aioi" ? "AIOI" : "UNAI"})</h4>
                    </h1>
                  </div>
                </div>
              </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4 mt-2">
              <div
                className="form-group mb-0"
                style={{ minWidth: "250px", maxWidth: "250px" }}
              >
                <Select
                  menuPortalTarget={document.body} 
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  options={switchBtn.mode == "add" ? [] : warehouseDD.data}
                  value={warehouseDD.select}
                  filterOption={filterOptions}
                  onChange={async (e) => {
                    if (warehouseDD.select?.value == e.value) {
                      return;
                    }

                    if (
                      Object.values(actionListRack.data).some(
                        (item) => item.actionList.length > 0
                      )
                    ) {
                      const error_list = await SaveRackAll(
                        `ข้อมูล Rack มีการเปลี่ยนแปลง ต้องการบันทึกก่อนใช่หรือไม่ ?`
                      );
                      if (error_list && error_list.length > 0) {
                        Swal.fire(
                          `แก้ไข Rack ทั้งหมดไม่สำเร็จ`,
                          error_list.join("<br />"),
                          "error"
                        );
                        return;
                      } else if (error_list && error_list.length == 0) {
                        await ApiSaveAllRackByWHId(
                          rackData.rack_list,
                          warehouseData.id,
                          true
                        );
                      }
                    }

                    setActionActive({
                      btn: "camera",
                    });
                    actionActive.btn = "camera";
                    GetWarehouseAPI(e.value);
                    setWarehouseDD({
                      ...warehouseDD,
                      select: e,
                    });
                    warehouseDD.select = e;
                  }}
                />
                <label htmlFor="">Warehouse</label>
              </div>
              </div>

            </div>
            
            
            <button
              hidden={NOT_USE_NOW}
              type="button"
              style={{ height: "40px", minWidth: "100px", maxWidth: "100px" }}
              className=" btn btn-block btn-primary mt-2"
              onClick={async () => {
                if (
                  Object.values(actionListRack.data).some(
                    (item) => item.actionList.length > 0
                  )
                ) {
                  const error_list = await SaveRackAll(
                    `ข้อมูลมีการเปลี่ยนแปลง ต้องการบันทึกก่อนใช่หรือไม่ ?`
                  );
                  if (error_list && error_list.length > 0) {
                    Swal.fire(
                      `แก้ไข Rack ทั้งหมดไม่สำเร็จ`,
                      error_list.join("<br />"),
                      "error"
                    );
                    return;
                  } else if (error_list && error_list.length == 0) {
                    await ApiSaveAllRackByWHId(
                      rackData.rack_list,
                      warehouseData.id,
                      true
                    );
                  }
                }

                clearWarehousePrepareAdd();
                handleSwitchBtn("wh");
              }}
            >
              Add
            </button>
            {Object.values(actionListRack.data).some(
              (item) => item.actionList.length > 0
            ) ? (
              <>
                <button
                  type="button"
                  style={{
                    height: "40px",
                    minWidth: "100px",
                    maxWidth: "100px",
                  }}
                  className=" btn btn-block btn-success mt-2"
                  onClick={async () => {
                    const error_list = await SaveRackAll(
                      `ต้องการที่จะแก้ไข Rack ทั้งหมดใช่หรือไม่`
                    );
                    if (error_list.length > 0) {
                      Swal.fire(
                        `แก้ไข Rack ทั้งหมดไม่สำเร็จ`,
                        error_list.join("<br />"),
                        "error"
                      );
                      return;
                    }
                    await ApiSaveAllRackByWHId(
                      rackData.rack_list,
                      warehouseData.id
                    );
                  }}
                >
                  Save All
                </button>
                <button
                  type="button"
                  style={{
                    height: "40px",
                    minWidth: "100px",
                    maxWidth: "100px",
                  }}
                  className=" btn btn-block btn-danger mt-2"
                  onClick={async () => {
                    Swal.fire({
                      title: "คุณต้องการล้างค่าตำแน่งทั้งหมดใช่หรือไม่ ?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: `ใช่`,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      cancelButtonText: `ไม่`,
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        setActionActive({
                          btn: "camera",
                        });
                        actionActive.btn = "camera";
                        clearRackInput();
                        await GetWarehouseAPI(warehouseData.id);
                      }
                    });
                  }}
                >
                  Clear Position
                </button>
              </>
            ) : (
              <></>
            )}
            {/* <button
              type="button"
              style={{ height: "40px", minWidth: "100px", maxWidth: "100px" }}
              className=" btn btn-block btn-primary mt-2"
              onClick={async () => {
                checkForkliftSelectPosition();
              }}
            >
              Check
            </button> */}
          </div>
          {/* <div className="col-12 col-md-3 col-xl-1 d-flex align-items-center"></div> */}
        </div>
      </div>
    </div>
  );
}

export default HeaderChangeWH;
