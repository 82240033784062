import React, { Component, useEffect, useState, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import "../../assets/css/Supp.css";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import "../../assets/css/table.css";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import { useParams } from "react-router";
import Sidebar from "../../component/Sidebar";
import { getOem, getToken, getUser, getAcademy } from "../../Utils/Common";
import Configs from "../../config";
import FormData from "form-data";
import axios from "axios";
import moment from "moment";
import NumberFormat from "react-number-format";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";
import LOGH from "../../component/log_detailHistory_feature";
import INF from "../../component/input_feature";
import Select from "react-select";
import { tag_point_cts } from "../../component/Report";

function WipPackInOUtCTS_mgt(mode) {
  const { product_id } = useParams();
  const [company, setCompany] = useState([]);
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);

  const [product, setproduct] = useState({
    product_no: "",
    product_name: "",
    product_amount: "",
  });

  const [product_process, setproduct_process] = useState({
    edit_type: true,
    loss_amount: "",
    amount: "",
    lot_no: "",
  });

  const [lotno, setlotno] = useState([]);

  const [isGen, setisGen] = useState(true);
  const [box_no_gen, setbox_no_gen] = useState();
  const [box_no_genDefault, setbox_no_genDefault] = useState();
  const [box_no_list, setbox_no_list] = useState([]);

  const select_lot_no = useRef();
  const [lot_in_stock_list, setlot_in_stock_list] = useState([]);
  const [lot_history_list, setlot_history_list] = useState([]);

  const [test, settest] = useState({
    test: "",
  });

  const getProductView = async () => {
    await axios({
      method: "get",
      url:
        Configs.API_URL +
        "/api/wipshupOutInCTS/getProductBy?product_id=" +
        product_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        /* console.log("ดาต้าวิวโปรดัก",response.data); */
        setproduct({
          ...product,
          product_no: response.data.product_no,
          product_name: response.data.product_name,
          product_amount: response.data.material_amount,
        });
        product.product_no = response.data.product_no;
        product.product_name = response.data.product_name;
        product.product_amount = response.data.material_amount;
        console.log("product", product);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getLotNo = async () => {
    await axios({
      method: "get",
      url:
        Configs.API_URL + "/api/packInOutCTS/getlotNo?product_id=" + product_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        /*      setlotno(response.data); */
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.no;

          options.push(element);
        });
        setlotno(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getLotInStock = async () => {
    await axios({
      method: "get",
      url:
        Configs.API_URL +
        "/api/packInOutCTS/getLotInStock?product_id=" +
        product_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //var sort_data_ = response.data.sort((a,b)=> moment(b.material_qc_date).format('YYYYMMDDHHMM') - moment(a.material_qc_date).format('YYYYMMDDHHMM'));
        /*         var sort_data = response.data.sort((a, b) => a.material_qc_status - b.material_qc_status); */

        setproduct({ ...product, product_amount: response.data.length });
        product.product_amount = response.data.length;
        setlot_in_stock_list(response.data);
        /* setlot_in_stock_list(sort_data); */
        //console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getLotHistory = async () => {
    await axios({
      method: "get",
      url:
        Configs.API_URL +
        "/api/packInOutCTS/GetLotPackHistory?product_id=" +
        product_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        setlot_history_list(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getProductView();
    getLotNo();

    getLotInStock();
    getLotHistory();
  }, []);

  function SelectedReportTag(index) {
    var tempList = lot_in_stock_list;
    console.log(index);

    if (tempList[index].selected1) {
      tempList[index].selected1 = false;
    } else {
      tempList[index].selected1 = true;
    }
    console.log(tempList);
    setlot_in_stock_list(tempList);
    settest({ ...test, test: "" });
    test.test = "";
  }
  const row_data_lot_in_stock = [];
  for (let index = 0; index < lot_in_stock_list.length; index++) {
    const rowItem = {};
    rowItem["lot_no"] = lot_in_stock_list[index].lot_no;
    rowItem["pack_no"] = lot_in_stock_list[index].pack_no;
    rowItem["Amount"] = (
      <div style={{ textAlign: 'right' }}>
        {
          <NumberFormat
            thousandSeparator={true}
            value={lot_in_stock_list[index].amount}
            displayType="text"
          />
        }

      </div>

    );
    rowItem["create_date"] = lot_in_stock_list[index].create_date
      ? moment(lot_in_stock_list[index].create_date).format("DD/MM/yyyy")
      : "";
    /*  rowItem["unit"] = lot_in_stock_list[index].unit; */
    rowItem["loss_amount"] = (
      <div style={{ textAlign: 'right' }}>
        {
          lot_in_stock_list[index].loss_amount
        }
      </div>
    );

    rowItem["box_no"] = lot_in_stock_list[index].ref_box_no;
    rowItem["plating_plant"] = lot_in_stock_list[index].plating_plant_name;
    rowItem["tel"] = (
      <a href={"tel:" + lot_in_stock_list[index].tel}>
        {lot_in_stock_list[index].tel}
      </a>
    );

    var dis_selected = true;
    if (lot_in_stock_list[index].qc_date !== null) {
      dis_selected = false;
    }
    rowItem["track_report"] = (
      /*  <>
        <button
          type="button"
          onClick={() => {
            SelectedReportTag(product_id, lot_in_stock_list[index].tracking_no);
          }}
          class="btn btn-block btn-warning "
        >
          Print
        </button>
      </> */

      <>
        {lot_in_stock_list[index] === undefined ? (
          <></>
        ) : lot_in_stock_list[index].selected1 ? (
          <button
            type="button"
            onClick={() => {
              SelectedReportTag(index);
            }}
            class="btn btn-block btn-info "
          >
            Selected Tag
          </button>
        ) : (
          <button
            type="button"
            onClick={() => {
              SelectedReportTag(index);
            }}
            class="btn btn-block btn-primary "
          >
            Select Tag
          </button>
        )}
      </>
    );
    rowItem["mgt"] = (
      <>
        {lot_in_stock_list[index].selected ? (
          <button
            type="button"
            disabled={dis_selected}
            onClick={() => {
              SelectedPackNo(index);
            }}
            class="btn btn-block btn-info "
          >
            Selected
          </button>
        ) : (
          <button
            type="button"
            disabled={dis_selected}
            onClick={() => {
              SelectedPackNo(index);
            }}
            class="btn btn-block btn-primary "
          >
            Select
          </button>
        )}
      </>
    );
    row_data_lot_in_stock.push(rowItem);
  }

  const table_lot_in_stock_list = {
    columns: [
      {
        label: "++++",
        field: "mgt",
        sort: "asc",
        width: 50,
      },

      {
        label: "Lot No.",
        field: "lot_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Pack No",
        field: "pack_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "อ้างอิง Pack เก่า",
        field: "box_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันที่เข้ารอเเพ็ค",
        field: "create_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "จำนวน",
        field: "Amount",
        sort: "asc",
        width: 50,
      },
      /*  {
        label: "หน่วย",
        field: "unit",
        sort: "asc",
        width: 50,
      },  */
      /*   {
        label: "จำนวนที่สูญหาย",
        field: "loss_amount",
        sort: "asc",
        width: 50,
      }, */

      {
        label: "Print ป้ายชี้บ่ง",
        field: "track_report",
        sort: "asc",
        width: 50,
      },
      {
        label: "โรงชุบ",
        field: "plating_plant",
        sort: "asc",
        width: 50,
      },
      {
        label: "เบอร์โทร",
        field: "tel",
        sort: "asc",
        width: 50,
      },
      /*{
        label: "สถานะ QC",
        field: "qc_status",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันที่ QC",
        field: "qc_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "QC โดย",
        field: "qc_by",
        sort: "asc",
        width: 50,
      }, */
    ],
    rows: row_data_lot_in_stock,
  };

  if (product_process.edit_type === true) {
    var index = table_lot_in_stock_list.columns.findIndex((e) => {
      return e.field === "mgt";
    });
    table_lot_in_stock_list.columns.splice(index, 1);
  }

  const row_data_lot_history = [];
  for (let index = 0; index < lot_history_list.length; index++) {
    const rowItem = {};
    /* console.log("status",lot_history_list[index]); */

    rowItem["lot_no"] = lot_history_list[index].lot_no;
    rowItem["pack_no"] = lot_history_list[index].pack_no;
    rowItem["Amount"] = (
      <div style={{ textAlign: 'right' }}>
        {
          <NumberFormat
            thousandSeparator={true}
            value={lot_history_list[index].amount}
            displayType="text"
          />
        }
      </div>

    );
    rowItem["create_date"] = lot_history_list[index].create_date
      ? moment(lot_history_list[index].create_date).format("DD/MM/yyyy")
      : "";
    rowItem["unit"] = lot_history_list[index].unit;
    rowItem["loss_amount"] = (
      <div style={{ textAlign: 'right' }}>

        {
          <NumberFormat
            thousandSeparator={true}
            value={lot_history_list[index].loss_amount}
            displayType="text"
          />
        }
      </div>


    );
    rowItem["box_no"] = lot_history_list[index].ref_box_no;
    rowItem["plating_plant"] = lot_history_list[index].plating_plant_name;
    rowItem["status"] =
      lot_history_list[index].edit_type === true ? "IN" : "OUT";

    row_data_lot_history.push(rowItem);
  }
  const table_lot_history_list = {
    columns: [
      {
        label: "Lot No.",
        field: "lot_no",
        sort: "asc",
        width: 50,
      },

      {
        label: "Pack No",
        field: "pack_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "อ้างอิง Pack เก่า",
        field: "box_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "สถานะ",
        field: "status",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันที่เข้ารอเเพ็ค",
        field: "create_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "จำนวน",
        field: "Amount",
        sort: "asc",
        width: 50,
      },
      /*  {
            label: "หน่วย",
            field: "unit",
            sort: "asc",
            width: 50,
          },  */
      {
        label: "จำนวนที่สูญหาย",
        field: "loss_amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "โรงชุบ",
        field: "plating_plant",
        sort: "asc",
        width: 50,
      },

      /*{
            label: "สถานะ QC",
            field: "qc_status",
            sort: "asc",
            width: 50,
          },
          {
            label: "วันที่ QC",
            field: "qc_date",
            sort: "asc",
            width: 50,
          },
          {
            label: "QC โดย",
            field: "qc_by",
            sort: "asc",
            width: 50,
          }, */
    ],
    rows: row_data_lot_history,
  };

  function saveOption() {
    var box_no = lot_in_stock_list.filter((e) => {
      return e.selected;
    });
    console.log(box_no);
    Swal.fire({
      title: "Do you want to Save the changes?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        var err_list = [];
        if (
          product_process.edit_type === false &&
          product_process.lot_no !== "" /* &&
          parseFloat(pack_no[0].amount) -
            parseFloat(product_process.loss_amount) <
            0 */
        ) {
          err_list.push("จำนวนสูญหายไม่ถูกต้อง!!!");
        }

        /*  if (
          product_process.edit_type === true &&
          (product_process.amount == "" || product_process.amount < 1)
        ) {
          err_list.push("กรุณากรอก จำนวนที่จะทำการ In bound!!!");
        } */
        if (box_no.length == 0 && product_process.edit_type === false) {
          err_list.push("กรุณาเลือก Box No. ที่ต้องการ Out bound.");
        }
        if (box_no_list.length == 0 && product_process.edit_type === true) {
          err_list.push("กรุณาเพิ่ม Box No. ที่ต้องการ In bound.");
        }
        if (product_process.lot_no == '' && product_process.edit_type === true) {
          err_list.push("กรุณาเลือก Lot No. ที่ต้องการ In bound.");
        }
        var check_ref_box = box_no_list.filter((e) => {
          return e.status_ref_box === false;
        });
        if (check_ref_box.length > 0 && product_process.edit_type === true) {
          err_list.push("มีบางรายการ ที่ อ้างอิง Box No ไม่ถูกต้อง");
        }
        var checkWeight = box_no_list.filter((e) => {
          return (
            e.weight_by_tag == 0 ||
            e.weight_by_tag == ""
          );
        });

        if (checkWeight.length > 0 && product_process.edit_type === true) {
          err_list.push(
            "กรุณาเพิ่ม น้ำหนักตามป้าย ของ Box No. ที่ต้องการ In bound."
          );
        }

        if (err_list.length > 0) {
          //มี err
          var err_message = "";
          for (var e = 0; e < err_list.length; e++) {
            err_message += "<br/>" + err_list[e];
          }
          Swal.fire("ระบบ", err_message, "error");
        } else {
          let tmp_save = {
            product_id: product_id,
            edit_type: product_process.edit_type,
            lot_no: product_process.lot_no,
            /*  amount: product_process.amount, */
            box_no_list: box_no_list,
            pack_no_list: box_no,
            loss_amount: product_process.loss_amount,
            /* lot_no_list:coil_no, */
          };
          console.log("save", tmp_save);
          axios({
            method: "post",
            url: Configs.API_URL + "/api/packInOutCTS/saveInOut",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: tmp_save,
          })
            .then(function (response) {
              //setlot_history_list(response.data);
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  getLotNo();
                  getProductView();
                  getLotInStock();
                  getLotHistory();
                  setbox_no_list([]);
                  setisGen(true);
                  //clear radio----------------------------------------
                  var radioButtonArray =
                    document.getElementsByName("check_coil_no");

                  for (var i = 0; i < radioButtonArray.length; i++) {
                    var radioButton = radioButtonArray[i];
                    radioButton.checked = false;
                  }
                  product_process.edit_type = true;

                  setproduct_process({
                    ...product_process,
                    loss_amount: "",
                    lot_no: "",
                    amount: "",
                  });
                  product_process.loss_amount = "";
                  product_process.lot_no = "";
                  product_process.amount = "";
                  if (product_process.edit_type === true) {
                    select_lot_no.current.select.clearValue();
                  }
                  //clear radio------------------------------------------
                });
              }
            })
            .catch(function (error) {
              Swal.fire("Over Stock!", error.response.data.message, "warning");
              console.log(error);
            });
          // ไม่มี err
        }
      }
    });
  }

  function SelectedPackNo(index) {
    var tempList = lot_in_stock_list;
    for (let i of tempList) {
      i.selected = false;
    }
    /*  console.log(index); */

    if (tempList[index].selected) {
      tempList[index].selected = false;
    } else {
      tempList[index].selected = true;
    }
    setlot_in_stock_list(tempList);
    settest({ ...test, test: "" });
    test.test = "";
  }

  function cancleOption(params) {
    Swal.fire({
      title: "Do you want to cancel and leave the changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "/Warehouse_Management/Pack_In_OUt";
      }
    });
  }

  const genBoxNo = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/api/packInOutCTS/genBoxNo",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        setbox_no_gen(response.data);
        setbox_no_genDefault(response.data);
        console.log(response.data);
        var tempList = box_no_list;
        setbox_no_list([]);
        let temp = {
          box_no: response.data,
          weight_by_tag: "",
          old_box_no: "",
        };
        tempList.push(temp);
        setbox_no_list(tempList);
        settest({ ...test, test: "" });
        test.test = "";
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  async function addBoxNoList() {
    var tempList = box_no_list;
    console.log("isGen", isGen);
    if (isGen) {
      await genBoxNo();
      setisGen(false);
    }
    if (!isGen) {
      var gen = box_no_gen;
      if (tempList.length > 0) {
        var intGen = box_no_gen.split("K");
        gen = parseInt(intGen[1]) + 1;
        gen = "PACK" + gen;
        /*       gen = parseInt(box_no_gen) +1; */
        /*  gen = box_no_gen; */
      }

      setbox_no_gen(gen);
      let temp = {
        box_no: gen,
        weight_by_tag: "",
        old_box_no: "",
      };
      tempList.push(temp);
      setbox_no_list(tempList);
      settest({ ...test, test: "" });
      test.test = "";
    }
  }

  async function ReRunBoxNo() {
    axios({
      method: "get",
      url: Configs.API_URL + "/api/packInOutCTS/genBoxNo",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        setbox_no_gen(response.data);
        setbox_no_genDefault(response.data);

        var tempList = box_no_list;
        setbox_no_list([]);
        var count = 0;

        for (let item of tempList) {
          var intGen = response.data.split("K");
          item.box_no = parseInt(intGen[1]) + count;
          item.box_no = "PACK" + item.box_no;

          count++;
        }
        setbox_no_list(tempList);
        console.log(tempList);
        settest({ ...test, test: "" });
        test.test = "";
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function delBoxNoList(index) {
    var tempList = box_no_list;
    tempList.splice(index, 1);
    setbox_no_list(tempList);
    var count = 0;
    for (let item of box_no_list) {
      var intGen = box_no_genDefault.split("K");
      item.box_no = parseInt(intGen[1]) + count;
      item.box_no = "BOX" + item.box_no;

      count++;
    }
    if (tempList.length === 0) {
      setisGen(true);
    }
    settest({ ...test, test: "" });
    test.test = "";
  }

  function changeWeightByTag(value, index) {
    var tempList = box_no_list;
    tempList[index].weight_by_tag = value;
    setbox_no_list(tempList);
    settest({ ...test, test: "" });
    test.test = "";
  }

  function changeOldBoxByTag(value, index) {
    var tempList = box_no_list;
    tempList[index].old_box_no = value;
    setbox_no_list(tempList);
    settest({ ...test, test: "" });
    test.test = "";
  }
  async function checkRefBox(ref_box, index) {
    let tmp_check = {
      product_id: product_id,
      product_ref_box_no: ref_box,
    };
    axios({
      method: "post",
      url: Configs.API_URL + "/api/packInOutCTS/checkRefBox",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tmp_check,
    })
      .then(function (response) {
        if (response.data) {
          var tempList = box_no_list;
          tempList[index].status_ref_box = response.data.status;
          tempList[index].ref_box_message = response.data.message;
          setbox_no_list(tempList);
          settest({ ...test, test: "" });
          test.test = "";
        }
      })
      .catch(function (error) {
        Swal.fire("Error!", error.response.data.message, "warning");
        console.log(error);
      });
  }

  const linkreport = async (product_id, tracking) => {
    var get_filler_materail = {
      product_id: product_id,
      tracking: tracking,
      company_key: "CTS",
      oem_id: getOem(),
      company_id: getUser().com,
      product_no: product.product_no,
      product_name: product.product_name,
    };

    console.log(get_filler_materail);
    axios({
      method: "post",
      url: Configs.API_URL + "/api/packInOutCTS/getPackloginreport",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_materail,
    })
      .then(function (response) {
        console.log("pack", response.data);
        if (response.data.length < 1) {
          Swal.fire({
            icon: "error",
            title: "ไม่มีข้อมูล",
          });
        } else {
        tag_point_cts(response.data, getUser().sub, "pack");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  async function QcTagGenerate() {
    console.log(lot_in_stock_list);
    const temdata = lot_in_stock_list.filter((e) => {
      return e.selected1 === true;
    });

    const templist = [];

    temdata.forEach((e) => {
      templist.push(e.pack_no);
    });
    console.log("templist", templist);
    await linkreport(product_id, templist);
  }
  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  งานรอ Pack In/Out Management {pageMode}
                  {"  "}
                  <a target="_blank" href={getAcademy()}>
                    <i class="fa fa-info-circle"></i>
                  </a>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    งานรอ Pack In/Out Management
                  </li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h2>Pack in warehouse {product.product_amount} items</h2>
              <div className="card-tools"></div>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-0 col-md-2 col-xl-2"></div>
                <div className="col-6 col-md-4 col-xl-4">
                  <h1>Product No. : {product.product_no}</h1>
                </div>
                <div className="col-6 col-md-4 col-xl-4">
                  <h1>Product Name : {product.product_name}</h1>
                </div>
              </div>

              <div className="row">
                <div className="col-0 col-md-2 col-xl-2"></div>
                <div className="col-6 col-md-4 col-xl-4">
                  <input
                    type="radio"
                    id="inbound"
                    name="edit_type"
                    checked={product_process.edit_type != true ? false : true}
                    value={true}
                    onClick={(e) => {
                      setproduct_process({
                        ...product_process,
                        edit_type: e.target.value === "true" ? true : false,
                      });
                      product_process.edit_type = true;
                      setproduct_process({
                        ...product_process,
                        /* edit_type: "", */
                        loss_amount: "",
                        amount: "",

                        lot_no: "",
                      });
                      product_process.edit_type = "";

                      product_process.amount = "";

                      product_process.lot_no = "";
                    }}
                  />

                  <label htmlFor="inbound">
                    <h4>&nbsp;&nbsp;In bound</h4>
                  </label>
                </div>

                <div className="col-6 col-md-4 col-xl-4">
                  <input
                    type="radio"
                    id="outbound"
                    name="edit_type"
                    value={true}
                    onClick={(e) => {
                      setproduct_process({
                        ...product_process,
                        edit_type: e.target.value === "true" ? false : true,
                        /*  edit_type: "", */
                        loss_amount: "",
                        /*   plating_plant_id: "", */
                        amount: "",
                        /* unit: "", */
                        lotno: "",
                        /* chup_id: "",
                        chup_tacking: "", */
                      });
                      setbox_no_list([]);
                      setisGen(true);
                      /* select_location_model.current.select.clearValue();
                      select_supplier_model.current.select.clearValue(); */
                    }}
                  />

                  <label htmlFor="outbound">
                    <h4>&nbsp;&nbsp;Out bound</h4>
                  </label>
                </div>
              </div>
              <div className="row">
                {" "}
                <div className="col-4"></div>
              </div>
              <LOGH
                history={table_lot_history_list}
                data={table_lot_in_stock_list}
                approve_cts={
                  <div className="form-group ">
                    <button
                      type="button"
                      className="btn btn-block btn-info"
                      onClick={() => QcTagGenerate()}
                    >
                      Report Tag
                    </button>
                  </div>
                }
              />

              {product_process.edit_type === true ? (
                <>
                  <div className="row">
                    <div
                      className="col-12 col-md-4 col-xl-4"
                      style={{ "z-index": "98" }}
                    >
                      <div className="form-group ">
                        {/* <Select
                          options={lotno}
                          value={
                            lotno.filter((f) => {
                              return f.label === product_process.lot_no;
                            })
                          }
                          onChange={(e) => {
                            if (e !== null) {
                              setproduct_process({
                                ...product_process,

                                lot_no: e.label,
                              });
                            }
                          }}
                        /> */}
                        <Select
                          options={lotno}
                          ref={select_lot_no}
                          value={lotno.filter((f) => {
                            return f.label === product_process.lot_no;
                          })}
                          onChange={(e) => {
                            if (e !== null) {
                              setproduct_process({
                                ...product_process,

                                lot_no: e.label,
                              });
                            }
                          }}
                        />

                        <label htmlFor="">Lot No.</label>
                      </div>
                    </div>

                    {/* <div className="col-12 col-md-4 col-xl-4">
                      <div className="form-group ">
                        <NumberFormat
                         thousandSeparator="true"
                          decimalScale={2}
                          type="text"
                          className="form-control"
                          allowNegative={false}
                          value={product_process.amount}
                          onChange={(e) => {
                            setproduct_process({
                              ...product_process,
                              amount: e.target.value,
                            });
                          }}
                        />
                        <label htmlFor="">Amount (กก.)</label>{" "}
                      </div>
                    </div> */}
                  </div>

                  <br />
                  <div className="row mb-2">
                    <div className="col-4 col-md-3 col-xl-2">
                      <button
                        type="button"
                        onClick={() => {
                          addBoxNoList();
                        }}
                        class="btn btn-block btn-primary "
                      >
                        เพิ่ม Pack No.{" "}
                        <i className="fa fa-plus-circle" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div className="col-4 col-md-3 col-xl-2">
                      <button
                        type="button"
                        onClick={() => {
                          ReRunBoxNo();
                        }}
                        class="btn btn-block btn-primary "
                      >
                        Refresh Pack No.{" "}
                        <i className="fa fa-history" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                  <br />
                  <div
                    className="table-responsive"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <table style={{ width: "100%", whiteSpace: "nowrap" }}>
                      <tr>
                        <td
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            width: "100px",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                        <td
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            width: "100px",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>

                        <td
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            width: "100px",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                        <td
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            width: "30px",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                      </tr>

                      {box_no_list.map((e, index) => {
                        var color_ref = "green";
                        if (e.status_ref_box === false) {
                          color_ref = "red";
                        }
                        return (
                          <>
                            <tr key={e.box_no}>
                              <td
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  width: "100px",
                                }}
                              >
                                <INF
                                  value={e.box_no}
                                  label={"Pack No."}
                                  /* onChange={(e) => {
                                setmaterial_process({
                                  ...material_process,
                                  coil_no: e.target.value,
                                });
                              }} */ disabled
                                />
                              </td>
                              <td
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  width: "100px",
                                }}
                              >
                                {" "}
                                <div className="form-group ">
                                  <NumberFormat
                                    thousandSeparator={true}
                                    decimalScale={4}
                                    type="text"
                                    className="form-control"
                                    allowNegative={false}
                                    value={e.weight_by_tag}
                                    onChange={(e) => {


                                      changeWeightByTag(Number(e.target.value.replace(/,/g, "")), index);
                                    }}
                                  />
                                  <label htmlFor="">จำนวน (กก.)</label>{" "}
                                </div>
                              </td>

                              <td
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  width: "100px",
                                }}
                              >
                                <div className="form-group ">
                                  <input
                                    maxLength="12"
                                    type="text"
                                    className="form-control"
                                    value={e.old_box_no}
                                    onChange={(e) => {
                                      changeOldBoxByTag(e.target.value, index);
                                      if (
                                        e.target.value.length > 0 &&
                                        e.target.value.length < 12
                                      ) {
                                        var tempList = box_no_list;
                                        tempList[index].status_ref_box = false;
                                        tempList[index].ref_box_message =
                                          "Incorrect";
                                        setbox_no_list(tempList);
                                        settest({ ...test, test: "" });
                                        test.test = "";
                                      }
                                      if (e.target.value.length === 0) {
                                        var tempList = box_no_list;
                                        tempList[index].status_ref_box = null;
                                        tempList[index].ref_box_message = "";
                                        setbox_no_list(tempList);
                                        settest({ ...test, test: "" });
                                        test.test = "";
                                      }
                                      if (e.target.value.length === 12) {
                                        checkRefBox(e.target.value, index);
                                      }
                                    }}
                                  />
                                  <label htmlFor="">
                                    อ้างอิง Pack No. เก่า{" "}
                                    {e.ref_box_message ? (
                                      <font color={color_ref}>
                                        {" >>>>> "}
                                        {e.ref_box_message}
                                      </font>
                                    ) : (
                                      ""
                                    )}
                                  </label>{" "}
                                </div>
                              </td>
                              <td
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  width: "30px",
                                }}
                              >
                                {" "}
                                <div className="form-group ">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      delBoxNoList(index);
                                    }}
                                    class="btn btn-block btn-danger "
                                  >
                                    X
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </table>
                  </div>
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-12 col-md-4 col-xl-4">
                      <div className="form-group ">
                        <NumberFormat
                          thousandSeparator={true}
                          decimalScale={4}
                          type="text"
                          className="form-control"
                          allowNegative={false}
                          value={product_process.loss_amount}
                          onChange={(e) => {
                            setproduct_process({
                              ...product_process,
                              loss_amount: Number(e.target.value.replace(/,/g, "")),
                            });
                          }}
                        />
                        <label htmlFor="">จำนวนที่สูญหาย (Loss)</label>{" "}
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="row mb-2">
                <div className="col-4 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-4 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/*  <Footter/> */}
    </div>
  );
}
export default WipPackInOUtCTS_mgt;
