import React, { Component, useEffect, useState } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser, getFeature,getAcademy } from "../../Utils/Common";
import { useParams } from "react-router";
import NumberFormat from "react-number-format";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { MultiSelect } from "react-multi-select-component";
import moment from "moment";
import GoogleMapReact from "google-map-react";
function Wait_delivery_add(mode) {
  const CustomInput = ({ value, onClick }) => (

    <
      div className="input-group" >
      < input type="text"
        className="form-control float-left"
        onClick={onClick}
        disabled={disable}
        value={value}
      /> <div className="input-group-prepend" >
        <span className="input-group-text" >
          <i className="far fa-calendar-alt" />
        </span>

        { /*  <label>test</label> */}
      </div>
    </div>


  )
  const [currentOEM, setCurrentOEM] = useState({ label: "", value: "" });

  const CustomInput2 = ({ value, onClick }) => (

    <
      div className="input-group" style={{ width: "100%" }} >
      <NumberFormat
        thousandSeparator={false}
        allowNegative={false}

        type="text"
        className="form-control"
        value={value}
      /*  onChange={(e) => {
         setamount({
           ...amount,
           amount: e.target.value,
         });
       }} */

      />
      {/* < input type = "number"
    className = "form-control float-left"
    onClick = { onClick }
    disabled={disable}
    value = { value }
    />  */}
    </div>


  )


  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);
  const [companyGroup, setCompanyGroup] = useState([]);
  const [model_category, setmodel_category] = useState([]);
  const [model_category_list, setmodel_category_list] = useState([]);
  const [bill, setbill] = useState({
    create_bill: "",
    create_bill_time: "",
    delivery_bill: "",
    delivery_bill_time: "",
    bill_no: "",
    //model_category:"",
    oem_target: getOem(),
    customer_name: "",
    bill_destination: "",
    bill_values: "",
    weight: "",
    is_pack: false,
    remark: "",
    oem_id: getOem(),
    company_id: getUser().com,
    longitude: "",
    latitude: "",
    compressive: "",
    meter_weight: "",
    weight_meter_updated: "",
    is_receive: false,
    person_sent: "",
    person_receive: "",
    time_receive : "",

  });

  const [billupdate, setbillupdate] = useState({
    lastupdate: "",
    compressive: "",
    meter_weight: "",
  });

  const [feature, setfeature] = useState([]);

  const [test, settest] = useState({
    test: "",
  });

  const options = [
    { label: "Grapes 🍇", value: "grapes" },
    { label: "Mango 🥭", value: "mango" },
    { label: "Strawberry 🍓", value: "strawberry" },
  ];


  const { id } = useParams();
  const GetBill_view = async () => {

    if (mode.mode == "read" || mode.mode == "edit") {
      axios({
        method: "get",
        url: Configs.API_URL + "/bill/findById?id=" + id,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          console.log("GetBill_view", response.data);
          if (response.data.time_receive !== null) {
            var chaektime_receive = new Date(response.data.time_receive) 
          } else {
            var chaektime_receive = ""
          }
        
          setbill({
            ...bill,
            create_bill: new Date(response.data.create_bill),
            create_bill_time: response.data.create_time || "",
            delivery_bill: new Date(response.data.delivery_bill),
            delivery_bill_time: response.data.delivery_time || "",
            bill_no: response.data.bill_no || "",
            //model_category:response.data.product_model_id || "",
            oem_target: response.data.bill_oem_id,
            customer_name: response.data.customer_name || "",
            bill_destination: response.data.bill_destination || "",
            bill_values: response.data.bill_values || "",
            weight: response.data.weight || "",
            is_pack: response.data.is_pack || "",
            remark: response.data.remark || "",
            oem_id: getOem(),
            company_id: getUser().com,
            longitude: response.data.longitude || "",
            latitude: response.data.latitude || "",
            compressive: response.data.compressive_strength || "",
            meter_weight: response.data.weight_meter || "",
            weight_meter_updated: response.data.weight_meter_updated || "",
            is_receive: response.data.is_receive || "",
            person_sent: response.data.person_sent || "",
            person_receive: response.data.person_receive || "",
            time_receive : chaektime_receive
          });
          // if(response.data.compressive_strength === "" && response.data.meter_weight === ""){

          // }

          // if(response.data.weight_meter_updated === null){
          //   let dateae = "ไม่มีข้อมูล"
          //   setbillupdate({
          //     ...billupdate,
          //     lastupdate: dateae});
          // }else{
          //   let dateas = moment(response.data.weight_meter_updated).format('yyyy-MM-DD')
          //   setbillupdate({

          //     ...billupdate,
          //     lastupdate: dateas,
          //     compressive :response.data.compressive_strength || "",
          //     meter_weight:response.data.weight_meter || "",});
          // }

          if (response.data.product_and_category !== null) {
            setmodel_category_list(response.data.product_and_category);
          }

        })
        .catch(function (error) {
          console.log(error);
        });
    }

  }

  var config_getOem = {
    method: "get",
    url: Configs.API_URL + "/company/findOEMByCompanyId?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };

  const Getmodel_category = async () => {
    let temp = {
      model_category: "",
      oem_id: getOem(),

    }
    //console.log(filler_supplier);
    axios({
      method: "post",
      url: Configs.API_URL + "/material/filtermodel_category",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp
    })
      .then(function (response) {
        const options = [];
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name;
          options.push(element);
        });
        setmodel_category(options);

      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    /*   var feature_data = JSON.parse(getFeature()).filter((element) => {
        return element.menu === "wait";
      });
      setfeature(feature_data); */

    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("[โหมด ดู]")
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("[โหมด เพิ่ม]")
    } else {
      setdisable(false);
      setPageMode("[โหมด แก้ไข]")
    }

    axios(config_getOem)
      .then(function (response) {
        const options = [];
        var element2 = {};
        element2["value"] = "All_OEM";
        element2["label"] = "---ทั้งหมด---";
        options.push(element2);
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name;
          options.push(element);
          if (el.id === getOem()) {
            setCurrentOEM({ label: el.name, value: el.id });
          }
        });

        console.log(options);
        setCompanyGroup(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);







  useEffect(() => {
    Getmodel_category();
    if (mode.mode !== "add") {
      GetBill_view();
    }

    if(mode.mode === "read" || mode.mode === "edit"){
      GetBill_view();
      setdefault_center({ ...default_center, lat: bill.latitude, lng: bill.longitude });
    }
  }, []);

  function cancleOption(params) {
    Swal.fire({
      title: "Do you want to cancel and leave the changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href =
          "/Warehouse_Management/wait_delivery";
      }

    });

  }

  function saveOption(params) {
    console.log(bill);
    const error_list = [];
    if (bill.create_bill == "") {
      let temp_err = {
        message: "Please enter information in the fields >>> [วันที่ออกบิล]."
      }
      error_list.push(temp_err);
    }

    if (bill.delivery_bill == "") {
      let temp_err = {
        message: "Please enter information in the fields >>> [วันที่นัดส่งสินค้า ]."
      }
      error_list.push(temp_err);
    }

    if (bill.bill_no.trim() == "") {
      let temp_err = {
        message: "Please enter information in the fields >>> [เลขที่บิล]."
      }
      error_list.push(temp_err);
    }
    if (bill.oem_target == "") {
      let temp_err = {
        message: "Please enter information in the fields >>> [กลุ่มบริษัท]."
      }
      error_list.push(temp_err);
    }

    if (bill.bill_values == "") {
      let temp_err = {
        message: "Please enter information in the fields >>> [มูลค่า]."
      }
      error_list.push(temp_err);
    }

    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    } else {
      if (mode.mode === "add") {
        bill.weight = bill.weight === null
          ? 0
          : Number(bill.weight.toString().replace(/,/g, ""));
        bill.bill_values = bill.bill_values === null
          ? 0
          : Number(bill.bill_values.toString().replace(/,/g, ""));
        bill.model_category = model_category_list;
        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/bill/add",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: bill,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/wait_delivery";
                });
              }

              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
      }

      if (mode.mode === "edit") {
        bill.id = id;
        bill.weight = bill.weight === null
          ? 0
          : Number(bill.weight.toString().replace(/,/g, ""));
        bill.bill_values = bill.bill_values === null
          ? 0
          : Number(bill.bill_values.toString().replace(/,/g, ""));
        bill.model_category = model_category_list;
        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/bill/update",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: bill,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/wait_delivery";
                });
              }

              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
      }

    }
  }

  function UpdateFormexpree(bilid) {
    console.log("UIWP:ID:", bilid);
    axios({
      method: "get",
      url: Configs.API_URL + "/bill/findByIdBil?bilid=" + bilid,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        console.log("GetBill_view", response.data);
        setbill({
          ...bill,
          compressive: response.data.compressive_strength,
          meter_weight: response.data.weight_meter,
        });
        if (response.data.weight_meter_updated === null) {
          let dateae = "ไม่มีข้อมูล"
          setbillupdate({
            ...billupdate,
            lastupdate: dateae
          });
        } else {
          let dateas = moment(response.data.weight_meter_updated).format('yyyy-MM-DD')
          setbillupdate({
            ...billupdate,
            lastupdate: dateas
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", error.response.data.message, "error");
      });
  }
  // let wighta = 1;
  console.log("ไม่มี", billupdate.lastupdate);




  const fruits = [
    { label: "Mango", value: "mg" },
    { label: "Guava", value: "gv" },
    { label: "Peach", value: "pc" },
    { label: "Apple", value: "ap" }
  ];


  const [state1, setstate1] = useState({
    fruits: null,
    states: null
  });

  const markerStyle = {
    position: "absolute",
    top: "100%",
    left: "50%",
    transform: "translate(-50%, -100%)",
  };
  const [location, setlocation] = useState([]);
  function setMark(lat, lng) {
    console.log("lat,lng", lat, lng);
    setlocation([]);
    const tempData2 = [];
    let temp =
    {
      "id": 1,
      "title": "My Position",
      "website": "www.google.com",
      "image": " ",
      "address": [
        {
          "id": 1,
          "country": "",
          "city": "",
          "street": "",
          "postcode": "",
          "lat": lat,
          "lng": lng
        }
      ]
    }
    tempData2.push(temp);
    setlocation(tempData2);
    setbill({ ...bill, latitude: lat, longitude: lng });
    settest({ ...test, test: "" });
    test.test = "";
  }


  /* 

  handleChange = 
  updateState = (value) => this.setstate1({ states: value }); */
  const [default_center, setdefault_center] = useState({
    lat: "",
    lng: "",
  })

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>บิลรอจัดส่ง {pageMode}{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">บิลรอจัดส่ง</li>
                  <li className="breadcrumb-item active">
                    บิลรอจัดส่ง{" "}
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row mb-2">
                <div className="col-sm-1">
                  <button
                    type="button"
                    onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                <div className="col-sm-1">
                  <button
                    type="button"
                    className="btn" onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-sm-1">
                  <button
                    type="button"
                    if
                    onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>

        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">{/* {pageMode} */} บิลรอจัดส่ง </h3>
            </div>
            <div className="card-body">
              <h3 className="mt-5-head">ใบส่งสินค้า</h3>
              <div className="tab-custom-content"></div>


              <div className="row">
                <div className="col-12 col-md-2 col-xl-1">
                  <div className="form-group ">
                    <label>&nbsp;&nbsp; วันที่ออกบิล  {bill.create_bill === "" ? (
                      <span style={{ color: "red" }}> *</span>
                    ) : (
                      ""
                    )}</label>
                  </div>
                </div>
                <div className="col-7 col-md-4 col-xl-2">
                  <div className="form-group ">
                    <DatePicker
                      selected={bill.create_bill}
                      disabled={disable}
                      dateFormat={"dd-MM-yyyy"}
                      onChange={
                        async (date) => {
                          setbill({
                            ...bill,
                            create_bill: date,
                          });
                          //SetDate_end(date)   
                        }}
                      maxDate={bill.delivery_bill}
                      // selectsEnd startDate = { filler_order_product.start_date }
                      // endDate = { filler_order_product.end_date }
                      // minDate = { filler_order_product.start_date }
                      customInput={<CustomInput />}

                    >
                    </DatePicker>
                  </div>
                </div>


                <div className="col-4 col-md-4 col-xl-2">

                  <div class="form-group">


                    {/*   <input
                      type="text"
                      className="form-control"
                      required
                      value={bill.create_bill_time}
                      disabled={disable}
                    
                      onChange={(e) => {
                        setbill({
                          ...bill,
                          create_bill_time: e.target.value,
                        });
                      }}
                    /> */}
                    <NumberFormat
                      placeholder="00:00"
                      allowNegative={false}
                      disabled={disable}
                      type="text"
                      format={"##:##"}
                      className="form-control"
                      value={bill.create_bill_time}
                      onChange={(e) => {
                        const myArray = e.target.value.split(":");
                        if (myArray[0] > 23 || myArray[1] > 59) {
                          setbill({
                            ...bill,
                            time: "23:59",
                          });
                        } else {
                          setbill({
                            ...bill,
                            create_bill_time: e.target.value,
                          });
                        }

                      }}

                    />
                    <label htmlFor="">
                      เวลา
                      {/*   {SparePart.spare_part_name.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
              </div>


              <div className="row">
                <div className="col-12 col-md-2 col-xl-1">
                  <div className="form-group ">
                    <label>&nbsp;&nbsp; วันที่นัดส่งสินค้า  {/* <span style={{ color: "red" }}> *</span> */}</label>
                  </div>
                </div>
                <div className="col-7 col-md-4 col-xl-2">
                  <div className="form-group ">
                    <DatePicker
                      selected={bill.delivery_bill}
                      dateFormat={"dd-MM-yyyy"}
                      onChange={
                        async (date) => {
                          setbill({
                            ...bill,
                            delivery_bill: date,
                          });
                          //SetDate_end(date)   
                        }}

                      //selectsStart startDate = {bill.create_bill}
                      minDate={bill.create_bill}
                      endDate={bill.delivery_bill}
                      // selectsEnd startDate = { filler_order_product.start_date }
                      // endDate = { filler_order_product.end_date }
                      // minDate = { filler_order_product.start_date }
                      customInput={<CustomInput />}

                    >
                    </DatePicker>
                  </div>
                </div>


                <div className="col-4 col-md-4 col-xl-2">
                  <div class="form-group">

                    {/* <input
                      type="text"
                      className="form-control"
                      required
                      value={bill.delivery_bill_time}
                      disabled={disable}
                      onChange={(e) => {
                        setbill({
                          ...bill,
                          delivery_bill_time: e.target.value,
                        });
                      }}
                    /> */}
                    <NumberFormat
                      placeholder="00:00"
                      allowNegative={false}
                      disabled={disable}
                      type="text"
                      format={"##:##"}
                      className="form-control"
                      value={bill.delivery_bill_time}
                      onChange={(e) => {
                        const myArray1 = e.target.value.split(":");
                        if (myArray1[0] > 23 || myArray1[1] > 59) {
                          setbill({
                            ...bill,
                            time: "23:59",
                          });
                        } else {
                          setbill({
                            ...bill,
                            delivery_bill_time: e.target.value,
                          });
                        }
                      }}

                    />
                    <label>
                      เวลา
                      {/*  {SparePart.spare_part_name.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">

                <div className="col-6 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={bill.bill_no}
                      disabled={disable}
                      onChange={(e) => {
                        setbill({
                          ...bill,
                          bill_no: e.target.value,
                        });
                      }}
                    />
                    <label>
                      เลขที่บิล
                      {bill.bill_no.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                  
                </div>
                {getOem() === currentOEM.value && currentOEM.label === "บจก.เอสที คอนกรีตโปรดักส์" ? (
                       <>
                
                </>
                      ) : (
                        <>
                        <div className="col-1 mt-3 ml-3">
                <input type="checkbox"
                    id="is_receive"
                    disabled={disable}
                    className="form-check-input"
                    onChange={(event) => {
                      setbill({ ...bill, is_receive: event.currentTarget.checked });
                      bill.is_receive = event.currentTarget.checked;

                    }}
                    checked={bill.is_receive}
                  />
                  <label htmlFor="is_receive">
                  รับสินค้าเอง
                  </label>
                </div>

                {bill.is_receive === true ? (
                       <><div className="col-5 col-md-4 col-xl-2">
                       <div class="form-group">
                         <input
                           type="text"
                           className="form-control"
                           required
                           value={bill.person_sent}
                           disabled={disable}
                           onChange={(e) => {
                             setbill({
                               ...bill,
                               person_sent: e.target.value,
                             });
                           }}
                         />
                         <label>
                         ผู้จัดสินค้า
                           {/*  {SparePart.spare_part_no.trim() === "" ? (
                             <span style={{ color: "red" }}> *</span>
                           ) : (
                             ""
                           )} */}
                         </label>
                       </div>
                     </div>
     
                   {/*   <div className="col-5 col-md-4 col-xl-2">
                       <div class="form-group">
                         <input
                           type="text"
                           className="form-control"
                           required
                           value={bill.person_receive}
                           disabled={disable}
                           onChange={(e) => {
                             setbill({
                               ...bill,
                               person_receive: e.target.value,
                             });
                           }}
                         />
                         <label>
                           ผู้รับ
                          
                         </label>
                       </div>
                     </div> */}
                  {/*    <div className="col-12 col-md-2 col-xl-1">
                  <div className="form-group ">
                    <label>วันที่นัดรับสินค้า  </label>
                  </div>
                </div>
                <div className="col-7 col-md-4 col-xl-2">
                  <div className="form-group ">
                    <DatePicker
                      selected={bill.time_receive}
                      dateFormat={"dd-MM-yyyy"}
                      onChange={
                        async (date) => {
                          setbill({
                            ...bill,
                            time_receive: date,
                          });
                           
                        }}

                     
                      minDate={bill.create_bill}
                     
                      customInput={<CustomInput />}

                    >
                    </DatePicker>
                  </div>
                </div> */}</>
                      ) : (
                        <></>
                      )}
                
                        </>
                      )}
              </div>
              
              <div className="row">
                <div className="col-12">




                  {/*       <pre>{JSON.stringify(model_category_list)}</pre> */}
                </div>    </div>
              <div className="row">
                <div className="col-6">
                  <label>model&category</label>

                  <MultiSelect
                    disabled={disable}
                    hasSelectAll={false}
                    options={model_category}
                    value={model_category_list}
                    onChange={setmodel_category_list}
                    labelledBy="Select"
                  />


                </div>
                <div className="col-6">
                  <br />
                  {/*  <table>
               
                        <tr>
              {model_category_list.map((e)=>{

                return ( 
                  
             <td>{e.label},</td>
      
                );
              })}
                  </tr>
     </table>  */}
                </div>
              </div>
              <br />
              <div className="row">

                <div className="col-5 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={bill.customer_name}
                      disabled={disable}
                      onChange={(e) => {
                        setbill({
                          ...bill,
                          customer_name: e.target.value,
                        });
                      }}
                    />
                    <label>
                      ชื่อลูกค้า
                      {/*  {SparePart.spare_part_no.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>

                <div className="col-5 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={bill.bill_destination}
                      disabled={disable}
                      onChange={(e) => {
                        setbill({
                          ...bill,
                          bill_destination: e.target.value,
                        });
                      }}
                    />
                    <label>
                      หน้างาน
                      {/*  {SparePart.spare_part_no.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">

                <div className="col-6 col-md-4 col-xl-2">
                  <div class="form-group">
                    <NumberFormat
                      thousandSeparator={true}
                      allowNegative={false}
                      disabled={disable}
                      type="text"
                      className="form-control"
                      value={bill.bill_values}
                      onChange={(e) => {
                        setbill({
                          ...bill,
                          bill_values: e.target.value,
                        });
                      }}

                    />
                    {/*  <input
                      type="text"
                      className="form-control"
                      required
                      value={bill.bill_values}
                      disabled={disable}
                      onChange={(e) => {
                        setbill({
                          ...bill,
                          bill_values: e.target.value,
                        });
                      }}
                    /> */}
                    <label>
                      มูลค่า
                      {/* {SparePart.spare_part_no.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-sm-3 col-xl-2">
                  <div class="form-group">
                    {getOem() === currentOEM.value && currentOEM.label === "บจก.เอสที คอนกรีตโปรดักส์" ? (
                      <>
                        <NumberFormat
                          thousandSeparator={true}
                          allowNegative={false}
                          disabled={disable}
                          type="text"
                          className="form-control"
                          value={bill.weight}
                          onChange={(e) => {
                            setbill({
                              ...bill,
                              weight: e.target.value,
                            });
                          }}

                        />


                        <label>
                          จำนวนคิว(คิว)
                          {/*   {SparePart.spare_part_no.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                        </label>
                      </>
                    ) : (
                      <>
                        <NumberFormat
                          thousandSeparator={true}
                          allowNegative={false}
                          disabled={disable}
                          type="text"
                          className="form-control"
                          value={bill.weight}
                          onChange={(e) => {
                            setbill({
                              ...bill,
                              weight: e.target.value,
                            });
                          }}

                        />
                        <label>
                          น้ำหนัก(ตัน)
                          {/*   {SparePart.spare_part_no.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                        </label>
                      </>
                    )}


                  </div>
                </div>


                <div className="col-6 col-md-4 col-xl-2 mt-3 ">

                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;


                  <input type="checkbox"
                    id="is_pack"
                    disabled={disable}
                    className="form-check-input"
                    onChange={(event) => {
                      setbill({ ...bill, is_pack: event.currentTarget.checked });
                      bill.is_pack = event.currentTarget.checked;

                    }}
                    checked={bill.is_pack}
                  />
                  <label htmlFor="is_pack">
                    จัดเเล้ว
                  </label>
                </div>
               
              </div>

              <div className="row">
                {getOem() === currentOEM.value && currentOEM.label === "บจก.เอสที คอนกรีตโปรดักส์" ? (
                  <>
                    <div className="col-6 col-md-4 col-sm-3 col-xl-2">
                      <div class="form-group">

                        <input
                          //  compressive_strength: "",
                          placeholder="0"
                          type="text"
                          className="form-control"
                          required
                          value={bill.compressive}
                          disabled={true}
                          onChange={(e) => {
                            setbill({
                              ...bill,
                              compressive: e.target.value,
                            });
                          }}
                        />
                        <label>
                          แรงอัด
                          {/* {bill.compressive === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                        </label>{" "}
                      </div>
                    </div>
                    <div className="col-6 col-md-4 col-sm-3 col-xl-2">
                      <div class="form-group">
                        <input
                          placeholder="0.00"
                          // weight_meter
                          type="text"
                          className="form-control"
                          allowNegative={false}
                          decimalScale={2}
                          required
                          value={bill.meter_weight}
                          disabled={true}
                          onChange={(e) => {
                            setbill({
                              ...bill,
                              meter_weight: e.target.value,
                            });
                          }}
                        />
                        <label>
                          น้ำหนักจากเครื่องชั่ง
                          {/* {bill.meter_weight === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                        </label>{" "}
                      </div>
                    </div>
                    <div className="col-md-3  col-lg-2  col-xs-2 ">
                      <div className="form-group ">
                        <button
                          type="button"
                          disabled={disable}
                          className="btn btn-block btn-info  "
                          onClick={() => { UpdateFormexpree(bill.bill_no); }}
                        >
                          ดึงข้อมูลเครื่องชั่งรถขนปูน
                        </button>
                        <div className="col-12 col-md-12 col-xl-12">
                          <table class="center" style={{ border: "0px solid #ddd", position: "sticky", center: "0", "z-index": "1", height: "20px", width: "100%" }}>
                            <tr>
                              <th>
                                {/*  compressive:"",
                              meter_weight: "", */}
                                <h5> อัพเดดล่าสุด:{bill.weight_meter_updated} </h5>


                              </th>
                              {/* {billupdate.map((e) => {
                        //let dates = moment(e.weight_meter_updated).format('yyyy-MM-DD');
                          return (
                      <th style = {{ textAlign:"right",padding: "15px"}} > <h1 style= {{fontSize:"50px"}}>Last update: </h1></th> 
                      );
                    })} */}
                            </tr>
                          </table>
                        </div>
                      </div>

                    </div>


                  </>
                ) : (
                  <>

                  </>
                )}
              </div>


              <div className="row">
                <div className="col-6 col-md-4 col-xl-3">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={bill.latitude}
                      disabled={disable}
                      onChange={(e) => {
                        setbill({
                          ...bill,
                          latitude: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Latitude
                    </label>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-xl-3">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={bill.longitude}
                      disabled={disable}
                      onChange={(e) => {
                        setbill({
                          ...bill,
                          longitude: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Longtitude
                    </label>
                  </div>
                </div>

                <div className="col-4 col-md-2 col-xl-1">
                  <div class="form-group">
                    <button
                      //disabled={disable}
                      type="button"
                      data-toggle="modal"
                      data-target="#modal-map"
                      onClick={() => {
                         setMark(bill.latitude, bill.longitude);
                         setdefault_center({ ...default_center, lat: bill.latitude || 13.847860, lng: bill.longitude || 100.604274 });
                         }}
                      
                      class="btn btn-block btn-success "
                    >
                      Map Marker
                    </button>
                  </div>
                </div>
              </div>


              <div className="row">
                <div className="col-12 col-md-10 col-xl-8">
                  <div class="form-group">
                    <textarea
                      type="text"
                      className="form-control"
                      required
                      style={{ height: "100px", resize: "none" }}
                      value={bill.remark}
                      disabled={disable}
                      onChange={(e) => {
                        setbill({
                          ...bill,
                          remark: e.target.value,
                        });
                      }}
                    />
                    <label>
                      หมายเหตุ
                      {/* {SparePart.description === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
              </div>


            </div>

          </div>
        </section>
        <div className="modal fade" id="modal-map">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Map Marker {">>>"} End User</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  //onClick={set_bomlist_excel}
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 ">
                    <div className="none-nolmalinput">
                      <div style={{ height: "80vh", width: "100%" }}>

                        {/*        /// key: "AIzaSyA7yYhu2SMOYY11PLOGB4DgJD-BwNEDamY" */}
                        {default_center.lat !== "" && default_center.lat !== "" ? (
                          <GoogleMapReact
                            bootstrapURLKeys={{
                              key: "AIzaSyCIOsYyhFVUe39aftCUBTkk1IczCxGQxD8"
                            }}
                            disabled={true}
                            defaultCenter={{ lat: parseFloat(default_center.lat), lng: parseFloat(default_center.lng) }}
                            /*      defaultCenter={{lat:parseFloat(bill.latitude || '13.847860'),lng:parseFloat(bill.longitude || '100.604274')}} */
                            defaultZoom={8}
                            onClick={(e) => {
                              console.log("lat: ", e.lat, " lng :", e.lng);
                              if (e.lat !== bill.latitude && e.lng !== bill.longitude) {
                                if (disable === false) {
                                  setMark(e.lat, e.lng);
                                }

                              }

                              //setposition({...position,lat:e.lat,lng:e.lng});


                            }}

                          >
                            {location.map(item => {
                              if (item.address.length !== 0) {
                                return item.address.map(i => {
                                  return (
                                    <div key={i.id} lat={i.lat} lng={i.lng}>
                                      <img style={markerStyle} width={40} height={40} src={"https://img.icons8.com/dusk/452/region-code.png"} alt="pin" />
                                    </div>
                                  );
                                });
                              }
                            })

                            }

                          </GoogleMapReact>
                        ) : (
                          <></>
                        )}

                        
                      </div>

                    </div>
                  </div>
                </div>
                <br />


              </div>
              <div className="modal-footer justify-content-between">

                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>

              </div>
            </div>
          </div>
        </div>


      </div>
    </div>

  );
}

export default Wait_delivery_add;
