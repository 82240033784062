import { MDBDataTable } from "mdbreact";
import { userdefault_img } from "../../../../../routes/imgRoute/imgUrl";
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import Configs from "../../../../../config";
import axios from "axios";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../../../Utils/Common";

function AEV_Product_Kitting({ mode }) {
  const { id } = useParams();
  const [disable, setdisable] = useState(null);
  const [pageMode, setpageMode] = useState("");
  let [categoryData, setCategoryData] = useState([]);
  const [categoryLv1Data, setCategoryLv1Data] = useState([]);
  const [categoryLv2Data, setCategoryLv2Data] = useState([]);
  const [categoryLv3Data, setCategoryLv3Data] = useState([]);
  const [categoryLv4Data, setCategoryLv4Data] = useState([]);
  const [MDBT, setMDBT] = useState(true);

  const [tabData, setTabData] = useState([]);

  const [remark, setRemark] = useState({
    remark: "",
  });

  const [selectedCategory, setSelectedCategory] = useState({
    lv1: "",
    lv2: "",
    lv3: "",
    lv4: "",
    statusLv2: false,
    statusLv3: false,
    statusLv4: false,
  });

  async function getCategory() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/GetCategory",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setCategoryData(response.data);
        categoryData = response.data;

        setCategoryLv1Data(
          response.data.filter((item) => {
            return item.cat_level === "1";
          })
        );
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }
  const modalProductRef = useRef();
  const clickModalProductRef = async () => {
    modalProductRef.current.click();
  };
  const onClickOpenModalProduct = async () => {
    const AddProductTemp = AddProduct.data;
    const ProductTableTemp = Product.data;
    const tempList = ProductTableTemp.map((el) => {
      const check = AddProductTemp.find(
        (tl) => tl.product_id === el.product_id
      );
      if (check) {
        console.log("el", el);
        el.check = true;
        return el;
      } else {
        el.check = false;
        return el;
      }
    });
    setProduct({ ...Product, data: tempList });
    Product.data = tempList;
    setMDBT(true);
    clickModalProductRef();
  };

  async function getProductKitting() {
    let tempData = {
      pk_pc_id: id,
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product_kitting/getProductKitting",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        // setAddProduct(response.data);
        // AddProduct = response.data;
        if (response.data && response.data?.length > 0) {
          console.log("response.data", response.data);
          setRemark({ ...remark, remark: response.data[0].pk_remark || "" });
          remark.remark = response.data[0].pk_remark || "";
        }
        setAddProduct({
          data: response.data,
          // ...AddProduct,
          // product_id: response.data[0].product_id,
          // product_name: response.data[0].product_name,
          // product_name_eng: response.data[0].product_name_eng,
          // product_company: response.data[0].product_company,
          // product_category: response.data[0].product_category,
        });
        AddProduct.data = response.data;
        setMDBT(true);
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  // async function GetBasicData() {
  //   let tempData = {
  //     Product_Id: id,
  //     // company_id: getUser().com,
  //     // oem_id: getOem(),
  //     // user_id: getUser().fup,
  //   };

  //   await axios({
  //     method: "post",
  //     url: Configs.API_URL_cuteplus + "/api/product/GetBasicData",
  //     headers: {
  //       Authorization: "Bearer " + getToken(),
  //       "X-TTT": Configs.API_TTT,
  //       "Content-Type": "application/json",
  //     },
  //     data: tempData,
  //   })
  //     .then(async (response) => {
  //       let CategoryCheck = true;

  //       response.data.CategoryList.sort((a, b) => {
  //         return a.cat_level > b.cat_level ? 1 : -1;
  //       });

  //       setSelectedCategory({
  //         lv1: response.data.CategoryList[0]
  //           ? response.data.CategoryList[0].cat_level === "1"
  //             ? response.data.CategoryList[0].cat_id
  //             : ""
  //           : "",
  //         lv2: response.data.CategoryList[1]
  //           ? response.data.CategoryList[1].cat_level === "2"
  //             ? response.data.CategoryList[1].cat_id
  //             : ""
  //           : "",
  //         lv3: response.data.CategoryList[2]
  //           ? response.data.CategoryList[2].cat_level === "3"
  //             ? response.data.CategoryList[2].cat_id
  //             : ""
  //           : "",
  //         lv4: response.data.CategoryList[3]
  //           ? response.data.CategoryList[3].cat_level === "4"
  //             ? response.data.CategoryList[3].cat_id
  //             : ""
  //           : "",
  //         statusLv2: response.data.CategoryList[1] ? true : false,
  //         statusLv3: response.data.CategoryList[2] ? true : false,
  //         statusLv4: response.data.CategoryList[3] ? true : false,
  //       });

  //       if (response.data.CategoryList[0]) {
  //         if (response.data.CategoryList[0].cat_level !== "1") {
  //           CategoryCheck = false;
  //         }
  //       }

  //       if (response.data.CategoryList[1]) {
  //         if (response.data.CategoryList[1].cat_level !== "2") {
  //           CategoryCheck = false;
  //         }
  //       }

  //       if (response.data.CategoryList[2]) {
  //         if (response.data.CategoryList[2].cat_level !== "3") {
  //           CategoryCheck = false;
  //         }
  //       }

  //       if (response.data.CategoryList[3]) {
  //         if (response.data.CategoryList[3].cat_level !== "4") {
  //           CategoryCheck = false;
  //         }
  //       }

  //       setCategoryLv2Data(
  //         response.data.CategoryList[1]
  //           ? categoryData.filter((item) => {
  //               return (
  //                 item.cat_parent_id === response.data.CategoryList[0].cat_id
  //               );
  //             })
  //           : []
  //       );

  //       setCategoryLv3Data(
  //         response.data.CategoryList[2]
  //           ? categoryData.filter((item) => {
  //               return (
  //                 item.cat_parent_id === response.data.CategoryList[1].cat_id
  //               );
  //             })
  //           : []
  //       );

  //       setCategoryLv4Data(
  //         response.data.CategoryList[3]
  //           ? categoryData.filter((item) => {
  //               return (
  //                 item.cat_parent_id === response.data.CategoryList[2].cat_id
  //               );
  //             })
  //           : []
  //       );

  //       const BarcodeData = [];

  //       // for (let item of response.data.BarcodeList) {
  //       //   BarcodeData.push({
  //       //     id: item.pb_id,
  //       //     piece: item.pb_piece || "",
  //       //     pack: item.pb_pack || "",
  //       //     carton: item.pb_carton || "",
  //       //   });
  //       // }

  //       // setBarcodeInputList(BarcodeData);

  //       setTabData({
  //         ...tabData,
  //         Product_Company: response.data.BacisData.product_company || "",
  //         Product_Name_Eng: response.data.BacisData.product_name_eng || "",
  //         Ship_out_from_Country:
  //           response.data.BacisData.product_country_id || "",
  //         Loading_Port: response.data.BacisData.product_loading_port || "",
  //         Thailand_Port: response.data.BacisData.product_thailand_port || "",
  //         Brand: response.data.BacisData.product_brand_id || "",
  //         Category: CategoryCheck
  //           ? response.data.BacisData.product_category_id
  //           : "",
  //       });
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }

  const [AddProduct, setAddProduct] = useState({
    data: [],
  });
  const [Product, setProduct] = useState({
    data: [],
  });

  useEffect(async () => {
    // getKitting();
    if (mode === "view") {
      setdisable(true);
      setpageMode("View");
      await getKittingData();
    } else if (mode === "edit") {
      setdisable(false);
      setpageMode("Edit");
      await getKittingData();
    } else {
      setdisable(false);
      setpageMode("Add");
    }
    if (mode === "edit" || mode === "view") {
      // await GetBasicData();
      await getProductKitting();
    }
    await GetCategory();
    await GetProduct();
    setMDBT(true);
    // await getCategory();
  }, []);
  const rowsAddProduct = [];
  for (let index = 0; index < AddProduct.data.length; index++) {
    const rowItem = {};
    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;
    rowItem["product_code"] = AddProduct.data[index].product_code;
    rowItem["product_image"] = (
      <div style={{ display: "flex", justifyContent: "center", width: "70px" }}>
        <img
          id="img"
          alt="..."
          className="img-fluid rounded shadow border-0"
          src={
            AddProduct.data[index].image
              ? AddProduct.data[index].image.ul_path_file !== undefined &&
                AddProduct.data[index].image.ul_path_file !== null &&
                AddProduct.data[index].image.ul_path_file !== ""
                ? AddProduct.data[index].image.ul_path_file?.indexOf("blob") >=
                  0
                  ? AddProduct.data[index].image.ul_path_file
                  : Configs.API_URL_IMG_cuteplus +
                    `/static/${AddProduct.data[index].image.ul_file_name}/${AddProduct.data[index].image.ul_path_file}`
                : userdefault_img.imgs
              : userdefault_img.imgs
          }
          onError={(e) => {
            e.target.src = userdefault_img.imgs;
          }}
          style={{
            width: "70px",
            height: "70px",
            position: "relative",
          }}
        />
      </div>
    );
    rowItem["product_name"] = AddProduct.data[index].product_name_eng;
    rowItem["product_quantity"] = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <a>
          <NumberFormat
            type="text"
            className="form-control"
            // required
            decimalScale={0}
            allowNegative={false}
            disabled={disable}
            value={AddProduct.data[index].pk_amount}
            onChange={(e) => {
              // setAddProduct({
              //   ...AddProduct,
              //   product_amount: e.target.value,
              // });
              let temp = AddProduct.data;
              temp[index].pk_amount = e.target.value;
              setAddProduct({
                ...AddProduct,
                data: temp,
              });
              AddProduct.data = temp;
            }}
          />
        </a>
      </div>
    );
    rowItem["management"] = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <a
          onClick={() => {
            if (mode !== "view") {
              let temp = Product.data;
              let findindex = temp.findIndex(
                (e) => e.product_id === AddProduct.data[index].product_id
              );
              if (findindex >= 0) {
                temp[findindex].check = false;
              }
              AddProduct.data.splice(index, 1);
              settest({ ...test, test: "" });
              test.test = "";
            }
          }}
        >
          <i
            className={`fas fa-trash-alt ${mode === "view" ? "disabled" : ""}`}
            style={{
              color: "red",
              cursor: mode === "view" ? "not-allowed" : "pointer",
            }}
          ></i>
        </a>
      </div>
    );
    rowsAddProduct.push(rowItem);
  }
  const AddProductTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Product Code",
        field: "product_code",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Product Image",
        field: "product_image",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Product Name",
        field: "product_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Product Quantity",
        field: "product_quantity",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],
    rows: rowsAddProduct,
  };

  const [test, settest] = useState({
    test: "",
  });

  function saveProduct() {
    console.log(Product.data);
    let findchecktrue = Product.data.filter((e) => {
      return e.check === true;
    });
    console.log(findchecktrue);
    if (findchecktrue.length > 0) {
      setAddProduct({
        ...AddProduct,
        data: findchecktrue,
      });
    } else {
      setAddProduct({
        ...AddProduct,
        data: [],
      });
    }
  }

  function cancelSaveToTable() {
    // let oldproduct = Product.data;
    let oldaddproduct = AddProduct.data;
    console.log(oldaddproduct);
    for (let i of Product.data) {
      i.check = false;
    }
    for (let i2 of AddProduct.data) {
      for (let i3 of Product.data) {
        if (i2.product_id === i3.product_id) {
          i3.check = true;
        }
      }
    }
    settest({ ...test, test: "" });
    test.test = "";
  }

  const rowsData = [];
  for (let index = 0; index < Product.data.length; index += 1) {
    const rowItem = {};
    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["code"] = Product.data[index].product_code
      ? Product.data[index].product_code
      : "-";

    rowItem["image"] = (
      <div style={{ display: "flex", justifyContent: "center", width: "70px" }}>
        <img
          id="img"
          alt="..."
          className="img-fluid rounded shadow border-0"
          src={
            Product.data[index].image
              ? Product.data[index].image.ul_path_file !== undefined &&
                Product.data[index].image.ul_path_file !== null &&
                Product.data[index].image.ul_path_file !== ""
                ? Product.data[index].image.ul_path_file?.indexOf("blob") >= 0
                  ? Product.data[index].image.ul_path_file
                  : Configs.API_URL_IMG_cuteplus +
                    `/static/${Product.data[index].image.ul_file_name}/${Product.data[index].image.ul_path_file}`
                : userdefault_img.imgs
              : userdefault_img.imgs
          }
          onError={(e) => {
            e.target.src = userdefault_img.imgs;
          }}
          style={{
            width: "70px",
            height: "70px",
            position: "relative",
          }}
        />
      </div>
    );

    rowItem["name"] = Product.data[index].product_name_eng
      ? Product.data[index].product_name_eng
      : "-";

    rowItem["category"] = Product.data[index].cat_name
      ? Product.data[index].cat_name
      : "-";

    rowItem["management"] =
      Product.data[index].check === false ? (
        <div>
          <button
            className="btn btn-block btn-primary"
            // onClick={selectCertificate.bind(this, certificate_data_table_modal[index])}
            onClick={() => {
              let temp = Product.data;
              temp[index].check = true;
              setProduct({
                data: temp,
              });
            }}
          >
            เลือก
          </button>
        </div>
      ) : (
        <div>
          <button
            className="btn btn-block btn-danger"
            // onClick={deleteCertificate.bind(this, certificate_data_table_modal[index], "modal")}
            onClick={() => {
              let temp = Product.data;
              temp[index].check = false;
              setProduct({
                data: temp,
              });
            }}
          >
            ยกเลิก
          </button>
        </div>
      );

    rowsData.push(rowItem);
  }

  const ProductTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Product Code",
        field: "code",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Image",
        field: "image",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Product Name",
        field: "name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Category",
        field: "category",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };

  const [Filter, setFilter] = useState({
    company: "",
    name: "",
    category: "",
  });

  function clear() {
    let oldaddproduct = AddProduct.data;
    console.log(oldaddproduct);
    for (let i of Product.data) {
      i.check = false;
    }
    for (let i2 of AddProduct.data) {
      for (let i3 of Product.data) {
        if (i2.product_id === i3.product_id) {
          i3.check = true;
        }
      }
    }
    settest({ ...test, test: "" });
    test.test = "";
    setFilter({
      ...Filter,
      company: "",
      name: "",
      category: "",
    });
    Filter.company = "";
    Filter.name = "";
    Filter.category = "";
    GetProduct();
  }

  const [Category, setCategory] = useState({
    data: [],
  });

  const GetCategory = async () => {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/GetCategory",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(function (response) {
        setCategory({ data: response.data });
        Category.data = response.data;
        // console.log(response.data);
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const [ProductKitting, setProductKitting] = useState({
    data: [],
  });

  const GetProduct = async () => {
    let temp = {
      company: Filter.company.trim() || "",
      name: Filter.name.trim() || "",
      category: Filter.category.trim() || "",
      id: KittingDetail.product_id || "",
      company_id: getUser().com,
      oem_id: getOem(),
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product_kitting/getProduct",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then(async function (response) {
        // console.log(Product.data)
        // console.log(AddProduct.data)
        // console.log(KittingDetail.data)
        let temp = response.data;
        for (let i of temp) {
          i.check = false;

          let tempImage = {
            company_id: getUser().com,
            oem_id: getOem(),
            // user_id: getUser().fup,
            ul_project: "Cute_Products_Plus",
            ul_type: "ภาพ",
            ul_ref_id: i.product_id,
            // ul_menu_id: ,
            ul_is_profile: true,
            // ul_file_group: ,
          };

          await axios({
            method: "post",
            url: Configs.API_URL_IMG_cuteplus + "/api/upload/getDataFileOne",
            headers: {
              Authorization: "Bearer " + getToken(),
              "Content-Type": "application/json",
              "X-TTT": Configs.API_TTT,
            },
            data: tempImage,
          }).then(function (response) {
            i.image = response.data;
          });

          if (mode === "edit") {
            const check = AddProduct.data.find(
              (e) => e.pk_kitting_id == i.product_id
            );
            if (check) {
              i.check = true;
            }

            AddProduct.data.forEach((e) => {
              if (e.pk_kitting_id == i.product_id) e.image = i.image;
            });
          }
        }
        console.log("temp", temp);
        /* if (mode === "edit") {
          // console.log("el.check");
          temp = temp.map((el) => {
            // console.log(AddProduct.data);
            const check = AddProduct.data.find(
              (e) => e.pk_kitting_id == el.product_id
            );
            if (check) {
              el.check = true;
              // console.log(el.check);
            }
            return el;
          });
        } */
        setProduct({ data: temp });
        Product.data = temp;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  async function getKittingData() {
    let tempData = {
      product_id: id,
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus + "/api/product_kitting/detailproduct_kitting",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setKittingDetail({
          ...KittingDetail,
          product_id: response.data[0].product_id,
          product_code: response.data[0].product_code,
          product_name: response.data[0].product_name,
          product_name_eng: response.data[0].product_name_eng,
          product_barcode: response.data[0].pb_piece,
          pb_piece: response.data[0].pb_piece,
          product_company: response.data[0].product_company,
          product_cost: response.data[0].product_resale,
          product_quantity: response.data[0].product_quantity,
          product_category: response.data[0].product_category,
          // product_parent_id: response.data[0].product_parent_id,
          // ? new Date(response.data[0].product_exp_date)
          // : "",
          // product_level: response.data[0].product_level,
          // product_remark: response.data[0].product_remark,
          // File_Name: response.data[0].product_file_name,
          // File_Path: response.data[0].product_file_path,
        });
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function saveOption() {
    if (remark.remark) {
      remark.remark = remark.remark?.trim() || null;
    }
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const error_list = [];
        // console.log(AddProduct.data.length)
        if (AddProduct.data.length <= 0) {
          let temp_err = {
            message: "กรุณากรอกเพิ่ม Product",
          };
          error_list.push(temp_err);
        }

        if (error_list.length > 0) {
          var err_message = "";
          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }
          Swal.fire("Error", err_message, "error");
        } else {
          Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
              Swal.showLoading();
            },
          }).then(async () => {
            for (let item of AddProduct.data) {
              if (item.pk_amount) {
                item.pk_amount =
                  typeof item.pk_amount === "string"
                    ? parseFloat(item.pk_amount?.replace(/,/g, ""))
                    : item.pk_amount;
              }
            }
            let axiosData = {};
            let axiosData2 = {};
            const temp = {
              product_id: KittingDetail.product_id,
              product_code: KittingDetail.product_code || "",
              name: KittingDetail.product_name_eng || "",
              category_id: KittingDetail.category_id || "",
              barcode: KittingDetail.pb_piece || "",
              product_resale: KittingDetail.product_cost || "",
              pb_piece: KittingDetail.pb_piece || "",
              company_id: getUser().com,
              oem_id: getOem(),
              user_id: getUser().fup,
              arr: AddProduct.data,
              user_name: getUser().name + " " + getUser().surname,
            };
            const temp2 = {
              product_id: KittingDetail.product_id,
              company_id: getUser().com,
              oem_id: getOem(),
              user_id: getUser().fup,
              arr: AddProduct.data,
              pk_amount: AddProduct.pk_amount,
              remark: remark.remark,
              user_name: getUser().name + " " + getUser().surname,
            };

            if (mode === "add") {
              axiosData = {
                method: "post",
                url:
                  Configs.API_URL_cuteplus +
                  "/api/product_kitting/checkaddProduct",
                headers: {
                  Authorization: "Bearer " + getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: temp,
              };
            }

            if (mode === "edit") {
              axiosData = {
                method: "post",
                url:
                  Configs.API_URL_cuteplus +
                  "/api/product_Kitting/checkeditProduct",
                headers: {
                  Authorization: "Bearer " + getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: temp,
              };
            }

            await axios(axiosData)
              .then(
                async function (response) {
                  if (mode === "add") {
                    // if (from != "channel") {
                    axiosData = {
                      method: "post",
                      url:
                        Configs.API_URL_cuteplus +
                        "/api/product_kitting/addProduct",
                      headers: {
                        Authorization: "Bearer " + getToken(),
                        "X-TTT": Configs.API_TTT,
                        "Content-Type": "application/json",
                      },
                      data: temp,
                    };
                    await axios(axiosData);
                    axiosData2 = {
                      method: "post",
                      url:
                        Configs.API_URL_cuteplus +
                        "/api/product_kitting/addKitting",
                      headers: {
                        Authorization: "Bearer " + getToken(),
                        "X-TTT": Configs.API_TTT,
                        "Content-Type": "application/json",
                      },
                      data: temp2,
                    };
                  }

                  if (mode === "edit") {
                    axiosData = {
                      method: "post",
                      url:
                        Configs.API_URL_cuteplus +
                        "/api/product_kitting/editProduct",
                      headers: {
                        Authorization: "Bearer " + getToken(),
                        "X-TTT": Configs.API_TTT,
                        "Content-Type": "application/json",
                      },
                      data: temp,
                    };
                    await axios(axiosData);
                    axiosData2 = {
                      method: "post",
                      url:
                        Configs.API_URL_cuteplus +
                        "/api/product_kitting/addKitting",
                      headers: {
                        Authorization: "Bearer " + getToken(),
                        "X-TTT": Configs.API_TTT,
                        "Content-Type": "application/json",
                      },
                      data: temp2,
                    };
                  }

                  await axios(axiosData2)
                    .then(function (response) {
                      Swal.fire({
                        icon: "success",
                        title: "บันทึกข้อมูลสำเร็จ",
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        timer: 2000,
                      }).then(() => {
                        window.location.href =
                          "/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Kitting_CutePlus";

                        // if (from === "channel") {
                        //   window.location.href =
                        //     "/Warehouse_Management/Factory_Master_Data_Cuteplus/Modern_Trade_Department_Store/channel";
                        // } else {
                        //   window.location.href =
                        //     "/Warehouse_Management/Factory_Master_Data_Cuteplus/Modern_Trade_Department_Store";
                        // }
                      });
                    })
                    .catch(function (error) {
                      Swal.fire({
                        title: "Error",
                        text: error.response.data.error.message,
                        icon: "error",
                      });
                    });
                }
                /* } */
              )
              .catch(function (error) {
                Swal.fire({
                  title: "Error",
                  text: error.response.data.error.message,
                  icon: "error",
                });
              });
          });
        }
      }
    });
  }

  function cancleOption() {
    if (mode === "add" || mode === "edit") {
      Swal.fire({
        title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `ใช่`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `ไม่`,
      }).then((result) => {
        if (result.isConfirmed) {
          // if (from == "channel") {
          window.location.href =
            "/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Kitting_CutePlus";
          // } else {
          //   window.location.href =
          //     "/Warehouse_Management/Factory_Master_Data_Cuteplus/Modern_Trade_Department_Store";
          // }
        }
      });
    } else {
      // if (from == "channel") {
      window.location.href =
        "/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Kitting_CutePlus";
      // } else {
      //   window.location.href =
      //     "/Warehouse_Management/Factory_Master_Data_Cuteplus/Modern_Trade_Department_Store";
      // }
    }
  }

  const [KittingDetail, setKittingDetail] = useState({
    // company: "",
    // name: "",
    // category: "",
    // category_id: "",
    // barcode: "",
    // cost: ""
  });

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-4 col-md-4 col-xl-6">
                <h1>{pageMode} Product Kitting</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Product Kitting</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              {mode === "edit" || mode === "add" ? (
                <>
                  <div className="col-12 col-md-4 col-xl-2">
                    <button
                      type="button"
                      className="btn btn-block btn-success"
                      onClick={async () => {
                        await saveOption();
                      }}
                    >
                      บันทึก
                    </button>
                  </div>
                  <div className="col-12 col-md-4 col-xl-2">
                    <button
                      type="button"
                      className="btn btn-block btn-danger"
                      onClick={async () => {
                        cancleOption();
                      }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-12 col-md-4 col-xl-2">
                    <button
                      type="button"
                      className="btn btn-block btn-danger"
                      onClick={async () => {
                        cancleOption();
                      }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-6">
                <label
                  style={{
                    fontSize: "24px",
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  Product Code : {KittingDetail.product_code}
                </label>
              </div>
              <div className="col-12 col-md-6">
                <label
                  style={{
                    fontSize: "24px",
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  Product Name : {KittingDetail.product_name_eng}
                </label>
              </div>
            </div>
          </div>
        </section>
        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Product Kitting {pageMode}</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <label
                          style={{
                            fontSize: "24px",
                            lineHeight: "normal",
                            wordBreak: "break-all",
                          }}
                        >
                          Product Kitting Detail
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-6 col-xl-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={true}
                            value={KittingDetail.product_code}
                            onChange={(e) => {
                              setKittingDetail({
                                ...KittingDetail,
                                product_code: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Product Code
                            {KittingDetail.product_code === "" ||
                            KittingDetail.product_code === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-6 col-md-6 col-xl-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={true}
                            value={KittingDetail.pb_piece}
                            onChange={(e) => {
                              setKittingDetail({
                                ...KittingDetail,
                                pb_piece: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Barcode
                            {KittingDetail.pb_piece === "" ||
                            KittingDetail.pb_piece === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-6 col-md-6 col-xl-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={true}
                            value={KittingDetail.product_name_eng}
                            onChange={(e) => {
                              setKittingDetail({
                                ...KittingDetail,
                                product_name_eng: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Product Name
                            {KittingDetail.product_name_eng === "" ||
                            KittingDetail.product_name_eng === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-6 col-md-6 col-xl-6">
                        <div className="form-group">
                          {/* <input
                            type="text"
                            className="form-control"
                            required
                            decimalScale={0}
                            allowNegative={false}
                            disabled={disable}
                            value={KittingDetail.product_cost}
                            onChange={(e) => {
                              setKittingDetail({
                                ...KittingDetail,
                                product_cost: e.target.value,
                              });
                            }}
                          /> */}
                          <NumberFormat
                            type="text"
                            className="form-control"
                            required
                            decimalScale={0}
                            allowNegative={false}
                            thousandSeparator={true}
                            disabled={true}
                            value={KittingDetail.product_cost}
                            onChange={(e) => {
                              setKittingDetail({
                                ...KittingDetail,
                                product_cost: e.target.value.replace(/,/g, ""), // Remove thousand separators before updating state
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Retail Price
                            {KittingDetail.product_cost === "" ||
                            KittingDetail.product_cost === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-12">
                        <label
                          style={{
                            fontSize: "24px",
                            lineHeight: "normal",
                            wordBreak: "break-all",
                          }}
                        >
                          Category
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-3">
                        <div class="form-group">
                          <select
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={selectedCategory.lv1}
                            onChange={(e) => {
                              let lv2Data = categoryData.filter((item) => {
                                return item.cat_parent_id === e.target.value;
                              });

                              if (lv2Data.length > 0) {
                                setCategoryLv2Data(lv2Data);

                                setSelectedCategory({
                                  ...selectedCategory,
                                  lv1: e.target.value,
                                  lv2: "",
                                  lv3: "",
                                  lv4: "",
                                  statusLv2: true,
                                  statusLv3: false,
                                  statusLv4: false,
                                });

                                const selectedOption =
                                  e.target.options[e.target.selectedIndex];
                                const cat_name =
                                  selectedOption.getAttribute("data-cat-name");

                                setKittingDetail({
                                  ...KittingDetail,
                                  category: cat_name,
                                  category_id: e.target.value,
                                });

                                setTabData({
                                  ...tabData,
                                  Category: "",
                                });
                              } else {
                                setCategoryLv2Data([]);

                                setSelectedCategory({
                                  ...selectedCategory,
                                  lv1: e.target.value,
                                  lv2: "",
                                  lv3: "",
                                  lv4: "",
                                  statusLv2: false,
                                  statusLv3: false,
                                  statusLv4: false,
                                });

                                const selectedOption =
                                  e.target.options[e.target.selectedIndex];
                                const cat_name =
                                  selectedOption.getAttribute("data-cat-name");

                                setKittingDetail({
                                  ...KittingDetail,
                                  category: cat_name,
                                  category_id: e.target.value,
                                });

                                setTabData({
                                  ...tabData,
                                  Category: e.target.value,
                                });
                              }
                            }}
                          >
                            {mode === "add" ? (
                              <option value="" disabled selected>
                                --- กรุณาเลือก ---
                              </option>
                            ) : (
                              <option
                                value={KittingDetail.cat_name}
                                disabled
                                selected
                              ></option>
                            )}
                            {categoryLv1Data.map((e) => {
                              return (
                                <option
                                  value={e.cat_id}
                                  data-cat-name={e.cat_name}
                                >
                                  {e.cat_name}
                                </option>
                              );
                            })}
                          </select>
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Lv. 1
                            {selectedCategory.lv1 === "" ||
                            selectedCategory.lv1 === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>

                      {selectedCategory.statusLv2 ? (
                        <div className="col-12 col-md-3">
                          <div class="form-group">
                            <select
                              type="text"
                              className="form-control"
                              required
                              disabled={disable}
                              value={selectedCategory.lv2}
                              onChange={(e) => {
                                let lv3Data = categoryData.filter((item) => {
                                  return item.cat_parent_id === e.target.value;
                                });

                                if (lv3Data.length > 0) {
                                  setCategoryLv3Data(lv3Data);

                                  setSelectedCategory({
                                    ...selectedCategory,
                                    lv2: e.target.value,
                                    lv3: "",
                                    lv4: "",
                                    statusLv3: true,
                                    statusLv4: false,
                                  });

                                  const selectedOption =
                                    e.target.options[e.target.selectedIndex];
                                  const cat_name =
                                    selectedOption.getAttribute(
                                      "data-cat-name"
                                    );

                                  setKittingDetail({
                                    ...KittingDetail,
                                    category: cat_name,
                                    category_id: e.target.value,
                                  });

                                  setTabData({
                                    ...tabData,
                                    Category: "",
                                  });
                                } else {
                                  setCategoryLv3Data([]);

                                  setSelectedCategory({
                                    ...selectedCategory,
                                    lv2: e.target.value,
                                    lv3: "",
                                    lv4: "",
                                    statusLv3: false,
                                    statusLv4: false,
                                  });

                                  const selectedOption =
                                    e.target.options[e.target.selectedIndex];
                                  const cat_name =
                                    selectedOption.getAttribute(
                                      "data-cat-name"
                                    );

                                  setKittingDetail({
                                    ...KittingDetail,
                                    category: cat_name,
                                    category_id: e.target.value,
                                  });

                                  setTabData({
                                    ...tabData,
                                    Category: e.target.value,
                                  });
                                }
                              }}
                            >
                              <option value="" disabled selected>
                                --- กรุณาเลือก ---
                              </option>
                              {categoryLv2Data.map((e) => {
                                return (
                                  <option
                                    value={e.cat_id}
                                    data-cat-name={e.cat_name}
                                  >
                                    {e.cat_name}
                                  </option>
                                );
                              })}
                            </select>
                            <label
                              style={{
                                lineHeight: "normal",
                                wordBreak: "break-all",
                              }}
                            >
                              Lv. 2
                              {selectedCategory.lv2 === "" ||
                              selectedCategory.lv2 === null ? (
                                <span style={{ color: "red" }}> *</span>
                              ) : (
                                ""
                              )}
                            </label>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {selectedCategory.statusLv3 ? (
                        <div className="col-12 col-md-3">
                          <div class="form-group">
                            <select
                              type="text"
                              className="form-control"
                              required
                              disabled={disable}
                              value={selectedCategory.lv3}
                              onChange={(e) => {
                                let lv4Data = categoryData.filter((item) => {
                                  return item.cat_parent_id === e.target.value;
                                });

                                if (lv4Data.length > 0) {
                                  setCategoryLv4Data(lv4Data);

                                  setSelectedCategory({
                                    ...selectedCategory,
                                    lv3: e.target.value,
                                    lv4: "",
                                    statusLv4: true,
                                  });

                                  const selectedOption =
                                    e.target.options[e.target.selectedIndex];
                                  const cat_name =
                                    selectedOption.getAttribute(
                                      "data-cat-name"
                                    );

                                  setKittingDetail({
                                    ...KittingDetail,
                                    category: cat_name,
                                    category_id: e.target.value,
                                  });

                                  setTabData({
                                    ...tabData,
                                    Category: "",
                                  });
                                } else {
                                  setCategoryLv4Data([]);

                                  setSelectedCategory({
                                    ...selectedCategory,
                                    lv3: e.target.value,
                                    lv4: "",
                                    statusLv4: false,
                                  });

                                  const selectedOption =
                                    e.target.options[e.target.selectedIndex];
                                  const cat_name =
                                    selectedOption.getAttribute(
                                      "data-cat-name"
                                    );

                                  setKittingDetail({
                                    ...KittingDetail,
                                    category: cat_name,
                                    category_id: e.target.value,
                                  });

                                  setTabData({
                                    ...tabData,
                                    Category: e.target.value,
                                  });
                                }
                              }}
                            >
                              <option value="" disabled selected>
                                --- กรุณาเลือก ---
                              </option>
                              {categoryLv3Data.map((e) => {
                                return (
                                  <option
                                    value={e.cat_id}
                                    data-cat-name={e.cat_name}
                                  >
                                    {e.cat_name}
                                  </option>
                                );
                              })}
                            </select>
                            <label
                              style={{
                                lineHeight: "normal",
                                wordBreak: "break-all",
                              }}
                            >
                              Lv. 3
                              {selectedCategory.lv3 === "" ||
                              selectedCategory.lv3 === null ? (
                                <span style={{ color: "red" }}> *</span>
                              ) : (
                                ""
                              )}
                            </label>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {selectedCategory.statusLv4 ? (
                        <div className="col-12 col-md-3">
                          <div class="form-group">
                            <select
                              type="text"
                              className="form-control"
                              required
                              disabled={disable}
                              value={selectedCategory.lv4}
                              onChange={(e) => {
                                setSelectedCategory({
                                  ...selectedCategory,
                                  lv4: e.target.value,
                                });

                                setTabData({
                                  ...tabData,
                                  Category: e.target.value,
                                });
                              }}
                            >
                              <option value="" disabled selected>
                                --- กรุณาเลือก ---
                              </option>
                              {categoryLv4Data.map((e) => {
                                return (
                                  <option
                                    value={e.cat_id}
                                    data-cat-name={e.cat_name}
                                  >
                                    {e.cat_name}
                                  </option>
                                );
                              })}
                            </select>
                            <label
                              style={{
                                lineHeight: "normal",
                                wordBreak: "break-all",
                              }}
                            >
                              Lv. 4
                              {selectedCategory.lv4 === "" ||
                              selectedCategory.lv4 === null ? (
                                <span style={{ color: "red" }}> *</span>
                              ) : (
                                ""
                              )}
                            </label>
                          </div>
                        </div> */}
                    {/* ) : (
                        <></>
                      )} */}
                    {/* </div> */}
                    <div className="row">
                      <div className="col-12">
                        <label
                          style={{
                            fontSize: "24px",
                            lineHeight: "normal",
                            wordBreak: "break-all",
                          }}
                        >
                          Add Product
                          {AddProduct.data.length <= 0 ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-6 col-md-3 col-xl-2">
                        {/* <button
                          type="button"
                          class="btn btn-block btn-primary"
                          data-toggle="modal"
                          data-target="#modalCertificate"
                          disabled={disable}
                          onClick={async () => {
                            await onClickOpenModalProduct();
                          }}
                        >
                          Add
                        </button> */}
                        <button
                          hidden={true}
                          data-toggle="modal"
                          data-target="#modalCertificate"
                          ref={modalProductRef}
                        />
                        <button
                          disabled={mode === "view" ? true : false}
                          type="button"
                          class="btn btn-block btn-primary"
                          onClick={async () => {
                            await onClickOpenModalProduct();
                            // await GetProduct();
                          }}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <div
                          className="table-responsive"
                          style={{ whiteSpace: "nowrap" }} //, whiteSpace: 'nowrap'
                        >
                          <MDBDataTable
                            sortable={false}
                            striped
                            disableRetreatAfterSorting={MDBT}
                            bordered
                            hover
                            data={AddProductTable}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-12 col-xl-10">
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            rows={2}
                            disabled={disable}
                            style={{
                              height: "100px",
                              fontSize: "25px",
                            }}
                            value={remark.remark}
                            onChange={(e) => {
                              setRemark({
                                ...remark,
                                remark: e.target.value,
                              });
                              remark.remark = e.target.value;
                            }}
                            defaultValue=""
                            required
                          />
                          <label>Remark</label>
                        </div>
                      </div>
                    </div>
                    {/* <div className="row mb-3">
                <div className="col-md-12">
                    <button
                        type="button"
                        className="btn btn-block btn-success"
                        onClick={() => {
                            saveOption()
                        }}
                    >
                        บันทึก
                    </button>
                </div>
            </div> */}

                    {/* Modal Certificate */}
                    <div
                      className="modal fade"
                      id="modalCertificate"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="modalCertificateLabel"
                      aria-hidden="true"
                      data-backdrop="static"
                    >
                      <div
                        className="modal-dialog modal-xl"
                        style={{ width: "70%" }}
                        role="document"
                      >
                        <div className="modal-content">
                          {/* Header */}
                          <div className="modal-header">
                            <h4
                              className="modal-title"
                              id="modalCertificateLabel"
                            >
                              <b>Add Product</b>
                            </h4>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span
                                aria-hidden="true"
                                onClick={() => {
                                  // cancelSaveToTable();
                                }}
                              >
                                &times;
                              </span>
                            </button>
                          </div>
                          {/* Body */}
                          <div className="modal-body">
                            <div className="row">
                              <div className="col-12 col-md-4 col-xl-2">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    required
                                    value={Filter.company}
                                    onChange={(e) => {
                                      setFilter({
                                        ...Filter,
                                        company: e.target.value,
                                      });
                                      Filter.company = e.target.value;
                                    }}
                                  />
                                  <label>Product Company</label>
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-2">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    required
                                    value={Filter.name}
                                    onChange={(e) => {
                                      setFilter({
                                        ...Filter,
                                        name: e.target.value,
                                      });
                                      Filter.name = e.target.value;
                                    }}
                                  />
                                  <label>Product Name</label>
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-2">
                                <div class="form-group">
                                  <select
                                    type="text"
                                    className="form-control"
                                    required
                                    value={Filter.category}
                                    onChange={(e) => {
                                      setFilter({
                                        ...Filter,
                                        category: e.target.value,
                                      });
                                      Filter.category = e.target.value;
                                    }}
                                  >
                                    <option key="" value="">
                                      Select a category
                                    </option>
                                    {Category.data.map((option, index) => (
                                      <option
                                        key={Category.data[index].cat_name}
                                        value={Category.data[index].cat_id}
                                      >
                                        {Category.data[index].cat_name}
                                      </option>
                                    ))}
                                  </select>
                                  <label>Category</label>
                                </div>
                              </div>
                              <div className="col-6 col-md-4 col-xl-2">
                                <div className="form-group ">
                                  <button
                                    type="button"
                                    className="btn btn-block btn-info"
                                    onClick={() => {
                                      GetProduct();
                                    }}
                                  >
                                    Search
                                  </button>
                                </div>
                              </div>
                              <div className="col-6 col-md-4 col-xl-2">
                                <div className="form-group ">
                                  <button
                                    type="button"
                                    className="btn btn-block btn-info"
                                    onClick={() => {
                                      clear();
                                    }}
                                  >
                                    Clear
                                  </button>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div
                                  className="table-responsive"
                                  style={{
                                    height: "450px",
                                    whiteSpace: "nowrap",
                                  }} //, whiteSpace: 'nowrap'
                                >
                                  <MDBDataTable
                                    sortable={false}
                                    striped
                                    bordered
                                    disableRetreatAfterSorting={MDBT}
                                    hover
                                    data={ProductTable}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Footer */}
                          <div
                            className="modal-footer"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <button
                              type="button"
                              className="btn btn-danger"
                              data-dismiss="modal"
                              //   ref={closeModalCertificate}
                              onClick={() => {
                                // if (fromSave.fromSave) {
                                // cancelSaveToTable();
                                // }
                              }}
                            >
                              ยกเลิก
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              data-dismiss="modal"
                              onClick={() => {
                                saveProduct();
                              }}
                            >
                              บันทึก
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AEV_Product_Kitting;
