import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getOem, getToken, getUser } from "../../Utils/Common";
import Configs from "../../config";
import axios from "axios";
import Swal from "sweetalert2";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";

function Config_UNAI_AEV({ mode }) {
  const { id } = useParams();
  // console.log("id", id);

  const [modalStatus, setModalStatus] = useState({
    status_1: false,
    mode_1: "Add",
    status_2: false,
  });

  const [getUnitData, setGetUnitData] = useState({
    sc_name: "",
  });

  const [warehouseData, setWarehouseData] = useState({
    dropdown: [],
  });
  const [getTableUnaiData, setGetTableUnaiData] = useState({
    data: [],
  });

  const [settingPage, setSettingPage] = useState({
    disableInputMain: false,
    disableInputModal: false,
  });

  const [configListData, setConfigListData] = useState({
    data: {
      main_data: [],
      sub_list: [],
    },
    remove: [],
  });

  const [configMainData, setConfigMainData] = useState({
    warehouse_id: "",
    remark: "",
    floor_id: "",
    username: "".trim(),
    password: "".trim(),
  });
  // console.log("configMainDataState", configMainData);
  const [checkConfigMainData, setCheckConfigMainData] = useState({
    wh_depth: "",
    wh_heigth: "",
    wh_width: "",
  });
  // console.log("configMainData", configMainData);

  const [configSubData, setConfigSubData] = useState({
    fu_id: "",
    fu_no: "",
    fu_name: "",
    fu_pos_x: "",
    fu_pos_z: "",
    fu_width: "",
    fu_height: "",
    fu_length: "",
    fu_cun_id: "",
    is_new: true,
    is_status: "add",
  });

  function handleConfigState(setState, state, keyState, value) {
    setState({
      ...state,
      [keyState]: value,
    });
    state[keyState] = value;
  }

  async function ConfirmSubmitModal(index) {
    const error_list = [];

    const checkWNo = await getCheckDataModal();
    if (checkWNo.length > 0) {
      error_list.push(checkWNo[0]?.trim().toLowerCase());
    }

    if (!configSubData.fu_no?.trim()) {
      error_list.push("กรุณากรอก Forklift No.");
    }

    if (!configSubData.fu_name?.trim()) {
      error_list.push("กรุณากรอก Forklift Name.");
    }

    // if (!configSubData.fu_pos_x?.trim()) {
    //   error_list.push("กรุณากรอก Position X");
    // }

    // if (!configSubData.fu_pos_z?.trim()) {
    //   error_list.push("กรุณากรอก Position Z");
    // }

    if (!configSubData.fu_width?.trim()) {
      error_list.push("กรุณากรอก Width");
    }

    if (!configSubData.fu_height?.trim()) {
      error_list.push("กรุณากรอก Height");
    }

    if (!configSubData.fu_length?.trim()) {
      error_list.push("กรุณากรอก Depth");
    }

    for (var i = 0; i < configListData.data.sub_list.length; i++) {
      if (
        configListData.data.sub_list[i].fu_no?.trim().toLowerCase() ===
          configSubData.fu_no?.trim().toLowerCase() &&
        (modalStatus.mode_1 === "Add"
          ? true
          : configSubData.fu_id !== configListData.data.sub_list[i].fu_id)
      ) {
        error_list.push(
          "No และ Name นี้ซ้ำ" +
            " " +
            configListData.data.sub_list[i].fu_no?.trim() +
            " " +
            configListData.data.sub_list[i].fu_name?.trim()
        );
      }
    }
    // console.log('checkConfigMainData', checkConfigMainData)
    // console.log('configSubData', configSubData)
    if (
      configSubData.fu_length?.trim().toLowerCase() >
        checkConfigMainData.wh_depth &&
      configSubData.fu_width?.trim().toLowerCase() >
        checkConfigMainData.wh_width &&
      configSubData.fu_height?.trim().toLowerCase() >
        checkConfigMainData.wh_heigth
    ) {
      error_list.push("Forklift มีขนาดใหญ่กว่า Warehouse ของคุณ");
    }

    if (error_list.length > 0) {
      var err_message = "";

      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e];
      }

      Swal.fire("Error", err_message, "error");
      return;
    } else {
      const temp_list = JSON.parse(JSON.stringify(configListData));

      if (!configSubData.fu_id) {
        // console.log("temp_list.data",temp_list.data.sub_list);
        const temp = JSON.parse(JSON.stringify(configSubData));
        const keyId = "id" + (Number(temp_list.data.sub_list.length) + 1);
        temp.is_status = "add";
        temp.fu_id = keyId;
        temp_list.data.sub_list.push({ ...temp });
      } else if (configSubData.fu_id) {
        // console.log('first')
        // console.log("111111",temp_list);
        const temp = JSON.parse(JSON.stringify(configSubData));
        const index_data = temp_list.data.sub_list.findIndex((item) => {
          return item.fu_id == temp.fu_id;
        });
        // console.log('index_data',index_data)
        if (index_data >= 0) {
          temp_list.data.sub_list[index_data] = temp;
          temp_list.data.sub_list[index_data].is_status = "update";
        }
      }

      setConfigListData({
        ...configListData,
        data: temp_list.data,
      });
      configListData.data = temp_list.data;

      setModalStatus({
        ...modalStatus,
        status_1: false,
      });
      modalStatus.status_1 = false;
    }
  }

  async function setUpDataModal(index) {
    const temp_data = JSON.parse(
      JSON.stringify(configListData.data.sub_list[index])
    );

    setConfigSubData({
      ...configSubData,
      fu_id: temp_data.fu_id,
      fu_cun_id: temp_data.fu_cun_id,
      fu_no: temp_data.fu_no,
      fu_name: temp_data.fu_name,
      fu_pos_x: temp_data.fu_pos_x,
      fu_pos_z: temp_data.fu_pos_z,
      fu_width: temp_data.fu_width,
      fu_height: temp_data.fu_height,
      fu_length: temp_data.fu_length,
      is_new: temp_data.is_new,
      // is_status: temp_data.fl_id ? "update" : "add",
    });
    configSubData.fu_id = temp_data.fu_id;
    configSubData.fu_cun_id = temp_data.fu_cun_id;
    configSubData.fu_no = temp_data.fu_no;
    configSubData.fu_name = temp_data.fu_name;
    configSubData.fu_pos_x = temp_data.fu_pos_x;
    configSubData.fu_pos_z = temp_data.fu_pos_z;
    configSubData.fu_width = temp_data.fu_width;
    configSubData.fu_height = temp_data.fu_height;
    configSubData.fu_length = temp_data.fu_length;
    configSubData.is_new = temp_data.is_new;
    // configSubData.is_status = temp_data.fl_id ? "update" : "add";
  }

  async function deleteDataList(index) {
    const temp_list = JSON.parse(JSON.stringify(configListData.data));
    const remove_list = JSON.parse(JSON.stringify(configListData.remove));

    // console.log('temp_list1414', temp_list)
    if (temp_list.sub_list[index].is_status === "add") {
      temp_list.sub_list.splice(index, 1);
      // console.log('temp_list.sub_list.splice(index, 1)',temp_list.sub_list.splice(index, 1))
    } else {
      remove_list.push({ ...temp_list.sub_list[index] });
      handleConfigState(
        setConfigListData,
        configListData,
        "remove",
        remove_list
      );
      temp_list.sub_list.splice(index, 1);
      // console.log('configListData1212', configListData)
    }
    setConfigListData({
      ...configListData,
      data: temp_list,
    });
    configListData.data = temp_list;
  }

  async function getTableForklift() {
    const send_data = {
      cun_id: id,
      cun_company_id: getUser().com,
      cun_oem_id: getOem(),
    };
    // console.log("send_data", send_data);

    await axios({
      method: "post",
      url: `${Configs.API_URL_AIOI_Back}/api/configUnai/getTableForklift`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        if (response_data.data.data) {
          // console.log('response_data.data.data', response_data.data.data)
          handleConfigState(
            setConfigMainData,
            configMainData,
            "warehouse_id",
            mode == "Add"
              ? null
              : response_data.data.data.main_data[0].cun_wh_id
          );
          handleConfigState(
            setConfigMainData,
            configMainData,
            "remark",
            mode == "Add"
              ? null
              : response_data.data.data.main_data[0].cun_wh_remark
          );
          handleConfigState(
            setConfigMainData,
            configMainData,
            "floor_id",
            mode == "Add"
              ? ""
              : response_data.data.data.main_data[0].cun_floor_id || ""
          );
          handleConfigState(
            setConfigMainData,
            configMainData,
            "username",
            mode == "Add"
              ? ""
              : response_data.data.data.main_data[0].cun_username || ""
          );
          handleConfigState(
            setConfigMainData,
            configMainData,
            "password",
            mode == "Add"
              ? ""
              : response_data.data.data.main_data[0].cun_password || ""
          );
          // console.log('response_data.data',response_data.data.data)
          const temp_sub = response_data.data.data.sub_list;
          const sub_list = [];
          for (let item of temp_sub) {
            sub_list.push({
              fu_cun_id: item.fu_cun_id,
              fu_no: item.fu_no,
              fu_name: item.fu_name,
              fu_pos_x: item.fu_pos_x,
              fu_pos_z: item.fu_pos_z,
              fu_width: item.fu_width,
              fu_length: item.fu_length,
              fu_height: item.fu_height,
              fu_wh_id: item.fu_wh_id,
              fu_id: item.fu_id || null,
              is_new: false,
              is_status: "update",
            });
          }

          setConfigListData({
            ...configListData,
            data: {
              main_data: response_data.data.data.main_data,
              sub_list: sub_list,
            },
          });
          configListData.data.main_data = response_data.data.main_data;
          configListData.data.sub_list = sub_list;
          await GetWarehouseForDropDown(
            mode == "Add"
              ? null
              : response_data.data.data.main_data[0].cun_wh_id
          );
        }
        // console.log('configListData77777', configListData);
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function getUnit() {
    // console.log(temp);
    await axios({
      method: "post",
      url: Configs.API_URL_AIOI_Back + "/api/configUnai/getUnit",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {
        if (response.data.data && response.data.data.length > 0) {
          setGetUnitData({
            ...getUnitData,
            sc_name: response.data.data[0].sc_name,
          });
          // console.log("ออกไหม",response.data.data[0].sc_name);
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  async function getCheckDataModal() {
    const temp = {
      fu_no: configSubData.fu_no?.trim(),
    };
    // console.log(temp);
    return await axios({
      method: "post",
      url: Configs.API_URL_AIOI_Back + "/api/configUnai/getCheckDataModal",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        const error_list = [];
        if (response.data.data.is_err === true) {
          error_list.push("Forklift ซ้ำในระบบ");
        }
        return error_list;

        // if (response.data.data && response.data.data.length > 0) {
        //   setGetUnitData({
        //     ...getUnitData,
        //     sc_name: response.data.data[0].sc_name,
        //   });
        //   // console.log("ออกไหม",response.data.data[0].sc_name);
        // }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  async function GetWarehouseForDropDown(wh_id) {
    const send_data = {
      cun_wh_id: wh_id,
      company_id: getUser().com,
      oem_id: getOem(),
    };
    // console.log('send_data111', send_data)
    await axios({
      method: "post",
      url: `${Configs.API_URL_AIOI_Back}/api/configUnai/getWarehouseForDropDown`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        // console.log('response_data', response_data)
        setWarehouseData({
          dropdown: response_data.data.data,
        });
        warehouseData.dropdown = response_data.data.data;
        const checkwid = response_data.data.data?.find((e) => {
          return e.value == configMainData.warehouse_id;
        });
        setCheckConfigMainData({
          wh_depth: checkwid.wh_depth || null,
          wh_heigth: checkwid.wh_height || null,
          wh_width: checkwid.wh_width || null,
        });
        checkConfigMainData.wh_depth = checkwid.wh_depth || null;
        checkConfigMainData.wh_heigth = checkwid.wh_height || null;
        checkConfigMainData.wh_width = checkwid.wh_width || null;
        // console.log("checkConfigMainData", checkConfigMainData);
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function saveConfigUnai() {
    const error_list = [];

    if (
      configMainData.warehouse_id === null ||
      configMainData.warehouse_id.trim() === ""
    ) {
      let temp_err = {
        message: "กรุณาเลือก Warehouse",
      };
      error_list.push(temp_err);
    }

    if (configListData.data.sub_list.length === 0) {
      let temp_err = {
        message: "กรุณาเพิ่ม forklift อย่างน้อย 1 คัน ",
      };
      error_list.push(temp_err);
    }
    if (
      configMainData.floor_id.trim() !== "" ||
      configMainData.username.trim() !== "" ||
      configMainData.password.trim() !== ""
    ) {
      if (configMainData.floor_id === "") {
        let temp_err = {
          message: "กรุณากรอก Floor ID",
        };
        error_list.push(temp_err);
      }
      if (configMainData.username === "") {
        let temp_err = {
          message: "กรุณากรอก Username",
        };
        error_list.push(temp_err);
      }
      if (configMainData.password === "") {
        let temp_err = {
          message: "กรุณากรอก Password",
        };
        error_list.push(temp_err);
      }
    } else {
      // for (let i = 0; i < configListData.data.sub_list.length; i++) {
      //   const check1 = configListData.data.sub_list[i];
      //   const check2 =
      //     configListData.data.sub_list[i + 1] !== undefined
      //       ? configListData.data.sub_list[i + 1]
      //       : null;
      //   if (check2) {
      //     if (
      //       check1.fu_no?.trim() == check2.fu_no?.trim() &&
      //       check1.fu_name?.trim() == check2.fu_name?.trim()
      //     ) {
      //       let temp_err = {
      //         message: "No และ Name ซ้ำ ",
      //       };
      //       error_list.push(temp_err);
      //     }
      //   }
      // }
    }

    if (error_list.length > 0) {
      var err_message = "";

      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }

      Swal.fire("กรอกข้อมูลไม่ถูกต้อง", err_message, "error");
    } else {
      Swal.fire({
        title: "คุณต้องการบันทึกข้อมูลใช่หรือไม่?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "บันทึก",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
              Swal.showLoading();
            },
          }).then(async () => {
            const sub_data_add = configListData.data.sub_list.filter((item) => {
              return item.is_new == true;
            });

            const sub_data_edit = configListData.data.sub_list.filter(
              (item) => {
                return item.is_new == false && item.is_status == "update";
              }
            );

            const send_data = {
              user_id: getUser().fup,
              company_id: getUser().com,
              oem_id: getOem(),
              created_name: getUser().name + " " + getUser().surname,
              cun_id: id,
              main_data: configMainData,
              sub_data_add: sub_data_add,
              sub_data_edit: sub_data_edit,
              sub_data_del: configListData.remove,
              sub_data_list: configListData.data.sub_list,
            };
            // console.log('send_data', send_data)

            let axios_data = {};

            if (mode == "Add") {
              axios_data = {
                method: "post",
                url: `${Configs.API_URL_AIOI_Back}/api/configUnai/addConfigUnai`,
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: send_data,
              };
            } else if (mode == "Edit") {
              axios_data = {
                method: "post",
                url: `${Configs.API_URL_AIOI_Back}/api/configUnai/updateConfigUnai`,
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: send_data,
              };
            }

            await axios(axios_data)
              .then(async (response_data) => {
                Swal.fire({
                  icon: "success",
                  text: "เสร็จสิ้น",
                  showConfirmButton: false,
                  timer: 1000,
                }).then(async () => {
                  window.location.href = "/IoT_Master_Data/Config_Unai";
                });
              })
              .catch(async (error) => {
                // console.log(error);
                Swal.fire({
                  icon: "warning",
                  // title: "กรุณากรอกข้อมูลใหม่",
                  text: error.response.data.error.message,
                });
              });
          });
        }
      });
    }
  }

  function SetModuleTableData(index) {
    const rowItem = {};

    rowItem["no"] = index + 1;

    rowItem["fu_no"] = configListData.data.sub_list[index].fu_no;

    rowItem["fu_name"] = configListData.data.sub_list[index].fu_name;

    rowItem["fu_pos_x"] = configListData.data.sub_list[index].fu_pos_x || "-";

    rowItem["fu_pos_z"] = configListData.data.sub_list[index].fu_pos_z || "-";

    rowItem["fu_width"] = configListData.data.sub_list[index].fu_width || "-";

    rowItem["fu_height"] = configListData.data.sub_list[index].fu_height || "-";

    rowItem["fu_length"] = configListData.data.sub_list[index].fu_length || "-";

    rowItem["management"] = (
      <div
        className="row justify-content-center"
        style={{ flexWrap: "nowrap", margin: "0 auto" }}
      >
        <div className="col-xl-3 col-md-3 col-xs-3  ">
          <a
            key={`view_${index}`}
            id="btn_edit"
            className=" btn btn-xs "
            onClick={async () => {
              await setUpDataModal(index);

              setSettingPage({
                ...settingPage,
                disableInputModal: true,
              });
              settingPage.disableInputModal = true;

              setModalStatus({
                ...modalStatus,
                status_1: true,
                mode_1: "View",
              });
              modalStatus.status_1 = true;
              modalStatus.mode_1 = "View";
            }}
          >
            <i class="fas fas fa-eye"></i>
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3  ">
          <a
            key={`edit_${index}`}
            id="btn_edit"
            className=" btn btn-xs "
            onClick={async () => {
              await setUpDataModal(index);

              setSettingPage({
                ...settingPage,
                disableInputModal: false,
              });
              settingPage.disableInputModal = false;

              setModalStatus({
                ...modalStatus,
                status_1: true,
                mode_1: "Edit",
              });
              modalStatus.status_1 = true;
              modalStatus.mode_1 = "Edit";
            }}
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={`delete_${index}`}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={async () => {
              await deleteDataList(index);
            }}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );

    return rowItem;
  }

  const ModuleTableData = [];
  for (
    let index = 0;
    index < configListData.data.sub_list?.length;
    index += 1
  ) {
    ModuleTableData.push(SetModuleTableData(index));
  }

  const ModuleTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Forklift No.",
        field: "fu_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Forklift Name",
        field: "fu_name",
        sort: "asc",
        width: 50,
      },
      // {
      //   label: (
      //     <div
      //       style={{ textAlign: "left" }}
      //     >{`Position X (${getUnitData.sc_name})`}</div>
      //   ),
      //   field: "fu_pos_x",
      //   sort: "asc",
      //   width: 50,
      // },
      // {
      //   label: (
      //     <div
      //       style={{ textAlign: "left" }}
      //     >{`Position Y (${getUnitData.sc_name})`}</div>
      //   ),
      //   field: "fu_pos_z",
      //   sort: "asc",
      //   width: 50,
      // },
      {
        label: (
          <div
            style={{ textAlign: "left" }}
          >{`Width  (${getUnitData.sc_name})`}</div>
        ),
        field: "fu_width",
        sort: "asc",
        width: 50,
      },
      {
        label: (
          <div
            style={{ textAlign: "left" }}
          >{`Height  (${getUnitData.sc_name})`}</div>
        ),
        field: "fu_height",
        sort: "asc",
        width: 50,
      },
      {
        label: (
          <div
            style={{ textAlign: "left" }}
          >{`Depth  (${getUnitData.sc_name})`}</div>
        ),
        field: "fu_length",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        sort: "asc",
        width: 50,
      },
    ],

    rows: ModuleTableData,
  };

  if (mode == "View") {
    const index = ModuleTable.columns.findIndex((e) => {
      return e.field === "management";
    });
    ModuleTable.columns.splice(index, 1);
  }

  useEffect(async () => {
    // if (mode == "Edit" || mode == "View" ) {
    // }

    await getTableForklift();
    await getUnit();
    if (mode == "View") {
      setSettingPage({
        ...settingPage,
        disableInputMain: true,
        disableInputModal: true,
      });
      settingPage.disableInputMain = true;
      settingPage.disableInputModal = true;
    }
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-12 col-sm-4 col-xl-6">
                <h1>Config UNAI {mode}</h1>
              </div>
              <div className="col-12 col-sm-8 col-xl-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">IoT Master Data</li>
                  <li className="breadcrumb-item active">Config UNAI</li>
                  <li className="breadcrumb-item active">{mode}</li>
                </ol>
              </div>
            </div>

            <div className="row">
              {mode !== "View" ? (
                <div className="col-6 col-md-2 col-xl-1">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-block btn-success"
                      onClick={async () => {
                        await saveConfigUnai();
                      }}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              ) : null}
              <div className="col-6 col-md-2 col-xl-1">
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-block btn-danger"
                    onClick={async () => {
                      window.location.href = "/IoT_Master_Data/Config_Unai";
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div className="card">
                  <div className="card-header">
                    {/* <h4>Config AIOI Add</h4> */}
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <h2
                          style={{
                            fontWeight: "bolder",
                            textDecoration: "underline",
                          }}
                        >
                          Information
                        </h2>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6 col-xl-4">
                        <div class="form-group">
                          <Select
                            isDisabled={settingPage.disableInputMain}
                            options={warehouseData.dropdown}
                            value={warehouseData.dropdown?.find((e) => {
                              return e.value == configMainData.warehouse_id;
                            })}
                            onChange={async (e) => {
                              // console.log(e);
                              if (e !== null) {
                                handleConfigState(
                                  setConfigMainData,
                                  configMainData,
                                  "warehouse_id",
                                  e.value
                                );
                                setCheckConfigMainData({
                                  wh_depth: e.wh_depth,
                                  wh_heigth: e.wh_height,
                                  wh_width: e.wh_width,
                                });
                                checkConfigMainData.wh_depth = e.wh_depth;
                                checkConfigMainData.wh_heigth = e.wh_height;
                                checkConfigMainData.wh_width = e.wh_width;
                                // console.log("+++++", checkConfigMainData);
                              }
                            }}
                          />
                          <label>
                            Warehouse <span style={{ color: "red" }}> *</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <div class="form-group">
                          <textarea
                            type="text"
                            className="form-control"
                            style={{ fontSize: "25px" }}
                            required
                            disabled={settingPage.disableInputMain}
                            value={configMainData.remark}
                            onChange={async (e) => {
                              handleConfigState(
                                setConfigMainData,
                                configMainData,
                                "remark",
                                e.target.value
                              );
                            }}
                          />
                          <label>Remark</label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <h2
                          style={{
                            fontWeight: "bolder",
                            textDecoration: "underline",
                          }}
                        >
                          Config Unai
                        </h2>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="row">
                        <div className="col-12 col-md-12 col-xl-12">
                          <div className="form-group">
                            <NumberFormat
                              type="text"
                              className="form-control"
                              id="textunai_floor"
                              allowNegative={false}
                              required="false"
                              value={configMainData.floor_id}
                              onChange={async (e) => {
                                handleConfigState(
                                  setConfigMainData,
                                  configMainData,
                                  "floor_id",
                                  e.target.value
                                );
                                /* console.log(
                                  "configMainData",
                                  configMainData.floor_id
                                ); */
                              }}
                            />
                            <label>Floor ID</label>
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="textunai_name"
                              required="false"
                              value={configMainData.username}
                              onChange={async (e) => {
                                if (
                                  !e.target.value &&
                                  e.target.value.length > 0
                                )
                                  return;
                                handleConfigState(
                                  setConfigMainData,
                                  configMainData,
                                  "username",
                                  e.target.value.trim()
                                );
                              }}
                            />
                            <label>Username</label>
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group">
                            <input
                              autocomplete="cc-number"
                              type="password"
                              className="form-control"
                              id="text_unai_pass"
                              required="false"
                              value={configMainData.password}
                              onChange={async (e) => {
                                if (
                                  !e.target.value &&
                                  e.target.value.length > 0
                                )
                                  return;
                                handleConfigState(
                                  setConfigMainData,
                                  configMainData,
                                  "password",
                                  e.target.value.trim()
                                );
                              }}
                            />
                            <label>Password</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <h2
                          style={{
                            fontWeight: "bolder",
                            textDecoration: "underline",
                          }}
                        >
                          Forklift
                        </h2>
                      </div>
                    </div>

                    {mode == "Add" || mode == "Edit" ? (
                      <>
                        <div className="row">
                          <div className="col-6 col-md-4 col-xl-2">
                            <div className="form-group">
                              <button
                                id="btn_add"
                                className="btn btn-block btn-primary"
                                type="button"
                                onClick={() => {
                                  setConfigSubData({
                                    fu_id: "",
                                    fu_cun_id: "",
                                    fu_no: "",
                                    fu_name: "",
                                    fu_pos_x: "0",
                                    fu_pos_z: "0",
                                    fu_width: "",
                                    fu_height: "",
                                    fu_length: "",
                                    is_new: true,
                                    is_status: "add",
                                  });

                                  setSettingPage({
                                    ...settingPage,
                                    disableInputModal: false,
                                  });
                                  settingPage.disableInputModal = false;

                                  setModalStatus({
                                    ...modalStatus,
                                    status_1: true,
                                    mode_1: "Add",
                                  });
                                  modalStatus.status_1 = true;
                                  modalStatus.mode_1 = "Add";
                                }}
                              >
                                Add Forklift
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <div className="row">
                      <div className="col-12 col-md-12 col-xl-12">
                        <div className="table-responsive">
                          <MDBDataTable
                            sortable={false}
                            className="table table-head-fixed"
                            striped
                            bordered
                            hover
                            fixedHeader
                            disableRetreatAfterSorting={true}
                            data={ModuleTable}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          size="sm"
          className="Modal_Center_LeftRight"
          isOpen={modalStatus.status_1}
          toggle={async () => {
            setModalStatus({
              ...modalStatus,
              status_1: false,
            });
            modalStatus.status_1 = false;
          }}
        >
          <ModalHeader
            toggle={async () => {
              setModalStatus({
                ...modalStatus,
                status_1: false,
              });
              modalStatus.status_1 = false;
            }}
          >
            <h4 className="modal-title" id="modalDelivery">
              <b>{modalStatus.mode_1} Config Forklift</b>
            </h4>
          </ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-12">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={configSubData.fu_no}
                    disabled={settingPage.disableInputModal}
                    onChange={(e) =>
                      handleConfigState(
                        setConfigSubData,
                        configSubData,
                        "fu_no",
                        e.target.value
                      )
                    }
                  />
                  <label>
                    Forklift No. <span style={{ color: "red" }}> *</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={configSubData.fu_name}
                    disabled={settingPage.disableInputModal}
                    onChange={(e) =>
                      handleConfigState(
                        setConfigSubData,
                        configSubData,
                        "fu_name",
                        e.target.value
                      )
                    }
                  />
                  <label>
                    Forklift Name <span style={{ color: "red" }}> *</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12" hidden>
                <div class="form-group">
                  <NumberFormat
                    className="form-control"
                    decimalScale={2}
                    allowNegative={false}
                    thousandSeparator={true}
                    disabled={settingPage.disableInputModal}
                    value={configSubData.fu_pos_x}
                    onChange={(e) =>
                      handleConfigState(
                        setConfigSubData,
                        configSubData,
                        "fu_pos_x",
                        e.target.value
                      )
                    }
                  />
                  <label>
                    Position X<span style={{ color: "red" }}> *</span>(
                    {getUnitData.sc_name})
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div class="form-group" hidden>
                  <NumberFormat
                    className="form-control"
                    decimalScale={2}
                    allowNegative={false}
                    thousandSeparator={true}
                    disabled={settingPage.disableInputModal}
                    value={configSubData.fu_pos_z}
                    onChange={(e) =>
                      handleConfigState(
                        setConfigSubData,
                        configSubData,
                        "fu_pos_z",
                        e.target.value
                      )
                    }
                  />
                  <label>
                    Position Z<span style={{ color: "red" }}> *</span>(
                    {getUnitData.sc_name})
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div class="form-group">
                  <NumberFormat
                    className="form-control"
                    decimalScale={2}
                    allowNegative={false}
                    thousandSeparator={true}
                    disabled={settingPage.disableInputModal}
                    value={configSubData.fu_width}
                    onChange={(e) =>
                      handleConfigState(
                        setConfigSubData,
                        configSubData,
                        "fu_width",
                        e.target.value
                      )
                    }
                  />
                  <label>
                    Width<span style={{ color: "red" }}> *</span>(
                    {getUnitData.sc_name})
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div class="form-group">
                  <NumberFormat
                    className="form-control"
                    decimalScale={2}
                    allowNegative={false}
                    thousandSeparator={true}
                    disabled={settingPage.disableInputModal}
                    value={configSubData.fu_height}
                    onChange={(e) =>
                      handleConfigState(
                        setConfigSubData,
                        configSubData,
                        "fu_height",
                        e.target.value
                      )
                    }
                  />
                  <label>
                    Height<span style={{ color: "red" }}> *</span>(
                    {getUnitData.sc_name})
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div class="form-group">
                  <NumberFormat
                    className="form-control"
                    decimalScale={2}
                    allowNegative={false}
                    thousandSeparator={true}
                    disabled={settingPage.disableInputModal}
                    value={configSubData.fu_length}
                    onChange={(e) =>
                      handleConfigState(
                        setConfigSubData,
                        configSubData,
                        "fu_length",
                        e.target.value
                      )
                    }
                  />
                  <label>
                    Depth<span style={{ color: "red" }}> *</span>(
                    {getUnitData.sc_name})
                  </label>
                </div>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            {modalStatus.mode_1 == "Add" || modalStatus.mode_1 == "Edit" ? (
              // console.log('modalStatus.mode_1', modalStatus.mode_1),
              <>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={async () => {
                    ConfirmSubmitModal();
                  }}
                >
                  บันทึก
                </button>
              </>
            ) : (
              <></>
            )}

            <button
              type="button"
              className="btn btn-danger"
              onClick={async () => {
                if (
                  modalStatus.mode_1 == "Add" ||
                  modalStatus.mode_1 == "Edit"
                ) {
                  Swal.fire({
                    title: "คุณมั่นใจว่าจะออกจากหน้านี้ใช่หรือไม่",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: `ใช่`,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    cancelButtonText: `ไม่`,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      setModalStatus({
                        ...modalStatus,
                        status_1: false,
                      });
                      modalStatus.status_1 = false;
                    }
                  });
                } else {
                  setModalStatus({
                    ...modalStatus,
                    status_1: false,
                  });
                  modalStatus.status_1 = false;
                }
              }}
            >
              ยกเลิก
            </button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
}

export default Config_UNAI_AEV;
