import React, { Component, useEffect, useState ,useRef} from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import "../../assets/css/Supp.css";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import "../../assets/css/table.css";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import { useParams } from "react-router";
import Sidebar from "../../component/Sidebar";
import { getOem, getToken, getUser,getAcademy } from "../../Utils/Common";
import Configs from "../../config";
import FormData from "form-data";
import axios from "axios";

import NumberFormat from "react-number-format";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";
import DatePicker from "react-datepicker";
import Resizer from "react-image-file-resizer";
import Select from "react-select";
//import { zIndex } from "html2canvas/dist/types/css/property-descriptors/z-index";

var moment = require("moment");

function Record_oil_add(mode) {
  const [company, setRecordoil] = useState([]);
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);
  const [getplate_number,setgetplate_number] = useState([]);
  const [getplate_number2,setgetplate_number2] = useState([]);
  const [getgas_station,setgetgas_station] = useState([]);
  const [record_oil, setrecord_oil] = useState({
   
    company_id: getUser().com,
    mile: "",
    plate_number:"",
    liter:"",
    locationAdd:"",
    dirver_name_add: "",
    refueling_name: "",
    meter_oil:"",
    date: "",
    oem_id: getOem(),
    time: "",
    image: "",
    image_name: "",
    imaget_path: "",
    oil_location_name: "",
    
  });
  const [filler_record, setFiller_record] = useState({
    start_date: "",

  });

  async function SetDate_start(date){
    setFiller_record({ ...filler_record, start_date: date})
  }
  const [currentOEM, setCurrentOEM] = useState({ label: "", value: "" });
  const [companys, setCompany] = useState([]);
  var config_getOem = {
    method: "get",
    url: Configs.API_URL + "/company/findOEMByCompanyId?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };
  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        /*   console.log(response.data); */
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const { id } = useParams();
  useEffect(() => {
    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);

  useEffect(() => {
    getplate();
    getgas(); 
    GetCompany();
    getview();
    axios(config_getOem)
      .then(function (response) {
        const options = [];
        var element2 = {};
        element2["value"] = "All_OEM";
        element2["label"] = "---ทั้งหมด---";
        options.push(element2);
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name;
          options.push(element);
          if (el.id === getOem()) {
            setCurrentOEM({ label: el.name, value: el.id });
          }
        });

        setCompany(options);
      })
      .catch(function (error) {
        console.log(error);
      });
     
  }, []);
  
  // console.log("oil_location_name",record_oil.oil_location_name);




  const getview = async () => {
     
    if ( mode.mode =="read" || mode.mode =="edit"  ) {
      axios({
        method: "get",
        url: Configs.API_URL + "/logistic/findById_oil?id="+id,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          // console.log("allfin",response.data.allfin[0]);
          // console.log("viewdin",response.data.viewdin);
          // let locao = "";
           
      
          setrecord_oil({
            company_id: response.data.allfin[0].company_id,
            mile: response.data.allfin[0].mileage_number || "",
            plate_number: response.data.allfin[0].logistic_car_id ||"",
            liter: response.data.allfin[0].liter || "",
            locationAdd: response.data.allfin[0].oil_location_id || "",
            dirver_name_add: response.data.allfin[0].fuel_receiver || "",
            refueling_name: response.data.allfin[0].fuel_giver || "",
            meter_oil: response.data.allfin[0].current_amount_meter || "",
            date:new Date(response.data.allfin[0].record_date) || "",
            oem_id: response.data.allfin[0].oem_id,
            time: moment(response.data.allfin[0].record_date).format('HH:mm'),
            image_name: response.data.allfin[0].slip_image_name || "",
            imaget_path: response.data.allfin[0].slip_image_path || "",
            oil_location_name:response.data.viewdin[0].oil_location_name ||"",
            
          });

        })
        .catch(function (error) {
          console.log(error);
        });
    }
    
  }
  
  
  function saveOption(params) {
    
    const error_list = [];
    
    
    if(record_oil.mile == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [เลขไมล์]."
      }
      error_list.push(temp_err);
    }
    if(record_oil.plate_number == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [ทะเบียนรถ]."
      }
      error_list.push(temp_err);
    }
    if(record_oil.date == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [วันที่]."
      }
      error_list.push(temp_err);
    }
    if(record_oil.liter == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [จำนวนลิตร]."
      }
      error_list.push(temp_err);
    }
    if(record_oil.locationAdd == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [สถานที่เติม]."
      }
      error_list.push(temp_err);
    }
    if(record_oil.time == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [เวลา]."
      }
      error_list.push(temp_err);
    }
    if(record_oil.dirver_name_add.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [ผู้เติมน้ำมัน]."
      }
      error_list.push(temp_err);
    }
    if(record_oil.oil_location_name === "SST"){
      if(record_oil.refueling_name.trim() == ""){
        let temp_err ={
          message:"Please enter information in the fields >>> [ผู้จ่ายน้ำมัน]."
        }
        error_list.push(temp_err);
      }
      if(record_oil.meter_oil == ""){
        let temp_err ={
          message:"Please enter information in the fields >>> [เลข Meter น้ำมัน]."
        }
        error_list.push(temp_err);
      }

    }
   
    
    if(error_list.length > 0){
      var err_message = "";
      for(var e =0; e < error_list.length;e++){
        err_message += "<br/>"+error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    }else{
      // let date1 = record_oil.date;
      if (mode.mode === "add") {
        const temp ={
          plate_number: record_oil.plate_number,
          // date: moment(date1).format("DD-MM-yyyy HH:mm"),
          date: record_oil.date,
          mile: record_oil.mile,
          liter: record_oil.liter,
          locationAdd: record_oil.locationAdd,
          dirver_name_add: record_oil.dirver_name_add.trim(),
          refueling_name: record_oil.refueling_name.trim(),
          meter_oil: record_oil.meter_oil,
          oem_id: record_oil.oem_id,
          company_id: record_oil.company_id,
          time: record_oil.time,
          image_name: record_oil.image_name,
          imaget_path: record_oil.imaget_path,
          oil_location_name:record_oil.oil_location_name,
          
        }
        console.log(temp);
        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/logistic/addrecordOil",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Record_oil";
                });
              }
              console.log("หน้าบ้าน",response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error",error.response.data.message, "error");
            });
        });
      }
      if (mode.mode === "edit") {
        const temp ={
          id:id,
          plate_number: record_oil.plate_number,
          date: record_oil.date,
          mile: record_oil.mile,
          liter: record_oil.liter,
          locationAdd: record_oil.locationAdd,
          dirver_name_add: record_oil.dirver_name_add.trim() ,
          refueling_name: record_oil.refueling_name.trim() ,
          meter_oil: record_oil.meter_oil,
          oem_id: record_oil.oem_id,
          company_id: record_oil.company_id,
          time: record_oil.time,
          image_name: record_oil.image_name,
          imaget_path: record_oil.imaget_path,
          oil_location_name:record_oil.oil_location_name,
        }
        console.log("อับเดดหน้าฟอน",temp);     
        Swal.fire( {
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/logistic/UpdateRecordOil?id="+id,
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Record_oil";
                });
              } 
              console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
       }
    }

  }


  function getplate(){
    let oem_id = {oem_id: getOem()}
      axios({
        method: "post",
        url: Configs.API_URL + "/logistic/getplate_number",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: oem_id,
      })
      .then(function (response){
        // console.log("ทะเบียนรถ ",response.data);
        setgetplate_number(response.data);
        
        
        // console.log("ทะเบียนรถ company",response.data.plate_company);
      })
     
        .catch(function (error) {
          console.log(error);
          Swal.fire("ErrorGetplate", error, "errorgetplate");
        });
  }


function getgas(){
  let locasionoill = {company_id: getUser().com}
    axios({
      method: "post",
      url: Configs.API_URL + "/logistic/getgas_station",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: locasionoill,
    })
    .then(function (response){
      setgetgas_station(response.data);
      console.log("เลือกที่เติม",response.data);
    })
      .catch(function (error) {
        console.log(error);
        Swal.fire("ErrorGetGas", error, "error");
      });
}

function cahecklocation(e){
  let locao = "";
  setrecord_oil({ ...record_oil, 
         locationAdd: e.target.value });
         record_oil.locationAdd = e.target.value
     
     console.log("locationAdd1123",record_oil.locationAdd);


var checkl = getgas_station.filter((e)=>{return e.id === record_oil.locationAdd });
var maa = checkl.map((e4) => {return e4.oil_location_name});

locao = maa;
console.log("locao",locao);
if(locao === undefined){
  locao = "";
}else{
  locao = maa[0];
}
setrecord_oil({ ...record_oil, 
  oil_location_name: locao });
  record_oil.oil_location_name = e.target.locao;

}

console.log("SD",record_oil.oil_location_name);

  function cancleOption(params) {
    Swal.fire({
      title: "Do you want to cancel and leave the changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      cancelButtonText: `No`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      denyButtonText: `Don't save`,
    }
        // "Do you want to cancel?",
        // "Wait",
        // "warning"
      ).then((result)=>{
        // console.log(result);
        if (result.isConfirmed === false){
      //     window.location.href =
      //  "/Warehouse_Management/Record_oil/add";
        }else{
             window.location.href =
    "/Warehouse_Management/Record_oil";
  }
      }
      );
  }

  function isFileImage(file) {
    const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
  
    return file && acceptedImageTypes.includes(file['type'])
  }

  async function setrecordOil_bil(e) {
      
        if(e.target.files[0]){
          const file = e.target.files[0];
          console.log("ss",file);
          const regex = /([\u0E00-\u0E7F]+)/gmu;
          const str = file.name;
          let m = regex.exec(str);
          if(m !== null || isFileImage(file) === false){
            //console.log("ชื่อไฟล์ไม่ถูกต้อง"); 
            Swal.fire(
              'Error',
              'File name or type are not correct!!!',
              'error'
            )
           }else{
            // const image = await resizeFile(file);
            var datedate = moment(record_oil.date).format('yyyyMMDD');
            var datetime = moment(record_oil.time).format('HHmm');
            console.log("datedate",datedate,"datetime",datetime);
            var strdate = datedate.toString() 
            var strtime = datetime.toString();
            // var file_image = dataURLtoFile(image,file.name);
            var allstr = strdate + strtime;
            console.log('Str',strdate,"ti0",strtime)
            var data = new FormData();
            data.append("Profile", file);
            data.append("typeFile",allstr);
            
            var config_uploadProfile = {
              method: "post",
              url: Configs.API_URL + "/upload/profile",
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: data,
            };
            axios(config_uploadProfile)
              .then(function (response) {
                if (response.data.status) {
                console.log(response.data);
    
                  setrecord_oil({
                    ...record_oil,
                    image_name: response.data.data.orgin_name,
                    imaget_path: response.data.data.path,
                    image: response.data.data,
                  });
                }
              })
              .catch(function (error) {
                console.log(error);
                Swal.fire(
                  'Error',
                  'File name !!!',
                  'error'
                )
              });
           }
          
        }
        else{
          console.log("ไม่ลงไฟล์");
        }
  }


  return (
    <div className="wrapper">
   {/*  <Header />
    <Sidebar menu="warehouse" activemenu="suppmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>บันทึกการใช้น้ำมัน{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">บันทึกการใช้น้ำมัน</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row mb-2">
                <div className="col-sm-1">
                  <button
                    type="button"
                    onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                <div className="col-sm-1">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-sm-1">
                  <button
                    type="button"
                    if
                      onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">บันทึกการใช้น้ำมัน {pageMode}</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body">
            <h3 className="mt-5-head">
            บันทึกการใช้น้ำมัน
                  </h3>
                <div className="tab-custom-content">
                 </div>
                 <br/>

              <div className="row">
                <div className="col-6">
                <div className="row">
                <div className="col-12 col-md-12 col-xl-8">
                <div class="form-group">
                        <select
                          className="custom-select select2 form-control"
                          value={record_oil.plate_number}
                          allowNegative={false}
                          disabled={disable}
                          onChange={(e) =>
                            setrecord_oil({
                              ...record_oil,
                              plate_number: e.target.value
                            })
                          }
                        >
                          <option value="">select</option>
                          {getplate_number.map((el) => {
                            return (
                              <option key={el.id} value={el.id}>
                                {el.plate_number}
                              </option>
                            );
                          })}
                        </select>

                        <label htmlFor="">เลือกทะเบียนรถ oem:</label>
                        <label>

                          {record_oil.plate_number === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-4"  style={{ "z-index": "99" }}>

                  <div class="form-group"  >
                  <DatePicker selected = {record_oil.date}
                   value={record_oil.date}
                      
                      disabled={disable}
                      className="form-control"
                      required
                      dateFormat = {"dd-MM-yyyy"}
                      onChange={async(e) => {
                        setrecord_oil({
                          ...record_oil,
                          date: e,
                        });
                      }}
                      // selectsStart startDate = {filler_record.start_date}
                      // endDate = {filler_record.end_date}
                      maxDate = {new Date()}
                      /> 
                      <label htmlFor="">เลือกวันที่</label>
                    <label>
                      {record_oil.date === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
                   <div class="form-group">
                    <NumberFormat
                        placeholder="00:00"
                        allowNegative={false}
                        disabled={disable}
                        type="text"
                        format={"##:##"}
                        className="form-control"
                        value={record_oil.time}
                        onChange={(e) => {
                          console.log(e.target.value)
                          const myArray = e.target.value.split(":");
                          
                            console.log(myArray)
                          if(myArray[0] > 23 || myArray[1] > 59){
                            setrecord_oil({
                              ...record_oil,
                              time: "23:59",
                            });
                          }else{
                            setrecord_oil({
                              ...record_oil,
                              time: e.target.value,
                            });
                          }
                         
                        }}
                      />
                    <label htmlFor="">
                      เวลา
                    </label>
                  </div>
                  
                </div>
                
              </div>
              <div className="row">
                <div className="col-10 col-md-10 col-xl-8">
                  <div class="form-group">
                    <NumberFormat
                      type="text"
                      className="form-control"
                      allowNegative={false}
                      decimalScale = {2}
                      required
                      //style={{ height: "100px", resize: "none" }}
                      value={record_oil.mile}
                      disabled={disable}
                      onChange={(e) => {
                        setrecord_oil({
                          ...record_oil,
                          mile: e.target.value,
                        });
                      }}
                    /> 
                    <label>
                      กรอกเลขไมล์
                      {record_oil.mile === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>{" "}
                  </div>
                  
                </div>
                
              <div className="row">
                
              <h5> <br></br> 
              &nbsp;(กม.)</h5>
              </div> 
              </div>

              <div className="row">
                <div className="col-10 col-md-10 col-xl-8">
                  <div class="form-group">
                    <NumberFormat
                      type="text"
                      allowNegative={false}
                      decimalScale = {2}
                      className="form-control"
                      required
                      //style={{ height: "100px", resize: "none" }}
                      value={record_oil.liter}
                      disabled={disable}
                      onChange={(e) => {
                        setrecord_oil({
                          ...record_oil,
                          liter: e.target.value,
                        });
                      }}
                    />
                    <label>
                      กรอกจำนวนลิตร
                      {record_oil.liter === "" ? (
                        <span style={{ color: "red" }}> *</span> 
                      ) : (
                        ""
                      )}
                    </label> 
                  </div>
                  
                </div>
                <div className="row">
                
                <h5> <br></br> 
                &nbsp;(ลิตร.)</h5>
                </div> 
              </div>
              <div className="row">
                <div className="col-12 col-md-12 col-xl-8">
                  
                  <label htmlFor="exampleInputFile">Part Image</label>
                  <span style = {{color:"red"}}> </span>
                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="exampleInputFile"
                        disabled={disable}
                        accept="image/*"
                        onChange={setrecordOil_bil}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="exampleInputFile"
                      >
                        {record_oil.image_name !== ""
                          ? record_oil.image_name
                          : "Select Image"}
                      </label>
                      
                    </div>
                    
                  </div>
                  
                  
                </div>
                
              </div>
              <br></br>
              


              <div className="row">
                <div className="col-12 col-md-12 col-xl-4">
                  <div class="form-group">
                  <select
                    className="custom-select select2 form-control"
                    value={record_oil.locationAdd}
                    disabled={disable}
                    onChange={cahecklocation}
                    // onChange={(e) =>
                    //   setrecord_oil({ 
                    //     ...record_oil, 
                    //     locationAdd: e.target.value })
                    // }
                  >
                    <option value="">Select</option>
                    {getgas_station.map((el) =>{
                      return(
                        <option key={el.id} value={el.id}>
                          {el.oil_location_name}
                        </option>
                      )
                    })}           
                  </select>
                  
                    <label htmlFor="">เลือกสถานที่เติม :</label>
                    <label>
                      
                      {record_oil.locationAdd === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-xl-4">
                <div class="form-group">
                    <input  
                    //  dirver_name_add: "",
                   
                      type="text"
                      className="form-control"
                      // allowNegative={false}
                      // decimalScale = {2}
                      required
                      
                      value={record_oil.dirver_name_add}
                      disabled={disable}
                      onChange={(e) => {
                        setrecord_oil({
                          ...record_oil,
                          dirver_name_add: e.target.value,
                        });
                      }}
                    /> 
                    <label>
                      ผู้เติมน้ำมัน
                      {record_oil.dirver_name_add === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>{" "}
                  </div>
                </div>
              </div>

              <div className="row">
                
              {record_oil.oil_location_name === "SST"?(
                    <>
                <div className="col-12 col-md-12 col-xl-4">    
                <div class="form-group">
                  
                    <input
                     //  refueling_name: "",
                      type="text"
                      className="form-control"
                      required
                      value={record_oil.refueling_name}
                      disabled={disable}
                      onChange={(e) => {
                        setrecord_oil({
                          ...record_oil,
                          refueling_name: e.target.value,
                        });
                      }}
                    /> 
                    <label>
                      ผู้จ่ายน้ำมัน
                      {record_oil.refueling_name === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>{" "}
                  </div>
                </div>
                <div className="col-12 col-md-12 col-xl-4">
                <div class="form-group">
                    <NumberFormat
                     // meter_oil
                      type="text"
                      className="form-control"
                      allowNegative={false}
                      decimalScale = {2}
                      required
                      value={record_oil.meter_oil}
                      disabled={disable}
                      onChange={(e) => {
                        setrecord_oil({
                          ...record_oil,
                          meter_oil: e.target.value,
                        });
                      }}
                    /> 
                    <label>
                     เลข Meter น้ำมัน  
                      {record_oil.meter_oil === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>{" "}
                  </div>
                </div>       
                    </>
                   ):(
                     <>
                     </>
                   )}
              </div>
      
              </div>

                <div className="col-6">
         
                <div>
                
                  <label htmlFor="exampleInputFile"> </label>
                  <img
                    id="img"
                    alt="..."
                    className="img-fluid rounded shadow border-0"
                    src={
                      record_oil.imaget_path !== "" &&
                      record_oil.imaget_path !== null &&
                      record_oil.imaget_path !== undefined
                        ? Configs.API_URL_IMG + record_oil.imaget_path
                        : userdefault_img.imgs
                    }
                    style={{
                      width: "80%",
                      height: "80%",
                      position: "absolute",
                    }}
                  />
                
                  
                </div>
                </div>
              </div>
             

              
            </div>
            
          </div>
        </section>
      </div>
 {/*      <Footter/> */}
      </div>
  );
}
export default Record_oil_add;
