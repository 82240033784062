import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";

import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser,getAcademy } from "../../Utils/Common";
import NumberFormat from "react-number-format";
import { useParams } from "react-router";

function Pad_Connection_add(mode) {

  const [company, setCompany] = useState([]);
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);

  const [type_emp, set_type_emp] = useState([]);

  const [pad_detail, set_pad_detail] = useState({
    oem_id: getOem(),
    company_id: getUser().com,
    pad_name:"",
    username:"",
    location:"",
    ip_address:"",
    password:"",
    gate_id:"",
    remark:"",
  });

  const GetemployeeTyer = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
    }
    console.log("get_com_oem",get_com_oem);
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getEmployeeType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data:get_com_oem,
    })
      .then(function (response) {
        set_type_emp(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const { id } = useParams();
  useEffect(() => {
    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);


  console.log("parmID",id);
  const getview = async () => {
     
    if ( mode.mode =="read" || mode.mode =="edit"  ) {
      axios({
        method: "get",
        url: Configs.API_URL_hrMagenatement + "/config/getbyid/"+id,
        headers: {
          Authorization: "Bearer "+getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          console.log("edit_view",response.data.data.pad_name);
          console.log("edit_view[0]",response.data);
          set_pad_detail({
            id: id,
            oem_id: response.data.data.oem_id,
            company_id: response.data.data.company_id,
            oem_id: response.data.data.oem_id,
            company_id:response.data.data.company_id,
            pad_name:response.data.data.pad_name,
            username:response.data.data.username,
            location:response.data.data.location,
            ip_address:response.data.data.ip_address,
            password:response.data.data.password,
            gate_id:response.data.data.gate_id,
            remark:response.data.data.remark,
           
          });

        })
        .catch(function (error) {
          console.log(error);
        });
    }
    
  }
  

//   const GetCompany = async () => {
//     axios({
//       method: "get",
//       url: Configs.API_URL + "/company/all",
//       headers: {
//         Authorization: getToken(),
//         "X-TTT": Configs.API_TTT,
//         "Content-Type": "application/json",
//       },
//     })
//       .then(function (response) {
//         //console.log(response.data);
//         setCompany(response.data);
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   };

  
  useEffect(() => {
    // GetCompany();
    // GetemployeeTyer();
    getview();
    //console.log("id:"+id);
    // GetProject_view();
  }, []);


  

  
  function saveOption(params) {
    //console.log(Number(system_config.extra_budget.replace(/,/g,"")),);
    // console.log(Number(system_config.begin_time));
    const error_list = [];
    if(pad_detail.pad_name.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Name]."
      }
      error_list.push(temp_err);
    }
    if(pad_detail.username.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Username]."
      }
      error_list.push(temp_err);
    }
    if(pad_detail.location.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Location]."
      }
      error_list.push(temp_err);
    }
    if(pad_detail.ip_address.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Ip Address]."
      }
      error_list.push(temp_err);
    }
    if(pad_detail.password.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Password]."
      }
      error_list.push(temp_err);
    }
    // if(pad_detail.remark.trim() == ""){
    //   let temp_err ={
    //     message:"Please enter information in the fields >>> [remark]."
    //   }
    //   error_list.push(temp_err);
    // }
    if(pad_detail.gate_id.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Gate]."
      }
      error_list.push(temp_err);
    }
    
    
    if(error_list.length > 0){
      var err_message = "";
      for(var e = 0; e < error_list.length;e++){
        err_message += "<br/>"+error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    }
      else{
      if (mode.mode === "add") {
        const add_system_config ={   
    oem_id: pad_detail.oem_id,
    company_id:pad_detail.company_id,
    pad_name:pad_detail.pad_name,
    username:pad_detail.username,
    location:pad_detail.location,
    ip_address:pad_detail.ip_address,
    password:pad_detail.password,
    gate_id:pad_detail.gate_id,
    remark:pad_detail.remark,
        }
        console.log("add_system_config",add_system_config);
        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",     
            url: Configs.API_URL_hrMagenatement + "/config?company_id="+getUser().com +"&oem_id="+getOem(),
            headers: {
              Authorization: "Bearer "+getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: add_system_config,
          })
            .then(function (response) {
              console.log(add_system_config);
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                  "/Human_Resource/ตั้งค่า/Pad_Connection";
                });
              }
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
      }
      if (mode.mode === "edit") {
        const edit_system_config = {
          id: id,
          oem_id: pad_detail.oem_id,
          company_id: pad_detail.company_id,
          oem_id: pad_detail.oem_id,
          company_id:pad_detail.company_id,
          pad_name:pad_detail.pad_name,
          username:pad_detail.username,
          location:pad_detail.location,
          ip_address:pad_detail.ip_address,
          password:pad_detail.password,
          gate_id:pad_detail.gate_id,
          remark:pad_detail.remark,
        }

        //console.log(edit_system_config);
       
        Swal.fire( {
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "put",
            url: Configs.API_URL_hrMagenatement + "/config/"+id,
            headers: {
              Authorization: "Bearer "+getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: edit_system_config,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                  "/Human_Resource/ตั้งค่า/Pad_Connection";
                });
              }
    
              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
        }
    }
  
  }


  

  function cancleOption(params) {
    Swal.fire({
      title: 'Do you want to cancel and leave the changes?',
      icon:'warning',
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:`No`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href =
        "/Human_Resource/ตั้งค่า/Pad_Connection";
      }
    }
    );
  }
  return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{pageMode} PAD{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">PAD</li>
                  <li className="breadcrumb-item active">{pageMode} PAD</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row mb-2">
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    id = "btn_cancle1"
                    onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                     <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    id = "btn_save"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    บันทึก
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    id = "btn_cancle"
                    if
                      onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-body">
              <h3 className="mt-2 mt-1-head">
              PAD Detail
              </h3>
              <div className="row">
              <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                  <input
                      type="text"
                      className="form-control"
                      id = "text_pad_name"
                      required
                      value={pad_detail.pad_name}
                      disabled={disable}
                      onChange={(e) => {
                        set_pad_detail({
                          ...pad_detail,
                          pad_name: e.target.value,
                        });
                      }}
                    />
                    <label>
                    PAD Name
                    {pad_detail.pad_name.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                  <input
                      type="text"
                      className="form-control"
                      id = "text_ip_address"
                      required
                      value={pad_detail.ip_address}
                      disabled={disable}
                      onChange={(e) => {
                        set_pad_detail({
                          ...pad_detail,
                          ip_address: e.target.value,
                        });
                      }}
                    />
                    <label>
                    Ip Address
                    {pad_detail.ip_address.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                  <input
                      type="text"
                      className="form-control"
                      id = "text_username_pc"
                      required
                      value={pad_detail.username}
                      disabled={disable}
                      onChange={(e) => {
                        set_pad_detail({
                          ...pad_detail,
                          username: e.target.value,
                        });
                      }}
                    />
                    <label>
                    Username
                    {pad_detail.username.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
             
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                  <input
                      type="password"
                      className="form-control"
                      id = "text_password_pc"
                      required
                      value={pad_detail.password}
                      disabled={disable}
                      onChange={(e) => {
                        set_pad_detail({
                          ...pad_detail,
                          password: e.target.value,
                        });
                      }}
                    />
                    <label>
                    Password
                    {pad_detail.password.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                  <input
                      type="text"
                      className="form-control"
                      required
                      id = "text_location"
                      value={pad_detail.location}
                      disabled={disable}
                      onChange={(e) => {
                        set_pad_detail({
                          ...pad_detail,
                          location: e.target.value,
                        });
                      }}
                    />
                    <label>
                    Location
                    {pad_detail.location.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                  <input
                      type="text"
                      className="form-control"
                      required
                      id = "text_gate_id"
                      value={pad_detail.gate_id}
                      disabled={disable}
                      onChange={(e) => {
                        set_pad_detail({
                          ...pad_detail,
                          gate_id: e.target.value,
                        });
                      }}
                    />
                    <label>
                    Gate
                    {pad_detail.gate_id.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              

              </div>
              <div className="row">
         
              <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                  <input
                      type="text"
                      className="form-control"
                      id = "text_remark"
                      required
                      value={pad_detail.remark}
                      disabled={disable}
                      onChange={(e) => {
                        set_pad_detail({
                          ...pad_detail,
                          remark: e.target.value,
                        });
                      }}
                    />
                    <label>
                    remark
                    {/* {pad_detail.remark.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
             
                </div>
                
            </div>
            
          </div>
        </section>
      </div>
     {/*  <Footter/> */}
      </div>
  );
}

export default Pad_Connection_add;
