import React, { useEffect, useState } from "react";
import axios from "axios";
import Configs from "../../../config";
import Swal from "sweetalert2";
import Table_All_ProjectTestSLA from "../../../component/component_ticket/table_all_projectTestSLA";
import Table_All_Project from "../../../component/component_ticket/table_all_project_of_project";
import {
  DatePicker,
  Avatar,
  Divider,
  Tooltip,
  Space,
  Checkbox,
  Switch,
  Radio,
  ConfigProvider,
  Drawer,
  TimePicker,
  Dropdown,
  Row,
  Col,
  Menu,
} from "antd";
import {
  AntDesignOutlined,
  UserOutlined,
  DownOutlined,
} from "@ant-design/icons";
import img_header_ticket from "../../../assets/img/IMG_Ticket_Management/img_header_ticket.png";
import img_Three_dot from "../../../assets/img/IMG_Ticket_Management/three_dot.png";
import Select from "react-select";
import { Button, Input } from "reactstrap";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import moment from "moment";
import { formatTimeStr } from "antd/es/statistic/utils";
import {
  getOem,
  getToken,
  getUser,
  getFeature,
  getAcademy,
} from "../../../Utils/Common";
import { useHistory } from "react-router-dom";

function SLA_Level(params) {
  const history = useHistory();
  dayjs.extend(customParseFormat);

  const [test, settest] = useState({
    test: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  // const [pageSize, setpageSize] = useState(10);
  const [pageSize, setpageSize] = useState(10);
  const [total, Settotal] = useState("");
  const [SLAList, setSLAList] = useState([]);
  const [Filter, setFilter] = useState({
    search_text: "",
  });
  const [SLA_Level_List, setSLA_Level_List] = useState({
    mode: "Add",
    sl_name: "",
    sl_minor: "",
    sl_average: "",
    sl_major: "",
    sl_critical: "",
    sl_time_minor: "",
    sl_time_average: "",
    sl_time_major: "",
    sl_time_critical: "",
    selectedDaysMinor: "",
    selectedDaysAverage: "",
    selectedDaysMajor: "",
    selectedDaysCritical: "",
    sl_date_minor: "",
    sl_date_average: "",
    sl_date_major: "",
    sl_date_critical: "",
    sl_minor_hour: "",
    sl_minor_min: "",
    sl_average_hour: "",
    sl_average_min: "",
    sl_major_hour: "",
    sl_major_min: "",
    sl_critical_hour: "",
    sl_critical_min: "",
    sl_response_time_hour: "",
    sl_response_time_min: "",
    sl_response_time: "",
  });

  // const onChangeTimeMinor = (time, timeString) => {
  //   const [hour, minute, second] = timeString.split(":").map(Number);
  //   const minutes = hour * 60 + minute;

  //   setSLA_Level_List({
  //     ...SLA_Level_List,
  //     sl_minor: minutes,
  //     sl_time_minor:timeString
  //   });

  // };
  // console.log("SLA_Level_List",SLA_Level_List);
  //TimeMinor

  const onChangeTimeMinor = (time, timeString) => {
    const [hour, minute, second] = timeString.split(":").map(Number);
    const minutes = hour * 60 + minute;

    let selectedDays =
      SLA_Level_List.selectedDaysMinor === ""
        ? 0
        : SLA_Level_List.selectedDaysMinor;

    let totalMinutes = (isNaN(minutes) ? 0 : minutes) + selectedDays * 24 * 60;

    // หาก selectedDaysMinor เป็น 0 หรือค่าว่างให้ใช้ค่าเดิม
    if (selectedDays === 0 && isNaN(minutes)) {
      // console.log("selectedDays",selectedDays);
      totalMinutes =
        SLA_Level_List.sl_minor - SLA_Level_List.selectedDaysMinor * 24 * 60;
    }
    // console.log("totalMinutes",minutes, totalMinutes,selectedDays);
    setSLA_Level_List({
      ...SLA_Level_List,
      sl_minor: totalMinutes,
      sl_time_minor: timeString,
    });
  };

  const onChangeDatePickerMinor = (date, dateString) => {
    const selectedDaysMinor =
      dateString === "" ? 0 : dayjs(dateString).diff(dayjs(), "day");
    // console.log("dasdadads",dayjs(dateString));
    let totalMinutes = SLA_Level_List.sl_minor + selectedDaysMinor * 24 * 60;

    // หาก selectedDaysMinor เป็น 0 หรือค่าว่างให้ใช้ค่าเดิม
    if (selectedDaysMinor === 0) {
      totalMinutes =
        SLA_Level_List.sl_minor - SLA_Level_List.selectedDaysMinor * 24 * 60;
    }

    setSLA_Level_List({
      ...SLA_Level_List,
      selectedDaysMinor,
      sl_minor: totalMinutes,
      sl_date_minor: dateString !== "" ? dayjs(dateString) : null,
    });
  };

  //TimeAverage

  const onChangeTimeAverage = (time, timeString) => {
    const [hour, minute, second] = timeString.split(":").map(Number);
    const minutes = hour * 60 + minute;

    let selectedDays =
      SLA_Level_List.selectedDaysAverage === ""
        ? 0
        : SLA_Level_List.selectedDaysAverage;

    let totalMinutes = (isNaN(minutes) ? 0 : minutes) + selectedDays * 24 * 60;

    // หาก selectedDaysAverage เป็น 0 หรือค่าว่างให้ใช้ค่าเดิม
    if (selectedDays === 0 && isNaN(minutes)) {
      // console.log("selectedDays",selectedDays);
      totalMinutes =
        SLA_Level_List.sl_average -
        SLA_Level_List.selectedDaysAverage * 24 * 60;
    }
    // console.log("totalMinutes",minutes, totalMinutes,selectedDays);
    setSLA_Level_List({
      ...SLA_Level_List,
      sl_average: totalMinutes,
      sl_time_average: timeString,
    });
  };

  const onChangeDatePickerAverage = (date, dateString) => {
    const selectedDaysAverage =
      dateString === "" ? 0 : dayjs(dateString).diff(dayjs(), "day");

    let totalMinutes =
      SLA_Level_List.sl_average + selectedDaysAverage * 24 * 60;

    // หาก selectedDaysMinor เป็น 0 หรือค่าว่างให้ใช้ค่าเดิม
    if (selectedDaysAverage === 0) {
      totalMinutes =
        SLA_Level_List.sl_average -
        SLA_Level_List.selectedDaysAverage * 24 * 60;
    }

    setSLA_Level_List({
      ...SLA_Level_List,
      selectedDaysAverage,
      sl_average: totalMinutes,
      sl_date_average: dateString !== "" ? dayjs(dateString) : null,
    });
  };

  //TimeMajor

  const onChangeTimeMajor = (time, timeString) => {
    const [hour, minute, second] = timeString.split(":").map(Number);
    const minutes = hour * 60 + minute;

    let selectedDays =
      SLA_Level_List.selectedDaysMajor === ""
        ? 0
        : SLA_Level_List.selectedDaysMajor;

    let totalMinutes = (isNaN(minutes) ? 0 : minutes) + selectedDays * 24 * 60;

    // หาก selectedDaysMajor เป็น 0 หรือค่าว่างให้ใช้ค่าเดิม
    if (selectedDays === 0 && isNaN(minutes)) {
      // console.log("selectedDays",selectedDays);
      totalMinutes =
        SLA_Level_List.sl_major - SLA_Level_List.selectedDaysMajor * 24 * 60;
    }
    // console.log("totalMinutes",minutes, totalMinutes,selectedDays);
    setSLA_Level_List({
      ...SLA_Level_List,
      sl_major: totalMinutes,
      sl_time_major: timeString,
    });
  };

  const onChangeDatePickerMajor = (date, dateString) => {
    const selectedDaysMajor =
      dateString === "" ? 0 : dayjs(dateString).diff(dayjs(), "day");

    let totalMinutes = SLA_Level_List.sl_major + selectedDaysMajor * 24 * 60;

    // หาก selectedDaysMinor เป็น 0 หรือค่าว่างให้ใช้ค่าเดิม
    if (selectedDaysMajor === 0) {
      totalMinutes =
        SLA_Level_List.sl_major - SLA_Level_List.selectedDaysMajor * 24 * 60;
    }

    setSLA_Level_List({
      ...SLA_Level_List,
      selectedDaysMajor,
      sl_major: totalMinutes,
      sl_date_major: dateString !== "" ? dayjs(dateString) : null,
    });
  };

  //TimeCritical

  const onChangeTimeCritical = (time, timeString) => {
    const [hour, minute, second] = timeString.split(":").map(Number);
    const minutes = hour * 60 + minute;

    let selectedDays =
      SLA_Level_List.selectedDaysCritical === ""
        ? 0
        : SLA_Level_List.selectedDaysCritical;

    let totalMinutes = (isNaN(minutes) ? 0 : minutes) + selectedDays * 24 * 60;

    // หาก selectedDaysCritical เป็น 0 หรือค่าว่างให้ใช้ค่าเดิม
    if (selectedDays === 0 && isNaN(minutes)) {
      // console.log("selectedDays",selectedDays);
      totalMinutes =
        SLA_Level_List.sl_critical -
        SLA_Level_List.selectedDaysCritical * 24 * 60;
    }
    // console.log("totalMinutes",minutes, totalMinutes,selectedDays);
    setSLA_Level_List({
      ...SLA_Level_List,
      sl_critical: totalMinutes,
      sl_time_critical: timeString,
    });
  };

  const onChangeDatePickerCritical = (date, dateString) => {
    const selectedDaysCritical =
      dateString === "" ? 0 : dayjs(dateString).diff(dayjs(), "day");

    let totalMinutes =
      SLA_Level_List.sl_critical + selectedDaysCritical * 24 * 60;

    // หาก selectedDaysMinor เป็น 0 หรือค่าว่างให้ใช้ค่าเดิม
    if (selectedDaysCritical === 0) {
      totalMinutes =
        SLA_Level_List.sl_critical -
        SLA_Level_List.selectedDaysCritical * 24 * 60;
    }

    setSLA_Level_List({
      ...SLA_Level_List,
      selectedDaysCritical,
      sl_critical: totalMinutes,
      sl_date_critical: dateString !== "" ? dayjs(dateString) : null,
    });
  };

  const onChange_Checkbox = (e) => {
    // console.log(`checked = ${e.target.checked}`);
  };
  const [value, setValue] = useState();
  const handlePageChange = (pageNumber) => {
    // console.log('Page: ', pageNumber);
  };
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    CheckRolePage().then(function (responseData) {
      // console.log(responseData);
      if (responseData) {
        // generateHoursOptions();
        filterSLA_Level();
      } else {
        // window.location = `/NonPermission`;
        history.push("/NonPermission", {
          module: "Setting Permission/SLA",
          menu: "SLA Level",
        });
      }
    });
  }, [currentPage, pageSize]);

  function CheckRolePage() {
    const id = JSON.parse(sessionStorage.getItem("user"));
    return axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/project/getByidChckeRole/${id.fup}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        if (
          response.data[0]?.iu_ps_id === "ea57bf2f-232b-40d6-972d-b83e2e952519"
        ) {
          return true;
        } else {
          return false;
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
        return false;
      });
  }

  const onShowSizeChange = (current, size) => {
    // console.log('Current:', current, 'Size:', size);
    setpageSize(size);
    setCurrentPage(current);
  };

  //  console.log("SLA_Level_List: ", SLA_Level_List);

  function Save_SLA_Level() {
    Swal.fire({
      title: "คุณต้องการบันทึกข้อมูลนี้หรือไม่ ?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        let tempTimeMinor =
          SLA_Level_List.sl_minor_hour * 60 +
          parseInt(SLA_Level_List.sl_minor_min);
        let tempTimeAverage =
          SLA_Level_List.sl_average_hour * 60 +
          parseInt(SLA_Level_List.sl_average_min);
        let tempTimeMajor =
          SLA_Level_List.sl_major_hour * 60 +
          parseInt(SLA_Level_List.sl_major_min);
        let tempTimeCritical =
          SLA_Level_List.sl_critical_hour * 60 +
          parseInt(SLA_Level_List.sl_critical_min);
        let tempTimeResponse =
          SLA_Level_List.sl_response_time_hour * 60 +
          parseInt(SLA_Level_List.sl_response_time_min);
        // console.log("tempTimeMinor: ",tempTimeMinor, SLA_Level_List.sl_minor_hour,SLA_Level_List.sl_minor_min);
        let temp = {
          sl_name: SLA_Level_List.sl_name.trim(),
          sl_minor: tempTimeMinor || "",
          sl_average: tempTimeAverage || "",
          sl_major: tempTimeMajor || "",
          sl_critical: tempTimeCritical || "",
          sl_response_time: tempTimeResponse || "",
          sl_minor_hour: SLA_Level_List.sl_minor_hour || null,
          sl_average_hour: SLA_Level_List.sl_average_hour || null,
          sl_major_hour: SLA_Level_List.sl_major_hour || null,
          sl_critical_hour: SLA_Level_List.sl_critical_hour || null,
          sl_minor_min: SLA_Level_List.sl_minor_min || null,
          sl_average_min: SLA_Level_List.sl_average_min || null,
          sl_major_min: SLA_Level_List.sl_major_min || null,
          sl_critical_min: SLA_Level_List.sl_critical_min || null,
          sl_response_time_hour: SLA_Level_List.sl_response_time_hour || null,
          sl_response_time_min: SLA_Level_List.sl_response_time_min || null,
        };
        const error_list = [];

        if (SLA_Level_List.sl_name.trim() === "") {
          let temp_err = {
            message: "กรุณากรอก SLA Level Group Name",
          };
          error_list.push(temp_err);
        }
        // console.log("tempTimeMinor: ",tempTimeMinor);
        if (isNaN(tempTimeMinor)) {
          let temp_err = {
            message: "กรุณากรอก Time of Minor Level",
          };
          error_list.push(temp_err);
        }
        if (isNaN(tempTimeAverage)) {
          let temp_err = {
            message: "กรุณากรอก Time of Average Level",
          };
          error_list.push(temp_err);
        }
        if (isNaN(tempTimeMajor)) {
          let temp_err = {
            message: "กรุณากรอก Time of Major Level",
          };
          error_list.push(temp_err);
        }
        if (isNaN(tempTimeCritical)) {
          let temp_err = {
            message: "กรุณากรอก Time of Critical Level",
          };
          error_list.push(temp_err);
        }
        if (isNaN(tempTimeResponse)) {
          let temp_err = {
            message: "กรุณากรอกเวลาในการตอบสนองรับ Ticket",
          };
          error_list.push(temp_err);
        }

        // console.log("error_list", error_list);
        if (error_list.length > 0) {
          var err_message = "";
          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }
          Swal.fire("Error", err_message, "error");
        } else {
          if (SLA_Level_List.mode === "Add") {
            axios({
              method: "post",
              url: `${Configs.API_Ticket}/api/sla_level/addSLA_Level`,
              headers: {
                Authorization: getToken(),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
              },
              data: temp,
            })
              .then(function (response) {
                if (response.data) {
                  Swal.fire({
                    icon: "success",
                    title: "บันทึกสำเร็จ",
                    showConfirmButton: false,
                    timer: 1500,
                  }).then((result) => {
                    // console.log("บันทึกสำเร็จ", result);

                    setSLA_Level_List({
                      ...SLA_Level_List,
                      // mode: "add",
                      sl_name: "",
                      sl_minor: "",
                      sl_average: "",
                      sl_major: "",
                      sl_critical: "",
                      sl_time_minor: "",
                      sl_time_average: "",
                      sl_time_major: "",
                      sl_time_critical: "",
                      sl_response_time: "",
                    });

                    onClose();
                    filterSLA_Level();
                  });
                }
              })
              .catch(function (error) {
                console.log(error);
                Swal.fire(
                  "แจ้งเตือน",
                  error.response.data.errorMessage,
                  "error"
                );
              });
          } else if (SLA_Level_List.mode === "Edit") {
            // console.log("SLA_Level_List ใน", SLA_Level_List);
            axios({
              method: "put",
              url: `${Configs.API_Ticket}/api/sla_level/editSLA/${SLA_Level_List.sl_id}`,
              headers: {
                Authorization: getToken(),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
              },
              data: temp,
            })
              .then(function (response) {
                if (response.data) {
                  Swal.fire({
                    icon: "success",
                    title: "บันทึกสำเร็จ",
                    showConfirmButton: false,
                    timer: 1500,
                  }).then((result) => {
                    // console.log("บันทึกสำเร็จ", result);

                    setSLA_Level_List({
                      ...SLA_Level_List,
                      sl_name: "",
                      sl_minor: "",
                      sl_average: "",
                      sl_major: "",
                      sl_critical: "",
                      sl_time_minor: "",
                      sl_time_average: "",
                      sl_time_major: "",
                      sl_time_critical: "",
                      sl_response_time: "",
                    });

                    onClose();
                    filterSLA_Level();
                  });
                }
              })
              .catch(function (error) {
                console.log(error);
                Swal.fire(
                  "แจ้งเตือน",
                  error.response.data.errorMessage,
                  "error"
                );
              });
          }
        }
      }
    });
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: 150,
      fontSize: 16,
      marginTop: "-18px",
      marginLeft: "-9px",
    }),
  };

  const onChange = (e) => {
    // console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };

  async function getByIdSLA_Level(id) {
    // console.log("GetByID: ",id);
    await axios({
      method: "get",
      url: `${Configs.API_Ticket}/api/sla_level/getByid/${id}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {
        //  console.log("GetById: ",response.data);

        setSLA_Level_List({
          ...SLA_Level_List,
          mode: "Edit",
          sl_id: id,
          sl_name: response.data.sl_name,
          sl_minor: parseInt(response.data.sl_minor),
          sl_average: parseInt(response.data.sl_average),
          sl_major: parseInt(response.data.sl_major),
          sl_critical: parseInt(response.data.sl_critical),
          sl_minor_hour: response.data.sl_minor_hour || null,
          sl_average_hour: response.data.sl_average_hour || null,
          sl_major_hour: response.data.sl_major_hour || null,
          sl_critical_hour: response.data.sl_critical_hour || null,
          sl_minor_min: response.data.sl_minor_min || null,
          sl_average_min: response.data.sl_average_min || null,
          sl_major_min: response.data.sl_major_min || null,
          sl_critical_min: response.data.sl_critical_min || null,
          sl_response_time_hour: response.data.sl_response_time_hour || null,
          sl_response_time_min: response.data.sl_response_time_min || null,
        });

        settest({ ...test, test: "" });
        test.test = "";
        showDrawer();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  //  console.log("SLA_Level_List: ", SLA_Level_List);
  const onChangePage = (page, pageSize) => {
    console.log("Page:", page);
    setCurrentPage(page);
    setpageSize(pageSize);
  };
  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      filterSLA_Level();
    }
  };

  function filterSLA_Level() {
    Swal.fire({
      title: "ระบบ",
      text: `ระบบกำลังอัพเดตข้อมูล`,
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    setSLAList([]);
    let temp = {
      search_text: Filter.search_text.trim() || "",
      current: currentPage || 1,
      pageSize: pageSize || 10,
    };
    axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/sla_level/selectSLA_Level`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        // console.log(response.data);

        Settotal(response.data.AllDataTotal);
        response.data._data.forEach((item) => {
          setSLAList((prevList) => [
            ...prevList,
            {
              id: item.sl_id,
              sl_name: item.sl_name,
              sl_minor: item.sl_minor,
              sl_average: item.sl_average,
              sl_major: item.sl_major,
              sl_critical: item.sl_critical,
              sl_create_date: item.sl_create_date,
              sl_update_date: item.sl_update_date,
              sl_is_use: item.sl_is_use,
              sl_is_active: item.sl_is_active,
              sl_average_hour: item.sl_average_hour,
              sl_major_hour: item.sl_major_hour,
              sl_minor_hour: item.sl_minor_hour,
              sl_critical_hour: item.sl_critical_hour,
              sl_response_time_hour: item.sl_response_time_hour,
              sl_response_time_min: item.sl_response_time_min,
              sl_response_time: item.sl_response_time,
              projects: item.projects,
            },
          ]);
        });
        Swal.close();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire(
          "แจ้งเตือน",
          error.response.data.error[0].errorMessage,
          "error"
        );
      });
  }

  async function delSLA_Level(id) {
    Swal.fire({
      title: "คุณต้องการลบข้อมูลนี้หรือไม่ ?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: `${Configs.API_Ticket}/api/sla_level/del_SLA/${id}`,
          headers: {
            Authorization: getToken(),
            "X-TTT": `${Configs.API_TTT}`,
            "Content-Type": "application/json",
          },
        })
          .then(async function (response) {
            filterSLA_Level();
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
          });
      }
    });
  }

  const formatDuration = (minutes) => {
    const days = Math.floor(minutes / (60 * 24));
    const hours = Math.floor((minutes % (60 * 24)) / 60);
    const mins = minutes % 60;
    return `${days} day ${hours} hr ${mins} min`;
  };

  function convertMinutesToHoursAndMinutes(totalMinutes) {
    // console.log('totalMinutes', totalMinutes)
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours} hr ${minutes} min`;
  }

  const styleLevel = {
    display: "flex",
    flexDirection: "column",
    fontSize: "12px",
    width: "60%",
    alignItems: "center",
    borderRadius: "10px",
    fontStyle: "italic",
  };
  const cardData = [];
  SLAList.forEach((item) => {
    cardData.push({
      id: item.id,
      content: (
        // <div style={{ width: "50%" }}>
        <>
          <Row align="bottom" style={{ alignItems: "center", height: "100%" }}>
            <Col className="gutter-row" span={5}>
              <Row>
                <Col>
                  <span
                    style={{
                      fontSize: "20px",
                      whiteSpace: 'pre-wrap',
                      fontWeight: 700,
                    }}
                  >{`${item.sl_name}`}</span>
                </Col>
              </Row>
            </Col>

            <Col className="gutter-row" span={17}>
              <Row>
                <Col
                  span={3}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      ...styleLevel,
                      color: "#808080",
                      background: "#DBDBDB",
                    }}
                  >
                    Minor
                  </span>
                  <Tooltip
                    overlay={
                      <div style={{ color: "#FFFFFF" }}>
                        {formatDuration(item.sl_minor)}
                      </div>
                    }
                    color="#000000"
                  >
                    <div style={{ fontSize: "14px", fontWeight: "500" }}>
                      {" "}
                      <i
                        class="far fa-clock"
                        style={{ marginTop: "5px", color: "#007BFF" }}
                      ></i>{" "}
                      &nbsp;{" "}
                      {`${convertMinutesToHoursAndMinutes(item.sl_minor)}`}
                    </div>
                  </Tooltip>
                </Col>

                <Col
                  span={3}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      ...styleLevel,
                      color: "#FF7B7F",
                      background: " #FFE7E8",
                    }}
                  >
                    Average
                  </span>
                  <Tooltip
                    overlay={
                      <div style={{ color: "#FFFFFF" }}>
                        {formatDuration(item.sl_average)}
                      </div>
                    }
                    color="#000000"
                  >
                    <div style={{ fontSize: "14px", fontWeight: "500" }}>
                      {" "}
                      <i
                        class="far fa-clock"
                        style={{ marginTop: "5px", color: "#007BFF" }}
                      ></i>{" "}
                      &nbsp;{" "}
                      {`${convertMinutesToHoursAndMinutes(item.sl_average)}`}
                    </div>
                  </Tooltip>
                </Col>

                <Col
                  span={3}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      ...styleLevel,
                      color: "#FEC400",
                      background: "#FBF0D2",
                    }}
                  >
                    Major
                  </span>
                  <Tooltip
                    overlay={
                      <div style={{ color: "#ffffff" }}>
                        {formatDuration(item.sl_major)}
                      </div>
                    }
                    color="#000000"
                  >
                    <div style={{ fontSize: "14px", fontWeight: "500" }}>
                      {" "}
                      <i
                        class="far fa-clock"
                        style={{ marginTop: "5px", color: "#007BFF" }}
                      ></i>{" "}
                      &nbsp;{" "}
                      {`${convertMinutesToHoursAndMinutes(item.sl_major)}`}
                    </div>
                  </Tooltip>
                </Col>

                <Col
                  span={3}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      ...styleLevel,
                      color: "#FF7B7F",
                      background: " #FFE7E8",
                    }}
                  >
                    Critical
                  </span>
                  <Tooltip
                    overlay={
                      <div style={{ color: "#ffffff" }}>
                        {formatDuration(item.sl_critical)}
                      </div>
                    }
                    color="#000000"
                  >
                    <div style={{ fontSize: "14px", fontWeight: "500" }}>
                      {" "}
                      <i
                        class="far fa-clock"
                        style={{ marginTop: "5px", color: "#007BFF" }}
                      ></i>{" "}
                      &nbsp;{" "}
                      {`${convertMinutesToHoursAndMinutes(item.sl_critical)}`}
                    </div>
                  </Tooltip>
                </Col>

                <Col
                  span={3}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      ...styleLevel,
                      color: "gray",
                      fontSize: "12px",
                      width: "100%",
                    }}
                  >
                    Create Date
                  </span>
                  <div style={{ fontSize: "14px", fontWeight: "500" }}>
                    {" "}
                    <i
                      class="fa fa-calendar"
                      style={{ marginTop: "5px", color: "#007BFF" }}
                    ></i>{" "}
                    &nbsp; {moment(item.sl_create_date).format("YYYY-MM-DD")}
                  </div>
                </Col>

                <Col
                  span={3}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      ...styleLevel,
                      color: "gray",
                      fontSize: "12px",
                      width: "100%",
                    }}
                  >
                    Update Date
                  </span>

                  <div style={{ fontSize: "14px", fontWeight: "500" }}>
                    {" "}
                    <i
                      class="fa fa-calendar"
                      style={{ marginTop: "5px", color: "#007BFF" }}
                    ></i>{" "}
                    &nbsp; {moment(item.sl_update_date).format("YYYY-MM-DD")}
                  </div>
                </Col>

                <Col
                  span={5}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      ...styleLevel,
                      color: "gray",
                      fontSize: "12px",
                      width: "100%",
                    }}
                  >
                    เวลาในการตอบสนองรับ Ticket
                  </span>
                  <div style={{ fontSize: "14px", fontWeight: "500" }}>
                    {" "}
                    <i
                      class="far fa-clock"
                      style={{ marginTop: "5px", color: "#007BFF" }}
                    ></i>{" "}
                    &nbsp;{" "}
                    {`${convertMinutesToHoursAndMinutes(
                      item.sl_response_time
                    )}`}
                  </div>
                </Col>
              </Row>
            </Col>

            <Col className="gutter-row" span={2}>
              <Row>
                <Col
                  span={11}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <span
                    style={{
                      color: "gray",
                      fontSize: "12px",
                      fontStyle: "italic",
                    }}
                  >
                    Active
                  </span>

                  <div style={{ color: "#007BFF" }}>
                    <Tooltip
                      overlay={
                        <div>
                          <div
                            className="tooltip-title"
                            style={{
                              color: "#000",
                              fontWeight: "bold",
                              borderBottom: "1px solid #d3d3d3",
                              paddingBottom: "3px",
                              // width: "100%"
                            }}
                          >
                            <span>Active</span>
                          </div>
                          <div
                            className="tooltip-content"
                            style={{ color: "#000" }}
                          >
                            {item.projects.length > 0 ? (
                              <>
                                {item.projects.map((project, index) => (
                                  <div key={index}>
                                    {`${project.pr_project_code}-${project.pr_name}`}
                                  </div>
                                ))}
                              </>
                            ) : (
                              <span style={{ color: "#000" }}>
                                No Project Active
                              </span>
                            )}
                          </div>
                        </div>
                      }
                      color="#FFFFFF"
                    >
                      <span style={{ fontSize: "18px" }}>
                        {item.projects.length}
                      </span>
                    </Tooltip>
                  </div>
                </Col>

                <Col
                  span={8}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      borderRight: "3px solid #ccc",
                      height: "100%",
                      borderRadius: "10px",
                    }}
                  ></div>
                </Col>

                <Col
                  span={5}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // justifyContent: "flex-start",
                  }}
                >
                  <Dropdown
                    overlay={
                      <div>
                        <Menu>
                          <div
                            style={{
                              color: "gray",
                              cursor: "default",
                              padding: "8px 12px", // Adds padding to match other items' spacing
                            }}
                          >
                            Management
                          </div>
                          {[
                            item.projects.length === 0
                              ? {
                                  label: (
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ color: "orange" }}
                                      onClick={() => {
                                        getByIdSLA_Level(item.id);
                                        setSLA_Level_List({
                                          ...SLA_Level_List,
                                          mode: "Edit",
                                        });
                                      }}
                                    >
                                      <i
                                        className="far fa-trash-alt"
                                        style={{
                                          marginTop: "5px",
                                          color: "orange",
                                          marginRight: "8px",
                                        }}
                                      ></i>
                                      Edit SLA
                                    </a>
                                  ),
                                  key: "0",
                                }
                              : null,
                            item.projects.length === 0
                              ? {
                                  label: (
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ color: "#DD2025" }}
                                      onClick={() => {
                                        delSLA_Level(item.id);
                                      }}
                                    >
                                      <i
                                        className="far fa-trash-alt"
                                        style={{
                                          marginTop: "5px",
                                          color: "#DD2025",
                                          marginRight: "8px",
                                        }}
                                      ></i>
                                      Delete SLA
                                    </a>
                                  ),
                                  key: "1",
                                }
                              : null,
                          ]
                            .filter((item) => item !== null)
                            .map((item) => (
                              <Menu.Item key={item.key}>{item.label}</Menu.Item>
                            ))}
                        </Menu>
                      </div>
                    }
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>
                        <img
                          src={img_Three_dot}
                          alt="Three dots"
                          width={25}
                          height={25}
                        />
                      </Space>
                    </a>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      ),
    });
  });
  return (
    <div className="content-wrapper ">
      <section className="content-header">
        <div
          className="container-fluid"
          style={{ marginTop: "10px", marginLeft: "12px" }}
        >
          <div className="row">
            <div
              className="col-12 col-sm-4 col-xl-6"
              style={{ display: "flex" }}
            >
              <img
                alt="..."
                className="img_header_ticket"
                src={img_header_ticket}
                width={30}
                height={30}
              />
              <h1
                style={{
                  fontWeight: 700,
                  fontSize: "55px",
                  marginTop: "-16px",
                  marginLeft: "18px",
                }}
              >
                SLA Level
              </h1>
            </div>

            <div className="col-12 col-sm-8 col-xl-6 ">
              <ol className="breadcrumb float-sm-right ">
                <li className="breadcrumb-item active">
                  Home
                  {/* <a href="/Welcome">Home</a> */}
                </li>
                <li className="breadcrumb-item active">
                  Setting Permission/SLA
                </li>
                <li className="breadcrumb-item">SLA Level</li>
                {/* <li className="breadcrumb-item active">{mode}</li> */}
              </ol>
            </div>
          </div>

          <div className="row">
            <div
              className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 mb-3"
              style={{ paddingLeft: "14px" }}
            >
              <div className="row mt-6">
                <p style={{ fontSize: "18px" }}>Search</p>
              </div>
              <div
                className="row mr-2"
                style={{ marginTop: "-18px", minWidth: "120px" }}
              >
                <input
                  // style={{ marginLeft: "-9px" }}
                  type="search"
                  id="search"
                  className="form-control"
                  placeholder="search"
                  value={Filter.search_text}
                  onChange={(e) => {
                    // setCurrentPage(1)
                    setFilter({
                      ...Filter,
                      search_text: e.target.value,
                    });
                    Filter.search_text = e.target.value;
                  }}
                  onKeyPress={handleEnterKeyPress}
                />
              </div>
            </div>

            <div className="row col-12 col-sm-12 col-md-4 col-lg-4 col-xl-5 mt-4">
              <div
                className="col-12 col-sm-24 col-md-4 col-lg-4 col-xl-4 "
                style={{
                  marginBottom: "10px",
                  justifyContent: "end",
                  display: "flex",
                }}
              >
                <Button
                  size="large"
                  type="primary"
                  style={{
                    border: "none",
                    backgroundColor: "#007BFF",
                    minWidth: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "38px",
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    setCurrentPage(1)
                    filterSLA_Level();
                  }}
                >
                  Search
                </Button>
              </div>
              <div
                className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 "
                style={{ marginBottom: "10px" }}
              >
                <Button
                  size="large"
                  type="primary"
                  value={Filter.search_text}
                  style={{
                    border: "none",
                    backgroundColor: "#007BFF",
                    minWidth: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "38px",
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    setCurrentPage(1)
                    setFilter({
                      ...Filter,
                      search_text: "",
                    });
                    Filter.search_text = "";
                    filterSLA_Level();
                  }}
                >
                  {/* <span style={{ fontSize: "20px" }}></span> */}
                  Clear
                </Button>
              </div>
            </div>

            <div className="col-12  col-sm-0 col-md-3 col-lg-3 col-xl-2 mb-3">
              <div className="row mt-6">
                {/* <p style={{ fontSize: "18px" }}>Project Code</p> */}
              </div>
              <div className="row-4" style={{ marginTop: "-18px" }}>
                {/* <Select
            className="Process"
            placeholder="All"
            id="Process"
            styles={customStyles}
            /> */}
              </div>
            </div>

            <div className="col-12  col-sm-12 col-md-2 col-lg-2 col-xl-2 mt-4">
              <div
                className="row justify-content-end "
                // style={{ marginRight: "15px" }}
              >
                <Button
                  style={{
                    width: "100%",
                    height: "38px",
                    backgroundColor: "#007BFF",
                    border: "none",
                    minWidth: "150px",
                  }}
                  onClick={() => {
                    setSLA_Level_List({
                      ...SLA_Level_List,
                      mode: "Add",
                      sl_name: "",
                      sl_minor: "",
                      sl_average: "",
                      sl_major: "",
                      sl_critical: "",
                      sl_time_minor: null,
                      sl_time_average: null,
                      sl_time_major: null,
                      sl_time_critical: null,
                      selectedDaysMinor: "",
                      selectedDaysAverage: "",
                      selectedDaysMajor: "",
                      selectedDaysCritical: "",
                      sl_date_minor: null,
                      sl_date_average: null,
                      sl_date_major: null,
                      sl_date_critical: null,
                      sl_minor_hour: "",
                      sl_minor_min: "",
                      sl_average_hour: "",
                      sl_average_min: "",
                      sl_major_hour: "",
                      sl_major_min: "",
                      sl_critical_hour: "",
                      sl_critical_min: "",
                      sl_response_time: "",
                      sl_response_time_hour: "",
                      sl_response_time_min: "",
                    });
                    // settest({...test,test:''});
                    // test.test = '';
                    showDrawer();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                    style={{marginTop:"-4px"}}
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                  </svg>
                  {"  "}Add SLA Level
                </Button>
              </div>
            </div>
          </div>

          <Table_All_Project
            currentPage={currentPage}
            pageSize={pageSize}
            onChange={onChangePage}
            cardData={cardData}
            onPageChange={handlePageChange}
            onShowSizeChange={onShowSizeChange}
            total={total}
            minWidth={"1550px"}
          />
        </div>
      </section>

      <ConfigProvider>
        <Drawer
          title={`${SLA_Level_List.mode} SLA Level`}
          placement="right"
          onClose={onClose}
          open={open}
          width={390}
          style={{ position: "absolute", paddingTop: "55px" }}
          extra={
            <Space>
              <Button
                style={{
                  color: "black",
                  backgroundColor: "white",
                  borderColor: "gray",
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                style={{
                  backgroundColor: "#007BFF",
                  border: "none",
                  width: "70px",
                }}
                onClick={Save_SLA_Level}
              >
                OK
              </Button>
            </Space>
          }
        >
          <div className="row" style={{ marginLeft: "2px" }}>
            SLA Level Group Name<font color="red">*</font>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <Input
                value={SLA_Level_List.sl_name}
                onChange={(e) => {
                  // setValue(e.target.value);
                  // console.log(e.target.value);
                  setSLA_Level_List({
                    ...SLA_Level_List,
                    sl_name: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div
            className="row mt-3"
            style={{ marginLeft: "2px", fontSize: "13px" }}
          >
            กำหนดเงื่อนไขลำดับการให้บริการในแต่ระดับชั้นความรุนแรง
          </div>
          <div className="row" style={{ marginLeft: "2px", fontSize: "11px" }}>
            <font color="red">
              **ระบุจำนวนชั่วโมง และนาทีที่ต้องการให้ดำเนินการแก้ไขเสร็จสิ้น**
            </font>
          </div>

          <div className="row mt-3" style={{ marginLeft: "2px" }}>
            เวลาในการตอบสนองรับ Ticket<font color="red">*</font>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <div className="row">
                <Input
                  style={{ height: "40px", width: "162px", marginLeft: "8px" }}
                  placeholder="Hours"
                  type="number"
                  value={SLA_Level_List.sl_response_time_hour}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d*$/.test(inputValue)) {
                      setSLA_Level_List({
                        ...SLA_Level_List,
                        sl_response_time_hour: inputValue,
                      });
                    }
                  }}
                />
                &nbsp;&nbsp;
                <Input
                  style={{ height: "40px", width: "162px" }}
                  placeholder="Minutes"
                  type="number"
                  value={SLA_Level_List.sl_response_time_min}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d*$/.test(inputValue)) {
                      setSLA_Level_List({
                        ...SLA_Level_List,
                        sl_response_time_min: inputValue,
                      });
                    }
                  }}
                />
              </div>

              {/* <DatePicker 
              style={{height:"40px"}} 
              onChange={onChangeDatePickerMinor} 
              value={SLA_Level_List.sl_date_minor ? dayjs(SLA_Level_List.sl_date_minor) : null}
              disabledDate={current => current && current < dayjs().startOf('day')}
              /> */}

              {/* <TimePicker style={{width:"173px", height:"40px"}} 
              onChange={onChangeTimeMinor} 
              value={SLA_Level_List.sl_time_minor
                ? dayjs(SLA_Level_List.sl_time_minor, 'HH:mm:ss') : null
              }
              defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
              allowClear={true}
               /> */}
            </div>
          </div>

          <div className="row mt-3" style={{ marginLeft: "2px" }}>
            Time of Minor Level (Minutes)<font color="red">*</font>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <div className="row">
                <Input
                  style={{ height: "40px", width: "162px", marginLeft: "8px" }}
                  placeholder="Hours"
                  type="number"
                  value={SLA_Level_List.sl_minor_hour}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d*$/.test(inputValue)) {
                      setSLA_Level_List({
                        ...SLA_Level_List,
                        sl_minor_hour: inputValue,
                      });
                    }
                  }}
                />
                &nbsp;&nbsp;
                <Input
                  style={{ height: "40px", width: "162px" }}
                  placeholder="Minutes"
                  type="number"
                  value={SLA_Level_List.sl_minor_min}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d*$/.test(inputValue)) {
                      setSLA_Level_List({
                        ...SLA_Level_List,
                        sl_minor_min: inputValue,
                      });
                    }
                  }}
                />
              </div>

              {/* <DatePicker 
              style={{height:"40px"}} 
              onChange={onChangeDatePickerMinor} 
              value={SLA_Level_List.sl_date_minor ? dayjs(SLA_Level_List.sl_date_minor) : null}
              disabledDate={current => current && current < dayjs().startOf('day')}
              /> */}

              {/* <TimePicker style={{width:"173px", height:"40px"}} 
              onChange={onChangeTimeMinor} 
              value={SLA_Level_List.sl_time_minor
                ? dayjs(SLA_Level_List.sl_time_minor, 'HH:mm:ss') : null
              }
              defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
              allowClear={true}
               /> */}
            </div>
          </div>

          <div className="row mt-3" style={{ marginLeft: "2px" }}>
            Time of Average Level (Minutes)<font color="red">*</font>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <div className="row">
                <Input
                  style={{ height: "40px", width: "162px", marginLeft: "8px" }}
                  placeholder="Hours"
                  type="number"
                  value={SLA_Level_List.sl_average_hour}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d*$/.test(inputValue)) {
                      setSLA_Level_List({
                        ...SLA_Level_List,
                        sl_average_hour: inputValue,
                      });
                    }
                  }}
                />
                &nbsp;&nbsp;
                <Input
                  style={{ height: "40px", width: "162px" }}
                  placeholder="Minutes"
                  type="number"
                  value={SLA_Level_List.sl_average_min}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d*$/.test(inputValue)) {
                      setSLA_Level_List({
                        ...SLA_Level_List,
                        sl_average_min: inputValue,
                      });
                    }
                  }}
                />
              </div>
              {/* <DatePicker style={{height:"40px"}}
              onChange={onChangeDatePickerAverage} 
              value={SLA_Level_List.sl_date_average ? dayjs(SLA_Level_List.sl_date_average) : null}
              disabledDate={current => current && current < dayjs().startOf('day')}
              />&nbsp;&nbsp;
              <TimePicker style={{width:"173px", height:"40px"}} 
              onChange={onChangeTimeAverage} 
              value={SLA_Level_List.sl_time_average
                ? dayjs(SLA_Level_List.sl_time_average, 'HH:mm:ss'):null }
                defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
               /> */}
            </div>
          </div>

          <div className="row mt-3" style={{ marginLeft: "2px" }}>
            Time of Major Level (Minutes)<font color="red">*</font>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <div className="row">
                <Input
                  style={{ height: "40px", width: "162px", marginLeft: "8px" }}
                  placeholder="Hours"
                  type="number"
                  value={SLA_Level_List.sl_major_hour}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d*$/.test(inputValue)) {
                      setSLA_Level_List({
                        ...SLA_Level_List,
                        sl_major_hour: inputValue,
                      });
                    }
                  }}
                />
                &nbsp;&nbsp;
                <Input
                  style={{ height: "40px", width: "162px" }}
                  placeholder="Minutes"
                  type="number"
                  value={SLA_Level_List.sl_major_min}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d*$/.test(inputValue)) {
                      setSLA_Level_List({
                        ...SLA_Level_List,
                        sl_major_min: inputValue,
                      });
                    }
                  }}
                />
              </div>
              {/* <DatePicker style={{height:"40px"}}
              onChange={onChangeDatePickerMajor} 
              value={SLA_Level_List.sl_date_major ? dayjs(SLA_Level_List.sl_date_major) : null}
              disabledDate={current => current && current < dayjs().startOf('day')}
              />&nbsp;&nbsp;
              <TimePicker style={{width:"173px", height:"40px"}} 
              onChange={onChangeTimeMajor} 
              value={SLA_Level_List.sl_time_major 
                ? dayjs(SLA_Level_List.sl_time_major, 'HH:mm:ss')
              : null}
              defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
               /> */}
            </div>
          </div>

          <div className="row mt-3" style={{ marginLeft: "2px" }}>
            Time of Critical Level (Minutes)<font color="red">*</font>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <div className="row">
                <Input
                  style={{ height: "40px", width: "162px", marginLeft: "8px" }}
                  placeholder="Hours"
                  type="number"
                  value={SLA_Level_List.sl_critical_hour}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d*$/.test(inputValue)) {
                      setSLA_Level_List({
                        ...SLA_Level_List,
                        sl_critical_hour: inputValue,
                      });
                    }
                  }}
                />
                &nbsp;&nbsp;
                <Input
                  style={{ height: "40px", width: "162px" }}
                  placeholder="Minutes"
                  type="number"
                  value={SLA_Level_List.sl_critical_min}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d*$/.test(inputValue)) {
                      setSLA_Level_List({
                        ...SLA_Level_List,
                        sl_critical_min: inputValue,
                      });
                    }
                  }}
                />
              </div>
              {/* <DatePicker style={{height:"40px"}}
              onChange={onChangeDatePickerCritical} 
              value={SLA_Level_List.sl_date_critical ? dayjs(SLA_Level_List.sl_date_critical) : null}
              disabledDate={current => current && current < dayjs().startOf('day')}
              />&nbsp;&nbsp;
              <TimePicker style={{width:"173px", height:"40px"}} 
              onChange={onChangeTimeCritical} 
                value={SLA_Level_List.sl_time_critical
                ? dayjs(SLA_Level_List.sl_time_critical, 'HH:mm:ss')
                : null} 
                defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
               /> */}
            </div>
          </div>
        </Drawer>
      </ConfigProvider>
    </div>
  );
}
export default SLA_Level;
