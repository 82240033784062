import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser, getAcademy } from "../../Utils/Common";
import { useParams } from "react-router";
import Select from "react-select";

function Manage_team_add(mode) {
  const [disable, setdisable] = useState(null);
  const [pageMode, setPageMode] = useState("");
  const { id } = useParams();

  const [Employee, setEmployee] = useState([]);
  const [headTeam, setHeadTeam] = useState([]);
  const [Employee_select, setEmployee_select] = useState([]);
  const [filler_employee, setfiller_employee] = useState({
    oem_id: getOem(),
    company_id: getUser().com,
    emp_no: "",
    emp_name_th: "",
  });
  const [team, setTeam] = useState({
    team_name: "",
    head_id: "",
    company_id: getUser().com,
    oem_id: getOem(),
    team_member: Employee_select,
    created_by: getUser().fup,
    updated_by: getUser().fup,
    current_headTeam: "",
  });
  const [test, settest] = useState({
    test: "",
  });
  /*  const select_headTeam = useRef(); */
  useEffect(async () => {
    if (mode.mode === "read" || mode.mode === "edit") {
      await GetList_view();
    } else {
      await GetEmployee();
    }

    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);
  const [MDBT, setMDBT] = useState(true);

  async function GetEmployee(select_emp) {
    var get_filler_employee = {
      oem_id: getOem(),
      company_id: getUser().com,
      emp_no: filler_employee.emp_no.trim(),
      emp_name_th: filler_employee.emp_name_th.trim(),
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/filterEmployeeForTeam",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_employee,
    })
      .then(async function (response) {
        response.data.data.sort((a, b) => {
          if (a.emp_no === null) {
            return 1;
          }

          if (b.emp_no === null) {
            return -1;
          }

          if (a.emp_no.toLowerCase() === b.emp_no.toLowerCase()) {
            return 0;
          }

          return a.emp_no.toLowerCase() < b.emp_no.toLowerCase() ? -1 : 1;
        });
        
        const EmpDataAll = [];
        setEmployee([]);

        console.log("select_emp", response.data.data);
        for (let item of response.data.data) {
          var check = [];

          if (select_emp !== null && select_emp !== undefined) {
            check = select_emp.filter((e) => {
              return e.emp_id === item.emp_id;
            });
          } else {
            check = Employee_select.filter((e) => {
              return e.emp_id === item.emp_id;
            });
          }

          if (check.length > 0) {
            item.isPush = true;
          } else {
            item.isPush = false;
          }

          console.log("curent head", team.current_headTeam);

          if (
            item.emp_id !== team.head_id &&
            item.emp_id !== team.current_headTeam
          ) {
            EmpDataAll.push(item);
          }
        }

        setEmployee(EmpDataAll);
        setMDBT(true);

        settest({ ...test, test: "" });
        test.test = "";
      })
      .catch(function (error) {
        console.log(error);
      });

    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/filterEmployeeForHeadTeam",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_employee,
    })
      .then(async function (response) {
        response.data.data.sort((a, b) => {
          if (a.emp_no === null) {
            return 1;
          }

          if (b.emp_no === null) {
            return -1;
          }

          if (a.emp_no.toLowerCase() === b.emp_no.toLowerCase()) {
            return 0;
          }

          return a.emp_no.toLowerCase() < b.emp_no.toLowerCase() ? -1 : 1;
        });

        const options = [{ value: null, label: "เลือก" }];
        console.log("setHeadTeam", response.data.data);
        await response.data.data.forEach((el) => {
          var element = {};
          element["value"] = el.emp_id;
          element["label"] = el.emp_name_th;

          if (mode.mode === "add") {
            if (el.emp_is_head) {
              options.push(element);
            }
          } else {
            options.push(element);
          }
        });

        setHeadTeam(options);

        settest({ ...test, test: "" });
        test.test = "";
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function reNewEmployee() {
    if (team.head_id !== "") {
      const EmpDataAll = Employee;
      setEmployee([]);
      let indexOf = EmpDataAll.findIndex(
        (element) => element.emp_id === team.head_id
      );
      EmpDataAll.splice(indexOf, 1);

      setEmployee(EmpDataAll);

      settest({ ...test, test: "" });
      test.test = "";
    }
  }

  const GetList_view = async () => {
    if (mode.mode === "read" || mode.mode === "edit") {
      axios({
        method: "get",
        url:
          Configs.API_URL_hrMagenatement +
          "/api/hrManagement/getTeamByID?id=" +
          id,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          response.data.data.team_member_list.sort((a, b) => {
            if (a.emp_no === null) {
              return 1;
            }
  
            if (b.emp_no === null) {
              return -1;
            }
  
            if (a.emp_no.toLowerCase() === b.emp_no.toLowerCase()) {
              return 0;
            }
  
            return a.emp_no.toLowerCase() < b.emp_no.toLowerCase() ? -1 : 1;
          });
          
          console.log(response.data.data);
          setEmployee_select(response.data.data.team_member_list);
          settest({ ...test, test: "" });
          test.test = "";

          //Employee_select = response.data.data.team_member_list;
          setTeam({
            ...team,
            team_name: response.data.data.team_name,
            head_id: response.data.data.head_id,
            company_id: getUser().com,
            oem_id: getOem(),
            team_member: response.data.data.team_member_list,
            created_by: getUser().fup,
            updated_by: getUser().fup,
            current_headTeam: "",
          });

          team.head_id = response.data.data.head_id;
          // team.team_member = response.data.data.team_member_list;
          settest({ ...test, test: "" });
          test.test = "";

          GetEmployee(response.data.data.team_member_list);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const emp_data_select = [];

  for (var index = 0; index < Employee_select.length; index++) {
    const rowItem = {};

    rowItem["no"] = index + 1;
    rowItem["emp_name_th"] = Employee_select[index].emp_name_th;
    rowItem["emp_no"] = Employee_select[index].emp_no;
    rowItem["emp_line"] = Employee_select[index].line_id;
    rowItem["emp_mobile"] = Employee_select[index].mobile;
    rowItem["mgt"] = (
      <>
        <div className="row">
          <div className="col-12">
            <button
              type="button"
              disabled={disable}
              id={Employee_select[index].emp_id}
              onClick={removeEmp.bind(this)}
              class="btn btn-block btn-danger "
            >
              Remove
            </button>
          </div>
        </div>
      </>
    );
    emp_data_select.push(rowItem);
  }

  const TableTeamMember = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อ - นามสกุล ",
        field: "emp_name_th",
        sort: "asc",
        width: 50,
      },
      {
        label: "รหัสพนักงาน",
        field: "emp_no",
        sort: "asc",
        width: 50,
      },

      {
        label: "Line",
        field: "emp_line",
        sort: "asc",
        width: 50,
      },
      {
        label: "เบอร์ติดต่อ",
        field: "emp_mobile",
        sort: "asc",
        width: 50,
      },

      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: emp_data_select,
  };

  function removeEmp(e) {
    const employee_p = Employee;
    setEmployee([]);
    for (let i of employee_p) {
      if (i.emp_id === e.target.id) {
        i.isPush = false;
      }
    }
    setEmployee(employee_p);
    const emp_select_temp = Employee_select;
    setEmployee_select([]);
    let indexOf = emp_select_temp.findIndex(
      (element) => element.emp_id === e.target.id
    );
    emp_select_temp.splice(indexOf, 1);
    setEmployee_select(emp_select_temp);
    settest({ ...test, test: "" });
    test.test = "";
    //console.log(data[0]);
  }

  function pushEmp(e) {
    // console.log(e.target.id);
    const emp_select_temp = Employee_select;
    var data = Employee.filter((element) => {
      return element.emp_id === e.target.id;
    });
    if (e.target.name === "ย้ายทีม") {
      Swal.fire({
        title: "คุณเเน่ใจที่ต้องการย้ายทีมของ " + data[0].emp_name_th,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Yes`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          const employee_p = Employee;
          setEmployee([]);
          for (let i of employee_p) {
            if (i.emp_id === e.target.id) {
              i.isPush = true;
            }
          }
          setEmployee(employee_p);
          // console.log(emp_select_temp);
          var checked = emp_select_temp.filter((e2) => {
            return e2.emp_id === data[0].emp_id;
          });
          // console.log(checked)
          if (checked.length > 0) {
            console.log("ซ้ำ");
          } else {
            emp_select_temp.push(data[0]);
          }
          setEmployee_select([]);
          setEmployee_select(emp_select_temp);
          settest({ ...test, test: "" });
          test.test = "";
        } /* else if (result.isDenied) {
     
      
    } */
      });
    } else {
      const employee_p = Employee;
      setEmployee([]);
      for (let i of employee_p) {
        if (i.emp_id === e.target.id) {
          i.isPush = true;
        }
      }
      setEmployee(employee_p);
      // console.log(emp_select_temp);
      var checked = emp_select_temp.filter((e2) => {
        return e2.emp_id === data[0].emp_id;
      });
      // console.log(checked)
      if (checked.length > 0) {
        console.log("ซ้ำ");
      } else {
        emp_select_temp.push(data[0]);
      }
      setEmployee_select([]);
      setEmployee_select(emp_select_temp);
      settest({ ...test, test: "" });
      test.test = "";
    }
  }

  const Employee_data = [];
  for (var index = 0; index < Employee.length; index++) {
    const rowItem = {};

    rowItem["no"] = index + 1;
    rowItem["emp_name_th"] = Employee[index].emp_name_th;
    rowItem["emp_no"] = Employee[index].emp_no;
    rowItem["emp_line"] = Employee[index].emp_line;
    rowItem["emp_mobile"] = Employee[index].emp_mobile;
    rowItem["mgt"] = (
      <>
        <div className="row">
          {Employee[index].isPush === true ? (
            <>
              <div className="col-12">
                <button
                  type="button"
                  disabled={disable}
                  id={Employee[index].emp_id}
                  onClick={removeEmp.bind(this)}
                  class="btn btn-block btn-danger "
                >
                  Remove
                </button>
              </div>
            </>
          ) : (
            <>
              {Employee[index].team_id === null ? (
                <div className="col-12">
                  <button
                    type="button"
                    name="เพิ่ม"
                    disabled={disable}
                    id={Employee[index].emp_id}
                    onClick={pushEmp.bind(this)}
                    class="btn btn-block btn-primary "
                  >
                    Push
                  </button>
                </div>
              ) : (
                <div className="col-12">
                  <button
                    type="button"
                    disabled={disable}
                    name="ย้ายทีม"
                    id={Employee[index].emp_id}
                    onClick={pushEmp.bind(this)}
                    class="btn btn-block btn-warning "
                  >
                    ย้ายทีม
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
    Employee_data.push(rowItem);
  }

  const TableEmployee = {
    columns: [
      {
        label: "ลำดับ",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อ - นามสกุล ",
        field: "emp_name_th",
        sort: "asc",
        width: 50,
      },
      {
        label: "รหัสพนักงาน",
        field: "emp_no",
        sort: "asc",
        width: 50,
      },

      {
        label: "Line",
        field: "emp_line",
        sort: "asc",
        width: 50,
      },
      {
        label: "เบอร์ติดต่อ",
        field: "emp_mobile",
        sort: "asc",
        width: 50,
      },
      {
        label: "จัดการ",
        field: "mgt",
        sort: "asc",
        width: 80,
      },
    ],
    rows: Employee_data,
  };

  async function clearFilter(params) {
    setfiller_employee({
      oem_id: getOem(),
      company_id: getUser().com,
      emp_no: "",
      emp_name_th: "",
    });
    filler_employee.oem_id = getOem();
    filler_employee.company_id = getUser().com;
    filler_employee.emp_no = "";
    filler_employee.emp_name_th = "";

    GetEmployee();
  }
  function cancleOption(params) {
    window.location.href = "/Human_Resource/organization/manage_team";
  }
  function saveOption(params) {
    const error_list = [];
    if (!team.head_id || team.head_id === "") {
      error_list.push({
        message: "กรุณาระบุ หัวหน้างาน",
      });
    }
    if (team.team_member.length === 0) {
      error_list.push({
        message: "กรุณาระบุ สมาชิกทีม",
      });
    }
    if (!team.team_name || team.team_name === "") {
      error_list.push({
        message: "กรุณาระบุ ชื่อทีม",
      });
    }

    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    } else {
      if (mode.mode === "add") {
        console.log("add", team);
        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL_hrMagenatement + "/api/hrManagement/addTeam",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: team,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Human_Resource/organization/manage_team";
                });
              }
            })
            .catch(function (error) {
              console.log(error.response);
              Swal.fire({
                icon: "warning",
                title: "กรุณากรอกข้อมูลใหม่",
                text: error.response.data.error.message,
              });
            });
        });
      } else if (mode.mode === "edit") {
        const team_temp = {
          id: id,
          team_name: team.team_name,
          head_id: team.head_id,
          company_id: team.company_id,
          oem_id: team.oem_id,
          team_member: team.team_member,
          created_by: team.created_by,
          updated_by: team.updated_by,
        };
        console.log("edit", team);

        Swal.fire({
          title: "Do you want to save the changes?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Yes`,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: `No`,
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "Saving",
              allowEscapeKey: false,
              allowOutsideClick: false,
              timer: 1000,
              onOpen: () => {
                Swal.showLoading();
              },
            }).then((result) => {
              axios({
                method: "post",
                url:
                  Configs.API_URL_hrMagenatement +
                  "/api/hrManagement/updateTeam",
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: team_temp,
              })
                .then(function (response) {
                  if (response.data) {
                    Swal.fire({
                      icon: "success",
                      title: "Save",
                      showConfirmButton: false,
                      timer: 1500,
                    }).then((result) => {
                      window.location.href =
                        "/Human_Resource/organization/manage_team";
                    });
                  }
                })
                .catch(function (error) {
                  console.log(error.response);
                  Swal.fire({
                    icon: "warning",
                    title: "กรุณากรอกข้อมูลใหม่",
                    text: error.response.data.error.message,
                  });
                });
            });
          }
        });
      }
    }
  }
  async function setcurrent_headteam(id) {
    const emp_id = Employee.filter((el) => {
      return el.emp_id === id;
    });
    console.log("current -->head", emp_id);
    setTeam({ ...team, current_headTeam: emp_id[0].head_id });
    team.current_headTeam = emp_id[0].head_id;

    console.log("setcurrent_headteam", emp_id[0].head_id);
  }
  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Manage Team {pageMode} </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">Organization</li>
                  <li className="breadcrumb-item active">
                    Manage Team {pageMode}
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row mb-2">
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    บันทึก
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Manage Taem</h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-6 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={team.team_name}
                      disabled={disable}
                      onChange={(e) => {
                        setTeam({
                          ...team,
                          team_name: e.target.value,
                        });
                      }}
                    />
                    <label>ชื่อทีม</label>
                  </div>
                </div>
                <div
                  className="col-6 col-md-4 col-xl-2"
                  style={{ zIndex: "98" }}
                >
                  <div class="form-group">
                    <Select
                      isDisabled={disable}
                      options={headTeam}
                      value={headTeam.filter((e) => {
                        return e.value === team.head_id;
                      })}
                      /*   ref={select_headTeam} */
                      defaultValue={mode === "add" ? null : team.head_id}
                      onChange={(e) => {
                        //console.log(e);
                        if (e !== null) {
                          setTeam({
                            ...team,
                            head_id: e.value || "",
                          });
                        }
                        team.head_id = e.value || "";
                        setcurrent_headteam(e.value);
                        GetEmployee();
                      }}
                    />
                    {/* <select
                          className="form-control custom-select "
                          type="text"
                          required
                          id="drop_level"
                          value={team.head_id}
                          disabled={disable}
                          onChange={(e) => {
                            setTeam({
                              ...team,
                              head_id: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled selected>
                            ----เลือก----
                          </option>
                          {headTeam.map((el) => {
                            return <option key={el.emp_id} value={el.emp_id}>{el.emp_name_th}</option>;
                          })}
                        </select> */}
                    <label>หัวหน้าทีม</label>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-12">
                  <label htmlFor="">Select Member</label>
                  <div className="row">
                    <div className="col-6 col-md-4 col-xl-2">
                      <button
                        type="button"
                        class="btn btn-block btn-info "
                        disabled={mode.mode === "read" ? true : false}
                        data-toggle="modal"
                        data-target="#modal-member"
                        // onClick={testassy}
                      >
                        Select Member
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-12 col-md-12 col-xl-12">
                  <div
                    className="table-responsive"
                    style={{ height: "600px", whiteSpace: "nowrap" }}
                  >
                    <MDBDataTable
                      className="table table-head-fixed"
                      sortable={false}
                      striped
                      bordered
                      hover
                      fixedHeader
                      data={TableTeamMember}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card-footer"></div>

            <div className="modal fade" id="modal-member">
              <div className="modal-dialog modal-xl">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Member</h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-12 ">
                        <div className="none-nolmalinput"></div>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group ">
                              <input
                                type="text"
                                className="form-control"
                                required="false"
                                value={filler_employee.emp_no}
                                onChange={(e) => {
                                  setfiller_employee({
                                    ...filler_employee,
                                    emp_no: e.target.value,
                                  });
                                }}
                              />
                              <label htmlFor="">Employee No</label>{" "}
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group ">
                              <input
                                type="text"
                                className="form-control"
                                required="false"
                                value={filler_employee.emp_name_th}
                                onChange={(e) => {
                                  setfiller_employee({
                                    ...filler_employee,
                                    emp_name_th: e.target.value,
                                  });
                                }}
                              />
                              <label htmlFor="">Employee Name</label>{" "}
                            </div>
                          </div>

                          <div className="col-6 col-md-4 col-xl-2">
                            <div className="form-group ">
                              <button
                                type="button"
                                class="btn btn-block btn-info "
                                disabled={mode.mode === "read" ? true : false}
                                onClick={() => {
                                  GetEmployee();
                                  setMDBT(false);
                                }}
                              >
                                Search
                              </button>
                            </div>
                          </div>

                          <div className="col-6 col-md-4 col-xl-2">
                            <div className="form-group ">
                              <button
                                type="button"
                                class="btn btn-block btn-info "
                                disabled={mode.mode === "read" ? true : false}
                                onClick={() => {
                                  setMDBT(false);
                                  clearFilter();
                                }}
                              >
                                Clear
                              </button>
                            </div>
                          </div>
                        </div>
                        <br />

                        <div
                          className="table-responsive"
                          style={{ height: "500px", whiteSpace: "nowrap" }}
                        >
                          <MDBDataTable
                            className="table table-head-fixed"
                            //paging={false}
                            sortable={false}
                            disableRetreatAfterSorting={MDBT}
                            /*  onPageChange={(page) => {
                                    console.log(page);
                                  }} */
                            striped
                            bordered
                            hover
                            fixedHeader
                            data={TableEmployee}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <h3></h3>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer justify-content-between">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}

export default Manage_team_add;
