import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Configs from "../../../config";
import Swal from "sweetalert2";
import Table_All_Project from "../../../component/component_ticket/table_all_project_of_project";
import {
  Avatar,
  Divider,
  Tooltip,
  Dropdown,
  Space,
  Checkbox,
  Switch,
  Upload,
  ConfigProvider,
  Drawer,
  DatePicker,
  Input,
  Modal,
} from "antd";
import {
  AntDesignOutlined,
  UserOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Button } from "reactstrap";
import img_header_ticket from "../../../assets/img/IMG_Ticket_Management/img_header_ticket.png";
import Select from "react-select";
import img_Three_dot from "../../../assets/img/IMG_Ticket_Management/three_dot.png";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import dayjs from "dayjs";
import {
  getOem,
  getToken,
  getUser,
  getFeature,
  getAcademy,
} from "../../../Utils/Common";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const TICKET_TYPE_SPECIAL = [
  "5e291a16-64b8-485f-964f-ab97b4ad2596", // ผู้ใช้ไม่เข้าใจระบบ
  "bdbc1d7c-f109-4751-84a7-dd5fcdf72ad3", // ให้คำปรึกษาแนะนำระบบ
  "ffd74165-fb9b-4416-9627-85dcbb938e4e", // feedback
];

function All_Ticket({ project_id, is_all }) {
  const onChange_Switch = (checked) => {
    console.log(`switch to ${checked}`);
  };
  const [Tickect_List, setTickect_List] = useState({
    mode: "add",
    ticket_code: "",
    ticket_no: "",
    ticket_name: "",
    ticket_tkt_id: "",
    ticket_severity_id: "",
    ticket_start_date: "",
    ticket_notified_time: "",
    ticket_administrator: "",
    ticket_create_date: "",
    ticket_create_by: "",
    ticket_description: "",
    ticket_customer_name: "",
    ticket_update_date: "",
    ticket_edit_by: "",
    ticket_pr_id: "",
    ticket_tsb_id: "",
    ticket_id: "",
    ticket_stk_id: "",
    ticket_select_day_notified: "",
    img_arry: [],
  });
  const [UserPermi, setUserPermi] = useState({
    iu_ps_id: "",
  });
  const [IdSLA, setIdSLA] = useState("");
  const [fileList, setFileList] = useState([]);
  const [delIMG, setDelIMG] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setpageSize] = useState(10);
  const [Filter, setFilter] = useState({
    phase_id: "",
    sla_status_id: "",
    search_text: "",
    search_text_prv: "",
    ticket_severity_id: "",
    severity_name: "",
    project_id: "",
  });
  const [projectDataReport, setprojectDataReport] = useState([]);
  const [ticketDataReport, setticketDataReport] = useState([]);
  const [ticket_id_Report, setticket_id_Report] = useState({
    id_list: [],
    data_list: [],
  });
  const [total, Settotal] = useState("");
  const onChangePage = (page) => {
    setCurrentPage(page);
  };
  const onShowSizeChange = (current, size) => {
    // console.log("Selected Page Size:", size);
    setpageSize(size);
  };

  const [FullProject, setFullProject] = useState({
    StrProjectNameShow: "",
    StrProjectCode: "",
    GenTicket_No: "",
    memberAll: [],
  });
  const [isResponse, setIsResponse] = useState({
    lg: false,
  });
  const [modalDescription, setmodalDescription] = useState({
    isOpen: false,
    text: "",
  });
  const [ALlTicketList, setALlTicketList] = useState([]);
  const [selectSLAstatusOption, setselectSLAstatusOption] = useState([]);
  const onChange_Checkbox = async (e, id_ticket, ticket) => {
    console.log("e: ", e, id_ticket, ticket);
    let ticket_id_list = [...ticket_id_Report.id_list];
    let ticket_list = [...ticket_id_Report.data_list];
    if (e === true) {
      ticket_id_list.push(id_ticket);
      ticket_list.push(ticket);
      // await axios({
      //   method: "get",
      //   url: `${Configs.API_Ticket}/api/ticket/getByIdTicketDetail/${id_ticket}`,
      //   headers: {
      //     Authorization: getToken(),
      //     "X-TTT": `${Configs.API_TTT}`,
      //     "Content-Type": "application/json",
      //   },
      // })
      //   .then(function (response) {
      //     // console.log("getByIdTicketDetail: ",response.data.data_ticket);
      //     setticketDataReport((prevData) => [
      //       ...prevData,
      //       ...response.data.data_ticket,
      //     ]);
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //     Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      //   });
    } else {
      // const filteredData = ticketDataReport.filter(
      //   (item) => item.ticket_id !== id_ticket
      // );
      // setticketDataReport(filteredData);
      ticket_id_list = ticket_id_list.filter((el) => el !== id_ticket);
      ticket_list = ticket_list.filter((el) => el.pr_id !== ticket.pr_id);
    }
    setticket_id_Report({
      ...ticket_id_Report,
      id_list: ticket_id_list,
      data_list: ticket_list,
    });
    ticket_id_Report.id_list = ticket_id_list;
    ticket_id_Report.data_list = ticket_list;
  };
  const [value, setValue] = useState();
  const [SelectTicket_TypeList, setSelectTicket_TypeList] = useState([]);
  const [SelectSeverityList, setSelectSeverityList] = useState([]);
  const SelectSeverityFilterList = [
    { value: "minor", label: "Minor" },
    { value: "average", label: "Average" },
    { value: "major", label: "Major" },
    { value: "critical", label: "Critical" },
    { value: "null", label: "No Status" },
  ];
  const [selectPhaseList, setselectPhaseList] = useState([]);
  const [SelectDevList, setSelectDevList] = useState([]);
  const [selectProjectCodeOption, setselectProjectCodeOption] = useState([]);
  const [modeManage, setModeManage] = useState({
    modeManage: "",
  });
  const handlePageChange = ({ currentPage, pageSize }) => {
    // console.log("Current Page:", currentPage);
    // console.log("Page Size:", pageSize);
    // Add any logic here to handle the page change
  };
  useEffect(() => {
    // console.log('Page:', page);
    // console.log('Page Size:', pageSize);
    // console.log(moment().add(770, 'minutes').diff(moment(), 'minutes'));
    // getDataReportExcelProject(project_id);

    if (!is_all && project_id) {
      setByIdSLA_Project(project_id);
    }
    checkRoleUserTicket();
    selectProjectCode();
    selectTicket_Type();
    filterAllTicket();
    selectSLAstatus();
    selectPhase();
    /* Swal.fire('test1', 'test detial1', 'success').then(() => {
      Swal.fire('test2', 'test detial2', 'success')
    }) */
  }, [
    currentPage,
    pageSize,
    /* Filter.sla_status_id,
    Filter.phase_id,
    Filter.ticket_severity_id, */
  ]);

  useEffect(() => {
    setIsResponse({
      ...isResponse,
      lg: window.innerWidth < 1240,
    });

    const handleResize = () => {
      setIsResponse({
        ...isResponse,
        lg: window.innerWidth < 1240,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { TextArea } = Input;
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    const element_1 = document.getElementsByClassName(
      "main-header navbar navbar-expand navbar-white navbar-light"
    );
    const element_2 = document.getElementsByClassName(
      "main-sidebar sidebar-dark-primary elevation-4 set-bg"
    );

    element_1[0].style.zIndex = "auto";
    element_2[0].style.zIndex = "auto";

    setFileList([]);
    setDelIMG([]);
    setOpen(true);
  };
  const onClose = (data, isNotSwal) => {
    function resetState() {
      const element_1 = document.getElementsByClassName(
        "main-header navbar navbar-expand navbar-white navbar-light"
      );
      const element_2 = document.getElementsByClassName(
        "main-sidebar sidebar-dark-primary elevation-4 set-bg"
      );

      element_1[0].style.zIndex = "";
      element_2[0].style.zIndex = "";

      setDelIMG([]);
      setFileList([]);
      setTickect_List({
        ...Tickect_List,
        mode: "add",
        // ticket_code: "",
        ticket_no: is_all ? "" : undefined,
        ticket_name: "",
        ticket_tkt_id: "",
        ticket_severity_id: "",
        ticket_start_date: "",
        ticket_notified_time: "",
        ticket_administrator: "",
        ticket_create_date: "",
        ticket_create_by: "",
        ticket_description: "",
        ticket_customer_name: "",
        ticket_update_date: "",
        ticket_edit_by: "",
        ticket_pr_id: "",
        ticket_tsb_id: "",
        ticket_id: "",
        ticket_stk_id: "",
        ticket_select_day_notified: "",
        img_arry: [],
      });
      Tickect_List.mode = "add";
      // Tickect_List.ticket_code = "";
      // Tickect_List.ticket_no = "";
      Tickect_List.ticket_name = "";
      Tickect_List.ticket_tkt_id = "";
      Tickect_List.ticket_severity_id = "";
      Tickect_List.ticket_start_date = "";
      Tickect_List.ticket_notified_time = "";
      Tickect_List.ticket_administrator = "";
      Tickect_List.ticket_create_date = "";
      Tickect_List.ticket_create_by = "";
      Tickect_List.ticket_description = "";
      Tickect_List.ticket_customer_name = "";
      Tickect_List.ticket_update_date = "";
      Tickect_List.ticket_edit_by = "";
      Tickect_List.ticket_pr_id = "";
      Tickect_List.ticket_tsb_id = "";
      Tickect_List.ticket_id = "";
      Tickect_List.ticket_stk_id = "";
      Tickect_List.ticket_select_day_notified = "";
      Tickect_List.img_arry = [];
      setOpen(false);
    }
    if (isNotSwal || Tickect_List.mode === "view") {
      resetState();
    } else {
      Swal.fire({
        title: `คุณต้องการยกเลิกการบันทึก ข้อมูลนี้หรือไม่ ?`,
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          resetState();
        }
      });
    }
  };

  const handleStartDateChange = (date) => {
    setTickect_List({
      ...Tickect_List,
      ticket_start_date: date,
    });
  };
  const handleNotifiedChange = (date) => {
    setTickect_List({
      ...Tickect_List,
      ticket_notified_time: date,
      // ticket_select_day_notified: "",
    });
    // Tickect_List.ticket_select_day_notified = "";
  };
  const [uploading, setUploading] = useState(false);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      fontSize: 16,
      // marginTop: "-18px",
      // marginLeft: "-9px"
    }),
  };

  const handleUploadSuccess = (file) => {
    setFileList(file.fileList);
  };

  // console.log("delIMG: ", delIMG);
  const handleRemove = (file) => {
    const newFileList = fileList.filter((item) => item.uid !== file.uid);
    // console.log("handleRemove",file.img_id);
    setDelIMG([...delIMG, file.img_id]);
    setFileList(newFileList);
  };
  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      const value_prve = Filter.search_text;
      setFilter({
        ...Filter,
        search_text_prv: value_prve,
      });
      Filter.search_text_prv = value_prve;
      filterAllTicket();
      // getDataReportExcelProject(project_id);
    }
  };
  // const handleUploadChange = (info) => {
  //   if (info.file.status === 'done') {
  //     handleUploadSuccess(info.file);
  //     // message.success(`${info.file.name} อัปโหลดสำเร็จ`);
  //   } else if (info.file.status === 'error') {
  //     // message.error(`${info.file.name} อัปโหลดไม่สำเร็จ`);
  //   }
  // };
  // console.log("ticketDataReport",ticketDataReport);

  //  console.log("Tickect_List: ", Tickect_List);
  // console.log("fileList", fileList);

  async function ExportReportProject() {
    if (ticket_id_Report.data_list.length === 0) {
      return;
    }
    Swal.fire({
      title: "ระบบ",
      text: `ระบบกำลังสร้างรายงาน`,
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    /* const data = [ ...projectDataReport ];
      const ticket_list = data[0].ticket_list.filter(el => ticket_id_Report.id_list.includes(el.tiket_id));
      data[0].ticket_list = [ ...ticket_list ]; */
    await axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/project/ExportReportProjectForTicketPage`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: {
        ticket_proj_list: [],
        // ticket_id_list: ticket_id_Report,
        raw_ticket_list: ticket_id_Report.data_list,
        user_id: getUser().fup,
      },
      responseType: "blob",
    })
      .then(function (response) {
        Swal.close();
        const contentDisposition = response.headers["content-disposition"];
        const filename = contentDisposition
          .split(";")
          .find((n) => n.includes("filename="))
          .replace("filename=", "")
          .trim()
          .slice(1, -1);

        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;

        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        Swal.fire({
          icon: "success",
          title: "ดาวน์โหลดสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          setticket_id_Report({
            ...ticket_id_Report,
            data_list: [],
            id_list: [],
          });
          ticket_id_Report.data_list = [];
          ticket_id_Report.id_list = [];
          // onClose();
          // filterAllProject();
        });
      })
      .catch(function (error) {
        Swal.close();
        console.error("ExportReportProject ->", error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  function getDataReportExcelProject(project_id) {
    setprojectDataReport([]);
    axios({
      method: "get",
      url: `${Configs.API_Ticket}/api/project/getDataReportExcel/${project_id}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        // console.log("ticket",response.data.data_ticket);
        // console.log("project",response.data.data_project[0]);
        const report = [];
        if (response.data) {
          const proj = response.data.data_project[0];
          // const tick = response.data.data_ticket;
          const data_report = {
            proj_create_by: proj.pr_create_by,
            proj_code: proj.pr_project_code,
            proj_name: proj.pr_name,
            ticket_list: [],
          };
          for (let tick of response.data.data_ticket) {
            const ticket = {
              tiket_id: tick.ticket_id,
              ticket_no: tick.ticket_no,
              ticket_start_date: tick.ticket_start_date,
              ticket_description: tick.ticket_description,
              ticket_type: tick.tt_name,
              ticket_severity: tick.severity_name,
              ticket_customer_name: tick.ticket_customer_name,
              ticket_fn_response: tick.manage_by_f,
              ticket_ln_response: tick.manage_by_l,
              ticket_severity_timeend: tick.severity_sla_time,
            };
            data_report.ticket_list.push(ticket);
          }
          report.push(data_report);
        }
        setprojectDataReport(report);
        // setticketDataReport(response.data.data_ticket);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  function getByIdTicket(id) {
    // SelectDev();
    axios({
      method: "get",
      url: `${Configs.API_Ticket}/api/ticket/getByIdTicketDetail/${id}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //  console.log("getById: ", response.data);

        const tempFile = response.data.data_img.map((image) => {
          const arrayBufferView = new Uint8Array(image.img_data.data);
          const blob = new Blob([arrayBufferView], { type: "image/jpeg" });
          const url = URL.createObjectURL(blob);
          const thumbUrl = URL.createObjectURL(blob); // สร้าง URL สำหรับ thumbnail
          return {
            uid: image.img_id,
            name: image.img_name,
            img_id: image.img_id,
            status: "done",
            url: url,
            thumbUrl: thumbUrl, // ใช้ URL สำหรับ thumbnail
          };
        });
        setFileList(tempFile);
        //  const prUserIdArray = JSON.parse(response.data.pr_user_id);
        setTickect_List({
          ...Tickect_List,
          // mode: "edit",
          ticket_id: response.data.data_ticket[0].ticket_id,
          ticket_code: response.data.data_ticket[0].ticket_code,
          ticket_no: response.data.data_ticket[0].ticket_no,
          ticket_name: response.data.data_ticket[0].ticket_name,
          ticket_tkt_id: response.data.data_ticket[0].ticket_tkt_id,
          ticket_severity_id: response.data.data_ticket[0].ticket_severity_id,
          ticket_start_date: response.data.data_ticket[0].ticket_start_date,
          ticket_notified_time:
            response.data.data_ticket[0].ticket_notified_time,
          ticket_administrator:
            response.data.data_ticket[0].ticket_administrator,
          ticket_create_date: response.data.data_ticket[0].ticket_create_date,
          ticket_create_by: response.data.data_ticket[0].ticket_create_by,
          ticket_description: response.data.data_ticket[0].ticket_description,
          ticket_customer_name:
            response.data.data_ticket[0].ticket_customer_name,
          ticket_update_date: response.data.data_ticket[0].ticket_update_date,
          ticket_edit_by: response.data.data_ticket[0].ticket_edit_by,
          ticket_pr_id: response.data.data_ticket[0].ticket_pr_id,
          ticket_tsb_id: response.data.data_ticket[0].ticket_tsb_id,
          sla_status_name: response.data.data_ticket[0].sla_status_name,
          ticket_create_by_ShowName:
            response.data.data_ticket[0].create_by_f +
              " " +
              response.data.data_ticket[0].create_by_l || "",
          img_arry: [],
          ticket_select_day_notified:
            response.data.data_ticket[0].ticket_notified_day,
        });
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  function checkRoleUserTicket() {
    const sessionDataString = sessionStorage.getItem("user");
    const sessionData = JSON.parse(sessionDataString);
    const fupValue = sessionData.fup;
    // console.log("fupValue: ", fupValue);
    let temp = {
      id: fupValue,
    };
    axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/ticket/checkRoleUserTicket`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        // console.log("dataRole: ", response.data[0].iu_ps_id);

        setUserPermi({ ...UserPermi, iu_ps_id: response.data[0].iu_ps_id });
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  function selectProjectCode() {
    axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/project/selectProjectCode`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: { user_id: getUser().fup },
    })
      .then(function (response) {
        // console.log("select",response.data);
        const dataW = [];
        // console.log(response.data);
        response.data.forEach((item, index) => {
          const formatData = {
            value: item.pr_project_code,
            label: `${item.pr_project_code}-${item.pr_name}`,
            pr_id_sl: item.pr_id_sl,
            pr_id: item.pr_id,
          };
          dataW.push(formatData);
        });
        setselectProjectCodeOption(dataW);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  function ClickApprove(id_ticket) {
    // console.log("C",id_ticket);
    const sessionDataString = sessionStorage.getItem("user");
    const sessionData = JSON.parse(sessionDataString);
    const fupValue = sessionData.fup;
    let temp = { user_id: fupValue };
    axios({
      method: "put",
      url: `${Configs.API_Ticket}/api/ticket/ClickApprove/${id_ticket}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        if (response.data.error_message) {
          Swal.fire("แจ้งเตือน", response.data.error_message, "warning").then(
            (res) => {
              if (res.isConfirmed) {
                filterAllTicket();
              }
            }
          );
        } else {
          filterAllTicket();
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  function ClickPadding(id_ticket) {
    // console.log("C",id_ticket);
    const sessionDataString = sessionStorage.getItem("user");
    const sessionData = JSON.parse(sessionDataString);
    const fupValue = sessionData.fup;
    let temp = { user_id: fupValue };
    axios({
      method: "put",
      url: `${Configs.API_Ticket}/api/ticket/ClickPadding/${id_ticket}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        filterAllTicket();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  function ClickReopen(id_ticket, is_dev) {
    // console.log("C",id_ticket);
    const sessionDataString = sessionStorage.getItem("user");
    const sessionData = JSON.parse(sessionDataString);
    const fupValue = sessionData.fup;
    let temp = { user_id: fupValue, is_dev };
    axios({
      method: "put",
      url: `${Configs.API_Ticket}/api/ticket/ClickReopen/${id_ticket}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        filterAllTicket();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  function ClickClose(id_ticket) {
    // console.log("C",id_ticket);
    const sessionDataString = sessionStorage.getItem("user");
    const sessionData = JSON.parse(sessionDataString);
    const fupValue = sessionData.fup;
    let temp = { user_id: fupValue };
    axios({
      method: "put",
      url: `${Configs.API_Ticket}/api/ticket/ClickClose/${id_ticket}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        filterAllTicket();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  // console.log("fileList",fileList);
  function SaveTicket() {
    Swal.fire({
      title: "คุณต้องการบันทึกข้อมูลนี้หรือไม่ ?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const error_list = [];
        console.log("Tickect_List :>> ", Tickect_List);
        if (is_all && !project_id) {
          if (!Tickect_List.ticket_pr_id) {
            let temp_err = {
              message: "กรุณาเลือก Project Name",
            };
            error_list.push(temp_err);
          }
        }
        if (Tickect_List.ticket_name?.trim() === "") {
          let temp_err = {
            message: "กรุณากรอก Ticket Name",
          };
          error_list.push(temp_err);
        }

        if (Tickect_List.ticket_tkt_id === "") {
          let temp_err = {
            message: "กรุณาเลือก Type of Ticket",
          };
          error_list.push(temp_err);
        }

        if (
          Tickect_List.ticket_tkt_id !== "877aab14-ba1f-4d7f-826f-f2185de9e590"
        ) {
          if (Tickect_List.ticket_severity_id === "") {
            let temp_err = {
              message: "กรุณาเลือก Severity",
            };
            error_list.push(temp_err);
          }
        }

        if (Tickect_List.ticket_start_date === "") {
          let temp_err = {
            message: "กรุณาเลือก Start Date",
          };
          error_list.push(temp_err);
        }
        /* if (
          Tickect_List.ticket_notified_time === "" &&
          Tickect_List.ticket_select_day_notified === ""
        ) {
          let temp_err = {
            message: "กรุณาเลือก Notified time",
          };
          error_list.push(temp_err);
        } */
        // if (Tickect_List.ticket_customer_name.trim() === ""  ) {
        //   let temp_err = {
        //     message: "กรุณากรอก Customer Name",
        //   };
        //   error_list.push(temp_err);
        // }
        if (
          Tickect_List.ticket_administrator === "" &&
          Tickect_List.ticket_tkt_id !==
            "5e291a16-64b8-485f-964f-ab97b4ad2596" &&
          Tickect_List.ticket_tkt_id !==
            "bdbc1d7c-f109-4751-84a7-dd5fcdf72ad3" &&
          Tickect_List.ticket_tkt_id !== "ffd74165-fb9b-4416-9627-85dcbb938e4e"
        ) {
          let temp_err = {
            message: "กรุณาเลือก Assign to",
          };
          error_list.push(temp_err);
        }

        if (Tickect_List.ticket_description.trim() === "") {
          let temp_err = {
            message: "กรุณากรอก Descriptions",
          };
          error_list.push(temp_err);
        }

        if (error_list.length > 0) {
          var err_message = "";
          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }
          Swal.fire("Error", err_message, "error");
        } else {
          if (Tickect_List.mode === "add") {
            let temp = {
              mode: "add",
              ticket_code: Tickect_List.ticket_code,
              ticket_no: Tickect_List.ticket_no,
              ticket_name: Tickect_List.ticket_name?.trim(),
              ticket_tkt_id: Tickect_List.ticket_tkt_id || null,
              ticket_severity_id: Tickect_List.ticket_severity_id || null,
              ticket_start_date: Tickect_List.ticket_start_date,
              ticket_notified_time: Tickect_List.ticket_notified_time,
              ticket_administrator:
                Tickect_List.ticket_tkt_id !==
                  "5e291a16-64b8-485f-964f-ab97b4ad2596" &&
                Tickect_List.ticket_tkt_id !==
                  "bdbc1d7c-f109-4751-84a7-dd5fcdf72ad3" &&
                Tickect_List.ticket_tkt_id !==
                  "ffd74165-fb9b-4416-9627-85dcbb938e4e"
                  ? Tickect_List.ticket_administrator
                  : getUser().fup,
              ticket_create_date: Tickect_List.ticket_create_date,
              ticket_customer_name: Tickect_List.ticket_customer_name,
              ticket_description: Tickect_List.ticket_description,
              ticket_create_by: Tickect_List.ticket_create_by,
              ticket_edit_by: null,
              /* Tickect_List.ticket_tkt_id !==
                  "5e291a16-64b8-485f-964f-ab97b4ad2596" &&
                Tickect_List.ticket_tkt_id !==
                  "bdbc1d7c-f109-4751-84a7-dd5fcdf72ad3" &&
                Tickect_List.ticket_tkt_id !==
                  "ffd74165-fb9b-4416-9627-85dcbb938e4e"
                  ? null
                  : getUser().fup */ ticket_stk_id:
                Tickect_List.ticket_stk_id || null,
              ticket_update_date: Tickect_List.ticket_create_date,
              ticket_pr_id: is_all ? Tickect_List.ticket_pr_id : project_id,
              ticket_tsb_id: "a4b304ec-0af4-49ef-91e6-f9d04a1effc6",
              ticket_notified_day: Tickect_List.ticket_select_day_notified,
              // img_arry:formData,
            };

            axios({
              method: "post",
              url: `${Configs.API_Ticket}/api/ticket/addTicket`,
              headers: {
                Authorization: getToken(),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
              },
              data: temp,
            })
              .then(async function (response) {
                // console.log("addTicket: ",response.data);
                const formData = new FormData();

                fileList.forEach((file) => {
                  formData.append(`files`, file.originFileObj);
                });
                formData.append(`img_ticket_id`, response.data.ticket_id);
                formData.append(`img_type`, "img_ticket");
                try {
                  const response = axios.post(
                    `${Configs.API_Ticket}/api/image/upload/${temp.ticket_no}`,
                    formData,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: getToken(),
                        "X-TTT": `${Configs.API_TTT}`,
                      },
                    }
                  );

                  // console.log(response.data);
                } catch (error) {
                  console.error("เกิดข้อผิดพลาดในการอัปโหลดไฟล์:", error);
                }

                Swal.fire({
                  icon: "success",
                  title: "บันทึกสำเร็จ",
                  showConfirmButton: false,
                  timer: 1500,
                }).then(async (result) => {
                  setFileList([]);
                  onClose(null, true);
                  // getDataReportExcelProject(project_id);
                  filterAllTicket();
                  setticketDataReport([]);
                });
              })
              .catch(function (error) {
                console.log(error);
                Swal.fire(
                  "แจ้งเตือน",
                  error.response.data.errorMessage,
                  "error"
                );
              });
          } else if (Tickect_List.mode === "edit") {
            // console.log("MODE Edit",Tickect_List.ticket_id);
            const sessionDataString = sessionStorage.getItem("user");
            const sessionData = JSON.parse(sessionDataString);
            const fupValue = sessionData.fup;
            let temp = {
              mode: "edit",
              ticket_code: Tickect_List.ticket_code,
              ticket_no: Tickect_List.ticket_no,
              ticket_name: Tickect_List.ticket_name?.trim(),
              ticket_tkt_id: Tickect_List.ticket_tkt_id,
              ticket_severity_id: Tickect_List.ticket_severity_id,
              ticket_start_date: Tickect_List.ticket_start_date,
              ticket_notified_time: Tickect_List.ticket_notified_time,
              ticket_administrator: Tickect_List.ticket_administrator,
              ticket_create_date: Tickect_List.ticket_create_date,
              ticket_customer_name: Tickect_List.ticket_customer_name,
              ticket_description: Tickect_List.ticket_description,
              ticket_create_by: Tickect_List.ticket_create_by,
              ticket_edit_by: undefined,
              // ticket_stk_id:Tickect_List.ticket_stk_id,
              // ticket_update_date:Tickect_List.ticket_create_date,
              // ticket_pr_id:project_id,
              ticket_tsb_id: Tickect_List.ticket_tsb_id,
              img_arry: [],
              ticket_notified_day: Tickect_List.ticket_select_day_notified,
            };

            axios({
              method: "put",
              url: `${Configs.API_Ticket}/api/ticket/editTicket/${Tickect_List.ticket_id}`,
              headers: {
                Authorization: getToken(),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
              },
              data: temp,
            })
              .then(async function (response) {
                const formData = new FormData();

                fileList.forEach((file) => {
                  // formData.append(`files`, file.url);
                  formData.append(`files`, file.originFileObj);
                  // formData.append(`img_id`, file.uid);
                  // console.log("file.originFileObj: ",file.uid);
                });
                formData.append(`delImg`, delIMG);
                formData.append(`img_ticket_id`, Tickect_List.ticket_id);
                formData.append(`img_type`, "img_ticket");
                try {
                  const response = axios.post(
                    `${Configs.API_Ticket}/api/image/upload/${temp.ticket_no}`,
                    formData,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: getToken(),
                        "X-TTT": `${Configs.API_TTT}`,
                      },
                    }
                  );

                  // console.log(response.data);
                } catch (error) {
                  console.error("เกิดข้อผิดพลาดในการอัปโหลดไฟล์:", error);
                }

                Swal.fire({
                  icon: "success",
                  title: "บันทึกสำเร็จ",
                  showConfirmButton: false,
                  timer: 1500,
                }).then(async (result) => {
                  setFileList([]);
                  onClose(null, true);
                  // getDataReportExcelProject(project_id);
                  filterAllTicket();
                  setticketDataReport([]);
                });
              })
              .catch(function (error) {
                console.log(error);
                Swal.fire(
                  "แจ้งเตือน",
                  error.response.data.errorMessage,
                  "error"
                );
              });
          }
        }
      }
    });
  }

  function delTicket(id) {
    Swal.fire({
      title: "คุณต้องการลบข้อมูลนี้หรือไม่ ?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: `${Configs.API_Ticket}/api/ticket/delTicket/${id}`,
          headers: {
            Authorization: getToken(),
            "X-TTT": `${Configs.API_TTT}`,
            "Content-Type": "application/json",
          },
        })
          .then(async function (response) {
            onClose(null, true);
            filterAllTicket();
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
          });
      }
    });
  }

  function changeStatusFail(id) {
    const sessionDataString = sessionStorage.getItem("user");
    const sessionData = JSON.parse(sessionDataString);
    const fupValue = sessionData.fup;
    let temp = { user_id: fupValue, fail_auto: true };
    axios({
      method: "put",
      url: `${Configs.API_Ticket}/api/ticket/changeStatusFail/${id}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        // filterAllTicket();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  function selectTicket_Type() {
    axios({
      method: "get",
      url: `${Configs.API_Ticket}/api/ticket_type/getAllTicketType`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        // console.log("select_sla",response.data);
        const dataW = [];
        //  console.log(response.data);
        response.data.forEach((item, index) => {
          const formatData = {
            value: item.tt_id,
            label: item.tt_name,
          };
          dataW.push(formatData);
        });
        setSelectTicket_TypeList(dataW);
      })
      .catch(function (error) {
        console.log(error);
        /*      Swal.fire("แจ้งเตือน", error.response.data.error[0].errorMessage, "error"); */
      });
  }

  function ShowTicketPage(project_name) {
    window.location = `/projectmanagement/Ticket/View/${project_name}`;
  }

  async function setByIdSLA_Project(id) {
    await axios({
      method: "get",
      url: `${Configs.API_Ticket}/api/project/getByid/${id}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {
        //  console.log("getById: ", response.data.pr_id_sl);
        await setIdSLA(response.data.pr_id_sl);

        // sessionStorage.setItem('SLA_LevelProject', response.data.pr_id_sl);
      })
      .catch(function (error) {
        console.log(error);
        /*      Swal.fire("แจ้งเตือน", error.response.data.error[0].errorMessage, "error"); */
      });
  }

  function selectSeverity(pr_id_sl) {
    const id = pr_id_sl || IdSLA;
    axios({
      method: "get",
      url: `${Configs.API_Ticket}/api/severity/selectSeverity/${id}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        const dataW = [];
        response.data.forEach((item, index) => {
          const formatData = {
            value: item.severity_id,
            label: `${item.severity_name} (${formatDuration(
              Number(item.severity_sla_time),
              true
            )})`,
          };
          dataW.push(formatData);
        });
        dataW.push({
          value: null,
          label: "No Status",
        });
        // console.log("re:", response.data);
        setSelectSeverityList(dataW);
      })
      .catch(function (error) {
        console.log(error);
        /*      Swal.fire("แจ้งเตือน", error.response.data.error[0].errorMessage, "error"); */
      });
  }

  function SelectDev(pr_id) {
    console.log("pr_id :>> ", pr_id);
    axios({
      method: pr_id ? "post" : "get",
      url: `${Configs.API_Ticket}/api/ticket/SelectDev`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: pr_id ? { id: pr_id } : undefined,
    })
      .then(function (response) {
        // console.log("select_sla",response.data);
        const dataW = [];
        //  console.log(response.data);
        // console.log('FullProject.memberAll :>> ', FullProject.memberAll);
        response.data.forEach((item, index) => {
          const formatData = {
            value: item.iu_id,
            label: `${item.iu_firstname} ${item.iu_lastname}`,
          };
          if (
            FullProject.memberAll.find((el) => el.id_user === item.iu_id) ||
            pr_id
          ) {
            dataW.push(formatData);
          }
        });
        // console.log("re:", response.data);
        setSelectDevList(dataW);
      })
      .catch(function (error) {
        console.log(error);
        /*      Swal.fire("แจ้งเตือน", error.response.data.error[0].errorMessage, "error"); */
      });
  }

  async function genProjectCode(project_id) {
    let temp = {
      project_id: project_id,
    };
    axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/ticket/genProjectCode`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        const sessionDataString = sessionStorage.getItem("user");

        const currentDate = new Date();
        // if (sessionDataString) {
        const sessionData = JSON.parse(sessionDataString);

        const fupValue = sessionData.fup;
        const nameValue = sessionData.name;
        setTickect_List({
          ...Tickect_List,
          ticket_no: response.data,
          ticket_create_by: fupValue,
          ticket_create_by_ShowName: nameValue,
          ticket_create_date: currentDate,
        });
        // }

        // setTickect_List({...Tickect_List, })
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }
  // console.log("ALlTicketList: ", ALlTicketList);
  function GetMaxWidthOverflow(ticket_tsb_id) {
    let str_return = "100px";
    if (
      ticket_tsb_id === "4d2ad7d2-316a-4431-a185-38ecdac80069" ||
      ticket_tsb_id === "41950bd6-ca94-4cdd-991e-44effbbe4870"
    ) {
      str_return = "80px";
    }
    return str_return;
  }
  function SwitchButton(ticket_tsb_id, id_ticket, ticket) {
    // console.log("tsb_id", ticket_tsb_id);
    // console.log("id_ticket: ",id_ticket);
    let button;
    if (UserPermi.iu_ps_id === "d9300f9b-e533-47a2-b40c-4039e2e50ea3") {
      //Dev

      if (ticket_tsb_id === "a4b304ec-0af4-49ef-91e6-f9d04a1effc6") {
        //Approve
        button = (
          <Button
            as="input"
            type="primary"
            value="Input"
            style={{
              backgroundColor: "#007BFF",
              border: "none",
              fontSize: "13px",
              // marginTop: "21px",
              padding: "5px 10px",
            }}
            onClick={() => {
              ClickApprove(id_ticket);
            }}
          >
            Approve
          </Button>
        );
      }

      if (ticket_tsb_id === "41950bd6-ca94-4cdd-991e-44effbbe4870") {
        //Reopen
        button = (
          <Button
            as="input"
            type="primary"
            value="Input"
            style={{
              backgroundColor: "#007BFF",
              border: "none",
              fontSize: "13px",
              // marginTop: "21px",
              padding: "5px 10px",
            }}
            onClick={() => {
              // ClickApprove(id_ticket);
              ClickReopen(id_ticket, true);
            }}
          >
            Reopen{" "}
            {Number(ticket.count_status_reopen || 0) > 0
              ? `(${Number(ticket.count_status_reopen || 0)})`
              : ""}
          </Button>
        );
      }

      if (ticket_tsb_id === "5fc5716d-91a5-4a76-b3fc-1e7b073cf9d7") {
        //Padding
        button = (
          <Button
            as="input"
            type="primary"
            value="Input"
            style={{
              backgroundColor: "#007BFF",
              border: "none",
              fontSize: "13px",
              // marginTop: "21px",
              // marginRight: "-20px",
              padding: "5px 10px",
            }}
            onClick={() => {
              ClickPadding(id_ticket);
            }}
          >
            Pedding
          </Button>
        );
      }

      if (
        ticket_tsb_id === "4d2ad7d2-316a-4431-a185-38ecdac80069" ||
        ticket_tsb_id === "2027332c-b338-470b-9ab1-4c7b99775288"
      ) {
        //Fixed
        button = (
          <Button
            as="input"
            type="primary"
            value="Input"
            style={{
              backgroundColor: "#47BA6F",
              border: "none",
              fontSize: "13px",
              // marginTop: "21px",
              padding: "5px 10px",
            }}
          >
            {ticket.phase_name === "CLOSE" ? "Close" : "Fixed"}
          </Button>
        );
      }
    }

    if (
      UserPermi.iu_ps_id === "d6655dd6-0302-4ebe-bc8c-de61a0339777" ||
      UserPermi.iu_ps_id === "ea57bf2f-232b-40d6-972d-b83e2e952519"
    ) {
      let disable = false;

      if (UserPermi.iu_ps_id === "ea57bf2f-232b-40d6-972d-b83e2e952519") {
        disable = true;
      }
      //Software Tester

      if (ticket_tsb_id === "4d2ad7d2-316a-4431-a185-38ecdac80069") {
        //Close & Reopen
        button = (
          <>
            <Button
              as="input"
              type="primary"
              value="Input"
              danger
              style={{
                backgroundColor:
                  UserPermi.iu_ps_id === "ea57bf2f-232b-40d6-972d-b83e2e952519"
                    ? "#A1A1A1"
                    : "#007BFF",
                border: "none",
                fontSize: "13px",
                // marginTop: "21px",
                padding: "5px 10px",
              }}
              disabled={disable}
              onClick={() => {
                if (
                  UserPermi.iu_ps_id !== "ea57bf2f-232b-40d6-972d-b83e2e952519"
                ) {
                  ClickClose(id_ticket);
                }
              }}
            >
              Close
            </Button>
            &nbsp;
            <Button
              as="input"
              type="primary"
              value="Input"
              style={{
                backgroundColor:
                  UserPermi.iu_ps_id === "ea57bf2f-232b-40d6-972d-b83e2e952519"
                    ? "#A1A1A1"
                    : "#007BFF",
                border: "none",
                fontSize: "13px",
                // marginTop: "21px",
                // marginLeft: "25px",
                padding: "5px 10px",
              }}
              disabled={disable}
              onClick={() => {
                if (
                  UserPermi.iu_ps_id !== "ea57bf2f-232b-40d6-972d-b83e2e952519"
                ) {
                  ClickReopen(id_ticket);
                }
              }}
            >
              Reopen{" "}
              {Number(ticket.count_status_reopen || 0) > 0
                ? `(${Number(ticket.count_status_reopen || 0)})`
                : ""}
            </Button>
          </>
        );
      }

      if (ticket_tsb_id === "a4b304ec-0af4-49ef-91e6-f9d04a1effc6") {
        // Approve
        button = (
          <Button
            as="input"
            type="primary"
            value="Input"
            style={{
              backgroundColor: "#A1A1A1",
              border: "none",
              fontSize: "13px",
              // marginTop: "21px",
              // marginRight: "-20px",
              padding: "5px 10px",
            }}
            disabled={disable}
          >
            Approve
          </Button>
        );
      }

      if (ticket_tsb_id === "5fc5716d-91a5-4a76-b3fc-1e7b073cf9d7") {
        //Padding
        button = (
          <Button
            as="input"
            type="primary"
            value="Input"
            style={{
              backgroundColor: "#A1A1A1",
              border: "none",
              fontSize: "13px",
              // marginTop: "21px",
              // marginRight: "-20px",
              padding: "5px 10px",
            }}
            disabled={disable}
          >
            Pedding
          </Button>
        );
      }

      if (ticket_tsb_id === "41950bd6-ca94-4cdd-991e-44effbbe4870") {
        //Reopen

        button = (
          <>
            <Button
              as="input"
              type="primary"
              value="Input"
              danger
              style={{
                backgroundColor: "#A1A1A1",
                border: "none",
                fontSize: "13px",
                padding: "5px 10px",
                // marginTop: "21px",
              }}
              disabled={disable}
            >
              Close
            </Button>
            &nbsp;
            <Button
              as="input"
              type="primary"
              value="Input"
              style={{
                backgroundColor: "#A1A1A1",
                border: "none",
                padding: "5px 10px",
                fontSize: "13px",
                // marginTop: "21px",
                // marginLeft: "25px",
              }}
              disabled={disable}
            >
              Reopen{" "}
              {Number(ticket.count_status_reopen || 0) > 0
                ? `(${Number(ticket.count_status_reopen || 0)})`
                : ""}
            </Button>
          </>
        );
      }

      if (ticket_tsb_id === "2027332c-b338-470b-9ab1-4c7b99775288") {
        //Fixed
        button = (
          <Button
            as="input"
            type="primary"
            value="Input"
            style={{
              backgroundColor: "#47BA6F",
              border: "none",
              fontSize: "13px",
              // marginTop: "21px",
              padding: "5px 10px",
            }}
            disabled={disable}
          >
            Close
          </Button>
        );
      }
    }

    return button;
  }

  // console.log("Tickect_List: ", Tickect_List);
  // console.log("getToken: ", getToken());

  async function filterAllTicket() {
    // console.log(sla_status_id,phase_id,ticket_severity_id);
    Swal.fire({
      title: "ระบบ",
      text: `ระบบกำลังอัพเดตข้อมูล`,
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    setALlTicketList([]);
    const sessionDataString = sessionStorage.getItem("user");
    const sessionData = JSON.parse(sessionDataString);
    const fupValue = sessionData.fup;

    let temp = {
      project_id: is_all ? Filter.project_id || null : project_id,
      phase_id: Filter.phase_id || "",
      sla_status_id: Filter.sla_status_id || "",
      ticket_severity_id: Filter.ticket_severity_id,
      search_text: Filter.search_text.trim() || "",
      current: currentPage || 1,
      pageSize: pageSize || 10,
      user_id: fupValue || "",
      severity_name: Filter.severity_name || "",
      is_all: is_all || false,
    };
    // console.log("temp", temp);
    await axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/ticket/getAllTicket`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        //  console.log("ALlTicketList", response.data);
        Settotal(response.data.AllTicketTotal);
        setTickect_List({
          ...Tickect_List,
          ticket_code: response.data.StrProjectNameShow,
        });
        setFullProject({
          ...FullProject,
          StrProjectNameShow: response.data.StrProjectNameShow,
          memberAll: response.data.member_list,
        });

        /* for (let i = 0; i < response.data._data.length; i++) {
          if (
            moment(response.data._data[i].ticket_start_date)
              .add(
                parseInt(response.data._data[i].severity_sla_time),
                "minutes"
              )
              .diff(moment(), "minutes") <= 0 &&
            (response.data._data[i].ticket_sla_status_id ==
              "8e8bbf90-ae9d-485f-9654-69436e600d63" || !response.data._data[i].ticket_sla_status_id) //sla_status -> In Process
          ) {
            // console.log( response.data._data[i].ticket_id);
            changeStatusFail(response.data._data[i].ticket_id);
          }
        } */

        response.data._data.forEach((item) => {
          setALlTicketList((prevList) => [
            ...prevList,
            {
              id: item.ticket_id,
              ticket_name: item.ticket_no,
              ticket_name_full: item.ticket_name,
              ticket_severity_id: item.ticket_severity_id,
              severity_name: item.severity_name,
              severity_sla_time: item.severity_sla_time,
              ticket_start_date: item.ticket_start_date,
              ticket_tsb_id: item.ticket_tsb_id,
              sla_status_name: item.sla_status_name,
              phase_name: item.phase_name,
              st_name: item.st_name,
              create_by: item.create_by_f + " " + item.create_by_l,
              edit_by: item.edit_by_f + " " + item.edit_by_l,
              edit_by_id: item.edit_by_id,
              create_by_id: item.create_by_id,
              ticket_description: item.ticket_description,
              count_status_reopen: item.count_reopen,
              pr_id: item.ticket_pr_id,
              pr_id_sl: item.pr_id_sl,
              create_by_img_path: item.create_by_img_path,
              edit_by_img_path: item.edit_by_img_path,
              date_fixed: item.date_fixed,
              // permission_role:item,
              // ticket_id:item.ticket_id

              // Img_Profile: item.Img_Profile
            },
          ]);
        });
        Swal.close();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  function GetStyleButtonDay1To3(day) {
    return {
      padding: "0 5px",
      backgroundColor:
        Tickect_List.ticket_select_day_notified === day ? "#007BFF" : "#FFFFFF",
      color:
        Tickect_List.ticket_select_day_notified === day
          ? "#FFFFFF"
          : "#00000090",
      borderColor: "#00000060",
      fontSize: "14px",
      whiteSpace: "nowrap",
      height: "100%",
    };
  }

  function selectSLAstatus() {
    axios({
      method: "get",
      url: `${Configs.API_Ticket}/api/ticket/selectSLAstatus`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        // console.log("select_sla",response.data);
        const dataW = [];
        // console.log(response.data);
        response.data.forEach((item, index) => {
          const formatData = {
            value: item.sla_status_id,
            label: item.sla_status_name,
          };
          dataW.push(formatData);
        });
        setselectSLAstatusOption(dataW);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  function selectPhase() {
    axios({
      method: "get",
      url: `${Configs.API_Ticket}/api/ticket/selectPhase`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        // console.log("select_sla",response.data);
        const dataW = [];
        // console.log(response.data);
        response.data.forEach((item, index) => {
          const formatData = {
            value: item.phase_id,
            label:
              item.phase_name.charAt(0).toUpperCase() +
              item.phase_name.slice(1).toLowerCase(),
          };
          dataW.push(formatData);
        });
        setselectPhaseList(dataW);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  function onClickNotifiedTime(day) {
    setTickect_List({
      ...Tickect_List,
      ticket_select_day_notified: day,
      // ticket_notified_time: null,
    });
    Tickect_List.ticket_select_day_notified = day;
    // Tickect_List.ticket_notified_time = null;
  }

  function GetColorStatusProject(status_name) {
    let str_return = "";
    if (status_name === "เสร็จสิ้น") {
      str_return = "#47BA6F";
    } else if (status_name === "ยกเลิก") {
      str_return = "#DD2025";
    } else if (status_name === "กำลังดำเนินการ") {
      str_return = "#007BFF";
    } else if (status_name === "รอดำเนินการ") {
      str_return = "#FEC400";
    }
    return str_return;
  }

  const formatDuration = (minutes, showAll) => {
    const days = Math.floor(minutes / (60 * 24));
    let hours = !showAll
      ? Math.floor(minutes / 60)
      : Math.floor((minutes % (60 * 24)) / 60);
    const mins = minutes % 60;
    /* if (!showAll) {
      hours = Math.floor((minutes) / 60);
    } */
    return !showAll
      ? `${hours} hr ${mins} min`
      : `${days} day ${hours} hr ${mins} min`;
  };

  function GetColorFill(key) {
    let str_return = "#007BFF";
    if (key?.toLowerCase() === "minor") {
      str_return = "#808080";
    } else if (key?.toLowerCase() === "average") {
      str_return = "#FF7B7F";
    } else if (key?.toLowerCase() === "major") {
      str_return = "#FEC400";
    } else if (key?.toLowerCase() === "critical") {
      str_return = "#DD2025";
    }

    return str_return;
  }

  const cardData = [];
  ALlTicketList.forEach(async (item, item_index) => {
    cardData.push({
      id: item.id,
      content: (
        <>
          {/* TODO: */}
          <div
            className="row"
            style={{ alignItems: "center", height: "100%", gap: "3px" }}
          >
            <div className="col">
              <div
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <div style={{}}>
                  <Checkbox
                    checked={ticket_id_Report.id_list.includes(item.id)}
                    onChange={(e) => {
                      onChange_Checkbox(e.target.checked, item.id, item);
                    }}
                  ></Checkbox>
                </div>

                <div
                  style={{
                    // overflow: "hidden",
                    // textOverflow: "ellipsis",
                    maxWidth: "300px",
                  }}
                >
                  <Tooltip
                    title={`${item.ticket_name} ${item.ticket_name_full}`}
                    placement="top"
                  >
                    <a
                      href={`/projectmanagement/Ticket/View/${item.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <span
                        className="text-hover-project-name-all-project"
                        style={{
                          fontSize: "18px",
                          fontWeight: 700,
                          // color: "#007BFF",
                          // whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          "-webkit-line-clamp": "1",
                          "-webkit-box-orient": "vertical",
                          wordBreak: "break-all",
                        }}
                      >
                        {`${item.ticket_name} ${item.ticket_name_full}`}
                      </span>
                    </a>
                  </Tooltip>
                  <div
                    style={{
                      // maxWidth: GetMaxWidthOverflow(item.ticket_tsb_id),
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      "-webkit-line-clamp": "1",
                      "-webkit-box-orient": "vertical",
                      wordBreak: "break-all",
                    }}
                  >
                    {item.ticket_description ? (
                      <>
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "11px",
                            color: "#808080",
                            margin: "0",
                            // whiteSpace: "nowrap",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setmodalDescription({
                              isOpen: true,
                              text: item.ticket_description,
                            });
                            modalDescription.isOpen = true;
                            modalDescription.text = item.ticket_description;
                          }}
                        >
                          {item.ticket_description || "No Description"}
                        </span>
                        {/* <Tooltip title={`${item.ticket_description}`} placement="bottom">
                          </Tooltip> */}
                      </>
                    ) : (
                      <>
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "11px",
                            color: "#808080",
                            margin: "0",
                            // whiteSpace: "nowrap",
                          }}
                        >
                          {item.ticket_description || "No Description"}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-auto">
              <div className="">
                <p
                  style={{ color: "gray", fontSize: "11px", textAlign: "end", marginBottom: '6px' }}
                >
                  Ticket Name
                </p>
              </div>
              <div style={{ overflow: "hidden", textOverflow: "ellipsis", textAlign: "end", maxWidth: '70px' }}>
                <Tooltip
                  title={`${item.ticket_name_full}`}
                  placement="top"
                >
                  <span
                    style={{
                      fontWeight: "400",
                      color: "#007BFF",
                      fontSize: "13px",
                      margin: "0",
                      whiteSpace: "nowrap",
                    }}
                  >{item.ticket_name_full}
                  </span>
                </Tooltip>
              </div>
            </div> */}

            <div className="col-1" style={{}}>
              <div>
                <p
                  style={{ color: "gray", fontSize: "11px", textAlign: "end" }}
                >
                  Status Ticket
                </p>
              </div>
              {item.st_name === null ? (
                <div style={{ marginTop: "-10px", textAlign: "end" }}>
                  {"-"}
                </div>
              ) : (
                <>
                  {item.st_name === "Success" ? (
                    <>
                      <div
                        style={{
                          marginTop: "-10px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          size="small"
                          as="input"
                          type="primary"
                          success
                          value="Input"
                          style={{
                            background: "#47BA6F",
                            // border: "none",
                            fontSize:
                              "13px" /* width: "33px", height: "35px" */,
                            padding: "3px 10px",
                            border: "none",
                          }}
                        >
                          {item.st_name || "Success"}
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className=""
                        style={{
                          marginTop: "-10px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          size="small"
                          as="input"
                          type="primary"
                          value="Input"
                          style={{
                            backgroundColor: "#007BFF",
                            border: "none",
                            fontSize:
                              "13px" /* width: "33px", height: "35px" */,
                            padding: "3px 10px",
                            border: "none",
                          }}
                        >
                          {item.st_name}
                        </Button>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>

            <div className="col-1">
              <div className="">
                <p
                  style={{ color: "gray", fontSize: "11px", textAlign: "end" }}
                >
                  Phase
                </p>
              </div>
              {item.phase_name === null ? (
                <div style={{ marginTop: "-10px", textAlign: "end" }}>
                  {"-"}
                </div>
              ) : (
                <>
                  {item.phase_name === "CLOSE" ? (
                    <>
                      <div
                        style={{
                          marginTop: "-10px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          size="small"
                          as="input"
                          type="primary"
                          value="Input"
                          style={{
                            backgroundColor: "#47BA6F",
                            // border: "none",
                            fontSize:
                              "13px" /* width: "33px", height: "35px" */,
                            padding: "3px 10px",
                            border: "none",
                          }}
                        >
                          {item.phase_name
                            ? item.phase_name.charAt(0).toUpperCase() +
                                item.phase_name.slice(1).toLowerCase() || "Done"
                            : "-"}
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          marginTop: "-10px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          size="small"
                          as="input"
                          type="primary"
                          value="Input"
                          style={{
                            backgroundColor: "#007BFF",
                            // border: "none",
                            fontSize:
                              "13px" /* width: "33px", height: "35px" */,
                            padding: "3px 10px",
                            border: "none",
                          }}
                        >
                          {item.phase_name
                            ? item.phase_name.charAt(0).toUpperCase() +
                              item.phase_name.slice(1).toLowerCase() +
                              (Number(item.count_status_reopen || 0) > 0 &&
                              item.phase_name === "REOPEN"
                                ? `(${Number(item.count_status_reopen || 0)})`
                                : "")
                            : "-"}
                        </Button>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            <div className="col-1">
              <div className="">
                <p
                  style={{ color: "gray", fontSize: "11px", textAlign: "end" }}
                >
                  Severity
                </p>
              </div>
              <div style={{ marginTop: "-10px", textAlign: "end" }}>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: GetColorFill(item.severity_name || "No Status"),
                  }}
                >
                  {" "}
                  {`${
                    item.severity_name
                      ? item.severity_name.charAt(0).toUpperCase() +
                        item.severity_name.slice(1).toLowerCase()
                      : "No Status"
                  }`}
                </span>
              </div>
            </div>

            <div className="col-1">
              <div className="">
                <p
                  style={{ color: "gray", fontSize: "11px", textAlign: "end" }}
                >
                  SLA Status
                </p>
              </div>
              <div style={{ marginTop: "-10px", textAlign: "end" }}>
                {item.sla_status_name === "Fail" ? (
                  <>
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#DD2025",
                        fontWeight: "bold",
                      }}
                    >
                      {!item.severity_name ? "-" : item.sla_status_name || "-"}
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      style={{
                        color:
                          item.sla_status_name === "In Process"
                            ? "#FEC400"
                            : item.sla_status_name === "Pass"
                            ? "#47BA6F"
                            : "#000000",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      {!item.severity_name ? "-" : item.sla_status_name || "-"}
                    </span>
                  </>
                )}
              </div>
            </div>

            <div className="col-auto">
              <div className="">
                <p
                  style={{ color: "gray", fontSize: "11px", textAlign: "end" }}
                >
                  Start Date
                </p>
              </div>
              <div style={{ marginTop: "-10px", textAlign: "end" }}>
                <i
                  class="far fa-calendar"
                  style={{
                    marginTop: "8px",
                    color: "#007BFF",
                    fontSize: "13px",
                  }}
                ></i>{" "}
                <span
                  style={{
                    fontSize: "11px",
                    marginTop: "5px",
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                  }}
                >
                  {`${moment(item.ticket_start_date).format(
                    "YYYY-MM-DD HH.mm"
                  )}`}
                </span>
              </div>
            </div>

            <div className="col-auto" style={{}}>
              <div className="">
                <p
                  style={{ color: "gray", fontSize: "11px", textAlign: "end" }}
                >
                  Complete in
                </p>
              </div>
              <div
                style={{
                  marginTop: "-10px",
                  textAlign: "end",
                  display: "flex",
                  whiteSpace: "nowrap",
                  alignItems: "center",
                  gap: "5px",
                  justifyContent: "flex-end",
                }}
              >
                {!item.severity_name ? (
                  <span>-</span>
                ) : (
                  <>
                    <i
                      class="far fa-clock"
                      style={{
                        // marginTop: "5px",
                        color: "#007BFF",
                        fontSize: "13px",
                      }}
                    ></i>{" "}
                    {/* {item.ticket_severity_id === null ||
                    moment(item.ticket_start_date).diff(moment(), "millisecond") >
                      0 ? (
                      <>{"-"}</>
                    ) :
                    ( */}
                    <>
                      <Tooltip
                        title={`${formatDuration(
                          Math.max(
                            moment(item.ticket_start_date)
                              .add(parseInt(item.severity_sla_time), "minutes")
                              .diff(
                                item.date_fixed
                                  ? moment(item.date_fixed)
                                  : moment(),
                                "minutes"
                              ),
                            0
                          ),
                          true
                        )}`}
                        placement="top"
                      >
                        <span style={{ fontSize: "11px", fontWeight: "bold" }}>
                          {formatDuration(
                            Math.max(
                              moment(item.ticket_start_date)
                                .add(
                                  parseInt(item.severity_sla_time),
                                  "minutes"
                                )
                                .diff(
                                  item.date_fixed
                                    ? moment(item.date_fixed)
                                    : moment(),
                                  "minutes"
                                ),
                              0
                            )
                          )}
                        </span>
                      </Tooltip>
                    </>
                  </>
                )}
              </div>
            </div>

            <div className="col-1" style={{}}>
              <div style={{ display: "flex", gap: "5px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <div
                    style={{
                      color: "gray",
                      fontSize: "11px",
                      // marginBottom: "0.5rem",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Create By
                  </div>
                  <Tooltip title={`${item.create_by}`} placement="top">
                    <Avatar
                      size={30}
                      src={
                        item?.create_by_img_path
                          ? Configs.API_URL_IMG + item?.create_by_img_path
                          : undefined
                        /* !FullProject.memberAll?.find(
                          (el) => el.id_user == item.create_by_id
                        )?.iu_profile_path
                        ? undefined
                        : `${Configs.API_URL}/static/` +
                        FullProject.memberAll?.find(
                          (el) => el.id_user == item.create_by_id
                        )?.iu_profile_path */
                      }
                      onError={(e) => {
                        e.target.value = item.create_by
                          .substring(0, 1)
                          .toUpperCase();
                      }}
                      style={{
                        backgroundColor: "#f56a00",
                      }}
                    >
                      {item.create_by.substring(0, 1).toUpperCase()}
                    </Avatar>
                  </Tooltip>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <div
                    style={{
                      color: "gray",
                      fontSize: "11px",
                      // marginLeft: "50px",
                      // marginBottom: "0.5rem",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Edit By
                  </div>
                  {item.edit_by_id ? (
                    <>
                      <Tooltip title={`${item.edit_by}`} placement="top">
                        <Avatar
                          style={{
                            backgroundColor: "#f56a00",
                          }}
                          size={30}
                          src={
                            item?.edit_by_img_path
                              ? Configs.API_URL_IMG + item?.edit_by_img_path
                              : undefined
                            /* !FullProject.memberAll?.find(
                                (el) => el.id_user == item.edit_by_id
                              )?.iu_profile_path
                              ? undefined
                              : `${Configs.API_URL}/static/` +
                              FullProject.memberAll?.find(
                                (el) => el.id_user == item.edit_by_id
                              )?.iu_profile_path */
                          }
                          onError={(e) => {
                            e.target.value = item.edit_by
                              .substring(0, 1)
                              .toUpperCase();
                          }}
                        >
                          {item.edit_by.substring(0, 1).toUpperCase()}
                        </Avatar>
                      </Tooltip>
                    </>
                  ) : (
                    <div>-</div>
                  )}
                </div>
              </div>
            </div>

            <div
              className="col-auto"
              style={{
                borderRight: "3px solid #ccc",
                height: "80px",
                margin: "0 10px",
                padding: "0",
              }}
            ></div>
            <div className="col-auto">
              {SwitchButton(item.ticket_tsb_id, item.id, item)}
            </div>
            {/* <Button as="input" type="button" value="Input" style={{ backgroundColor: "#007BFF", border: "none", fontSize:"13px",marginTop:"21px" }}
                      >
                        Approve
                      </Button> */}
            <div className="col-auto">
              {UserPermi.iu_ps_id !==
              "d6655dd6-0302-4ebe-bc8c-de61a0339777" /* is not tester */ ? (
                <></>
              ) : (
                <>
                  <Dropdown
                    menu={{
                      items: [
                        {
                          label: (
                            <>
                              {item.phase_name === "CLOSE" ? (
                                <>
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "black" }}
                                    onClick={() => {
                                      // console.log("item :>> ", item);
                                      selectSeverity(item.pr_id_sl);
                                      SelectDev(item.pr_id);
                                      setTickect_List({
                                        ...Tickect_List,
                                        mode: "view",
                                      });
                                      Tickect_List.mode = "view";
                                      // console.log('Tickect_List.mode', Tickect_List.mode)
                                      getByIdTicket(item.id);
                                      showDrawer();
                                      // getByIdProject(item.id);
                                    }}
                                  >
                                    View Ticket
                                  </a>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ),
                          key: "2",
                        },
                        {
                          label: (
                            <>
                              {item.ticket_tsb_id ===
                                "a4b304ec-0af4-49ef-91e6-f9d04a1effc6" ||
                              item.sla_status_name === "Fail" ||
                              item.phase_name !== "CLOSE" ? (
                                <>
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "#FF9900" }}
                                    onClick={() => {
                                      // console.log("item :>> ", item);
                                      selectSeverity(item.pr_id_sl);
                                      SelectDev(item.pr_id);
                                      // console.log('phase_name', item.phase_name)
                                      setTickect_List({
                                        ...Tickect_List,
                                        mode: "edit",
                                      });
                                      Tickect_List.mode = "edit";
                                      getByIdTicket(item.id);
                                      showDrawer();
                                      // getByIdProject(item.id);
                                    }}
                                  >
                                    Edit Ticket
                                  </a>
                                </>
                              ) : (
                                <>
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "black" }}
                                    onClick={() => {
                                      // setTickect_List({...Tickect_List, mode:"edit"});
                                      // getByIdTicket(item.id);
                                      // showDrawer();
                                      // getByIdProject(item.id);
                                    }}
                                  >
                                    Do not edit
                                  </a>
                                </>
                              )}
                            </>
                          ),
                          key: "0",
                        },
                        {
                          label: (
                            <>
                              {item.ticket_tsb_id !==
                                "5fc5716d-91a5-4a76-b3fc-1e7b073cf9d7" ||
                              item.ticket_tsb_id ===
                                "2027332c-b338-470b-9ab1-4c7b99775288" ||
                              item.sla_status_name === "Fail" ? (
                                <>
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "#DD2025" }}
                                    onClick={() => {
                                      delTicket(item.id);
                                    }}
                                  >
                                    Delete Ticket
                                  </a>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ),
                          key: "1",
                        },
                      ],
                    }}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>
                        <img
                          src={img_Three_dot}
                          width={25}
                          height={25}
                          style={
                            {
                              /* marginLeft: "18px", marginTop: "15px" */
                            }
                          }
                        />
                      </Space>
                    </a>
                  </Dropdown>
                </>
              )}
            </div>
          </div>
        </>
      ),
    });
  });

  const ticketReportRef = useRef(null);
  // ticketReportRef.current.hidden

  function GetReportExcel() {
    return (
      <table id="table-to-xls" style={{ display: "none" }}>
        <tbody>
          <tr>
            <th
              colSpan="10"
              rowSpan="4"
              contentEditable="true"
              style={{ width: "1500px" }}
            >
              <div style={{ marginLeft: "100px" }} className="row">
                {/* {imageBuffer && <img src={`data:image/png;base64,${arrayBufferToBase64(imageBuffer)}`} alt="Image" style={{ height: "60px" }} />} */}
                เอกสารบันทึกรายชื่อผู้รับผิดชอบข้อผิดพลาด (Document recode
                responsible person error project)
              </div>
            </th>
          </tr>
          <tr></tr>
          <tr></tr>
          <tr></tr>
          <tr colSpan="10">
            <th
              colSpan="10"
              style={{
                border: "1px solid black",
                backgroundColor: "#90A4E3",
              }}
            >
              Introduction
            </th>
          </tr>
          <tr>
            <th
              colSpan="10"
              rowSpan="4"
              contentEditable="true"
              style={{
                border: "0.5px solid black",
                textAlign: "left",
                width: "1500px",
              }}
            >
              เอกสารชุดนี้เรียกว่า Document reocde responsible person error
              project มีวัถตุประสงค์เพื่อ <br />
              1. บันทึกข้อมูลรายชื่อของผู้ที่ทำหน้าที่รับผิดชอบการดำเนินการแก้ไข
              Ticket ที่เกิดขึ้นในระบบ <br />
              2. เพื่อใช้สำหรับการประเมินผลการทำงานและการดำเนินการแก้ไขปัญหา
              Ticket <br />
              3.
              สำหรับการดำเนินการค้นหาและติดตามการแก้ไขข้อผิดพลาดที่เกิดขึ้นได้ตรงตามบุคคลที่รับผิดชอบ
            </th>
          </tr>
          <tr></tr>
          <tr></tr>
          <tr></tr>
          <tr></tr>
          <tr colSpan="2">
            <th
              style={{
                backgroundColor: "#90A4E3",
                border: "1px solid black",
              }}
            >
              Author :
            </th>
            <th
              colSpan="9"
              style={{ textAlign: "left", border: "1px solid black" }}
            >
              {projectDataReport.pr_create_by || "-"}
            </th>
          </tr>
          <tr colSpan="2">
            <th
              style={{
                backgroundColor: "#90A4E3",
                border: "1px solid black",
              }}
            >
              Project :
            </th>
            <th
              th
              colSpan="9"
              style={{ textAlign: "left", border: "1px solid black" }}
            >
              {projectDataReport.pr_project_code || "-"}
            </th>
          </tr>
          <tr></tr>
          <tr>
            <th
              colSpan="3"
              style={{
                textAlign: "left",
                color: "#FFFFFF",
                backgroundColor: "#4682B4",
                border: "1px solid black",
              }}
            >
              Error Project :
            </th>
          </tr>
          <tr>
            <th
              style={{
                backgroundColor: "#90A4E3",
                border: "1px solid black",
              }}
            >
              Code.
            </th>
            <th
              style={{
                backgroundColor: "#90A4E3",
                border: "1px solid black",
              }}
            >
              Date Start
            </th>
            <th
              colSpan="3"
              style={{
                backgroundColor: "#90A4E3",
                border: "1px solid black",
              }}
            >
              Error Project
            </th>
            <th
              style={{
                backgroundColor: "#90A4E3",
                border: "1px solid black",
              }}
            >
              Ticket type
            </th>
            <th
              style={{
                backgroundColor: "#90A4E3",
                border: "1px solid black",
              }}
            >
              Severity
            </th>
            <th
              style={{
                backgroundColor: "#90A4E3",
                border: "1px solid black",
              }}
            >
              Date Finish
            </th>
            <th
              style={{
                backgroundColor: "#90A4E3",
                border: "1px solid black",
              }}
            >
              Problem owner
            </th>
            <th
              style={{
                backgroundColor: "#90A4E3",
                border: "1px solid black",
              }}
            >
              responsible person
            </th>
          </tr>
          {ticketDataReport.map((data, index) => (
            <tr>
              <th style={{ textAlign: "left", border: "1px solid black" }}>
                {" "}
                {data.ticket_code || "-"}
              </th>
              <th style={{ textAlign: "left", border: "1px solid black" }}>
                {" "}
                {moment(data.ticket_start_date).format("YYYY/MM/DD HH.mm") ||
                  "-"}
              </th>
              <th
                colSpan="3"
                style={{ textAlign: "left", border: "1px solid black" }}
              >
                {data.ticket_description || "-"}
              </th>
              <th style={{ textAlign: "left", border: "1px solid black" }}>
                {data.tt_name || (
                  <div style={{ textAlign: "center" }}>{"-"}</div>
                )}
              </th>
              {data.severity_name === "minor" ? (
                <>
                  <th
                    style={{
                      background: "#DD2025",
                      textAlign: "center",
                      border: "1px solid black",
                    }}
                  >
                    {data.severity_name || "-"}
                  </th>
                </>
              ) : (
                <>
                  {data.severity_name === "average" ? (
                    <>
                      <th
                        style={{
                          background: "#FF9900",
                          textAlign: "center",
                          border: "1px solid black",
                        }}
                      >
                        {data.severity_name || "-"}
                      </th>
                    </>
                  ) : (
                    <>
                      {data.severity_name === "major" ? (
                        <>
                          <th
                            style={{
                              background: "#AD00FF",
                              textAlign: "center",
                              border: "1px solid black",
                            }}
                          >
                            {data.severity_name || "-"}
                          </th>
                        </>
                      ) : (
                        <>
                          {data.severity_name === "critical" ? (
                            <>
                              <th
                                style={{
                                  background: "#007BFF",
                                  textAlign: "center",
                                  border: "1px solid black",
                                }}
                              >
                                {data.severity_name || "-"}
                              </th>
                            </>
                          ) : (
                            <>
                              {" "}
                              <th
                                style={{
                                  textAlign: "center",
                                  border: "1px solid black",
                                }}
                              >
                                {"Feedback"}
                              </th>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              <th style={{ textAlign: "left", border: "1px solid black" }}>
                {moment(data.ticket_start_date)
                  .add(180, "minutes")
                  .format("YYYY/MM/DD HH.mm") || "-"}
              </th>
              <th style={{ textAlign: "center", border: "1px solid black" }}>
                {/* {data.ticket_customer_name || "-"} */}
              </th>
              <th style={{ textAlign: "left", border: "1px solid black" }}>
                {data.manage_by_f + " " + data.manage_by_l || "-"}
              </th>
            </tr>
          ))}
          <tr></tr>
          <tr>
            <th colSpan="3">Project manager</th>
            <th></th>
            <th></th>
            <th></th>
            <th colSpan="3">Deverloper</th>
          </tr>
          <tr></tr>
          <tr>
            <th colSpan="3">
              ลงชื่อ:
              (.............................................................)
            </th>
            <th></th>
            <th></th>
            <th></th>
            <th colSpan="3">
              {" "}
              ลงชื่อ:
              (...................................................................){" "}
            </th>
          </tr>

          <tr>
            <th>วันที่ :</th>
            <th /* colSpan="2" style={{textAlign:"left"}} */>
              {moment(new Date()).format("YYYY/MM/DD")}
            </th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th>วันที่ :</th>
            <th /* colSpan="2" style={{textAlign:"left"}} */>
              {moment(new Date()).format("YYYY/MM/DD")}
            </th>
          </tr>

          <tr></tr>
          <tr></tr>

          <tr>
            <th colSpan="3">Software tester</th>
          </tr>
          <tr></tr>
          <tr>
            <th colSpan="3">
              ลงชื่อ:
              (..........................................................)
            </th>
          </tr>
          <tr>
            <th>วันที่ :</th>
            <th /* style={{textAlign:"left"}} */>
              {moment(new Date()).format("YYYY/MM/DD")}
            </th>
          </tr>
        </tbody>
      </table>
    );
  }

  function GetElementBtnAddAndExport() {
    return (
      <>
        {UserPermi.iu_ps_id !== "d6655dd6-0302-4ebe-bc8c-de61a0339777" ? ( // is not tester
          <div
            className={
              is_all
                ? "col-12 col-sm-12 col-md-6 col-lg-4"
                : "col-12 col-sm-12 col-md-0 col-lg-6"
            }
            style={
              {
                /*  marginBottom: "10px"  */
              }
            }
          ></div>
        ) : (
          <></>
        )}
        <div
          className={
            is_all
              ? "col-12 col-sm-12 col-md-6 col-lg-4"
              : "col-12 col-sm-12 col-md-6 col-lg-6"
          }
          style={{ marginBottom: "10px" }}
        >
          <Button
            size="large"
            type={"primary"}
            style={{
              /* marginRight: "5px", */
              backgroundColor:
                ticket_id_Report.id_list.length !== 0
                  ? "rgb(0, 123, 255)"
                  : "gray",
              border: "none",
              color: "white",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // marginBottom: '10px',
              height: "45px",
              gap: "5px",
              borderRadius: "5px",
              whiteSpace: "nowrap",
            }}
            disabled={ticket_id_Report.id_list.length === 0}
            onClick={async () => {
              // console.log('ticketReportRef.current :>> ', ticketReportRef.current);
              /* if (ticketReportRef.current)
                              ticketReportRef.current.handleDownload(); */
              Swal.fire({
                title: "คุณต้องการออก Report ข้อมูลนี้หรือไม่ ?",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "ยืนยัน",
                cancelButtonText: "ยกเลิก",
              }).then(async (result) => {
                if (result.isConfirmed) {
                  await ExportReportProject();
                }
              });
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-plus-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
            </svg>{" "}
            {/* <span style={{ fontSize: "20px", whiteSpace: "nowrap" }}>
                          </span> */}
            Export Report
          </Button>
        </div>
        {UserPermi.iu_ps_id !== "d6655dd6-0302-4ebe-bc8c-de61a0339777" ? ( // is not tester
          <></>
        ) : (
          <div
            className={
              is_all
                ? "col-12 col-sm-12 col-md-6 col-lg-4"
                : "col-12 col-sm-12 col-md-6 col-lg-6"
            }
            style={{ marginBottom: "10px" }}
          >
            <Button
              size="large"
              type="primary"
              style={{
                border: "none",
                backgroundColor: "#007BFF",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "45px",
                gap: "5px",
                borderRadius: "5px",
              }}
              onClick={() => {
                onClose(null, true);
                if (!is_all && project_id) {
                  SelectDev();
                  selectSeverity();
                  genProjectCode(project_id);
                }
                setTickect_List({ ...Tickect_List, mode: "add" });

                showDrawer();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-plus-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
              </svg>
              {"  "}
              {/* <span style={{ fontSize: "20px" }}></span> */}
              Add Ticket
            </Button>
          </div>
        )}
        {/* {FormatOld()} */}
      </>
    );
  }

  function GetElementBtnSearchAndClear() {
    return (
      <>
        <div
          className={
            is_all
              ? "col-12 col-sm-12 col-md-6 col-lg-4"
              : "col-12 col-sm-12 col-md-4 col-lg-2"
          }
          style={{ marginBottom: "10px" }}
        >
          <Button
            size="large"
            type="primary"
            style={{
              border: "none",
              backgroundColor: "#007BFF",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
              height: "45px",
              borderRadius: "5px",
            }}
            onClick={() => {
              setCurrentPage(1);
              filterAllTicket();
              // getDataReportExcelProject(project_id);
            }}
          >
            Search
          </Button>
        </div>
        <div
          className={
            is_all
              ? "col-12 col-sm-12 col-md-6 col-lg-4"
              : "col-12 col-sm-12 col-md-4 col-lg-2"
          }
          style={{ marginBottom: "10px" }}
        >
          <Button
            size="large"
            type="primary"
            style={{
              border: "none",
              backgroundColor: "#007BFF",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
              height: "45px",
              borderRadius: "5px",
            }}
            onClick={() => {
              setFilter({
                ...Filter,
                search_text: "",
                search_text_prv: "",
                severity_name: "",
                phase_id: "",
                sla_status_id: "",
                project_id: "",
              });
              Filter.search_text = "";
              Filter.search_text_prv = "";
              Filter.severity_name = "";
              Filter.phase_id = "";
              Filter.sla_status_id = "";
              Filter.project_id = "";

              setCurrentPage(1);
              filterAllTicket();
            }}
          >
            Clear
          </Button>
        </div>
      </>
    );
  }

  return (
    <div className="content-wrapper">
      {/* <img style={{ height: '190px', width: '1000px' }} src={`${'http://localhost:5555'}/static_ticket${'/Mail/photo_2024-07-02_14-55-32.jpg'}`}/> */}
      <section className="content-header">
        <div className="container-fluid" style={{ marginTop: "10px" }}>
          <div className="row" style={{ justifyContent: "space-between" }}>
            <div
              className="col-12 col-sm-auto col-xl-auto"
              style={{ display: "flex" }}
            >
              <img
                alt="..."
                className="img_header_ticket"
                src={img_header_ticket}
                width={30}
                height={30}
              />
              <h1
                style={{
                  fontWeight: 700,
                  fontSize: "55px",
                  marginTop: "-16px",
                  marginLeft: "18px",
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                {is_all ? (
                  <>All Ticket</>
                ) : (
                  <>
                    {FullProject.StrProjectNameShow}
                    <Avatar.Group
                      maxPopoverPlacement="bottom"
                      maxCount={3}
                      size="default"
                      maxStyle={{
                        color: "#f56a00",
                        backgroundColor: "#fde3cf",
                        marginRight: "10px",
                        // marginBottom: "10px",
                      }}
                      style={{ display: "flex" }}
                    >
                      {FullProject.memberAll?.map((user, index) => (
                        <Tooltip
                          title={`${user.account_name}`}
                          placement="bottom"
                        >
                          <Avatar
                            src={
                              user?.iu_profile_path
                                ? Configs.API_URL_IMG + user?.iu_profile_path
                                : undefined
                            }
                            onError={(e) => {
                              e.target.value = user.account_name
                                .substring(0, 1)
                                .toUpperCase();
                            }}
                            key={user.id_user}
                            style={{
                              backgroundColor: "#f56a00",
                            }}
                          >
                            {user.account_name.substring(0, 1).toUpperCase()}
                          </Avatar>
                        </Tooltip>
                      ))}
                    </Avatar.Group>
                  </>
                )}
              </h1>
            </div>
            <div className="col-12 col-sm-12 col-xl-auto">
              <ol className="breadcrumb float-sm-right ">
                <li className="breadcrumb-item active">
                  Home
                  {/* <a href="/Welcome">Home</a> */}
                </li>
                <li className="breadcrumb-item active">Project Management</li>
                <li className="breadcrumb-item active">
                  {is_all ? "All Ticket" : "All Project"}
                </li>
                {is_all ? (
                  <></>
                ) : (
                  <li className="breadcrumb-item">
                    {FullProject.StrProjectNameShow}
                  </li>
                )}
                {/* <li className="breadcrumb-item active">{mode}</li> */}
              </ol>
            </div>
          </div>

          <div
            className="row"
            style={{ justifyContent: "space-between", alignItems: "end" }}
          >
            <div
              className={`col-12 col-sm-12 col-md-12 col-lg-${
                is_all ? "9" : "7"
              }`}
            >
              <div className="row" style={{ alignItems: "flex-end" }}>
                <div
                  className={`col-12 col-sm-12 col-md-${
                    is_all ? "8" : "4"
                  } col-lg-${is_all ? "4" : "2"}`}
                  style={{ marginBottom: "10px" }}
                >
                  <span style={{ fontSize: "18px", margin: "0" }}>Search</span>
                  <input
                    // type="search"
                    id="search"
                    className="form-control"
                    placeholder="search"
                    value={Filter.search_text}
                    onChange={(e) => {
                      const value_prve = Filter.search_text;
                      setFilter({
                        ...Filter,
                        search_text: e.target.value,
                        search_text_prv: value_prve,
                      });
                      Filter.search_text = e.target.value;
                      Filter.search_text_prv = value_prve;
                    }}
                    /* onClick={() => {
                      setTimeout(() => {
                        if (Filter.search_text_prv && !Filter.search_text) {
                          const value_prve = '';
                          setFilter({
                            ...Filter,
                            search_text_prv: value_prve,
                          });
                          Filter.search_text_prv = value_prve;
                          filterAllTicket();
                          getDataReportExcelProject(project_id);
                        }
                      }, 100)
                    }}
                    onKeyPress={handleEnterKeyPress} */
                  />
                </div>
                <div
                  className="col-12 col-sm-12 col-md-4 col-lg-2"
                  style={{ marginBottom: "10px" }}
                >
                  <span
                    style={{
                      fontSize: "18px",
                      margin: "0",
                      whiteSpace: "nowrap",
                    }}
                  >
                    SLA Status
                  </span>
                  <Select
                    className="select_SLA_Status"
                    placeholder="All"
                    id="select_SLA_Status"
                    styles={customStyles}
                    options={selectSLAstatusOption}
                    value={
                      selectSLAstatusOption.find((item, key) => {
                        return item.value === Filter.sla_status_id;
                      }) || null
                    }
                    onChange={(e) => {
                      if (e) {
                        setFilter({
                          ...Filter,
                          sla_status_id: e.value,
                        });
                        // filterAllTicket();
                      } else {
                        setFilter({
                          ...Filter,
                          sla_status_id: "",
                        });
                        // filterAllTicket();
                      }
                    }}
                    isClearable={true}
                  />
                </div>
                <div
                  className="col-12 col-sm-12 col-md-4 col-lg-2"
                  style={{ marginBottom: "10px" }}
                >
                  <span style={{ fontSize: "18px", margin: "0" }}>Phase</span>
                  <Select
                    className="select_Phase"
                    placeholder="All"
                    id="select_Phase"
                    styles={customStyles}
                    options={selectPhaseList}
                    value={
                      selectPhaseList.find((item, key) => {
                        return item.value === Filter.phase_id;
                      }) || null
                    }
                    onChange={(e) => {
                      if (e) {
                        setFilter({
                          ...Filter,
                          phase_id: e.value,
                        });
                        // filterAllTicket();
                      } else {
                        setFilter({
                          ...Filter,
                          phase_id: "",
                        });
                        // filterAllTicket();
                      }
                    }}
                    isClearable={true}
                  />
                </div>
                <div
                  className="col-12 col-sm-12 col-md-4 col-lg-2"
                  style={{ marginBottom: "10px" }}
                >
                  <span style={{ fontSize: "18px", margin: "0" }}>
                    Severity
                  </span>
                  <Select
                    className="select_Severity"
                    placeholder="All"
                    id="select_Severity"
                    styles={customStyles}
                    options={SelectSeverityFilterList}
                    value={
                      SelectSeverityFilterList.find(
                        (option) => option.value === Filter.severity_name
                      ) || null
                    }
                    onChange={(e) => {
                      if (e) {
                        setFilter({
                          ...Filter,
                          severity_name: e.value,
                        });
                        Filter.severity_name = e.value;
                      } else {
                        setFilter({
                          ...Filter,
                          severity_name: "",
                        });
                        Filter.severity_name = "";
                      }
                      // filterAllTicket();
                    }}
                    isClearable={true}
                  />
                </div>
                {is_all ? (
                  <div
                    className="col-12 col-sm-12 col-md-4 col-lg-2"
                    style={{ marginBottom: "10px" }}
                  >
                    <span
                      style={{
                        fontSize: "18px",
                        margin: "0",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Project Code
                    </span>
                    <Select
                      className="select_Project_Code"
                      placeholder="All"
                      id="select_Project_Code"
                      styles={customStyles}
                      options={selectProjectCodeOption}
                      value={
                        selectProjectCodeOption.find((item, key) => {
                          return item.pr_id === Filter.project_id;
                        }) || null
                      }
                      onChange={(e) => {
                        if (e) {
                          setFilter({
                            ...Filter,
                            project_id: e.pr_id,
                          });
                          // filterAllTicket();
                        } else {
                          setFilter({
                            ...Filter,
                            project_id: "",
                          });
                          // filterAllTicket();
                        }
                      }}
                      isClearable={true}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {is_all ? <></> : <>{GetElementBtnSearchAndClear()}</>}
              </div>
            </div>
            <div
              className={`col-12 col-sm-12 col-md-${is_all ? 12 : 8} col-lg-${
                is_all ? 12 : isResponse.lg ? 4 : 3
              }`}
            >
              {!is_all ? (
                <div className="row">{GetElementBtnAddAndExport()}</div>
              ) : (
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6">
                    <div className="row">{GetElementBtnSearchAndClear()}</div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6">
                    <div className="row" style={{ justifyContent: "flex-end" }}>
                      {GetElementBtnAddAndExport()}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {GetReportExcel()}

          <Table_All_Project
            currentPage={currentPage}
            pageSize={pageSize}
            onChange={onChangePage}
            cardData={cardData}
            onPageChange={handlePageChange}
            onShowSizeChange={onShowSizeChange}
            total={total}
            minWidth={"1222px"}
          />
        </div>
      </section>

      <ConfigProvider>
        <Drawer
          title={
            Tickect_List.mode === "add"
              ? "Add Ticket"
              : Tickect_List.mode === "view"
              ? "View Ticket"
              : "Edit Ticket"
          }
          placement="right"
          onClose={onClose}
          open={open}
          // style={{ position: "absolute", paddingTop: "55px" }}
          extra={
            <Space>
              <Button
                style={{
                  background: "none",
                  color: "#00000090",
                  borderColor: "#00000060",
                  minWidth: "72px",
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                style={{
                  backgroundColor: "#007BFF",
                  border: "none",
                  minWidth: "72px",
                }}
                onClick={SaveTicket}
                hidden={Tickect_List.mode === "view"}
              >
                OK
              </Button>
            </Space>
          }
        >
          <div className="row" style={{ marginLeft: "2px" }}>
            Project Code
            <font color="red" hidden={!is_all}>
              *
            </font>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              {!is_all && project_id ? (
                <Input disabled value={FullProject.StrProjectNameShow}></Input>
              ) : (
                <Select
                  className="selectProjectCodeOption"
                  placeholder="All"
                  id="selectProjectCodeOption"
                  isDisabled={
                    is_all
                      ? Tickect_List.mode !== "add"
                      : Tickect_List.ticket_tsb_id !==
                          "a4b304ec-0af4-49ef-91e6-f9d04a1effc6" &&
                        Tickect_List.sla_status_name !== "Fail"
                  }
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: "100%",
                      height: "35px",
                    }),
                  }}
                  options={selectProjectCodeOption}
                  value={
                    selectProjectCodeOption.find((item, key) => {
                      return item.pr_id === Tickect_List.ticket_pr_id;
                    }) || null
                  }
                  onChange={(e) => {
                    // console.log('e :>> ', e);
                    setTickect_List({
                      ...Tickect_List,
                      ticket_pr_id: e.pr_id,
                    });
                    Tickect_List.ticket_pr_id = e.pr_id;
                    SelectDev(e.pr_id);
                    selectSeverity(e.pr_id_sl);
                    genProjectCode(e.pr_id);
                  }}
                />
              )}
            </div>
          </div>

          <div className="row mt-2" style={{ marginLeft: "2px" }}>
            Ticket No.
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <Input
                disabled
                placeholder="Ticket No."
                value={Tickect_List.ticket_no}
              ></Input>
            </div>
          </div>

          <div className="row mt-2" style={{ marginLeft: "2px" }}>
            Ticket Name<font color="red">*</font>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <Input
                disabled={
                  Tickect_List.ticket_tsb_id !==
                    "a4b304ec-0af4-49ef-91e6-f9d04a1effc6" &&
                  Tickect_List.sla_status_name !== "Fail" &&
                  Tickect_List.mode !== "add"
                }
                value={Tickect_List.ticket_name}
                placeholder="Ticket Name"
                onChange={(e) => {
                  setTickect_List({
                    ...Tickect_List,
                    ticket_name: e.target.value,
                  });
                }}
              ></Input>
            </div>
          </div>

          <div className="row mt-2" style={{ marginLeft: "2px" }}>
            Type of Ticket<font color="red">*</font>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <Select
                className="SelectTicket_TypeList"
                placeholder="All"
                id="SelectTicket_TypeList"
                isDisabled={
                  Tickect_List.ticket_tsb_id !==
                    "a4b304ec-0af4-49ef-91e6-f9d04a1effc6" &&
                  Tickect_List.sla_status_name !== "Fail" &&
                  Tickect_List.mode !== "add"
                }
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: "100%",
                    height: "35px",
                  }),
                }}
                options={SelectTicket_TypeList}
                value={SelectTicket_TypeList.filter((item, key) => {
                  return item.value === Tickect_List.ticket_tkt_id;
                })}
                onChange={(e) => {
                  setTickect_List({
                    ...Tickect_List,
                    ticket_tkt_id: e.value,
                    ticket_severity_id: "",
                  });
                }}
              />
            </div>
          </div>

          {Tickect_List.ticket_tkt_id ===
          "877aab14-ba1f-4d7f-826f-f2185de9e590" ? (
            <></>
          ) : (
            <>
              <div className="row mt-2" style={{ marginLeft: "2px" }}>
                Severity<font color="red">*</font>
              </div>
              <div className="row mt-2">
                <div className="col-12">
                  <Select
                    className="select_SLA_addProject"
                    placeholder="All"
                    id="select_SLA_addProject"
                    // isDisabled={is_all && !Tickect_List.ticket_pr_id}
                    isDisabled={
                      (is_all && !Tickect_List.ticket_pr_id) ||
                      (Tickect_List.ticket_tsb_id !==
                        "a4b304ec-0af4-49ef-91e6-f9d04a1effc6" &&
                        Tickect_List.sla_status_name !== "Fail" &&
                        Tickect_List.mode !== "add")
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "100%",
                        height: "35px",
                      }),
                    }}
                    options={SelectSeverityList?.filter((item, key) => {
                      if (
                        TICKET_TYPE_SPECIAL.includes(Tickect_List.ticket_tkt_id)
                      ) {
                        return item;
                      } else if (item.value) {
                        return item;
                      }
                    })}
                    value={SelectSeverityList?.filter((item, key) => {
                      return item.value === Tickect_List.ticket_severity_id;
                    })}
                    disabled={
                      Tickect_List.ticket_tsb_id !==
                        "a4b304ec-0af4-49ef-91e6-f9d04a1effc6" &&
                      Tickect_List.sla_status_name !== "Fail"
                    }
                    onChange={(e) => {
                      setTickect_List({
                        ...Tickect_List,
                        ticket_severity_id: e.value,
                      });
                    }}
                  />
                </div>
              </div>
            </>
          )}

          <div className="row mt-2" style={{ marginLeft: "2px" }}>
            Start Date<font color="red">*</font>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <DatePicker
                style={{ width: "100%", height: "35px" }}
                // renderExtraFooter={() => "extra footer"}
                disabled={
                  Tickect_List.ticket_tsb_id !==
                    "a4b304ec-0af4-49ef-91e6-f9d04a1effc6" &&
                  Tickect_List.sla_status_name !== "Fail" &&
                  Tickect_List.mode !== "add"
                }
                showTime
                onChange={handleStartDateChange}
                value={
                  Tickect_List.ticket_start_date
                    ? dayjs(Tickect_List.ticket_start_date)
                    : null
                }
                /* disabledDate={(current) =>
                  current && current < dayjs().startOf("day")
                } */
              />
            </div>
          </div>

          <div className="row mt-2" style={{ marginLeft: "2px" }}>
            Notified time{/* <font color="red">*</font> */}
          </div>
          <div className="row mt-2">
            <div
              className="col-12 d-flex"
              style={{ alignItems: "center", gap: "3px" }}
            >
              <DatePicker
                style={{ width: "100%", height: "35px" }}
                // renderExtraFooter={() => "extra footer"}
                disabled={
                  Tickect_List.ticket_tsb_id !==
                    "a4b304ec-0af4-49ef-91e6-f9d04a1effc6" &&
                  Tickect_List.sla_status_name !== "Fail" &&
                  Tickect_List.mode !== "add"
                }
                showTime
                onChange={handleNotifiedChange}
                value={
                  Tickect_List.ticket_notified_time
                    ? dayjs(Tickect_List.ticket_notified_time)
                    : null
                }
                disabledDate={(current) =>
                  current && current < dayjs().startOf("day")
                }
              />
              <span>OR</span>
              <Button
                disabled={
                  Tickect_List.ticket_tsb_id !==
                    "a4b304ec-0af4-49ef-91e6-f9d04a1effc6" &&
                  Tickect_List.sla_status_name !== "Fail" &&
                  Tickect_List.mode !== "add"
                }
                style={GetStyleButtonDay1To3("1")}
                onClick={() => onClickNotifiedTime("1")}
              >
                1 Day
              </Button>
              <Button
                disabled={
                  Tickect_List.ticket_tsb_id !==
                    "a4b304ec-0af4-49ef-91e6-f9d04a1effc6" &&
                  Tickect_List.sla_status_name !== "Fail" &&
                  Tickect_List.mode !== "add"
                }
                style={GetStyleButtonDay1To3("2")}
                onClick={() => onClickNotifiedTime("2")}
              >
                2 Day
              </Button>
              <Button
                disabled={
                  Tickect_List.ticket_tsb_id !==
                    "a4b304ec-0af4-49ef-91e6-f9d04a1effc6" &&
                  Tickect_List.sla_status_name !== "Fail" &&
                  Tickect_List.mode !== "add"
                }
                style={GetStyleButtonDay1To3("3")}
                onClick={() => onClickNotifiedTime("3")}
              >
                3 Day
              </Button>
            </div>
          </div>

          <div className="row mt-2" style={{ marginLeft: "2px" }}>
            Customer Name (คนแจ้งเรื่อง){/* <font color="red">*</font> */}
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <Input
                disabled={
                  Tickect_List.ticket_tsb_id !==
                    "a4b304ec-0af4-49ef-91e6-f9d04a1effc6" &&
                  Tickect_List.sla_status_name !== "Fail" &&
                  Tickect_List.mode !== "add"
                }
                value={Tickect_List.ticket_customer_name}
                onChange={(e) => {
                  setTickect_List({
                    ...Tickect_List,
                    ticket_customer_name: e.target.value,
                  });
                  console.log(
                    "Tickect_List.sla_status_name ",
                    Tickect_List?.sla_status_name
                  );
                  console.log("Tickect_List. ", Tickect_List);
                }}
              ></Input>
            </div>
          </div>

          <div className="row mt-2" style={{ marginLeft: "2px" }}>
            Create by
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <Input
                disabled
                value={`${getUser().name} ${getUser().surname}`}
              ></Input>
            </div>
          </div>
          {
            /* ผู้ใช้ไม่เข้าใจระบบ,  ให้คำปรึกษาแนะนำระบบ, feedback */
            Tickect_List.ticket_tkt_id ===
              "5e291a16-64b8-485f-964f-ab97b4ad2596" ||
            Tickect_List.ticket_tkt_id ===
              "bdbc1d7c-f109-4751-84a7-dd5fcdf72ad3" ||
            Tickect_List.ticket_tkt_id ===
              "ffd74165-fb9b-4416-9627-85dcbb938e4e" ? (
              <></>
            ) : (
              <>
                <div className="row mt-2" style={{ marginLeft: "2px" }}>
                  Assign to<font color="red">*</font>
                </div>
                <div className="row mt-2">
                  <div className="col-12">
                    <Select
                      className="select_ticket_administrator"
                      placeholder="All"
                      id="select_ticket_administrator"
                      isDisabled={
                        (is_all && !Tickect_List.ticket_pr_id) ||
                        (Tickect_List.ticket_tsb_id !==
                          "a4b304ec-0af4-49ef-91e6-f9d04a1effc6" &&
                          Tickect_List.sla_status_name !== "Fail" &&
                          Tickect_List.mode !== "add")
                      }
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "100%",
                          height: "35px",
                        }),
                      }}
                      options={SelectDevList}
                      value={SelectDevList.filter((item, key) => {
                        return item.value === Tickect_List.ticket_administrator;
                      })}
                      onChange={(e) => {
                        setTickect_List({
                          ...Tickect_List,
                          ticket_administrator: e.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </>
            )
          }

          {/* <div className="row" style={{ marginLeft: "2px" }}>
            Create Date
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <Input
                disabled
                value={moment(Tickect_List.ticket_create_date).format(
                  "YYYY-MM-DD HH.mm"
                )}
              ></Input>
            </div>
          </div> */}

          <div className="row mt-2" style={{ marginLeft: "2px" }}>
            Descriptions<font color="red">*</font>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <TextArea
                // showCount
                // maxLength={100}
                disabled={
                  Tickect_List.mode === "view" && Tickect_List.mode !== "add"
                }
                value={Tickect_List.ticket_description}
                onChange={(e) => {
                  setTickect_List({
                    ...Tickect_List,
                    ticket_description: e.target.value,
                  });
                }}
                placeholder=""
              />
            </div>
          </div>

          <div className="row mt-2" style={{ marginLeft: "2px" }}>
            Upload
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <Upload
                action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                listType="picture"
                fileList={fileList}
                onRemove={handleRemove}
                onChange={handleUploadSuccess}
                accept=".jpg,.png"
                multiple
              >
                <Button
                  style={{ border: "none", backgroundColor: "#007BFF" }}
                  disabled={
                    Tickect_List.mode === "view" && Tickect_List.mode !== "add"
                  }
                >
                  <UploadOutlined /> Upload
                </Button>
              </Upload>
            </div>
          </div>
        </Drawer>
        <Modal
          title="Description"
          centered
          open={modalDescription.isOpen}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          onCancel={() =>
            setmodalDescription({ ...modalDescription, isOpen: false })
          }
        >
          <p className="mt-2">{modalDescription.text}</p>
        </Modal>
      </ConfigProvider>
    </div>
  );
}
export default All_Ticket;
