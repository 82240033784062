import React, { useState, useEffect } from "react";

import "../../../../assets/css/ProductService.css";

import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import moment from "moment";

import axios from "axios";
import Configs from "../../../../config";

import {
  getOem,
  getToken,
  getUser,
  getAcademy,
} from "../../../../Utils/Common";
import Swal from "sweetalert2";

function Product_Status_Card({ data, FilterOrderList }) {
  // console.log(data);
  async function sentSuccess() {
    const tempData = {
      ol_id: data.ol_id,
    };

    axios({
      method: "post",
      url: Configs.API_URL_incentive + "/api/incentivePo/sentSuccess",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        // console.log(response.data);
        await FilterOrderList();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function CheckProductHave() {
    const tempData = {
      product_id: data.product_id,
    };

    axios({
      method: "post",
      url: Configs.API_URL_incentive + "/api/incentivePo/checkProductHave",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        if (response.data.length !== 0) {
          window.location.href = `/Human_Resource/incentive_user/product_service/product_view_details/${data.product_id}`;
        } else {
          Swal.fire({
            title: "ไม่มีสินค้าชิ้นนี้ในระบบ",
            icon: "error",
          }).then(() => {
            window.location.href =
              "/Human_Resource/incentive_user/product_service";
          });
        }
      })
      .catch(function (error) {
        console.log(error);

        Swal.fire({
          title: "ไม่มีสินค้าชิ้นนี้ในระบบ",
          icon: "error",
        }).then(() => {
          window.location.href =
            "/Human_Resource/incentive_user/product_service";
        });
      });
  }

  return (
    <>
      <div
        className="card"
        style={{
          width: "100%",
          minHeight: "278px",
          borderRadius: "15px",
          backgroundColor: "#E4F4FF",
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          cursor: "pointer",
        }}
      >
        <img
          alt={data.product_image || "..."}
          src={
            data.product_image_path
              ? Configs.API_URL_IMG_incentive + data.product_image_path
              : userdefault_img.imgs
          }
          className="slide pstcard_img"
          onClick={() => {
            CheckProductHave();
          }}
        />

        <div
          style={
            data.ol_remark
              ? {
                  margin: "15px 15px 10px 15px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }
              : {
                  margin: "15px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }
          }
        >
          <label
            className="textVerticalOverflow-1"
            style={{
              margin: "0px",
              fontSize: "48px",
              fontWeight: "700",
              lineHeight: "normal",
              color: "black",
              wordBreak: "break-all",
            }}
          >
            <a
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                CheckProductHave();
              }}
            >
              {data.ol_product_name}
            </a>
          </label>

          <label
            className="textVerticalOverflow-1"
            style={{
              margin: "0px",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "normal",
              color: "#8A8A8A",
              wordBreak: "break-all",
            }}
          >
            {data.product_detail}
          </label>

          <div style={{ display: "flex" }}>
            <label
              // className="textVerticalOverflow-1"
              className="pstcard_text"
              style={{
                margin: "0px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "normal",
                color: "#8A8A8A",
              }}
            >
              รายละเอียดสินค้า
            </label>

            <label
              // className="textVerticalOverflow-1"
              style={{
                margin: "0px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "normal",
                color: "#8A8A8A",
                marginRight: "10px",
              }}
            >
              :
            </label>

            <label
              className="textVerticalOverflow-1"
              style={{
                margin: "0px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "normal",
                color: "#8A8A8A",
                wordBreak: "break-all",
              }}
            >
              {data.ol_model_name}
            </label>
          </div>

          <div style={{ display: "flex" }}>
            <label
              // className="textVerticalOverflow-1"
              className="pstcard_text"
              style={{
                margin: "0px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "normal",
                color: "#8A8A8A",
              }}
            >
              จำนวน
            </label>

            <label
              // className="textVerticalOverflow-1"
              style={{
                margin: "0px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "normal",
                color: "#8A8A8A",
                marginRight: "10px",
              }}
            >
              :
            </label>

            <label
              className="textVerticalOverflow-1"
              style={{
                margin: "0px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "normal",
                color: "#8A8A8A",
                wordBreak: "break-all",
              }}
            >
              {data.ol_amount}
            </label>
          </div>

          <div style={{ display: "flex" }}>
            <label
              // className="textVerticalOverflow-1"
              className="pstcard_text"
              style={{
                margin: "0px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "normal",
                color: "#8A8A8A",
              }}
            >
              รวมคะแนนการสั่งซื้อ
            </label>

            <label
              // className="textVerticalOverflow-1"
              style={{
                margin: "0px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "normal",
                color: "#8A8A8A",
                marginRight: "10px",
              }}
            >
              :
            </label>

            <label
              className="textVerticalOverflow-1"
              style={{
                margin: "0px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "normal",
                color: "#0094FF",
                wordBreak: "break-all",
              }}
            >
              {data.ol_amount * data.ol_point} คะแนน
            </label>
          </div>

          <div style={{ display: "flex" }}>
            <label
              // className="textVerticalOverflow-1"
              className="pstcard_text"
              style={{
                margin: "0px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "normal",
                color: "#8A8A8A",
              }}
            >
              วันที่สั่งสินค้า
            </label>

            <label
              // className="textVerticalOverflow-1"
              style={{
                margin: "0px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "normal",
                color: "#8A8A8A",
                marginRight: "10px",
              }}
            >
              :
            </label>

            <label
              className="textVerticalOverflow-1"
              style={{
                margin: "0px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "normal",
                color: "#8A8A8A",
                wordBreak: "break-all",
              }}
            >
              {moment(data.ol_create_date)
                .add(543, "year")
                .format("DD/MM/YYYY")}
            </label>
          </div>

          <div style={{ display: "flex" }}>
            <label
              // className="textVerticalOverflow-1"
              className="pstcard_text"
              style={{
                margin: "0px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "normal",
                color: "#8A8A8A",
              }}
            >
              หมายเลขพัสดุ
            </label>

            <label
              // className="textVerticalOverflow-1"
              style={{
                margin: "0px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "normal",
                color: "#8A8A8A",
                marginRight: "10px",
              }}
            >
              :
            </label>

            <label
              className="textVerticalOverflow-1"
              style={{
                margin: "0px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "normal",
                color: "#8A8A8A",
                wordBreak: "break-all",
              }}
            >
              {data.ol_tracking_no || "-"}
            </label>
          </div>

          <div style={{ display: "flex" }}>
            <label
              // className="textVerticalOverflow-1"
              className="pstcard_text"
              style={{
                margin: "0px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "normal",
                color: "#8A8A8A",
              }}
            >
              ชื่อบริษัทขนส่ง
            </label>

            <label
              // className="textVerticalOverflow-1"
              style={{
                margin: "0px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "normal",
                color: "#8A8A8A",
                marginRight: "10px",
              }}
            >
              :
            </label>

            <label
              className="textVerticalOverflow-1"
              style={{
                margin: "0px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "normal",
                color: "#8A8A8A",
                wordBreak: "break-all",
              }}
            >
              {data.ol_tracking_name || "-"}
            </label>
          </div>

          <div style={{ display: "flex", alignItems: "baseline" }}>
            <label
              // className="textVerticalOverflow-1"
              className="pstcard_text"
              style={{
                margin: "0px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "normal",
                color: "#8A8A8A",
              }}
            >
              สถานะสินค้า
            </label>

            <label
              // className="textVerticalOverflow-1"
              style={{
                margin: "0px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "normal",
                color: "#8A8A8A",
                marginRight: "5px",
              }}
            >
              :
            </label>

            <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
              <button
                className="btn"
                style={
                  data.os_id === "76b3aa3d-96ae-4525-a6f7-3f82b1bea353"
                    ? {
                        backgroundColor: "#D9D9D9",
                        maxWidth: "100px",
                        height: "25px",
                        padding: "0px",
                        width: "100%",
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#0094FF",
                        opacity: "1",
                        margin: "2px 5px",
                      }
                    : data.os_id === "2b7d431a-6697-4e33-bc41-a678bf9f28f5"
                    ? {
                        backgroundColor: "#D9D9D9",
                        maxWidth: "100px",
                        height: "25px",
                        padding: "0px",
                        width: "100%",
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#E1A303",
                        opacity: "1",
                        margin: "2px 5px",
                      }
                    : data.os_id === "11c0ae24-51fe-4c53-839a-c4a78a05ffe2"
                    ? {
                        backgroundColor: "#D9D9D9",
                        maxWidth: "100px",
                        height: "25px",
                        padding: "0px",
                        width: "100%",
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#F10606",
                        opacity: "1",
                        margin: "2px 5px",
                      }
                    : {
                        backgroundColor: "#D9D9D9",
                        maxWidth: "100px",
                        height: "25px",
                        padding: "0px",
                        width: "100%",
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#00A45F",
                        opacity: "1",
                        margin: "2px 5px",
                      }
                }
                disabled
              >
                {data.os_name}
              </button>

              {data.os_id === "612884f0-493a-43c4-a2f9-1cd9314b8b31" ? (
                data.is_sent ? (
                  <button
                    className="btn"
                    style={{
                      backgroundColor: "#D9D9D9",
                      maxWidth: "100px",
                      height: "25px",
                      padding: "0px",
                      width: "100%",
                      fontSize: "16px",
                      fontWeight: "700",
                      color: "#003583",
                      opacity: "1",
                      margin: "2px 5px",
                    }}
                    disabled
                  >
                    สำเร็จ
                  </button>
                ) : (
                  <>
                    <div style={{ display: "grid" }}>
                      <button
                        className="btn"
                        style={{
                          backgroundColor: "#0094FF",
                          maxWidth: "100px",
                          height: "25px",
                          padding: "0px",
                          width: "100%",
                          fontSize: "16px",
                          fontWeight: "700",
                          color: "#ffffff",
                          margin: "2px 5px",
                        }}
                        onClick={async () => {
                          await sentSuccess();
                        }}
                      >
                        สำเร็จ
                      </button>
                      <label
                        style={{
                          margin: "0px",
                          fontSize: "7px",
                          fontWeight: "400",
                          lineHeight: "normal",
                          color: "#8A8A8A",
                          width: "100px",
                          margin: "0px 6px",
                        }}
                      >
                        *กรุณากดยืนยันหลังจากได้รับสินค้าเรียบร้อยแล้ว
                      </label>
                    </div>
                  </>
                )
              ) : (
                <></>
              )}
            </div>
          </div>

          {data.ol_remark ? (
            <div style={{ display: "flex" }}>
              <label
                // className="textVerticalOverflow-1"
                style={{
                  margin: "0px",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "normal",
                  color: "black",
                }}
              >
                หมายเหตุ
              </label>

              <label
                // className="textVerticalOverflow-1"
                style={{
                  margin: "0px",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "normal",
                  color: "black",
                  margin: "0px 10px",
                }}
              >
                :
              </label>

              <label
                // className="textVerticalOverflow-1"
                style={{
                  margin: "0px",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  color: "black",
                  wordBreak: "break-all",
                }}
              >
                {data.ol_remark}
              </label>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default Product_Status_Card;
