import { MDBDataTable } from "mdbreact";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import Configs from "../../../../config";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
// import Sales_Order from "./Component/Sales_Order";
import DatePicker from "react-datepicker";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../../Utils/Common";
import Invoice_Products_Component from "./Component/Invoice_Products_Component";

function Invoice_EV({ mode }) {
  const { id } = useParams();
  const [disable, setdisable] = useState(null);
  const [pageMode, setpageMode] = useState("");
  const [Invoice, setInvoice] = useState({});
  const [inputData, setInputData] = useState({});

  const [AddProduct, setAddProduct] = useState({
    data: [],
  });
  const [Product, setProduct] = useState({
    data: [],
  });
  const [test, settest] = useState({
    test: "",
  });
  const [inputLang, setInputLang] = useState({
    data: "ENG",
    check: false,
  });

  const [inputDataComponent, setInputDataComponent] = useState({
    data: [],
  });

  const [errorListComponent, setErrorListComponent] = useState({
    data: [],
  });

  const [functionSaveComponent, setFunctionSaveComponent] = useState({
    data: null,
  });

  const [modalInputCustomer, setModalInputCustomer] = useState({
    customerOld: "",
    contactOld: "",
    shipOld: "",
    customer: "",
    contact: "",
    ship: "",
  });

  const [customerDropDown, setCustomerDropDown] = useState({
    data: [],
  });

  const [contactDropDown, setContactDropDown] = useState({
    data: [],
    dropdownOld: [],
    dropdown: [],
  });

  const [shipDropDown, setShipDropDown] = useState({
    data: [],
    dropdownOld: [],
    dropdown: [],
  });

  const [shippingByDropDown, setShippingByDropDown] = useState({
    data: [],
  });

  const [customerTypeData, setCustomerTypeData] = useState({
    data: [],
  });

  const [modalCustomer, setModalCustomer] = useState(false);

  useEffect(async () => {
    setpageMode(mode === "edit" ? "Edit" : "View");
    setdisable(mode === "edit" ? false : true);
    await GetCustomerType();
    await GetCusConShipDropDown();
    await GetShippingByDropDown();

    if (id) {
      await GetInvoiceById();
    }
  }, []);

  const clearDataOnChange_CtId = async () => {
    setInvoice({
      ...Invoice,
      so_cus_name_id: "",
      so_cus_name: "",
      so_cus_house_number: "",
      so_cus_building: "",
      so_swine: "",
      so_soi: "",
      so_road: "",
      so_sub_district: "",
      so_district: "",
      so_province: "",
      so_postcode: "",
      so_contact_name: "",
      so_cus_name_id: "",
      so_contact_email: "",
      so_contact_tel: "",
      so_ship_contract_name: "",
      so_csh_id: "",
      so_csh_name: "",
      so_ship_tel: "",
      so_ship_house_number: "",
      so_ship_building: "",
      so_ship_swine: "",
      so_ship_soi: "",
      so_ship_road: "",
      so_ship_sub_district: "",
      so_ship_district: "",
      so_ship_province: "",
      so_ship_postcode: "",
    });
    Invoice.so_cus_name_id = "";
    Invoice.so_cus_name = "";
    Invoice.so_cus_house_number = "";
    Invoice.so_cus_building = "";
    Invoice.so_swine = "";
    Invoice.so_soi = "";
    Invoice.so_road = "";
    Invoice.so_sub_district = "";
    Invoice.so_district = "";
    Invoice.so_province = "";
    Invoice.so_postcode = "";
    Invoice.so_contact_name = "";
    Invoice.so_cus_name_id = "";
    Invoice.so_contact_email = "";
    Invoice.so_contact_tel = "";
    Invoice.so_ship_contract_name = "";
    Invoice.so_csh_id = "";
    Invoice.so_csh_name = "";
    Invoice.so_ship_tel = "";
    Invoice.so_ship_house_number = "";
    Invoice.so_ship_building = "";
    Invoice.so_ship_swine = "";
    Invoice.so_ship_soi = "";
    Invoice.so_ship_road = "";
    Invoice.so_ship_sub_district = "";
    Invoice.so_ship_district = "";
    Invoice.so_ship_province = "";
    Invoice.so_ship_postcode = "";
  }

  async function GetCustomerType() {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com
    }
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCustomerType",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp
    })
      .then(async (response) => {
        setCustomerTypeData({
          ...customerTypeData,
          data: response.data,
        });
        customerTypeData.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const GetInvoiceById = async () => {
    let temp = {
      user_id: getUser().fup,
      oem_id: getOem(),
      company_id: getUser().com,
      inv_id: id,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/invoice/GetInvoiceById",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        // console.log("response", response.data[0].inv_csh_id);
        // console.log('shipDropDown.data?.find(el => el.csh_cus_id === res.inv_csh_id)', shipDropDown.data?.find(el => el.csh_cus_id === ));
        if (response.data && response.data.length > 0) {
          const res = response.data[0];
          setInvoice({
            ...Invoice,
            inv_id: res.inv_id,
            inv_no: res.inv_no,
            so_id: res.inv_so_id,
            so_no: res.inv_so_no,
            so_delivery_date: res.inv_date ? new Date(res.inv_date) : "",
            so_cus_house_number: res.inv_cus_house_number || "",
            so_cus_building: res.inv_cus_building || "",
            so_swine: res.inv_swine || "",
            so_soi: res.inv_soi || "",
            so_road: res.inv_road || "",
            so_sub_district: res.inv_sub_district || "",
            so_district: res.inv_district || "",
            so_province: res.inv_province || "",
            so_postcode: res.inv_postcode || "",
            ///////
            so_cus_name: res.inv_cus_name || "",
            so_cus_name_id: res.inv_cus_id,
            so_contact_name: res.inv_contact_name || "",
            so_contact_email: res.inv_contact_email || "",
            so_contact_tel: res.inv_contact_tel || "",
            ///////
            so_ship_contract_name: res.inv_ship_contract_name || "",
            so_csh_id: res.inv_csh_id,
            so_csh_name: res.inv_ship_name,
            so_ship_tel: res.inv_ship_tel || "",
            ///////
            so_ship_house_number: res.inv_ship_house_number || "",
            so_ship_building: res.inv_ship_building || "",
            so_ship_swine: res.inv_ship_swine || "",
            so_ship_soi: res.inv_ship_soi || "",
            so_ship_road: res.inv_ship_road || "",
            so_ship_sub_district: res.inv_ship_sub_district || "",
            so_ship_district: res.inv_ship_district || "",
            so_ship_province: res.inv_ship_province || "",
            so_ship_postcode: res.inv_ship_postcode || "",
            inv_cus_remark: res.inv_cus_remark || "",
            inv_lang: res.inv_lang === "TH" ? true : false,
            inv_incl_vat: res.inv_incl_vat,
            inv_final_discount:
              res.inv_final_discount_percent && parseFloat(res.inv_final_discount_percent) > 100
                ? 100
                : res.inv_final_discount_percent || "",
            inv_final_discount_bath:
              res.inv_final_discount && parseFloat(res.inv_final_discount) > 100
                ? 100
                : res.inv_final_discount || "",
            inv_shipping_cost: res.inv_shipping_cost || "",
            inv_total_befor_vat: res.inv_total_befor_vat || "",
            inv_vat: res.inv_vat || "",
            inv_total_price: res.inv_total_price || "",
            inv_remark: res.inv_remark || "",
            so_ds_id: res.inv_ds_id || null,
            inv_deposit: res.inv_deposit || "",
            so_ct_id: res.inv_ct_id || "fd5acfc9-6d49-45fc-b7bf-aba6e2536b93"
          });
          setModalInputCustomer({
            ...modalInputCustomer,
            contact: res.inv_ccp_id,
            ship: res.inv_csh_id,
          });
          if (res.inv_cus_name) {
            setOn_change_cusName({ data: res.inv_cus_name });
            on_change_cusName.data = res.inv_cus_name;
          }
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  async function GetCusConShipDropDown() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCusConShipDropDown",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setCustomerDropDown({
          ...customerDropDown,
          data: response.data.customer,
        });
        customerDropDown.data = response.data.customer;

        setContactDropDown({
          ...contactDropDown,
          data: response.data.contractPoint,
        });
        contactDropDown.data = response.data.contractPoint;

        setShipDropDown({
          ...shipDropDown,
          data: response.data.ship,
        });
        shipDropDown.data = response.data.ship;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetShippingByDropDown() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/logistic/getShippingByDropDown",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setShippingByDropDown({
          ...shippingByDropDown,
          data: response.data,
        });
        shippingByDropDown.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const [on_change_cusName, setOn_change_cusName] = useState({
    data: ""
  });

  async function GetCusConShipData() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
      customer: modalInputCustomer.customer,
      contact: modalInputCustomer.contact,
      ship: modalInputCustomer.ship,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCusConShipData",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        const _has_data_res = response.data.customer.length > 0 ? response.data.customer : null;
        let checkCusCommon = false;
        if (Invoice.so_ct_id === "7095dc2b-efeb-433e-af3f-449e9e90bdf2") {
          checkCusCommon = true;
        }

        if (!_has_data_res) {
          return;
        }
        let cus_find = "";
        if (_has_data_res[0].cus_id) {
          cus_find = customerDropDown.data?.find(
            (el) => el.cus_id === _has_data_res[0].cus_id
          );
        }
        setInvoice({
          ...Invoice,
          so_cus_house_number:
            _has_data_res
              ? _has_data_res[0][checkCusCommon ? "cus_house_number" : "cus_branch_house_number"] || ""
              : "",
          so_cus_building:
            _has_data_res
              ? _has_data_res[0][checkCusCommon ? "cus_building" : "cus_branch_building"] || ""
              : "",
          so_swine:
            _has_data_res
              ? _has_data_res[0][checkCusCommon ? "cus_swine" : "cus_branch_swine"] || ""
              : "",
          so_soi:
            _has_data_res
              ? _has_data_res[0][checkCusCommon ? "cus_soi" : "cus_branch_soi"] || ""
              : "",
          so_road:
            _has_data_res
              ? _has_data_res[0][checkCusCommon ? "cus_road" : "cus_branch_road"] || ""
              : "",
          so_sub_district:
            _has_data_res
              ? _has_data_res[0][checkCusCommon ? "cus_sub_district" : "cus_branch_sub_district"] || ""
              : "",
          so_district:
            _has_data_res
              ? _has_data_res[0][checkCusCommon ? "cus_district" : "cus_branch_district"] || ""
              : "",
          so_province:
            _has_data_res
              ? _has_data_res[0][checkCusCommon ? "cus_province" : "cus_branch_province"] || ""
              : "",
          so_postcode:
            _has_data_res
              ? _has_data_res[0][checkCusCommon ? "cus_postcode" : "cus_branch_postcode"] || ""
              : "",
          ///////
          so_contact_name:
            _has_data_res
              ? _has_data_res[0].cus_name || ""
              : "",
          so_cus_name_id:
            _has_data_res
              ? _has_data_res[0].cus_id || null
              : null,
          so_cus_name: 
            cus_find
              ? cus_find.cus_name || ""
              : "",
          so_contact_email:
            _has_data_res
              ? _has_data_res[0].cus_email || ""
              : "",
          so_contact_tel:
            _has_data_res
              ? _has_data_res[0].cus_tel || ""
              : "",
          ///////
          so_ship_contract_name:
            response.data.ship.length > 0
              ? response.data.ship[0].csh_conteact_name || ""
              : "",
          so_csh_id:
            response.data.ship.length > 0
              ? response.data.ship[0].csh_id || null
              : null,
          so_csh_name:
            response.data.ship.length > 0
              ? response.data.ship[0].csh_name || ""
              : "",
          so_ship_tel:
            response.data.ship.length > 0
              ? response.data.ship[0].csh_tel || ""
              : "",
          ///////
          so_ship_house_number:
            response.data.ship.length > 0
              ? response.data.ship[0].csh_house_number || ""
              : "",
          so_ship_building:
            response.data.ship.length > 0
              ? response.data.ship[0].csh_building || ""
              : "",
          so_ship_swine:
            response.data.ship.length > 0
              ? response.data.ship[0].csh_swine || ""
              : "",
          so_ship_soi:
            response.data.ship.length > 0
              ? response.data.ship[0].csh_soi || ""
              : "",
          so_ship_road:
            response.data.ship.length > 0
              ? response.data.ship[0].csh_road || ""
              : "",
          so_ship_sub_district:
            response.data.ship.length > 0
              ? response.data.ship[0].csh_sub_district || ""
              : "",
          so_ship_district:
            response.data.ship.length > 0
              ? response.data.ship[0].csh_district || ""
              : "",
          so_ship_province:
            response.data.ship.length > 0
              ? response.data.ship[0].csh_province || ""
              : "",
          so_ship_postcode:
            response.data.ship.length > 0
              ? response.data.ship[0].csh_postcode || ""
              : "",
          po_confirm_cus: true,
        });

        setInputLang({
          data: "ENG",
          check: false,
        });

        if (cus_find.cus_name) {
          setOn_change_cusName({ data: cus_find.cus_name });
          on_change_cusName.data = cus_find.cus_name;
        }
      })
      .catch(function (error) {
        console.log('error', error);
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const clearCustomer = async () => {
    await functionSaveComponent.getProduct();
    await clearDataOnChange_CtId();
    await functionSaveComponent.calSummaryProductTable()
    setInvoice({
      ...Invoice,
      inv_total_price: 0,
      so_change_customer: false,
      inv_vat: 0
    });
    Invoice.so_change_customer = false;
    Invoice.inv_total_price = 0;
    Invoice.inv_vat = 0;
    /* setInvoice({
      ...Invoice,
      so_cus_house_number: "",
      so_cus_building: "",
      so_swine: "",
      so_soi: "",
      so_road: "",
      so_sub_district: "",
      so_district: "",
      so_province: "",
      so_postcode: "",
      ///////
      so_contact_name: "",
      so_cus_name_id: "",
      so_cus_name: "", 
      so_contact_email: "",
      so_contact_tel: "",
      ///////
      so_ship_contract_name: "",
      so_csh_id: "",
      so_csh_name: "",
      so_ship_tel: "",
      ///////
      so_ship_house_number: "",
      so_ship_building: "",
      so_ship_swine: "",
      so_ship_soi: "",
      so_ship_road: "",
      so_ship_sub_district: "",
      so_ship_district: "",
      so_ship_province: "",
      so_ship_postcode: "",
      po_confirm_cus: true,
    }); */
  }

  const checkErrorForSave = async () => {
    let error_list = [];

    if (Invoice.so_cus_name) {
      Invoice.so_cus_name = Invoice.so_cus_name?.trim();
    }

    if (Invoice.so_cus_house_number) {
      Invoice.so_cus_house_number = Invoice.so_cus_house_number?.trim();
    }

    if (Invoice.so_cus_building) {
      Invoice.so_cus_building = Invoice.so_cus_building?.trim();
    }

    if (Invoice.so_swine) {
      Invoice.so_swine = Invoice.so_swine?.trim();
    }

    if (Invoice.so_soi) {
      Invoice.so_soi = Invoice.so_soi?.trim();
    }

    if (Invoice.so_road) {
      Invoice.so_road = Invoice.so_road?.trim();
    }

    if (Invoice.so_sub_district) {
      Invoice.so_sub_district = Invoice.so_sub_district?.trim();
    }

    if (Invoice.so_district) {
      Invoice.so_district = Invoice.so_district?.trim();
    }

    if (Invoice.so_province) {
      Invoice.so_province = Invoice.so_province?.trim();
    }

    if (Invoice.so_postcode) {
      Invoice.so_postcode = Invoice.so_postcode?.trim();
    }

    if (Invoice.so_contact_name) {
      Invoice.so_contact_name = Invoice.so_contact_name?.trim();
    }

    if (Invoice.so_contact_email) {
      Invoice.so_contact_email = Invoice.so_contact_email?.trim();
    }

    if (Invoice.so_contact_tel) {
      Invoice.so_contact_tel = Invoice.so_contact_tel?.trim();
    }

    if (Invoice.so_ship_contract_name) {
      Invoice.so_ship_contract_name = Invoice.so_ship_contract_name?.trim();
    }

    if (Invoice.so_csh_name) {
      Invoice.so_csh_name = Invoice.so_csh_name?.trim();
    }

    if (Invoice.so_ship_tel) {
      Invoice.so_ship_tel = Invoice.so_ship_tel?.trim();
    }

    if (Invoice.so_ship_house_number) {
      Invoice.so_ship_house_number = Invoice.so_ship_house_number?.trim();
    }

    if (Invoice.so_ship_building) {
      Invoice.so_ship_building = Invoice.so_ship_building?.trim();
    }

    if (Invoice.so_ship_swine) {
      Invoice.so_ship_swine = Invoice.so_ship_swine?.trim();
    }

    if (Invoice.so_ship_soi) {
      Invoice.so_ship_soi = Invoice.so_ship_soi?.trim();
    }

    if (Invoice.so_ship_road) {
      Invoice.so_ship_road = Invoice.so_ship_road?.trim();
    }

    if (Invoice.so_ship_sub_district) {
      Invoice.so_ship_sub_district = Invoice.so_ship_sub_district?.trim();
    }

    if (Invoice.so_ship_district) {
      Invoice.so_ship_district = Invoice.so_ship_district?.trim();
    }

    if (Invoice.so_ship_province) {
      Invoice.so_ship_province = Invoice.so_ship_province?.trim();
    }

    if (Invoice.so_ship_postcode) {
      Invoice.so_ship_postcode = Invoice.so_ship_postcode?.trim();
    }

    if (Invoice.inv_cus_remark) {
      Invoice.inv_cus_remark = Invoice.inv_cus_remark?.trim();
    }

    if (Invoice.inv_final_discount) {
      Invoice.inv_final_discount =
        typeof Invoice.inv_final_discount === "string"
          ? parseFloat(Invoice.inv_final_discount?.replace(/,/g, ""))
          : Invoice.inv_final_discount;
    }

    if (Invoice.inv_shipping_cost) {
      Invoice.inv_shipping_cost =
        typeof Invoice.inv_shipping_cost === "string"
          ? parseFloat(Invoice.inv_shipping_cost?.replace(/,/g, ""))
          : Invoice.inv_shipping_cost;
    }

    if (Invoice.inv_total_befor_vat) {
      Invoice.inv_total_befor_vat =
        typeof Invoice.inv_total_befor_vat === "string"
          ? parseFloat(Invoice.inv_total_befor_vat?.replace(/,/g, ""))
          : Invoice.inv_total_befor_vat;
    }

    if (Invoice.inv_vat) {
      Invoice.inv_vat =
        typeof Invoice.inv_vat === "string"
          ? parseFloat(Invoice.inv_vat?.replace(/,/g, ""))
          : Invoice.inv_vat;
    }

    if (Invoice.inv_total_price) {
      Invoice.inv_total_price =
        typeof Invoice.inv_total_price === "string"
          ? parseFloat(Invoice.inv_total_price?.replace(/,/g, ""))
          : Invoice.inv_total_price;
    }

    if (Invoice.inv_remark) {
      Invoice.inv_remark = Invoice.inv_remark?.trim();
    }

    if (modalInputCustomer.contact) {
      Invoice.inv_ccp_id = modalInputCustomer.contact;
    }

    if (modalInputCustomer.ship) {
      Invoice.inv_csh_id = modalInputCustomer.ship;
    }

    if (!Invoice.so_delivery_date) {
      let temp_err = {
        message: `กรุณากรอก Invoice date`,
      };
      error_list.push(temp_err);
    }

    if (!Invoice.so_cus_name) {
      let temp_err = {
        message: `กรุณากรอก Customer Name`,
      };
      error_list.push(temp_err);
    }

    if (!Invoice.so_cus_house_number) {
      let temp_err = {
        message: `กรุณากรอกบ้านเลขที่ ใน Customer Detail`,
      };
      error_list.push(temp_err);
    }

    if (!Invoice.so_cus_building) {
      let temp_err = {
        message: `กรุณากรอกอาคาร ใน Customer Detail`,
      };
      error_list.push(temp_err);
    }

    if (!Invoice.so_swine) {
      let temp_err = {
        message: `กรุณากรอกหมู่ ใน Customer Detail`,
      };
      error_list.push(temp_err);
    }

    if (!Invoice.so_soi) {
      let temp_err = {
        message: `กรุณากรอกซอย ใน Customer Detail`,
      };
      error_list.push(temp_err);
    }

    if (!Invoice.so_road) {
      let temp_err = {
        message: `กรุณากรอกถนน ใน Customer Detail`,
      };
      error_list.push(temp_err);
    }

    if (!Invoice.so_sub_district) {
      let temp_err = {
        message: `กรุณากรอกแขวง / ตำบล ใน Customer Detail`,
      };
      error_list.push(temp_err);
    }

    if (!Invoice.so_district) {
      let temp_err = {
        message: `กรุณากรอกเขต / อำเภอ ใน Customer Detail`,
      };
      error_list.push(temp_err);
    }

    if (!Invoice.so_province) {
      let temp_err = {
        message: `กรุณากรอกจังหวัด ใน Customer Detail`,
      };
      error_list.push(temp_err);
    }

    // if (!Invoice.so_postcode) {
    //   let temp_err = {
    //     message: `กรุณากรอกรหัสไปรษณีย์ ใน Customer Detail`,
    //   };
    //   error_list.push(temp_err);
    // }
    const postcode = Invoice.so_postcode?.toString();
    if (
      Invoice.so_postcode === undefined ||
      Invoice.so_postcode === null ||
      postcode.length === 0
    ) {
      let temp_err = {
        message: `กรุณากรอกรหัสไปรษณีย์ ใน Customer Detail`,
      };
      error_list.push(temp_err);
    } else {
      if (postcode.length >= 1 && postcode.length <= 4) {
        let temp_err = {
          message: `รหัสไปรษณีย์ต้องมี 5 หลัก ใน Customer Detail`,
        };
        error_list.push(temp_err);
      }
    }

    if (!Invoice.so_contact_name) {
      let temp_err = {
        message: `กรุณากรอก Customer Contact Name`,
      };
      error_list.push(temp_err);
    }

    if (!Invoice.so_contact_email) {
      let temp_err = {
        message: `กรุณากรอก Contact Email`,
      };
      error_list.push(temp_err);
    }

    if (!Invoice.so_contact_tel) {
      let temp_err = {
        message: `กรุณากรอก Contact Tel.`,
      };
      error_list.push(temp_err);
    } /* else if (Invoice.so_contact_tel?.replace(/-/g, "").trim().length != 10) {
      let temp_err = {
        message: `เบอร์โทรศัพท์ Contact Tel. ต้องมี 10 หลัก`,
      };
      error_list.push(temp_err);
    } */

    if (!Invoice.so_ship_contract_name) {
      let temp_err = {
        message: `กรุณากรอกผู้ติดต่อ (Ship to)`,
      };
      error_list.push(temp_err);
    }

    if (!Invoice.so_csh_name) {
      let temp_err = {
        message: `กรุณากรอกชื่อที่อยู่ (Ship to)`,
      };
      error_list.push(temp_err);
    }

    if (!Invoice.so_ship_tel) {
      let temp_err = {
        message: `กรุณากรอกเบอรโทรศัพท์ (Ship to)`,
      };
      error_list.push(temp_err);
    } /* else if (Invoice.so_ship_tel?.replace(/-/g, "").trim().length != 10) {
      let temp_err = {
        message: `เบอร์โทรศัพท์ (Ship to) ต้องมี 10 หลัก`,
      };
      error_list.push(temp_err);
    } */

    if (!Invoice.so_ship_house_number) {
      let temp_err = {
        message: `กรุณากรอกบ้านเลขที่ (Ship to)`,
      };
      error_list.push(temp_err);
    }

    if (!Invoice.so_ship_building) {
      let temp_err = {
        message: `กรุณากรอกอาคาร (Ship to)`,
      };
      error_list.push(temp_err);
    }

    if (!Invoice.so_ship_swine) {
      let temp_err = {
        message: `กรุณากรอกหมู่ (Ship to)`,
      };
      error_list.push(temp_err);
    }

    if (!Invoice.so_ship_soi) {
      let temp_err = {
        message: `กรุณากรอกซอย (Ship to)`,
      };
      error_list.push(temp_err);
    }

    if (!Invoice.so_ship_road) {
      let temp_err = {
        message: `กรุณากรอกถนน (Ship to)`,
      };
      error_list.push(temp_err);
    }

    if (!Invoice.so_ship_sub_district) {
      let temp_err = {
        message: `กรุณากรอกแขวง / ตำบล (Ship to)`,
      };
      error_list.push(temp_err);
    }

    if (!Invoice.so_ship_district) {
      let temp_err = {
        message: `กรุณากรอกเขต / อำเภอ (Ship to)`,
      };
      error_list.push(temp_err);
    }

    if (!Invoice.so_ship_province) {
      let temp_err = {
        message: `กรุณากรอกจังหวัด (Ship to)`,
      };
      error_list.push(temp_err);
    }

    // if (!Invoice.so_ship_postcode) {
    //   let temp_err = {
    //     message: `กรุณากรอกรหัสไปรษณีย์ (Ship to)`,
    //   };
    //   error_list.push(temp_err);
    // }
    const postcode2 = Invoice.so_ship_postcode?.toString();
    if (
      Invoice.so_ship_postcode === undefined ||
      Invoice.so_ship_postcode === null ||
      postcode2.length === 0
    ) {
      let temp_err = {
        message: `กรุณากรอกรหัสไปรษณีย์ (Ship to)`,
      };
      error_list.push(temp_err);
    } else {
      if (postcode2.length >= 1 && postcode2.length <= 4) {
        let temp_err = {
          message: `รหัสไปรษณีย์ต้องมี 5 หลัก (Ship to)`,
        };
        error_list.push(temp_err);
      }
    }

    if (!Invoice.so_ds_id) {
      let temp_err = {
        message: `กรุณาเลือก Shipping by`,
      };
      error_list.push(temp_err);
    }

    if (on_change_cusName.data) {
      if (on_change_cusName.data?.trim() !== Invoice.so_cus_name?.trim()) {
        Invoice.so_cus_name_id = "";
      } else {
        const check = customerDropDown.data?.find(
          (el) => el.cus_name === Invoice.so_cus_name?.trim()
        )
        if (check) {
          Invoice.so_cus_name_id = check.cus_id;
        }
      }
    } else {
      Invoice.so_cus_name_id = "";
    }

    return error_list;
  };

  async function saveOption() {
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let error_list = [];

        error_list = await checkErrorForSave();

        await functionSaveComponent.data();
        error_list.push(...errorListComponent.data);
        // console.log('inputDataTab2', inputDataTab2);

        if (error_list.length > 0) {
          var err_message = "";

          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }

          Swal.fire("Error", err_message, "error");
        } else {
          Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
              Swal.showLoading();
            },
          }).then(async () => {
            const temp = {
              company_id: getUser().com,
              oem_id: getOem(),
              user_id: getUser().fup,
              user_name: getUser().name + " " + getUser().surname,
              product_list: inputDataComponent,
              data: Invoice,
            };
            await axios({
              method: "post",
              url:
                Configs.API_URL_cuteplus_sales + `/api/invoice/updateInvoice`,
              headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp,
            })
              .then(async function (response) {
                // console.log('response saveDrafQuotation -', response.data);
                if (response.data) {
                }
              })
              .then(async function (response) {
                Swal.fire({
                  icon: "success",
                  title: "บันทึกข้อมูลสำเร็จ",
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: false,
                  timer: 2000,
                }).then(() => {
                  window.location.href =
                    "/Warehouse_Management/Invoice_Cuteplus";
                });
              })
              .catch(function (error) {
                Swal.fire({
                  title: "Error",
                  text: error.response.data.error.message,
                  icon: "error",
                });
              });
          });
        }
      }
    });
  }

  function cancleOption() {
    if (mode === "add" || mode === "edit") {
      Swal.fire({
        title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `ใช่`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `ไม่`,
      }).then((result) => {
        if (result.isConfirmed) {
          // if (from == "channel") {
          window.location.href = "/Warehouse_Management/Invoice_Cuteplus";
          // } else {
          //   window.location.href =
          //     "/Warehouse_Management/Factory_Master_Data_Cuteplus/Modern_Trade_Department_Store";
          // }
        }
      });
    } else {
      // if (from == "channel") {
      window.location.href = "/Warehouse_Management/Invoice_Cuteplus";
      // } else {
      //   window.location.href =
      //     "/Warehouse_Management/Factory_Master_Data_Cuteplus/Modern_Trade_Department_Store";
      // }
    }
  }

  const cancelQuotation = async () => {
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะยกเลิกรายการ Invoice นี้ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const temp = {
          company_id: getUser().com,
          oem_id: getOem(),
          user_id: getUser().fup,
          user_name: getUser().name + " " + getUser().surname,
          inv_id: id,
        };
        await axios({
          method: "post",
          url:
            Configs.API_URL_cuteplus_sales + `/api/invoice/cancelInvoice`,
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(async function (response) {

            // if (response.data.length > 0) {
            //   await updateLogProductInvoice(response.data[0]);
            // }

            Swal.fire({
              icon: "success",
              title: "ยกเลิกรายการ Invoice สำเร็จ",
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              window.location.href = "/Warehouse_Management/Invoice_Cuteplus";
            });
          })
          .catch(function (error) {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      }
    });
  }

  // const updateLogProductInvoice = async (invoice) => {
  //   invoice.oem_id = getOem();
  //   invoice.user_id = getUser().fup;
  //   invoice.company_id = getUser().com;
  //   invoice.user_name = getUser().name + " " + getUser().surname;

  //   await axios({
  //     method: "post",
  //     url:
  //       Configs.API_URL_cuteplus + `/api/log_product/updateCancelLogOfInvoice`,
  //     headers: {
  //       Authorization: "Bearer " + getToken(),
  //       "X-TTT": Configs.API_TTT,
  //       "Content-Type": "application/json",
  //     },
  //     data: invoice,
  //   })
  //     .then(async function (response) {
  //     })
  //     .catch(function (error) {
  //       Swal.fire({
  //         title: "Error",
  //         text: error.response.data.error.message,
  //         icon: "error",
  //       });
  //     });
  // }

  const CustomInput = ({ value, onClick, isDis }) => (
    <div className="input-group">
      <input
        disabled={isDis}
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-12 col-md-4 col-xl-6">
                <h1 style={{ whiteSpace: "nowrap" }}>Invoice {pageMode}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">Invoice</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              {mode === "edit" || mode === "add" ? (
                <>
                  <div className="col-6 col-md-4 col-xl-2">
                    <button
                      type="button"
                      className="btn btn-block btn-success"
                      onClick={async () => {
                        // await getSonoRef();
                        // console.log(Invoice);
                        // deleteImagesAndFilesOfProduct()

                        await saveOption();
                      }}
                    >
                      บันทึก
                    </button>
                  </div>
                  <div className="col-6 col-md-4 col-xl-2">
                    <button
                      type="button"
                      className="btn btn-block btn-danger"
                      onClick={async () => {
                        cancleOption();
                      }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-12 col-md-4 col-xl-2">
                    <button
                      type="button"
                      className="btn btn-block btn-danger"
                      onClick={async () => {
                        cancleOption();
                      }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div className="card">
                  {/* <div> */}
                  <div className="card-header">
                    <div
                      className="row"
                      style={{ justifyContent: "start", alignItems: "start" }}
                    >
                      <div
                        className="col-3"
                        style={{
                          display: "flex",
                          gap: "1rem",
                          alignItems: "center",
                        }}
                      >
                        <h3
                          className="card-title"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Invoice
                        </h3>
                        <button
                          hidden={mode !== "edit" ? true : false}
                          onClick={async () => {
                            await cancelQuotation();
                          }}
                          style={{ whiteSpace: "nowrap", minWidth: "100px", maxWidth: "150px" }}
                          type="button"
                          className="btn btn-block btn-danger"
                        >
                          Cancel Invoice
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <div className="card-header">
                    <h3 className="card-title">Sales Order (Sales)</h3>
                  </div> */}
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={true}
                            value={
                              mode === "add" ? "Auto Generate" : Invoice.inv_no
                            }
                          // onChange={(e) => {
                          //   console.log(Invoice)
                          //   // setInvoice({
                          //   //   ...Invoice,
                          //   //   so_no: e.target.value,
                          //   // });
                          // }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Invoice No.
                            {/* {Invoice.so_no === "" ||
                              Invoice.so_no === null ? (
                                <span style={{ color: "red" }}> *</span>
                              ) : (
                                ""
                              )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={true}
                            value={
                              mode === "add" ? "Auto Generate" : Invoice.so_no
                            }
                          // onChange={(e) => {
                          //   console.log(Invoice)
                          //   // setInvoice({
                          //   //   ...Invoice,
                          //   //   so_no: e.target.value,
                          //   // });
                          // }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Sale Order No. *
                            {/* {Invoice.so_no === "" ||
                              Invoice.so_no === null ? (
                                <span style={{ color: "red" }}> *</span>
                              ) : (
                                ""
                              )} */}
                          </label>
                        </div>
                      </div>

                      {/* </div> */}
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6 col-xl-2">
                        <div class="form-group">
                          <select
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_ct_id}
                            onChange={async (e) => {
                              const value = e.target.value;
                              const ct_name = customerTypeData.data?.find(el => el.ct_id === value).ct_name
                              setInvoice({
                                ...Invoice,
                                so_ct_id: value,
                                so_ct_name: ct_name,
                                so_change_customer: true
                              });
                              Invoice.so_ct_id = value;
                              Invoice.so_ct_name = ct_name;
                              Invoice.so_change_customer = true;
                              await functionSaveComponent.getProduct();
                              await clearDataOnChange_CtId();
                              await functionSaveComponent.calSummaryProductTable()
                              setInvoice({
                                ...Invoice,
                                inv_total_price: 0,
                                so_change_customer: false,
                                inv_vat: 0
                              });
                              Invoice.so_change_customer = false;
                              Invoice.inv_total_price = 0;
                              Invoice.inv_vat = 0;
                              /* setSalesOrder({
                                ...SalesOrder,
                                so_ct_id: value,
                                so_ct_name: ct_name,
                                so_change_customer: true
                              });
                              SalesOrder.so_ct_id = value;
                              SalesOrder.so_ct_name = ct_name;
                              SalesOrder.so_change_customer = true;
                              clearSonoRef();
                              await functionSaveTab2.getProduct();

                              setSalesOrder({
                                ...SalesOrder,
                                so_change_customer: false
                              });
                              SalesOrder.so_change_customer = false; */
                            }}
                          >
                            <option value="" disabled>
                              -- กรุณาเลือก --
                            </option>

                            {customerTypeData.data.map((data, index) => {
                              return (
                                <option key={data.ct_id} value={data.ct_id}>
                                  {data.ct_name}
                                </option>
                              );
                            })}
                          </select>
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            ประเภทลูกค้า
                            {Invoice.so_ct_id === "" ||
                              Invoice.so_ct_id === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-3">
                        <div
                          class="form-group"
                          style={{ display: "flex ", alignItems: "baseline" }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_cus_name}
                            onChange={(e) => {
                              setInputData({
                                ...inputData,
                                cus_name: e.target.id,
                              });
                              setInvoice({
                                ...Invoice,
                                so_cus_name: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Customer Name
                            {Invoice.so_cus_name?.trim() === "" ||
                            Invoice.so_cus_name === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>

                          <a
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            onClick={() => {
                              if (!disable) {
                                setInvoice({
                                  ...Invoice,
                                  so_cus_name_id_supply: ""
                                })
                                Invoice.so_cus_name_id_supply = "";
                                setModalInputCustomer({
                                  ...modalInputCustomer,
                                  contact: "",
                                  ship: ""
                                });
                                modalInputCustomer.contact = "";
                                modalInputCustomer.ship = "";
                                setContactDropDown({
                                  ...contactDropDown,
                                  dropdown: [],
                                });
                                setShipDropDown({
                                  ...shipDropDown,
                                  dropdown: [],
                                });
                                setModalCustomer(true);
                              }
                            }}
                          >
                            <i class="fas fa-search"></i>
                          </a>
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group">
                          <DatePicker
                            required
                            disabled={disable}
                            selected={Invoice.so_delivery_date}
                            locale="th"
                            wrapperClassName="w-100"
                            dateFormat={"dd-MM-yyyy"}
                            onChange={async (date) => {
                              setInvoice({
                                ...Invoice,
                                so_delivery_date: date,
                              });
                            }}
                            selectsStart
                            showYearDropdown
                            showMonthDropdown
                            // startDate={new Date().setDate(new Date().getDate() + 30)}
                            minDate={new Date()}
                            customInput={<CustomInput isDis={disable} />}
                          />
                          <label>Invoice Date</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-header">
                    <h3 className="card-title">Customer Detail</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-md-12 col-xl-12">
                        <label>
                          ที่อยู่
                          {Invoice.so_cus_house_number === "" ||
                            Invoice.so_cus_house_number === null ||
                            Invoice.so_cus_house_number === undefined ||
                            Invoice.so_cus_building === "" ||
                            Invoice.so_cus_building === null ||
                            Invoice.so_cus_building === undefined ||
                            Invoice.so_swine === "" ||
                            Invoice.so_swine === null ||
                            Invoice.so_swine === undefined ||
                            Invoice.so_soi === "" ||
                            Invoice.so_soi === null ||
                            Invoice.so_soi === undefined ||
                            Invoice.so_road === "" ||
                            Invoice.so_road === null ||
                            Invoice.so_road === undefined ||
                            Invoice.so_sub_district === "" ||
                            Invoice.so_sub_district === null ||
                            Invoice.so_sub_district === undefined ||
                            Invoice.so_district === "" ||
                            Invoice.so_district === null ||
                            Invoice.so_district === undefined ||
                            Invoice.so_province === "" ||
                            Invoice.so_province === null ||
                            Invoice.so_province === undefined ||
                            Invoice.so_postcode === "" ||
                            Invoice.so_postcode === null ||
                            Invoice.so_postcode === undefined ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                      <div className="col-12 col-md-5 col-xl-2">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_cus_house_number}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_cus_house_number: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            บ้านเลขที่
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-5 col-xl-2">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_cus_building}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_cus_building: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            อาคาร
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-2 col-xl-1">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_swine}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_swine: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            หมู่
                            {/* {Invoice.so_swine === "" ||
                            Invoice.so_swine === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-xl-2">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_soi}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_soi: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            ซอย
                            {/* {Invoice.so_soi === "" ||
                            Invoice.so_soi === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-xl-2">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_road}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_road: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            ถนน
                            {/* {Invoice.so_road === "" ||
                            Invoice.so_road === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-xl-3"></div>
                      <div className="col-12 col-md-4 col-xl-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_sub_district}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_sub_district: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            แขวง/ตำบล
                            {/* {Invoice.so_sub_district === "" ||
                            Invoice.so_sub_district === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-xl-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_district}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_district: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            เขต/อำเภอ
                            {Invoice.so_district === "" ||
                              Invoice.so_district === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-xl-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_province}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_province: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            จังหวัด
                            {/* {Invoice.so_province === "" ||
                            Invoice.so_province === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-xl-3"></div>
                      <div className="col-12 col-md-4 col-xl-3">
                        <div className="form-group">
                          <NumberFormat
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            decimalScale={0}
                            maxLength={5}
                            allowNegative={false}
                            value={Invoice.so_postcode}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_postcode: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            รหัสไปรษณีย์
                            {/* {Invoice.so_postcode === "" ||
                            Invoice.so_postcode === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-8 col-md-8 col-xl-8"></div>
                      <div className="col-12 col-md-4 col-xl-4">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_contact_name}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_contact_name: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Customer Contact Name
                            {Invoice.so_contact_name === "" ||
                            Invoice.so_contact_name === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-6 col-md-6 col-xl-6"></div>
                      <div className="col-12 col-md-4 col-xl-4">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_contact_email}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_contact_email: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Contact Email
                            {Invoice.so_contact_email === "" ||
                            Invoice.so_contact_email === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-xl-4">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            // format={"###-###-####"}
                            required
                            disabled={disable}
                            value={Invoice.so_contact_tel}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_contact_tel: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Contact Tel.
                            {Invoice.so_contact_tel === "" ||
                            Invoice.so_contact_tel === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-xl-12">
                        <label>Ship to</label>
                      </div>
                      <div className="col-12 col-md-4 col-xl-4">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_ship_contract_name}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_ship_contract_name: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            ผู้ติดต่อ
                            {Invoice.so_ship_contract_name === "" ||
                              Invoice.so_ship_contract_name === null ||
                              Invoice.so_ship_contract_name === undefined ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-6 col-md-6 col-xl-6"></div>
                      <div className="col-12 col-md-4 col-xl-4">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_csh_name}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_csh_name: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            ชื่อที่อยู่
                            {Invoice.so_csh_name === "" ||
                              Invoice.so_csh_name === null ||
                              Invoice.so_csh_name === undefined ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-xl-4">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            // format={"###-###-####"}
                            disabled={disable}
                            value={Invoice.so_ship_tel}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_ship_tel: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            เบอร์โทรศัพท์
                            {/* {Invoice.so_ship_tel === "" ||
                            Invoice.so_ship_tel === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-xl-12">
                        <label>
                          ที่อยู่
                          {Invoice.so_ship_house_number === "" ||
                            Invoice.so_ship_house_number === null ||
                            Invoice.so_ship_house_number === undefined ||
                            Invoice.so_ship_building === "" ||
                            Invoice.so_ship_building === null ||
                            Invoice.so_ship_building === undefined ||
                            Invoice.so_ship_swine === "" ||
                            Invoice.so_ship_swine === null ||
                            Invoice.so_ship_swine === undefined ||
                            Invoice.so_ship_soi === "" ||
                            Invoice.so_ship_soi === null ||
                            Invoice.so_ship_soi === undefined ||
                            Invoice.so_ship_road === "" ||
                            Invoice.so_ship_road === null ||
                            Invoice.so_ship_road === undefined ||
                            Invoice.so_ship_sub_district === "" ||
                            Invoice.so_ship_sub_district === null ||
                            Invoice.so_ship_sub_district === undefined ||
                            Invoice.so_ship_district === "" ||
                            Invoice.so_ship_district === null ||
                            Invoice.so_ship_district === undefined ||
                            Invoice.so_ship_province === "" ||
                            Invoice.so_ship_province === null ||
                            Invoice.so_ship_province === undefined ||
                            Invoice.so_ship_postcode === "" ||
                            Invoice.so_ship_postcode === null ||
                            Invoice.so_ship_postcode === undefined ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                      <div className="col-12 col-md-2 col-xl-2">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_ship_house_number}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_ship_house_number: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            บ้านเลขที่
                            {/* {Invoice.so_ship_house_number === "" ||
                            Invoice.so_ship_house_number === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-2 col-xl-2">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_ship_building}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_ship_building: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            อาคาร
                            {/* {Invoice.so_ship_building === "" ||
                            Invoice.so_ship_building === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-1 col-xl-1">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_ship_swine}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_ship_swine: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            หมู่
                            {/* {Invoice.so_ship_swine === "" ||
                            Invoice.so_ship_swine === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-2 col-xl-2">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_ship_soi}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_ship_soi: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            ซอย
                            {/* {Invoice.so_ship_soi === "" ||
                            Invoice.so_ship_soi === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-2 col-xl-2">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_ship_road}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_ship_road: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            ถนน
                            {/* {Invoice.so_ship_road === "" ||
                            Invoice.so_ship_road === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-3 col-xl-3"></div>
                      <div className="col-12 col-md-3 col-xl-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_ship_sub_district}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_ship_sub_district: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            แขวง/ตำบล
                            {/* {Invoice.so_ship_sub_district === "" ||
                            Invoice.so_ship_sub_district === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-3 col-xl-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_ship_district}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_ship_district: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            เขต/อำเภอ
                            {/* {Invoice.so_ship_district === "" ||
                            Invoice.so_ship_district === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-3 col-xl-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_ship_province}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_ship_province: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            จังหวัด
                            {/* {Invoice.so_ship_province === "" ||
                            Invoice.so_ship_province === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-3 col-xl-3"></div>
                      <div className="col-12 col-md-3 col-xl-3">
                        <div className="form-group">
                          <NumberFormat
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            decimalScale={0}
                            maxLength={5}
                            allowNegative={false}
                            value={Invoice.so_ship_postcode}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_ship_postcode: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            รหัสไปรษณีย์
                            {/* {Invoice.so_ship_postcode === "" ||
                            Invoice.so_ship_postcode === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-8 col-md-8 col-xl-8"></div>
                      <div className="col-12 col-md-3 col-xl-3">
                        <div className="form-group">
                          <select
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_ds_id}
                            onChange={(e) => {
                              // console.log("wait:", e.target.value);
                              setInvoice({
                                ...Invoice,
                                so_ds_id: e.target.value,
                              });
                              Invoice.so_ds_id = e.target.value;
                              settest("test");
                              // console.log("success:", e.target.value);
                            }}
                          >
                            <option key="" value="">
                              --- เลือก ---
                            </option>
                            {shippingByDropDown.data?.map((option, index) => (
                              <option
                                key={shippingByDropDown.data[index].ds_name}
                                value={shippingByDropDown.data[index].ds_id}
                              >
                                {/* {console.log(shippingByDropDown.data[index].ds_id)} */}
                                {shippingByDropDown.data[index].ds_name}
                              </option>
                            ))}
                          </select>
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Shipping by
                            {Invoice.so_ds_id === "" ||
                              Invoice.so_ds_id === null ||
                              Invoice.so_ds_id === undefined ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.inv_cus_remark}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                inv_cus_remark: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Remark
                            {/* {Invoice.so_cus_remark === "" ||
                            Invoice.so_cus_remark === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-header">
                    <h3 className="card-title">Product</h3>
                  </div>
                  <div className="card-body">
                    <Invoice_Products_Component
                      id={id}
                      mode={mode}
                      isDisabled={disable}
                      Invoice_data={Invoice}
                      setInvoice_data={setInvoice}
                      inputDataTab2={inputDataComponent}
                      setInputDataTab2={setInputDataComponent}
                      errorListTab2={errorListComponent}
                      setErrorListTab2={setErrorListComponent}
                      functionSaveTab2={functionSaveComponent}
                      setFunctionSaveTab2={setFunctionSaveComponent}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            size="sm"
            className="Modal_Center_LeftRight"
            isOpen={modalCustomer}
            toggle={() => {
              setModalInputCustomer({
                ...modalInputCustomer,
                customer: modalInputCustomer.customerOld,
                contact: modalInputCustomer.contactOld,
                ship: modalInputCustomer.shipOld,
              });

              modalInputCustomer.customer = modalInputCustomer.customerOld;
              modalInputCustomer.contact = modalInputCustomer.contactOld;
              modalInputCustomer.ship = modalInputCustomer.shipOld;

              setContactDropDown({
                ...contactDropDown,
                dropdown: contactDropDown.dropdownOld,
              });

              setShipDropDown({
                ...shipDropDown,
                dropdown: shipDropDown.dropdownOld,
              });

              setModalCustomer(false);
            }}
          >
            <ModalHeader
              toggle={() => {
                setModalInputCustomer({
                  ...modalInputCustomer,
                  customer: modalInputCustomer.customerOld,
                  contact: modalInputCustomer.contactOld,
                  ship: modalInputCustomer.shipOld,
                });

                modalInputCustomer.customer = modalInputCustomer.customerOld;
                modalInputCustomer.contact = modalInputCustomer.contactOld;
                modalInputCustomer.ship = modalInputCustomer.shipOld;

                setContactDropDown({
                  ...contactDropDown,
                  dropdown: contactDropDown.dropdownOld,
                });

                setShipDropDown({
                  ...shipDropDown,
                  dropdown: shipDropDown.dropdownOld,
                });

                setModalCustomer(false);
              }}
            >
              <h4 className="modal-title" id="modalCustomer">
                <b>Customer</b>
              </h4>
            </ModalHeader>

            <ModalBody>
              <div className="row">
                <div className="col-12">
                  <div class="form-group">
                    <select
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={Invoice.so_cus_name_id_supply}
                      onChange={(e) => {
                        const cus_find = customerDropDown.data?.find(
                          (el) => el.cus_id === e.target.value
                        );
                        setInvoice({
                          ...Invoice,
                          // so_cus_name: cus_find.cus_name,
                          so_cus_name_id_supply: e.target.value,
                        });
                        Invoice.so_cus_name_id_supply = e.target.value

                        if (cus_find) {

                          setModalInputCustomer({
                            ...modalInputCustomer,
                            customer: cus_find.cus_id,
                            contact: "",
                            ship: "",
                          });
                          const contactTamp = contactDropDown.data.filter(
                            (item) => {
                              return item.ccp_cus_id === cus_find.cus_id;
                            }
                          );

                          setContactDropDown({
                            ...contactDropDown,
                            dropdown: contactTamp,
                          });
                          const shipTamp = shipDropDown.data.filter((item) => {
                            return item.csh_cus_id === cus_find.cus_id;
                          });

                          setShipDropDown({
                            ...shipDropDown,
                            dropdown: shipTamp,
                          });
                        } else {
                          setContactDropDown({
                            ...contactDropDown,
                            dropdown: [],
                          });
                          setShipDropDown({
                            ...shipDropDown,
                            dropdown: [],
                          });
                        }
                      }}
                    >
                      <option key="" value="">
                        --Select Customer--
                      </option>

                      {customerDropDown.data.map((data, index) => {
                        return (
                          <option
                            key={index}
                            value={data.cus_id}
                            id={data.cus_id}
                          >
                            {data.cus_name}
                          </option>
                        );
                      })}
                    </select>
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      Customer
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div class="form-group">
                    <select
                      type="text"
                      className="form-control"
                      required
                      disabled={
                        disable || contactDropDown.dropdown.length === 0
                      }
                      value={modalInputCustomer.contact}
                      onChange={(e) => {
                        setModalInputCustomer({
                          ...modalInputCustomer,
                          contact: e.target.value,
                        });
                      }}
                    >
                      <option key="" value="">
                        --Select Contact Name--
                      </option>

                      {contactDropDown.dropdown.map((data, index) => {
                        return (
                          <option key={index} value={data.ccp_id}>
                            {data.ccp_name}
                          </option>
                        );
                      })}
                    </select>
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      Contact Name
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div class="form-group">
                    <select
                      type="text"
                      className="form-control"
                      required
                      disabled={disable || shipDropDown.dropdown.length === 0}
                      value={modalInputCustomer.ship}
                      onChange={(e) => {
                        setModalInputCustomer({
                          ...modalInputCustomer,
                          ship: e.target.value,
                        });
                      }}
                    >
                      <option key="" value="">
                        --Select Ship to--
                      </option>

                      {shipDropDown.dropdown.map((data, index) => {
                        return (
                          <option key={index} value={data.csh_id}>
                            {data.csh_name}
                          </option>
                        );
                      })}
                    </select>
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      Ship to
                      {/* {inputData.Supplier_Code === "" ||
                      inputData.Supplier_Code === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
              </div>
            </ModalBody>

            <ModalFooter style={{ justifyContent: "space-between" }}>
              <button
                type="button"
                className="btn btn-danger"
                onClick={async () => {
                  setModalInputCustomer({
                    ...modalInputCustomer,
                    customer: modalInputCustomer.customerOld,
                    contact: modalInputCustomer.contactOld,
                    ship: modalInputCustomer.shipOld,
                  });

                  modalInputCustomer.customer = modalInputCustomer.customerOld;
                  modalInputCustomer.contact = modalInputCustomer.contactOld;
                  modalInputCustomer.ship = modalInputCustomer.shipOld;

                  setContactDropDown({
                    ...contactDropDown,
                    dropdown: contactDropDown.dropdownOld,
                  });

                  setShipDropDown({
                    ...shipDropDown,
                    dropdown: shipDropDown.dropdownOld,
                  });

                  setModalCustomer(false);
                }}
              >
                ยกเลิก
              </button>

              <button
                type="button"
                className="btn btn-success"
                onClick={async () => {
                  setModalInputCustomer({
                    ...modalInputCustomer,
                    customerOld: modalInputCustomer.customer,
                    contactOld: modalInputCustomer.contact,
                    shipOld: modalInputCustomer.ship,
                  });

                  modalInputCustomer.customerOld = modalInputCustomer.customer;
                  modalInputCustomer.contactOld = modalInputCustomer.contact;
                  modalInputCustomer.shipOld = modalInputCustomer.ship;

                  setContactDropDown({
                    ...contactDropDown,
                    dropdownOld: contactDropDown.dropdown,
                  });

                  setShipDropDown({
                    ...shipDropDown,
                    dropdownOld: shipDropDown.dropdown,
                  });

                  if (!Invoice.so_cus_name_id) {
                    setInvoice({
                      ...Invoice,
                      so_change_customer: true
                    });
                    Invoice.so_change_customer = true;
                    await clearCustomer();
                  } else {
                    if (!modalInputCustomer.contact) {
                      Swal.fire(
                        "Error",
                        "กรุณาเลือก Customer name ก่อนทำรายการถัดไป",
                        "error"
                      );
                      return;
                    }
                    if (!modalInputCustomer.ship) {
                      Swal.fire(
                        "Error",
                        "กรุณาเลือก Ship to ก่อนทำรายการถัดไป",
                        "error"
                      );
                      return;
                    }
                  }

                  setModalCustomer(false);

                  await GetCusConShipData();
                }}
              >
                บันทึก
              </button>
            </ModalFooter>
          </Modal>
        </section>
      </div>
    </div>
  );
}

export default Invoice_EV;
