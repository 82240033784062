import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import axios from "axios";
import * as THREE from "three";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls";
import Stats from "three/examples/jsm/libs/stats.module";
import forkliftModelPath from "../../../assets/model/O8YPSDGS93RU3UX0EKZK7ZGC2.glb";
import mapModelPath from "../../../assets/model/merged-map.glb";
import rackModelPath from "../../../assets/model/Rack.glb";
import { GUI } from "dat.gui";
import "../../../assets/css/Warehouse3D.css";
import socketClientModule from "../../../component/socket/_client";
import { render } from "@testing-library/react";
import { reset } from "numeral";
import {
  CSS2DRenderer,
  CSS2DObject,
} from "three/addons/renderers/CSS2DRenderer.js";
import Configs from "../../../config";
import { getToken } from "../../../Utils/Common";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
// import { Modal } from "antd";
import { TransformControls } from "three/examples/jsm/controls/TransformControls";
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { InteractionManager } from "three.interactive";
import ToolWarehouse from "./Component/ToolWarehouse";
import ConfigWarehouse from "./Component/ConfigWarehouse";
import HeaderChangeWH from "./Component/HeaderChangeWH";
// import ThreeScene from "./ThreeScene";

function Warehouse3D_Main({ viewOnly, whIdRef, positionRef, mainData, modelType, viewMode, callBackPositionName, showRackPopup }) {
  
  const NOT_USE_NOW = true;
  let groupObjSelect = null;
  /* 
    ไว้ตอน ปล่อยการคลิกแล้วเมาส์ แล้วไป hover บน object จะไม่ย้ายไป select object อื่น
  */
  let axisYClicked = false;
  const [actionActive, setActionActive] = useState({
    btn: "", // move, rotate, scale, camera
  });
  const [testRack, settestRack] = useState({
    dataList: null,
  });
  const [cubeState, setCubeState] = useState({
    data: new THREE.Mesh(
      new THREE.BoxGeometry(5, 5, 5),
      new THREE.MeshNormalMaterial()
    ),
    isFocus: false,
  });
  const [actionListRack, setActionListRack] = useState({
    data: {} /* [
      { rack_id: '1', actionList: ['scale', 'move', 'move'] }
    ] */,
  });
  const [switchBtn, setswitchBtn] = useState({
    wh: true,
    rack: false,
    mode: null,
  });
  const [warehouseData, setwarehouseData] = useState({
    id: null,
    width: 36,
    depth: 24,
    height: 12,
    unit: "cm.",
  });
  const [rackData, setrackData] = useState({
    rack_id: null,
    width: 0,
    depth: 0,
    height: 0,
    axis_x: 0,
    axis_x_pos: 1,
    axis_x_chr: "",
    axis_y: 0,
    axis_y_pos: 1,
    axis_y_chr: "",
    axis_z: 0,
    axis_z_pos: 1,
    axis_z_chr: "",
    rows: 0,
    columns: 0,
    rack_name: "",
    rack_name_input: "",
    width_input: 0,
    depth_input: 0,
    height_input: 0,
    axis_x_input: 0,
    axis_x_pos_input: 1,
    axis_x_chr_input: "",
    axis_y_input: 0,
    axis_y_pos_input: 1,
    axis_y_chr_input: "",
    axis_z_input: 0,
    axis_z_pos_input: 1,
    axis_z_chr_input: "",
    rows_input: 0,
    columns_input: 0,
    position: "east",
    unit: "cm.",
    is_select_rack: false,
    rack_list: [],
    position_list: [],
  });
  
  const [useZAxis, setUseZAxis] = useState({
    is_use: false,
  });
  const [itemRack, setItemRack] = useState({});
  const [forkliftName, setForkliftName] = useState({});
  // const [changeWarehouse, setChangeWarehouse] = useState(false);
  // const [isFirstRun, setIsFirstRun] = useState(true);
  const [sizeRoom, setSizeRoom] = useState({
    width: 36,
    height: 12,
    depth: 24,
    name: null,
    width_input: 36,
    height_input: 12,
    depth_input: 24,
    name_input: null,
  });

  const [dataOfWarehouse, setDataOfWarehouse] = useState({
    position: [],
    log_list: [],
  });
/* min: 12, max: 52  */
  const [valueMinMaxSpoonFLK, setValueMinMaxSpoonFLK] = useState({ min: 10, max: 52 });

  const [functionComponent, setFunctionComponent] = useState({
    generateRoom: null /* Func of ConfigWarehouse */,
    clearRoom: clearRoom,
    cancelAdd: null /* Func of headerChangeWH */,
    SaveWarehouse: null,
    GetWarehouseAPI: null,
  });

  const [socketConnected, setSocketConnected] = useState(false);
  const [fps, setFPS] = useState(0);
  const [socketLatency, setSocketLatency] = useState(0);

  const xRef = React.useRef(0);
  const zRef = React.useRef(0);
  let x_response = {}, y_response = {};

  const lookAtRef = React.useRef(false);
  const offsetSceneRef = React.useRef(false);
  const modelRef = React.useRef(null);
  const modelForkliftRef = React.useRef(null);
  const modelCircleRef = React.useRef(null);
  const isFirstRunRef = React.useRef(true);
  const changeWarehouseRef = React.useRef(false);
  const directionRef = React.useRef(null);
  const itemRackRef = React.useRef();
  function SetPOSModel(size) {
    // console.log('size', -(size.width / 2));
    let width = 0;
    if (modelRef.current) {
      const boundingBox = new THREE.Box3().setFromObject(modelRef.current);
      width = boundingBox.max.x - boundingBox.min.x;
    }
    // xRef.current = -(size.width / 2) + (width ? (width / 2) : width);
    xRef.current = width / 2;
    zRef.current = size.depth / 2;
  }

  // Variables to store Azimuthal Angle
  let angle = 0;
  let dir = new THREE.Vector3();

  // Variables to handle zoom method
  let zoomingIn = false;
  let zoomingOut = false;
  let animationFrameId;
  const animationFrameIdRef = useRef();

  // Reference instances
  const guiRef = useRef();
  const canvasRef = useRef();
  const cameraRef = useRef();
  const controlsRef = useRef();
  const trackballsRef = useRef();
  const compassRef = useRef();

  const labelRef = useRef();

  let frames = 0,
    prevTime = performance.now();
  let socketx, socket_on_connected;
  const isCallSocketAgainRef = useRef(true);
  const encryptTopicRef = useRef("16faafd0421b54a8f2f09cdfdd9c4d901a049a7b");
  const socketTokenRef = useRef(
    ""/* "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsaWNlbnNlS2V5IjoibGljZW5zZUtleSIsImlhdCI6MTcwNDU4ODk3OCwiZXhwIjoxNzA3MTgwOTc4fQ.vcPBm7BYTFtcdPLPgHTXp42PVph39TchkaitITKGzjI" */
  );

  async function socketConnectNew() {
    if (!isCallSocketAgainRef.current) return;
    else {
      try {
        socketClientModule.leave(
          `unai/${encryptTopicRef.current}/sensor/raw`,
          socket_on_connected
        );
      } catch (error) {
        console.log('error leave socket :>> ', error);
      }
    }
    let idx;
    var options = {};
    options.query = {
      token: socketTokenRef.current,
    };
    // console.log('socket conecting...');
    socketx = socketClientModule.setup(
      "https://socketx.lailab.online",
      "/ble/location",
      "/",
      (socket) => {
        setSocketConnected(socket.connected);
        socket_on_connected = socket;
        idx =
          "user" +
          "_" +
          Math.floor(1000 + Math.random() * 9000) +
          "_" +
          Math.floor(1000 + Math.random() * 9000);
        socketClientModule.register(idx, socket);
        socketClientModule.join(
          `unai/${encryptTopicRef.current}/sensor/raw`,
          socket_on_connected
        );
        // console.log('socket.id : ', socket.id);
      },
      options
    );

    socketx.on("clientBox", function (data) {
      if (typeof data === "object" && data !== null) {
        if (data.topic != undefined || data.topic != "NULL") {
          // console.log('data?.topic :>> ', data?.topic);
          if (data?.topic?.includes("/sensor/raw") || true) {
            const key_name_forklift = data.tagId;
            if (data && data.tagId && modelForkliftRef.current[key_name_forklift]) {
              // console.log('dataddddddddddddddddddddddddddd :>> ', data.heightWithOffset);
              if (data.eventType == 'Load') {
                console.log('YESSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS');
                
              }

              /* lookAtRef.current = true;
              xRef.current = data.x * 2;
              zRef.current = Math.abs(data.y) * 2; 
              */
              modelForkliftRef.current.on_name_flk = key_name_forklift;
              const range_value = !x_response[key_name_forklift] && !y_response[key_name_forklift] ? 0.001 : 0.2;
              function returnNumber(value) {
                return range_value == 0.001 ? /* parseInt */(Number(value || 0)) : (Number(value || 0));
              }
              if (!x_response[key_name_forklift] && !y_response[key_name_forklift]) {
                x_response[key_name_forklift] = returnNumber(data.x);
                y_response[key_name_forklift] = returnNumber(data.y);
              }
              let mul_cal = 2, ratio_pos = 5.5;
              // console.log('x_response[key_name_forklift] :>> ', x_response[key_name_forklift]);
              // console.log('y_response[key_name_forklift] :>> ', y_response[key_name_forklift]);
              // console.log('range_value :>> ', range_value);
              // console.log('Math.abs(x_response[key_name_forklift] - data.x) :>> ', Math.abs(x_response[key_name_forklift] - data.x));
              // console.log('data.y :>> ', data.y);
              // console.log('y_response[key_name_forklift] :>> ', y_response[key_name_forklift]);
              // console.log('Math.abs(x_response[key_name_forklift] - data.x) :>> ', Math.abs(x_response[key_name_forklift] - data.x));
              // console.log('Math.abs(y_response[key_name_forklift] - data.y) :>> ', Math.abs(y_response[key_name_forklift] - data.y));
              // console.log('Math.abs(x_response[key_name_forklift] - data.x) > range_value || Math.abs(y_response[key_name_forklift] - data.y) > range_value :>> ', Math.abs(x_response[key_name_forklift] - data.x) > range_value || Math.abs(y_response[key_name_forklift] - data.y) > range_value);
              if (Math.abs(returnNumber(x_response[key_name_forklift]) - (returnNumber(data.x))) > range_value || Math.abs(returnNumber(y_response[key_name_forklift]) - (returnNumber(data.y))) > range_value || range_value == 0.001) {
                /* console.log('x_response[key_name_forklift] :>> ', x_response[key_name_forklift]);
                console.log('data.x :>> ', data.x); */
                const is_diff_turn_x = Math.abs(returnNumber(x_response[key_name_forklift]) - returnNumber(data.x)) > 1;
                // console.log('is_diff_turn_x :>> ', is_diff_turn_x);
                if (returnNumber(x_response[key_name_forklift]) < returnNumber(data.x) && is_diff_turn_x) {
                  directionRef.current = "w";
                } else if (returnNumber(x_response[key_name_forklift]) > returnNumber(data.x) && is_diff_turn_x) {
                  directionRef.current = "s";
                }
                x_response[key_name_forklift] = returnNumber(data.x);
                // console.log('x_response[key_name_forklift] :>> ', x_response[key_name_forklift]);
                modelForkliftRef.current[key_name_forklift].pos_x = returnNumber(x_response[key_name_forklift]) * ratio_pos * mul_cal;
                
                const is_diff_turn_y = Math.abs(returnNumber(y_response[key_name_forklift]) - returnNumber(data.y)) > 1;
                // console.log('is_diff_turn_y :>> ', is_diff_turn_y);
                if (returnNumber(y_response[key_name_forklift]) < returnNumber(data.y) && is_diff_turn_y) {
                  directionRef.current = "d";
                } else if (returnNumber(y_response[key_name_forklift]) > returnNumber(data.y) && is_diff_turn_y) {
                  directionRef.current = "a";
                }
                y_response[key_name_forklift] = returnNumber(data.y);
                // console.log('y_response[key_name_forklift] :>> ', y_response[key_name_forklift]);
                modelForkliftRef.current[key_name_forklift].pos_z = returnNumber(y_response[key_name_forklift]) * ratio_pos * -mul_cal;
                // console.log('directionRef.current :>> ', directionRef.current);
                // if (data.z > 2.3)
                  // console.log('data.z :>> ', data.z); fu_height
                lookAtRef.current = true;
              }
              
              if (modelForkliftRef.current[key_name_forklift].spoon_model) {
                // console.log('data.sv :>> ', data.sv);
                const dataMin = 0.66;
                const dataMax = 4.65;

                const spoonMin = valueMinMaxSpoonFLK.min;
                const spoonMax = valueMinMaxSpoonFLK.max;

                const normalizedHeight = (data.sv - dataMin) / (dataMax - dataMin);
                const mappedHeight = (normalizedHeight) * (spoonMax - spoonMin) + spoonMin;

                // Apply the adjusted height to your model
                modelForkliftRef.current[key_name_forklift].spoon_model.position.y = mappedHeight;
                lookAtRef.current = true;

              }/*  else {
                lookAtRef.current = false;
              } */

              // console.log('modelForkliftRef.current[key_name_forklift].pos_z :>> ', modelForkliftRef.current[key_name_forklift].pos_z);
              // console.log('modelForkliftRef.current[key_name_forklift].pos_x :>> ', modelForkliftRef.current[key_name_forklift].pos_x);
            }
            // calculateCumulatedTag(data);
          } else if (data?.topic?.includes("/anchor")) {
            // calculateCumulatedAnchor(data);
          }
        }
      } else {
        lookAtRef.current = false;
        console.log("[clientBox] :", data);
      }
    });
  }

  async function socketConnect() {
    let idx;
    var options = {};
    options.query = {
      token: socketTokenRef.current,
    };
    // console.log('socket conecting...');
    socketx = socketClientModule.setup(
      "https://socketx.lailab.online",
      "/ble/location",
      "/",
      (socket) => {
        setSocketConnected(socket.connected);
        socket_on_connected = socket;
        idx =
          "user" +
          "_" +
          Math.floor(1000 + Math.random() * 9000) +
          "_" +
          Math.floor(1000 + Math.random() * 9000);
        socketClientModule.register(idx, socket);
        socketClientModule.join(
          `unai/${encryptTopicRef.current}/tag`,
          socket_on_connected
        );
        // console.log('socket.id : ', socket.id);
      },
      options
    );

    socketx.on("clientBox", function (data) {
      if (typeof data === "object" && data !== null) {
        if (data.topic != undefined || data.topic != "NULL") {
          if (data?.topic?.includes("/tag")) {
            const key_name_forklift = data.firstName;
            if (data && modelForkliftRef.current[key_name_forklift]) {
              // console.log('data :>> ', data);
              /* lookAtRef.current = true;
              xRef.current = data.x * 2;
              zRef.current = Math.abs(data.y) * 2; 
              */
              modelForkliftRef.current.on_name_flk = key_name_forklift;
              if (!x_response[key_name_forklift] && !y_response[key_name_forklift] && false) {
                x_response[key_name_forklift] = data.x;
                y_response[key_name_forklift] = data.y;
              }

              // console.log('Math.abs(x_response[key_name_forklift] - data.x) :>> ', Math.abs(x_response[key_name_forklift] - data.x));
              // console.log('data.y :>> ', data.y);
              // console.log('y_response[key_name_forklift] :>> ', y_response[key_name_forklift]);
              // console.log('Math.abs(x_response[key_name_forklift] - data.x) :>> ', Math.abs(x_response[key_name_forklift] - data.x));
              // console.log('Math.abs(y_response[key_name_forklift] - data.y) :>> ', Math.abs(y_response[key_name_forklift] - data.y));
              if (Math.abs(x_response[key_name_forklift] - data.x) > 0.001 || Math.abs(y_response[key_name_forklift] - data.y) > 0.001) {
                /* console.log('x_response[key_name_forklift] :>> ', x_response[key_name_forklift]);
                console.log('data.x :>> ', data.x); */
                const is_diff_turn_x = Math.abs(x_response[key_name_forklift] - data.x) > 1;
                // console.log('is_diff_turn_x :>> ', is_diff_turn_x);
                if (x_response[key_name_forklift] < data.x && is_diff_turn_x) {
                  directionRef.current = "w";
                } else if (x_response[key_name_forklift] > data.x && is_diff_turn_x) {
                  directionRef.current = "s";
                }
                x_response[key_name_forklift] = data.x;
                modelForkliftRef.current[key_name_forklift].pos_x = x_response[key_name_forklift] * 10;
                
                const is_diff_turn_y = Math.abs(y_response[key_name_forklift] - data.y) > 1;
                // console.log('is_diff_turn_y :>> ', is_diff_turn_y);
                if (y_response[key_name_forklift] < data.y && is_diff_turn_y) {
                  directionRef.current = "d";
                } else if (y_response[key_name_forklift] > data.y && is_diff_turn_y) {
                  directionRef.current = "a";
                }
                y_response[key_name_forklift] = data.y;
                modelForkliftRef.current[key_name_forklift].pos_z = Math.abs(y_response[key_name_forklift]) * 10;
                // console.log('directionRef.current :>> ', directionRef.current);
                if (data.z > 2.3)
                  console.log('data.z :>> ', data.z);
                if (modelForkliftRef.current[key_name_forklift].spoon_model) {
                  modelForkliftRef.current[key_name_forklift].spoon_model.position.y = data.z > 1.8 ? 1.8 : data.z < 0.4 ? 0.4 : data.z;
                  // console.log('modelForkliftRef.current[key_name_forklift].spoon_model :>> ', modelForkliftRef.current[key_name_forklift].spoon_model.position.y);                 
                }
                lookAtRef.current = true;
              }

              // console.log('modelForkliftRef.current[key_name_forklift].pos_z :>> ', modelForkliftRef.current[key_name_forklift].pos_z);
              // console.log('modelForkliftRef.current[key_name_forklift].pos_x :>> ', modelForkliftRef.current[key_name_forklift].pos_x);
            }
            // calculateCumulatedTag(data);
          } else if (data?.topic?.includes("/anchor")) {
            // calculateCumulatedAnchor(data);
          }
        }
      } else {
        lookAtRef.current = false;
        console.log("[clientBox] :", data);
      }
    });
  }

  async function requestGenToken(props) {
    if (encryptTopicRef.current == props.data.encrypt_topic && socketTokenRef.current == props.data.socket_token) {
      isCallSocketAgainRef.current = false;
    } else {
      isCallSocketAgainRef.current = true;
    }
    encryptTopicRef.current = props.data.encrypt_topic;
    socketTokenRef.current = props.data.socket_token;
    // let config = {
    //   method: "get",
    //   url: `${Configs.API_URL_IoT_Connect}` + "/IOT_Connect/genTokenIoTConnect",
    //   headers: {
    //     Authorization: getToken(),
    //     "X-TTT": Configs.API_TTT,
    //     "Content-Type": "application/json",
    //   },
    // };
    /* await axios
      .request(config)
      .then((response) => {
        encryptTopicRef.current = response.data.encrypt_topic;
        socketTokenRef.current = response.data.socket_token;
        // return response.data;
      })
      .catch((error) => {
        console.log(error);
      }); */
  }

  function handleCubeStateIsFocus(bool) {
    setCubeState({
      ...cubeState,
      isFocus: bool,
    });
    cubeState.isFocus = bool;
  }

  function GetModeTransFormControls() {
    return actionActive.btn == "move" ? "translate" : actionActive.btn;
  }

  function CreateGroundGeometry(scene, width, height) {
    const scale =
      width * height < 1500 ? width * height + 200 : (width * height) / 3;
    const ground = new THREE.Mesh(
      new THREE.PlaneGeometry(scale, scale, 10, 10),
      new THREE.MeshStandardMaterial({
        color: '0xfbecff',
      })
    );
    ground.rotation.x = -Math.PI / 2;
    ground.position.y = -0.98;
    ground.position.z = width / 2;
    ground.receiveShadow = true;
    scene.add(ground);
  }

  function SetUpLigthAndDirectionLight(scene) {
    const ambient = new THREE.HemisphereLight(0xffffff, 0xffffff, 1);
    ambient.position.set(0, 20, 0);
    scene.add(ambient);
    // ambient.castShadow = true;

    const dirLight = new THREE.DirectionalLight(0xffffff, 3);
    dirLight.color.setHSL(0.1, 1, 0.95);
    dirLight.position.set(-1, 1.75, 1);
    dirLight.position.multiplyScalar(30);

    dirLight.castShadow = true;
    dirLight.shadow.camera.near = 0.1;
    dirLight.shadow.camera.far = 500;
    dirLight.shadow.camera.right = 70;
    dirLight.shadow.camera.left = -50;
    dirLight.shadow.camera.top = 50;
    dirLight.shadow.camera.bottom = -70;
    dirLight.shadow.mapSize.width = 512;
    dirLight.shadow.mapSize.height = 512;
    dirLight.shadow.radius = 8;
    dirLight.shadow.blurSamples = 25;
    dirLight.shadow.bias = -0.0005;
    scene.add(dirLight);
  }

  function CreateRoom(width, height, depth, scene, obj_check_name_model, viewMode) {
    
    let material;
    let geometry;
    // พื้น Warehouse
    if(actionActive.btn === "2D" || viewMode === "2D") {
      const material2D = new THREE.MeshStandardMaterial({
        color: '#696a6a',
        metalness: 0.2, // เพิ่มคุณสมบัติสะท้อนแสง
        roughness: 0.8, // ลดความหยาบเพื่อเพิ่มความมันวาว
        transparent: true,
        opacity: 1,
        side: 1,
      });
      geometry = new THREE.BoxGeometry(width, height, depth); // ลด height ให้เป็น 1 หน่วย
      material = [null, null, null, material2D, null, null];
    } else {
      const frontMaterial = new THREE.MeshStandardMaterial({
        color: '#696a6a',
        metalness: 0.2, // เพิ่มคุณสมบัติสะท้อนแสง
        roughness: 0.8, // ลดความหยาบเพื่อเพิ่มความมันวาว
        transparent: true,
        opacity: 1,
        side: 2,
      });

      // กำแพง Warehouse
      const backMaterial = new THREE.MeshStandardMaterial({
        color: '#e6e6e6',
        metalness: 0.1, // เพิ่มคุณสมบัติสะท้อนแสงเล็กน้อย
        roughness: 0.9, // ปรับความหยาบให้ดูสมจริงขึ้น
        transparent: true,
        opacity: 1,
        side: 1,
      });

      material = [backMaterial, backMaterial, backMaterial, frontMaterial, backMaterial, backMaterial];
      geometry = new THREE.BoxGeometry(width, height, depth);
    }
    const extendFloor = new THREE.Mesh(geometry, material);
    extendFloor.position.setX(width / 2.5 * -1);
    extendFloor.position.setY(height / 2);
    extendFloor.position.setZ(depth / 2);
    // extendFloor.position.setZ(depth / 2);
    
    obj_check_name_model["extendFloor"] = "extendFloor";
    extendFloor.name = "extendFloor";
    scene.add(extendFloor);
  }



  function SetConfigControlsAndTrackballs(controls, trackballs) {
    controls.enableDamping = true;
    controls.dampingFactor = 0.12;
    controls.enableZoom = false;
    // controls.enablePan = false;
    controls.enableRotate = true;
    // controls.enableRotate = false; // ใช้ตอน user click btn action object
    controls.minDistance = 5;
    controls.maxDistance = Infinity;
    controls.minPolarAngle = 0;
    controls.maxPolarAngle = 1.5;
    controls.minAzimuthAngle = -Infinity;
    controls.maxAzimuthAngle = Infinity;

    // trackballs.enableRotate = false;
    // trackballs.enablePan = false;
    trackballs.noRotate = true;
    trackballs.noPan = true;
    trackballs.noZoom = false;
    trackballs.minDistance = 5;
    trackballs.maxDistance = Infinity;
    trackballs.zoomSpeed = 1.5;
  }

  const GAP = 6;
  function ClearRackList() {
    cancelAnimationFrame(animationFrameIdRef.current);
    console.log('clear success.', animationFrameIdRef.current);
    
    /* setrackData({
      ...rackData,
      rack_list: [],
    });
    rackData.rack_list = [];

    let unit =
      warehouseData.unit == "mm." ? 0.1 : warehouseData.unit == "m." ? 100 : 1;

    cancelAnimationFrame(animationFrameId);
    initialData(
      warehouseData.width * unit,
      warehouseData.height * unit,
      warehouseData.depth * unit
    ); */
  }

  async function addRackModalConfig(
    range_width,
    scene,
    rackItem,
    interactionManager,
    is_return,
    ratio_x_rack,
    ratio_y_rack,
    ratio_z_rack,
    is_update,
    positionListParams,
    rackListParams,
    obj_check_name_model,
    viewMode
  ) {
    // console.log('ratio_x_rack, ratio_y_rack, ratio_z_rack,', ratio_x_rack, ratio_y_rack, ratio_z_rack)
    // console.log('rackItem', rackItem);
    const { groupObj, ...other } = rackItem;
    const rack = { ...other };

    const rack_sub = { ...other };
    let unit = rackData.unit == "mm." ? 0.01 : rackData.unit == "m." ? 1 : 0.1;
    rack_sub.depth_rack = rack_sub.depth_rack * (ratio_z_rack || 1);
    rack_sub.height_rack = rack_sub.height_rack * (ratio_y_rack || 1);
    rack_sub.width_rack = rack_sub.width_rack * (ratio_x_rack || 1);
    rack.depth_rack = rack.depth_rack * (ratio_z_rack || 1) * unit;
    rack.height_rack = rack.height_rack * (ratio_y_rack || 1) * unit;
    rack.width_rack = rack.width_rack * (ratio_x_rack || 1) * unit;

    const MUL_VALUE = (rack_sub.width_rack > 100 || rack_sub.depth_rack > 100
    ? 5 : 10)
    // console.log('rack', rack)
    const group = new THREE.Group();
    // front pillar
    const width_pillar =
      rack.width_rack > 100 || rack.depth_rack > 100
        ? Math.max(rack.width_rack, rack.depth_rack) / 50
        : 1 / MUL_VALUE;
    const depth_pillar =
      rack.width_rack > 100 || rack.depth_rack > 100
        ? Math.max(rack.width_rack, rack.depth_rack) / 50
        : 1 / MUL_VALUE;

    function createThickLine(start, end, thickness, color) {
      const direction = new THREE.Vector3().subVectors(end, start);
      const length = direction.length();
      const geometry = new THREE.BoxGeometry(thickness, thickness, length);
      
      const material = new THREE.MeshBasicMaterial({ 
        color: color,
        transparent: true,
        opacity: actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? 1 : 0,
        depthWrite: actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? true : false 
      });
      const line = new THREE.Mesh(geometry, material);

      const midpoint = new THREE.Vector3().addVectors(start, end).multiplyScalar(0.5);
      line.position.copy(midpoint);
      
      // กำหนดลำดับการเรนเดอร์สูงขึ้นเพื่อให้เรนเดอร์หลังจากวัตถุอื่น ๆ
      line.renderOrder = 1;

      line.lookAt(end);
      
      return line;
    }

    // ฟังก์ชันสำหรับสร้างเส้นเฉียงระหว่างเสา
    function createDiagonalLines(pillar1, pillar2, height, thickness, color) {
      const diagonalLines = new THREE.Group();
      const sections = 5; // จำนวนส่วนที่ต้องการแบ่ง
      const sectionHeight = height / sections;
    
      for (let i = 0; i < sections; i++) {
        let start, end;
        if (i % 2 === 0) {
          // เส้นจากซ้ายไปขวา
          start = new THREE.Vector3(
            pillar1.position.x,
            pillar1.position.y - height / 2 + i * sectionHeight,
            pillar1.position.z
          );
          end = new THREE.Vector3(
            pillar2.position.x,
            pillar2.position.y - height / 2 + (i + 1) * sectionHeight,
            pillar2.position.z
          );
        } else {
          // เส้นจากขวาไปซ้าย
          start = new THREE.Vector3(
            pillar2.position.x,
            pillar2.position.y - height / 2 + i * sectionHeight,
            pillar2.position.z
          );
          end = new THREE.Vector3(
            pillar1.position.x,
            pillar1.position.y - height / 2 + (i + 1) * sectionHeight,
            pillar1.position.z
          );
        }
        diagonalLines.add(createThickLine(start, end, thickness, color));
      }
    
      return diagonalLines;
    }
    
      // ฟังก์ชันสำหรับสร้างเส้นที่มีความหนา
      const rackWidth = rack.width_rack - width_pillar;
      const rackHeight = rack.height_rack;
      const rackDepth = rack.depth_rack - depth_pillar;

      const pillar1 = new THREE.Mesh(
        new THREE.BoxGeometry(width_pillar * 2, rack.height_rack, depth_pillar * 2),
        new THREE.MeshStandardMaterial({
          // color: 0x353a39,
          color: '#0064b2',
          transparent: true,
          opacity: actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? 1 : 0,
          depthWrite: actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? true : false
          // side: 1
        })
      );

      pillar1.position.setX(-rack.width_rack / 2 + width_pillar / 2);
      pillar1.position.setY(rack.height_rack / 2);
      pillar1.position.setZ(rack.depth_rack / 2 - depth_pillar / 2);

      const pillar2 = pillar1.clone();
      pillar2.position.setX(rack.width_rack / 2 - width_pillar / 2);

      group.add(pillar2);
      group.add(pillar1);

      const wall_rack_l = new THREE.Mesh(
        new THREE.BoxGeometry(0.1, rack.height_rack, rack.depth_rack),
        new THREE.MeshStandardMaterial({
          color: 0x353a39,
          transparent: true,
          opacity: actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? 0.8 : 0,
          depthWrite: actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? true : false
        })
      );
      wall_rack_l.position.setY(rack.height_rack / 2);
      wall_rack_l.position.setX(-rack.width_rack / 2);

      const wall_rack_r = wall_rack_l.clone();
      wall_rack_r.position.setX(rack.width_rack / 2);
      
      // group.add(wall_rack_l);
      // group.add(wall_rack_r);

      /* scene.add(pillar2);
      scene.add(pillar1); */

      // back pillar
      const pillar3 = new THREE.Mesh(
        new THREE.BoxGeometry(width_pillar * 2, rack.height_rack, depth_pillar * 2),
        new THREE.MeshStandardMaterial({
          // color: 0x353a39,
          color: '#0064b2',
          transparent: true,
          opacity: actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? 1 : 0,
          depthWrite: actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? true : false
          // side: 1
        })
      );

      pillar3.position.setX(-rack.width_rack / 2 + width_pillar / 2);
      pillar3.position.setY(rack.height_rack / 2);
      pillar3.position.setZ(-rack.depth_rack / 2 + depth_pillar / 2);

      const pillar4 = pillar3.clone();
      pillar4.position.setX(rack.width_rack / 2 - width_pillar / 2);

      const pillar_start = -rack.width_rack / 2 + width_pillar / 2;
      for (let index = 1; index <= rack.col_rack - 1; index++) {
        const pillarLoop1 = pillar3.clone();
        pillarLoop1.position.setX(
          pillar_start +
            ((rack.width_rack - width_pillar) / rack.col_rack) * index
        );

        const pillarLoop2 = pillar1.clone();
        pillarLoop2.position.setX(
          pillar_start +
            ((rack.width_rack - width_pillar) / rack.col_rack) * index
        );

        // group.add(pillarLoop1);
        // group.add(pillarLoop2);
        // group.add(createThickLine(
        //   pillar1.position.clone(),
        //   pillarLoop1.position.clone(),
        //   0.3, // ความหนาของเส้น
        //   '#c26e0a' // สีของเส้น
        // ));
        // group.add(createThickLine(
        //   pillar2.position.clone(),
        //   pillarLoop2.position.clone(),
        //   0.3, // ความหนาของเส้น
        //   '#c26e0a' // สีของเส้น
        // ));
        /* scene.add(pillarLoop1);
        scene.add(pillarLoop2); */
      }

      group.add(pillar4);
      group.add(pillar3);
      // สร้างเส้นเชื่อมระหว่างเสาแบบเฉียง
      const diagonalThickness = 0.3;
      const diagonalLines1 = createDiagonalLines(pillar1, pillar3, rackHeight, diagonalThickness, '#0064b2');
      const diagonalLines2 = createDiagonalLines(pillar2, pillar4, rackHeight, diagonalThickness, '#0064b2');

      group.add(diagonalLines1);
      group.add(diagonalLines2);

      // group.add(createThickLine(
      //   new THREE.Vector3(pillar1.position.x, pillar1.position.y - rackHeight / 2, pillar1.position.z),
      //   new THREE.Vector3(pillar2.position.x, pillar2.position.y - rackHeight / 2, pillar2.position.z),
      //   diagonalThickness, // ความหนาของเส้น
      //   '#0064b2'
      // ));
      group.add(createThickLine(
        new THREE.Vector3(pillar1.position.x, pillar1.position.y + rackHeight / 2, pillar1.position.z),
        new THREE.Vector3(pillar3.position.x, pillar3.position.y + rackHeight / 2, pillar3.position.z),
        diagonalThickness, // ความหนาของเส้น
        '#0064b2'
      ));
      // group.add(createThickLine(
      //   new THREE.Vector3(pillar2.position.x, pillar2.position.y - rackHeight / 2, pillar2.position.z),
      //   new THREE.Vector3(pillar4.position.x, pillar4.position.y - rackHeight / 2, pillar4.position.z),
      //   diagonalThickness, // ความหนาของเส้น
      //   '#0064b2'
      // ));
      group.add(createThickLine(
        new THREE.Vector3(pillar2.position.x, pillar2.position.y + rackHeight / 2, pillar2.position.z),
        new THREE.Vector3(pillar4.position.x, pillar4.position.y + rackHeight / 2, pillar4.position.z),
        diagonalThickness, // ความหนาของเส้น
        '#0064b2'
      ));
      /* scene.add(pillar4);
      scene.add(pillar3); */
      // console.log('rack.split_rack_z', rack.split_rack_z);
      
    const GAP_BOX = 1 * width_pillar;
      let runIdIndex = 0;
      let runBreak = 0;
      const rackListRef = [...myStateRef.current];
      const _index_rackListRef = rackListRef.findIndex(
        (el) => el.rack_id === rack.rack_id
      );
      // const _find = rackData.rack_list.find(el => el.rack_id == rack.rack_id);
      rackListRef[_index_rackListRef].floor_tile_list = [];
      for (let index = 0; index < rack.row_rack; index++) {
        for (let index3 = 0; index3 < rack.split_rack_z; index3++) {
          for (let index2 = 0; index2 < rack.col_rack; index2++) {
            // rackData.position_list[runIdIndex]?.rp_id
            
            // console.log('rack.position_list[runIdIndex]?.rp_id', rack.position_list[runIdIndex]?.rp_id);
            // console.log('dataOfWarehouse.log_list', dataOfWarehouse.log_list);
            const log_list =  
              positionListParams && positionListParams.length > 0
                ? positionListParams
                : dataOfWarehouse.log_list;

            const _filter = log_list.filter(
              (el) => el.lp_rp_id == rack.position_list[runIdIndex]?.rp_id
            );

            const width_box =
              (rack.width_rack - width_pillar) / rack.col_rack - width_pillar;
            const height_box = rack.height_rack / rack.row_rack - GAP_BOX;
            const depth_box =
              (rack.depth_rack - width_pillar * 2) / rack.split_rack_z;
            const position_x_box = (index2 * (width_box + width_pillar) -
              rack.width_rack / 2 +
              width_box / 2 +
              width_pillar)
            const position_z_box = (index3 * (depth_box + 0) -
              rack.depth_rack / 2 +
              depth_box / 2 +
              width_pillar) // - (depth_box / 2))

            const width_tile =
              rack.width_rack /*  - width_pillar */ /
              rack.col_rack; /*  - (width_pillar) */
            const depth_tile =
              ((rack.depth_rack - width_pillar * 2) /* / rack.split_rack_z */) * 1.5;
              
            if (index === 0 && index3 === 0) {
              const floor_tile_front = new THREE.Mesh(
                new THREE.BoxGeometry(width_tile, 0.2 / MUL_VALUE, depth_tile),
                new THREE.MeshStandardMaterial({
                  // color: 0xf5675d,
                  // color: '#757575',
                  transparent: true,
                  depthWrite: false,
                  opacity: 0,
                })
              );
              floor_tile_front.position.setX(
                index2 * (width_box + width_pillar) -
                  rack.width_rack / 2 +
                  width_box / 2 +
                  width_pillar
              );
              floor_tile_front.position.setY(0)
              floor_tile_front.position.setZ((depth_tile / 2 + rack.depth_rack / 2) * -1);

              const floor_tile_end = floor_tile_front.clone();
              floor_tile_end.position.setZ((depth_tile / 2 + rack.depth_rack / 2) * 1);

              floor_tile_end.userData.id = rack.rack_id + runBreak;
              runBreak++;
              rackListRef[_index_rackListRef].floor_tile_list.push({
                tile: floor_tile_end,
                [`x-${position_x_box}_z-${((rack.split_rack_z - 1) * (depth_box + 0) -
                  rack.depth_rack / 2 +
                  depth_box / 2 +
                  width_pillar)}`]: []
              });
              floor_tile_front.userData.id = rack.rack_id + runBreak;
              runBreak++;
              rackListRef[_index_rackListRef].floor_tile_list.push({
                tile: floor_tile_front,
                [`x-${position_x_box}_z-${position_z_box}`]: []
              });
              // console.log('gen :>> ');
              floor_tile_end.name = 'tile_end';
              floor_tile_front.name = 'tile_front';
              group.add(floor_tile_end);
              group.add(floor_tile_front);
            }
            
              // ฟังก์ชั่นสำหรับสร้างแผ่นไม้
              function createWoodPlank(width, height, depth, material) {
                const plankGeometry = new THREE.BoxGeometry(width, height, depth);
                return new THREE.Mesh(plankGeometry, material);
              }
  
              // ฟังก์ชั่นสำหรับสร้าง pallet
              function createPallet(width, height, depth, material) {
                const pallet = new THREE.Group();
                
                const plankHeight = height * 0.1; // ความหนาของแผ่นไม้
                const plankGap = height * 0.08; // ช่องว่างระหว่างแผ่นไม้
                
                const numberOfPlanks = Math.floor(height / (plankHeight + plankGap));
  
                const plankWidth = width;
                const plankDepth = depth / numberOfPlanks;
  
                material.transparent = true;
                material.opacity = actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? 1 : 0;
                material.depthWrite = actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? true : false;
                for (let i = 0; i < numberOfPlanks; i++) {
                  const plank1 = createWoodPlank(plankWidth, plankHeight, plankDepth, material);
                  const plank2 = createWoodPlank(plankWidth, plankHeight, plankDepth, material);
                  plank1.position.y = 1;
                  plank1.position.z = (i * (plankDepth + plankGap)) - (depth / 2) + (plankDepth / 2);
                  pallet.add(plank1);
                  plank2.position.y = 0; // วางอยู่ที่พื้น
                  plank2.position.z = (i * (plankDepth + plankGap)) - (depth / 2) + (plankDepth / 2);
                  pallet.add(plank2);
                }
  
                // สร้างขอบของ pallet
                const sideWidth = width * 0.1;
                const sideHeight = 1; // ขอบด้านข้าง
                const sideDepth = depth;
  
  
                const leftSide = createWoodPlank(sideWidth, sideHeight, sideDepth, material);
                leftSide.position.set(-width / 2 + sideWidth / 2, sideHeight / 2, 0.1);
                pallet.add(leftSide);
  
                const rightSide = createWoodPlank(sideWidth, sideHeight, sideDepth, material);
                rightSide.position.set(width / 2 - sideWidth / 2, sideHeight / 2, 0.1);
                pallet.add(rightSide);
  
                const middleSide = createWoodPlank(sideWidth, sideHeight, sideDepth, material);
                middleSide.position.set(0, sideHeight / 2, 0.1);
                pallet.add(middleSide);
  
                return pallet;
              }
  
              // ขนาดของ pallet
              const palletWidth = width_box / 1;
              const palletHeight = height_box * 0.1; 
              const palletDepth = depth_box / 1.2;
  
              // สร้าง material สำหรับไม้
              const woodMaterial = new THREE.MeshLambertMaterial({ color: '#3f4346' });
  
              // สร้าง pallet
              const pallet = createPallet(palletWidth, palletHeight, palletDepth, woodMaterial);
  
              // ตั้งค่าตำแหน่งของ pallet
              pallet.position.set(position_x_box, (2.5 / MUL_VALUE) + (rack.height_rack / rack.row_rack) * index, position_z_box);
  
              // เพิ่ม pallet เข้าไปในกลุ่ม
              group.add(pallet);
              // console.log('depth_box', depth_box);
              const box_product = new THREE.Mesh(
                new THREE.BoxGeometry(width_box / 1.1, height_box * 0.6, depth_box / 1.2),
                new THREE.MeshStandardMaterial({
                    color: index % 2 == 0 ? 0xf3bc83 : '#bf9a7b',
                    transparent: true,
                    // opacity: 1,
                    opacity: actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? 1 : 0,
                    depthWrite: actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? true : false
                })
              );
            
              // Position the box
              box_product.position.set(
                  position_x_box,
                  height_box / ((rack.height_rack / rack.row_rack)) + 0.25 / MUL_VALUE + (rack.height_rack / rack.row_rack) * index + (height_box * 0.6 / 2 + 0.5),
                  position_z_box
              );
              
              // Define the vertices for the lines
              const vertices = new Float32Array([
                  // Left edge
                  -width_box / 2.2, height_box * 0.3, -depth_box / 2.4,
                  -width_box / 2.2, height_box * 0.3, depth_box / 2.4,
              
                  // Right edge
                  width_box / 2.2, height_box * 0.3, -depth_box / 2.4,
                  width_box / 2.2, height_box * 0.3, depth_box / 2.4,
              
                  // Center top
                  -width_box / 2.2, height_box * 0.3, 0,
                  width_box / 2.2, height_box * 0.3, 0,
              ]);
            
            // Create the geometry and material for the lines
            const geometry = new THREE.BufferGeometry();
            geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
            const material = new THREE.LineBasicMaterial({ color: '#656668' });
            
            // Create the lines and add them to the scene
            const lines = new THREE.LineSegments(geometry, material);
            lines.position.copy(box_product.position);
            
            box_product.name = "box_product";
            if (rackListRef[_index_rackListRef].position_list[runIdIndex]) {
                box_product.userData = {
                    has_prod: true,
                    id: rackListRef[_index_rackListRef].position_list[runIdIndex].rp_id
                };
                rackListRef[_index_rackListRef].floor_tile_list
                    .filter(_filter => Array.isArray(_filter[`x-${position_x_box}_z-${position_z_box}`]))
                    .forEach(_forEach => {
                        Object.keys(_forEach).forEach((obj) => {
                            if (obj === `x-${position_x_box}_z-${position_z_box}`) {
                                _forEach[obj].push({ box: box_product, pos_product: rackListRef[_index_rackListRef].position_list[runIdIndex] });
                            }
                        });
                    });
            
                rackListRef[_index_rackListRef].position_list[runIdIndex].box = box_product;
            }
            
            myStateRef.current = rackListRef;
            
            if (_filter.length == 0) {
                box_product.visible = false;
                box_product.userData.has_prod = false;
            } else {
              if(actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined)){
                group.add(lines);
              }
            }
            
            group.add(box_product);
            runIdIndex++;
            
          }
        }
        // พื้น rack
        // const floor_rack = new THREE.Mesh(
        //   new THREE.BoxGeometry(
        //     rack.width_rack - width_pillar,
        //     0.5 / MUL_VALUE,
        //     rack.depth_rack - depth_pillar
        //   ),
        //   new THREE.MeshStandardMaterial({
        //     color: 0x33ffd7,
        //     // color: '#c26e0a',
        //     transparent: true,
        //     opacity: 1,
        //   })
        // );

        // กำหนดขนาดของ rack และคำนวณตำแหน่ง
        // const rackWidth = rack.width_rack - width_pillar;
        // const rackHeight = 0.5 / MUL_VALUE;
        // const rackDepth = rack.depth_rack - depth_pillar;

        // กำหนดระยะห่างระหว่างเส้นตรงกลางแนวตั้ง
        const numDivisions = 5;
        const spacing = rackWidth / (numDivisions + 1);

        // สร้างกลุ่มสำหรับเส้นทั้งหมด
        const linesGroup = new THREE.Group();
          
        if(actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined)) {  
          // สร้างกรอบด้านหน้า
          linesGroup.add(createThickLine(
            new THREE.Vector3(-rackWidth / 2, 0, rackDepth / 2),
            new THREE.Vector3(rackWidth / 2, 0, rackDepth / 2),
            0.3, 
            '#c26e0a' 
          ));
          linesGroup.add(createThickLine(
            new THREE.Vector3(rackWidth / 2, 0, rackDepth / 2),
            new THREE.Vector3(rackWidth / 2, 0, -rackDepth / 2),
            0.3, 
            '#c26e0a'
          ));
          linesGroup.add(createThickLine(
            new THREE.Vector3(rackWidth / 2, 0, -rackDepth / 2),
            new THREE.Vector3(-rackWidth / 2, 0, -rackDepth / 2),
            0.3, 
            '#c26e0a'
          ));
          linesGroup.add(createThickLine(
            new THREE.Vector3(-rackWidth / 2, 0, -rackDepth / 2),
            new THREE.Vector3(-rackWidth / 2, 0, rackDepth / 2),
            0.3, 
            '#c26e0a'
          ));
          // สร้างเส้นตรงกลางแนวตั้ง
          for (let i = 1; i <= numDivisions; i++) {
            const x = -rackWidth / 2 + spacing * i;
            linesGroup.add(createThickLine(
              new THREE.Vector3(x, 0, rackDepth / 2),
              new THREE.Vector3(x, 0, -rackDepth / 2),
              0.3, // ความหนาของเส้น
              '#c26e0a'
            ));
          }
          linesGroup.position.setX(0); // ตำแหน่งในแนว X
          linesGroup.position.setY(
            (0.25 / MUL_VALUE) + (rack.height_rack / rack.row_rack) * index
          );
          linesGroup.position.setZ(0);
        } else if(actionActive.btn === '2D' || viewMode === '2D') {
          const geometry = new THREE.PlaneGeometry(rackWidth, rackDepth);
          const material = new THREE.MeshBasicMaterial({ color: '#c26e0a', side: THREE.DoubleSide });
          const rectangle = new THREE.Mesh(geometry, material);

          // หมุนเพื่อให้สี่เหลี่ยมอยู่ในระนาบเดียวกับเส้นรอบกรอบที่คุณสร้างไว้
          linesGroup.rotation.x = Math.PI / 2;

          linesGroup.add(rectangle);
          linesGroup.position.setX(0); // ตำแหน่งในแนว X
          linesGroup.position.setY(1);
          linesGroup.position.setZ(0);
          // const loader = new FontLoader();
          // loader.load('https://threejs.org/examples/fonts/helvetiker_regular.typeface.json', function (font) {
          //     rackData.rack_list.forEach((rack, rackIndex) => {
          //         rack.position_list.forEach((position, positionIndex) => {
          //           console.log(position.rp_id === itemRack.rp_id);
          //             if (position.rp_id === itemRack.rp_id) {
          //                 console.log(position.position);
          
          //                 // Create text geometry only for the matching position
          //                 const textGeometry = new TextGeometry(position.position, { 
          //                     font: font,
          //                     size: 3, // Text size
          //                     height: 1, // Text height
          //                 });
          
          //                 const textMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });
          //                 const textMesh = new THREE.Mesh(textGeometry, textMaterial);
          
          //                 // Rotate text to face upwards
          //                 textMesh.rotation.x = -Math.PI / 2; // Rotate text to horizontal
          
          //                 // Compute the bounding box of the text to center it properly
          //                 textGeometry.computeBoundingBox();
          //                 const textWidth = textGeometry.boundingBox.max.x - textGeometry.boundingBox.min.x;
          //                 const textHeight = textGeometry.boundingBox.max.y - textGeometry.boundingBox.min.y;
          
          //                 // Position the text in the center of the corresponding rack position
          //                 textMesh.position.set(
          //                     itemRack.position.x - 4.5, // Centered along X
          //                     1.5, // Default Y position
          //                     itemRack.position.z + 1.5 // Centered along Z
          //                 );
          
          //                 // Add the text to the scene
          //                 scene.add(textMesh);
          //             }
          //         });
          //     });
          // });          
        }
        linesGroup.name = "floor";

        group.add(linesGroup);
      }

      for (let index = 0; index < rack.split_rack_z - 1; index++) {
        const wall_rack = new THREE.Mesh(
          new THREE.BoxGeometry(rack.width_rack, rack.height_rack, 0.1),
          new THREE.MeshStandardMaterial({
            // color: 0x353a39,
            color: 'white',
            transparent: true,
            opacity: 0.3,
            depthWrite: false,
          })
        );
        wall_rack.position.setY(rack.height_rack / 2);
        wall_rack.position.setZ(
          rack.config_position -
            rack.depth_rack / 2 +
            (rack.depth_rack / rack.split_rack_z) * (index + 1.06)
        );
        group.add(wall_rack);
      }
      // console.log('indexMain', indexMain);

      const pivotY = -(rack.height_rack / 2);
      const offset = pivotY - group.position.y;
      const remove_child_group2 = [];
      group.children.forEach((child, index_child) => {
        child.position.y += offset;

        if (child.name === "tile_front" || child.name === "tile_end") {
          remove_child_group2.push(child);
        }
      });
      group.position.y = rack.height_rack / 2;
      group.position.z = rack.depth_rack / 2 + (0.5 / MUL_VALUE) + range_width;
      group.updateMatrix();

      if (!is_return && rack.position) {
        group.position.x = rack.position.x;
        group.position.y = rack.position.y;
        group.position.z = rack.position.z;
      }

      if (!is_return && rack.rotation) {
        group.rotation.x = 0
        group.rotation.y = rack.rotation.y;
        group.rotation.z = 0
        group.rotation.isEuler = rack.rotation.isEuler;
      }

      group.addEventListener("click", (event) => {
        if (!axisYClicked) {
          // console.log('Group clicked!', rack);
          group.userData = rack_sub;
          // console.log('rackListParams', rackListParams);
          if (!viewOnly) {
            SetConfigShowDataRackPopup(rack_sub, false, rackListParams);
          }
          // console.log('group.id', group.id);

          settestRack({ dataList: group });
          testRack.dataList = group;
        }
      });

      /*
        กรณีที่ user คลิก object ก่อน แล้วค่อย มาเลือก tool จะ update state เพื่อ auto
        select tool ให้
      */
      if (
        testRack.dataList &&
        testRack.dataList.userData.rack_id === rack.rack_id
      ) {
        // console.log('in');
        SetConfigShowDataRackPopup(rack_sub, is_update, rackListParams);
        // if (rack.userData)
        group.userData = rack_sub;
        settestRack({ dataList: group });
        testRack.dataList = group;
      }

      interactionManager.add(group);

      rackListRef[_index_rackListRef].remove_child_group2 = remove_child_group2;
      rackListRef[_index_rackListRef].model = group;
      myStateRef.current = rackListRef;
      if (is_return) {
        return group;
      } else {
        const key_group = `rack_${group.id}`;
        obj_check_name_model[key_group] = key_group;
        group.name = key_group;
        if (rack.rack_id === "ba9a905a-1e43-4110-b356-a2fc9da7156f")
          group.rotation.y = -0.9;
        scene.add(group);
      }
  }

  function checkPosition(obj1, obj2) {
    const box1 = new THREE.Box3().setFromObject(obj1);
    // const box2 = new THREE.Box3().setFromObject(obj2);

    const pointToCheck = new THREE.Vector3(obj1.position.x, 0, obj1.position.z);
    if (!obj2.containsPoint(pointToCheck)) return "";

    const obj1Center = box1.getCenter(new THREE.Vector3());
    const obj2Center = obj2.getCenter(new THREE.Vector3());

    const diff = obj2Center.clone().sub(obj1Center);

    if (
      Math.abs(diff.x) > Math.abs(diff.y) &&
      Math.abs(diff.x) > Math.abs(diff.z)
    ) {
      if (diff.x > 0) {
        return "left";
      } else {
        return "right";
      }
    } else {
      if (diff.z > 0) {
        return "below";
      } else {
        return "above";
      }
    }
  }

  // console.log('rackData in wh3D', rackData);
  function SetConfigShowDataRackPopup(rackParams, is_update, rackListParams, rp_id) {
    // console.log('rackParams', rackParams);
    if (rackData.on_position_rp_id === rp_id && rackParams.rack_id === rackData.rack_id) return;
    let rack = rackParams;
    const temp = [...rackListParams];
    if (is_update) {
      const _index = rackListParams.findIndex(
        (el) => el.rack_id === rackParams.rack_id
      );
      if (_index >= 0) {
        temp[_index].width_rack = rack.width_rack;
        temp[_index].height_rack = rack.height_rack;
        temp[_index].depth_rack = rack.depth_rack;
      }
    }

    setswitchBtn({
      ...switchBtn,
      rack: true,
      wh: false,
    });
    switchBtn.rack = true;
    switchBtn.wh = false;

    setUseZAxis({
      is_use: rack.use_rack_axis_z,
    });
    useZAxis.is_use = rack.use_rack_axis_z;

    setrackData({
      ...rackData,
      rack_id: rack.rack_id,
      rack_name_input: rack.rack_name,
      width_input: rack.width_rack,
      depth_input: rack.depth_rack,
      height_input: rack.height_rack,
      axis_x_input: rack.rack_axis_x,
      axis_x_pos_input: rack.rack_position_format_x,
      axis_x_chr_input: rack.rack_separate_char_x,
      axis_y_input: rack.rack_axis_y,
      axis_y_pos_input: rack.rack_position_format_y,
      axis_y_chr_input: rack.rack_separate_char_y,
      axis_z_input: rack.rack_axis_z,
      axis_z_pos_input: rack.rack_position_format_z,
      axis_z_chr_input: rack.rack_separate_char_z,

      // when press cancel
      rack_name: rack.rack_name,
      width: rack.width_rack,
      depth: rack.depth_rack,
      height: rack.height_rack,
      axis_x: rack.rack_axis_x,
      axis_x_pos: rack.rack_position_format_x,
      axis_x_chr: rack.rack_separate_char_x,
      axis_y: rack.rack_axis_y,
      axis_y_pos: rack.rack_position_format_y,
      axis_y_chr: rack.rack_separate_char_y,
      axis_z: rack.rack_axis_z,
      axis_z_pos: rack.rack_position_format_z,
      axis_z_chr: rack.rack_separate_char_z,

      is_select_rack: true,
      position_list: rack.position_list,
      rack_list: [...temp],
      on_position_rp_id: rp_id,
    });
    rackData.rack_id = rack.rack_id;

    rackData.rack_name_input = rack.rack_name;
    rackData.width_input = rack.width_rack;
    rackData.depth_input = rack.depth_rack;
    rackData.height_input = rack.height_rack;
    rackData.axis_x_input = rack.rack_axis_x;
    rackData.axis_x_pos_input = rack.rack_position_format_x;
    rackData.axis_x_chr_input = rack.rack_separate_char_x;
    rackData.axis_y_input = rack.rack_axis_y;
    rackData.axis_y_pos_input = rack.rack_position_format_y;
    rackData.axis_y_chr_input = rack.rack_separate_char_y;
    rackData.axis_z_input = rack.rack_axis_z;
    rackData.axis_z_pos_input = rack.rack_position_format_z;
    rackData.axis_z_chr_input = rack.rack_separate_char_z;

    rackData.rack_name = rack.rack_name;
    rackData.width = rack.width_rack;
    rackData.depth = rack.depth_rack;
    rackData.height = rack.height_rack;
    rackData.axis_x = rack.rack_axis_x;
    rackData.axis_x_pos = rack.rack_position_format_x;
    rackData.axis_x_chr = rack.rack_separate_char_x;
    rackData.axis_y = rack.rack_axis_y;
    rackData.axis_y_pos = rack.rack_position_format_y;
    rackData.axis_y_chr = rack.rack_separate_char_y;
    rackData.axis_z = rack.rack_axis_z;
    rackData.axis_z_pos = rack.rack_position_format_z;
    rackData.axis_z_chr = rack.rack_separate_char_z;

    rackData.is_select_rack = true;
    rackData.position_list = rack.position_list;
    rackData.rack_list = [...temp];
    rackData.on_position_rp_id = rp_id;
    // console.log('rackData SetConfigShowDataRa/ckPopup', rackData);
  }

  // Functions to set zoom in state to true and request animation frame
  function startZoomIn() {
    zoomingIn = true;
  }

  // Functions to set zoom in state to false and cancel request animation frame
  function stopZoomIn() {
    zoomingIn = false;
  }

  // Functions to set zoom out state to true and request animation frame
  function startZoomOut() {
    zoomingOut = true;
  }

  // Functions to set zoom out state to false and cancel request animation frame
  function stopZoomOut() {
    zoomingOut = false;
  }

  // Functions to zoom in
  function onZoomIn() {
    if (zoomingIn) {
      cameraRef.current.getWorldDirection(dir);
      cameraRef.current.position.addScaledVector(dir, 1.0);
    }
  }

  // Functions to zoom out
  function onZoomIn() {
    if (zoomingIn) {
      cameraRef.current.getWorldDirection(dir);
      cameraRef.current.position.addScaledVector(dir, 10.0);
    }
  }

  // Functions to zoom out
  function onZoomOut() {
    if (zoomingOut) {
      cameraRef.current.getWorldDirection(dir);
      cameraRef.current.position.addScaledVector(dir, -10.0);
    }
  }

  // Function reset camera to traditional position
  function resetControls() {
    const camera = cameraRef.current.position;
    const targetPosition = new THREE.Vector3(40, 40, 40);
    camera.set(40, 40, 40);
  }

  // Functions to convert radians to degrees
  function radiansToDegrees(radians) {
    return radians * (180 / Math.PI);
  }

  function createagrid(opts) {
    var config = {
      height: opts.width || 500,
      width: opts.depth || 500,
      linesheight: opts.width || 500,
      lineswidth: opts.depth || 500,
      color: 0xdd006c,
    };

    var material = new THREE.LineBasicMaterial({
      color: config.color,
      opacity: 0.2,
    });

    const points = [];
    var gridobject = new THREE.Object3D(),
      gridgeo = new THREE.BufferGeometry(),
      stepw = /* 2 * */ config.width / config.lineswidth,
      steph = /* 2 * */ config.height / config.linesheight;

    //width
    for (var i = -config.width; i <= config.width; i += stepw) {
      points.push(new THREE.Vector3(-config.height, i, 0));
      points.push(new THREE.Vector3(config.height, i, 0));
    }
    //height
    for (var i = -config.height; i <= config.height; i += steph) {
      points.push(new THREE.Vector3(i, -config.width, 0));
      points.push(new THREE.Vector3(i, config.width, 0));
    }
    let geometry = new THREE.BufferGeometry().setFromPoints(points);
    geometry.rotateX(Math.PI * 0.5);
    geometry.rotateY(Math.PI * 0.5);
    var line = new THREE.LineSegments(geometry, material);
    line.position.z = (opts.depth || 500) / 2;
    // gridobject.add(line);

    return line;
  }

  const [widthPage, setWidthPage] = useState({
    data: "",
  });

  const [container, setContainer] = useState({
    data: null,
  });

  function clearRoom() {
    cancelAnimationFrame(animationFrameIdRef.current);
    initialData(0, 0, 0, true, rackData.rack_list);
  }

  function setForklift(forkliftModelPath, scene, loader, room_depth, room_width) {
    // console.log('modelForkliftRef', modelForkliftRef.current);
    const modelAnimate = [
      "mesh_0",
      "mesh_1",
      "mesh_2",
      "mesh_3",
      "mesh_4",
      "mesh_5",
      "mesh_6" /* ที่ยก */,
      "mesh_7" /* เสาเหล็ก */,
      "mesh_8" /* หมวก */,
      "mesh_11" /* ขา */,
      "mesh_12" /* เท้า */,
      "mesh_13" /* ตัว */,
      "mesh_14" /* มือ */,
      "mesh_17" /* แขน */,
      "mesh_18" /* หัวคน */,
      "mesh_19",
    ];

    // const loader = new THREE.GLTFLoader();
    loader.load(forkliftModelPath, function (gltf) {
      // gltf.scene.rotation.y = Math.PI;
      const temp_forklift = { ...modelForkliftRef.current };
      const model_forklift = gltf.scene.clone();

      model_forklift.traverse(function (child) {
        child.position.y = 0.36;
        child.rotation.y = Math.PI;

        if (child.name === "mesh_6") {
          child.position.y = 0.4;
        }
      });
      let unit =
        warehouseData.unit == "mm."
          ? 0.01
          : warehouseData.unit == "m."
          ? 1
          : 0.1;
      // console.log('temp_forklift', temp_forklift);
      for (const [key, value] of Object.entries(temp_forklift)) {
        if (key === "on_name_flk") continue;

        const forklift = temp_forklift[key];
        forklift.model = model_forklift.clone();

        // forklift.model.rotation.y = Math.PI;
        const bbox = new THREE.Box3().setFromObject(forklift.model);
        const currentSize = bbox.getSize(new THREE.Vector3()); // Get current size

        // Step 2: Calculate scale factors for desired size (width: 36, height: 10, depth: 24)
        const desiredSize = new THREE.Vector3(Number(forklift.fu_width) * unit, Number(forklift.fu_height) * unit, Number(forklift.fu_length) * unit);
        const scaleFactor = desiredSize.clone().divide(currentSize);

        const desiredSize2 = new THREE.Vector3(Number(forklift.fu_width) * unit, (Number(forklift.fu_height) * unit) / 1.75, Number(forklift.fu_length) * unit);
        const scaleFactor2 = desiredSize2.clone().divide(currentSize);

        // Step 3: Apply scaling
        // forklift.model.scale.copy(scaleFactor);

        forklift.pos_x = Number(forklift.fu_pos_x) || room_width / 2.5 * -1;
        forklift.pos_z = Number(forklift.fu_pos_z);
        forklift.pos_y_scoop = valueMinMaxSpoonFLK.min;

        forklift.model.position.x = Number(forklift.pos_x || room_width / 2.5 * -1);
        forklift.model.position.z = Number(forklift.pos_z);

        forklift.spoon_model = forklift.model.children[0].children.find(el =>
          el.name === "mesh_6"
        );
        // console.log('forklift.model.children[0].children :>> ', forklift.model.children[0].children);
        forklift.model.children[0].children.forEach(el => {
          if (el.name === "mesh_6" || el.name === "mesh_7") {
            el.position.y = valueMinMaxSpoonFLK.min;
            el.scale.copy(scaleFactor);
          } else {
            el.scale.copy(scaleFactor2);
          }
        });
        // console.log('forklift.spoon_model :>> ', forklift.spoon_model);
        let padding = 1.5;
        forklift.model.position.y += ((Number(forklift.fu_height) * unit) / 1.75 / 2 - padding);
        scene.add(forklift.model);
      }
    });
  }

  async function initialData(
    width,
    height,
    depth,
    isClear,
    rackListParams,
    positionListParams,
    viewMode,
    is_change_wh
  ) {
    
    let obj_check_name_model = {};
    lookAtRef.current = false;

    const position_camera = GetPositionCamera();
    // Initialize instances
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    const scene = new THREE.Scene();
    const container = document.getElementById("content-wrapper");
    // const win_width = window.innerWidth;
    // const win_height = window.innerHeight;
    const win_width = viewOnly ? container.clientWidth : window.innerWidth
    const win_height = viewOnly ? container.clientHeight : window.innerHeight
    const camera = new THREE.PerspectiveCamera(
      45,
      win_width / win_height,
      0.5,
      width * height * depth + 2000
    );
    const controls = new OrbitControls(camera, renderer.domElement);
    const trackballs = new TrackballControls(camera, renderer.domElement);
    const loader = new GLTFLoader();
    const destination = new THREE.Vector3(0, 0, 0);
    const interactionManager = new InteractionManager(
      renderer,
      camera,
      renderer.domElement
    );
    // Set up Renderer
    if (!container.data) {
      setContainer({
        data: { width: container.clientWidth, height: container.clientHeight },
      });
      container.data = {
        width: container.clientWidth,
        height: container.clientHeight,
      };
    }
    renderer.setSize(container.data.width, container.data.height);
    // console.log("container.data.width", container.data.width);
    setWidthPage({ data: container.data.width });
    widthPage.data = container.data.width;
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.VSMShadowMap;

    const currentFirstChild = canvasRef.current.firstChild;
    if (currentFirstChild !== renderer.domElement) {
      if (currentFirstChild) {
        canvasRef.current.removeChild(currentFirstChild);
      }
      canvasRef.current.appendChild(renderer.domElement);
    }

    // Set up Background
    scene.background = new THREE.Color(0xded5ff);
    // scene.fog = new THREE.Fog(0xded5ff, 60, 200);

    // Set up Lights
    SetUpLigthAndDirectionLight(scene);

    if (camera.position.x !== 0 || camera.position.y !== 0 || camera.position.z !== 0) {
      position_camera.x = camera.position.x;
      position_camera.y = camera.position.y;
      position_camera.z = camera.position.z;
    }
    if (is_change_wh) {
      changeWarehouseRef.current = true;
    }
    if (changeWarehouseRef.current === true || actionActive.btn === '2D' || viewMode === '2D' || viewMode === 'camera') {
        position_camera.x = 50;
        position_camera.y = 50;
        position_camera.z = 50;
        isFirstRunRef.current = true;
        changeWarehouseRef.current = false;
    }
    
    if (actionActive.btn === "camera" || actionActive.btn === '2D' || viewMode === '2D' || viewMode === 'camera') {
        if (isFirstRunRef.current) {
          console.log(isFirstRunRef.current);
            camera.position.set(
                (width / 5),
                (height / 2) * 20,
                (depth / 2)
            );
            // camera.lookAt(0, 0, 0);
            isFirstRunRef.current = false;
        } else if (actionActive.btn != '2D') {
            camera.position.set(
                position_camera.x,
                position_camera.y,
                position_camera.z
            );
            isFirstRunRef.current = false;
        }
    } else {
        if (camera.position.x !== 0 || camera.position.y !== 0 || camera.position.z !== 0) {
            position_camera.x = camera.position.x;
            position_camera.y = camera.position.y;
            position_camera.z = camera.position.z;
        }
        camera.position.set(
            position_camera.x,
            position_camera.y,
            position_camera.z
        );
        isFirstRunRef.current = false;
    }

    camera.layers.enableAll();

    // Configuration Controls
    SetConfigControlsAndTrackballs(controls, trackballs);
    controls.target.set(width / 5, 0, depth / 2);

    // Assign useRef
    controlsRef.current = controls;
    trackballsRef.current = trackballs;
    cameraRef.current = camera;

    // ตั้งค่าตำแหน่งเริ่มต้นของรถ forklift
    const _forklift = modelForkliftRef.current;
    // console.log('_forklift', _forklift);
    setForkliftName(_forklift.on_name_flk);
    if (
      !isClear &&
      Object.keys(_forklift).length !== 0 &&
      (actionActive.btn == "camera" || actionActive.btn == "2D")
    ) {
      // console.log('_forklift[_forklift.on_name_flk]', _forklift[_forklift.on_name_flk]);
      if (!_forklift[_forklift.on_name_flk].model) {
        setForklift(forkliftModelPath, scene, loader, depth, width);
      } else {
        if (actionActive.btn == "2D" || viewMode == '2D') {
          // ดึงข้อมูล forklift
          const temp_forklift = { ...modelForkliftRef.current };
          // ขนาดและจำนวนของวงกลม
          const radius = 3; // ขนาดของวงกลม
          const segments = 32;
      
          // สร้างวงกลมและตั้งค่า
          const objCircle = {};
          for (const [key, value] of Object.entries(temp_forklift)) {
            if(key === "on_name_flk" || !value.model) continue;
            // สร้างวงกลม
            const circleGeometry = new THREE.CircleGeometry(radius, segments);
            const circleMaterial = new THREE.MeshBasicMaterial({ color: 'red' });
            const circle = new THREE.Mesh(circleGeometry, circleMaterial);
    
            // หมุนวงกลมให้อยู่ในแนวนอน
            circle.rotation.x = -Math.PI / 2;
            
            // ตั้งตำแหน่งวงกลมให้ห่างจากจุดศูนย์กลาง
            // circle.position.set(value.pos_x, value.pos_y, value.pos_z);
            circle.position.set(value.model.position.x, 0, value.model.position.z);
            circle.position.y = 0.1
            objCircle[key] = circle;
            scene.add(circle);
            const arrowLength = 3;
            const arrowColor = 'black';

            // สร้างลูกศร
            const arrowHelper = new THREE.ArrowHelper(
              new THREE.Vector3(1, 0, 0),
              new THREE.Vector3(0, 0, 0), 
              arrowLength,
              arrowColor
            );
            arrowHelper.position.y = 0.1;
            // arrowHelper.rotation.z = -Math.PI / 2; 
            // เพิ่มลูกศรเข้าไปในวงกลม
            circle.add(arrowHelper);
          }
          modelCircleRef.current = objCircle;     
          console.log('modelCircleRef.current: ',modelCircleRef.current);
           
        } else if (actionActive.btn != "2D") {
          const temp_forklift = { ...modelForkliftRef.current };
          for (const [key, value] of Object.entries(temp_forklift)) {
              const forklift = temp_forklift[key];
              if (forklift.model) scene.add(forklift.model);
          }
        }
      }
    }

    // Add grid
    // method 1
    // scene.add(createagrid({ width, height, depth }));
    // method 2
    /* const gridHelper = new THREE.GridHelper(width * 100, depth * 100, 0x888888, 0x444444);
		scene.add( gridHelper ); */

    // Create Ground Geometry
    // console.log(' width, height, depth',  width, height, depth)
    CreateGroundGeometry(scene, width, height, depth);

    // Add Rack
    // console.log('rackListParams.length', rackListParams.length);
    const rack_old_list = [];
    let unit = rackData.unit == "mm." ? 0.01 : rackData.unit == "m." ? 1 : 0.1;
    if (rackListParams.length > 0 && !isClear) {
      let range_width = 0;
      // console.log("rackListParams", rackListParams);
      for (let index = 0; index < rackListParams.length; index++) {
        const element = rackListParams[index];

        if (element.groupObj) {
          
          const _RACK = await addRackModalConfig(
            0,
            scene,
            element.groupObj.userData,
            interactionManager,
            true,
            element.groupObj.scale.x,
            element.groupObj.scale.y,
            element.groupObj.scale.z,
            true, // update w h d rack
            positionListParams,
            rackListParams
          );
          // console.log('_RACK', _RACK)
          _RACK.rotation.x = element.groupObj.rotation.x;
          _RACK.rotation.y = element.groupObj.rotation.y;
          _RACK.rotation.z = element.groupObj.rotation.z;
          _RACK.rotation.order = element.groupObj.rotation.order;

          _RACK.position.x = element.groupObj.position.x;
          _RACK.position.y =
            ((element.groupObj.userData.height_rack * unit) * element.groupObj.scale.y) /
            2;
          _RACK.position.z = element.groupObj.position.z;

          element.groupObj.userData.width_rack =
            (element.groupObj.userData.width_rack) * element.groupObj.scale.x;
          element.groupObj.userData.height_rack =
            (element.groupObj.userData.height_rack) * element.groupObj.scale.y;
          element.groupObj.userData.depth_rack =
            (element.groupObj.userData.depth_rack) * element.groupObj.scale.z;
          _RACK.userData = element.groupObj.userData;

          const key_group = `rack_${_RACK.id}`;
          obj_check_name_model[key_group] = key_group;
          _RACK.name = key_group;

          rack_old_list.push(key_group);
          interactionManager.add(_RACK);
          scene.add(_RACK);
          range_width += element.depth_rack + 3;
          continue;
        }

        addRackModalConfig(
          range_width,
          scene,
          element,
          interactionManager,
          false,
          null,
          null,
          null,
          false,
          positionListParams,
          rackListParams,
          obj_check_name_model,
          viewMode
        );
        range_width += element.depth_rack + 3;
      }
    }
    // interactionManager.add(scene);

    // Add Axes Helper
    const axesHelper = new THREE.AxesHelper(15);
    scene.add(axesHelper);

    // if (true ) {
    //     const box = new THREE.Box3(
    //       new THREE.Vector3(110.68660201298857, -0.13126527834744017, 306.15453035945274),  // min
    //       new THREE.Vector3(123.84637180891286, 35.150000005960464, 331.4071142493007)   // max
    //   );
      
    //   // Step 2: Compute the size of the box
    //   const size = new THREE.Vector3();
    //   box.getSize(size);
      
    //   // Step 3: Create the BoxGeometry using the size of the Box3
    //   const geometry = new THREE.BoxGeometry(size.x, size.y, size.z);
      
    //   // Step 4: Position the geometry at the center of the Box3
    //   const center = new THREE.Vector3();
    //   box.getCenter(center);
    //   geometry.translate(center.x, center.y, center.z);
      
    //   // Step 5: Create a mesh with the geometry and a material
    //   const material = new THREE.MeshBasicMaterial({ color: 0x00ff00, wireframe: true });
    //   const mesh = new THREE.Mesh(geometry, material);
      
    //   // Step 6: Add the mesh to the scene
    //   scene.add(mesh);
    // }

    // CreateRoom
    // console.log('width, height, depth', width, height, depth);
    CreateRoom(width, height, depth, scene, obj_check_name_model, viewMode);

    function onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    }

    const t_controls = new TransformControls(camera, renderer.domElement);
    t_controls.addEventListener("mouseUp", async (event) => {
      setTimeout(() => {
        axisYClicked = false;
      }, 100);
      // console.log('event.target.axis', event.target.axis);
      if (testRack.dataList && (actionActive.btn != "camera" || actionActive.btn !== "2D")) {
        const temp = { ...actionListRack.data };
        if (
          testRack.dataList.userData.rack_id &&
          !temp[testRack.dataList.userData.rack_id]?.actionList.includes(
            actionActive.btn
          )
        ) {
          temp[testRack.dataList.userData.rack_id]?.actionList.push(
            actionActive.btn
          );
          setActionListRack({ ...actionListRack, data: { ...temp } });
        }
      }

      if (testRack.dataList && actionActive.btn == "scale") {
        // console.log('testRack.dataList', testRack.dataList)
        groupObjSelect = scene.getObjectById(testRack.dataList.id);
        if (groupObjSelect) {
          t_controls.remove(groupObjSelect);
          // console.log('groupObjSelect----', groupObjSelect)

          const _RACK = await addRackModalConfig(
            0,
            scene,
            groupObjSelect.userData,
            interactionManager,
            true,
            groupObjSelect.scale.x,
            groupObjSelect.scale.y,
            groupObjSelect.scale.z,
            true, // update w h d rack
            positionListParams,
            rackListParams
          );
          _RACK.rotation.x = groupObjSelect.rotation.x;
          _RACK.rotation.y = groupObjSelect.rotation.y;
          _RACK.rotation.z = groupObjSelect.rotation.z;
          _RACK.rotation.order = groupObjSelect.rotation.order;

          _RACK.position.x = groupObjSelect.position.x;
          _RACK.position.y =
            ((groupObjSelect.groupObj.userData.height_rack * unit) * groupObjSelect.scale.y) / 2;
          _RACK.position.z = groupObjSelect.position.z;

          groupObjSelect.userData.width_rack =
            groupObjSelect.userData.width_rack * groupObjSelect.scale.x;
          groupObjSelect.userData.height_rack =
            groupObjSelect.userData.height_rack * groupObjSelect.scale.y;
          groupObjSelect.userData.depth_rack =
            groupObjSelect.userData.depth_rack * groupObjSelect.scale.z;
          _RACK.userData = groupObjSelect.userData;

          settestRack({
            dataList: _RACK,
          });
          testRack.dataList = _RACK;
          const mode_controls = GetModeTransFormControls();

          t_controls.setMode(mode_controls);
          if (mode_controls == "translate") {
            t_controls.showY = false;
          } else if (mode_controls == "rotate") {
            t_controls.showZ = false;
            t_controls.showX = false;
          }

          scene.add(_RACK);
          scene.remove(groupObjSelect);
        }
      }
    });

    t_controls.addEventListener("mouseDown", async (event) => {
      axisYClicked = true;
    });

    if (!offsetSceneRef.current) {
      const pivotX = width / 2;
      // const offset = pivotX - group.position.y;
      // console.log('obj_check_name_model', obj_check_name_model);
      scene.children.forEach((child) => {
        if (
          obj_check_name_model[child.name] ===
          "extendFloor" /* && !rack_old_list.includes(obj_check_name_model[child.name] )*/
        ) {
          child.position.x += pivotX;
        }
      });
    }
    offsetSceneRef.current = false;

    function animate() {
      animationFrameIdRef.current = requestAnimationFrame(animate);

      const time = performance.now();
      frames++;

      if (time >= prevTime + 1000) {
        setFPS(Math.round((frames * 1000) / (time - prevTime)));

        frames = 0;
        prevTime = time;
      }

      SetConfigAnimateRack(scene, t_controls);
      // if (actionActive.btn == "2D") {
      //   controls.enablePan = false;
      // }

      if (actionActive.btn !== "camera" || (viewMode !== "camera" && viewMode)) {
        controls.enableRotate = false;
      } else {
        controls.enableRotate = true;
      }
      controlsRef.current = controls;

      angle = radiansToDegrees(controls.getAzimuthalAngle());

      cameraRef.current.updateProjectionMatrix();

      if (compassRef.current)
        compassRef.current.style.transform = `rotate(${angle}deg)`;

      /* destination.set(xRef.current.toFixed(2), 0, zRef.current.toFixed(2));


      if (modelRef.current) {
        modelRef.current.position.lerp(destination, 0.1);

        SetDestinationOfForklift(destination);
      } */
      const _forklift = modelForkliftRef.current;
      if (
        _forklift.on_name_flk &&
        _forklift[_forklift.on_name_flk].pos_x !== undefined &&
        (actionActive.btn == "camera" || actionActive.btn == "2D")
      ) {
        // console.log('_forklift', _forklift[_forklift.on_name_flk].pos_x);
        const pos_x_const = _forklift[_forklift.on_name_flk].pos_lock_x && false ? _forklift[_forklift.on_name_flk].pos_lock_x : _forklift[_forklift.on_name_flk].pos_x;
        const pos_z_const = _forklift[_forklift.on_name_flk].pos_lock_z && false ? _forklift[_forklift.on_name_flk].pos_lock_z : _forklift[_forklift.on_name_flk].pos_z;
        // _forklift[_forklift.on_name_flk].pos_lock_z = null;
        // console.log('_forklift[_forklift.on_name_flk].pos_x :>> ', _forklift[_forklift.on_name_flk].pos_x);
        destination.set(
          /* parseInt */(Number(pos_x_const)),
          0,
          /* parseInt */(Number(pos_z_const))
        );
        if (_forklift.on_name_flk && _forklift[_forklift.on_name_flk].model) {
          // console.log('innnnnnnnnn');
          /* _forklift[_forklift.on_name_flk].model.position.lerp(
            destination,
            0.1
          ); */
          // moveAndRotateForklift(_forklift[_forklift.on_name_flk].model, pos_x_const, pos_z_const);
          // console.log('destination :>> ', destination.x, destination.z);
          SetDestinationOfForklift3(destination);
        }
      }

      const target = controls.target;
      controls.update();
      trackballs.target.set(target.x, target.y, target.z);
      trackballs.update();
      interactionManager.update();
      renderer.render(scene, camera);
    }

    window.addEventListener("resize", onWindowResize);
    document
      .getElementById("content-wrapper")
      .addEventListener("click", updatePositionRack);
    animate();

    // Dispose when components unmounts
    return () => {
      window.removeEventListener("resize", onWindowResize);
      document
        .getElementById("content-wrapper")
        .removeEventListener("click", updatePositionRack);
      renderer.clear();
      renderer.dispose();
      if (trackballsRef.current) {
        trackballsRef.current.dispose();
      }
    };
  }

  function SetConfigAnimateRack(scene, t_controls) {
    if (testRack.dataList) {
      if (
        (actionActive.btn == "move" ||
          actionActive.btn == "rotate" ||
          actionActive.btn == "scale") &&
        testRack.dataList &&
        scene.getObjectById(testRack.dataList.id)
      ) {
        groupObjSelect = scene.getObjectById(testRack.dataList.id);
        // console.log('groupObjSelect', groupObjSelect)

        const mode_controls = GetModeTransFormControls();

        t_controls.setMode(mode_controls);
        if (mode_controls == "translate") {
          t_controls.showY = false;
        } else if (mode_controls == "rotate") {
          t_controls.showZ = false;
          t_controls.showX = false;
        }
        t_controls.attach(testRack.dataList);
        scene.add(t_controls);
      } else if (scene.children.includes(t_controls)) {
        scene.remove(t_controls);
      }
    } else {
      if (scene.children.includes(t_controls)) {
        scene.remove(t_controls);
      }
    }
  }

  function move2AndRotateForklift(forklift, targetX, targetZ) {
    // Step 1: Calculate the direction vector from the forklift to the target
    const forkliftPosition = forklift.position.clone();
    const targetPosition = new THREE.Vector3(targetX, forklift.position.y, targetZ);

    // Direction vector from forklift to the target
    const direction = targetPosition.clone().sub(forkliftPosition);

    // Step 2: Calculate the desired angle (in radians) to face the target
    const desiredAngle = Math.atan2(direction.z, direction.x); // Adjusted axis for correct facing direction

    // Step 3: Calculate the difference between the current rotation and the desired rotation
    let angleDifference = desiredAngle - forklift.rotation.y;

    // Normalize the angle difference to the range [-Math.PI, Math.PI] for shortest rotation path
    angleDifference = ((angleDifference + Math.PI) % (2 * Math.PI)) - Math.PI;

    // Step 4: Apply a small rotation step to smoothly rotate the forklift
    const rotationStep = 100; // Adjust this value to control rotation speed
    if (Math.abs(angleDifference) > rotationStep) {
        forklift.rotation.y += Math.sign(angleDifference) * rotationStep;
    } else {
        forklift.rotation.y = desiredAngle; // Snap to the desired angle when close enough
    }

    // Step 5: Move the forklift towards the target
    const moveDistance = 0.5; // Adjust this value based on how fast you want the forklift to move
    const moveVector = direction.normalize().multiplyScalar(moveDistance);
    forklift.position.add(moveVector);
  }

  const targetRef = useRef({ targetX: 0, targetZ: 0 });
  function moveAndRotateForklift(forklift, targetX, targetZ, not_rotate) {
    if (targetX != targetRef.current.targetX || targetZ != targetRef.current.targetZ) {
      targetRef.current.targetX = targetX;
      targetRef.current.targetZ = targetZ;
      // Step 1: Calculate the direction vector from the forklift to the target
      const forkliftPosition = forklift.position.clone();
      const targetPosition = new THREE.Vector3(targetX, forklift.position.y, targetZ);
  
      // Direction vector from forklift to the target
      const direction = targetPosition.clone().sub(forkliftPosition);
      // Step 2: Rotate the forklift to face the target
      if (!not_rotate) {
        const angle = Math.atan2(direction.z, direction.x); // Adjust axis for correct facing direction
        forklift.rotation.y = angle; // Set the forklift's Y rotation to face the target
      }
  
      // Step 4: Move the forklift towards the target
      const moveDistance = 1; // Adjust this value based on how fast you want the forklift to move
      const moveVector = direction.multiplyScalar(moveDistance) // .normalize().multiplyScalar(moveDistance);
      forklift.position.add(moveVector);
      if (actionActive.btn === '2D' || viewMode === '2D') {
        const _circle = modelCircleRef.current[modelForkliftRef.current.on_name_flk];        
        _circle.position.add(moveVector);
        
        if (!not_rotate) {
          const arrow = _circle.children.find(child => child instanceof THREE.ArrowHelper);
          if (arrow) {
            arrow.rotation.z = -angle - Math.PI / 2; 
          }
        }
      }
    }
  }



  function SetDestinationOfForklift3(destination) {
    const _forklift = modelForkliftRef.current[modelForkliftRef.current.on_name_flk].model;
    if (!_forklift) return;

    function collisionFunction(
      _forklift,
      rack_collision,
      mul,
      element,
      collision,
      pos_box,
      front_back_tile,
      is_swap,
      rotation_y
    ) {
      collision = true;
      const model_forklift =
        modelForkliftRef.current[modelForkliftRef.current.on_name_flk];
        // console.log('mul :>> ', Math.PI * mul);
      _forklift.rotation.y = Math.PI * mul;
      const degree = !rotation_y ? 0 : rotation_y * 180 / Math.PI;
      if (rotation_y && (Math.abs(degree) > 100 || Math.abs(degree) < 80)) {
        // const int_num = parseInt(rotation_y)
        // console.log(' (rotation_y - int_num) :>> ',  (rotation_y - int_num));
        _forklift.rotation.y -= (rotation_y);
        // console.log('_forklift.rotation.y :>> ', _forklift.rotation.y);
      }
      model_forklift.model.rotation.y = _forklift.rotation.y;
      // _forklift.position.z = _forklift.position.z + 15
      let pos_x = 0;
      let pos_z = 0;
      if (front_back_tile === 'tile_front') {
        pos_x = is_swap ? (pos_box.max.x + pos_box.min.x) / 2 : (pos_box.min.x + pos_box.max.x) / 2
        pos_z = (pos_box.max.z + pos_box.min.z) / 2
      } else {
        pos_x = (pos_box.max.x + pos_box.min.x) / 2
        pos_z = is_swap ? ((pos_box.max.z + pos_box.min.z) / 2) : ((pos_box.max.z + pos_box.min.z) / 2)
      }
      model_forklift.pos_lock_x = pos_x;
      model_forklift.pos_x = pos_x;
      _forklift.position.x = pos_x;

      model_forklift.pos_lock_z = pos_z;
      model_forklift.pos_z = pos_z;
      _forklift.position.z = pos_z

      if (actionActive.btn == "2D") {
        modelCircleRef.current[modelForkliftRef.current.on_name_flk].position.x = pos_x;
        modelCircleRef.current[modelForkliftRef.current.on_name_flk].position.z = pos_z;
      }

      modelForkliftRef.current[modelForkliftRef.current.on_name_flk] =
        model_forklift;
      /* 
      model_forklift.pos_x = pos_x
      _forklift.position.x = pos_x */
      return collision;
    }
    function setCurrentCollision(element, pos_func, on_rack_id, element2, index2) {
      modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_collision = element.floor_tile_list[index2].tile.userData.id;
      modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_el_collision = element;
      modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_el_collision_sup = element;
      modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_direction = pos_func;
      modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_on_rack = on_rack_id;
      modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_on_side = element2.name;
      modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_on_tileindex = index2;
    }

    // console.log('Math.abs(_forklift.position.z - modelForkliftRef.current[modelForkliftRef.current.on_name_flk].pos_z) :>> ', Math.abs(_forklift.position.z - modelForkliftRef.current[modelForkliftRef.current.on_name_flk].pos_z));
    // console.log('Math.abs(_forklift.position.x - modelForkliftRef.current[modelForkliftRef.current.on_name_flk].pos_x) :>> ', Math.abs(_forklift.position.x - modelForkliftRef.current[modelForkliftRef.current.on_name_flk].pos_x));
    // console.log('lookAtRef.current :>> ', lookAtRef.current);
    if (
      lookAtRef.current &&
      (Math.abs(_forklift.position.x - modelForkliftRef.current[modelForkliftRef.current.on_name_flk].pos_x) > 0.1 ||
        Math.abs(_forklift.position.z - modelForkliftRef.current[modelForkliftRef.current.on_name_flk].pos_z) > 0.1
      )
    ) {

      if (modelForkliftRef.current.on_name_flk)
        modelForkliftRef.current[modelForkliftRef.current.on_name_flk].pos_y_scoop = modelForkliftRef.current[modelForkliftRef.current.on_name_flk].pos_y_scoop_sup;
      let collision = false;
      let box_found = [];
      let rack_collision = null;
      const dup__forklift = _forklift.clone();
      const _child = modelForkliftRef.current[modelForkliftRef.current.on_name_flk].spoon_model; // dup__forklift.children[0].children.find(el => el.name == "mesh_6");
      const boxForklift = new THREE.Box3().setFromObject(dup__forklift);

      for (let index = 0; index < myStateRef.current.length; index++) {
        const element = myStateRef.current[index];
        if (element.floor_tile_list.length === 0) continue;
        if (!_child) break;
        if (modelForkliftRef.current[modelForkliftRef.current.on_name_flk]?.current_on_rack && modelForkliftRef.current[modelForkliftRef.current.on_name_flk]?.current_on_rack !== element.rack_id) continue;

        element.index_rack = index;

        dup__forklift.position.x = modelForkliftRef.current[modelForkliftRef.current.on_name_flk].pos_x;
        dup__forklift.position.z = modelForkliftRef.current[modelForkliftRef.current.on_name_flk].pos_z;
        let boxForklift2 = new THREE.Box3().setFromObject(dup__forklift);

        const boxRack = new THREE.Box3().setFromObject(element.model);
        for (
          let index2 = 0;
          index2 < element.floor_tile_list.length;
          index2++
        ) {
          const element2 = element.floor_tile_list[index2].tile;
          const boxTile = new THREE.Box3().setFromObject(element2);

          if (boxTile.intersectsBox(boxForklift2) && (modelForkliftRef.current[modelForkliftRef.current.on_name_flk]?.current_collision !== element.floor_tile_list[index2].tile.userData?.id)) {
            if (modelForkliftRef.current[modelForkliftRef.current.on_name_flk]?.current_on_side && modelForkliftRef.current[modelForkliftRef.current.on_name_flk]?.current_on_side !== element2.name) continue;
            // console.log('element :>> ', element);
            box_found.push({
              boxTile,
              boxRack,
              element,
              element2,
              index2,
              on_rack_id: element.rack_id
            });
          }
        }
        // if (box_found.length > 0) {
        //   break;
        // }
      }
      // console.log('box_found :>> ', box_found);
      if (box_found.length === 0) {

        modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_collision = null;
        modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_el_collision = null;
      }

      let boxRack = null, element = null, element2 = null, boxTile = null, index2 = null, on_rack_id = null;
      if (box_found.length == 1 && _child) {
        let pos_func = checkPosition(dup__forklift, box_found[0].boxRack);
        // console.log('pos_func :>> ', pos_func);
        if (pos_func) {
          boxTile = box_found[0].boxTile;
          boxRack = box_found[0].boxRack;
          element = box_found[0].element;
          element2 = box_found[0].element2;
          index2 = box_found[0].index2;
          on_rack_id = box_found[0].on_rack_id;
        }
      } else if (box_found.length > 0 && _child) {
        // console.log('pos_func else if (box_found.length > 0 && _child) :>> ', box_found);
        const forklift_pos = new THREE.Box3().setFromObject(dup__forklift)/* dup__forklift.position */;
        let min_distance = Infinity;
        // console.log('box_found', box_found);
        box_found.forEach((el) => {
          let pos_func = checkPosition(dup__forklift, el.boxRack);
          if (pos_func) {

            const box_pos = el.boxTile;
            
            const centerbox_pos = new THREE.Vector3();
            box_pos.getCenter(centerbox_pos);
            const centerForklift = new THREE.Vector3();
            forklift_pos.getCenter(centerForklift);
            // console.log('forklift_pos :>> ', forklift_pos);
            const distance = centerForklift.distanceTo(centerbox_pos);
            // console.log('distance :>> ', distance);
            if (distance < min_distance) {
              min_distance = distance;
              boxTile = el.boxTile;
              boxRack = el.boxRack;
              element = el.element;
              element2 = el.element2;
              index2 = el.index2;
              on_rack_id = el.on_rack_id;
            }
          }
        });
      }
      // console.log('on index :>> ', index2);
      // console.log('modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_on_tileindex :>> ', modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_on_tileindex);
      console.log('boxRack && element && element2 && boxTile && index2 !== null :>> ', boxRack && element && element2 && boxTile && index2 !== null);
      console.log('boxTile :>> ', boxTile);
      console.log('modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_el_collision_sup :>> ', modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_el_collision_sup);
      if (boxRack && element && element2 && boxTile && index2 !== null) {
        // console.log('boxRack :>> ', boxRack);
        let pos_func = checkPosition(_forklift, boxRack);
        // console.log('pos_func222 :>> ', pos_func);
        const is_not_collision = (pos_func == "")
        if (modelForkliftRef.current[modelForkliftRef.current.on_name_flk]?.current_direction) pos_func = modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_direction;
        if (is_not_collision) {
          modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_collision = null;
          modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_on_tileindex = null;
          modelForkliftRef.current[modelForkliftRef.current.on_name_flk].pos_lock_x = null;
          modelForkliftRef.current[modelForkliftRef.current.on_name_flk].pos_lock_z = null;

        }
        // console.log('pos_func :>> ', pos_func);
        const current_on_tileindex = modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_on_tileindex;
        // console.log('element :>> ', element);
        const rotate_y = element.model.rotation.y;
        // console.log('rotate_y :>> ', rotate_y);
        // console.log('current_on_tileindex :>> ', current_on_tileindex);

        // console.log('directionRef.current :>> ', directionRef.current);
        if (rotate_y == 0 || (rotate_y > 0 && rotate_y < 1)) {
          // console.log('rotate_y :>> ', rotate_y);
          // console.log((directionRef.current == "w" &&  index2 > current_on_tileindex) || (directionRef.current == "s" &&  index2 < current_on_tileindex));
          if (current_on_tileindex == null || (directionRef.current == "w" && index2 > current_on_tileindex) || (directionRef.current == "s" &&  index2 < current_on_tileindex)) {
            // console.log('pos_func :>> ', pos_func);
            if (pos_func == "above") {
              // console.log('in above top :>> ');

              setCurrentCollision(element, pos_func, on_rack_id, element2, index2);
              collision = collisionFunction(
                _forklift,
                rack_collision,
                -0.5,
                element,
                collision,
                boxTile,
                element2.name,
                true,
                rotate_y
              );
            }
            if (pos_func == "below") {
              // console.log('in below top :>> ');

              setCurrentCollision(element, pos_func, on_rack_id, element2, index2);
              collision = collisionFunction(
                _forklift,
                rack_collision,
                0.5,
                element,
                collision,
                boxTile,
                element2.name,
                false,
                rotate_y
              );
            }
          }
        } else if (rotate_y < 0 && rotate_y > -1) {
          if (current_on_tileindex == null || (directionRef.current == "s" && index2 < current_on_tileindex) || (directionRef.current == "w" &&  index2 > current_on_tileindex)) {
            if (pos_func == "above") {
              // console.log('in above bottom :>> ');
              
              setCurrentCollision(element, pos_func, on_rack_id, element2, index2);
              collision = collisionFunction(
                _forklift,
                rack_collision,
                -0.5,
                element,
                collision,
                boxTile,
                element2.name,
                false,
                rotate_y
              );
            }
            if (pos_func == "below") {
              // console.log('in below bottom :>> ');
              
              setCurrentCollision(element, pos_func, on_rack_id, element2, index2);
              collision = collisionFunction(
                _forklift,
                rack_collision,
                0.5,
                element,
                collision,
                boxTile,
                element2.name,
                false,
                rotate_y
              );
            }
          }
        } else if (rotate_y == 1 || (rotate_y > 1 && rotate_y < 2)) {
          if (current_on_tileindex == null || (directionRef.current == "a" && index2 > current_on_tileindex) || (directionRef.current == "d" &&  index2 < current_on_tileindex)) {
            if (pos_func == "left") {
              // console.log('in left top :>> ');
              // const _childRack = modelForkliftRef.current[modelForkliftRef.current.on_name_flk].spoon_model; // modelForkliftRef.current[modelForkliftRef.current.on_name_flk].model.children[0].children.find(el => el.name == "mesh_6");
              // new THREE.Box3().setFromObject(modelForkliftRef.current[modelForkliftRef.current.on_name_flk].model)
              let is_intersect = false;
              // const future_model = _forklift.clone();
              // if (_forklift) {
              //   future_model.rotation.y = Math.PI * 2;
              //   const rack_clone = element.model.clone()

              //   const remove_child = [];
              //   rack_clone.children.forEach(child => {
              //     if (child.name == 'tile_end' || child.name == 'tile_front') {
              //       remove_child.push(child);
              //     }
              //   });
              //   remove_child.forEach(child => rack_clone.remove(child));

              //   const box_rack = new THREE.Box3().setFromObject(rack_clone);
              //   console.log('box_rack :>> ', box_rack);
              //   future_model.traverse(function (child) {
              //     if (child.name === "mesh_6") {
              //       const box = new THREE.Box3().setFromObject(child);
              //       if (box_rack.intersectsBox(box)) is_intersect = true;
              //     }
              //   })
              // }
              // console.log('is_intersect :>> ', is_intersect);
              setCurrentCollision(element, pos_func, on_rack_id, element2, index2);
              // modelForkliftRef.current[modelForkliftRef.current.on_name_flk].model.traverse(function (child) {
              //   child.rotation.y = Math.PI * 1;
              // });
              /* if (is_intersect) {
                modelForkliftRef.current[modelForkliftRef.current.on_name_flk].model.rotation.y = Math.PI * 1;
              } else {
                modelForkliftRef.current[modelForkliftRef.current.on_name_flk].model.rotation.y = Math.PI * 1;
              } */
              // console.log('is_intersect :>> ', is_intersect);
              collision = collisionFunction(
                _forklift,
                rack_collision,
                2,
                element,
                collision,
                boxTile,
                element2.name,
                false,
                rotate_y
              );
            }
            if (pos_func == "right") {
              // console.log('in right top :>> ');
              
              setCurrentCollision(element, pos_func, on_rack_id, element2, index2);
              collision = collisionFunction(
                _forklift,
                rack_collision,
                1,
                element,
                collision,
                boxTile,
                element2.name,
                false,
                rotate_y
              );
            }
          }
        } else if (rotate_y < -1 && rotate_y > -2) {
          if (current_on_tileindex == null || (directionRef.current == "a" && index2 < current_on_tileindex) || (directionRef.current == "d" &&  index2 > current_on_tileindex)) {
            if (pos_func == "left") {
              // console.log('in left bottom :>> ');
              
              // let is_intersect = false;
              // console.log('_forklift.rotation :>> ', _forklift.rotation);
              // console.log('modelForkliftRef.current[modelForkliftRef.current.on_name_flk].model.rotation :>> ', modelForkliftRef.current[modelForkliftRef.current.on_name_flk].model.rotation);
              // const future_model = _forklift.clone();
              // if (_forklift) {
              //   future_model.rotation.y = Math.PI * 1;
              //   const rack_clone = element.model.clone()

              //   const remove_child = [];
              //   rack_clone.children.forEach(child => {
              //     if (child.name == 'tile_end' || child.name == 'tile_front') {
              //       remove_child.push(child);
              //     }
              //   });
              //   remove_child.forEach(child => rack_clone.remove(child));

              //   const box_rack = new THREE.Box3().setFromObject(rack_clone);
              //   console.log('box_rack :>> ', box_rack);
              //   future_model.traverse(function (child) {
              //     if (child.name === "mesh_6") {
              //       const box = new THREE.Box3().setFromObject(child);
              //       if (box_rack.intersectsBox(box)) is_intersect = true;
              //     }
              //   })
              // }
              // console.log('is_intersect :>> ', is_intersect);
              setCurrentCollision(element, pos_func, on_rack_id, element2, index2);
              collision = collisionFunction(
                _forklift,
                rack_collision,
                /* is_intersect ? 1 :  */2,
                element,
                collision,
                boxTile,
                element2.name,
                false,
                rotate_y
              );
            }
            if (pos_func == "right") {
              // console.log('in right bottom :>> ');
              
              setCurrentCollision(element, pos_func, on_rack_id, element2, index2);
              collision = collisionFunction(
                _forklift,
                rack_collision,
                1,
                element,
                collision,
                boxTile,
                element2.name,
                false,
                rotate_y
              );
            }
          }
        }
      } else if (modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_on_tileindex !== null && modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_on_tileindex !== undefined && modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_el_collision_sup) {
        const tile_index = modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_on_tileindex;
        const el = modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_el_collision_sup;
        // el.floor_tile_list[tile_index].tile
        const box1 = new THREE.Box3().setFromObject(el.floor_tile_list[tile_index].tile);
        const pointToCheck = new THREE.Vector3(dup__forklift.position.x, 0, dup__forklift.position.z);
        if (!box1.containsPoint(pointToCheck)) {
          // modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_collision = null;
          modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_on_tileindex = null;
          modelForkliftRef.current[modelForkliftRef.current.on_name_flk].pos_lock_x = null;
          modelForkliftRef.current[modelForkliftRef.current.on_name_flk].pos_lock_z = null;
        }
      }

      modelForkliftRef.current[modelForkliftRef.current.on_name_flk].collision =
        collision;
      // modelForkliftRef.current[modelForkliftRef.current.on_name_flk].count++;
      // modelForkliftRef.current[modelForkliftRef.current.on_name_flk].const_width_col = rack_collision.width_rack / rack_collision.col_rack;
      const model_forklift =
        modelForkliftRef.current[modelForkliftRef.current.on_name_flk];
      if (collision || modelForkliftRef.current[modelForkliftRef.current.on_name_flk]?.current_collision || (modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_on_tileindex !== undefined && modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_on_tileindex !== null)) {
        if (collision) {
          const _childRack = modelForkliftRef.current[modelForkliftRef.current.on_name_flk].spoon_model; // modelForkliftRef.current[modelForkliftRef.current.on_name_flk].model.children[0].children.find(el => el.name == "mesh_6");
          new THREE.Box3().setFromObject(modelForkliftRef.current[modelForkliftRef.current.on_name_flk].model)
          // console.log('test :>> ', new THREE.Box3().setFromObject(modelForkliftRef.current[modelForkliftRef.current.on_name_flk].model));
          if (_childRack && element && index2 !== null) {
            const box = new THREE.Box3().setFromObject(_childRack);
            const centerY = box.min.y + 1;
            box.expandByVector(new THREE.Vector3(0, -10, 0));
            const box2 = box.clone();
            box2.max.y = centerY;
            box2.min.y = centerY - 1;

            let unit = rackData.unit == "mm." ? 100 : rackData.unit == "m." ? 1 : 10;
            // console.log('element, index2 :>> ', element, index2);
            let index_item = 0;
            for (let item of element.floor_tile_list[index2][Object.keys(element.floor_tile_list[index2])[1]]) {
              const box_product = new THREE.Box3().setFromObject(item.box);
              // console.log('box_product :>> ', box_product);
              // console.log('box2 :>> ', box2);
              if (box_product.intersectsBox(box2)) {
                const bottomY = box_product.min.y * unit;
                // pos_rack / pos_product * 1.8
                // console.log('box_product :>> ', box_product);
                // console.log('element.height_rack :>> ', element.height_rack);
                // console.log('(bottomY / element.height_rack) :>> ', (bottomY / element.height_rack) * 1.8);
                // setPositionYScoopFKL("", ((bottomY / element.height_rack) * 1.8) < 0.4 ? 0.4 : ((bottomY / element.height_rack) * 1.8) + 0.2, true);
                SetConfigShowDataRackPopup(element, false, myStateRef.current, item.pos_product.box.userData.id);
                /* if (viewOnly) {
                } else {
                  setrackData({
                    ...rackData,
                    on_position_rp_id: item.pos_product.box.userData.id
                  });
                  rackData.on_position_rp_id = item.pos_product.box.userData.id;
                } */

                myStateRef.current[element.index_rack].model.children.forEach(child => {
                  if (child.userData.id && child.userData.id === item.pos_product.box.userData.id && child.name === "box_product") {
                    if (actionActive.btn == "2D" && itemRackRef.current != item.pos_product.position || viewMode == "2D" && itemRackRef.current != item.pos_product.position) {
                      // setItemRack(...item.pos_product.position)
                      itemRackRef.current = item.pos_product.position;
                    }
                    whIdRef?.current && callBackPositionName && callBackPositionName(item.pos_product.position);
                    child.visible = true;
                    child.material = new THREE.MeshStandardMaterial({
                      color: 0xFF0000,
                      transparent: true,
                      // opacity: 0.6,
                      opacity: actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? 0.6 : 0,
                      depthWrite: actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? true : false,
                      emissive: new THREE.Color("red"),
                      emissiveIntensity: 10
                    })
                  } else if (child.name === "box_product" && !child.userData.has_prod) {
                    if (actionActive.btn == "2D" && itemRackRef.current != item.pos_product.position || viewMode == "2D" && itemRackRef.current != item.pos_product.position) {
                      // setItemRack(...item.pos_product.position)
                      itemRackRef.current = item.pos_product.position;
                    }
                    whIdRef?.current && callBackPositionName && callBackPositionName(item.pos_product.position);
                    child.visible = false;
                    child.material = new THREE.MeshStandardMaterial({
                      color: 0xb3806b,
                      transparent: true,
                      // opacity: 1,
                      opacity: actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? 1 : 0,
                      depthWrite: actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? true : false
                    })
                  } else if (child.name === "box_product") {
                    if (actionActive.btn == "2D" && itemRackRef.current != item.pos_product.position || viewMode == "2D" && itemRackRef.current != item.pos_product.position) {
                      // setItemRack(...item.pos_product.position)
                      itemRackRef.current = item.pos_product.position;
                    }
                    whIdRef?.current && callBackPositionName && callBackPositionName(item.pos_product.position);
                    child.visible = true;
                    child.material = new THREE.MeshStandardMaterial({
                      color: 0xb3806b,
                      transparent: true,
                      // opacity: 1,
                      opacity: actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? 1 : 0,
                      depthWrite: actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? true : false
                    })
                  }
                })
              }
              index_item++;
            }
    
          }
        } else {
          moveAndRotateForklift(_forklift, destination.x, destination.z, true);
        }
        return;
      }
      
      modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_direction = null;
      modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_on_rack = null;
      modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_on_side = null;
      // console.log('modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_on_tileindex :>> ', modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_on_tileindex);
      if (modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_on_tileindex === undefined) {
        modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_on_tileindex = null;
      }
      const element_check = modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_el_collision_sup;
      // console.log('element_check :>> ', element_check);
      if (element_check) {
        if (viewOnly) {
          if (switchBtn.rack) {
            setswitchBtn({
              ...switchBtn,
              rack: false,
            });
            switchBtn.rack = false;
          }
          if (rackData.rack_name_input) {
            setrackData({
              ...rackData,
              rack_name_input: '',
            });
            rackData.rack_name_input = '';
          }
        }
        myStateRef.current[element_check.index_rack].model.children.forEach(child => {
          if (child.name === "box_product" && !child.userData.has_prod) {
            child.visible = false;
            child.material = new THREE.MeshStandardMaterial({
              color: 0xb3806b,
              transparent: true,
              // opacity: 1,
              opacity: actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? 1 : 0,
              depthWrite: actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? true : false
            })
          } else if (child.name === "box_product") {
            child.visible = true;
            child.material = new THREE.MeshStandardMaterial({
              color: 0xb3806b,
              transparent: true,
              // opacity: 1,
              opacity: actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? 1 : 0,
              depthWrite: actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? true : false
            })
          }
        })
      }
      // modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_on_tileindex = -1;
      // if (!modelForkliftRef.current[modelForkliftRef.current.on_name_flk]?.current_on_tileindex)
/*       model_forklift.pos_lock_x = null;
      model_forklift.pos_lock_z = null; */
      modelForkliftRef.current[modelForkliftRef.current.on_name_flk] = model_forklift;
      
      if (!collision/*  && lookAtRef.current */ /* && 
          (Math.abs(Math.abs(destination.x) - Math.abs(modelForkliftRef.current[modelForkliftRef.current.on_name_flk].pos_x)) > 0.01) 
          || (Math.abs(Math.abs(destination.z) - Math.abs(modelForkliftRef.current[modelForkliftRef.current.on_name_flk].pos_z)) > 0.01) */) {
        moveAndRotateForklift(_forklift, destination.x, destination.z);
        // modelForkliftRef.current[modelForkliftRef.current.on_name_flk].pos_x = destination.x;
        // modelForkliftRef.current[modelForkliftRef.current.on_name_flk].pos_z = destination.y;
        // modelForkliftRef.current?.[modelForkliftRef.current?.on_name_flk]?.model.lookAt(destination);
        // const is_turn = Math.abs(destinate.z - destinate.m_z) > 0.3
        // if (true) {
        //   if (destinate.z < destinate.m_z) {
        //     modelForkliftRef.current[modelForkliftRef.current.on_name_flk].model.rotation.y += Math.PI * -0.5;
        //   } else {
        //     modelForkliftRef.current[modelForkliftRef.current.on_name_flk].model.rotation.y += Math.PI * 0.5;
        //   }
        // }
        // console.log('in collisionnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn', modelForkliftRef.current?.[modelForkliftRef.current?.on_name_flk]?.model?.rotation?.y);
      }
      
    } else if (lookAtRef.current && modelForkliftRef.current[modelForkliftRef.current.on_name_flk].pos_y_scoop !== modelForkliftRef.current[modelForkliftRef.current.on_name_flk].pos_y_scoop_sup) {
      if (modelForkliftRef.current.on_name_flk)
        modelForkliftRef.current[modelForkliftRef.current.on_name_flk].pos_y_scoop = modelForkliftRef.current[modelForkliftRef.current.on_name_flk].pos_y_scoop_sup;

      const dup__forklift = _forklift.clone();
      const element = modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_el_collision || modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_el_collision_sup;
      const index2 = modelForkliftRef.current[modelForkliftRef.current.on_name_flk].current_on_tileindex;
      const _childRack = modelForkliftRef.current[modelForkliftRef.current.on_name_flk].spoon_model; // dup__forklift.children[0].children.find(el => el.name == "mesh_6");
      if (_childRack && element && index2 !== null) {
        const box = new THREE.Box3().setFromObject(_childRack);
        const centerY = box.min.y + 1;
        box.expandByVector(new THREE.Vector3(0, -10, 0));
        const box2 = box.clone();
        box2.max.y = centerY;
        box2.min.y = centerY - 1;
        for (let item of element.floor_tile_list[index2][Object.keys(element.floor_tile_list[index2])[1]]) {
          const box_product = new THREE.Box3().setFromObject(item.box);
          if (box_product.intersectsBox(box2)) {

            SetConfigShowDataRackPopup(element, false, myStateRef.current, item.pos_product.box.userData.id);
            /* if (viewOnly) {
            } else {
              setrackData({
                ...rackData,
                on_position_rp_id: item.pos_product.box.userData.id
              });
              rackData.on_position_rp_id = item.pos_product.box.userData.id;
            } */
            myStateRef.current[element.index_rack].model.children.forEach(child => {
              // console.log('red child :>> ', child);
              // setItemRack({ rp_id: item.pos_product.box.userData.id, position: element.position});
              if (child.userData.id && child.userData.id === item.pos_product.box.userData.id && child.name === "box_product") {
                if (actionActive.btn == "2D" && itemRackRef.current != item.pos_product.position || viewMode == "2D" && itemRackRef.current != item.pos_product.position) {
                  // setItemRack(...item.pos_product.position)
                  itemRackRef.current = item.pos_product.position;
                }
                whIdRef?.current && callBackPositionName && callBackPositionName(item.pos_product.position);
                child.visible = true;
                child.material = new THREE.MeshStandardMaterial({
                  color: 0xFF0000,
                  transparent: true,
                  // opacity: 0.6,
                  opacity: actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? 0.6 : 0,
                  depthWrite: actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? true : false,
                  emissive: new THREE.Color("red"),
                  emissiveIntensity: 10
                })
              } else if (child.name === "box_product" && !child.userData.has_prod) {
                if (actionActive.btn == "2D" && itemRackRef.current != item.pos_product.position || viewMode == "2D" && itemRackRef.current != item.pos_product.position) {
                  // setItemRack(...item.pos_product.position)
                  itemRackRef.current = item.pos_product.position;
                }
                whIdRef?.current && callBackPositionName && callBackPositionName(item.pos_product.position);
                child.visible = false;
                child.material = new THREE.MeshStandardMaterial({
                  color: 0xb3806b,
                  transparent: true,
                  // opacity: 1,
                  opacity: actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? 1 : 0,
                  depthWrite: actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? true : false
                })
              } else if (child.name === "box_product") {
                if (actionActive.btn == "2D" && itemRackRef.current != item.pos_product.position || viewMode == "2D" && itemRackRef.current != item.pos_product.position) {
                  // setItemRack(...item.pos_product.position)
                  itemRackRef.current = item.pos_product.position;
                }
                whIdRef?.current && callBackPositionName && callBackPositionName(item.pos_product.position);
                child.visible = true;
                child.material = new THREE.MeshStandardMaterial({
                  color: 0xb3806b,
                  transparent: true,
                  // opacity: 1,
                  opacity: actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? 1 : 0,
                  depthWrite: actionActive.btn != "2D" && viewMode == undefined || (viewMode != "2D" && viewMode != undefined) ? true : false
                })
              }
            })
          }
        }

      }
    }
  }

  function setPositionYScoopFKL(symbow, value, not_limit) {
    modelForkliftRef.current[modelForkliftRef.current.on_name_flk].model.traverse(function (child) {
      
      if (!symbow) {
        if (child.name === "mesh_6" && not_limit) {
          child.position.y = value;
          modelForkliftRef.current[modelForkliftRef.current.on_name_flk].pos_y_scoop_sup = child.position.y
        }
      } else if (symbow === 'plus') {
        if (child.name === "mesh_6" && (child.position.y < valueMinMaxSpoonFLK.max)) {
          console.log('child.position.y :>> ', child.position.y);
          child.position.y += value;
          modelForkliftRef.current[modelForkliftRef.current.on_name_flk].pos_y_scoop_sup = child.position.y
          console.log('child.position.y :>> ', child.position.y);
        }
      } else if (symbow === 'minus') {
        if (child.name === "mesh_6" && (child.position.y > valueMinMaxSpoonFLK.min)) {
          console.log('child.position.y :>> ', child.position.y);

          child.position.y -= value;
          modelForkliftRef.current[modelForkliftRef.current.on_name_flk].pos_y_scoop_sup = child.position.y
          console.log('child.position.y :>> ', child.position.y);
        }
      }
    });
  }

  function GetPositionCamera() {
    const CT_temp = controlsRef?.current || {};
    return {
      x: CT_temp?.object?.position.x || 50,
      y: CT_temp?.object?.position.y || 50,
      z: CT_temp?.object?.position.z || 50,
    };
  }

  const myStateRef = React.useRef(rackData.rack_list);
  // console.log('myStateRef.current :>> ', myStateRef.current);

  function updatePositionRack() {
    if (groupObjSelect) {
      // console.log('groupObjSelect', groupObjSelect);
      // console.log('rackData.rack_list--------------------------->', [ ...myStateRef.current ]);
      const _index = [...myStateRef.current].findIndex(
        (el) => el.rack_id === groupObjSelect.userData.rack_id
      );

      if (_index != -1) {
        const arrTemp = [...myStateRef.current];
        arrTemp[_index].groupObj = groupObjSelect;
        setrackData({
          ...rackData,
          rack_list: arrTemp,
        });
        rackData.rack_list = arrTemp;
      }
    }
  }

  useEffect(async () => {
    if (actionActive.btn || viewMode) {
      let unit =
        warehouseData.unit == "mm."
          ? 0.01
          : warehouseData.unit == "m."
          ? 1
          : 0.1;
      cancelAnimationFrame(animationFrameIdRef.current);
      const mode = viewMode;
      initialData(
        sizeRoom.width * unit,
        sizeRoom.height * unit,
        sizeRoom.depth * unit,
        undefined,
        rackData.rack_list,
        null,
        mode
      );
    }
  }, [actionActive.btn, viewMode]);

  useEffect(() => {
    if (mainData?.forklift_name && modelForkliftRef.current) {
      modelForkliftRef.current.on_name_flk = mainData.forklift_name;
      x_response = {};
      y_response = {};
    }
  }, [
    JSON.stringify(mainData || null),
    JSON.stringify(modelForkliftRef.current || null),
  ]);

  useEffect(() => {
    window.addEventListener("keypress", (event) => {

      const _forklift = modelForkliftRef.current[modelForkliftRef.current.on_name_flk];
      // console.log('modelCircleRef.current :>> ', modelCircleRef.current);
      const move_range = 1;
      let _circle = null;
      if (actionActive.btn === '2D' || viewMode === '2D') {
        // _circle = modelCircleRef.current[modelForkliftRef.current.on_name_flk];
      }
      if (event.key == "w" /*  && !_forklift.collision */) {
        lookAtRef.current = true;
        directionRef.current = "w";
        _forklift.pos_x += move_range;
        // moveAndRotateForklift(_forklift.model, _forklift.pos_x, _forklift.pos_z);
        _circle && (_circle.position.x += move_range);
      }
      if (event.key == "s") {
        lookAtRef.current = true;
        directionRef.current = "s";
        _forklift.pos_x -= move_range;
        // moveAndRotateForklift(_forklift.model, _forklift.pos_x, _forklift.pos_z);
        _circle && (_circle.position.x -= move_range);
      }
      if (event.key == "a") {
        lookAtRef.current = true;
        directionRef.current = "a";
        _forklift.pos_z -= move_range;
        // moveAndRotateForklift(_forklift.model, _forklift.pos_x, _forklift.pos_z);
        _circle && (_circle.position.z -= move_range);
      }
      if (event.key == "d") {
        lookAtRef.current = true;
        directionRef.current = "d";
        _forklift.pos_z += move_range;
        // moveAndRotateForklift(_forklift.model, _forklift.pos_x, _forklift.pos_z);
        _circle && (_circle.position.z += move_range);
      }
      if (event.key == ",") {
        lookAtRef.current = true;

        setPositionYScoopFKL('plus', 0.5);
      }
      if (event.key == ".") {
        lookAtRef.current = true;

        setPositionYScoopFKL('minus', 0.5);
      }

      if (event.key == "x") {
        modelForkliftRef.current[modelForkliftRef.current.on_name_flk].model.traverse(function (child) {
          child.rotation.y = Math.PI * 1;
        });
        // console.log('_forklift.model.rotation.y :>> ', _forklift.model.rotation.y);
      }

      if (event.key == "z") {
        _forklift.model.rotation.y = Math.PI * 2
      }
    });
  }, [actionActive.btn])

  // console.log('rackData in WH3D main', rackData);
  
  return (
    actionActive.btn != "2D" ? (
      <div
        id={viewOnly ? "" : "content-wrapper"}
        className={viewOnly ? "" : "content-wrapper"}
        style={{ position: 'relative' }}
      >
        <HeaderChangeWH
          position={{ left: "250px", top: "58px" }}
          widthPage={widthPage.data}
          sizeRoom={sizeRoom}
          setSizeRoom={setSizeRoom}
          setwarehouseData={setwarehouseData}
          warehouseData={warehouseData}
          setrackData={setrackData}
          rackData={rackData}
          actionActive={actionActive}
          setActionActive={setActionActive}
          switchBtn={switchBtn}
          setswitchBtn={setswitchBtn}
          functionComponent={functionComponent}
          setFunctionComponent={setFunctionComponent}
          actionListRack={actionListRack}
          setActionListRack={setActionListRack}
          dataOfWarehouse={dataOfWarehouse}
          setDataOfWarehouse={setDataOfWarehouse}
          myStateRef={myStateRef}
          socketConnect={socketConnectNew}
          requestGenToken={requestGenToken}
          SetPOSModel={SetPOSModel}
          xRef={xRef}
          zRef={zRef}
          lookAtRef={lookAtRef}
          modelRef={modelRef}
          modelForkliftRef={modelForkliftRef}
          viewOnly={viewOnly}
          whIdRef={whIdRef}
          positionRef={positionRef}
          NOT_USE_NOW={NOT_USE_NOW}
          modelType={modelType}
        />
        <ToolWarehouse
          actionActive={actionActive}
          setActionActive={setActionActive}
          position={{ left: "20px", top: "160px" }}
          viewOnly={viewOnly}
          offsetSceneRef={offsetSceneRef}
        />
        <ConfigWarehouse
          rackData={rackData}
          setrackData={setrackData}
          switchBtn={switchBtn}
          setswitchBtn={setswitchBtn}
          setwarehouseData={setwarehouseData}
          warehouseData={warehouseData}
          setSizeRoom={setSizeRoom}
          sizeRoom={sizeRoom}
          initialData={initialData}
          GAP={GAP}
          animationFrameId={animationFrameIdRef.current}
          position={{ left: viewOnly ? "20px" : undefined, right: viewOnly ? undefined : "20px", top: viewOnly ? "60px" : "160px" }}
          functionComponent={functionComponent}
          setFunctionComponent={setFunctionComponent}
          useZAxis={useZAxis}
          setUseZAxis={setUseZAxis}
          actionListRack={actionListRack}
          setActionListRack={setActionListRack}
          dataOfWarehouse={dataOfWarehouse}
          setDataOfWarehouse={setDataOfWarehouse}
          viewOnly={viewOnly}
          NOT_USE_NOW={NOT_USE_NOW}
          modelType={modelType}
          actionActive={actionActive}
          showRackPopup={showRackPopup}
        />

        <div className={"container-fluid" + (viewOnly ? " p-0" : "")}>
          <div className="scene-container">
            {/* <div id="performance-panel" class="performance-panel">
              {warehouseData.width && warehouseData.depth && (
                <div>
                  <hr />
                  <span>ROW: {warehouseData.width}</span>
                  <span>COLUMN: {warehouseData.depth}</span>
                </div>
              )}
            </div> */}

            <div id="compass" class="compass" ref={compassRef} hidden={viewOnly}>
              <div id="north" class="north"></div>
              <div id="south" class="south"></div>
            </div>

            <div
              className="card"
              id="yourCanvasContainerID"
              ref={canvasRef}
            ></div>

            <div
              className="label"
              id="label"
              ref={labelRef}
              hidden={viewOnly}
            ></div>

            <div className="extend-panel" hidden={viewOnly}>
              <button
                id="zoom-out-button"
                className="zoom-out-button"
                onMouseDown={startZoomOut}
                onMouseUp={stopZoomOut}
                onMouseLeave={stopZoomOut}
              >
                <b>－</b>
              </button>
              <button
                id="zoom-in-button"
                className="zoom-in-button"
                onMouseDown={startZoomIn}
                onMouseUp={stopZoomIn}
                onMouseLeave={stopZoomIn}
              >
                <b>＋</b>
              </button>
              <button
                id="reset-zoom-button"
                className="reset-zoom-button"
                onClick={resetControls}
              >
                <b>⟳</b>
              </button>
              {/* <button
                id="reset-zoom-button"
                className="reset-zoom-button"
                onClick={ClearRackList}
              >
                <b>R</b>
              </button> */}
            </div>

            <div
              hidden={viewOnly}
              ref={guiRef}
              style={{ position: "absolute", bottom: 20, left: 0, zIndex: 1000 }}
            />
          </div>
        </div>

        <div
          style={{
            left: "480px",
            bottom: "10px",
            position: "fixed",
            zIndex: 1,
            gap: "5px",
          }}
          hidden={viewOnly}
        >
          <div className="p-2">
            <div id="performance-panel" class="performance-panel">
              <p id="fps">fps: {fps}</p>
              <p id="latency">
                latency: {socketLatency > 0 ? `${socketLatency}ms` : "???"}
              </p>
              <p id="status">
                Status: {socketConnected ? "Connected" : "Not Connected"}
              </p>
            </div>
          </div>
        </div>
      </div>
    ) : 
    (
      <>
        <div
          id={viewOnly ? "" : "content-wrapper"}
          className={viewOnly ? "" : "content-wrapper"}
          style={{ position: 'relative' }}
        >
          <HeaderChangeWH
            position={{ left: "250px", top: "58px" }}
            widthPage={widthPage.data}
            sizeRoom={sizeRoom}
            setSizeRoom={setSizeRoom}
            setwarehouseData={setwarehouseData}
            warehouseData={warehouseData}
            setrackData={setrackData}
            rackData={rackData}
            actionActive={actionActive}
            setActionActive={setActionActive}
            switchBtn={switchBtn}
            setswitchBtn={setswitchBtn}
            functionComponent={functionComponent}
            setFunctionComponent={setFunctionComponent}
            actionListRack={actionListRack}
            setActionListRack={setActionListRack}
            dataOfWarehouse={dataOfWarehouse}
            setDataOfWarehouse={setDataOfWarehouse}
            myStateRef={myStateRef}
            socketConnect={socketConnectNew}
            requestGenToken={requestGenToken}
            SetPOSModel={SetPOSModel}
            xRef={xRef}
            zRef={zRef}
            lookAtRef={lookAtRef}
            modelRef={modelRef}
            modelForkliftRef={modelForkliftRef}
            viewOnly={viewOnly}
            whIdRef={whIdRef}
            positionRef={positionRef}
            NOT_USE_NOW={NOT_USE_NOW}
            modelType={modelType}
          />
          <ToolWarehouse
            actionActive={actionActive}
            setActionActive={setActionActive}
            position={{ left: "20px", top: "160px" }}
            viewOnly={viewOnly}
            offsetSceneRef={offsetSceneRef}
          />
          <ConfigWarehouse
            rackData={rackData}
            setrackData={setrackData}
            switchBtn={switchBtn}
            setswitchBtn={setswitchBtn}
            setwarehouseData={setwarehouseData}
            warehouseData={warehouseData}
            setSizeRoom={setSizeRoom}
            sizeRoom={sizeRoom}
            initialData={initialData}
            GAP={GAP}
            animationFrameId={animationFrameIdRef.current}
            position={{ left: viewOnly ? "20px" : undefined, right: viewOnly ? undefined : "20px", top: viewOnly ? "60px" : "160px" }}
            functionComponent={functionComponent}
            setFunctionComponent={setFunctionComponent}
            useZAxis={useZAxis}
            setUseZAxis={setUseZAxis}
            actionListRack={actionListRack}
            setActionListRack={setActionListRack}
            dataOfWarehouse={dataOfWarehouse}
            setDataOfWarehouse={setDataOfWarehouse}
            viewOnly={viewOnly}
            NOT_USE_NOW={NOT_USE_NOW}
            modelType={modelType}
            actionActive={actionActive}
            showRackPopup={showRackPopup}
          />

          <div className={"container-fluid" + (viewOnly ? " p-0" : "")}>
            <div className="scene-container">
              {/* <div id="performance-panel" class="performance-panel">
                {warehouseData.width && warehouseData.depth && (
                  <div>
                    <hr />
                    <span>ROW: {warehouseData.width}</span>
                    <span>COLUMN: {warehouseData.depth}</span>
                  </div>
                )}
              </div> */}

              <div id="compass" class="compass" ref={compassRef} hidden={viewOnly}>
                <div id="north" class="north"></div>
                <div id="south" class="south"></div>
              </div>

              <div
                className="card"
                id="yourCanvasContainerID"
                ref={canvasRef}
              ></div>

              <div
                className="label"
                id="label"
                ref={labelRef}
                hidden={viewOnly}
              ></div>

              <div className="extend-panel" hidden={viewOnly}>
                <button
                  id="zoom-out-button"
                  className="zoom-out-button"
                  onMouseDown={startZoomOut}
                  onMouseUp={stopZoomOut}
                  onMouseLeave={stopZoomOut}
                >
                  <b>－</b>
                </button>
                <button
                  id="zoom-in-button"
                  className="zoom-in-button"
                  onMouseDown={startZoomIn}
                  onMouseUp={stopZoomIn}
                  onMouseLeave={stopZoomIn}
                >
                  <b>＋</b>
                </button>
                <button
                  id="reset-zoom-button"
                  className="reset-zoom-button"
                  onClick={resetControls}
                >
                  <b>⟳</b>
                </button>
                {/* <button
                  id="reset-zoom-button"
                  className="reset-zoom-button"
                  onClick={ClearRackList}
                >
                  <b>R</b>
                </button> */}
              </div>

              <div
                hidden={viewOnly}
                ref={guiRef}
                style={{ position: "absolute", bottom: 20, left: 0, zIndex: 1000 }}
              />
            </div>
          </div>
          <div
            style={{
              left: "480px",
              bottom: "10px",
              position: "fixed",
              zIndex: 1,
              gap: "5px",
            }}
            hidden={viewOnly}
          >
            <div className="row">
              <div className="p-2">
                <div id="performance-panel" class="performance-panel">
                  <p id="fps">fps: {fps}</p>
                  <p id="latency">
                    latency: {socketLatency > 0 ? `${socketLatency}ms` : "???"}
                  </p>
                  <p id="status">
                    Status: {socketConnected ? "Connected" : "Not Connected"}
                  </p>
                </div>
              </div>
              <div>
                <div style={{ position: "absolute", zIndex: "95", display: 'flex', flexDirection: 'row', backgroundColor: '#00408E', paddingLeft: '10px', paddingRight: '10px', width: 'auto' }}>
                  <p style={{ color: 'white', width: '160px'}}>Forklift Name: {Object.keys(forkliftName).length > 0 ? forkliftName : "???" }</p>
                  <p style={{ color: 'white', width: '60px' }}>Rack: {itemRackRef.current ? itemRackRef.current : "???"}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
}

export default Warehouse3D_Main;
