import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";

import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import {
  getOem,
  getToken,
  getUser,
  getAcademy,
  getFeature,
} from "../../Utils/Common";
import Select from "react-select";
import jsPDF from "jspdf";
import {
  Get_font_pdf_th,
  Get_font_pdf_th2,
  Get_font_pdf_th2_bold,
} from "../../assets/font/pdf_font";
import { userdefault_img, logoTTT } from "../../routes/imgRoute/imgUrl";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useParams } from "react-router";
import "../../assets/css/add-on-2.css";

import Task_Work_Card from "./Manage_Component/Task_Work_Card";
import Result_Each_Weeks from "./Manage_Component/Result_Each_Weeks";
import Cumulative_Graph from "./Manage_Component/Cumulative_Graph";

import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";

function Task_Work() {
  const [mode, setMode] = useState({ data: "table" });

  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });

  const { week, year } = useParams();

  const [employeeData, setEmployeeData] = useState({
    data: [],
  });

  const [evaluateData, setEvaluateData] = useState({
    data: [],
  });

  const [assignData, setAssignData] = useState({
    data: [],
  });

  const [checkWeek, setCheckWeek] = useState(true);

  const [allWeek, setAllWeek] = useState(1);

  const [showWeek, setShowWeek] = useState(1);

  const [showYear, setShowYear] = useState(1);

  const [searchWeek, setSearchWeek] = useState({
    week: 1,
  });

  const [searchYear, setSearchYear] = useState({
    year: 1,
  });

  const [showDate, setShowDate] = useState({
    startDate: null,
    startMonth: null,
    endDate: null,
    endMonth: null,
    year: null,
  });

  const [reportDate, setReportDate] = useState({
    startDate: null,
    endDate: null,
  });

  const [inputData, setInputData] = useState({
    assign_by_id: null,
    task: null,
  });

  const [employeeOption, setEmployeeOption] = useState({
    data: [],
  });

  const [getEmployeeWorkTypeData, setGetEmployeeWorkTypeData] = useState({
    data: [],
  });

  const [saveCurrentDate, setSaveCurrentDate] = useState({
    startDate: null,
    endDate: null,
    week: 0,
    year: 0,
  });

  const [cumulativeModal, setCumulativeModal] = useState(false);

  const [resultEachWeekData, setResultEachWeekData] = useState({
    data: [],
  });

  // const [resultSuccessData, setResultSuccessData] = useState({
  //   greenSuccess: 0,
  //   yellowSeccess: 0,
  //   redFail: 0,
  //   data: [],
  // });

  const [resultSuccessDataAll, setResultSuccessDataAll] = useState({
    selectDropDown: "",
    dropdown: [],
    dataList: [],
    showData: [],
  });

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  function DistanceStatus({ data }) {
    return (
      <div style={{ display: "flex", textAlign: "left" }}>
        <div
          style={{
            background: `${
              data.assign_to_status_id ===
              "22bb6045-d8b6-44dd-8f2f-be7330c17aa9"
                ? "#AEAEAE"
                : new Date(saveCurrentDate.startDate).setHours(0, 0, 0, 0) >
                  new Date(data.due_date).setHours(0, 0, 0, 0)
                ? "#FF415C"
                : new Date(saveCurrentDate.endDate).setHours(0, 0, 0, 0) <
                  new Date(data.due_date).setHours(0, 0, 0, 0)
                ? "#3579F6"
                : "#E9B500"
            }`,
            borderRadius: "28px",
            width: "25px",
            height: "43px",
            flexShrink: "0",
          }}
        />

        <div style={{ paddingLeft: "10px" }}>
          <span
            className="textVerticalOverflow-3"
            style={{
              color: `${
                data.assign_to_status_id ===
                "22bb6045-d8b6-44dd-8f2f-be7330c17aa9"
                  ? "#AEAEAE"
                  : new Date(saveCurrentDate.startDate).setHours(0, 0, 0, 0) >
                    new Date(data.due_date).setHours(0, 0, 0, 0)
                  ? "#FF415C"
                  : new Date(saveCurrentDate.endDate).setHours(0, 0, 0, 0) <
                    new Date(data.due_date).setHours(0, 0, 0, 0)
                  ? "#3579F6"
                  : "#E9B500"
              }`,
              fontSize: "16px",
              lineHeight: "normal",
              wordBreak: "break-word",
              userSelect: "none",
              // marginRight: "10px",
            }}
          >
            {data.assign_to_status_id === "22bb6045-d8b6-44dd-8f2f-be7330c17aa9"
              ? "SUCCESSED TASK"
              : new Date(saveCurrentDate.startDate).setHours(0, 0, 0, 0) >
                new Date(data.due_date).setHours(0, 0, 0, 0)
              ? "LATE TASK"
              : new Date(saveCurrentDate.endDate).setHours(0, 0, 0, 0) <
                new Date(data.due_date).setHours(0, 0, 0, 0)
              ? "FUTURE TASK"
              : "CURRENT TASK"}
          </span>

          <span
            className="textVerticalOverflow-3"
            style={{
              color: `${
                data.assign_to_status_id ===
                "22bb6045-d8b6-44dd-8f2f-be7330c17aa9"
                  ? "#AEAEAE"
                  : new Date(saveCurrentDate.startDate).setHours(0, 0, 0, 0) >
                    new Date(data.due_date).setHours(0, 0, 0, 0)
                  ? "#FF415C"
                  : new Date(saveCurrentDate.endDate).setHours(0, 0, 0, 0) <
                    new Date(data.due_date).setHours(0, 0, 0, 0)
                  ? "#3579F6"
                  : "#E9B500"
              }`,
              fontSize: "24px",
              lineHeight: "normal",
              wordBreak: "break-word",
              userSelect: "none",
              // marginRight: "10px",
            }}
          >
            {moment(data.due_date).locale("en").format("MMMM DD, YYYY")}
          </span>
        </div>
      </div>
    );
  }

  const rowsData = [];
  for (let index = 0; index < assignData.data.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = index + 1;
    rowItem["task"] = (
      <>
        <div style={{ textAlign: "left", fontWeight: "700" }}>
          {assignData.data[index].task}
        </div>
        <div
          style={{
            textAlign: "left",
            maxWidth: "500px",
            whiteSpace: "pre-line",
          }}
        >
          {assignData.data[index].task_detail}
        </div>
      </>
    );
    rowItem["searchTask"] = assignData.data[index].task;
    rowItem["searchTaskDetail"] = assignData.data[index].task_detail;
    rowItem["due_date"] = assignData.data[index].due_date
      ? moment(assignData.data[index].due_date).format("DD-MM-YYYY")
      : null;
    rowItem["assign_by"] = `${assignData.data[index].assign_by_no || ""} ${
      assignData.data[index].assign_by_nickname
        ? "( " + assignData.data[index].assign_by_nickname + " )"
        : ""
    } `;
    // rowItem["assign_to"] = assignData.data[index].assign_to_name;
    rowItem["created_date"] = assignData.data[index].created_date
      ? moment(assignData.data[index].created_date).format("DD-MM-YYYY")
      : null;
    rowItem["status"] =
      assignData.data[index].assign_to_status_name +
      (assignData.data[index].task_count === 0
        ? ""
        : ` ( ${assignData.data[index].task_count} )`);

    if (
      assignData.data[index].assign_to_status_id ===
      "a4147c32-a749-4b91-b9eb-e11bf6d5a219"
    ) {
      rowItem["action"] =
        assignData.data[index].assign_by_status_id !==
        "7a2a0ab3-cc81-4b17-9129-c95347337dd0" ? (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
                <button
                  type="button"
                  id="btn_search"
                  className="btn btn-block btn-primary"
                  style={{ minWidth: "130px" }}
                  onClick={() =>
                    SentWork({
                      task_id: assignData.data[index].task_id,
                      assign_by_status_id:
                        "7a2a0ab3-cc81-4b17-9129-c95347337dd0",
                    })
                  }
                >
                  ส่งงาน
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
                <button
                  type="button"
                  id="btn_search"
                  className="btn btn-block btn-secondary"
                  style={{ minWidth: "130px" }}
                  // onClick={() =>
                  //   SentWork({
                  //     task_id: assignData[index].task_id,
                  //     assign_by_status_id: "7a2a0ab3-cc81-4b17-9129-c95347337dd0",
                  //   })
                  // }
                  disabled
                >
                  ส่งงาน
                </button>
              </div>
            </div>
          </>
        );
    }

    if (
      assignData.data[index].assign_to_status_id ===
      "53140a82-3fdb-44d8-a726-77492c63c800"
    ) {
      rowItem["action"] =
        assignData.data[index].assign_by_status_id !==
        "7a2a0ab3-cc81-4b17-9129-c95347337dd0" ? (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
                <button
                  type="button"
                  id="btn_search"
                  className="btn btn-block btn-warning"
                  style={{ minWidth: "130px" }}
                  onClick={() =>
                    SentWork({
                      task_id: assignData.data[index].task_id,
                      assign_by_status_id:
                        "7a2a0ab3-cc81-4b17-9129-c95347337dd0",
                    })
                  }
                >
                  ส่งงานอีกครั้ง
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
                <button
                  type="button"
                  id="btn_search"
                  className="btn btn-block btn-warning"
                  style={{ minWidth: "130px" }}
                  // onClick={() =>
                  //   SentWork({
                  //     task_id: assignData[index].task_id,
                  //     assign_by_status_id:
                  //       "7a2a0ab3-cc81-4b17-9129-c95347337dd0",
                  //   })
                  // }
                  disabled
                >
                  ส่งงานอีกครั้ง
                </button>
              </div>
            </div>
          </>
        );
    }

    if (
      assignData.data[index].assign_to_status_id ===
      "dd65b3a6-9946-491f-8fd9-688113f52daf"
    ) {
      rowItem["action"] =
        assignData.data[index].assign_by_status_id !==
        "7a2a0ab3-cc81-4b17-9129-c95347337dd0" ? (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
                <button
                  type="button"
                  id="btn_search"
                  className="btn btn-block btn-danger"
                  style={{ minWidth: "130px" }}
                  onClick={() =>
                    SentWork({
                      task_id: assignData.data[index].task_id,
                      assign_by_status_id:
                        "7a2a0ab3-cc81-4b17-9129-c95347337dd0",
                    })
                  }
                >
                  ส่งงานอีกครั้ง
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
                <button
                  type="button"
                  id="btn_search"
                  className="btn btn-block btn-danger"
                  style={{ minWidth: "130px" }}
                  // onClick={() =>
                  //   SentWork({
                  //     task_id: assignData[index].task_id,
                  //     assign_by_status_id:
                  //       "7a2a0ab3-cc81-4b17-9129-c95347337dd0",
                  //   })
                  // }
                  disabled
                >
                  ส่งงานอีกครั้ง
                </button>
              </div>
            </div>
          </>
        );
    }

    if (
      assignData.data[index].assign_to_status_id ===
      "22bb6045-d8b6-44dd-8f2f-be7330c17aa9"
    ) {
      rowItem["action"] = (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: "100%" }}>
              <button
                type="button"
                id="btn_search"
                className="btn btn-block btn-success"
                style={{ minWidth: "130px" }}
                disabled
              >
                สำเร็จ
              </button>
            </div>
          </div>
        </>
      );
    }

    rowItem["task_due"] = <DistanceStatus data={assignData.data[index]} />;

    rowItem["management"] = (
      <div className="row" style={{ flexWrap: "nowrap" }}>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            href={`/Human_Resource/to_do_list/assign_work/readt/${assignData.data[index].task_id}/${searchWeek.week}/${searchYear.year}`}
            id="btn_read"
            key={assignData.data[index].task_id}
            className="btn btn-xs "
          >
            <i class="fas fa-eye"></i>
            {"   "}
          </a>
        </div>
        {assignData.data[index].assign_by_id ===
          assignData.data[index].assign_to_id &&
        assignData.data[index].assign_by_status_id !==
          "f46730e7-9980-46b5-a4e5-7967550e4ea2" ? (
          <>
            <div className="col-xl-3 col-md-3 col-xs-3 ">
              <a
                href={`/Human_Resource/to_do_list/assign_work/editt/${assignData.data[index].task_id}/${searchWeek.week}/${searchYear.year}`}
                id="btn_edit"
                key={assignData.data[index].task_id}
                className=" btn btn-xs "
              >
                {"   "}
                <i class="fas fa-pencil-alt"></i>
                {"   "}
              </a>
            </div>
            <div className="col-xl-3 col-md-3 col-xs-3 ">
              <a
                key={assignData.data[index].task_id}
                id="btn_delete"
                className=" btn btn-xs "
                onClick={() => DeleteAssign(assignData.data[index].task_id)}
              >
                <i class="fas fa-trash-alt"></i>
              </a>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    );

    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Task",
        field: "task",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Due Date",
        field: "due_date",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Assign by",
        field: "assign_by",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Create Date",
        field: "created_date",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Action",
        field: "action",
        // sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "status",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Task Due",
        field: "task_due",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };

  const clearFilter = async () => {
    setInputData({
      ...inputData,
      assign_by_id: "",
      task: "",
    });
    inputData.assign_by_id = "";
    inputData.task = "";

    const currentDate = new Date();
    setShowWeek(moment(currentDate).isoWeekday(1).week());
    setShowYear(moment(currentDate).year());

    setSearchWeek({
      ...searchWeek,
      week: moment(currentDate).isoWeekday(1).week(),
    });
    setSearchYear({
      ...searchYear,
      year: moment(currentDate).year(),
    });
    searchWeek.week = moment(currentDate).isoWeekday(1).week();
    searchYear.year = moment(currentDate).year();

    setShowDate({
      ...showDate,
      startDate: moment().year(moment(currentDate).year()).week(moment(currentDate).isoWeekday(1).week()).clone().startOf('week').add(1, "days").date(),
      startMonth: moment().year(moment(currentDate).year()).week(moment(currentDate).isoWeekday(1).week()).clone().startOf('week').add(1, "days").locale("en").format("MMM"),
      endDate: moment().year(moment(currentDate).year()).week(moment(currentDate).isoWeekday(1).week()).clone().endOf('week').subtract(1, "days").date(),
      endMonth: moment().year(moment(currentDate).year()).week(moment(currentDate).isoWeekday(1).week()).clone().endOf('week').subtract(1, "days").locale("en").format("MMM"),
      year: moment().year(moment(currentDate).year()).week(moment(currentDate).isoWeekday(1).week()).clone().endOf('week').subtract(1, "days").year(),
    });
    showDate.startDate = moment().year(moment(currentDate).year()).week(moment(currentDate).isoWeekday(1).week()).clone().startOf('week').add(1, "days").date();
    showDate.startMonth = moment().year(moment(currentDate).year()).week(moment(currentDate).isoWeekday(1).week()).clone().startOf('week').add(1, "days").locale("en").format("MMM");
    showDate.endDate = moment().year(moment(currentDate).year()).week(moment(currentDate).isoWeekday(1).week()).clone().endOf('week').subtract(1, "days").date();
    showDate.endMonth = moment().year(moment(currentDate).year()).week(moment(currentDate).isoWeekday(1).week()).clone().endOf('week').subtract(1, "days").locale("en").format("MMM");
    showDate.year = moment().year(moment(currentDate).year()).week(moment(currentDate).isoWeekday(1).week()).clone().endOf('week').subtract(1, "days").year();

    await FilterTask();
    await FilterEvaluate();
    // await getResultEvaluate();
    await getResultEvaluateAll();
  };

  const SentWork = async (model) => {
    let pushData = {
      id: model.task_id,
      assign_by_status_id: model.assign_by_status_id,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/updateSentWork",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: pushData,
    })
      .then(async function (response) {
        await FilterTask();
        await FilterEvaluate();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const DeleteAssign = async (task_id) => {
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios({
          method: "post",
          url:
            Configs.API_URL_hrMagenatement + "/api/hrManagement/deleteAssign",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: { id: task_id },
        })
          .then(async function (response) {
            Swal.fire({
              icon: "success",
              text: "เสร็จสิ้น",
              showConfirmButton: false,
              timer: 1000,
            }).then(async () => {
              await FilterTask();
              await FilterEvaluate();
            });
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
          });
      }
    });
  };

  const GetEmployeeWorkType = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
      getAll: true,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getEmpWorkType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(async function (response) {
        const arrList = [];

        for (let item of response.data.data) {
          let temp = {
            id: item.id,
            contract_type_name: item.contract_type_name,
            sort: item.sort,
          };

          arrList.push(temp);
        }

        // arrList.sort((a, b) => {
        //   if (a.sort === null) {
        //     return 1;
        //   }

        //   if (b.sort === null) {
        //     return -1;
        //   }

        //   if (a.sort === b.sort) {
        //     return 0;
        //   }

        //   return a.sort < b.sort ? -1 : 1;
        // });

        // console.log(arrList);

        setGetEmployeeWorkTypeData({ data: arrList });
        getEmployeeWorkTypeData.data = arrList;

        // console.log('getEmployeeWorkTypeData', getEmployeeWorkTypeData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const FilterEmployee = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
      resign_status: true,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterEmployee",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        // response.data.data.sort((a, b) => {
        //   if (a.emp_no === null) {
        //     return 1;
        //   }

        //   if (b.emp_no === null) {
        //     return -1;
        //   }

        //   if (a.emp_no.toLowerCase() === b.emp_no.toLowerCase()) {
        //     return 0;
        //   }

        //   return a.emp_no.toLowerCase() < b.emp_no.toLowerCase() ? -1 : 1;
        // });

        let empAll = [];

        for (let item of getEmployeeWorkTypeData.data) {
          let empEqualType = response.data.data.filter((empItem) => {
            return empItem.emp_contract_type_id === item.id;
          });

          empEqualType.sort((a, b) => {
            if (a.emp_no === null) {
              return 1;
            }

            if (b.emp_no === null) {
              return -1;
            }

            if (a.emp_no.toLowerCase() === b.emp_no.toLowerCase()) {
              return 0;
            }

            return a.emp_no.toLowerCase() < b.emp_no.toLowerCase() ? -1 : 1;
          });

          empAll.push(...empEqualType);
        }

        setEmployeeData({
          data: empAll,
        });
        employeeData.data = empAll;

        const options = [{ value: "", label: "Select All" }];
        empAll.forEach((el) => {
          var element = {};
          element["value"] = el.emp_id;
          element["label"] = `${el.emp_no || ""} ${
            el.emp_nickname ? "( " + el.emp_nickname + " )" : ""
          } `;
          options.push(element);
        });

        setEmployeeOption({ data: options });
        employeeOption.data = options;
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const FilterTask = async () => {
    let get_filler_assign = {
      oem_id: getOem(),
      company_id: getUser().com,
      user: getUser().fup,
      assign_by_id: inputData.assign_by_id || null,
      task: inputData.task ? inputData.task.trim() : null,
      week: searchWeek.week,
      year: searchYear.year,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterTask",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_assign,
    })
      .then(async function (response) {
        // response.data.data.sort(
        //   (a, b) =>
        //     Number(moment(b.updated_date).format("YYYYMMDDHHmmss")) -
        //     Number(moment(a.updated_date).format("YYYYMMDDHHmmss"))
        // );

        const otherData = response.data.data.filter((item) => {
          return (
            item.assign_to_status_id !== "22bb6045-d8b6-44dd-8f2f-be7330c17aa9"
          );
        });

        const successfulData = response.data.data.filter((item) => {
          return (
            item.assign_to_status_id === "22bb6045-d8b6-44dd-8f2f-be7330c17aa9"
          );
        });

        otherData.sort((a, b) => {
          if (moment(a.due_date) > moment(b.due_date)) {
            return 1;
          }

          if (moment(a.due_date) < moment(b.due_date)) {
            return -1;
          }
        });

        successfulData.sort((a, b) => {
          if (moment(a.due_date) > moment(b.due_date)) {
            return 1;
          }

          if (moment(a.due_date) < moment(b.due_date)) {
            return -1;
          }
        });

        setAssignData({
          data: [...otherData, ...successfulData],
        });
        assignData.data = [...otherData, ...successfulData];
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const FilterEvaluate = async () => {
    let get_com_oem = {
      employee_id: getUser().fup,
      oem_id: getOem(),
      company_id: getUser().com,
      week: searchWeek.week,
      year: searchYear.year,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterEvaluate",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        setEvaluateData({
          data: response.data.data,
        });
        evaluateData.data = response.data.data;

        if (
          // Math.ceil(
          //   Math.floor(
          //     (new Date() - new Date(new Date().getFullYear(), 0, 1)) /
          //       (24 * 60 * 60 * 1000)
          //   ) / 7
          // )
          moment(new Date()).isoWeekday(1).isoWeekday(1).week() === searchWeek.week &&
          new Date().getFullYear() === searchYear.year
        ) {
          setCheckWeek(true);
        } else {
          setCheckWeek(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const ReportEvaluate = async () => {
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
      start_date: reportDate.startDate,
      end_date: reportDate.endDate,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/reportEvaluate",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        // response.data.data.sort((a, b) => {
        //   if (a.emp_no === null) {
        //     return 1;
        //   }

        //   if (b.emp_no === null) {
        //     return -1;
        //   }

        //   if (a.emp_no.toLowerCase() === b.emp_no.toLowerCase()) {
        //     return 0;
        //   }

        //   return a.emp_no.toLowerCase() < b.emp_no.toLowerCase() ? -1 : 1;
        // });

        let empAll = [];

        for (let item of getEmployeeWorkTypeData.data) {
          let empEqualType = response.data.data.filter((empItem) => {
            return empItem.emp_contract_type_id === item.id;
          });

          empEqualType.sort((a, b) => {
            if (a.emp_no === null) {
              return 1;
            }

            if (b.emp_no === null) {
              return -1;
            }

            if (a.emp_no.toLowerCase() === b.emp_no.toLowerCase()) {
              return 0;
            }

            return a.emp_no.toLowerCase() < b.emp_no.toLowerCase() ? -1 : 1;
          });

          empAll.push(...empEqualType);
        }

        var img = logoTTT.imgs;

        const doc = new jsPDF("p", "mm", "a4");

        doc.addFileToVFS("THSarabunNew.ttf", Get_font_pdf_th2());
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.addFileToVFS("THSarabunNew-bold.ttf", Get_font_pdf_th2_bold());
        doc.addFont("THSarabunNew-bold.ttf", "THSarabunNew", "bold");
        // doc.addFont("Rocki", "bold");

        doc.addImage(img, "JPEG", 15, 15, 25, 25);

        doc.setFontSize(25);
        doc.setFont("THSarabunNew", "bold");
        doc.text(105, 20, "สรุปการปฏิบัติงาน", "center");

        doc.setFontSize(16);
        doc.setFont("THSarabunNew", "normal");
        doc.text(
          105,
          40,
          `ระหว่าง : ${moment(reportDate.startDate).format(
            "DD/MM/YYYY"
          )} - ${moment(reportDate.endDate).format("DD/MM/YYYY")}`,
          "center"
        );

        doc.rect(10, 50, 190, 8);
        doc.setFontSize(14);
        doc.setFont("THSarabunNew", "bold");

        doc.setTextColor(255, 255, 255);

        doc.setFillColor(0, 43, 97);
        doc.rect(10, 50, 70, 8, "FD");
        doc.text(45, 55.5, `ชื่อพนักงาน`, "center");

        doc.setFillColor(0, 43, 97);
        doc.rect(80, 50, 30, 8, "FD");
        doc.text(95, 55.5, `รหัสพนักงาน`, "center");

        doc.setFillColor(0, 43, 97);
        doc.rect(110, 50, 30, 8, "FD");
        doc.text(125, 55.5, `ปฏิบัติงานทั้งหมด`, "center");

        doc.setFillColor(0, 204, 102);
        doc.rect(140, 50, 20, 8, "FD");
        doc.text(150, 55.5, `ผ่าน`, "center");

        doc.setFillColor(255, 0, 0);
        doc.rect(160, 50, 20, 8, "FD");
        doc.text(170, 55.5, `ไม่ผ่าน`, "center");

        doc.setFillColor(0, 43, 97);
        doc.rect(180, 50, 20, 8, "FD");
        doc.text(190, 55.5, `เปอร์เซ็น`, "center");

        doc.setTextColor(0, 0, 0);
        doc.setFont("THSarabunNew", "normal");

        let y = 58;
        let yt = 63.5;
        let pageNumber = 1;

        doc.text(200, 291, `หน้า ${pageNumber}`, "right");

        for (let index = 0; index < empAll.length; index++) {
          if (index === 28) {
            doc.addPage("p");
            // doc.rect(10, 20, 190, 8);
            // doc.setFontSize(14);
            // doc.setFont("THSarabunNew", "bold");

            // doc.setTextColor(255, 255, 255);

            // doc.setFillColor(0, 43, 97);
            // doc.rect(10, 20, 70, 8, "FD");
            // doc.text(45, 25.5, `ชื่อพนักงาน`, "center");

            // doc.setFillColor(0, 43, 97);
            // doc.rect(80, 20, 30, 8, "FD");
            // doc.text(95, 25.5, `รหัสพนักงาน`, "center");

            // doc.setFillColor(0, 43, 97);
            // doc.rect(110, 20, 30, 8, "FD");
            // doc.text(125, 25.5, `ปฏิบัติงานทั้งหมด`, "center");

            // doc.setFillColor(0, 204, 102);
            // doc.rect(140, 20, 20, 8, "FD");
            // doc.text(150, 25.5, `ผ่าน`, "center");

            // doc.setFillColor(255, 0, 0);
            // doc.rect(160, 20, 20, 8, "FD");
            // doc.text(170, 25.5, `ไม่ผ่าน`, "center");

            // doc.setFillColor(0, 43, 97);
            // doc.rect(180, 20, 20, 8, "FD");
            // doc.text(190, 25.5, `เปอร์เซ็น`, "center");

            // y = 28;
            // yt = 33;
            // doc.setTextColor(0, 0, 0);
            // doc.setFont("THSarabunNew", "normal");

            doc.addImage(img, "JPEG", 15, 15, 25, 25);

            doc.setFontSize(25);
            doc.setFont("THSarabunNew", "bold");
            doc.text(105, 20, "สรุปการปฏิบัติงาน", "center");

            doc.setFontSize(16);
            doc.setFont("THSarabunNew", "normal");
            doc.text(
              105,
              40,
              `ระหว่าง : ${moment(reportDate.startDate).format(
                "DD/MM/YYYY"
              )} - ${moment(reportDate.endDate).format("DD/MM/YYYY")}`,
              "center"
            );

            doc.rect(10, 50, 190, 8);
            doc.setFontSize(14);
            doc.setFont("THSarabunNew", "bold");

            doc.setTextColor(255, 255, 255);

            doc.setFillColor(0, 43, 97);
            doc.rect(10, 50, 70, 8, "FD");
            doc.text(45, 55.5, `ชื่อพนักงาน`, "center");

            doc.setFillColor(0, 43, 97);
            doc.rect(80, 50, 30, 8, "FD");
            doc.text(95, 55.5, `รหัสพนักงาน`, "center");

            doc.setFillColor(0, 43, 97);
            doc.rect(110, 50, 30, 8, "FD");
            doc.text(125, 55.5, `ปฏิบัติงานทั้งหมด`, "center");

            doc.setFillColor(0, 204, 102);
            doc.rect(140, 50, 20, 8, "FD");
            doc.text(150, 55.5, `ผ่าน`, "center");

            doc.setFillColor(255, 0, 0);
            doc.rect(160, 50, 20, 8, "FD");
            doc.text(170, 55.5, `ไม่ผ่าน`, "center");

            doc.setFillColor(0, 43, 97);
            doc.rect(180, 50, 20, 8, "FD");
            doc.text(190, 55.5, `เปอร์เซ็น`, "center");

            doc.setTextColor(0, 0, 0);
            doc.setFont("THSarabunNew", "normal");

            y = 58;
            yt = 63.5;

            pageNumber += 1;
            doc.text(200, 291, `หน้า ${pageNumber}`, "right");
          }

          if (index % 28 === 0 && index !== 0 && index !== 28) {
            doc.addPage("p");

            // doc.rect(10, 20, 190, 8);
            // doc.setFontSize(14);
            // doc.setFont("THSarabunNew", "bold");

            // doc.setTextColor(255, 255, 255);

            // doc.setFillColor(0, 43, 97);
            // doc.rect(10, 20, 70, 8, "FD");
            // doc.text(45, 25.5, `ชื่อพนักงาน`, "center");

            // doc.setFillColor(0, 43, 97);
            // doc.rect(80, 20, 30, 8, "FD");
            // doc.text(95, 25.5, `รหัสพนักงาน`, "center");

            // doc.setFillColor(0, 43, 97);
            // doc.rect(110, 20, 30, 8, "FD");
            // doc.text(125, 25.5, `ปฏิบัติงานทั้งหมด`, "center");

            // doc.setFillColor(0, 204, 102);
            // doc.rect(140, 20, 20, 8, "FD");
            // doc.text(150, 25.5, `ผ่าน`, "center");

            // doc.setFillColor(255, 0, 0);
            // doc.rect(160, 20, 20, 8, "FD");
            // doc.text(170, 25.5, `ไม่ผ่าน`, "center");

            // doc.setFillColor(0, 43, 97);
            // doc.rect(180, 20, 20, 8, "FD");
            // doc.text(190, 25.5, `เปอร์เซ็น`, "center");

            // y = 28;
            // yt = 33;
            // doc.setTextColor(0, 0, 0);
            // doc.setFont("THSarabunNew", "normal");

            doc.addImage(img, "JPEG", 15, 15, 25, 25);

            doc.setFontSize(25);
            doc.setFont("THSarabunNew", "bold");
            doc.text(105, 20, "สรุปการปฏิบัติงาน", "center");

            doc.setFontSize(16);
            doc.setFont("THSarabunNew", "normal");
            doc.text(
              105,
              40,
              `ระหว่าง : ${moment(reportDate.startDate).format(
                "DD/MM/YYYY"
              )} - ${moment(reportDate.endDate).format("DD/MM/YYYY")}`,
              "center"
            );

            doc.rect(10, 50, 190, 8);
            doc.setFontSize(14);
            doc.setFont("THSarabunNew", "bold");

            doc.setTextColor(255, 255, 255);

            doc.setFillColor(0, 43, 97);
            doc.rect(10, 50, 70, 8, "FD");
            doc.text(45, 55.5, `ชื่อพนักงาน`, "center");

            doc.setFillColor(0, 43, 97);
            doc.rect(80, 50, 30, 8, "FD");
            doc.text(95, 55.5, `รหัสพนักงาน`, "center");

            doc.setFillColor(0, 43, 97);
            doc.rect(110, 50, 30, 8, "FD");
            doc.text(125, 55.5, `ปฏิบัติงานทั้งหมด`, "center");

            doc.setFillColor(0, 204, 102);
            doc.rect(140, 50, 20, 8, "FD");
            doc.text(150, 55.5, `ผ่าน`, "center");

            doc.setFillColor(255, 0, 0);
            doc.rect(160, 50, 20, 8, "FD");
            doc.text(170, 55.5, `ไม่ผ่าน`, "center");

            doc.setFillColor(0, 43, 97);
            doc.rect(180, 50, 20, 8, "FD");
            doc.text(190, 55.5, `เปอร์เซ็น`, "center");

            doc.setTextColor(0, 0, 0);
            doc.setFont("THSarabunNew", "normal");

            y = 58;
            yt = 63.5;

            pageNumber += 1;
            doc.text(200, 291, `หน้า ${pageNumber}`, "right");
          }

          doc.rect(10, y, 190, 8);

          doc.rect(10, y, 70, 8);
          doc.text(12, yt, `${empAll[index].emp_name || ""}`, "left");

          doc.rect(80, y, 30, 8);
          doc.text(95, yt, `${empAll[index].emp_no || ""}`, "center");

          doc.rect(110, y, 30, 8);
          doc.text(125, yt, `${empAll[index].total_week}`, "center");

          doc.rect(140, y, 20, 8);
          doc.text(150, yt, `${empAll[index].pass}`, "center");

          doc.rect(160, y, 20, 8);
          doc.text(170, yt, `${empAll[index].fail}`, "center");

          doc.rect(180, y, 20, 8);
          doc.text(
            190,
            yt,
            `${
              empAll[index].persent_pass === null
                ? 0
                : empAll[index].persent_pass
            }%`,
            "center"
          );

          y += 8;
          yt += 8;
        }

        window.open(doc.output("bloburl"));
        Swal.close();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  // async function getResultEvaluate() {
  //   const tempData = {
  //     employee_id: getUser().fup,
  //     oem_id: getOem(),
  //     company_id: getUser().com,
  //     year: searchYear.year,
  //   };

  //   await axios({
  //     method: "post",
  //     url:
  //       Configs.API_URL_hrMagenatement + "/api/hrManagement/getResultEvaluate",
  //     headers: {
  //       Authorization: getToken(),
  //       "X-TTT": Configs.API_TTT,
  //       "Content-Type": "application/json",
  //     },
  //     data: tempData,
  //   })
  //     .then(async function (response) {
  //       const dataChartREW = [];
  //       const dataChartROS = [];
  //       let greenSuccessData = 0;
  //       let yellowSuccessData = 0;
  //       let redSuccessData = 0;
  //       let scoreOfMonth = 0;
  //       let monthCheck = 0;

  //       for (let index = 0; index < 52; index++) {
  //         const data = response.data.data.filter((item) => {
  //           return item.week == index + 1;
  //         });

  //         if (data.length > 0) {
  //           let number = 0;

  //           if (
  //             data[0].week_status_id === "7a232969-76c8-4133-bc0a-62079d01d10b"
  //           ) {
  //             number = 2;
  //             greenSuccessData += 1;
  //             scoreOfMonth += 3;
  //           } else if (
  //             data[0].week_status_id === "fa9561cb-aa94-4ba5-93fa-a774cd5b84fa"
  //           ) {
  //             number = 1;
  //             yellowSuccessData += 1;
  //             scoreOfMonth += 1;
  //           } else if (
  //             data[0].week_status_id === "d4edf04e-94c3-4b26-aad8-62b0906bfc55"
  //           ) {
  //             number = -1;
  //             redSuccessData += 1;
  //             scoreOfMonth -= 1;
  //           }

  //           dataChartREW.push(number);
  //           dataChartROS.push(scoreOfMonth);
  //         } else {
  //           dataChartREW.push(0);

  //           if (searchYear.year < saveCurrentDate.year) {
  //             dataChartROS.push(scoreOfMonth);
  //           } else if (
  //             searchYear.year == saveCurrentDate.year &&
  //             index + 1 <= saveCurrentDate.week
  //           ) {
  //             dataChartROS.push(scoreOfMonth);
  //           }
  //         }

  //         // if (saveCurrentDate.week <= data[0].week && saveCurrentDate.year <= data[0].year) {
  //         //   dataChartROS.push(scoreOfMonth);
  //         // }
  //       }

  //       setResultEachWeekData({
  //         data: dataChartREW,
  //       });
  //       resultEachWeekData.data = dataChartREW;

  //       setResultSuccessData({
  //         greenSuccess: greenSuccessData,
  //         yellowSeccess: yellowSuccessData,
  //         redFail: redSuccessData,
  //         data: dataChartROS,
  //       });
  //       resultSuccessData.greenSuccess = greenSuccessData;
  //       resultSuccessData.yellowSeccess = yellowSuccessData;
  //       resultSuccessData.redFail = redSuccessData;
  //       resultSuccessData.data = dataChartROS;
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       // Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
  //     });
  // }

  async function getResultEvaluateAll() {
    const tempData = {
      employee_id: getUser().fup,
      oem_id: getOem(),
      company_id: getUser().com,
      year: searchYear.year,
    };

    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getResultEvaluateScore",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async function (response) {
        const dataChartREW = [];
        const dataChartROS = [];
        const dataChartROSAll = [];
        const dataChartROSDropDown = [];

        let greenSuccessData = 0;
        let yellowSuccessData = 0;
        let redSuccessData = 0;
        let scoreOfMonth = 0;

        response.data.data.sort((a, b) => {
          if (a.empNo === null) {
            return 1;
          }

          if (b.empNo === null) {
            return -1;
          }

          if (a.empNo.toLowerCase() === b.empNo.toLowerCase()) {
            return 0;
          }

          return a.empNo.toLowerCase() < b.empNo.toLowerCase() ? -1 : 1;
        });

        for (let item of response.data.data) {
          dataChartROSDropDown.push({ value: item.empId, label: item.empName });

          const tempData = {
            id: item.empId,
            name: item.empName,
            greenSuccess: 0,
            yellowSeccess: 0,
            redFail: 0,
            data: [],
          };

          let scoreOfMonthMember = 0;
          for (let index = 0; index < 52; index++) {
            const data = item.empData.filter((item) => {
              return item.week == index + 1;
            });

            if (data.length > 0) {
              let number = 0;

              if (
                data[0].week_status_id ===
                "7a232969-76c8-4133-bc0a-62079d01d10b"
              ) {
                if (item.empId === getUser().fup) {
                  number = 2;
                  greenSuccessData += 1;
                  scoreOfMonth += 3;
                }

                tempData.greenSuccess += 1;
                scoreOfMonthMember += 3;
              } else if (
                data[0].week_status_id ===
                "fa9561cb-aa94-4ba5-93fa-a774cd5b84fa"
              ) {
                if (item.empId === getUser().fup) {
                  number = 1;
                  yellowSuccessData += 1;
                  scoreOfMonth += 1;
                }

                tempData.yellowSeccess += 1;
                scoreOfMonthMember += 1;
              } else if (
                data[0].week_status_id ===
                "d4edf04e-94c3-4b26-aad8-62b0906bfc55"
              ) {
                if (item.empId === getUser().fup) {
                  number = -1;
                  redSuccessData += 1;
                  scoreOfMonth -= 1;
                }

                tempData.redFail += 1;
                scoreOfMonthMember -= 1;
              }

              if (item.empId === getUser().fup) {
                dataChartREW.push(number);
                dataChartROS.push(scoreOfMonth);
              }

              tempData.data.push(scoreOfMonthMember);
            } else {
              if (item.empId === getUser().fup) {
                dataChartREW.push(0);

                if (searchYear.year < saveCurrentDate.year) {
                  dataChartROS.push(scoreOfMonth);
                } else if (
                  searchYear.year == saveCurrentDate.year &&
                  index + 1 <= saveCurrentDate.week
                ) {
                  dataChartROS.push(scoreOfMonth);
                }
              }

              if (searchYear.year < saveCurrentDate.year) {
                tempData.data.push(scoreOfMonthMember);
              } else if (
                searchYear.year == saveCurrentDate.year &&
                index + 1 <= saveCurrentDate.week
              ) {
                tempData.data.push(scoreOfMonthMember);
              }
            }
          }

          dataChartROSAll.push(tempData);
        }

        // console.log(dataChartREW);
        // console.log(dataChartROS);
        // console.log(dataChartROSAll);

        setResultEachWeekData({
          data: dataChartREW,
        });
        resultEachWeekData.data = dataChartREW;

        // setResultSuccessData({
        //   greenSuccess: greenSuccessData,
        //   yellowSeccess: yellowSuccessData,
        //   redFail: redSuccessData,
        //   data: dataChartROS,
        // });
        // resultSuccessData.greenSuccess = greenSuccessData;
        // resultSuccessData.yellowSeccess = yellowSuccessData;
        // resultSuccessData.redFail = redSuccessData;
        // resultSuccessData.data = dataChartROS;

        const showData = dataChartROSAll.filter((item) => {
          return item.id === getUser().fup;
        });

        // console.log(showData[0]);

        setResultSuccessDataAll({
          ...resultSuccessDataAll,
          // selectDropDown: getUser().fup,
          selectDropDown: [
            dataChartROSDropDown.find((item) => {
              return item.value === getUser().fup;
            }),
          ],
          dropdown: dataChartROSDropDown,
          dataList: dataChartROSAll,
          showData: showData,
        });
        resultSuccessDataAll.selectDropDown = [
          dataChartROSDropDown.find((item) => {
            return item.value === getUser().fup;
          }),
        ];
        resultSuccessDataAll.dropdown = dataChartROSDropDown;
        resultSuccessDataAll.dataList = dataChartROSAll;
        resultSuccessDataAll.showData = showData;
      })
      .catch(function (error) {
        console.log(error);
        // Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  useEffect(async () => {
    const currentDate = new Date();
    setAllWeek(moment().year(year || moment(currentDate).year()).weeksInYear());
    setShowWeek(week || moment(currentDate).isoWeekday(1).week());
    setShowYear(year || moment(currentDate).year());

    setSearchWeek({ ...searchWeek, week: week || moment(currentDate).isoWeekday(1).week() });
    setSearchYear({ ...searchYear, year: year || moment(currentDate).year() });
    searchWeek.week = week || moment(currentDate).isoWeekday(1).week();
    searchYear.year = year || moment(currentDate).year();

    setShowDate({
      ...showDate,
      startDate: moment().year(year || moment(currentDate).year()).week(week || moment(currentDate).isoWeekday(1).week()).clone().startOf('week').add(1, "days").date(),
      startMonth: moment().year(year || moment(currentDate).year()).week(week || moment(currentDate).isoWeekday(1).week()).clone().startOf('week').add(1, "days").locale("en").format("MMM"),
      endDate: moment().year(year || moment(currentDate).year()).week(week || moment(currentDate).isoWeekday(1).week()).clone().endOf('week').subtract(1, "days").date(),
      endMonth: moment().year(year || moment(currentDate).year()).week(week || moment(currentDate).isoWeekday(1).week()).clone().endOf('week').subtract(1, "days").locale("en").format("MMM"),
      year: moment().year(year || moment(currentDate).year()).week(week || moment(currentDate).isoWeekday(1).week()).clone().endOf('week').subtract(1, "days").year(),
    });
    showDate.startDate = moment().year(year || moment(currentDate).year()).week(week || moment(currentDate).isoWeekday(1).week()).clone().startOf('week').add(1, "days").date();
    showDate.startMonth = moment().year(year || moment(currentDate).year()).week(week || moment(currentDate).isoWeekday(1).week()).clone().startOf('week').add(1, "days").locale("en").format("MMM");
    showDate.endDate = moment().year(year || moment(currentDate).year()).week(week || moment(currentDate).isoWeekday(1).week()).clone().endOf('week').subtract(1, "days").date();
    showDate.endMonth = moment().year(year || moment(currentDate).year()).week(week || moment(currentDate).isoWeekday(1).week()).clone().endOf('week').subtract(1, "days").locale("en").format("MMM");
    showDate.year = moment().year(year || moment(currentDate).year()).week(week || moment(currentDate).isoWeekday(1).week()).clone().endOf('week').subtract(1, "days").year();

    setSaveCurrentDate({
      ...saveCurrentDate,
      startDate: moment().year(year || moment(currentDate).year()).week(week || moment(currentDate).isoWeekday(1).week()).clone().startOf('week').add(1, "days"),
      endDate: moment().year(year || moment(currentDate).year()).week(week || moment(currentDate).isoWeekday(1).week()).clone().endOf('week').subtract(1, "days"),
      week: moment(currentDate).isoWeekday(1).week(),
      year: moment().year(year || moment(currentDate).year()).week(week || moment(currentDate).isoWeekday(1).week()).clone().endOf('week').subtract(1, "days").year(),
    });
    saveCurrentDate.startDate = moment().year(year || moment(currentDate).year()).week(week || moment(currentDate).isoWeekday(1).week()).clone().startOf('week').add(1, "days");
    saveCurrentDate.endDate = moment().year(year || moment(currentDate).year()).week(week || moment(currentDate).isoWeekday(1).week()).clone().endOf('week').subtract(1, "days");
    saveCurrentDate.week = moment(currentDate).isoWeekday(1).week();
    saveCurrentDate.year = moment().year(year || moment(currentDate).year()).week(week || moment(currentDate).isoWeekday(1).week()).clone().endOf('week').subtract(1, "days").year();

    await GetEmployeeWorkType();
    await FilterEmployee();
    await FilterTask();
    await FilterEvaluate();
    // await getResultEvaluate();
    await getResultEvaluateAll();
  }, []);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      windowSize.width = window.innerWidth;
      windowSize.height = window.innerHeight;

      if (window.innerWidth <= 600) {
        setMode({
          data: "design",
        });
        mode.data = "design";
      }

      // setElementSize({
      //   width: document.getElementById(`slider-${id}`).offsetWidth,
      // });
      // elementSize.width = document.getElementById(`slider-${id}`).offsetWidth;
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  function addToDo(params) {
    window.location.href = `/Human_Resource/to_do_list/assign_work/addt/${searchWeek.week}/${searchYear.year}`;
  }

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  งานที่ได้รับมอบหมาย{"  "}
                  {/* <a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a> */}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">Task</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group" style={{ zIndex: "11" }}>
                  <Select
                    options={employeeOption.data}
                    value={employeeOption.data.filter((e) => {
                      return e.value === inputData.assign_by_id;
                    })}
                    onChange={(e) => {
                      if (e !== null) {
                        setInputData({
                          ...inputData,
                          assign_by_id: e.value,
                        });
                      }
                    }}
                  />
                  <label htmlFor="">Assign By</label>
                </div>
              </div>

              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    id="text_empname"
                    value={inputData.task}
                    onChange={(e) => {
                      setInputData({
                        ...inputData,
                        task: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Task</label>{" "}
                </div>
              </div>

              <div className="col-12 col-md-4 col-xl-3">
                <div className="row">
                  <div className="col-2 col-xl-3">
                    <div className="form-group">
                      <button
                        type="button"
                        id="btn_clear"
                        className="btn btn-block btn-primary"
                        style={{ width: "auto" }}
                        onClick={async () => {
                          Number(showWeek) === 1 ? setShowWeek(Number(allWeek)) : setShowWeek(Number(showWeek) - 1);

                          setShowDate({
                            ...showDate,
                            startDate: moment().year(Number(showYear)).week(Number(showWeek) - 1).clone().startOf('week').add(1, "days").date(),
                            startMonth: moment().year(Number(showYear)).week(Number(showWeek) - 1).clone().startOf('week').add(1, "days").locale("en").format("MMM"),
                            endDate: moment().year(Number(showYear)).week(Number(showWeek) - 1).clone().endOf('week').subtract(1, "days").date(),
                            endMonth: moment().year(Number(showYear)).week(Number(showWeek) - 1).clone().endOf('week').subtract(1, "days").locale("en").format("MMM"),
                            year: moment().year(Number(showYear)).week(Number(showWeek) - 1).clone().endOf('week').subtract(1, "days").year(),
                          });

                          setSearchWeek({
                            ...searchWeek,
                            week: Number(showWeek) === 1 ? Number(allWeek) : Number(showWeek) - 1,
                          });
                          setSearchYear({ 
                            ...searchYear,
                            year: showYear,
                          });
                          searchWeek.week = Number(showWeek) === 1 ? Number(allWeek) : Number(showWeek) - 1;
                          searchYear.year = showYear;

                          await FilterTask();
                          await FilterEvaluate();
                        }}
                      >
                        <span
                          className="fa fa-chevron-left"
                          style={{ verticalAlign: "middle" }}
                        />
                      </button>
                    </div>
                  </div>

                  <div className="col-8 col-xl-6">
                    <div className="row">
                      <div className="col">
                        <div
                          style={{
                            position: "relative",
                            padding: "10px 0px",
                            marginBottom: "1rem",
                            textAlign: "center",
                          }}
                        >
                          <label
                            style={{
                              fontSize: "1.4rem",
                              margin: "4px 0px 0px 0px",
                            }}
                          >
                            Week
                          </label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            style={{
                              textAlign: "center",
                            }}
                            onChange={(e) => {
                              if (
                                Number(e.target.value) < 1 && e.target.value !== ""
                              ) {
                                setShowWeek(1);
                              } else if (
                                Number(e.target.value) > Number(allWeek) && e.target.value !== ""
                              ) {
                                setShowWeek(Number(allWeek));
                              } else {
                                setShowWeek(e.target.value);
                              }

                              setShowDate({
                                ...showDate,
                                startDate: moment().year(Number(showYear)).week(Number(e.target.value) < 1 && e.target.value !== "" ? 1 : Number(e.target.value) > allWeek && e.target.value !== "" ? Number(allWeek) : Number(e.target.value)).clone().startOf('week').add(1, "days").date(),
                                startMonth: moment().year(Number(showYear)).week(Number(e.target.value) < 1 && e.target.value !== "" ? 1 : Number(e.target.value) > allWeek && e.target.value !== "" ? Number(allWeek) : Number(e.target.value)).clone().startOf('week').add(1, "days").locale("en").format("MMM"),
                                endDate: moment().year(Number(showYear)).week(Number(e.target.value) < 1 && e.target.value !== "" ? 1 : Number(e.target.value) > allWeek && e.target.value !== "" ? Number(allWeek) : Number(e.target.value)).clone().startOf('week').subtract(1, "days").date(),
                                endMonth: moment().year(Number(showYear)).week(Number(e.target.value) < 1 && e.target.value !== "" ? 1 : Number(e.target.value) > allWeek && e.target.value !== "" ? Number(allWeek) : Number(e.target.value)).clone().startOf('week').subtract(1, "days").locale("en").format("MMM"),
                                year: moment().year(Number(showYear)).week(Number(e.target.value) < 1 && e.target.value !== "" ? 1 : Number(e.target.value) > allWeek && e.target.value !== "" ? Number(allWeek) : Number(e.target.value)).clone().startOf('week').add(1, "days").month() > moment().year(Number(showYear)).week(Number(e.target.value) < 1 && e.target.value !== "" ? 1 : Number(e.target.value) > allWeek && e.target.value !== "" ? Number(allWeek) : Number(e.target.value)).clone().startOf('week').subtract(1, "days").month()
                                  ? moment().year(Number(showYear)).week(Number(e.target.value) < 1 && e.target.value !== "" ? 1 : Number(e.target.value) > allWeek && e.target.value !== "" ? Number(allWeek) : Number(e.target.value)).clone().startOf('week').subtract(1, "days").year() + 1
                                  : moment().year(Number(showYear)).week(Number(e.target.value) < 1 && e.target.value !== "" ? 1 : Number(e.target.value) > allWeek && e.target.value !== "" ? Number(allWeek) : Number(e.target.value)).clone().startOf('week').subtract(1, "days").year(),
                              });
                            }}
                            value={showWeek}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-2 col-xl-3"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <div className="form-group">
                      <button
                        type="button"
                        id="btn_clear"
                        className="btn btn-block btn-primary"
                        style={{ width: "auto" }}
                        onClick={async () => {
                          Number(showWeek) === Number(allWeek) ? setShowWeek(1) : setShowWeek(Number(showWeek) + 1);

                          setShowDate({
                            ...showDate,
                            startDate: moment().year(Number(showYear)).week(Number(showWeek) + 1).clone().startOf('week').add(1, "days").date(),
                            startMonth: moment().year(Number(showYear)).week(Number(showWeek) + 1).clone().startOf('week').add(1, "days").locale("en").format("MMM"),
                            endDate: moment().year(Number(showYear)).week(Number(showWeek) + 1).clone().endOf('week').subtract(1, "days").date(),
                            endMonth: moment().year(Number(showYear)).week(Number(showWeek) + 1).clone().endOf('week').subtract(1, "days").locale("en").format("MMM"),
                            year: moment().year(Number(showYear)).week(Number(showWeek) + 1).clone().endOf('week').subtract(1, "days").year(),
                          });

                          setSearchWeek({
                            ...searchWeek,
                            week: Number(showWeek) === Number(allWeek) ? 1 : Number(showWeek) + 1,
                          });
                          setSearchYear({ 
                            ...searchYear,
                            year: showYear,
                          });
                          searchWeek.week = Number(showWeek) === Number(allWeek) ? 1 : Number(showWeek) + 1;
                          searchYear.year = showYear;

                          await FilterTask();
                          await FilterEvaluate();
                        }}
                      >
                        <span
                          className="fa fa-chevron-right"
                          style={{ verticalAlign: "middle" }}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-4 col-xl-3">
                <div className="row">
                  <div className="col-2">
                    <div className="form-group">
                      <button
                        type="button"
                        id="btn_clear"
                        className="btn btn-block btn-primary"
                        style={{ width: "auto" }}
                        onClick={async () => {
                          setShowYear(Number(showYear) - 1);
                          setAllWeek(moment().year(Number(showYear) - 1).weeksInYear());

                          if (moment().year(Number(showYear) + 1).weeksInYear() < allWeek && moment().year(Number(showYear) + 1).weeksInYear() < showWeek) {
                            setShowWeek(moment().year(Number(showYear) - 1).weeksInYear());
                          }

                          setShowDate({
                            ...showDate,
                            startDate: moment().year(Number(showYear) - 1).week(Number(showWeek)).clone().startOf('week').add(1, "days").date(),
                            startMonth: moment().year(Number(showYear) - 1).week(Number(showWeek)).clone().startOf('week').add(1, "days").locale("en").format("MMM"),
                            endDate: moment().year(Number(showYear) - 1).week(Number(showWeek)).clone().endOf('week').subtract(1, "days").date(),
                            endMonth: moment().year(Number(showYear) - 1).week(Number(showWeek)).clone().endOf('week').subtract(1, "days").locale("en").format("MMM"),
                            year: moment().year(Number(showYear) - 1).week(Number(showWeek)).clone().endOf('week').subtract(1, "days").year(),
                          });

                          setSearchWeek({
                            ...searchWeek,
                            week: showWeek,
                          });
                          setSearchYear({
                            ...searchYear,
                            year: Number(showYear) - 1,
                          });
                          searchWeek.week = showWeek;
                          searchYear.year = Number(showYear) - 1;

                          await FilterTask();
                          await FilterEvaluate();
                          // await getResultEvaluate();
                          await getResultEvaluateAll();
                        }}
                      >
                        <span
                          className="fa fa-chevron-left"
                          style={{ verticalAlign: "middle" }}
                        />
                      </button>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="row">
                      <div className="col">
                        <div
                          style={{
                            position: "relative",
                            padding: "10px 0px",
                            marginBottom: "1rem",
                            textAlign: "center",
                          }}
                        >
                          <label
                            style={{
                              fontSize: "1.4rem",
                              margin: "4px 0px 0px 0px",
                            }}
                          >
                            Year
                          </label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            style={{
                              textAlign: "center",
                            }}
                            onChange={(e) => {
                              setShowYear(e.target.value);
                              setAllWeek(moment().year(Number(e.target.value)).weeksInYear());
    
                              if (moment().year(Number(e.target.value)).weeksInYear() < allWeek) {
                                setShowWeek(moment().year(Number(e.target.value)).weeksInYear());
                              }
    
                              setShowDate({
                                ...showDate,
                                startDate: moment().year(Number(e.target.value)).week(Number(showWeek)).clone().startOf('week').add(1, "days").date(),
                                startMonth: moment().year(Number(e.target.value)).week(Number(showWeek)).clone().startOf('week').add(1, "days").locale("en").format("MMM"),
                                endDate: moment().year(Number(e.target.value)).week(Number(showWeek)).clone().endOf('week').subtract(1, "days").date(),
                                endMonth: moment().year(Number(e.target.value)).week(Number(showWeek)).clone().endOf('week').subtract(1, "days").locale("en").format("MMM"),
                                year: moment().year(Number(e.target.value)).week(Number(showWeek)).clone().endOf('week').subtract(1, "days").year(),
                              });
                            }}
                            value={showYear}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-2"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <div className="form-group">
                      <button
                        type="button"
                        id="btn_clear"
                        className="btn btn-block btn-primary"
                        style={{ width: "auto" }}
                        onClick={async () => {
                          setShowYear(Number(showYear) + 1);
                          setAllWeek(moment().year(Number(showYear) + 1).weeksInYear());

                          if (moment().year(Number(showYear) + 1).weeksInYear() < allWeek && moment().year(Number(showYear) + 1).weeksInYear() < showWeek) {
                            setShowWeek(moment().year(Number(showYear) + 1).weeksInYear());
                          }

                          setShowDate({
                            ...showDate,
                            startDate: moment().year(Number(showYear) + 1).week(Number(showWeek)).clone().startOf('week').add(1, "days").date(),
                            startMonth: moment().year(Number(showYear) + 1).week(Number(showWeek)).clone().startOf('week').add(1, "days").locale("en").format("MMM"),
                            endDate: moment().year(Number(showYear) + 1).week(Number(showWeek)).clone().endOf('week').subtract(1, "days").date(),
                            endMonth: moment().year(Number(showYear) + 1).week(Number(showWeek)).clone().endOf('week').subtract(1, "days").locale("en").format("MMM"),
                            year: moment().year(Number(showYear) + 1).week(Number(showWeek)).clone().endOf('week').subtract(1, "days").year(),
                          });

                          setSearchWeek({
                            ...searchWeek,
                            week: showWeek,
                          });
                          setSearchYear({
                            ...searchYear,
                            year: Number(showYear) + 1,
                          });
                          searchWeek.week = showWeek;
                          searchYear.year = Number(showYear) + 1;

                          await FilterTask();
                          await FilterEvaluate();
                          // await getResultEvaluate();
                          await getResultEvaluateAll();
                        }}
                      >
                        <span
                          className="fa fa-chevron-right"
                          style={{ verticalAlign: "middle" }}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-4 col-xl-2">
                <div
                  style={{
                    position: "relative",
                    padding: "10px 0px",
                    marginBottom: "1rem",
                    textAlign: "center",
                  }}
                >
                  <label
                    style={{
                      fontSize: "1.4rem",
                      margin: "4px 0px 0px 0px",
                    }}
                  >
                    {`${showDate.startDate} ${showDate.startMonth} - ${showDate.endDate} ${showDate.endMonth} ${showDate.year}`}
                  </label>
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-primary"
                    onClick={async () => {
                      setSearchWeek({
                        ...searchWeek,
                        week: showWeek,
                      });
                      setSearchYear({
                        ...searchYear,
                        year: showYear,
                      });
                      searchWeek.week = showWeek;
                      searchYear.year = showYear;

                      await FilterTask();
                      await FilterEvaluate();
                      // await getResultEvaluate();
                      await getResultEvaluateAll();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_clear"
                    className="btn btn-block btn-primary"
                    onClick={clearFilter}
                  >
                    Clear
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-primary"
                    onClick={addToDo}
                  >
                    Add To Do
                  </button>
                </div>
              </div>
              {employeeData.data.find((item) => {
                return (
                  item.emp_id === getUser().fup &&
                  (item.emp_position_id ===
                    "e0fae6c3-0dc7-4f7b-9b96-12db0d8d89de" ||
                    item.emp_position_id ===
                      "4fe7a230-980c-46db-95a1-9e2f1253785d" ||
                    item.emp_position_id ===
                      "71aad960-49d9-4fca-aeed-d13ccb48a20b")
                );
              }) ? (
                <div className="col-6 col-md-4 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      id="btn_clear"
                      className="btn btn-block btn-primary"
                      data-toggle="modal"
                      data-target="#modal-sm"
                      onClick={async () => {
                        setReportDate({
                          startDate: null,
                          endDate: null,
                        });
                      }}
                    >
                      Summary Report
                    </button>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      setCumulativeModal(true);
                    }}
                  >
                    Cumulative Graph
                  </button>
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-12 col-md-12 col-xl-4">
                <div
                  style={{
                    position: "relative",
                    // padding: "10px 0px",
                    margin: "10px 0px",
                    marginBottom: "1rem",
                    textAlign: "center",
                    // minWidth: "60px",
                    height: "37.6px",
                  }}
                  className={`badge btn-block widthSizeStatus 
                    ${
                      evaluateData.data.length === 0
                        ? `badge-dark`
                        : evaluateData.data[0].week_status_name === "Over-Expec"
                        ? `badge-success`
                        : evaluateData.data[0].week_status_name === "Pass"
                        ? `badge-warning`
                        : evaluateData.data[0].week_status_name === "Fail"
                        ? `badge-danger`
                        : ``
                    }`}
                >
                  <label
                    style={{
                      fontSize: "1.4rem",
                      margin: "4px 0px 0px 0px",
                    }}
                  >
                    Status :{" "}
                    {evaluateData.data.length === 0 ? (
                      checkWeek ? (
                        "In Process"
                      ) : (
                        ""
                      )
                    ) : evaluateData.data[0].week_status_name ===
                      "Over-Expec" ? (
                      <label>{evaluateData.data[0].week_status_name}</label>
                    ) : evaluateData.data[0].week_status_name === "Pass" ? (
                      <label>{evaluateData.data[0].week_status_name}</label>
                    ) : evaluateData.data[0].week_status_name === "Fail" ? (
                      <label>{evaluateData.data[0].week_status_name}</label>
                    ) : (
                      <></>
                    )}
                  </label>
                </div>

                <div
                  style={{
                    position: "relative",
                    padding: "0px 10px",
                    margin: "10px 0px",
                    marginBottom: "1rem",
                    // textAlign: "center",
                    // minWidth: "60px",
                    // height: "37.6px",
                  }}
                >
                  <label
                    style={{
                      fontSize: "1.2rem",
                      margin: "4px 0px 0px 0px",
                    }}
                  >
                    หมายเหตุ :{" "}
                    {evaluateData.data.length === 0
                      ? ""
                      : evaluateData.data[0].remark}{" "}
                    {evaluateData.data.length === 0
                      ? ""
                      : `( ${moment(evaluateData.data[0].update_date).format(
                          "DD/MM/YYYY :: HH.mm น."
                        )} )`}
                  </label>
                </div>
              </div>
              <div className="col-12 col-md-12 col-xl-8">
                <Result_Each_Weeks
                  resultEachWeekData={resultEachWeekData}
                  saveCurrentDate={saveCurrentDate}
                  allWeek={allWeek}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          {windowSize.width > 600 ? (
            <div className="row">
              <div className="col-12" style={{ textAlign: "right" }}>
                <button
                  className={`buttonIcon ${
                    mode.data === "design" ? "active" : ""
                  }`}
                  style={{ marginRight: "2.5px" }}
                  onClick={() => {
                    setMode({ data: "design" });
                  }}
                >
                  <i
                    className={`customSvgBasil ${
                      mode.data === "design" ? "active" : ""
                    }`}
                  />
                </button>
                <button
                  className={`buttonIcon ${
                    mode.data === "table" ? "active" : ""
                  }`}
                  style={{ marginLeft: "2.5px" }}
                  onClick={() => {
                    setMode({ data: "table" });
                  }}
                >
                  <i
                    className={`customSvgRadix ${
                      mode.data === "table" ? "active" : ""
                    }`}
                  />
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}

          {mode.data === "design" ? (
            <div
              style={{
                display: "grid",
                gridTemplateColumns:
                  windowSize.width > 600
                    ? "repeat(auto-fill, minmax(400px, 1fr))"
                    : "repeat(auto-fill, minmax(300px, 1fr))",
                justifyItems: "center",
                paddingBottom: "10px",
                gap: "10px",
              }}
            >
              {assignData.data.map((item) => {
                return (
                  <Task_Work_Card
                    data={item}
                    week={searchWeek.week}
                    year={searchYear.year}
                    DeleteAssign={DeleteAssign}
                    SentWork={SentWork}
                    saveCurrentDate={saveCurrentDate}
                  />
                );
              })}
            </div>
          ) : (
            <div className="card">
              <div className="card-header">
                {/* <h3 className="card-title">Project</h3> */}
                {/* <div className="card-tools"></div> */}
              </div>

              <div className="card-body">
                <div
                  className="table-responsive "
                  style={{ whiteSpace: "nowrap" }}
                >
                  <MDBDataTable
                    sortable={false}
                    className="table table-head-fixed text-center"
                    striped
                    bordered
                    hover
                    fixedHeader
                    data={data}
                  />
                </div>
              </div>
              <div className="card-footer" />
            </div>
          )}
        </section>

        <div className="modal fade" id="modal-sm">
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">รายงานสรุปผล</h4>

                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  //onClick={close}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <div class="form-group">
                        <DatePicker
                          selected={reportDate.startDate}
                          wrapperClassName="w-100"
                          locale="th"
                          id="text_start_work"
                          dateFormat={"dd-MM-yyyy"}
                          onChange={async (date) => {
                            setReportDate({
                              ...reportDate,
                              startDate: date,
                            });
                          }}
                          selectsStart
                          showYearDropdown
                          showMonthDropdown
                          maxDate={new Date()}
                          customInput={<CustomInput />}
                        />
                        <label>
                          เริ่มต้น
                          {reportDate.startDate === "" ||
                          reportDate.startDate === null ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div class="form-group">
                        <DatePicker
                          selected={reportDate.endDate}
                          wrapperClassName="w-100"
                          locale="th"
                          id="text_start_work"
                          dateFormat={"dd-MM-yyyy"}
                          onChange={async (date) => {
                            setReportDate({
                              ...reportDate,
                              endDate: date,
                            });
                          }}
                          selectsStart
                          showYearDropdown
                          showMonthDropdown
                          minDate={reportDate.startDate}
                          maxDate={new Date()}
                          customInput={<CustomInput />}
                          disabled={
                            reportDate.startDate === null ? true : false
                          }
                        />
                        <label>
                          สิ้นสุด
                          {reportDate.endDate === "" ||
                          reportDate.endDate === null ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer" style={{ justifyContent: "left" }}>
                <div className="row">
                  <div className="col-12">
                    <button
                      type="button"
                      onClick={ReportEvaluate}
                      class="btn btn-block btn-success "
                    >
                      ยืนยัน
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          size="xl"
          className="Modal_Center_LeftRight"
          isOpen={cumulativeModal}
          toggle={() => {
            setCumulativeModal(false);

            const showData = resultSuccessDataAll.dataList.filter((item) => {
              return item.id === getUser().fup;
            });

            setResultSuccessDataAll({
              ...resultSuccessDataAll,
              selectDropDown: [
                resultSuccessDataAll.dropdown.find((item) => {
                  return item.value === getUser().fup;
                }),
              ],
              showData: showData,
            });
            resultSuccessDataAll.selectDropDown = [
              resultSuccessDataAll.dropdown.find((item) => {
                return item.value === getUser().fup;
              }),
            ];
            resultSuccessDataAll.showData = showData;
          }}
        >
          <ModalHeader
            toggle={() => {
              setCumulativeModal(false);

              const showData = resultSuccessDataAll.dataList.filter((item) => {
                return item.id === getUser().fup;
              });

              setResultSuccessDataAll({
                ...resultSuccessDataAll,
                selectDropDown: [
                  resultSuccessDataAll.dropdown.find((item) => {
                    return item.value === getUser().fup;
                  }),
                ],
                showData: showData,
              });
              resultSuccessDataAll.selectDropDown = [
                resultSuccessDataAll.dropdown.find((item) => {
                  return item.value === getUser().fup;
                }),
              ];
              resultSuccessDataAll.showData = showData;
            }}
          >
            {/* <h4 className="modal-title" id="modalStockBalance">
              <b>Save Balance Stock</b>
            </h4> */}
          </ModalHeader>

          <ModalBody>
            <Cumulative_Graph
              // resultSuccessData={resultSuccessData}
              setResultSuccessDataAll={setResultSuccessDataAll}
              resultSuccessDataAll={resultSuccessDataAll}
              allWeek={allWeek}
            />
          </ModalBody>

          {/* <ModalFooter></ModalFooter> */}
        </Modal>
      </div>
    </div>
  );
}

export default Task_Work;
