import React, { useState, useEffect } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import SVGBarChart from "./SVGBarChart.svg";

import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function Result_Each_Weeks({ resultEachWeekData, saveCurrentDate, allWeek }) {
  if (saveCurrentDate?.week > 1) {
    if (
      saveCurrentDate?.week >=
      Math.ceil(
        allWeek /
          (document.getElementsByClassName("chartAreaWrapper")[0].scrollWidth /
            (document.getElementsByClassName("chartAreaWrapper")[0]
              .clientWidth -
              62.5))
      )
    ) {
      document.getElementsByClassName("chartAreaWrapper")[0].scrollLeft =
        62.5 +
        56.5 *
          (saveCurrentDate?.week -
            Math.ceil(
              allWeek /
                (document.getElementsByClassName("chartAreaWrapper")[0]
                  .scrollWidth /
                  (document.getElementsByClassName("chartAreaWrapper")[0]
                    .clientWidth -
                    62.5))
            ));
    }
  }

  const yLabels = {
    2: "เกินคาด",
    1: "ผ่าน",
    0: "รอประเมิน",
    "-1": "ไม่ผ่าน",
  };

  const [label, setLabel] = useState({
    data: [],
  });

  function getGradient(ctx, chartArea) {
    const red_gradient = ctx.createLinearGradient(
      0,
      chartArea.bottom,
      0,
      chartArea.top
    );
    red_gradient.addColorStop(0, "#4A000A");
    red_gradient.addColorStop(0.2, "#FF415C");
    red_gradient.addColorStop(0.4, "#FFFFFF00");

    const yellow_gradient = ctx.createLinearGradient(
      0,
      chartArea.bottom,
      0,
      chartArea.top
    );
    yellow_gradient.addColorStop(0, "#FFFFFF00");
    yellow_gradient.addColorStop(0.3, "#FFE794");
    yellow_gradient.addColorStop(0.6, "#D4A500");

    const green_gradient = ctx.createLinearGradient(
      0,
      chartArea.bottom,
      0,
      chartArea.top
    );
    green_gradient.addColorStop(0, "#FFFFFF00");
    green_gradient.addColorStop(0.5, "#7DEE7B");
    green_gradient.addColorStop(1, "#1DC256");

    return resultEachWeekData.data.map((item) =>
      item === 2
        ? green_gradient
        : item === 1
        ? yellow_gradient
        : item === -1
        ? red_gradient
        : ""
    );
  }

  const data = {
    labels: label.data,
    datasets: [
      {
        // label: "My First dataset",
        // fill: false,
        // lineTension: 0.1,
        // backgroundColor: "rgba(75,192,192,0.4)",
        // borderColor: "rgba(75,192,192,1)",
        // borderCapStyle: "butt",
        // borderDash: [],
        // borderDashOffset: 0.0,
        // borderJoinStyle: "miter",
        // pointBorderColor: "rgba(75,192,192,1)",
        // pointBackgroundColor: "#fff",
        // pointBorderWidth: 1,
        // pointHoverRadius: 5,
        // pointHoverBackgroundColor: "rgba(75,192,192,1)",
        // pointHoverBorderColor: "rgba(220,220,220,1)",
        // pointHoverBorderWidth: 2,
        // pointRadius: 1,
        // pointHitRadius: 10,
        // borderColor: ["#a3f1ff"],
        // borderWidth: 1,
        data: resultEachWeekData.data,
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea, scales } = chart;

          if (!chartArea) {
            return;
          }
          return getGradient(ctx, chartArea, scales);
        },
        // backgroundColor: datata.map((item) =>
        //   item === 2
        //     ? "green"
        //     : item === 1
        //     ? "yellow"
        //     : item === -1
        //     ? "red"
        //     : ""
        // ),
        borderRadius: 100,
        // label: "จำนวนวันที่มาทำงาน",
      },
    ],
  };

  function pad_2num(num) {
    if (num < 10) {
      return "0" + num.toString();
    } else {
      return num.toString();
    }
  }

  useEffect(async () => {
    const weekOfMonth = [];

    for (let index = 0; index < allWeek; index++) {
      weekOfMonth.push(`Week${pad_2num(index + 1)}`);
    }

    setLabel({
      data: weekOfMonth,
    });
    label.data = weekOfMonth;
  }, [allWeek]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          borderRadius: "10px",
          boxShadow: "0px 5.40741px 30px -10px rgba(0, 0, 0, 0.15)",
        }}
      >
        <div style={{ display: "flex", margin: "10px" }}>
          <img
            src={SVGBarChart}
            style={{ width: "46px", marginRight: "10px" }}
          />

          <div style={{ display: "flex", flexDirection: "column" }}>
            <label
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                lineHeight: "normal",
                display: "block",
                margin: "0px",
                color: "#000000",
              }}
            >
              Result each Weeks
            </label>

            <label
              style={{
                fontSize: "15px",
                fontWeight: "normal",
                lineHeight: "normal",
                display: "block",
                margin: "0px",
                color: "#A3A3A3",
              }}
            >
              ผลงานของคุณในแต่ละสัปดาห์
            </label>
          </div>
        </div>

        <div
          style={{
            margin: "10px 20px",
          }}
        >
          <div className="chartWrapper">
            <div className="chartAreaWrapper customScroll-2">
              <div className="chartAreaWrapper2">
                <Bar
                  id="chart-result-week"
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                    scales: {
                      y: {
                        min: -1,
                        max: 2,
                        ticks: {
                          stepSize: 1,
                          callback: function (value) {
                            return yLabels[value];
                          },
                        },
                        grid: {
                          borderDash: [8, 4],
                        },
                      },
                    },
                  }}
                  data={data}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Result_Each_Weeks;
