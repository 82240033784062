import React, { Component, useEffect, useState, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import "../../assets/css/Supp.css";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import "../../assets/css/table.css";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import { useParams } from "react-router";
import Sidebar from "../../component/Sidebar";
import { getOem, getToken, getUser, getAcademy } from "../../Utils/Common";
import Configs from "../../config";
import FormData from "form-data";
import axios from "axios";
import moment from "moment";
import NumberFormat from "react-number-format";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";
import LOGH from "../../component/log_detailHistory_feature";
import INF from "../../component/input_feature";
import Select from "react-select";

import jsPDF from "jspdf";
import { Get_font_pdf_th, Get_font_pdf_th2, Get_font_pdf_th2_bold } from "../../assets/font/pdf_font";
import QRCode2 from "qrcode";

function MatInOutCTS_mgt(mode) {
  const { material_id } = useParams();
  const [company, setCompany] = useState([]);
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);

  const [checkEdit, setCheckEdit] = useState(false);

  const [checkreport, setcheckreport] = useState(true);
  const [materail, setmaterail] = useState([]);
  const [getsup, setgetsup] = useState([]);

  const [material, setmaterial] = useState({
    material_no: "",
    material_name: "",
    material_amount: "",
    material_supplier_id: "",
    material_supplier_name: "",
  });

  const [material_process, setmaterial_process] = useState({
    edit_type: true,
    is_cer: false,
    coil_no: "",
    invoice_no: "",
    coil_no_target: "",
    invoice_no_target: "",
    supplier_id: "",
    amount: "",
    weight_tag: "",
    weight_actual: "",
    grade: "",
    unit: "",
    location: "",
    lot_no: "",
    log_id: "",
  });

  const [location_list, setlocation_list] = useState([]);
  const select_location_model = useRef();
  const [supplier_list, setsupplier_list] = useState([]);
  const select_supplier_model = useRef();
  const [lot_in_stock_list, setlot_in_stock_list] = useState([]);
  const [lot_in_stock_report, setlot_in_stock_report] = useState([]);
  const [lot_history_list, setlot_history_list] = useState([]);
  const [lotno, setlotno] = useState([]);
  const [coil_no_list, setcoil_no_list] = useState([]);
  const [coil_no_gen, setcoil_no_gen] = useState();
  const [coil_no_genDefault, setcoil_no_genDefault] = useState();
  const [isGen, setisGen] = useState(true);
  const [coil_no_select_list, setcoil_no_select_list] = useState([]);
  const [test, settest] = useState({
    test: "",
  });

  const genCoilNo = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/api/rawMatInOut/genCoilNo",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        setcoil_no_gen(parseInt(response.data));
        setcoil_no_genDefault(parseInt(response.data));

        var tempList = coil_no_list;
        setcoil_no_list([]);
        let temp = {
          coil_no: parseInt(response.data),
          weight_by_tag: "",
          old_coil_no: "",
        };
        tempList.push(temp);
        setcoil_no_list(tempList);
        settest({ ...test, test: "" });
        test.test = "";
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getMateralView = async () => {
    axios({
      method: "get",
      url:
        Configs.API_URL +
        "/api/rawMatInOut/getMaterialMGTById?material_id=" +
        material_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {
        setmaterial({
          ...material,
          material_no: response.data.material_no,
          material_name: response.data.material_name,
          material_amount: response.data.material_amount,
          material_supplier_id: response.data.material_supplier_id,
          material_supplier_name: response.data.material_supplier_name,
        });
        material.material_supplier_id = response.data.material_supplier_id;

        material_process.supplier_id = response.data.material_supplier_id;
        //console.log(response.data);
        await getMaterail(
          response.data.material_no,
          response.data.material_name
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getSupplier = async () => {
    let tmp = {
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url: Configs.API_URL + "/api/rawMatInOut/getSupplier",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tmp,
    })
      .then(function (response) {
        /*      setlocation_list(response.data); */
        setgetsup(response.data);
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name;
          options.push(element);
        });
        setsupplier_list(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getLocation = async () => {
    let tmp = {
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url: Configs.API_URL + "/api/rawMatInOut/getLocation",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tmp,
    })
      .then(function (response) {
        /*      setlocation_list(response.data); */
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.location;
          options.push(element);
        });
        setlocation_list(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getLotInStock = async () => {
    axios({
      method: "get",
      url:
        Configs.API_URL +
        "/api/rawMatInOut/getMaterialLotStockById?material_id=" +
        material_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //var sort_data_ = response.data.sort((a,b)=> moment(b.material_qc_date).format('YYYYMMDDHHMM') - moment(a.material_qc_date).format('YYYYMMDDHHMM'));
        var sort_data = response.data.sort(
          (a, b) => a.material_qc_status - b.material_qc_status
        );

        for (let i of sort_data) {
          i.selected1 = false
        }
        setlot_in_stock_list(sort_data);
        setlot_in_stock_report(sort_data);

        settest({ ...test, test: "" });
        test.test = "";
        //console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getLotHistory = async () => {
    axios({
      method: "get",
      url:
        Configs.API_URL +
        "/api/rawMatInOut/getHistoryLotById?material_id=" +
        material_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        setlot_history_list(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(async () => {
    await getMateralView();
    await getLocation();
    await getSupplier();
    await getLotInStock();
    await getLotHistory();
    await getLotNo();
  }, []);

  function coil_no_select(e) {
    var result = e.target.value.split(":::");
    var coil_no = result[0];
    var invoice_no = result[1];
    //console.log(coil_no,"------",invoice_no)
    setmaterial_process({
      ...material_process,
      coil_no_target: coil_no,
      invoice_no_target: invoice_no,
    });
    material_process.coil_no_target = coil_no;
    material_process.invoice_no_target = invoice_no;
  }

  function setChangeParam(value) {
    setmaterial_process({
      ...material_process,
      log_id: value.material_log_id,
      invoice_no: value.material_invoice_no,
      supplier_id: value.material_supplier_id,
      grade: value.material_grade || '',
      location: value.material_location_id,
      is_cer: value.material_is_certificate,
    });

    setmaterial({
      ...material,
      material_supplier_id: value.material_supplier_id,
    });

    var tempList = [];
    let temp = {
      coil_no: value.material_coil_no,
      weight_by_tag: value.material_weight_tag,
      supp_coil: value.material_supp_coil,
      old_coil_no: value.material_ref_coil_no,
    };
    tempList.push(temp);
    setcoil_no_list(tempList);
    settest({ ...test, test: "" });
    test.test = "";

    setCheckEdit(true);
  }

  const row_data_lot_in_stock = [];
  for (let index = 0; index < lot_in_stock_list.length; index++) {
    const rowItem = {};
    rowItem["coil_no"] = lot_in_stock_list[index].material_coil_no;
    rowItem["invoice_no"] = lot_in_stock_list[index].material_invoice_no;
    /*     rowItem["amount"] = lot_in_stock_list[index].material_amount; */
    rowItem["weight_by_tag"] = (
      <div style={{ textAlign: 'right' }}>
        {
          <NumberFormat
            thousandSeparator={true}
            value={lot_in_stock_list[index].material_weight_tag}
            displayType="text"
          />
        }
      </div>

    )
    rowItem["grade"] = lot_in_stock_list[index].material_grade;
    rowItem["supplier"] = lot_in_stock_list[index].material_supplier;
    rowItem["ref_coil_no"] = lot_in_stock_list[index].material_ref_coil_no;
    rowItem["location"] = lot_in_stock_list[index].material_location;
    rowItem["certificate"] =
      lot_in_stock_list[index].material_is_certificate === true ? (
        <button className="btn btn-block btn-success" disabled={true}>
          มี Certificate แล้ว
        </button>
      ) : (
        <>
          <button
            className="btn btn-block btn-warning"
            disabled={false}
            id={index}
            /*    data-toggle="modal"
          data-target="#modal-input_qc_approve" */
            onClick={setCerTarget.bind(
              this,
              lot_in_stock_list[index].material_coil_no,
              lot_in_stock_list[index].material_invoice_no
            )}
          >
            รับ Certificate
          </button>
        </>
      );
    rowItem["qc_by"] = lot_in_stock_list[index].material_qc_approved_by;
    rowItem["qc_status"] =
      lot_in_stock_list[index].material_qc_status === true ? (
        <button className="btn btn-block btn-success" disabled={true}>
          {" "}
          Checked
        </button>
      ) : (
        <>
          <button
            className="btn btn-block btn-warning"
            disabled={false}
            id={index}
            /*    data-toggle="modal"
            data-target="#modal-input_qc_approve" */
            onClick={setQcTarget.bind(
              this,
              lot_in_stock_list[index].material_coil_no,
              lot_in_stock_list[index].material_invoice_no
            )}
          >
            Check
          </button>
        </>
      );
    rowItem["qc_date"] =
      lot_in_stock_list[index].material_qc_date !== null
        ? moment(lot_in_stock_list[index].material_qc_date).format(
          "DD/MM/yyyy HH:mm น."
        )
        : "";

    var dis_selected = true;
    if (lot_in_stock_list[index].material_qc_status === true) {
      dis_selected = false;
    }

    rowItem["mgt"] = (
      <>
        {lot_in_stock_list[index].selected ? (
          <button
            type="button"
            disabled={dis_selected}
            onClick={() => {
              SelectedCoilNo(index);
            }}
            class="btn btn-block btn-info "
          >
            Selected
          </button>
        ) : (
          <button
            type="button"
            disabled={dis_selected}
            onClick={() => {
              SelectedCoilNo(index);
            }}
            class="btn btn-block btn-primary "
          >
            Select
          </button>
        )}
      </>
    );

    rowItem["mgtRe"] = (
      <>
        {lot_in_stock_report[index] === undefined ? (
          <></>
        ) : lot_in_stock_report[index].selected1 ? (
          <button
            type="button"
            disabled={dis_selected}
            onClick={() => {
              SelectedReportTag(index);
            }}
            class="btn btn-block btn-info "
          >
            Selected Tag
          </button>
        ) : (
          <button
            type="button"
            disabled={dis_selected}
            onClick={() => {
              SelectedReportTag(index);
            }}
            class="btn btn-block btn-primary "
          >
            Select Tag
          </button>
        )}
      </>
    );

    rowItem["manage"] = (
      <>
        {lot_in_stock_list[index].material_qc_status ? (
          <></>
        ) : (
          <a
            // href={
            //   "/Warehouse_Management/Raw_Mat_In_Out_CTS/mgt/" +
            //   materail[index].material_id
            // }
            // key={materail[index].material_id}
            className=" btn btn-xs " /* onClick={editpage.bind(this,materail[index].id)} */
            onClick={() => {
              setChangeParam(lot_in_stock_list[index]);
            }}
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        )}
      </>
    );
    row_data_lot_in_stock.push(rowItem);
  }

  const table_lot_in_stock_list = {
    columns: [
      {
        label: "++++",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
      {
        label: "Report Tag",
        field: "mgtRe",
        sort: "asc",
        width: 50,
      },
      {
        label: "Invoice No",
        field: "invoice_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Coil No",
        field: "coil_no",
        sort: "asc",
        width: 50,
      },
      /*     {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      }, */
      {
        label: "น้ำหนักตามป้าย (กก.)",
        field: "weight_by_tag",
        sort: "asc",
        width: 50,
      },
      {
        label: "Grade",
        field: "grade",
        sort: "asc",
        width: 50,
      },
      {
        label: "Supplier",
        field: "supplier",
        sort: "asc",
        width: 50,
      },

      /*    {
        label: "น้ำหนักจริง",
        field: "weight_actual",
        sort: "asc",
        width: 50,
      }, */
      {
        label: "อ้างอิง Coil No เก่า",
        field: "ref_coil_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Certificate",
        field: "certificate",
        sort: "asc",
        width: 50,
      },
      {
        label: "QC Check",
        field: "qc_status",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date QC",
        field: "qc_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "QC By",
        field: "qc_by",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "manage",
        sort: "asc",
        width: 50,
      },
    ],
    rows: row_data_lot_in_stock,
  };

  if (material_process.edit_type === true) {
    var index = table_lot_in_stock_list.columns.findIndex((e) => {
      return e.field === "mgt";
    });
    table_lot_in_stock_list.columns.splice(index, 1);
  }

  if (material_process.edit_type !== true) {
    var index = table_lot_in_stock_list.columns.findIndex((e) => {
      return e.field === "manage";
    });
    table_lot_in_stock_list.columns.splice(index, 1);
  }

  const getMaterail = async (material_no, material_name) => {
    var get_filler_materail = {
      material_no: material_no.trim(),
      materail_name: material_name.trim(),
      // materail_lot_start_date: filler_materail.materail_lot_start_date,
      // materail_lot_end_date:
      //   filler_materail.materail_lot_end_date ||
      //   filler_materail.materail_lot_start_date,
      // is_waitQc: filler_materail.is_waitQc,
      // is_minStock: filler_materail.is_minStock,
      // is_waitCer: filler_materail.is_waitCer,
      company_key: "CTS",
      oem_id: getOem(),
      company_id: getUser().com,
    };

    /*  console.log(filler_materail); */
    axios({
      method: "post",
      url: Configs.API_URL + "/api/rawMatInOut/getMaterail",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_materail,
    })
      .then(function (response) {
        // /*           response.data.sort((a,b)=> a.plate_number.localeCompare(b.plate_number)); */
        // response.data.sort((a, b) =>
        //   a.material_no.localeCompare(b.material_no)
        // );

        setmaterail(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getLotNo = async () => {
    axios({
      method: "get",
      url:
        Configs.API_URL +
        "/api/rawMatInOut/getlotNo?material_id=" +
        material_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        /*      setlotno(response.data); */
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.no;

          options.push(element);
        });
        setlotno(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const row_data_lot_history = [];
  for (let index = 0; index < lot_history_list.length; index++) {
    const rowItem = {};
    rowItem["coil_no"] = lot_history_list[index].material_coil_no;
    rowItem["invoice_no"] = lot_history_list[index].material_invoice_no;
    rowItem["status"] =
      lot_history_list[index].material_edit_type === true ? "IN" : "OUT";
    /*     rowItem["amount"] = lot_history_list[index].material_amount; */
    rowItem["grade"] = lot_history_list[index].material_grade;
    rowItem["weight_by_tag"] = (
      <div style={{ textAlign: 'right' }}>
        {
          <NumberFormat
            thousandSeparator={true}
            value={lot_history_list[index].material_weight_tag}
            displayType="text"
          />
        }
      </div>

    )
    rowItem["supplier"] = lot_history_list[index].material_supplier;
    rowItem["qc_by"] = lot_history_list[index].material_qc_approved_by;
    /*  rowItem["qc_status"] = lot_history_list[index].material_qc_status === true ? "Checked" : "Check"; */
    /* rowItem["qc_date"] = lot_history_list[index].material_qc_date !== null ? moment(lot_history_list[index].material_qc_date).format('DD/MM/yyyy HH:mm น.') : ""; */
    rowItem["ref_coil_no"] = lot_history_list[index].material_ref_coil;
    rowItem["location"] = lot_history_list[index].material_location;
    rowItem["date"] = moment(
      lot_history_list[index].material_create_date
    ).format("DD/MM/yyyy HH:mm น.");
    row_data_lot_history.push(rowItem);
  }
  const table_lot_history_list = {
    columns: [
      {
        label: "Invoice No",
        field: "invoice_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Coil No",
        field: "coil_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 50,
      },

      {
        label: "Grade",
        field: "grade",
        sort: "asc",
        width: 50,
      },
      {
        label: "น้ำหนักตามป้าย (กก.)",
        field: "weight_by_tag",
        sort: "asc",
        width: 50,
      },
      {
        label: "Supplier",
        field: "supplier",
        sort: "asc",
        width: 50,
      },

      {
        label: "อ้างอิง Coil No เก่า",
        field: "ref_coil_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Location",
        field: "location",
        sort: "asc",
        width: 50,
      },
      {
        label: "date",
        field: "date",
        sort: "asc",
        width: 50,
      },
      {
        label: "QC By",
        field: "qc_by",
        sort: "asc",
        width: 50,
      },
    ],
    rows: row_data_lot_history,
  };

  const [approve_person, setapprove_person] = useState({
    material_coil_no: "",
    material_invoice_no: "",
    qc_person: "",
  });

  function setQcTarget(material_coil_no, material_invoice_no, e) {
    setapprove_person({
      ...approve_person,
      material_coil_no: material_coil_no,
      material_invoice_no: material_invoice_no,
    });
    approve_person.material_coil_no = material_coil_no;
    approve_person.material_invoice_no = material_invoice_no;
    saveQcApprove();
  }

  function setCerTarget(material_coil_no, material_invoice_no, e) {
    setapprove_person({
      ...approve_person,
      material_coil_no: material_coil_no,
      material_invoice_no: material_invoice_no,
    });
    approve_person.material_coil_no = material_coil_no;
    approve_person.material_invoice_no = material_invoice_no;
    saveHaveCer();
  }

  function saveQcApprove(e) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, confirm it!",
    }).then((result) => {
      var err_list = [];
      if (result.isConfirmed) {
        if (approve_person.qc_person.trim() === "") {
          err_list.push("กรุณาใส่ชื่อผู้ตรวจ");
        }
        if (err_list.length > 0) {
          var err_txt = "";
          for (let err of err_list) {
            err_txt += err + "\n";
          }
          Swal.fire("ระบบ", err_txt, "error");
        } else {
          console.log("heere approve");
          let tmp_qc_approve = {
            oem_id: getOem(),
            company_id: getUser().com,
            material_coil_no: approve_person.material_coil_no,
            material_invoice_no: approve_person.material_invoice_no,
            material_id: material_id,
            qc_approve_person: approve_person.qc_person,
          };
          console.log(tmp_qc_approve);
          axios({
            method: "post",
            url: Configs.API_URL + "/api/rawMatInOut/qcApproved",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: tmp_qc_approve,
          })
            .then(function (response) {
              //setlot_history_list(response.data);
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  getLotNo();
                  getMateralView();
                  getLotInStock();
                  getLotHistory();

                  //clear radio----------------------------------------
                  var radioButtonArray =
                    document.getElementsByName("check_coil_no");

                  for (var i = 0; i < radioButtonArray.length; i++) {
                    var radioButton = radioButtonArray[i];
                    radioButton.checked = false;
                  }
                  material_process.edit_type = true;
                  setmaterial_process({
                    ...material_process,
                    coil_no_target: "",
                    invoice_no_target: "",
                    coil_no: "",
                    invoice_no: "",
                    /*    supplier_id: "", */
                    amount: "",
                    weight_tag: "",
                    weight_actual: "",
                    grade: "",
                    location: "",
                    unit: "",
                    lot_no: "",
                    is_cer: false,
                  });
                  material_process.coil_no_target = "";
                  material_process.invoice_no_target = "";

                  material_process.coil_no = "";
                  material_process.invoice_no = "";
                  material_process.lot_no = "";
                  material_process.amount = "";
                  material_process.weight_tag = "";
                  material_process.weight_actual = "";
                  material_process.grade = "";
                  material_process.location = "";
                  material_process.unit = "";
                  material_process.is_cer = false;
                  setcoil_no_list([]);
                  select_location_model.current.select.clearValue();
                  select_supplier_model.current.select.clearValue();
                  setisGen(true);
                  //clear radio------------------------------------------

                  setapprove_person({
                    ...approve_person,
                    material_coil_no: "",
                    material_invoice_no: "" /* , qc_person: ""  */,
                  });
                  approve_person.material_coil_no = "";
                  approve_person.material_invoice_no = "";
                  /*   approve_person.qc_person = ""; */
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
    });
  }

  function saveHaveCer(e) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, confirm it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let tmp_cer = {
          oem_id: getOem(),
          company_id: getUser().com,
          material_coil_no: approve_person.material_coil_no,
          material_invoice_no: approve_person.material_invoice_no,
          material_id: material_id,
          qc_approve_person: approve_person.qc_person,
        };

        axios({
          method: "post",
          url: Configs.API_URL + "/api/rawMatInOut/saveHaveCer",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: tmp_cer,
        })
          .then(function (response) {
            //setlot_history_list(response.data);
            if (response.data) {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                getLotInStock();
                getLotHistory();

                setapprove_person({
                  ...approve_person,
                  material_coil_no: "",
                  material_invoice_no: "" /* , qc_person: ""  */,
                });
                approve_person.material_coil_no = "";
                approve_person.material_invoice_no = "";
                /*   approve_person.qc_person = ""; */
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  }

  function saveOption() {
    var coil_no = lot_in_stock_list.filter((e) => {
      return e.selected;
    });
    /* console.log(coil_no); */
    Swal.fire({
      title: "Do you want to Save the changes?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        var err_list = [];

        if (
          material_process.invoice_no.trim() == "" &&
          material_process.edit_type === true
        ) {
          err_list.push("กรุณากรอก Invoice No.");
        }

        if (coil_no.length == 0 && material_process.edit_type === false) {
          err_list.push("กรุณาเลือก Coil No. ที่ต้องการ Out bound.");
        }
        if (coil_no_list.length == 0 && material_process.edit_type === true) {
          err_list.push("กรุณาเพิ่ม Coil No. ที่ต้องการ In bound.");
        }
        var check_ref_coil = coil_no_list.filter((e) => {
          return e.status_ref_coil === false;
        });
        if (check_ref_coil.length > 0 && material_process.edit_type === true) {
          err_list.push("มีบางรายการ ที่ อ้างอิง Coil No ไม่ถูกต้อง");
        }
        var checkWeight = coil_no_list.filter((e) => {
          return (
            Number(e.weight_by_tag.replace(/,/g, "")) == 0 ||
            Number(e.weight_by_tag.replace(/,/g, "")) == ""
          );
        });

        if (checkWeight.length > 0 && material_process.edit_type === true) {
          err_list.push(
            "กรุณาเพิ่ม น้ำหนักตามป้าย ของ Coil No. ที่ต้องการ In bound."
          );
        }
        if (
          material_process.lot_no === "" &&
          material_process.edit_type === false
        ) {
          err_list.push("กรุณาเลือก Lot No. ก่อนทำการ Out bound.");
        }
        if (err_list.length > 0) {
          //มี err
          var err_message = "";
          for (var e = 0; e < err_list.length; e++) {
            err_message += "<br/>" + err_list[e];
          }
          Swal.fire("ระบบ", err_message, "error");
        } else {
          var strToInt = coil_no_list;
          for (let item of strToInt) {
            item.weight_by_tag = Number(item.weight_by_tag.replace(/,/g, ""));
          }
          let tmp_save = {
            material_id: material_id,
            material_coil_no:
              material_process.edit_type === true ? strToInt : coil_no,
            material_invoice_no:
              material_process.edit_type === true
                ? material_process.invoice_no.trim()
                : material_process.invoice_no_target.trim(),
            material_edit_type: material_process.edit_type,
            material_amount: 1,
            lot_no: material_process.lot_no,
            material_location: material_process.location,

            material_grade: material_process.grade,
            material_suplier_id: material_process.supplier_id,
            material_cer: material_process.is_cer,
          };
          axios({
            method: "post",
            url:
              Configs.API_URL + "/api/rawMatInOut/saveEditAmountMaterialInOut",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: tmp_save,
          })
            .then(function (response) {
              //setlot_history_list(response.data);
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  getLotNo();
                  getMateralView();
                  getLotInStock();
                  getLotHistory();

                  //clear radio----------------------------------------
                  var radioButtonArray =
                    document.getElementsByName("check_coil_no");

                  for (var i = 0; i < radioButtonArray.length; i++) {
                    var radioButton = radioButtonArray[i];
                    radioButton.checked = false;
                  }
                  material_process.edit_type = true;
                  setmaterial_process({
                    ...material_process,
                    coil_no_target: "",
                    invoice_no_target: "",
                    coil_no: "",
                    invoice_no: "",
                    /*    supplier_id: "", */
                    amount: "",
                    weight_tag: "",
                    weight_actual: "",
                    grade: "",
                    location: "",
                    unit: "",
                    lot_no: "",
                    is_cer: false,
                  });
                  material_process.coil_no_target = "";
                  material_process.invoice_no_target = "";

                  material_process.coil_no = "";
                  material_process.invoice_no = "";
                  material_process.lot_no = "";
                  material_process.amount = "";
                  material_process.weight_tag = "";
                  material_process.weight_actual = "";
                  material_process.grade = "";
                  material_process.location = "";
                  material_process.unit = "";
                  material_process.is_cer = false;
                  setcoil_no_list([]);
                  select_location_model.current.select.clearValue();
                  select_supplier_model.current.select.clearValue();
                  setisGen(true);
                  //clear radio------------------------------------------
                });
              }
            })
            .catch(function (error) {
              Swal.fire("Over Stock!", error.response.data.message, "warning");
              console.log(error);
            });
          // ไม่มี err
        }
      }
    });
  }

  function saveOption2() {
    Swal.fire({
      title: "Do you want to Save the changes?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        var err_list = [];

        if (
          material_process.invoice_no === "" ||
          material_process.invoice_no === null
        ) {
          err_list.push("กรุณากรอก Invoice No.");
        }


        var check_ref_coil = coil_no_list.filter((e) => {
          return e.status_ref_coil === false;
        });
        if (check_ref_coil.length > 0) {
          err_list.push("มีบางรายการ ที่ อ้างอิง Coil No ไม่ถูกต้อง");
        }
        var checkWeight = coil_no_list.filter((e) => {
          return (
           /*  Number(e.weight_by_tag.replace(/,/g, "")) == 0 ||
            Number(e.weight_by_tag.replace(/,/g, "")) == "" */
            Number((e.weight_by_tag || 0).toString().replace(/,/g, "")) == 0  ||
            Number((e.weight_by_tag || 0).toString().replace(/,/g, "" )) == ""
          );
        });
        if (checkWeight.length > 0) {
          err_list.push(
            "กรุณากรอก น้ำหนักตามป้าย ของ Coil No."
          );
        }
        if (err_list.length > 0) {
          //มี err
          var err_message = "";
          for (var e = 0; e < err_list.length; e++) {
            err_message += "<br/>" + err_list[e];
          }
          Swal.fire("ระบบ", err_message, "error");
        } else {
          console.log(coil_no_list);
          let tmp_save = {
            log_id: material_process.log_id,
            invoice_no: material_process.invoice_no,
            weight_by_tag: coil_no_list.length > 0 ? Number((coil_no_list[0].weight_by_tag || 0).toString().replace(/,/g, "")) : '',
            supp_coil:coil_no_list.length > 0 ? coil_no_list[0].supp_coil : '',
            old_coil_no:coil_no_list.length > 0 ? coil_no_list[0].old_coil_no : '',
            supplier_id: material_process.supplier_id,
            grade: material_process.grade,
            location: material_process.location,
            is_cer: material_process.is_cer,
          };
          axios({
            method: "post",
            url: Configs.API_URL + "/api/rawMatInOut/saveEditLogMaterialInOut",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: tmp_save,
          })
            .then(function (response) {
              //setlot_history_list(response. zdata);
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  getLotNo();
                  getMateralView();
                  getLotInStock();
                  getLotHistory();

                  //clear radio----------------------------------------
                  var radioButtonArray =
                    document.getElementsByName("check_coil_no");

                  for (var i = 0; i < radioButtonArray.length; i++) {
                    var radioButton = radioButtonArray[i];
                    radioButton.checked = false;
                  }
                  material_process.edit_type = true;
                  setmaterial_process({
                    ...material_process,
                    coil_no_target: "",
                    invoice_no_target: "",
                    coil_no: "",
                    invoice_no: "",
                    /*    supplier_id: "", */
                    amount: "",
                    weight_tag: "",
                    weight_actual: "",
                    grade: "",
                    location: "",
                    unit: "",
                    lot_no: "",
                    is_cer: false,
                  });
                  material_process.coil_no_target = "";
                  material_process.invoice_no_target = "";

                  material_process.coil_no = "";
                  material_process.invoice_no = "";
                  material_process.lot_no = "";
                  material_process.amount = "";
                  material_process.weight_tag = "";
                  material_process.weight_actual = "";
                  material_process.grade = "";
                  material_process.location = "";
                  material_process.unit = "";
                  material_process.is_cer = false;
                  setcoil_no_list([]);
                  select_location_model.current.select.clearValue();
                  select_supplier_model.current.select.clearValue();
                  setisGen(true);
                  setCheckEdit(false);
                  //clear radio------------------------------------------
                });
              }
            })
            .catch(function (error) {
              Swal.fire("Over Stock!", error.response.data.message, "warning");
              console.log(error);
            });
          // ไม่มี err
        }
      }
    });
  }

  async function checkRefCoil(ref_coil, index) {
    let tmp_check = {
      material_id: material_id,
      material_ref_coil_no: ref_coil,
    };
    axios({
      method: "post",
      url: Configs.API_URL + "/api/rawMatInOut/checkRefCoil",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tmp_check,
    })
      .then(function (response) {
        if (response.data) {
          var tempList = coil_no_list;
          tempList[index].status_ref_coil = response.data.status;
          tempList[index].ref_coil_message = response.data.message;
          setcoil_no_list(tempList);
          settest({ ...test, test: "" });
          test.test = "";
        }
      })
      .catch(function (error) {
        Swal.fire("Error!", error.response.data.message, "warning");
        console.log(error);
      });
  }

  async function addCoilNoList() {
    var tempList = coil_no_list;
    if (isGen) {
      await genCoilNo();
      setisGen(false);
    }
    if (!isGen) {
      var gen = coil_no_gen;
      if (tempList.length > 0) {
        gen = parseInt(coil_no_gen) + 1;
      }

      setcoil_no_gen(gen);
      let temp = {
        coil_no: gen,
        weight_by_tag: "",
        old_coil_no: "",
      };
      tempList.push(temp);
      setcoil_no_list(tempList);
      settest({ ...test, test: "" });
      test.test = "";
    }
  }

  function delCoilNoList(index) {
    var tempList = coil_no_list;
    tempList.splice(index, 1);
    setcoil_no_list(tempList);
    var count = 0;
    for (let item of coil_no_list) {
      item.coil_no = parseInt(coil_no_genDefault) + count;
      count++;
    }
    if (tempList.length === 0) {
      setisGen(true);
    }
    settest({ ...test, test: "" });
    test.test = "";
  }

  function changeWeightByTag(value, index) {
    /* console.log(value,index) */
    var tempList = coil_no_list;
    tempList[index].weight_by_tag = value;
    setcoil_no_list(tempList);
    settest({ ...test, test: "" });
    test.test = "";
  }
  function changeSuppCoil(value, index) {
    /* console.log(value,index) */
    var tempList = coil_no_list;
    tempList[index].supp_coil = value;
    setcoil_no_list(tempList);
    settest({ ...test, test: "" });
    test.test = "";
  }

  function changeOldCoilByTag(value, index) {
    /*    console.log(value,index) */
    var tempList = coil_no_list;
    tempList[index].old_coil_no = value;
    setcoil_no_list(tempList);
    settest({ ...test, test: "" });
    test.test = "";
  }

  async function ReRunCoilNo() {
    axios({
      method: "get",
      url: Configs.API_URL + "/api/rawMatInOut/genCoilNo",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        setcoil_no_gen(parseInt(response.data));
        setcoil_no_genDefault(parseInt(response.data));

        var tempList = coil_no_list;
        setcoil_no_list([]);
        var count = 0;
        for (let item of tempList) {
          item.coil_no = parseInt(response.data) + count;
          count++;
        }
        setcoil_no_list(tempList);
        console.log(tempList);
        settest({ ...test, test: "" });
        test.test = "";
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function SelectedCoilNo(index) {
    var tempList = lot_in_stock_list;
    console.log(index);

    if (tempList[index].selected) {
      tempList[index].selected = false;
    } else {
      tempList[index].selected = true;
    }
    console.log(tempList);
    setlot_in_stock_list(tempList);
    settest({ ...test, test: "" });
    test.test = "";
  }

  function SelectedReportTag(index) {
    var tempList = lot_in_stock_report;
    setlot_in_stock_report([])
    console.log(index);

    if (tempList[index].selected1) {
      tempList[index].selected1 = false;
    } else {
      tempList[index].selected1 = true;
    }
    var check = tempList.filter((e) => {
      return e.selected1 === true;
    });
    if (check.length > 0) {
      setcheckreport(false)
    } else {
      setcheckreport(true)
    }

    console.log(tempList);
    setlot_in_stock_report(tempList);
    settest({ ...test, test: "" });
    test.test = "";
  }

  function cancleOption(params) {
    Swal.fire({
      title: "Do you want to cancel and leave the changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "/Warehouse_Management/Raw_Mat_In_Out_CTS";
      }
    });
  }

  function cancleOption2(params) {
    Swal.fire({
      title: "Do you want to cancel and leave the changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        setCheckEdit(false);

        setmaterial_process({
          ...material_process,
          invoice_no: "",
          supplier_id: null,
          grade: "",
          location: null,
          is_cer: null,
        });

        setmaterial({
          ...material,
          material_supplier_id: null,
        });

        setcoil_no_list([]);
      }
    });
  }

  function QcTagGenerate() {
    var materail_list = [];
   /*  console.log(lot_in_stock_report); */
    for (let i of lot_in_stock_report) {
      if ( i.selected1 === true) {
        materail_list.push(i)
      }
    }
    

    if (materail_list  < 1) {
      Swal.fire({
        icon: "error",
        title: "ไม่มีข้อมูล",
      });
    } else {
      /*  console.log(moment(new Date()).format('MMM')) */
      const doc = new jsPDF("l", "mm", "a4");

      /* const stock_month = [
        {th:"มกราคม",eng:"January"},
        {th:"กุมภาพันธ์",eng:"February"},
        {th:"มีนาคม",eng:"March"},
        {th:"เมษายน",eng:"April"},
        {th:"พฤษภาคม",eng:"May"},
        {th:"มิถุนายน",eng:"June"},
        {th:"กรกฎาคม",eng:"July"},
        {th:"สิงหาคม",eng:"August"},
        {th:"กันยายน",eng:"September"},
        {th:"ตุลาคม",eng:"October"},
        {th:"พฤศจิกายน",eng:"November"},
        {th:"ธันวาคม",eng:"December"},
      ] */

      doc.addFileToVFS("THSarabunNew.ttf", Get_font_pdf_th2());
      doc.addFileToVFS("THSarabunNewBold.ttf", Get_font_pdf_th2_bold());
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.addFont("THSarabunNewBold.ttf", "THSarabunNew", "bold");
      doc.addFont("Rocki", "bold");
      doc.setFont("THSarabunNew", "normal");
      doc.setFontSize(15);

      /*   doc.rect(5,5,286,200); */

      doc.rect(0, 0, 297, 210);

      //บนซ้าย

      //บนขวา
      /*  doc.rect(148.5,0,148.5,105); 
    //ล่างซ้าย
    doc.rect(0,105,148.5,105); 
    //ล่างขวา
    doc.rect(148.5,105,148.5,105); 
     */
      /* doc.rect(0,0,145,100);    
    doc.rect(145,0,1,100); */
    /*    let check_select = lot_in_stock_report.filter((item) => {
        return item.selected1 === true;
      });

 console.log(check_select); */

      var Qc_passedtemp = materail[0].material_lot_list_qc_passed;
      var material_lot_list_qc_passed_list = [];
      var nameloop = '';
      var num = 0;

      // console.log(materail_list);
      for (let check of materail_list) {
        var Qc_passed = Qc_passedtemp.filter((el) => {
          return el.material_coil_no === check.material_coil_no;
        });
        console.log(Qc_passed);

        if (nameloop === check.material_coil_no) {
          num+=1

        }else{
          num = 0
        }
        // console.log(num);
        var item = Qc_passed[num];
        // console.log( item.material_coil_no , "sss",check.material_coil_no);
        nameloop = check.material_coil_no || '';
        if (item.material_coil_no === check.material_coil_no) {
          var supplier = getsup.filter((e) => {
            return e.id === item.material_supplier;
          });
          for (let i of supplier) {
            var getsupplier = i.full_name;
          }
          let as = {
            tracking_no: item.tracking_no,
            material_actual_weight: item.material_actual_weight || 0,
            material_amount: item.material_amount,
            material_coil_no:
              item.material_ref_coil_no === null
                ? item.material_coil_no
                : item.material_ref_coil_no,
            material_grade: item.material_grade,
            material_invoice_no: item.material_invoice_no,
            material_location: item.material_location,
            material_qc_approve_person: item.material_qc_approve_person,
            material_qc_date: item.material_qc_date,
            material_qc_status: item.material_qc_status,
            material_supplier: getsupplier,
            material_supplier_coil: item.material_supplier_coil,
            material_tag_weight: item.material_tag_weight,
            material_weight_actual: item.material_weight_actual || 0,
            material_no: materail[0].material_no || "-",
            material_dimention: materail[0].material_dimention || "-",
            material_name: materail[0].material_name || "-",
          };

          material_lot_list_qc_passed_list.push(as);
        }
      }

      console.log(material_lot_list_qc_passed_list);

      /*  console.log(img,logoTTT.imgs) */
      var year_th = parseInt(moment(new Date()).format("YY")) + 43;

      var counts = 0;
      var numloop = 0;

      for (  let loop = numloop; loop < material_lot_list_qc_passed_list.length; loop = loop + 4 ) {
        var row = 0;
        for (let i = 0; row < 4; i = i + 4) {
          if (!material_lot_list_qc_passed_list[loop + row]) {
          } else {
            var material_dimention = "";
            var material_supplier = "";
            var material_no = "";
            var material_grade = "";
            var material_coin_no = "";
            var material_invoice_no = "";
            var material_qc_approve_person = "";
            var material_tag_weight = "";
            var material_actual_weight = "";
            var material_supplier_coil = "";

            var img_qr = "";
            /* console.log(material_lot_list_qc_passed_list[loop+row].tracking_no) */
            var track =
              material_lot_list_qc_passed_list[loop + row].tracking_no;
            QRCode2.toDataURL(track, function (err, url) {
              img_qr = url;
            });

            material_no = material_lot_list_qc_passed_list[loop + row].material_supplier_coil || "-";
            material_dimention =  material_lot_list_qc_passed_list[loop + row].material_name || "-";
            material_supplier =
              material_lot_list_qc_passed_list[loop + row].material_supplier ||
              "-";
            material_grade =
              material_lot_list_qc_passed_list[loop + row].material_grade ||
              "-";
            material_coin_no =
              material_lot_list_qc_passed_list[loop + row].material_coil_no;
            material_invoice_no =
              material_lot_list_qc_passed_list[loop + row].material_invoice_no || '';
            material_qc_approve_person =
              material_lot_list_qc_passed_list[loop + row]
                .material_qc_approve_person || "-";
            material_tag_weight =
              material_lot_list_qc_passed_list[loop + row]
                .material_tag_weight || "";
            material_actual_weight =
              material_lot_list_qc_passed_list[loop + row]
                .material_actual_weight || "";
            material_supplier_coil =
              material_lot_list_qc_passed_list[loop + row]
                .material_supplier_coil;
            // console.log(material_coin_no);
          /*   var month_th = stock_month.filter((e) => {
              return (
                e.eng ===
                moment(
                  material_lot_list_qc_passed_list[loop + row].material_qc_date
                ).format("MMMM")
              );
            }); */
            
            var x = 0;
            var y = 0;
            if (row == 0) {
              x = 0;
              y = 0;
            } else if (row == 1) {
              x = 148.5;
              y = 0;
            } else if (row == 2) {
              x = 0;
              y = 105;
            } else if (row == 3) {
              x = 148.5;
              y = 105;
            }

            doc.addImage(img_qr, "JPEG", 95 + x, 58 + y, 50, 50);
            doc.setDrawColor(255, 255, 255);
            doc.setFillColor(255, 255, 255);
            /*  doc.rect(115.5+x,77+y,10,10,"FD");  */
            doc.setDrawColor(0, 0, 0);
            /*  doc.addImage(img, 'JPEG',117+x,79+y, 7,7); */
            doc.setLineDash([3, 4], 0);
            doc.rect(0 + x, 0 + y, 148.5, 105);
            doc.setLineDash([0, 0], 0);
            doc.rect(8 + x, 5 + y, 90, 8);
            doc.text(47 + x, 10 + y, "MATERIAL");
            doc.rect(8 + x, 13 + y, 35, 8);
            doc.text(25 + x, 18 + y, "VENDOR", "center");
            doc.rect(43 + x, 13 + y, 55, 8);
            doc.text(70.5 + x, 18 + y, material_supplier.toString(), "center");
            doc.rect(8 + x, 21 + y, 35, 8);
            doc.text(25 + x, 26 + y, "GRADE", "center");
            doc.rect(43 + x, 21 + y, 55, 8);
            doc.setFont("THSarabunNew", "bold");
            doc.setFontSize(20);
            doc.text(70 + x, 26.5 + y, material_grade.toString(), "center");
            doc.setFont("THSarabunNew", "normal");
            doc.setFontSize(15);

            doc.rect(98 + x, 5 + y, 43, 24);
            doc.setFontSize(30);
            doc.text(120 + x, 20 + y, "SoC FREE", "center");
            doc.text(120 + x, 20 + y, "SoC FREE", "center");
            doc.text(120 + x, 20 + y, "SoC FREE", "center");

            doc.setFontSize(15);
            doc.rect(8 + x, 29 + y, 35, 8);
            doc.text(25 + x, 34 + y, "DIMENSION", "center");
            doc.rect(43 + x, 29 + y, 98, 8);
            doc.setFont("THSarabunNew", "bold");
            doc.setFontSize(20);
            doc.text(45 + x, 34.5 + y, material_dimention.toString(), "left");
            doc.setFont("THSarabunNew", "normal");
            doc.setFontSize(15);

            doc.text(120 + x, 34 + y, material_no.toString(), "center");
            doc.rect(98 + x, 29 + y, 43, 8);
            /*  doc.text(
              119.5 + x,
              34 + y,
              material_supplier_coil.toString(),
              "center"
            ); */

            doc.rect(8 + x, 37 + y, 35, 8);

            doc.text(25 + x, 42 + y, "WEIGHT", "center");
            doc.setFontSize(10);

            doc.text(45.5 + x, 39.5 + y, "Tag", "center");
            /*  doc.text(74+x,39.5+y,"Actual","center"); */
            doc.setFontSize(15);
            doc.rect(43 + x, 37 + y, 55, 8);
            /*  doc.rect(43+x,37+y,27,8); */
            doc.setFont("THSarabunNew", "bold");
            doc.setFontSize(20);
            doc.text(
              70 + x,
              43 + y,
              material_tag_weight
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " Kg",
              "center"
            );
            doc.setFont("THSarabunNew", "normal");
            doc.setFontSize(15);
            /*  doc.text(84+x,43+y,material_actual_weight.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),"center"); */

            doc.rect(98 + x, 37 + y, 43, 8);

            if (
              material_lot_list_qc_passed_list[loop + row].material_qc_date !==
              null
            ) {
              doc.text(120 + x, 42 + y, "เดือน " + moment(
                material_lot_list_qc_passed_list[loop + row].material_qc_date
              ).format("MMMM"), "center");
            }

            doc.rect(8 + x, 45 + y, 35, 8);
            doc.text(25 + x, 50 + y, "COIL NO.", "center");
            doc.rect(43 + x, 45 + y, 55, 8);
            doc.text(45 + x, 50 + y, material_coin_no.toString(), "left");
            doc.rect(8 + x, 53 + y, 35, 8);
            doc.text(25 + x, 58 + y, "INVOICE NO.", "center");
            doc.rect(43 + x, 53 + y, 55, 8);
            doc.text(45 + x, 58 + y, material_invoice_no.toString(), "left");

            doc.rect(98 + x, 45 + y, 43, 16);
            doc.setFontSize(30);
            doc.text(120 + x, 56 + y, "OK", "center");
            doc.text(120 + x, 56 + y, "OK", "center");
            doc.text(120 + x, 56 + y, "OK", "center");

            doc.setFontSize(15);

            doc.text(
              34 + x,
              72 + y,
              material_qc_approve_person.toString(),
              "center"
            );
            doc.text(
              34 + x,
              73 + y,
              "..........................................",
              "center"
            );
            doc.text(34 + x, 79 + y, "QC.", "center");
            if (
              material_lot_list_qc_passed_list[loop + row].material_qc_date ===
              null
            ) {
              doc.text(80 + x, 72 + y, "ไม่ระบุ", "center");
            } else {
              doc.text(
                80 + x,
                72 + y,
                moment(
                  material_lot_list_qc_passed_list[loop + row].material_qc_date
                )
                  .format("DD/MM/YY")
                  .toString(),
                "center"
              );
            }
            doc.text(
              80 + x,
              73 + y,
              "..........................................",
              "center"
            );
            doc.text(80 + x, 79 + y, "DATE", "center");

            doc.text(
              35 + x,
              100 + y,
              "F-QC2-01 Rev No.:1 Date:27/12/53",
              "center"
            );
          }

          row = row + 1;
          counts = material_lot_list_qc_passed_list.length - 4;

          /*  console.log(material_lot_list_qc_passed_list,"กฟห") */
        }

        if (loop < counts) {
          doc.addPage("l");
        }
      }
      window.open(doc.output("bloburl"));
    }
  }

  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Raw Mat In/Out Management {pageMode}
                  {"  "}
                  <a target="_blank" href={getAcademy()}>
                    <i class="fa fa-info-circle"></i>
                  </a>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Raw Mat In/Out Management
                  </li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h2>Coil No in warehouse {material.material_amount} items</h2>
              <div className="card-tools"></div>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-0 col-md-2 col-xl-2"></div>
                <div className="col-6 col-md-4 col-xl-4">
                  <h1>Material Code : {material.material_no}</h1>
                </div>
                <div className="col-6 col-md-4 col-xl-4">
                  <h1>Material Name : {material.material_name}</h1>
                </div>
              </div>

              <div className="row">
                <div className="col-0 col-md-2 col-xl-2"></div>
                <div className="col-6 col-md-4 col-xl-4">
                  <input
                    type="radio"
                    id="inbound"
                    name="edit_type"
                    checked={material_process.edit_type != true ? false : true}
                    value={true}
                    onClick={(e) => {
                      setmaterial_process({
                        ...material_process,
                        edit_type: e.target.value === "true" ? true : false,
                      });
                      material_process.edit_type = true;
                      setmaterial_process({
                        ...material_process,
                        coil_no_target: "",
                        invoice_no_target: "",
                        coil_no: "",
                        invoice_no: "",
                        lot_no: "",
                        amount: "",
                        weight_tag: "",
                        weight_actual: "",
                        grade: "",
                        location: "",
                        unit: "",
                        is_cer: false,
                      });
                      material_process.coil_no_target = "";
                      material_process.invoice_no_target = "";

                      material_process.coil_no = "";
                      material_process.invoice_no = "";
                      material_process.lot_no = "";
                      material_process.amount = "";
                      material_process.weight_tag = "";
                      material_process.weight_actual = "";
                      material_process.grade = "";
                      material_process.location = "";
                      material_process.unit = "";
                      material_process.is_cer = false;
                      setisGen(true);
                      //select_location_model.current.select.clearValue();
                    }}
                  />

                  <label htmlFor="inbound">
                    <h4>&nbsp;&nbsp;In bound</h4>
                  </label>
                </div>

                <div className="col-6 col-md-4 col-xl-4">
                  <input
                    type="radio"
                    id="outbound"
                    name="edit_type"
                    value={true}
                    onClick={(e) => {
                      setmaterial_process({
                        ...material_process,
                        edit_type: e.target.value === "true" ? false : true,
                        coil_no_target: "",
                        invoice_no_target: "",
                        coil_no: "",
                        invoice_no: "",
                        lot_no: "",
                        amount: "",
                        weight_tag: "",
                        weight_actual: "",
                        grade: "",
                        location: "",
                        unit: "",
                        is_cer: false,
                      });

                      setcoil_no_list([]);
                      select_location_model.current.select.clearValue();
                      select_supplier_model.current.select.clearValue();
                    }}
                  />

                  <label htmlFor="outbound">
                    <h4>&nbsp;&nbsp;Out bound</h4>
                  </label>
                </div>
              </div>
              <div className="row">
                {" "}
                <div className="col-4"></div>
              </div>
              <LOGH
                approve_cts={
                  <INF
                    value={approve_person.qc_person}
                    onChange={(e) => {
                      setapprove_person({
                        ...approve_person,
                        qc_person: e.target.value,
                      });
                    }}
                    label={"ชื่อผู้ตรวจ"}
                  />
                }
                report_tag={
                  <div className="form-group ">
                    <button
                      type="button"
                      className="btn btn-block btn-info"
                      disabled={checkreport}
                      onClick={() => QcTagGenerate()}
                    >
                      Report Tag
                    </button>
                  </div>
                }
                history={table_lot_history_list}
                data={table_lot_in_stock_list}
              />

              {material_process.edit_type === true ? (
                <>
                  <div className="row">
                    <div className="col-12 col-md-4 col-xl-4">
                      <INF
                        value={material_process.invoice_no}
                        label={"Invoice No."}
                        onChange={(e) => {
                          setmaterial_process({
                            ...material_process,
                            invoice_no: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div
                      className="col-12 col-md-4 col-xl-4"
                      style={{ "z-index": "99" }}
                    >
                      <div className="form-group ">
                        <Select
                          options={supplier_list}
                          value={supplier_list.filter((f) => {
                            return f.value === material.material_supplier_id;
                          })}
                          ref={select_supplier_model}
                          onChange={(e) => {
                            if (e !== null) {
                              setmaterial_process({
                                ...material_process,
                                supplier_id: e.value,
                              });
                              setmaterial({
                                ...material,
                                material_supplier_id: e.value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="">Vender/Supplier</label>
                      </div>
                    </div>

                    <div className="col-12 col-md-4 col-xl-4">
                      <INF
                        value={material_process.grade}
                        label={"Grade"}
                        onChange={(e) => {
                          setmaterial_process({
                            ...material_process,
                            grade: e.target.value,
                          });
                        }}
                      />
                    </div>

                    <div
                      className="col-12 col-md-4 col-xl-4"
                      style={{ "z-index": "98" }}
                    >
                      <div className="form-group ">
                        <Select
                          options={location_list}
                          defaultValue={material_process.location}
                          value={location_list.filter((f) => {
                            return f.value === material_process.location;
                          })}
                          ref={select_location_model}
                          onChange={(e) => {
                            if (e !== null) {
                              setmaterial_process({
                                ...material_process,
                                location: e.value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="">Location</label>
                      </div>
                    </div>
                    {/*  <div className="col-1"/> */}
                    <div
                      className="col-12 col-md-4 col-xl-4 mt-3"
                      style={{ textAlign: "left" }}
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="is_Cer"
                        name="is_Cer"
                        value="is_Cer"
                        checked={material_process.is_cer}
                        onChange={(event) => {
                          setmaterial_process({
                            ...material_process,
                            is_cer: event.currentTarget.checked,
                          });
                          material_process.is_cer = event.currentTarget.checked;
                        }}
                      />
                      {/*  {console.log(  product.is_virtual)} */}
                      <label htmlFor="is_Cer">รับ Certificate</label>
                      {/*            </div> */}
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-4 col-md-3 col-xl-2">
                      <button
                        type="button"
                        onClick={() => {
                          addCoilNoList();
                        }}
                        class="btn btn-block btn-primary"
                        disabled={checkEdit}
                      >
                        Add Coil No.{" "}
                        <i className="fa fa-plus-circle" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div className="col-4 col-md-3 col-xl-2">
                      <button
                        type="button"
                        onClick={() => {
                          ReRunCoilNo();
                        }}
                        class="btn btn-block btn-primary "
                        disabled={checkEdit}
                      >
                        Refresh Coil No.{" "}
                        <i className="fa fa-history" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                  <br />

                  <div
                    className="table-responsive"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <table style={{ width: "100%", whiteSpace: "nowrap" }}>
                      <tr>
                        <td
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            width: "100px",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                        <td
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            width: "100px",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>

                        <td
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            width: "100px",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                        <td
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            width: "30px",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                      </tr>
                      {coil_no_list.map((e, index) => {
                        var color_ref = "green";
                        if (e.status_ref_coil === false) {
                          color_ref = "red";
                        }
                        return (
                          <>
                            <tr>
                              <td
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  width: "100px",
                                }}
                              >
                                <INF
                                  value={e.coil_no}
                                  label={"Coil No."}
                                  /* onChange={(e) => {
                                setmaterial_process({
                                  ...material_process,
                                  coil_no: e.target.value,
                                });
                              }} */ disabled
                                />
                              </td>
                              <td
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  width: "100px",
                                }}
                              >
                                {" "}
                                <div className="form-group ">
                                  <NumberFormat
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    type="text"
                                    className="form-control"
                                    allowNegative={false}
                                    value={e.weight_by_tag}
                                    onChange={(e) => {
                                      changeWeightByTag(e.target.value, index);
                                    }}
                                 /*    disabled={checkEdit} */
                                  />
                                  <label htmlFor="">น้ำหนักตามป้าย (กก.)</label>{" "}
                                </div>
                              </td>

                              <td
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  width: "100px",
                                }}
                              >
                                <div className="form-group ">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={e.supp_coil}
                                    onChange={(e) => {
                                      changeSuppCoil(e.target.value, index);
                                    }}
                                    /* disabled={checkEdit} */
                                  />
                                  <label htmlFor="">Supp. Coil</label>{" "}
                                </div>
                              </td>

                              <td
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  width: "100px",
                                }}
                              >
                                <div className="form-group ">
                                  <NumberFormat
                                    thousandSeparator={false}
                                    /*        decimalScale={2} */

                                    maxLength="8"
                                    type="text"
                                    className="form-control"
                                    allowNegative={false}
                                    value={e.old_coil_no}
                                    onChange={(e) => {
                                      changeOldCoilByTag(e.target.value, index);
                                      if (
                                        e.target.value.length > 0 &&
                                        e.target.value.length < 8
                                      ) {
                                        var tempList = coil_no_list;
                                        tempList[index].status_ref_coil = false;
                                        tempList[index].ref_coil_message =
                                          "Incorrect";
                                        setcoil_no_list(tempList);
                                        settest({ ...test, test: "" });
                                        test.test = "";
                                      }
                                      if (e.target.value.length === 0) {
                                        var tempList = coil_no_list;
                                        tempList[index].status_ref_coil = null;
                                        tempList[index].ref_coil_message = "";
                                        setcoil_no_list(tempList);
                                        settest({ ...test, test: "" });
                                        test.test = "";
                                      }
                                      if (e.target.value.length === 8) {
                                        checkRefCoil(e.target.value, index);
                                      }
                                    }}
                    /*                 disabled={checkEdit} */
                                  />
                                  <label htmlFor="">
                                    อ้างอิง Coil No. เก่า{" "}
                                    {e.ref_coil_message ? (
                                      <font color={color_ref}>
                                        {" >>>>> "}
                                        {e.ref_coil_message}
                                      </font>
                                    ) : (
                                      ""
                                    )}
                                  </label>{" "}
                                </div>
                              </td>
                              <td
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  width: "30px",
                                }}
                              >
                                {" "}
                                <div className="form-group ">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      delCoilNoList(index);
                                    }}
                                    class="btn btn-block btn-danger "
                                    disabled={checkEdit}
                                  >
                                    X
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </table>
                  </div>
                  <hr />
                </>
              ) : (
                <>
                  <div className="row">
                    <div
                      className="col-12 col-md-4 col-xl-4"
                      style={{ "z-index": "98" }}
                    >
                      <div className="form-group ">
                        <Select
                          options={lotno}
                          value={lotno.filter((f) => {
                            return f.label === material_process.lot_no;
                          })}
                          onChange={(e) => {
                            if (e !== null) {
                              setmaterial_process({
                                ...material_process,

                                lot_no: e.label,
                              });
                            }
                          }}
                        />
                        <label htmlFor="">Lot No.</label>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="row mb-2">
                {checkEdit ? (
                  <>
                    <div className="col-4 col-md-3 col-xl-2">
                      <button
                        type="button"
                        onClick={saveOption2}
                        class="btn btn-block btn-success "
                      >
                        Save Edit
                      </button>
                    </div>
                    <div className="col-4 col-md-3 col-xl-2">
                      <button
                        type="button"
                        onClick={cancleOption2}
                        class="btn btn-block btn-danger "
                      >
                        Cancel Edit
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-4 col-md-3 col-xl-2">
                      <button
                        type="button"
                        onClick={saveOption}
                        class="btn btn-block btn-success "
                      >
                        Save
                      </button>
                    </div>
                    <div className="col-4 col-md-3 col-xl-2">
                      <button
                        type="button"
                        onClick={cancleOption}
                        class="btn btn-block btn-danger "
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
      {/*  <Footter/> */}
    </div>
  );
}
export default MatInOutCTS_mgt;
