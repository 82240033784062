import React, { Component, useEffect, useState, useRef } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import { MDBDataTable } from "mdbreact";
import QRCode from "qrcode.react";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import { userdefault_img, logoTTT } from "../../routes/imgRoute/imgUrl";
import "../../assets/css/table.css";
import axios from "axios";
import Configs from "../../config";
import {
  getOem,
  getToken,
  getUser,
  getComConfig,
  getFeature,
  getAcademy,
  getOemlist_,
  getnamecompanee,
} from "../../Utils/Common";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import moment from "moment";
import sortJsonArray from "sort-json-array";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import INF from "../../component/input_feature";
import LOGH from "../../component/log_detailHistory_feature";
import INDF from "../../component/input_date_feature";
import Load_status from "./Load_status";
import Select from "react-select";
import { stock_product_report } from "../../component/Report";
function FGInOut(params) {
  const [block_btn, setblock_btn] = useState(false);

  const [product_data, setProduct_data] = useState([]);
  const [product_model_option, setproduct_model_option] = useState([]);
  const [loop_option, setloop_option] = useState([]);
  const [warehouse_option, setwarehouse_option] = useState([]);
  const [rack_option, setrack_option] = useState([]);
  const [product_on_rack_option, setproduct_on_rack_option] = useState([]);
  const [load_status_option, setload_status_option] = useState([]);
  const [project_option, setproject_option] = useState([]);
  const [color_option, setcolor_option] = useState([]);
  const [feature, setfeature] = useState([]);
  const [model, setModel] = useState([]);
  const [oem_id, setOem_id] = useState(getOem());
  const [historyFG, setHistoryFG] = useState([]);
  const [filler_product, setFiller_product] = useState({
    no: "",
    name: "",
    product_model_id: "",
    oem_id: getOem(),
  });

  const [filler_checkbox, setfiller_checkbox] = useState({
    inventory: false,
    virtual_product: false,
    under_min_lot: false,
  });

  const [tool_index, settool_index] = useState({
    index1: "100",
    index2: "99",
  });
  //console.log(filler_product);
  const [search_cus_end, setsearch_cus_end] = useState({
    result_cus: "",
    result_end: "",
  });
  const [isChecked, setIsChecked] = useState(false);
  const [oemfix, setoemfix] = useState();
  //const [checked, setChecked] = useState();
  const [is_fifo, setis_fifo] = useState(true);
  const [color, setcolor] = useState([]);
  var isChecked2 = true;
  const [filter_loop, setFilter_loop] = useState({
    loop_no: "",
    company_id: getUser().com,
  });
  const [loop, setLoop] = useState([]);
  const [filter_warehouse, setFilter_warehouse] = useState({
    id: "",
    code_no: "",
    company_id: getUser().com,
    parent_id: "",
    type: "Warehouse",
  });
  const [warehouse, setWarehouse] = useState([]);
  const [rack, setRack] = useState([]);
  const [product_on_rack, setProduct_on_rack] = useState([]);
  const [load_status, setLoad_status] = useState([]);
  const [warehouse_data, setwarehouse_data] = useState({
    loop_id: "",
    warehouse_id: "",
    rack_id: "",
    product_on_rack_id: "",
    load_status_id: "",
    remark: "",
    description: "",
  });
  const [log_detailFG, setlog_detailFG] = useState({
    id: "",
    product_id: "",
    user_id: "",
    edit_type: "",
    amount: "",
    create_date: "",
    project_id: "",
    fg_lot_no: "",
    exprire_date: "",
  });
  
  const [project, setProject] = useState([]);
  const [type_select, settype_select] = useState({
    status: "plant_item",
  });

  const [filler_project, setFiller_project] = useState({
    project_name: "",
    company_id: getUser().com,
    oem_id: getOem(),
  });
  const [calender_data, setcalender_data] = useState({
    exp: "",
    now_date: new Date(),
  });

  const [summary_report, setSummary_report] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [customer_option, setcustomer_option] = useState([]);
  const [End_user_option, setEnd_user_option] = useState([]);

  const [filler_summary_report, setFiller_summary_report] = useState({
    product_name: "",
    oem_id: getOem(),
  });
  const [end_user, setEnd_user] = useState([]);
  const GetEnd_user = async () => {
    let temp = {
      oem_id: getOem(),
    };
    axios({
      method: "post",
      url: Configs.API_URL + "/end_user/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        //console.log(response.data);
        setEnd_user(response.data);
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value1"] = el.id;
          element["value2"] = el.fullname;
          element["label"] = el.name;
          options.push(element);
        });
        setEnd_user_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  var config_color = {
    method: "get",
    url: Configs.API_URL + "/product/getProductColor?id=" + getOem(),
    headers: {
      "X-TTT": Configs.API_TTT,
      Authorization: getToken(),
      "Content-Type": "application/json",
    },
  };

  const GetCustomer = async () => {
    var filter_customer = {
      name: "",
      relate_product: "",
      company_id: getUser().com,
      companyGroup_id: "",
    };
    //console.log(filler_customer);
    axios({
      method: "post",
      url: Configs.API_URL + "/customer/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_customer,
    })
      .then(function (response) {
        //console.log(response.data);
        setCustomer(response.data);
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value1"] = el.id;
          element["value2"] = el.full_name;
          element["label"] = el.name;
          options.push(element);
        });
        setcustomer_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const Getcolor = async () => {
    await axios(config_color)
      .then(function (response) {
        setcolor(response.data);
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name;
          options.push(element);
        });
        setcolor_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    if (getOem() === "480479b6-dcd3-4a4f-89a7-95c5e8f275bc") {
      setoemfix("thanasub");
    }
    ////////permission feature//////////
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "F/G IN/OUT";
    });
    var feature_data1 = JSON.parse(getFeature()).filter((element) => {
      return element.feature === "Plant Item view";
    });
    var storage_feature = [];
    for (var i = 0; i < feature_data.length; i++) {
      storage_feature.push(feature_data[i]);
    }
    for (var k = 0; k < feature_data1.length; k++) {
      storage_feature.push(feature_data1[k]);
    }
    setfeature(storage_feature);

    //console.log(feature)
    ////////permission feature//////////  {feature.map((e) =>{
    feature_data.map((e) => {
      if (e.feature === "FG IN/OUT Lot No") {
        // setamount({ ...amount, lot_no: "FG" + moment(new Date()).format("YYYYMMDD") });
      }
      if (e.feature === "FG IN/OUT EXP") {
        setcalender_data({ ...calender_data, exp: new Date() });
      }
      if (e.feature === "FG IN/OUT Color (OUT)") {
        Getcolor();
      }

      if (e.feature === "FG IN/OUT EXP") {
        sethave_exp({ ...have_exp, have: true, name: "Exprire Date" });
        have_exp.have = true;
        have_exp.name = "Exprire Date";
      }
    });

    const script = document.createElement("script");

    script.src = `js/Product`;
    document.body.appendChild(script);
  }, []);

  var config_model = {
    method: "get",
    url: Configs.API_URL + "/product/getProductModel?id=" + oem_id,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  const Getamount = async () => {
    Swal.fire({
      title: "Do you want to Save the changes?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        setblock_btn(true);
        if (
          amount.amount !== null &&
          amount.amount !== undefined &&
          amount.amount !== "" &&
          amount.id !== null &&
          Number(amount.amount.replace(/,/g, "")) > 0
        ) {
          if (amount.edit_type !== null) {
            if (
              ((amount.status_sst === "customer" && amount.customer === "") ||
                (amount.status_sst === "end_user" && amount.end_user === "")) &&
              amount.edit_type === true
            ) {
              if (amount.customer === "" && amount.status_sst === "customer") {
                Swal.fire(
                  "check input",
                  "โปรดกรอก [No. Customer] ให้ถูกต้อง",
                  "warning"
                );
              } else if (
                amount.end_user === "" &&
                amount.status_sst === "end_user"
              ) {
                Swal.fire(
                  "check input",
                  "โปรดกรอก [No. End user] ให้ถูกต้อง",
                  "warning"
                );
              }
            } else {
              var temp_amount;
              axios({
                method: "get",
                url: Configs.API_URL + "/handheld/genLotNo?oem_id=" + getOem(),
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
              })
                .then(function (response) {
                  setamount({ ...amount, lot_no: response.data });
                  amount.lot_no = response.data;

                  if (amount.edit_type === true) {
                    temp_amount = {
                      id: amount.id,
                      edit_type: amount.edit_type,
                      amount: Number(amount.amount.replace(/,/g, "")),
                      lot_no: amount.lot_no,
                      exp_date: calender_data.exp,
                      now_date: calender_data.now_date,
                      warehouse: warehouse_data,
                      status_sst: amount.status_sst,
                      customer: amount.customer,
                      end_user: amount.end_user,
                      stp_machine: amount.stp_machine,
                      remark: amount.remark,

                      //thanasub
                      buy_price: amount.buy_price,
                    };
                  } else {
                    //const test = Load_status;

                    temp_amount = {
                      id: amount.id,
                      edit_type: amount.edit_type,
                      lot_no: amount.lot_no,
                      lot_no_select: amount.lot_no_select,
                      amount: Number(amount.amount.replace(/,/g, "")),
                      project_id: amount.project_id,
                      remark: amount.remark || null,
                      color: amount.color || null,
                      warehouse: warehouse_data,
                      remark: amount.remark,

                      //thanasub
                      sell_price: Number(amount.sell_price.replace(/,/g, "")),
                      buy_price: amount.buy_price,
                      oem_fix: oemfix,
                      is_trade: amount.is_trade,
                    };
                  }

                  var status = load_status.filter((e) => {
                    return e.id === warehouse_data.load_status_id;
                  });

                  if (
                    status.length > 0 &&
                    status[0].status == "Fail" &&
                    warehouse_data.remark.trim() == ""
                  ) {
                    Swal.fire(
                      "Error",
                      'Load status in "Fail" Please enter information in the fields >>> [Remark].',
                      "warning"
                    );
                    //console.log("ไปกรอก remark เดี๋ยวนี้นะ!!!")
                  } else {
                    if (temp_amount.amount > 0) {
                      var url_api = Configs.API_URL + "/product/amountFGInOut";
                      if (is_fifo === true) {
                        //FIFO ปกติ
                        url_api = Configs.API_URL + "/product/amountFGInOut";
                      } else if (is_fifo === false) {
                        //NON-FIFO แบบเลือกตัด
                        url_api =
                          Configs.API_URL + "/product/amountFGInOut_NoFIFO";
                      }
                      axios({
                        method: "post",
                        url: url_api,
                        headers: {
                          Authorization: getToken(),
                          "X-TTT": Configs.API_TTT,
                          "Content-Type": "application/json",
                        },
                        data: temp_amount,
                      })
                        .then(async (response) => {
                          Swal.fire({
                            title: "Saving",
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            timer: 300,
                            onOpen: () => {
                              Swal.showLoading();
                            },
                          }).then((result) => {
                            Swal.fire({
                              icon: "success",
                              title: "Save",
                              showConfirmButton: false,
                              timer: 1500,
                            })
                              .then((result) => {
                                /*     if (oemfix === "thanasub") {
                                window.location.href =
                                  "/Warehouse_Management/F_G_In_Out";
                              } else { */

                                genLotNo();
                                getHistoryFG(amount.id);
                                GetSummaryReport();
                                let temp = {
                                  id: amount.id,
                                  no: "",
                                  name: "",
                                  product_model_id: "",
                                  oem_id: oem_id,

                                  //check:isChecked,
                                };
                                //clear radio----------------------------------------
                                var radioButtonArray =
                                  document.getElementsByName("check_lot_fg");
                                //console.log(radioButtonArray)
                                for (
                                  var i = 0;
                                  i < radioButtonArray.length;
                                  i++
                                ) {
                                  var radioButton = radioButtonArray[i];
                                  radioButton.checked = false;
                                }
                                //clear radio------------------------------------------
                                axios({
                                  method: "get",
                                  url:
                                    Configs.API_URL +
                                    "/product/getlog_detailFG?id=" +
                                    temp.id,
                                  headers: {
                                    Authorization: getToken(),
                                    "X-TTT": Configs.API_TTT,
                                    "Content-Type": "application/json",
                                  },
                                })
                                  .then(function (response) {
                                    setlog_detailFG(response.data);
                                  })
                                  .catch(function (error) {
                                    console.log(error);
                                  });
                                axios({
                                  method: "post",
                                  url: Configs.API_URL + "/product/filter",
                                  headers: {
                                    Authorization: getToken(),
                                    "X-TTT": Configs.API_TTT,
                                    "Content-Type": "application/json",
                                  },
                                  data: temp,
                                })
                                  .then(async (response) => {
                                    let set_total_view = response.data.filter(
                                      (element) => {
                                        return element.id === amount.id;
                                      }
                                    );

                                    //console.log(set_total_view)
                                    console.log(
                                      set_total_view[set_total_view.length - 1]
                                        .fg_amount
                                    );
                                    setamount({
                                      ...amount,
                                      total:
                                        set_total_view[
                                          set_total_view.length - 1
                                        ].fg_amount,
                                      amount: "",
                                      remark: "",
                                      color: "",
                                      status_sst: "",
                                      customer: "",
                                      remark: "",
                                      //thanasub
                                      sell_price: "",
                                      buy_price: "",
                                    });
                                    amount.total =
                                      set_total_view[
                                        set_total_view.length - 1
                                      ].fg_amount;
                                    amount.amount = "";
                                    amount.status_sst = "";
                                    amount.customer = "";
                                    amount.remark = "";
                                    //thanasub
                                    amount.sell_price = "";
                                    amount.buy_price = "";
                                    setwarehouse_data({
                                      ...warehouse_data,

                                      remark: "",
                                      description: "",
                                    });
                                  })

                                  .catch(async (error) => {
                                    console.log(error);
                                  });
                                /*       } */
                              })
                              .then(() => {
                                Gettable();
                                GetSummaryReport();
                                setblock_btn(false);
                              });
                          });
                        })
                        .catch(async (error) => {
                          Swal.fire(
                            "Over Stock!",
                            "Check your amount",
                            "warning"
                          );

                          console.log(error);
                          setblock_btn(false);
                        });
                    } else {
                      Swal.fire(
                        "check amount",
                        "amount can't negative",
                        "warning"
                      );
                      setblock_btn(false);
                    }
                  }
                })
                .catch(function (e) {
                  console.log(e);
                });
            }
          } else {
            Swal.fire(
              "check in bound , out bound",
              "bound can't null",
              "warning"
            );
          }
        } else {
          Swal.fire("check amount", "amount can't null", "warning");
        }
      }
    });
    setblock_btn(false);
  };

  const Getlog_detailFG = async () => {
    axios(config_model)
      .then(async (response) => {
        setlog_detailFG(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };
  function compare(a, b) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }

  const Getmodel = async () => {
    axios(config_model)
      .then(async (response) => {
        setModel(response.data);
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name;
          options.push(element);
        });
        //console.log(options);
        options.sort(compare);
        //console.log(options);
        setproduct_model_option(options);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  const Gettable = async () => {
    let oem_fix = "";
    if (getOem() === "480479b6-dcd3-4a4f-89a7-95c5e8f275bc") {
      setoemfix("thanasub");
      oem_fix = "thanasub";
    }
    let temp = {
      no: filler_product.no,
      name: filler_product.name,
      product_model_id: filler_product.product_model_id,
      oem_id: oem_id,
      inventory: filler_checkbox.inventory,
      virtual_product: filler_checkbox.virtual_product,
      under_min_lot: filler_checkbox.under_min_lot,
      oem_fix: oem_fix,
    };

    await axios({
      method: "post",
      url: Configs.API_URL + "/product/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        // console.log(response.data);

        response.data.sort((a, b) => b.fg_amount - a.fg_amount);

        await setProduct_data(response.data);
        //console.log(sorted);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  const Gettable_all = async () => {
    let oem_fix = "";
    if (getOem() === "480479b6-dcd3-4a4f-89a7-95c5e8f275bc") {
      setoemfix("thanasub");
      oem_fix = "thanasub";
    }
    let temp = {
      no: filler_product.no,
      name: filler_product.name,
      product_model_id: filler_product.product_model_id,
      oem_id: oem_id,
      inventory: filler_checkbox.inventory,
      virtual_product: filler_checkbox.virtual_product,
      under_min_lot: filler_checkbox.under_min_lot,
      oem_fix: oem_fix,
    };

    await axios({
      method: "post",
      url: Configs.API_URL + "/product/filter_allItem",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        //console.log(response.data)
        let test = sortJsonArray(response.data, "no", "asc");

        await setProduct_data(test);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  const Gettable2 = async () => {
    let oem_fix = "";
    if (getOem() === "480479b6-dcd3-4a4f-89a7-95c5e8f275bc") {
      setoemfix("thanasub");
      oem_fix = "thanasub";
    }
    let temp = {
      no: filler_product.no,
      name: filler_product.name,
      product_model_id: filler_product.product_model_id,
      oem_id: oem_id,
      inventory: filler_checkbox.inventory,
      virtual_product: filler_checkbox.virtual_product,
      under_min_lot: filler_checkbox.under_min_lot,
      oem_fix: oem_fix,
    };

    await axios({
      method: "post",
      url: Configs.API_URL + "/product/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        // let sorted = response.data.sort((a, b) => b.supplier.localeCompare(b.supplier));
        let sorted = response.data.sort((a, b) => b.fg_amount - a.fg_amount);
        await setProduct_data(sorted);
        /*         setProduct_data(response.data); */
        //console.log(sorted);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  const Gettable_all2 = async () => {
    let oem_fix = "";
    if (getOem() === "480479b6-dcd3-4a4f-89a7-95c5e8f275bc") {
      setoemfix("thanasub");
      oem_fix = "thanasub";
    }

    let temp = {
      no: filler_product.no,
      name: filler_product.name,
      product_model_id: filler_product.product_model_id,
      oem_id: oem_id,
      inventory: filler_checkbox.inventory,
      virtual_product: filler_checkbox.virtual_product,
      under_min_lot: filler_checkbox.under_min_lot,
      oem_fix: filler_checkbox.oem_fix,
    };

    await axios({
      method: "post",
      url: Configs.API_URL + "/product/filter_allItem",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        //console.log(response.data)
        let test = sortJsonArray(response.data, "no", "asc");

        await setProduct_data(test);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  const GetSummaryReport = async () => {
    axios({
      method: "post",
      url: Configs.API_URL + "/product/summaryReport",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_summary_report,
    })
      .then(function (response) {
        //console.log(response.data);
        setSummary_report(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  async function form(
    no,
    name,
    id,
    amount_total,
    lot_no,
    edit_type,
    exprire_date,
    unit,
    is_trade,
    buy_price
  ) {
    //thanasub
    amount.sell_price = "";
    amount.buy_price = buy_price;
    amount.is_trade = false;
    amount.amount = "";
    amount.remark = "";
    amount.status_sst = "";
    amount.customer = "";
    amount.end_user = "";
    setis_fifo(true);
    setmessage({ ...message, name: name, no: no });
    setcalender_data({ ...calender_data, exp: new Date() });

    calender_data.exp = new Date();
    setamount({
      ...amount,
      id: id,
      amount: "",
      total: amount_total || 0,
      lot_no: lot_no,
      edit_type: true,
      exprire_date: new Date(),
      unit: unit,
    });
    amount.edit_type = true;
    if (is_trade === true && oemfix === "thanasub") {
      setamount({ ...amount, edit_type: false, is_trade: true });
      amount.edit_type = false;
      amount.is_trade = true;
    }
    genLotNo();
    getHistoryFG(id);
    amount.id = id;
    amount.total = amount_total;
    amount.unit = unit;
    setlog_detailFG({ ...log_detailFG });
    setwarehouse_data({
      ...warehouse_data,
      loop_id: "",
      warehouse_id: "",
      rack_id: "",
      product_on_rack_id: "",
      load_status_id: "",
      remark: "",
      description: "",
    });
    await axios({
      method: "get",
      url: Configs.API_URL + "/product/getlog_detailFG?id=" + id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //console.log(response.data);
        setlog_detailFG(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    document.getElementById("amount_in").focus();
  }

  const [amount, setamount] = useState({
    id: "",
    edit_type: true,
    amount: "",
    total: "",
    lot_no: "",
    lot_no_select: "",
    remark: "",
    color: "",
    status_sst: "",
    customer: "",
    end_user: "",
    stp_machine: "",
    remark: "",

    //thanasub
    sell_price: "",
    //lot_no: "FG" + moment(calender_data.now_date).format('YYYYMMDD'),
  });

  const [message, setmessage] = useState({
    num: "",
    name: "",
  });
  function addpage() {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Product/add";
  }
  function handleChange(value) {
    Gettable();
  }

  const selectProduct_model = useRef();
  const selectLoop_model = useRef();
  const selectWarehouse_model = useRef();
  const selectRack_model = useRef();
  const selectProduct_on_rack_model = useRef();
  const selectLoad_status_model = useRef();
  const selectProject_model = useRef();
  const selectColor_model = useRef();
  const selectCustomer = useRef();
  const clearfillter = async () => {
    setlog_detailFG({ ...log_detailFG });
    await axios({
      method: "get",
      url: Configs.API_URL + "/product/getlog_detailFG?id=" + amount.id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //console.log(response.data);

        setlog_detailFG(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    setamount({ ...amount, customer: "", end_user: "" });

    if (amount.edit_type == true) {
      genLotNo();
    } else {
      setamount({ ...amount, lot_no: "" });
    }
    await setFiller_product({
      ...filler_product,
      product_model_id: "",
      name: "",
      no: "",
    });
    filler_product.product_model_id = "";
    filler_product.name = "";
    filler_product.no = "";
    //setIsChecked(!isChecked)
    //setChecked(!checked)
    isChecked2 = false;
    if (amount.edit_type === true) {
      {
        feature.map((e) => {
          if (e.featrure === "FG IN Warehouse") {
            selectLoop_model.current.select.clearValue();
            selectWarehouse_model.current.select.clearValue();
            selectRack_model.current.select.clearValue();
            selectProduct_on_rack_model.current.select.clearValue();
            selectLoad_status_model.current.select.clearValue();
          }
        });
      }
      selectProduct_model.current.select.clearValue();
    } else if (amount.edit_type === false) {
      {
        feature.map((e) => {
          if (e.featrure === "FG OUT Project") {
            selectProject_model.current.select.clearValue();
          }
          if (e.featrure === "FG IN/OUT Color (OUT)") {
            selectColor_model.current.select.clearValue();
          }
        });
      }
    }

    Gettable();
  };
  function addpage() {
    window.location.href = "/Warehouse_Management/F_G_In_Out";
  }

  const GetProject = async () => {
    //console.log(filler_project);
    axios({
      method: "post",
      url: Configs.API_URL + "/project/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_project,
    })
      .then(function (response) {
        //console.log(response.data);
        setProject(response.data);
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.project_name;
          options.push(element);
        });
        setproject_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  ///////////////fg out to in warehouse feature/////////////////
  const GetLoop = async () => {
    axios({
      method: "post",
      url: Configs.API_URL + "/loop/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_loop,
    })
      .then(function (response) {
        //console.log(response.data);
        setLoop(response.data);
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.loop_no;
          options.push(element);
        });
        setloop_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const GetWarehouse = async () => {
    axios({
      method: "post",
      url: Configs.API_URL + "/warehouse/filter/warehouse",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_warehouse,
    })
      .then(function (response) {
        //console.log(response.data);
        setWarehouse(response.data);
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.code_no;
          options.push(element);
        });
        setwarehouse_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  
  function GetRack(id) {
    var temp = {
      id: "",
      code_no: "",
      company_id: getUser().com,
      parent_id: id,
      type: "Rack",
    };
    axios({
      method: "post",
      url: Configs.API_URL + "/warehouse/filter/rack",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        //console.log(response.data);
        selectRack_model.current.select.clearValue();
        setRack(response.data);
        GetProduct_on_rack(response.data.id);
        const options = [];
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.code_no;
          options.push(element);
        });
        setrack_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function GetProduct_on_rack(id) {
    var temp = {
      id: "",
      code_no: "",
      company_id: getUser().com,
      parent_id: id,
      type: "Product_on_rack",
    };
    axios({
      method: "post",
      url: Configs.API_URL + "/warehouse/filter/product_on_rack",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        //console.log(response.data);
        selectProduct_on_rack_model.current.select.clearValue();
        setProduct_on_rack(response.data);
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.code_no;
          options.push(element);
        });
        setproduct_on_rack_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const GetLoad_status = async () => {
    var temp = {
      status: "",
      company_id: getUser().com,
    };
    //console.log(filler_project);
    axios({
      method: "post",
      url: Configs.API_URL + "/load_status/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        //console.log(response.data);
        setLoad_status(response.data);
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.status;
          options.push(element);
        });
        setload_status_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    Gettable();
    Getmodel();
    GetProject();
    Getlog_detailFG();
    GetSummaryReport();
    ///////////////fg out to in warehouse feature/////////////////
    GetLoop();
    GetWarehouse();
    GetLoad_status();
    GetCustomer();
    GetEnd_user();
  }, []);

  if (amount.amount.length > 0 && oemfix === "thanasub") {
    var input = document.getElementById("amount_in");

    // Execute a function when the user presses a key on the keyboard
    input.addEventListener("keypress", function (event) {
      // If the user presses the "Enter" key on the keyboard
      if (event.key === "Enter") {
        // Cancel the default action, if needed
        event.preventDefault();
        // Trigger the button element with a click
        document.getElementById("btn_save").click();
      }
    });
  }

  const [wip_info, setwip_info] = useState({
    wip_amount: "",
  });
  function formWipInfo(wip, e) {
    setwip_info({ ...wip_info, wip_amount: wip || 0 });
    wip_info.wip_amount = wip || 0;
  }

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );
  async function SetDate_and_GetDayoff_start(date) {
    setcalender_data({ ...calender_data, exp: date });
  }

  const rowsData = [];
  //console.log(type_select);
  for (var index = 0; index < product_data.length; index++) {
    var id_toProduct;
    var FG_amount;
    //console.log(type_select.status)
    if (type_select.status === "all_item") {
      console.log(product_data[index].product_plants_item);
      if (product_data[index].product_plants_item === null) {
        //console.log("HERE")
        id_toProduct = product_data[index].id;
        FG_amount = product_data[index].fg_amount || 0;
      } else {
        id_toProduct = product_data[index].product_plants_item;
        var find_fg_amount = product_data.filter((element) => {
          return element.id === id_toProduct;
        });

        //console.log("find_fg_amount[0]",find_fg_amount);
        if (find_fg_amount.length > 0) {
          FG_amount = find_fg_amount[0].fg_amount;
        } else {
          FG_amount = product_data[index].fg_amount || 0;
        }
      }
    } else {
      id_toProduct = product_data[index].id;
      FG_amount = product_data[index].fg_amount;
    }
    const rowItem = {};
    if (product_data[index].fg_amount < product_data[index].min_product) {
      var c = "red";
    } else if (
      product_data[index].fg_amount >= product_data[index].min_product
    ) {
      var c = "black";
    } else {
      var c = "black";
    }
    for (let indexin = 0; indexin < model.length; indexin++) {
      if (
        model[indexin].id == product_data[index].product_model_id &&
        product_data[index].product_model_id !== undefined
      ) {
        rowItem["product_model_id"] = (
          <div style={{ color: c }}>{"" + model[indexin].name}</div>
        );
      }
    }

    if (FG_amount !== null) {
      if (
        feature.filter((e) => {
          return e.feature === "AmountDecimalScale";
        }).length > 0
      ) {
        rowItem["amount"] = (
          <>
            {feature.filter((e) => {
              return e.feature === "See_Wip_amount";
            }).length > 0 ? (
              <a
                data-toggle="modal"
                data-target="#modal-wip_info"
                href=""
                onClick={formWipInfo.bind(this, product_data[index].wip_amount)}
              >
                <div style={{ color: c }}>
                  <NumberFormat
                    value={FG_amount.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </div>
              </a>
            ) : (
              <div style={{ color: c }}>
                <NumberFormat
                  value={FG_amount.toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </div>
            )}
          </>
        );
      } else {
        rowItem["amount"] = (
          <>
            {feature.filter((e) => {
              return e.feature === "See_Wip_amount";
            }).length > 0 ? (
              <a
                data-toggle="modal"
                data-target="#modal-wip_info"
                href=""
                onClick={formWipInfo.bind(this, product_data[index].wip_amount)}
              >
                <div style={{ color: c }}>
                  <NumberFormat
                    value={FG_amount}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </div>
              </a>
            ) : (
              <div style={{ color: c }}>
                <NumberFormat
                  value={FG_amount}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </div>
            )}
          </>
        );
      }
    } else {
      rowItem["amount"] = (
        <>
          {feature.filter((e) => {
            return e.feature === "See_Wip_amount";
          }).length > 0 ? (
            <a
              data-toggle="modal"
              data-target="#modal-wip_info"
              href=""
              onClick={formWipInfo.bind(this, product_data[index].wip_amount)}
            >
              <div style={{ color: c }}>0</div>
            </a>
          ) : (
            <div style={{ color: c }}>0</div>
          )}
        </>
      );
    }
    rowItem["num"] = <div /* style={{ color: c }} */>{index + 1}</div>;
    rowItem["no"] = product_data[index].no;
    rowItem["name"] = product_data[index].name;

    rowItem["qty_unit"] = (
      <div style={{ color: c }}>{product_data[index].qty_unit}</div>
    );
    rowItem["min_prod"] = (
      <div style={{ color: c }}>
        <NumberFormat
          value={
            product_data[index].min_product === null
              ? 0
              : product_data[index].min_product
          }
          displayType={"text"}
          thousandSeparator={true}
        />
      </div>
    );
    rowItem["unit"] = product_data[index].unit;
    var unit_img = "";
    unit_img = product_data[index].unit;
    rowItem["image"] = (
      <div style={{ textAlign: "center" }}>
        <img
          alt="..."
          className="img-fluid rounded "
          src={
            product_data[index].imaget_path !== null
              ? Configs.API_URL_IMG + product_data[index].imaget_path
              : userdefault_img.imgs
          }
          style={{ width: "50px", height: "50px" }}
        />
      </div>
    );
    rowItem["qr"] = (
      <div style={{ textAlign: "center" }}>
        <QRCode
          value={product_data[index].no}
          size={50}
          fgColor={"#000000"}
          bgColor={"#ffffff"}
          level={"M"}
          renderAs={"svg"}
          includeMargin={false}
          imageSettings={{
            src: logoTTT.imgs,
            height: 15,
            width: 15,
            x: null,
            y: null,
            excavate: true,
          }}
        />
      </div>
    );
    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            className=" btn btn-xs "
            data-toggle="modal"
            data-target="#modal-xl"
            onClick={form.bind(
              this,
              product_data[index].no,
              product_data[index].name,
              id_toProduct,
              FG_amount,
              amount.lot_no,
              amount.edit_type,
              calender_data.exp,
              unit_img,
              product_data[index].is_trade,
              product_data[index].buy_price || 0
            )}
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
      </div>
    );

    //thanasub
    rowItem["sell_price"] = numberWithCommas(
      product_data[index].sell_price || 0
    );
    rowItem["supplier"] = product_data[index].supplier || "ไม่ระบุ";
    rowItem["line"] = product_data[index].line;
    rowItem["tel"] = product_data[index].tel;
    rowItem["description"] = product_data[index].description;

    //thanasub

    rowsData.push(rowItem);
  }
  {
    feature.map((e) => {
      if (e.feature === "Image") {
      }
    });
  }

  var go = [];

  go.push(
    {
      label: "No",
      field: "num",
      /*     sort: "asc", */
      width: 50,
    },
    {
      label: "Product No",
      field: "no",
      /*        sort: "asc", */
      width: 50,
    },
    {
      label: "Image",
      field: "image",
      /*     sort: "asc", */
      width: 50,
    },
    {
      label: "QR",
      field: "qr",
      /*    sort: "asc", */
      width: 50,
    },

    {
      label: "Product" + " " + "Name",
      field: "name",
      /*      sort: "asc", */
      width: 100,
    },
    {
      label: "Model Category",
      field: "product_model_id",
      /*      sort: "asc", */
      width: 50,
    },

    {
      label: "Qty Unit",
      field: "qty_unit",
      /*        sort: "asc", */
      width: 50,
    },
    {
      label: "Min Stock",
      field: "min_prod",
      /*         sort: "asc", */
      width: 50,
    },
    //thanasub

    {
      label: "Supplier",
      field: "supplier",
      sort: "asc",
      width: 50,
    },
    {
      label: "Line",
      field: "line",
      /*         sort: "asc", */
      width: 50,
    },
    {
      label: "Tel.",
      field: "tel",
      /*     sort: "asc", */
      width: 50,
    },
    //thanasub
    {
      label: "Amount",
      field: "amount",
      /*    sort: "asc", */
      width: 50,
    },
    {
      label: "Sell Price",
      field: "sell_price",
      /*         sort: "asc", */
      width: 50,
    }
  );

  var ComConfig = getComConfig();
  /* console.log(ComConfig.com_name) */
  if (ComConfig.com_name !== "Central Spring co., Ltd.") {
    go.push({
      label: "Unit",
      field: "unit",

      width: 50,
    });
  }

  {
    feature.map((e) => {
      if (e.feature === "description") {
        go.push({
          label: "Description",
          field: "description",
          /*      sort: "asc", */
          width: 50,
        });
      }
    });
  }
  // console.log('getUser().fup ', getUser().fup )
  if (getUser().fup !== "2eb417e0-4499-42e3-829a-c31dc32e2b34"){
    go.push({
      label: "MGT",
      field: "mgt",
      /*      sort: "asc", */
      width: 50,
      // hiden : getUser().fup === "0fc9e975-8bfa-43ff-9f6f-c31120c9c957"
    });
  }
  const data = {
    columns: go,
    rows: rowsData,
  };
  var Image_feature = feature.filter((e) => {
    return e.feature === "Image";
  });
  var Qty_unit_feature = feature.filter((e) => {
    return e.feature === "Qty_unit";
  });
  var QR_feature = feature.filter((e) => {
    return e.feature === "QR";
  });
  var min_stock_feature = feature.filter((e) => {
    return e.feature === "min_stock";
  });
  if (Image_feature.length === 0) {
    var index = data.columns.findIndex((e) => {
      return e.field === "image";
    });
    data.columns.splice(index, 1);
  }
  if (Qty_unit_feature.length === 0) {
    var index = data.columns.findIndex((e) => {
      return e.field === "qty_unit";
    });
    data.columns.splice(index, 1);
  }
  if (QR_feature.length === 0) {
    var index = data.columns.findIndex((e) => {
      return e.field === "qr";
    });
    data.columns.splice(index, 1);
  }
  if (min_stock_feature.length === 0) {
    var index = data.columns.findIndex((e) => {
      return e.field === "min_prod";
    });
    data.columns.splice(index, 1);
  }
  if (oemfix === "thanasub") {
    /*     var index = data.columns.findIndex((e) => {
      return e.field === "no";
    }) */
    data.columns.splice(index, 1);
    var index2 = data.columns.findIndex((e) => {
      return e.field === "unit";
    });
    data.columns.splice(index2, 1);

    var index3 = data.columns.findIndex((e) => {
      return e.field === "qty_unit";
    });
    if (Qty_unit_feature.length > 0) {
      data.columns.splice(index3, 1);
    }
  } else {
    var index = data.columns.findIndex((e) => {
      return e.field === "sell_price";
    });
    data.columns.splice(index, 1);
    var index2 = data.columns.findIndex((e) => {
      return e.field === "supplier";
    });
    data.columns.splice(index2, 1);
    var index3 = data.columns.findIndex((e) => {
      return e.field === "line";
    });
    data.columns.splice(index3, 1);
    var index4 = data.columns.findIndex((e) => {
      return e.field === "tel";
    });
    data.columns.splice(index4, 1);
  }

  const options = [];
  for (let index = 0; index < 5; index++) {
    const element = [];
    element["value"] = index;
    element["label"] = index;
    options.push(element);
  }

  const rowsData_sum_report = [];

  for (var index = 0; index < summary_report.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["part_no"] = summary_report[index].no;
    rowItem["pro_name"] = summary_report[index].name;
    if (summary_report[index].edit_type == true) {
      rowItem["edit_type"] = "in";
    } else {
      rowItem["edit_type"] = "out";
    }

    rowItem["amount"] = summary_report[index].amount;
    rowItem["project"] = summary_report[index].project_name;
    rowItem["date"] = moment(summary_report[index].create_date).format(
      "DD/MM/yyyy :: HH:mm"
    );
    rowsData_sum_report.push(rowItem);
  }

  const sum_report = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product No",
        field: "part_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product Name",
        field: "pro_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "edit_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "Project",
        field: "project",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData_sum_report,
  };

  function changes_log_no(e) {
    //console.log(e.target.value);
    var lot_no = e.target.value;
    setamount({
      ...amount,
      lot_no_select: lot_no,
    });
    amount.lot_no_select = lot_no;
    //console.log(amount);
  }
  var unit_name = "";
  unit_name = amount.unit || "";
  const rowsData_lot_detail = [];

  for (var index = 0; index < log_detailFG.length; index++) {
    const rowItem = {};
    rowItem["fg_lot_no"] = log_detailFG[index].fg_lot_no;
    if (
      feature.filter((e) => {
        return e.feature === "AmountDecimalScale";
      }).length > 0
    ) {
      rowItem["amount"] =
        parseFloat(log_detailFG[index].amount)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        " " +
        unit_name;
    } else {
      rowItem["amount"] =
        parseFloat(log_detailFG[index].amount)
          .toFixed(0)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        " " +
        unit_name;
    }
    //rowItem["amount"] = parseFloat(log_detailFG[index].amount).toFixed( 2 ).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ;

    rowItem["exprire_date"] = log_detailFG[index].exprire_date
      ? moment(log_detailFG[index].exprire_date) /* .add(7, 'hours') */
          .format("DD/MM/yyyy")
      : "-";

    /////---feature status_sst ---//////
    rowItem["is_reserved"] =
      log_detailFG[index].is_reserved === true ? "ติดจอง" : "คลัง";
    rowItem["customer"] = log_detailFG[index].customer || "";
    rowItem["stp_machine"] = log_detailFG[index].stp_machine || "";
    /////---feature status_sst ---//////
    rowItem["mgt"] = (
      <div className="row">
        <div className="col-6"></div>
        <div className="col-4">
          <input
            className="form-check-input"
            type="radio"
            id={index}
            name="check_lot_fg"
            value={log_detailFG[index].fg_lot_no}
            onClick={changes_log_no.bind(this)}
          />
        </div>
      </div>
    );
    rowsData_lot_detail.push(rowItem);
  }
  const [have_exp, sethave_exp] = useState({
    have: false,
    name: "Create date",
  });

  const Data_lot_detail = {
    columns: [
      ///////// mode no-FIFO ///////
      {
        label: "++++",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
      ///////// mode no-FIFO ///////
      {
        label: "Lot No",
        field: "fg_lot_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: have_exp.name,
        field: "exprire_date",
        sort: "asc",
        width: 50,
      },
      /////---feature status_sst ---//////
      {
        label: "สถานะ",
        field: "is_reserved",
        sort: "asc",
        width: 50,
      },
      {
        label: "ลูกค้า/ตัวแทนจำหน่าย",
        field: "customer",
        sort: "asc",
        width: 50,
      },

      /////---feature status_sst ---//////
    ],
    rows: rowsData_lot_detail,
  };
  var Status_sst = feature.filter((e) => {
    return e.feature === "status_sst";
  });
  if (Status_sst.length === 0) {
    var index = Data_lot_detail.columns.findIndex((e) => {
      return e.field === "is_reserved";
    });
    Data_lot_detail.columns.splice(index, 1);
    var index2 = Data_lot_detail.columns.findIndex((e) => {
      return e.field === "customer";
    });

    Data_lot_detail.columns.splice(index2, 1);
    /*   var index3 = Data_lot_detail.columns.findIndex((e) => {
      return e.field === "stp_machine";
    })
    Data_lot_detail.columns.splice(index3, 1); */
  }

  if (is_fifo === true) {
    var index = Data_lot_detail.columns.findIndex((e) => {
      return e.field === "mgt";
    });
    Data_lot_detail.columns.splice(index, 1);
  }

  function showmessage() {
    Swal.fire({
      icon: "info",
      title: "Waiting for connect service API QR",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    });
  }
  async function set_status_plant_item(e) {
    settype_select({
      ...type_select,
      status: e.target.value,
    });
    //console.log(type_select.status)
    Gettable();
  }

  async function set_status_all_item(e) {
    settype_select({
      ...type_select,
      status: e.target.value,
    });
    //console.log(type_select.status)
    Gettable_all();
  }

  async function search_check(e) {
    //setIsChecked();
    //setIsChecked(e);
    isChecked2 = e;
    setfiller_checkbox({ ...filler_checkbox, inventory: e });
    filler_checkbox.inventory = e;
    //console.log(isChecked2)
    search_datacheck();
  }

  function search_data() {
    if (type_select.status === "all_item") {
      //console.log("all_item")
      Gettable_all();
    } else {
      //console.log("plant_item")
      Gettable();
    }
  }

  function search_datacheck() {
    if (type_select.status === "all_item") {
      //console.log("all_item")
      Gettable_all2();
    } else {
      //console.log("plant_item")
      Gettable2();
    }
  }

  async function genLotNo() {
    axios({
      method: "get",
      url: Configs.API_URL + "/handheld/genLotNo?oem_id=" + getOem(),
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        setamount({ ...amount, lot_no: response.data });
        amount.lot_no = response.data;
        //console.log(response.data)
      })
      .catch(function (e) {
        console.log(e);
      });
  }

  function getHistoryFG(product_id) {
    axios({
      method: "get",
      url: Configs.API_URL + "/product/getHistoryFG?id=" + product_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      /* data: tracking, */
    })
      .then(function (response) {
        setHistoryFG(response.data);
        //console.log(response.data)
      })
      .catch(function (e) {
        console.log(e);
      });
  }
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const rowsData_historyFG = [];

  for (var index = 0; index < historyFG.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["fg_lot_no"] = historyFG[index].fg_lot_no;

    var status;
    if (historyFG[index].edit_type === true) {
      status = "IN";
    } else {
      status = "OUT";
    }
    rowItem["status"] = status;
    if (
      feature.filter((e) => {
        return e.feature === "AmountDecimalScale";
      }).length > 0
    ) {
      rowItem["amount"] =
        parseFloat(historyFG[index].amount)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        " " +
        unit_name;
    } else {
      rowItem["amount"] =
        parseFloat(historyFG[index].amount)
          .toFixed(0)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        " " +
        unit_name;
    }
    rowItem["date"] = moment(historyFG[index].create_date).format(
      "DD/MM/yyyy :: HH:mm น."
    );
    rowItem["is_reserved"] =
      historyFG[index].is_reserved === true ? "ติดจอง" : "คลัง";
    rowItem["customer"] = historyFG[index].customer || "";
    rowItem["stp_machine"] = historyFG[index].stp_machine || "";

    rowItem["remark"] = historyFG[index].remark;
    rowsData_historyFG.push(rowItem);
  }

  const Data_HistoryFG = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Lot No",
        field: "fg_lot_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },

      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 50,
      },
      /////---feature status_sst ---//////
      {
        label: "สถานะ",
        field: "is_reserved",
        sort: "asc",
        width: 50,
      },
      {
        label: "ลูกค้า/ตัวแทนจำหน่าย",
        field: "customer",
        sort: "asc",
        width: 50,
      },
      /*  {
        label: "เครื่องทอ",
        field: "stp_machine",
        sort: "asc",
        width: 50,
      }, */

      {
        label: "Remark",
        field: "remark",
        sort: "asc",
        width: 50,
      },
      /////---feature status_sst ---//////
    ],
    rows: rowsData_historyFG,
  };

  if (Status_sst.length === 0) {
    var index = Data_HistoryFG.columns.findIndex((e) => {
      return e.field === "is_reserved";
    });
    Data_HistoryFG.columns.splice(index, 1);
    var index2 = Data_HistoryFG.columns.findIndex((e) => {
      return e.field === "customer";
    });
    Data_HistoryFG.columns.splice(index2, 1);
    /*   var index3 = Data_HistoryFG.columns.findIndex((e) => {
      return e.field === "stp_machine";
    })
    Data_HistoryFG.columns.splice(index3, 1); */
  }
  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = data.color;
      return {
        ...styles,
        backgroundColor: isSelected ? "#87CEFA" : "#FFF",
        color: "#000",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };

  function searchArrayCus(key) {
    var data_cus = customer.filter((e) => {
      return e.name === key;
    });
    var customer_ = "No Customer!!!";
    var customer_id = "";
    if (data_cus.length > 0) {
      customer_ = data_cus[0].name + "    " + data_cus[0].full_name;
      customer_id = data_cus[0].id;
    }
    setamount({ ...amount, customer: customer_id });
    amount.customer = customer_id;
    setsearch_cus_end({ ...search_cus_end, result_cus: customer_ });
    setsearch_cus_end.result_cus = customer_;
    //console.log(customer_);
  }

  function searchArrayEnd(key) {
    var data_end = end_user.filter((e) => {
      return e.name === key;
    });
    var enduser_ = "No End user!!!";
    var enduser_id = "";
    if (data_end.length > 0) {
      enduser_ = data_end[0].name + "    " + data_end[0].fullname;
      enduser_id = data_end[0].id;
    }
    setamount({ ...amount, end_user: enduser_id });
    amount.end_user = enduser_id;
    setsearch_cus_end({ ...search_cus_end, result_end: enduser_ });
    setsearch_cus_end.result_end = enduser_;
    //console.log(customer_);
  }

  const linkreport = async () => {
    Swal.fire({
      title: "กำลังโหลดข้อมูลรายงาน",
      /*    html: 'Please wait...', */
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    var get_filler_materail = {
      oem_id: getOem(),
      company_id: getUser().com,
    };

    axios({
      method: "post",
      url: Configs.API_URL + "/product/getproductreport",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_materail,
    })
      .then(function (response) {
        Swal.fire({
          didOpen: () => {
            Swal.close();
          },
        });
        if (response.data < 1) {
          Swal.fire({
            icon: "error",
            title: "ไม่มีข้อมูล",
          });
        } else {
          stock_product_report(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="fginout" submenu="none" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  F/G In/Out{"  "}
                  <a target="_blank" href={getAcademy()}>
                    <i class="fa fa-info-circle"></i>
                  </a>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">F/G In/Out</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_product.no}
                    onChange={(e) => {
                      setFiller_product({
                        ...filler_product,
                        no: e.target.value.trim(),
                      });
                    }}
                  />
                  <label htmlFor="">Product No.</label>{" "}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_product.name.trim()}
                    onChange={(e) => {
                      setFiller_product({
                        ...filler_product,
                        name: e.target.value.trim(),
                      });
                    }}
                  />
                  <label htmlFor="">Product Name</label>{" "}
                </div>
              </div>

              <div
                className="col-12 col-md-6 col-xl-3"
                style={{ "z-index": "99" }}
              >
                <div className="form-group ">
                  <Select
                    placeholder=""
                    styles={colourStyles}
                    options={product_model_option}
                    defaultValue={filler_product.product_model_id}
                    ref={selectProduct_model}
                    onChange={(e) => {
                      // console.log(e);
                      if (e !== null) {
                        setFiller_product({
                          ...filler_product,
                          product_model_id: e.value,
                        });
                        filler_product.product_model_id = e.value;
                      }

                      search_data();
                    }}
                  />
                  <label htmlFor="">Model Category</label>
                </div>
              </div>

              {/* <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filler_product.product_model_id}
                    onChange={(e) => {
                      setFiller_product({
                        ...filler_product,
                        product_model_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select Model
                    </option>
                    {model.map((el) => {
                      return <option value={el.id}>{el.name}</option>;
                    })}
                  </select>
                  <label htmlFor="">Category/Model</label>
                </div>
              </div> */}
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              {feature.map((e) => {
                if (e.feature === "Plant Item view") {
                  return (
                    <>
                      <div className="col-1"></div>
                      <div className="col-5 col-md-3 col-xl-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="plant_item"
                          name="type_select"
                          value="plant_item"
                          checked={
                            type_select.status === "plant_item" ? true : false
                          }
                          onClick={set_status_plant_item.bind(this)}
                        />
                        <label htmlFor="plant_item">Plant items view.</label>
                      </div>
                      <div className="col-1"></div>
                      <div className="col-5 col-md-3 col-xl-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="all_item"
                          name="type_select"
                          value="all_item"
                          checked={
                            type_select.status === "all_item" ? true : false
                          }
                          onClick={set_status_all_item.bind(this)}
                        />
                        <label htmlFor="all_item">All items view.</label>
                      </div>
                    </>
                  );
                }

                if (e.feature === "Filter Inventory Checkbox") {
                  return (
                    <>
                      <div className="col-1"></div>
                      <div className="col-5 col-md-3 col-xl-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="ch_inventory"
                          onChange={(event) => {
                            //console.log(event.currentTarget.checked);
                            setfiller_checkbox({
                              ...filler_checkbox,
                              inventory: event.currentTarget.checked,
                            });
                            filler_checkbox.inventory =
                              event.currentTarget.checked;
                            Gettable();
                            /*  isChecked2 = setIsChecked(event.currentTarget.checked);
                             search_check(event.currentTarget.checked) */
                          }}
                          //onClick = {search_check.bind(this)}
                          //checked={isChecked2 === true ? true : false}
                          checked={filler_checkbox.inventory}
                        />
                        <label htmlFor="ch_inventory">Inventory</label>
                      </div>
                    </>
                  );
                }
                if (e.feature === "Filter Virtual Product Checkbox") {
                  return (
                    <>
                      <div className="col-1"></div>
                      <div className="col-5 col-md-3 col-xl-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="ch_virtual_product"
                          onChange={(event) => {
                            //console.log(event.currentTarget.checked);
                            setfiller_checkbox({
                              ...filler_checkbox,
                              virtual_product: event.currentTarget.checked,
                            });
                            filler_checkbox.virtual_product =
                              event.currentTarget.checked;
                            Gettable();
                            /*  isChecked2 = setIsChecked(event.currentTarget.checked);
                             search_check(event.currentTarget.checked) */
                          }}
                          //onClick = {search_check.bind(this)}
                          //checked={isChecked2 === true ? true : false}
                          checked={filler_checkbox.virtual_product}
                        />
                        <label htmlFor="ch_virtual_product">
                          Virtual Product
                        </label>
                      </div>
                    </>
                  );
                }
                if (e.feature === "Filter Under Min Stock Checkbox") {
                  return (
                    <>
                      <div className="col-1"></div>
                      <div className="col-5 col-md-3 col-xl-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="ch_under_min_lot"
                          onChange={(event) => {
                            //console.log(event.currentTarget.checked);
                            setfiller_checkbox({
                              ...filler_checkbox,
                              under_min_lot: event.currentTarget.checked,
                            });
                            filler_checkbox.under_min_lot =
                              event.currentTarget.checked;
                            Gettable();
                            /*  isChecked2 = setIsChecked(event.currentTarget.checked);
                             search_check(event.currentTarget.checked) */
                          }}
                          //onClick = {search_check.bind(this)}
                          //checked={isChecked2 === true ? true : false}
                          checked={filler_checkbox.under_min_lot}
                        />
                        <label htmlFor="ch_under_min_lot">
                          Under min stock
                        </label>
                      </div>
                    </>
                  );
                }
              })}
            </div>{" "}
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      search_data();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info "
                    onClick={() => {
                      clearfillter();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
              {feature.map((e) => {
                if (e.feature === "FG IN OUT Report Stock") {
                  return (
                    <div className="col-8 col-md-3 col-xl-2">
                      <div className="form-group ">
                        <ReactHTMLTableToExcel
                          id="test1-table-xls-button"
                          className="btn btn-block btn-primary"
                          table="stock-table-to-xls"
                          filename={
                            "St" + " " + moment(new Date()).format("DD-MM-yyyy")
                          }
                          sheet="tablexls"
                          buttonText="download FG Stock.xls"
                        />
                      </div>
                    </div>
                  );
                }
              })}

              {feature.map((e) => {
                if (e.feature === "stock product report") {
                  return (
                    <div className="col-4 col-md-3 col-xl-2">
                      <div className="form-group ">
                        <button
                          type="button"
                          className="btn btn-block btn-info"
                          onClick={() => linkreport()}
                        >
                          รายงาน Stock ปัจจุบัน
                        </button>
                      </div>
                    </div>
                  );
                }
              })}

              {feature.map((e) => {
                if (e.feature === "Summary Project Report") {
                  return (
                    <div className="col-8 col-md-3 col-xl-2">
                      <div className="form-group ">
                        <ReactHTMLTableToExcel
                          id="test-table-xls-button"
                          className="btn btn-block btn-primary"
                          table="table-to-xls"
                          filename={
                            "SM" + " " + moment(new Date()).format("DD-MM-yyyy")
                          }
                          sheet="tablexls"
                          buttonText="Summary Project Report"
                        />
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Product</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body ">
              <div
                className="table-responsive"
                style={{ whiteSpace: "nowrap" }}
              >
                <MDBDataTable
                  className="table table-head-fixed"
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={data}
                  width="1000px"
                />
              </div>

              <div className="table-responsive" style={{ display: "none" }}>
                <MDBDataTable
                  className="table table-head-fixed"
                  sortable={false}
                  striped
                  bordered
                  entries={4096}
                  hover
                  fixedHeader
                  id="table-to-xls"
                  data={sum_report}
                />
              </div>
            </div>
            <div className="card-footer"></div>
          </div>
        </section>

        <div className="modal fade" id="modal-xl">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title">
                  {" "}
                  Edit Amount : Items in warehouse &nbsp;&nbsp;&nbsp;
                  {
                    <NumberFormat
                      style={{
                        backgroundColor: "#F0FFFF",
                        border: "1px solid #ddd",
                        padding: "10px",
                        width: "100",
                      }}
                      value={
                        feature.filter((e) => {
                          return e.feature === "AmountDecimalScale";
                        }).length > 0
                          ? (amount.total || 0).toFixed(2)
                          : amount.total || 0
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  }
                  &nbsp;&nbsp;&nbsp; items
                </h1>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-0 col-md-2 col-xl-2"></div>
                  <div className="col-12 col-md-5 col-xl-5">
                    <h3>Product No : {message.no}</h3>
                  </div>

                  <div className="col-12 col-md-5 col-xl-5">
                    <h3>Product Name : {message.name}</h3>
                  </div>
                </div>

                <div className="row">
                  <div className="col-0 col-md-2 col-xl-2"></div>
                  <div className="col-0">
                    <input
                      type="radio"
                      id="inbound"
                      name="edit_type"
                      disabled={amount.is_trade}
                      checked={amount.edit_type != true ? false : true}
                      value={true}
                      onChange={(e) => {
                        setamount({
                          ...amount,
                          edit_type: e.target.value === "true" ? true : false,
                        });
                        setis_fifo(true);
                      }}
                    />
                  </div>
                  <div className="col-5 col-md-5 col-xl-5">
                    <label htmlFor="inbound">
                      <h4>
                        {oemfix === "thanasub" ? " ซื้อเข้า" : "In bound"}
                      </h4>
                    </label>
                  </div>

                  <div className="col-0">
                    <input
                      type="radio"
                      id="outbound"
                      name="edit_type"
                      value={false}
                      checked={amount.edit_type != false ? false : true}
                      onChange={(e) => {
                        setamount({
                          ...amount,
                          edit_type: e.target.value === "false" ? false : true,
                        });
                      }}
                    />
                  </div>
                  <div className="col-5 col-md-4 col-xl-4">
                    <label htmlFor="outbound">
                      <h4>{oemfix === "thanasub" ? " ขายออก" : "Out bound"}</h4>
                    </label>
                  </div>
                </div>
                {amount.edit_type === false ? (
                  <>
                    {feature.map((e) => {
                      if (e.feature === "Switch_Logic_Out_bound") {
                        return (
                          <>
                            <div
                              className="row"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              <div className="col-0 col-md-3 col-xl-3"></div>
                              <div
                                className="col-5 col-md-2 col-xl-2"
                                style={{ textAlign: "right" }}
                              >
                                <label for="is_fifo">Manual out FG</label>
                              </div>
                              <div className="col-3">
                                <div class="custom-control custom-switch">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="is_fifo"
                                    defaultChecked={is_fifo}
                                    onClick={(e) => {
                                      //console.log(e);

                                      setis_fifo(e.target.checked);

                                      setis_fifo(e.target.checked);
                                    }}
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="is_fifo"
                                  >
                                    First In First Out
                                  </label>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      }
                    })}
                  </>
                ) : (
                  <></>
                )}

                {feature.map((e) => {
                  if (e.feature === "FG IN/OUT History") {
                    return (
                      <LOGH
                        onClick={clearfillter}
                        history={Data_HistoryFG}
                        data={Data_lot_detail}
                      />
                    );
                  }
                })}
                {/*  <div style={{
                  color: "#000000",
                  background: "#D3D3D3",
                  border: " 7px solid #FFFFFF",
                  borderradius: "20px"
                }} ><p>Log Detail</p>

                  <div className="col-2">
                    <div className="form-group ">
                      <button
                        type="button"
                        className="btn btn-block btn-info "
                        onClick={() => {
                          clearfillter();
                        }}
                      >
                        Clear
                      </button>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive" >
                      <MDBDataTable
                        className="table table-head-fixed"
                        sortable = {false}
                        searching={false}
                        striped
                        bordered
                        hover
                        fixedHeader
                        order={['amount', 'asc']}
                        data={Data_lot_detail}
                      />
                    </div>
                  </div>
                
                </div> */}
                {amount.edit_type === false ? (
                  <div className="row">
                    <div className="col-4"></div>

                    {feature.map((e) => {
                      if (e.feature === "FG OUT Project") {
                        return (
                          <>
                            <div
                              className="col-12 col-md-4 col-xl-4"
                              style={{ "z-index": "98" }}
                            >
                              <div className="form-group ">
                                <Select
                                  options={project_option}
                                  defaultValue={amount.project_id}
                                  ref={selectProject_model}
                                  onChange={(e) => {
                                    //console.log(e);
                                    if (e !== null) {
                                      setamount({
                                        ...amount,
                                        project_id: e.value,
                                      });
                                    }
                                  }}
                                />
                                <label htmlFor="">Project</label>
                              </div>
                            </div>
                          </>
                        );
                      }
                    })}

                    <div className="col-4"></div>
                    <div className="col-4"></div>
                    <div className="col-4">
                      {/*  {feature.map((e) =>{
                    if(e.feature === "FG IN/OUT Lot No"){
                      
                      return (
                        <INF value = {amount.lot_no} disabled = {true} label = {"Lot No"} onChange ={(e) => {
                          setamount({
                            ...amount,
                            lot_no: e.target.value,
                          });
                        }}/>
                      );
                    }
                  })

                  } */}
                    </div>
                    <div className="col-1">
                      <div className="form-group ">
                        {/*   <button
                        type="button"
                        className="btn btn-primary"
                        onClick={clearfillter}
                      >
                        Clear
                      </button> */}
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="row">
                      {/*           <div className="col-4"></div> */}
                      {amount.edit_type === false ? (
                        <></>
                      ) : (
                        /*   <div className="row">

                    <div className="col-4"></div> */
                        <div
                          className="col-12 col-md-4 col-xl-4"
                          style={{ "z-index": "101", alignItems: "right" }}
                        >
                          {feature.map((e) => {
                            if (e.feature === "FG IN/OUT EXP") {
                              return (
                                <INDF
                                  selected={calender_data.exp}
                                  txt="EXP Date"
                                  onChange={(date) =>
                                    SetDate_and_GetDayoff_start(date)
                                  }
                                  customInput={<CustomInput />}
                                />
                              );
                            }
                          })}
                        </div>
                        /*    <div className="col-4">

                    </div>
                  </div> */
                      )}
                      <div className="col-12 col-md-4 col-xl-4">
                        {feature.map((e) => {
                          if (e.feature === "FG IN/OUT Lot No") {
                            return (
                              <INF
                                value={amount.lot_no}
                                disabled={true}
                                label={"Lot No"}
                                onChange={(e) => {
                                  setamount({
                                    ...amount,
                                    lot_no: e.target.value,
                                  });
                                }}
                              />
                            );
                          }
                        })}
                      </div>

                      {feature.map((e) => {
                        if (e.feature === "status_sst") {
                          return (
                            <div className="col-12 col-md-4 col-xl-4">
                              <div class="form-group">
                                <select
                                  className="custom-select select2 form-control"
                                  value={amount.status_sst}
                                  onChange={(e) => {
                                    setamount({
                                      ...amount,
                                      status_sst: e.target.value,
                                    });
                                    search_cus_end.result_end = "";
                                    search_cus_end.result_cus = "";
                                  }}
                                >
                                  <option value="Warehouse_STP">
                                    Warehouse STP
                                  </option>
                                  <option value="customer">Customer</option>
                                  <option value="end_user">End User</option>
                                </select>

                                <label htmlFor="">สถานะ :</label>
                              </div>
                            </div>
                          );
                        }
                      })}
                      <div className="col-4"></div>

                      {amount.status_sst === "customer" ? (
                        <>
                          <div
                            className="col-12 col-md-4 col-xl-4"
                            style={{ "z-index": "99" }}
                          >
                            <div className="form-group ">
                              <Select
                                placeholder=""
                                styles={colourStyles}
                                options={customer_option}
                                defaultValue={amount.customer}
                                onChange={(e) => {
                                  // console.log(e);

                                  setamount({
                                    ...amount,
                                    customer: e.value1,
                                  });
                                  amount.customer = e.value1;

                                  setsearch_cus_end({
                                    ...search_cus_end,
                                    result_cus: e.value2,
                                  });
                                  search_cus_end.result_cus = e.value2;
                                }}
                              />
                              <label htmlFor="">No. Customer </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-4 col-xl-4">
                            <div class="form-group">
                              <INF
                                value={search_cus_end.result_cus}
                                disabled={true}
                                label={"Result Customer"} /* onChange={(e) => {
                                 setamount({ ...amount, customer: e.target.value })
                              }} */
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {amount.status_sst === "end_user" ? (
                            <>
                              <div
                                className="col-12 col-md-4 col-xl-4"
                                style={{ "z-index": "99" }}
                              >
                                <div className="form-group ">
                                  <Select
                                    placeholder=""
                                    styles={colourStyles}
                                    options={End_user_option}
                                    defaultValue={amount.customend_userer}
                                    onChange={(e) => {
                                      // console.log(e);

                                      setamount({
                                        ...amount,
                                        end_user: e.value1,
                                      });
                                      amount.end_user = e.value1;

                                      setsearch_cus_end({
                                        ...search_cus_end,
                                        result_end: e.value2,
                                      });
                                      search_cus_end.result_end = e.value2;
                                    }}
                                  />
                                  <label htmlFor="">No. ENd User</label>
                                </div>
                              </div>
                              <div className="col-12 col-md-4 col-xl-4">
                                <div class="form-group">
                                  <INF
                                    value={search_cus_end.result_end}
                                    disabled={true}
                                    label={
                                      "Result End user"
                                    } /* onChange={(e) => {
                                 setamount({ ...amount, customer: e.target.value })
                              }} */
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}

                <div className="row">
                  <div className="col-12 col-md-4 col-xl-4">
                    {/* {amount.edit_type === false ? (
                      <>
                        {feature.map((e) => {
                          if (e.feature === "FG IN/OUT Remark (OUT)") {
                            return (
                              <INF value={amount.remark} label={"Remark"} onChange={(e) => {
                                setamount({
                                  ...amount,
                                  remark: e.target.value,
                                });
                              }} />
                            );
                          }
                        })

                        }</>) : (
                      <>
                      </>
                    )} */}
                  </div>

                  <div className="col-12 col-md-4 col-xl-4">
                    <div className="form-group ">
                      {feature.filter((e) => {
                        return e.feature === "AmountDecimalScale";
                      }).length > 0 ? (
                        <>
                          <NumberFormat
                            thousandSeparator={true}
                            allowNegative={false}
                            id="amount_in"
                            type="text"
                            className="form-control"
                            decimalScale={2}
                            value={amount.amount}
                            onChange={(e) => {
                              setamount({
                                ...amount,
                                amount: e.target.value,
                              });
                            }}
                            autofocus
                            required
                          />
                        </>
                      ) : (
                        <>
                          <NumberFormat
                            thousandSeparator={true}
                            allowNegative={false}
                            id="amount_in"
                            type="text"
                            className="form-control"
                            decimalScale={0}
                            value={amount.amount}
                            onChange={(e) => {
                              setamount({
                                ...amount,
                                amount: e.target.value,
                              });
                            }}
                            autofocus
                            required
                          />
                        </>
                      )}
                      <label htmlFor="">Amount</label>{" "}
                    </div>
                  </div>

                  {oemfix === "thanasub" && amount.edit_type === false ? (
                    <>
                      <div className="col-12 col-md-4 col-xl-4">
                        <div className="form-group ">
                          <NumberFormat
                            thousandSeparator={true}
                            allowNegative={false}
                            id="amount_in"
                            type="text"
                            className="form-control"
                            decimalScale={2}
                            value={amount.sell_price}
                            onChange={(e) => {
                              setamount({
                                ...amount,
                                sell_price: e.target.value,
                              });
                            }}
                            autofocus
                            required
                          />
                          <label htmlFor="">ราคาขาย/ชิ้น</label>{" "}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-12 col-md-4 col-xl-4"></div>
                    </>
                  )}

                  <div className="col-12 col-md-4 col-xl-4"></div>

                  <div className="col-12 col-md-4 col-xl-4">
                    <div className="form-group ">
                      <input
                        type="text"
                        className="form-control"
                        value={amount.remark}
                        onChange={(e) => {
                          setamount({ ...amount, remark: e.target.value });
                        }}
                      />
                      <label htmlFor="">Remark</label>{" "}
                    </div>
                  </div>

                  {amount.edit_type === false ? (
                    <>
                      {feature.map((e) => {
                        if (e.feature === "FG IN/OUT Color (OUT)") {
                          return (
                            <div
                              className="col-12 col-md-4 col-xl-4"
                              style={{ "z-index": "98" }}
                            >
                              <div className="form-group ">
                                <Select
                                  options={color_option}
                                  defaultValue={amount.color}
                                  ref={selectColor_model}
                                  onChange={(e) => {
                                    //console.log(e);
                                    if (e !== null) {
                                      setamount({
                                        ...amount,
                                        color: e.value,
                                      });
                                    }
                                  }}
                                />
                                <label htmlFor="">Color</label>
                              </div>
                            </div>
                            /*  <div className="col-4">
                             <div className="form-group">
                             <select
                               className="custom-select"
                               value={amount.color}
                               //disabled={mode.mode === "read" ? true : false}
                               onChange={(e) =>
                                 setamount({
                                   ...amount,
                                   color: e.target.value,
                                 })
                               }
                             >
                               <option value="" selected disabled>
                                 select Color
                               </option>
                               {color.map((el) => {
                                 return (
                                   <option key={el.id} value={el.name}>
                                     {el.name}
                                   </option>
                                 );
                               })}
                             </select>
                             <label htmlFor="">Color</label>
                           </div>
                           </div> */
                          );
                        }
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </div>

                {amount.edit_type === true ? (
                  <>
                    {feature.map((e) => {
                      if (e.feature === "FG IN Warehouse") {
                        return (
                          <>
                            <div className="row">
                              <div
                                className="col-3"
                                style={{ "z-index": "99" }}
                              >
                                <div className="form-group ">
                                  <Select
                                    options={loop_option}
                                    defaultValue={warehouse_data.loop_id}
                                    ref={selectLoop_model}
                                    onChange={(e) => {
                                      //console.log(e);
                                      if (e !== null) {
                                        setwarehouse_data({
                                          ...warehouse_data,
                                          loop_id: e.value,
                                        });
                                      }
                                    }}
                                  />
                                  <label htmlFor="">Loop</label>
                                </div>
                              </div>
                              <div
                                className="col-3"
                                style={{ "z-index": "99" }}
                              >
                                <div className="form-group ">
                                  <Select
                                    options={warehouse_option}
                                    defaultValue={warehouse_data.warehouse_id}
                                    ref={selectWarehouse_model}
                                    onChange={(e) => {
                                      //console.log(e);
                                      if (e !== null) {
                                        setwarehouse_data({
                                          ...warehouse_data,
                                          warehouse_id: e.value,
                                        });
                                        GetRack(e.value);
                                      }
                                    }}
                                  />
                                  <label htmlFor="">Warehouse</label>
                                </div>
                              </div>

                              <div
                                className="col-3"
                                style={{ "z-index": "99" }}
                              >
                                <div className="form-group ">
                                  <Select
                                    options={rack_option}
                                    defaultValue={warehouse_data.rack_id}
                                    ref={selectRack_model}
                                    onChange={(e) => {
                                      //console.log(e);
                                      if (e !== null) {
                                        setwarehouse_data({
                                          ...warehouse_data,
                                          rack_id: e.value,
                                        });
                                        GetProduct_on_rack(e.value);
                                      }
                                    }}
                                  />
                                  <label htmlFor="">Rack</label>
                                </div>
                              </div>

                              <div
                                className="col-3"
                                style={{ "z-index": "99" }}
                              >
                                <div className="form-group ">
                                  <Select
                                    options={product_on_rack_option}
                                    defaultValue={
                                      warehouse_data.product_on_rack_id
                                    }
                                    ref={selectProduct_on_rack_model}
                                    onChange={(e) => {
                                      //console.log(e);
                                      if (e !== null) {
                                        setwarehouse_data({
                                          ...warehouse_data,
                                          product_on_rack_id: e.value,
                                        });
                                      }
                                    }}
                                  />
                                  <label htmlFor="">Product On Rack</label>
                                </div>
                              </div>

                              <div
                                className="col-3"
                                style={{ "z-index": "98" }}
                              >
                                <div className="form-group ">
                                  <Select
                                    options={load_status_option}
                                    defaultValue={warehouse_data.load_status_id}
                                    ref={selectLoad_status_model}
                                    onChange={(e) => {
                                      //console.log(e);
                                      if (e !== null) {
                                        setwarehouse_data({
                                          ...warehouse_data,
                                          load_status_id: e.value,
                                        });
                                      }
                                    }}
                                  />
                                  <label htmlFor="">Load Status</label>
                                </div>
                              </div>
                              {/*  <div className="col-3">
                   <div className="form-group">
                        <select
                          className="custom-select"
                          value={warehouse_data.loop_id}
                          //disabled={mode.mode === "read" ? true : false}
                          onChange={(e) =>
                            setwarehouse_data({
                              ...warehouse_data,
                              loop_id: e.target.value,
                            })
                          }
                        >
                          <option value="" selected disabled>
                            select loop
                          </option>
                          {loop.map((el) => {
                            return (
                              <option key={el.id} value={el.id}>
                                {el.loop_no}
                              </option>
                            );
                          })}
                        </select>
                        <label htmlFor="">Loop</label>
                      </div>
                   </div> 
                   <div className="col-3">
                   <div className="form-group">
                        <select
                          className="custom-select"
                          value={warehouse_data.warehouse_id}
                          //disabled={mode.mode === "read" ? true : false}
                         
                          onChange={(e) =>{
                            setwarehouse_data({
                              ...warehouse_data,
                              warehouse_id: e.target.value,
                            });

                            GetRack(e.target.value);
                          }}
                        >
                          <option value="" selected disabled>
                            select warehouse
                          </option>
                          {warehouse.map((el) => {
                            return (
                              <option key={el.id} value={el.id}>
                                {el.code_no}
                              </option>
                            );
                          })}
                        </select>
                        <label htmlFor="">Warehouse</label>
                      </div>
                   </div>
                   <div className="col-3">
                   <div className="form-group">
                        <select
                          className="custom-select"
                          value={warehouse_data.rack_id}
                          //disabled={mode.mode === "read" ? true : false}
                          onChange={(e) =>{
                            setwarehouse_data({
                              ...warehouse_data,
                              rack_id: e.target.value,
                            })
                            GetProduct_on_rack(e.target.value);
                          }}
                        >
                          <option value="" selected>
                            select rack
                          </option>
                          {rack.map((el) => {
                            return (
                              <option key={el.id} value={el.id}>
                                {el.code_no}
                              </option>
                            );
                          })}
                        </select>
                        <label htmlFor="">Rack</label>
                      </div>
                   </div>
                   <div className="col-3">
                   <div className="form-group">
                        <select
                          className="custom-select"
                          value={warehouse_data.product_on_rack_id}
                          //disabled={mode.mode === "read" ? true : false}
                          onChange={(e) =>{
                            setwarehouse_data({
                              ...warehouse_data,
                              product_on_rack_id: e.target.value,
                            })
                 
                          }}
                        >
                          <option value="" selected>
                            select product on rack
                          </option>
                          {product_on_rack.map((el) => {
                            return (
                              <option key={el.id} value={el.id}>
                                {el.code_no}
                              </option>
                            );
                          })}
                        </select>
                        <label htmlFor="">Product on rack</label>
                      </div>
                   </div>
                
                   <div className="col-3">
                   <div className="form-group">
                        <select
                          className="custom-select"
                          value={warehouse_data.load_status_id}
                          //disabled={mode.mode === "read" ? true : false}
                          onChange={(e) =>
                            setwarehouse_data({
                              ...warehouse_data,
                              load_status_id: e.target.value,
                            })
                          }
                        >
                          <option value="" selected disabled>
                            select load status
                          </option>
                          {load_status.map((el) => {
                            return (
                              <option key={el.id} value={el.id}>
                                {el.status}
                              </option>
                            );
                          })}
                        </select>
                        <label htmlFor="">Load Status</label>
                      </div>
                   </div>*/}
                              {load_status.map((e) => {
                                if (
                                  e.status == "Fail" &&
                                  e.id === warehouse_data.load_status_id
                                ) {
                                  return (
                                    <>
                                      <div className="col-3">
                                        <INF
                                          value={warehouse_data.remark}
                                          label={"Remark"}
                                          onChange={(e) => {
                                            setwarehouse_data({
                                              ...warehouse_data,
                                              remark: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                    </>
                                  );
                                }
                              })}

                              <div className="col-3">
                                <INF
                                  value={warehouse_data.description}
                                  label={"Description"}
                                  onChange={(e) => {
                                    setwarehouse_data({
                                      ...warehouse_data,
                                      description: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        );
                      }
                    })}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="modal-footer justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  id="btn_save"
                  onClick={Getamount}
                  disabled={block_btn}
                  //data-dismiss="modal"
                  //aria-label="Close"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modal-wip_info">
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-label">WIP Amount</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <h2>{numberWithCommas(wip_info.wip_amount)}</h2>
            </div>
            <div className="modal-footer " align="right">
              <div className="row">
                {/*      <div className="col-12" align="right"> */}
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>
                {/*    </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive" style={{ display: "none" }}>
        <table
          id="stock-table-to-xls"
          className="table table-head-fixed"
          border="1"
        >
          <thead>
            <tr>
              {/* <th>No</th> */}
              <th>Product No</th>
              <th>Product Name</th>
              {/* <th>Model Category</th> */}
              <th>Amount</th>
              {/* <th>Unit</th> */}
              {/* <th>Project</th>
                                                                    <th>Date</th> */}
            </tr>
          </thead>
          <tbody>
            {product_data.map((el) => {
              let ammount = "";
              if (el.fg_amount < 1) {
              } else {
                return (
                  <>
                    <tr>
                      {/* <td>{el.no}</td> */}
                      <td>{el.no}</td>
                      <td>{el.name}</td>
                      <td>{el.fg_amount}</td>
                      {/* <td>{el.}</td>
                                                                                <td>{el.}</td> */}
                    </tr>
                  </>
                );
              }
            })}
          </tbody>
        </table>
      </div>

      {/*  <Footter /> */}
    </div>
  );
}
export default FGInOut;
