import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import "../../assets/css/Supp.css";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import "../../assets/css/table.css";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import { useParams } from "react-router";
import Sidebar from "../../component/Sidebar";
import { getOem, getToken, getUser,getAcademy } from "../../Utils/Common";
import Configs from "../../config";
import FormData from "form-data";
import axios from "axios";
import NumberFormat from "react-number-format";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";

function Project_add(mode) {
  const [company, setCompany] = useState([]);
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);
  const [project, setproject] = useState({
    company_id: getUser().com,
    project_id: "",
    project_name: "",
    contact_name: "",
    contact_tel: "",
    contact_email: "",
    detail: "",
    is_use:"",
  });
  const { id } = useParams();
  useEffect(() => {
    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);

  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //console.log(response.data);
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  
  useEffect(() => {
    GetCompany();
    //console.log("id:"+id);
    GetProject_view();
  }, []);


  
  

  const GetProject_view = async () => {
     
    if ( mode.mode =="read" || mode.mode =="edit"  ) {
      axios({
        method: "get",
        url: Configs.API_URL + "/project/findById?id="+ id,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          //console.log(response.data);
          setproject({
            company_id: response.data.company_id,
            project_id: response.data.id,
            project_name: response.data.project_name || "",
            contact_name: response.data.contact_name || "",
            contact_tel: response.data.contect_tel || "",
            contact_email: response.data.contact_email || "",
            detail: response.data.detail || "",
            is_use:response.data.is_use,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    
  }
  
  
  function saveOption(params) {
    const error_list = [];
    if(project.project_name.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Project Name]."
      }
      error_list.push(temp_err);
    }
    if(project.detail == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Project Detail]."
      }
      error_list.push(temp_err);
    }
    if(project.contact_name == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Contact Name]."
      }
      error_list.push(temp_err);
    }
    if(project.contact_tel == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Contact Tel]."
      }
      error_list.push(temp_err);
    }
    if(project.contact_email == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Contact Email]."
      }
      error_list.push(temp_err);
    }
    if(error_list.length > 0){
      var err_message = "";
      for(var e = 0; e < error_list.length;e++){
        err_message += "<br/>"+error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    }else{
      if (mode.mode === "add") {
        const temp ={
        
          company_id: project.company_id,
          oem_id:getOem(),
          project_name: project.project_name.trim(),
          contact_name: project.contact_name.trim(),
          contact_tel: project.contact_tel,
          contact_email: project.contact_email.trim(),
          detail: project.detail.trim(),
        }
        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/project/add",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Factory_Master_Data/Project";
                });
              }
  
              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
      }
      if (mode.mode === "edit") {

        const temp ={
          project_id:id,
          company_id: project.company_id,
          oem_id:getOem(),
          project_name: project.project_name.trim(),
          contact_name: project.contact_name.trim(),
          contact_tel: project.contact_tel,
          contact_email: project.contact_email.trim(),
          detail: project.detail.trim(),
    
        }
        //console.log(temp);
       
        Swal.fire( {
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/project/update",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Factory_Master_Data/Project";
                });
              }
    
              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
       }
    }
  
  }

  

  function cancleOption(params) {
    Swal.fire({
      title: 'Do you want to cancel and leave the changes?',
      icon:'warning',
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:`No`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href =
        "/Warehouse_Management/Factory_Master_Data/Project";
      }
    }
    );
  }
  return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Project {pageMode}{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Project</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row mb-2">
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                     <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    if
                      onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Project {pageMode}</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body">
            <h3 className="mt-5-head">
                  Project Detail
                  </h3>
                <div className="tab-custom-content">
                 </div>
                 <br/>
              <div className="row">
              
                     

                  
              <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={project.project_name}
                      disabled={disable}
                      onChange={(e) => {
                        setproject({
                          ...project,
                          project_name: e.target.value,
                        });
                      }}
                
                    />
                    <label>
                      Project Name
                      {project.project_name.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>

               
              </div>
              <div className="row">
              <div className="col-12 col-md-10 col-xl-8">
                  <div class="form-group">
                    <textarea
                      type="text"
                      className="form-control"
                      required
                      style={{ height: "100px", resize: "none" }}
                      value={project.detail}
                      disabled={disable}
                      onChange={(e) => {
                        setproject({
                          ...project,
                          detail: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Project Detail
                      {project.detail === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>

       

             
              <h3 className="mt-5 mt-5-head">
                    Contact Point
                  </h3>
                <div className="tab-custom-content">
                 </div>
                 <br/>
              <div className="row">
              <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                  <input
                      type="text"
                      className="form-control"
                      required
                      value={project.contact_name}
                      disabled={disable}
                      onChange={(e) => {
                        setproject({
                          ...project,
                          contact_name: e.target.value,
                        });
                      }}
                    />
                    <label>
                    Contact Name
                    {project.contact_name.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                
                    <NumberFormat
                      className="form-control"
                      required
                      thousandSeparator={false}
                      format={"###-###-################"}
                      value={project.contact_tel}
                      disabled={disable}
                      onChange={(e) => {
                        setproject({
                          ...project,
                          contact_tel: e.target.value,
                        });
                      }}
                    />
                    <label>
                    Contact Tel
                    {project.contact_tel === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
              <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                  <input
                      type="text"
                      className="form-control"
                      required
                      value={project.contact_email}
                      disabled={disable}
                      onChange={(e) => {
                        setproject({
                          ...project,
                          contact_email: e.target.value,
                        });
                      }}
                    />
                    <label>
                    Contact Email
                    {project.contact_email.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
             
                
              </div>
            </div>
            
          </div>
        </section>
      </div>
     {/*  <Footter/> */}
      </div>
  );
}
export default Project_add;
