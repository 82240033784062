import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import "../../assets/css/Supp.css";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import "../../assets/css/table.css";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import { useParams } from "react-router";
import Sidebar from "../../component/Sidebar";
import { getOem, getToken, getUser,getAcademy } from "../../Utils/Common";
import Configs from "../../config";
import FormData from "form-data";
import axios from "axios";
import NumberFormat from "react-number-format";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";

function Tooling_supplier_add(mode) {
  const [company, setCompany] = useState([]);
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);
  const [supplier, setsupplier] = useState({
    company_id: getUser().com,
    name: "",
    is_use: "",
    sort: "",
    detail: "",
    contact_tel: "",
    contact_fax: "",
    contact_name: "",
    supplier_email: "",
    line_id: "",
    supplier_email_person: "",
    remark: "",
    full_name: "",
  });
  const { id } = useParams();
  useEffect(() => {
    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);

  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //console.log(response.data);
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GetCompany();
    //console.log("id:"+id);
    GetSupp_view();
  }, []);

  const GetSupp_view = async () => {
    if (mode.mode == "read" || mode.mode == "edit") {
      axios({
        method: "get",
        url: Configs.API_URL + "/tooling/tooling_supplier_findById?id=" + id,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          //console.log(response.data);
          setsupplier({
            company_id: response.data.company_id,
            name: response.data.name || "",
            is_use: response.data.is_use,
            sort: response.data.sort,
            detail: response.data.detail || "",
            contact_tel: response.data.contact_tel,
            contact_fax: response.data.contact_fax || "",
            supplier_email: response.data.supplier_email || "",
            supplier_email_person: response.data.supplier_email_person || "",
            remark: response.data.remark || "",
            contact_name: response.data.contact_name || "",
            full_name: response.data.full_name || "",
            line_id: response.data.line_id || "",
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  function saveOption(params) {
    const error_list = [];
    if (supplier.name.trim() == "") {
      let temp_err = {
        message:
          "Please enter information in the fields >>> [Supplier Name].",
      };
      error_list.push(temp_err);
    }
    /*  if(supplier.full_name.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Supplier Name]."
      }
      error_list.push(temp_err);
    } */
    /*   if(supplier.detail.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Supplier Detail]."
      }
      error_list.push(temp_err);
    }
    if(supplier.supplier_email.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Supplier Email]."
      }
      error_list.push(temp_err);
    }
    if(supplier.line_id.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Line ID]."
      }
      error_list.push(temp_err);
    }
    if(supplier.contact_fax.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Contact Fax]."
      }
      error_list.push(temp_err);
    }
    if(supplier.contact_name.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Contact Name]."
      }
      error_list.push(temp_err);
    }
    if(supplier.supplier_email_person.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Supplier Email Person]."
      }
      error_list.push(temp_err);
    }
    if(supplier.contact_tel == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Contact Tel]."
      }
      error_list.push(temp_err);
    } */
    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    } else {
      if (mode.mode === "add") {
        const temp = {
          company_id: supplier.company_id,
          name: supplier.name.trim(),
          is_use: supplier.is_use,

          detail: supplier.detail.trim(),
          contact_tel: supplier.contact_tel,
          contact_fax: supplier.contact_fax.trim(),
          contact_name: supplier.contact_name.trim(),
          supplier_email: supplier.supplier_email.trim(),
          supplier_email_person: supplier.supplier_email_person.trim(),
          remark: supplier.remark,
          full_name: supplier.full_name.trim(),
          line_id: supplier.line_id,
        };

        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/tooling/tooling_supplier_add",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: supplier,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Factory_Master_Data/tooling_supplier";
                });
              }

              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
      } else if (mode.mode === "edit") {
        const temp = {
          id: id,
          company_id: supplier.company_id,
          name: supplier.name.trim(),
          is_use: supplier.is_use,

          detail: supplier.detail.trim(),
          contact_tel: supplier.contact_tel,
          contact_fax: supplier.contact_fax.trim(),
          contact_name: supplier.contact_name.trim(),
          supplier_email: supplier.supplier_email.trim(),
          supplier_email_person: supplier.supplier_email_person.trim(),
          remark: supplier.remark,
          full_name: supplier.full_name.trim(),
          line_id: supplier.line_id,
        };
        //console.log(temp);

        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/tooling/tooling_supplier_update",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Factory_Master_Data/tooling_supplier";
                });
              }

              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
      }
    }
  }

  function cancleOption(params) {
    Swal.fire({
      title: 'Do you want to cancel and leave the changes?',
      icon:'warning',
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:`No`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href =
        "/Warehouse_Management/Factory_Master_Data/tooling_supplier";
      }
    }
    );
  }
  return (
    <div className="wrapper">
      {/*  <Header />
    <Sidebar menu="warehouse" activemenu="suppmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Supplier {pageMode}{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Supplier</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row mb-2">
           <div className="col-4 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
          <div className="col-4 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-4 col-md-3 col-xl-2">
                  <button
                    type="button"
                    if
                    onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Supplier {pageMode}</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body">
              <h3 className="mt-5-head">Supplier Detail</h3>
              <div className="tab-custom-content"></div>
              <br />
              <div className="row">
                {/* <div className="col-4">
                  <div className="form-group ">
                    <select
                      className="custom-select select2 form-control"
                      value={supplier.company_id}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          company_id: e.target.value,
                        });
                      }}
                    >
                      <option value="" disabled selected>
                        Select Company
                      </option>
                      {company.map((el, index) => {
                        return (
                          <option key={index} value={el.id}>
                            {el.name}
                          </option>
                        );
                      })}
                    </select>
                    <label htmlFor="">Company</label>
                  </div>
                </div> */}

        <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={supplier.name}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          name: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Supplier Name
                      {supplier.name.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={supplier.full_name}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          full_name: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Supplier Full Name
                      {/* {supplier.full_name.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
              <div className="col-12 col-md-10 col-xl-8">
                  <div class="form-group">
                    <textarea
                      type="text"
                      className="form-control"
                      required
                      style={{ height: "100px", resize: "none" }}
                      value={supplier.detail}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          detail: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Supplier Detail
                      {/* {supplier.detail.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
              <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={supplier.supplier_email}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          supplier_email: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Supplier Email
                      {/*  {supplier.supplier_email.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={supplier.line_id}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          line_id: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Line ID
                      {/*   {supplier.line_id.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                  <NumberFormat
                      className="form-control"
                      required
                      thousandSeparator={false}
                      format={"###-###-################"}
                      value={supplier.contact_fax}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          contact_fax: e.target.value,
                        });
                      }}
                    />
                   {/*  <input
                      type="text"
                      className="form-control"
                      required
                      value={supplier.contact_fax}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          contact_fax: e.target.value,
                        });
                      }}
                    /> */}
                    <label>
                      Contact Fax
               
                    </label>
                  </div>
                </div>
              </div>

              <h3 className="mt-5 mt-5-head">Contact Point</h3>
              <div className="tab-custom-content"></div>
              <br />
              <div className="row">
              <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={supplier.contact_name}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          contact_name: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Contact Name
                      {/* {supplier.contact_name.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={supplier.supplier_email_person}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          supplier_email_person: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Supplier Person Email
                      {/* {supplier.supplier_email_person.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
              <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <NumberFormat
                      className="form-control"
                      required
                      thousandSeparator={false}
                      format={"###-###-################"}
                      value={supplier.contact_tel}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          contact_tel: e.target.value,
                        });
                      }}
                    />

                    <label>
                      Contact Tel
                      {/* {supplier.contact_tel === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={supplier.remark}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          remark: e.target.value,
                        });
                      }}
                    />
                    <label>Remark</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/*      <Footter/> */}
    </div>
  );
}
export default Tooling_supplier_add;
