import React, { Component, useEffect, useState, useRef } from "react";
import axios from "axios";
import Configs from "../../../../config";
import { useLocation, useParams } from "react-router";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import Swal from "sweetalert2";
import DatePicker, { registerLocale } from "react-datepicker";
import NumberFormat from "react-number-format";
import { pay_product_detail_buyer } from "./pay_product_detail_buyer";
import {
  getOem,
  getToken,
  getUser,
  getAcademy,
  getComConfig,
} from "../../../../Utils/Common";

import "../../../../assets/css/ProductService.css";

import Select from "react-select";
import PointCartLine from "../Incentive_User_component/Point_Cart_Line";

import customerImage from "../../../../assets/img/product_incentive/customer.png";
import "../../../../assets/css/product_incentive.css";
import { useHistory } from "react-router-dom";

function Cart_Product_Sum() {
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const dataReceived = location.state;
  const handleClick = () => {
    const dataToSend = {
      product: productSelect.product,
    };

    history.push({
      pathname: "/Human_Resource/incentive_user/product_service/pay_details",
      state: dataToSend,
    });
  };

  const [product_emp, setProduct_emp] = useState({
    product: [],
  });

  const [checkAll, setCheckAll] = useState({
    status: false,
  });

  const [checkAllSelect, setCheckAllSelect] = useState({
    status: false,
  });

  const [productSelect, setProductSelect] = useState({
    product: [],
  });

  const [totalSumAndCount, setTotalSumAndCount] = useState({
    totalSum: 0,
    count: 0,
  });

  useEffect(async () => {
    await getProductInCart();
  }, []);

  const callbackProduct = async (id) => {
    console.log("dataReceived", dataReceived);
    const arr = product_emp.product;
    let arr2 = productSelect.product;
    let count = totalSumAndCount.count;
    let total = totalSumAndCount.totalSum;
    let totalCal = 0;
    let countCal = 0;
    setProduct_emp({ product: [] });
    setProductSelect({ product: [] });
    if (id) {
      arr.find((el) => {
        // const temp = dataReceived.product.find(item => item.sc_id === el.sc_id);
        // if (temp) {
        const idx = arr.findIndex((ar) => ar.sc_id === id);
        if (idx >= 0) {
          el.p_checkbox = true;
          arr[idx].ps_checkbox = true;
          // arr[idx].p_amount = temp.p_amount;
          arr2.push(arr[idx]);
          totalCal +=
            total +
            (arr[idx].p_point ? parseInt(arr[idx].p_point) : 0) *
              (arr[idx].p_point ? parseInt(arr[idx].p_amount) : 0);
          countCal += count + 1;
          return el;
        }
        return el;

        /*}  else {
                } */
      });
    } else if (dataReceived) {
      arr.map((el) => {
        const temp = dataReceived.product.find(
          (item) => item.sc_id === el.sc_id
        );
        if (temp) {
          const idx = arr.findIndex((ar) => ar.sc_id === temp.sc_id);
          el.p_checkbox = true;
          arr[idx].ps_checkbox = true;
          arr[idx].p_amount = temp.p_amount;
          arr2.push(arr[idx]);
          totalCal +=
            total +
            (arr[idx].p_point ? parseInt(arr[idx].p_point) : 0) *
              (arr[idx].p_point ? parseInt(arr[idx].p_amount) : 0);
          countCal += count + 1;
          return el;
        } else {
          return el;
        }
      });
    }
    setProduct_emp({ product: arr });
    product_emp.product = arr;

    setProductSelect({ product: arr2 });
    productSelect.product = arr2;

    setTotalSumAndCount({
      totalSum: totalCal,
      count: countCal,
    });
    totalSumAndCount.totalSum = totalCal;
    totalSumAndCount.count = countCal;
    await checkSelectAll();
  };

  const checkSelectAll = async () => {
    const arr = product_emp.product;
    const arr2 = productSelect.product;

    const temp1 = arr.filter((el) => el.p_checkbox === true);
    const temp2 = arr2.filter((el) => el.ps_checkbox === true);

    if (temp1.length === arr.length) {
      setCheckAll({
        status: true,
      });
      checkAll.status = true;
    } else {
      setCheckAll({
        status: false,
      });
      checkAll.status = false;
    }
    if (temp2.length === arr2.length && arr2.length !== 0) {
      setCheckAllSelect({
        status: true,
      });
      checkAllSelect.status = true;
    } else {
      setCheckAllSelect({
        status: false,
      });
      checkAllSelect.status = false;
    }
  };

  const cancleSelectProduct = () => {
    let arr2 = [],
      arr = [];
    const tempList = productSelect.product.find(
      (el) => el.ps_checkbox === true
    );
    if (tempList) {
      let sumTotal = 0;
      arr2 = productSelect.product.filter((el) => {
        if (el.ps_checkbox == false) {
          sumTotal +=
            (el.p_point ? parseInt(el.p_point) : 0) *
            (el.p_point ? parseInt(el.p_amount) : 0);
          return el;
        } else if (el.ps_checkbox == true) {
          el.p_checkbox = false;
          // arr.push(el);
        }
      });
      console.log("arr", arr);

      setProductSelect({ product: [] });
      setProductSelect({ product: arr2 });
      productSelect.product = arr2;
      if (arr2.length > 0) {
        setTotalSumAndCount({
          totalSum: sumTotal,
          count: arr2.length,
        });
        totalSumAndCount.totalSum = sumTotal;
        totalSumAndCount.count = arr2.length;
      }

      if (arr2.length === 0) {
        setTotalSumAndCount({
          totalSum: 0,
          count: 0,
        });
        totalSumAndCount.totalSum = 0;
        totalSumAndCount.count = 0;
      }
      checkSelectAll();
    }
  };

  const handleCheckAllSelectChange = (e) => {
    const arr2 = [];
    const statusTF = e.target.checked;
    for (let item of productSelect.product) {
      item.ps_checkbox = statusTF;
      arr2.push(item);
    }
    setProductSelect({ product: [] });
    setProductSelect({ product: arr2 });
    productSelect.product = arr2;

    if (!statusTF) {
      setTotalSumAndCount({
        totalSum: 0,
        count: 0,
      });
      totalSumAndCount.totalSum = 0;
      totalSumAndCount.count = 0;
    } else {
      updateCountAndTotal();
    }

    setCheckAllSelect({ status: statusTF });
    checkAllSelect.status = statusTF;
  };

  const handleCheckChange = (e, i) => {
    const arr = product_emp.product;
    let arr2 = productSelect.product;
    let count = totalSumAndCount.count;
    let total = totalSumAndCount.totalSum;
    setProduct_emp({ product: [] });
    setProductSelect({ product: [] });
    arr[e].p_checkbox = i.target.checked;
    setProduct_emp({ product: arr });
    product_emp.product = arr;
    arr[e].ps_checkbox = false;
    if (i.target.checked === true) {
      arr2.push(arr[e]);
      setProductSelect({ product: arr2 });
      productSelect.product = arr2;
      /* setTotalSumAndCount({
                totalSum: total + ((arr[e].p_point ? parseInt(arr[e].p_point) : 0) * (arr[e].p_point ? parseInt(arr[e].p_amount) : 0)),
                count: count + 1,
            });
            totalSumAndCount.totalSum = total + ((arr[e].p_point ? parseInt(arr[e].p_point) : 0) * (arr[e].p_point ? parseInt(arr[e].p_amount) : 0));
            totalSumAndCount.count = count + 1; */
    } else {
      const temp = arr2;
      arr2 = temp.filter((el) => el.sc_id !== arr[e].sc_id);
      setProductSelect({ product: arr2 });
      productSelect.product = arr2;
      /* setTotalSumAndCount({
                totalSum: total - ((arr[e].p_point ? parseInt(arr[e].p_point) : 0) * (arr[e].p_point ? parseInt(arr[e].p_amount) : 0)),
                count: count - 1,
            });
            totalSumAndCount.totalSum = total - ((arr[e].p_point ? parseInt(arr[e].p_point) : 0) * (arr[e].p_point ? parseInt(arr[e].p_amount) : 0));
            totalSumAndCount.count = count - 1; */
    }
    console.log("arr2.length", arr2.length, product_emp.product.length);
    checkSelectAll();
    updateCountAndTotal();
    /* if (arr2.length === product_emp.product.length) {
            console.log('true');
            setCheckAll({
                status: true,
            });
            checkAll.status = true;
        } else {
            setCheckAll({
                status: false,
            });
            checkAll.status = false;
        } */
  };

  const handleCheckAllChange = (e) => {
    const arr = [];
    const arr2 = [];
    console.log("arr", arr);
    console.log("!checkAll.status", e.target.checked);
    const statusTF = e.target.checked;
    let sumTotal = 0;
    for (let item of product_emp.product) {
      console.log("item", item);
      item.p_checkbox = statusTF;
      let point = item.p_point ? parseInt(item.p_point) : 0;
      let amount = item.p_amount ? parseInt(item.p_amount) : 0;
      sumTotal += point * amount;
      arr.push(item);

      // item.ps_checkbox = false;
      arr2.push(item);
    }

    const checkSelectAll = arr2.filter((el) => el.ps_checkbox === true);
    if (arr2.length === checkSelectAll.length) {
      setCheckAllSelect({ status: true });
      checkAllSelect.status = true;
    } else {
      setCheckAllSelect({ status: false });
      checkAllSelect.status = false;
    }
    setProduct_emp({ product: [] });
    setProduct_emp({ product: arr });
    product_emp.product = arr;

    if (statusTF == true) {
      setProductSelect({ product: arr2 });
      productSelect.product = arr2;
    } else if (statusTF == false) {
      setProductSelect({ product: [] });
      productSelect.product = [];
    }
    // countTotalSum(statusTF, sumTotal);
    setCheckAll({ status: statusTF });
    checkAll.status = statusTF;
  };

  const countTotalSum = (status, pointTotal) => {
    if (status === true) {
      setTotalSumAndCount({
        totalSum: pointTotal,
        count: product_emp.product.length,
      });
      totalSumAndCount.totalSum = pointTotal;
      totalSumAndCount.count = product_emp.product.length;
    } else {
      setTotalSumAndCount({
        totalSum: 0,
        count: 0,
      });
      totalSumAndCount.totalSum = 0;
      totalSumAndCount.count = 0;
    }
  };

  const chengeValueAmount = (mode, idx) => {
    const arr = product_emp.product;
    let amount = product_emp.product[idx].p_amount;
    setProduct_emp({ product: [] });
    if (mode === "add") {
      arr[idx].p_amount = 1 + parseInt(amount);
    } else if (mode === "sub" && parseInt(amount) > 1) {
      arr[idx].p_amount = parseInt(amount) - 1;
    }
    setProduct_emp({ product: arr });
    product_emp.product = arr;
    updateCountAndTotal();
  };

  const updateCountAndTotal = () => {
    const temp = product_emp.product.filter((el) => el.p_checkbox === true);
    let count = 0;
    if (temp) {
      let sumTotal = 0;
      for (let item of temp) {
        if (item.ps_checkbox == true) {
          sumTotal +=
            (item.p_point ? parseInt(item.p_point) : 0) *
            (item.p_point ? parseInt(item.p_amount) : 0);
          count++;
        }
      }

      setTotalSumAndCount({
        totalSum: sumTotal,
        count: count,
      });
      totalSumAndCount.totalSum = sumTotal;
      totalSumAndCount.count = count;
    }
  };

  const getProductInCart = async () => {
    var get_category = {
      oem_id: getOem(),
      company_id: getUser().com,
      fup: getUser().fup,
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_incentive +
        "/api/detailsAddressEmployee/getProductInCart",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_category,
    })
      .then(async function (response) {
        console.log(response.data.employee_address_list, "ds");
        const arr = [];
        if (response.data.employee_address_list.length > 0) {
          for (let item of response.data.employee_address_list) {
            item.p_checkbox = false;
            arr.push(item);
          }
        }

        setProduct_emp({ product: arr });
        product_emp.product = arr;

        if (id) {
          console.log("id", id);
          await callbackProduct(id);
        } else if (dataReceived) {
          await callbackProduct();
        }
        // setselect_category(response.data.category_list);
        /* const arr = [];
                if (response.data.employee_address_list.length > 0) {
                    for (let item of response.data.employee_address_list) {
                        arr.push(item);
                    }
                }
                setAddress_emp({ data: arr });
                address_emp.data = arr; */
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "warning",
          title: "เกิดข้อผิดพลาด",
          text: error.response.data.error.errorDis,
        });
      });
  };

  function checkSwalErrorSave() {
    var error_list = [];
    let check = true;
    if (productSelect.product.length == 0) {
      let temp_err = {
        message: "กรุณาเลือกสินค้า ก่อนกดยืนยัน",
      };
      check = false;
      error_list.push(temp_err);
    }

    const temp = productSelect.product.filter((el) => el.ps_checkbox === true);
    if (check && temp.length === 0) {
      let temp_err = {
        message: "กรุณาเลือกสินค้า ในตารางสรุปคำสั่งซื้อ ก่อนกดยืนยัน",
      };
      error_list.push(temp_err);
    }

    return error_list;
  }

  const deleteProductCart = (idx) => {
    const sc_product_id = product_emp.product[idx].sc_id;
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("test delete");
        axios({
          method: "get",
          url:
            Configs.API_URL_incentive +
            "/api/detailsAddressEmployee/deleteProductInCart/" +
            sc_product_id,
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            console.log(response);
            Swal.fire({
              icon: "success",
              text: "เสร็จสิ้น",
              showConfirmButton: false,
              timer: 1000,
            }).then(() => {
              window.location.reload(); //.href = "/Human_Resource/intensive_TTT/manage_product_system";
            });
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
          });
      }
    });
  };

  function numberWithCommas(x) {
    return (
      <NumberFormat
        thousandSeparator={true}
        value={x || 0}
        displayType="text"
      />
    );
    /*  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); */
  }

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h1>
                  <b>รถเข็น</b>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">สินค้าและบริการ</li>
                  <li className="breadcrumb-item active">รถเข็น</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="row">
            <PointCartLine />
          </div>
          <div className="scroll-response">
            <div style={{ width: "100%", minWidth: "1024px" }}>
              <div className="row " key={"address"}>
                <div
                  className=""
                  key={"address-col"}
                  style={{
                    backgroundColor: "#e4f4ff",
                    height: "auto",
                    padding: ".5em",
                    margin: "1em 1em",
                    width: "100%",
                    borderRadius: "20px",
                  }}
                >
                  <div className="row header-product">
                    <div
                      className="col-.5 header-input"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ display: "flex", alignSelf: "center" }}>
                        <input
                          // style={{ width: '20px', height: '20px' }}
                          className={`larger ${
                            checkAll.status === true ? "check-color" : ""
                          }`}
                          type="checkbox"
                          id="select_all"
                          checked={checkAll.status}
                          // defaultChecked={checkAll.status}
                          // disabled={el.emp_checkbox}
                          onClick={(e) => {
                            handleCheckAllChange(e);
                          }}
                          /* onChange={(e) => {
                                                    // handleCheckChange(e);
                                                }} */
                        ></input>
                      </div>
                    </div>
                    <div
                      className="col-xl-4 col-md-4 col-sm-3 col-3"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <h4 className="h4-response" style={{ margin: "0" }}>
                        <b>สินค้า</b>
                      </h4>
                    </div>
                    <div
                      className="col-xl-2 col-md-2 col-sm-2 col-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h4 className="h4-response" style={{ margin: "0" }}>
                        <b>คะแนนต่อชิ้น</b>
                      </h4>
                    </div>
                    <div
                      className="col-xl-2 col-md-2 col-sm-2 col-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h4 className="h4-response" style={{ margin: "0" }}>
                        <b>จำนวน</b>
                      </h4>
                    </div>
                    <div
                      className="col-xl-2 col-md-2 col-sm-2 col-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h4 className="h4-response" style={{ margin: "0" }}>
                        <b>คะแนนรวม</b>
                      </h4>
                    </div>
                    <div
                      className="col-xl-1 col-md-1 col-sm-1 col-1"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h4 className="h4-response" style={{ margin: "0" }}>
                        <b>MGT</b>
                      </h4>
                    </div>
                  </div>
                  {/* <h4 style={{ margin: '0', fontWeight: '800' }}>รายละเอียดการจัดส่ง</h4> */}
                </div>
              </div>
              {product_emp.product.map((el, index) => {
                return (
                  <>
                    <div className="row" key={"address-row" + el.sc_id}>
                      <div
                        className=""
                        key={"address-col" + el.sc_id}
                        style={{
                          backgroundColor: "#e4f4ff",
                          height: "auto",
                          padding: ".5em",
                          margin: ".5em 1em",
                          marginTop: "0",
                          width: "100%",
                          borderRadius: "20px",
                        }}
                      >
                        <div className="row">
                          <div
                            className="col-.5"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              margin: "0 1em",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignSelf: "center" }}
                            >
                              <input
                                // style={{ width: '20px', height: '20px' }}
                                className={`larger  ${
                                  el.p_checkbox == true ? "check-color" : ""
                                }`}
                                type="checkbox"
                                id="select_all"
                                checked={el.p_checkbox == true ? true : false}
                                // defaultChecked={el.p_checkbox}
                                // disabled={el.emp_checkbox}
                                onClick={(e) => {
                                  handleCheckChange(index, e);
                                }}
                                /* onChange={(e) => {
                                                                // handleCheckChange(e);
                                                            }} */
                              ></input>
                            </div>
                          </div>
                          <div className="col-xl-3 col-md-3 col-sm-3 col-2">
                            <div
                              className="row"
                              style={{ alignItems: "center" }}
                            >
                              <div className="col-4" style={{ padding: "0" }}>
                                <img
                                  style={{
                                    width: "60px",
                                    height: "60px",
                                    position: "relative",
                                    display: "block",
                                    borderRadius: "10px",
                                  }}
                                  id="img"
                                  alt="..."
                                  className="img-fluid rounded shadow border-0 border-img"
                                  src={
                                    el.p_img_path !== "" &&
                                    el.p_img_path !== null &&
                                    el.p_img_path !== undefined
                                      ? Configs.API_URL_IMG_incentive +
                                        el.p_img_path
                                      : userdefault_img.imgs
                                  }
                                />
                              </div>
                              <div
                                className="col-8"
                                style={{
                                  display: "flex",
                                  lineHeight: "1.2",
                                  flexDirection: "column",
                                  // flexWrap: "nowrap"
                                }}
                              >
                                <h4
                                  className="h4-response"
                                  style={{ margin: "0" }}
                                >
                                  <b>{el.p_name}</b>
                                </h4>
                                <p
                                  style={{
                                    margin: "0",
                                    fontSize: ".8em",
                                    color: "#000000a3",
                                  }}
                                >
                                  {el.p_detail}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-1 " style={{ display: "flex" }}>
                            <div
                              className="row"
                              style={{ alignItems: "center" }}
                            >
                              <div
                                className="col-12 whiteS"
                                style={{
                                  display: "flex",
                                  lineHeight: "1.2",
                                  flexDirection: "column",
                                  color: "#000000a3",
                                  whiteSpace: "nowrap",
                                  // flexWrap: "nowrap"
                                }}
                              >
                                <h3
                                  className="text-of"
                                  style={{ margin: "0", fontSize: ".8em" }}
                                >
                                  รายละเอียดสินค้า &#9660;
                                </h3>
                                <p style={{ margin: "0", fontSize: ".8em" }}>
                                  {el.p_model}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-xl-2 col-md-2 col-sm-2 col-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <h4 className="h4-response">
                              <b>
                                {numberWithCommas(
                                  el.p_point_flash_sale || el.p_point
                                )}{" "}
                                คะแนน
                              </b>
                            </h4>
                          </div>
                          <div
                            className="col-xl-2 col-md-2 col-sm-2 col-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              columnGap: ".5rem",
                            }}
                          >
                            <button
                              style={{
                                borderRadius: "1em",
                                border: "1px solid #9b9bff",
                                display: "flex",
                                height: "1em",
                                alignItems: "center",
                                outline: "none",
                                boxShadow: "none",
                              }}
                              onClick={() => {
                                chengeValueAmount("sub", index);
                              }}
                            >
                              -
                            </button>
                            <input
                              type="text"
                              id="select_all" /* defaultValue={2} */
                              value={el.p_amount}
                              style={{
                                width: 50,
                                borderRadius: "20px",
                                ontFamily: "-webkit-body",
                                eight: 30,
                                textAlign: "center",
                                border: "1px solid #8c8cea",
                                color: "#000000a3",
                              }}
                            />
                            <button
                              style={{
                                borderRadius: "1em",
                                border: "1px solid #9b9bff",
                                display: "flex",
                                height: "1em",
                                alignItems: "center",
                                outline: "none",
                                boxShadow: "none",
                              }}
                              onClick={() => {
                                chengeValueAmount("add", index);
                              }}
                            >
                              +
                            </button>
                          </div>
                          <div
                            className="col-xl-2 col-md-2 col-sm-2 col-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <h4
                              className="h4-response"
                              style={{ textAlign: "center" }}
                            >
                              <b style={{ color: "#0d9bff" }}>
                                {numberWithCommas(
                                  (el.p_point_flash_sale
                                    ? el.p_point_flash_sale
                                      ? parseInt(el.p_point_flash_sale)
                                      : 0
                                    : el.p_point
                                    ? parseInt(el.p_point)
                                    : 0) *
                                    (el.p_amount ? parseInt(el.p_amount) : 1)
                                )}{" "}
                                คะแนน
                              </b>
                            </h4>
                          </div>
                          <div
                            className="col-xl-1 col-md-1 col-sm-1 col-1"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: ".8em",
                              color: "#34347a",
                            }}
                          >
                            <a
                              /* key={product_data[index].product_id}
                               */
                              id={"btn_delete" + (index + 1)}
                              className=" btn btn-xs "
                              onClick={() => {
                                deleteProductCart(index);
                              }}
                            >
                              <i
                                class="fas fa-trash-alt"
                                style={{
                                  border: "1px solid #7c7c7c",
                                  padding: "5px",
                                  background: "white",
                                  borderRadius: "6px",
                                }}
                              ></i>
                            </a>
                          </div>
                        </div>
                        {/* <h4 style={{ margin: '0', fontWeight: '800' }}>รายละเอียดการจัดส่ง</h4> */}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          <div className="row">
            <div
              className=""
              style={{
                /* backgroundColor: '#dbf0f8', */ height: "auto",
                margin: "0 1em",
                width: "100%",
                borderRadius: "20px",
              }}
            >
              <hr></hr>
            </div>
          </div>
          <div className="row">
            <div
              className=""
              style={{
                backgroundColor: "#e4f4ff",
                height: "auto",
                padding: "1em",
                margin: "0 1em",
                width: "100%",
                borderRadius: "20px",
              }}
            >
              <h4
                className="h4-response"
                style={{ margin: "0", fontWeight: "800" }}
              >
                สรุปคำสั่งซื้อ
              </h4>
            </div>
          </div>
          <div className="row">
            <div
              style={{
                height: "auto",
                padding: "1em",
                margin: "0 1em",
                width: "100%",
                borderRadius: "20px",
                display: "flex",
                columnGap: "1rem",
              }}
            >
              <h4
                className="h4-response"
                style={{ margin: "0", fontWeight: "800", whiteSpace: "nowrap" }}
              >
                รวม ( {totalSumAndCount.count} สินค้า) :
              </h4>
              <h4
                className="h4-response"
                style={{
                  margin: "0",
                  fontWeight: "800",
                  whiteSpace: "nowrap",
                  color: "#0d9bff",
                }}
              >
                {numberWithCommas(totalSumAndCount.totalSum)} คะแนน
              </h4>
            </div>
          </div>
          <div className="scroll-response">
            <div
              className=""
              style={{
                border: "1px solid #100058",
                height: "auto",
                padding: "1em",
                margin: "0 1em 1em 1em",
                borderRadius: "20px",
                /* width: '100%', */ minWidth: "1024px",
              }}
            >
              <div className="flex-contain">
                <div className="flex-contain-item">
                  <input
                    style={{
                      width: "20px",
                      height: "20px",
                      display: "flex",
                      position: "relative",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className={`larger ${
                      checkAllSelect.status === true ? " check-color" : ""
                    }`}
                    type="checkbox"
                    id="select_all"
                    checked={checkAllSelect.status}
                    // disabled={el.emp_checkbox}
                    onClick={(e) => {
                      handleCheckAllSelectChange(e);
                    }}
                    /* onChange={(e) => {
                                        // handleCheckChange(e);
                                    }} */
                  ></input>
                  <h4
                    className="h4-response"
                    style={{ margin: "0", fontWeight: "800" }}
                  >
                    เลือกทั้งหมด
                  </h4>
                  <div
                    style={{
                      backgroundColor: "#606060",
                      width: "2px",
                      height: "100%",
                      color: "#000000a3",
                    }}
                  ></div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      cancleSelectProduct();
                    }}
                  >
                    <h4
                      className="h4-response"
                      style={{ margin: "0", color: "#000000a3" }}
                    >
                      ลบ
                    </h4>
                  </div>
                </div>
                <div className="flex-contain-item"></div>
              </div>
              <div className="flex-contain-item">
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "separate",
                    borderSpacing: "0 1rem",
                  }}
                >
                  <tr>
                    <th
                      style={{ backgroundColor: "inherit", color: "#000000a3" }}
                    ></th>
                    <th
                      style={{
                        backgroundColor: "inherit",
                        textAlign: "start",
                        color: "#000000a3",
                        fontWeight: "500",
                      }}
                    >
                      รหัสสินค้า
                    </th>
                    <th
                      style={{
                        backgroundColor: "inherit",
                        color: "#000000a3",
                        fontWeight: "500",
                      }}
                    >
                      สินค้า
                    </th>
                    <th
                      style={{
                        backgroundColor: "inherit",
                        color: "#000000a3",
                        fontWeight: "500",
                      }}
                    >
                      ตัวเลือกสินค้า
                    </th>
                    <th
                      style={{
                        backgroundColor: "inherit",
                        color: "#000000a3",
                        fontWeight: "500",
                      }}
                    >
                      จำนวน {"    "}
                    </th>
                    <th
                      style={{
                        backgroundColor: "inherit",
                        color: "#000000a3",
                        fontWeight: "500",
                      }}
                    >
                      คะแนนรวม
                    </th>
                  </tr>
                  {productSelect.product.map((el, index) => {
                    return (
                      <>
                        <tr style={{ textAlign: "center" }}>
                          <td
                            style={{
                              textAlign: "center",
                              display: "revert",
                              alignItems: "start",
                              justifyContent: "start",
                              marginTop: ".2rem",
                              columnGap: "1rem",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <input
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  display: "flex",
                                  position: "relative",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                className={`larger ${
                                  el.ps_checkbox === true ? " check-color" : ""
                                }`}
                                type="checkbox"
                                id="select_all"
                                checked={el.ps_checkbox}
                                // disabled={el.emp_checkbox}
                                onClick={(e) => {
                                  const statusTF = e.target.checked;
                                  const arr = productSelect.product;
                                  arr[index].ps_checkbox = statusTF;
                                  setProductSelect({ product: [] });
                                  setProductSelect({ product: arr });
                                  productSelect.product = arr;
                                  // handleCheckChange(index);
                                  const temp = productSelect.product.filter(
                                    (el) => el.ps_checkbox === true
                                  );
                                  if (
                                    productSelect.product.length == temp.length
                                  ) {
                                    setCheckAllSelect({
                                      status: true,
                                    });
                                    checkAllSelect.status = true;
                                  } else {
                                    setCheckAllSelect({
                                      status: false,
                                    });
                                    checkAllSelect.status = false;
                                  }
                                  updateCountAndTotal();
                                  console.log(
                                    "productSelect.product",
                                    productSelect.product
                                  );
                                }}
                                /* onChange={(e) => {
                                                                // handleCheckChange(e);
                                                            }} */
                              ></input>
                            </div>
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              columnGap: "1rem",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "50%",
                                justifyContent: "start",
                                color: "#000000a3",
                              }}
                            >
                              {el.p_no}
                            </div>
                          </td>
                          <td style={{ color: "#000000a3" }}>{el.p_name}</td>
                          <td style={{ color: "#000000a3" }}>{el.p_model}</td>
                          <td style={{ color: "#000000a3" }}>{el.p_amount}</td>
                          <td style={{ color: "#000000a3" }}>
                            {numberWithCommas(
                              (el.p_point_flash_sale || el.p_point) *
                                el.p_amount
                            )}{" "}
                            คะแนน
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>

          <div
            className="row"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className="col-6 col-xl-3 col-md-3 col-sm-3"
              style={{
                height: "auto",
                padding: "1em",
                margin: "0",
                width: "auto",
                borderRadius: "20px",
              }}
            >
              <button
                type="button"
                style={{
                  backgroundColor: "#001f50",
                  borderRadius: "10px",
                  padding: ".5em 1.5em",
                  border: "none",
                }}
                className="btn btn-block btn-primary"
                id="btn-add"
                onClick={() => {
                  console.log("log");
                  const errorList = checkSwalErrorSave();
                  if (errorList.length > 0) {
                    var err_message = "";
                    for (var e = 0; e < errorList.length; e++) {
                      err_message += "<br/>" + errorList[e].message;
                    }
                    Swal.fire("Error", err_message, "error");
                    return;
                  }

                  Swal.fire({
                    title: "ยืนยันการสั่งสินค้านี้ ใช่หรือไม่",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: `ใช่`,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    cancelButtonText: `ไม่`,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handleClick();
                    }
                  });

                  // saveEmpAddress();
                  /* Swal.fire({
                                        title: 'ทำการสั่งสินค้าเรียบร้อยแล้ว',
                                        // text: `กรุณาตรวจสอบสถานะ ที่เมนูสถานะสินค้า`,
                                        html: `<h4 style="color: blue;">กรุณาตรวจสอบสถานะ ที่เมนูสถานะสินค้า</h4>`,
                                        iconHtml: `<img style="width: 120px; height: 120px; border: 0;" src="${customerImage}" >`,
                                        customClass: {
                                            icon: 'no-border',
                                            text: 'text-color'
                                        }
                                    }) */
                }}
              >
                ยืนยันสั่งสินค้า
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Cart_Product_Sum;
