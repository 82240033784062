import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Configs from "../../../config";
import Swal from "sweetalert2";
import img_header_ticket from "../../../assets/img/IMG_Ticket_Management/img_header_ticket.png";
import LogoTTT from "../../../assets/img/IMG_Ticket_Management/logoTTT03.png";
import Select from "react-select";
import moment from "moment";
import Table_All_Project from "../../../component/component_ticket/table_all_project_of_project";
import img_Three_dot from "../../../assets/img/IMG_Ticket_Management/three_dot.png";
import {
  Avatar,
  Divider,
  Tooltip,
  Dropdown,
  Space,
  Checkbox,
  Drawer,
  ConfigProvider,
  DatePicker,
  Modal,
  Card,
  Row,
  Col,
} from "antd";
import {
  AntDesignOutlined,
  UserOutlined,
  DownOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import { Button, Input } from "reactstrap";
import dayjs from "dayjs";
import {
  getOem,
  getToken,
  getUser,
  getFeature,
  getAcademy,
} from "../../../Utils/Common";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const getFont = (font) => {
  if (font === 1) {
    return { fontFamily: "DB HeaventRounded,sans-serif" };
  } else if (font === 2) {
    return { fontFamily: "Inter" };
  }
};

function All_Project(params) {
  const [Project_List, setProject_List] = useState({
    mode: "add",
    pr_id: "",
    pr_project_code: "",
    pr_name: "",
    pr_details: "",
    pr_create_date: "",
    pr_maintenance_end_date: "",
    pr_maintenance_start_date: "",
    pr_id_sl: "",
    pr_create_by: "",
    pr_project_status_id: "",
    pr_cus_id: "",
  });
  const [isResponse, setIsResponse] = useState({
    lg: false,
  });
  const [imageBlob, setImageBlob] = useState(null);
  const [projectDataReport, setprojectDataReport] = useState("");
  const [ticketDataReport, setticketDataReport] = useState([]);
  const [AllProjectList, setAllProjectList] = useState([]);
  const [selectProjectCodeOption, setselectProjectCodeOption] = useState([]);
  const [BoxUser, setBoxUser] = useState([]);
  const [UserPermi, setUserPermi] = useState({
    iu_ps_id: "",
    id_project: "",
  });
  const [SelectReport, setSelectReport] = useState({
    project_list: [],
    ticket_proj_list: [],
  });
  const [total, Settotal] = useState("");
  const [totalUser, SettotalUser] = useState("");

  const onChange_Checkbox = (e, id_project) => {
    // console.log("e: ",e);
    if (e === true) {
      setUserPermi({ ...UserPermi, id_project: id_project });
      axios({
        method: "get",
        url: `${Configs.API_Ticket}/api/project/getDataReportExcel/${id_project}`,
        headers: {
          Authorization: getToken(),
          "X-TTT": `${Configs.API_TTT}`,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          // console.log("ticket",response.data.data_ticket);
          // console.log("project",response.data.data_project[0]);
          setprojectDataReport(response.data.data_project[0]);
          setticketDataReport(response.data.data_ticket);
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
        });
    } else {
      setUserPermi({ ...UserPermi, id_project: "" });
      setprojectDataReport("");
      setticketDataReport([]);
    }
    // console.log('checked =',id_project);
  };
  // console.log("id_project: ", UserPermi.id_project);

  const onChange_CheckboxUser = (e, id_user, account_name, permission_role) => {
    setBoxUser((prevState) => {
      if (!e) {
        // ถ้าค่า e เป็น false ให้นำ id_user ออกจาก state
        return prevState.filter((item) => item.id_user !== id_user);
      } else {
        // ถ้าค่า e เป็น true และ id_user ยังไม่มีอยู่ใน state ให้เพิ่ม id_user เข้าไปใน state
        if (!prevState.some((item) => item.id_user === id_user)) {
          return [
            ...prevState,
            {
              id_user: id_user,
              status: true,
              account_name: account_name,
              is_leader: false,
              role: permission_role,
            },
          ];
        }
      }
      return prevState; // ถ้าไม่ตรงเงื่อนไขใด ๆ ให้ส่งค่า state เดิมกลับไป
    });
  };
  const removeFromBoxUser = (id_user) => {
    setBoxUser((prevState) =>
      prevState.filter((item) => item.id_user !== id_user)
    );
  };

  // console.log("BoxUser: ", BoxUser);

  const handlePageChangeUser = ({ currentPage, pageSize }) => {
    // console.log("Current Page:", currentPage);
    // console.log("Page Size:", pageSize);
    // Add any logic here to handle the page change
  };
  const handlePageChange = ({ currentPage, pageSize }) => {
    // console.log("Current Page:", currentPage);
    // console.log("Page Size:", pageSize);
    // Add any logic here to handle the page change
  };
  const inputRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [open, setOpen] = useState(false);
  const [modeDrawer, setModeDrawer] = useState("");
  const showDrawer = () => {
    const element_1 = document.getElementsByClassName(
      "main-header navbar navbar-expand navbar-white navbar-light"
    );
    const element_2 = document.getElementsByClassName(
      "main-sidebar sidebar-dark-primary elevation-4 set-bg"
    );

    element_1[0].style.zIndex = "auto";
    element_2[0].style.zIndex = "auto";

    setOpen(true);
  };
  const onClose = (data, isNotSwal) => {
    function resetState() {
      const element_1 = document.getElementsByClassName(
        "main-header navbar navbar-expand navbar-white navbar-light"
      );
      const element_2 = document.getElementsByClassName(
        "main-sidebar sidebar-dark-primary elevation-4 set-bg"
      );

      element_1[0].style.zIndex = "";
      element_2[0].style.zIndex = "";

      setProject_List({
        ...Project_List,
        mode: "add",
        pr_id: "",
        pr_project_code: "",
        pr_name: "",
        pr_details: "",
        pr_create_date: "",
        pr_maintenance_end_date: null,
        pr_maintenance_start_date: null,
        pr_id_sl: "",
        pr_create_by: "",
        pr_cus_id: "",
        pr_project_status_id: "",
      });
      Project_List.mode = "add";
      Project_List.pr_id = "";
      Project_List.pr_project_code = "";
      Project_List.pr_name = "";
      Project_List.pr_details = "";
      Project_List.pr_create_date = "";
      Project_List.pr_maintenance_end_date = null;
      Project_List.pr_maintenance_start_date = null;
      Project_List.pr_id_sl = "";
      Project_List.pr_create_by = "";
      Project_List.pr_cus_id = "";
      Project_List.pr_project_status_id = "";
      setBoxUser([]);
      setOpen(false);
    }
    if (isNotSwal) {
      resetState();
    } else {
      Swal.fire({
        title: `คุณต้องการยกเลิก${
          modeDrawer == "report" ? "การออก Report" : "การบันทึก ข้อมูล"
        }นี้หรือไม่ ?`,
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          resetState();
        }
      });
    }
  };
  const [selectSLA_LevelList, setselectSLA_LevelList] = useState([]);
  const [selectProjectStatusList, setselectProjectStatusList] = useState([]);
  const [selectCustomerList, setselectCustomerList] = useState([]);
  const [SelectPosition, setSelectPosition] = useState([]);
  const [Filter, setFilter] = useState({
    search_text_prev: "",
    search_text: "",
    pr_project_code: "",
    search_member_prev: "",
    search_member: "",
  });
  const [FilterUser, setFilterUser] = useState({
    search_text: "",
    search_position: "",
  });
  const [currentPageUser, setCurrentPageUser] = useState(1);
  const [pageSizeUser, setpageSizeUser] = useState(10);
  const onChangePageUser = (page) => {
    setCurrentPageUser(page);
  };
  const [PermissionAccountList, setPermissionAccountList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setpageSize] = useState(10);
  const onChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      const value_prve = Filter.search_text;
      const mem_value_prve = Filter.search_member;
      setFilter({
        ...Filter,
        search_text_prev: value_prve,
        search_member_prev: mem_value_prve,
      });
      Filter.search_text_prev = value_prve;
      Filter.search_member_prev = mem_value_prve;
      filterAllProject();
    }
  };

  const handleEnterKeyPressUser = (e) => {
    if (e.key === "Enter") {
      filterPermission(FilterUser.search_position);
    }
  };
  const onShowSizeChange = (current, size) => {
    // console.log("Selected Page Size:", size);
    setpageSize(size);
  };
  const onShowSizeChangeUser = (current, size) => {
    // console.log("Selected Page Size:", size);
    setpageSizeUser(size);
  };

  // const handleOpen = () => inputRef.current.focus();
  const history = useHistory();
  useEffect(() => {
    // console.log('Page:', page);
    CheckRolePage().then(function (responseData) {
      // console.log(responseData);
      if (responseData) {
        // sessionStorage.setItem('SLA_LevelProject', "");
        setprojectDataReport("");
        setticketDataReport([]);
        getImageBlob();
        checkRoleUserTicket();
        selectProjectCode();
        filterPermission();
        selectSLA_Level();
        GetDropDownProjectStatus();
        GetDropDownCustomer();
        filterAllProject();
      } else {
        // window.location = `/NonPermission`;
        history.push("/NonPermission", {
          module: "Project Management",
          menu: "All Project",
        });
      }
    });
  }, [
    currentPage,
    pageSize,
    currentPageUser,
    pageSizeUser,
    // Filter.pr_project_code,
  ]);

  useEffect(() => {
    setIsResponse({
      ...isResponse,
      lg: window.innerWidth < 1240,
    });

    const handleResize = () => {
      setIsResponse({
        ...isResponse,
        lg: window.innerWidth < 1240,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // console.log("imageBlob:",imageBlob);

  const handleStartDateChange = (date) => {
    setProject_List({
      ...Project_List,
      pr_maintenance_start_date: date,
    });
  };
  const handleEndDateChange = (date) => {
    setProject_List({
      ...Project_List,
      pr_maintenance_end_date: date,
    });
  };

  // console.log("ProjectList: ", Project_List);
  //  console.log("ssswwqe",BoxUser);

  async function GetTicketDetailsBySelectReport(notSwal) {
    if (notSwal) {
      Swal.fire({
        title: "ระบบ",
        text: `ระบบกำลังดึงข้อมูล`,
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading();
        },
      });
    }
    await axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/project/GetTicketDetailsBySelectReport`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: {
        projects_id: SelectReport.project_list.map((proj) => proj.id),
        user_id: getUser().fup,
      },
    })
      .then(function (response) {
        //  console.log("setPermissionAccountList",response.data);

        let project_list = JSON.parse(
          JSON.stringify(SelectReport.project_list)
        );
        if (Array.isArray(response.data)) {
          for (let item of project_list) {
            item.ticket_list = response.data.filter(
              (el) => el.ticket_pr_id === item.id
            );
          }
        } else {
          project_list = [];
        }
        setSelectReport({
          ...SelectReport,
          ticket_proj_list: project_list,
        });
        SelectReport.ticket_proj_list = project_list;

        Swal.close();
      })
      .catch(function (error) {
        Swal.close();
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  async function filterPermission(search_position) {
    Swal.fire({
      title: "ระบบ",
      text: `ระบบกำลังอัพเดตข้อมูล`,
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    setPermissionAccountList([]);
    // console.log(Filter.search_position);
    let temp = {
      search_text: FilterUser.search_text.trim() || "",
      current: currentPageUser || 1,
      pageSize: pageSizeUser || 10,
      search_position: search_position || "",
      except_id_list: ["ea57bf2f-232b-40d6-972d-b83e2e952519"],
      com_id: getUser().com,
    };
    await axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/identity_user/getAllHavePermission`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        SettotalUser(response.data.AllDataTotal);
        // response.data._data.forEach((item) => {
        response.data.AllDataTotal.forEach((item) => {
          setPermissionAccountList((prevList) => [
            ...prevList,
            {
              id: item.iu_id,
              account_name: item.iu_firstname + " " + item.iu_lastname,
              permission_role: item.ps_name,
              iu_is_use: item.iu_is_use,
              iu_profile_name: item.iu_profile_name,
              iu_profile_path: item.iu_profile_path,
              // Img_Profile: item.Img_Profile
            },
          ]);
        });
        Swal.close();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  function getByIdProject(id) {
    axios({
      method: "get",
      url: `${Configs.API_Ticket}/api/project/getByid/${id}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //  console.log("getById: ", response.data);
        const prUserIdArray = JSON.parse(response.data.pr_user_id);
        setBoxUser(prUserIdArray);
        setProject_List({
          ...Project_List,
          mode: "edit",
          pr_id: id,
          pr_name: response.data.pr_name,
          pr_project_code: response.data.pr_project_code,
          pr_create_date: response.data.pr_create_date,
          pr_id_sl: response.data.pr_id_sl,
          pr_maintenance_start_date: response.data.pr_maintenance_start_date,
          pr_maintenance_end_date: response.data.pr_maintenance_end_date,
          pr_create_by: response.data.pr_create_by,
          pr_user_id: prUserIdArray,
          pr_project_status_id: response.data.pr_project_status_id,
          pr_cus_id: response.data.pr_cus_id,
        });
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  // function setByIdSLA_Project(id){
  //   axios({
  //     method: "get",
  //     url: `${Configs.API_Ticket}/api/project/getByid/${id}`,
  //     headers: {
  //       Authorization: getToken(),
  //       "X-TTT": `${Configs.API_TTT}`,
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then(function (response) {
  //     //  console.log("getById: ", response.data);
  //     sessionStorage.setItem('SLA_LevelProject', response.data.pr_id_sl);

  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       /*      Swal.fire("แจ้งเตือน", error.response.data.error[0].errorMessage, "error"); */
  //     });
  // }

  function SelectPositionFun() {
    axios({
      method: "get",
      url: `${Configs.API_Ticket}/api/permission_status/getAll`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        // console.log("select_sla",response.data);
        const dataW = [];
        //  console.log(response.data);
        response.data.forEach((item, index) => {
          if (
            item.ps_id !== "ea57bf2f-232b-40d6-972d-b83e2e952519" ||
            item.ps_name !== "Super Admin"
          ) {
            const formatData = {
              value: item.ps_id,
              label: item.ps_name,
            };
            dataW.push(formatData);
          }
        });
        setSelectPosition(dataW);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  function selectSLA_Level() {
    axios({
      method: "get",
      url: `${Configs.API_Ticket}/api/project/selectSLA_Level`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        // console.log("select_sla",response.data);
        const dataW = [];
        //  console.log(response.data);
        response.data.forEach((item, index) => {
          const formatData = {
            value: item.sl_id,
            label: item.sl_name,
          };
          dataW.push(formatData);
        });
        setselectSLA_LevelList(dataW);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  function GetDropDownProjectStatus() {
    axios({
      method: "get",
      url: `${Configs.API_Ticket}/api/project/GetDropDownProjectStatus`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        // console.log("select_sla",response.data);
        const dataW = [];
        //  console.log(response.data);
        response.data.forEach((item, index) => {
          const formatData = {
            value: item.pjs_id,
            label: item.pjs_status_name,
          };
          dataW.push(formatData);
        });
        setselectProjectStatusList(dataW);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  function GetDropDownCustomer() {
    axios({
      method: "get",
      url: `${Configs.API_Ticket}/api/project/GetDropDownCustomer`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        // console.log("select_sla",response.data);
        const dataW = [];
        //  console.log(response.data);
        response.data.forEach((item, index) => {
          const formatData = {
            value: item.cus_id,
            label: item.cus_company_name,
          };
          dataW.push(formatData);
        });
        setselectCustomerList(dataW);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  function setAddData() {
    const sessionDataString = sessionStorage.getItem("user");
    const currentDate = new Date();
    if (sessionDataString) {
      const sessionData = JSON.parse(sessionDataString);
      const fupValue = sessionData.name;
      setProject_List({
        ...Project_List,
        pr_create_by: fupValue,
        pr_create_date: currentDate,
      });
      // console.log(fupValue);
    }
  }

  // console.log("Project_list: ", Project_List);
  function ExportReportProject() {
    Swal.fire({
      title: "คุณต้องการออก Report ข้อมูลนี้หรือไม่ ?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          await axios({
            method: "post",
            url: `${Configs.API_Ticket}/api/project/ExportReportProject`,
            headers: {
              Authorization: getToken(),
              "X-TTT": `${Configs.API_TTT}`,
              "Content-Type": "application/json",
            },
            data: {
              ticket_proj_list: SelectReport.ticket_proj_list,
              user_id: getUser().fup,
            },
            responseType: "blob",
          })
            .then(function (response) {
              const contentDisposition =
                response.headers["content-disposition"];
              const filename = contentDisposition
                .split(";")
                .find((n) => n.includes("filename="))
                .replace("filename=", "")
                .trim()
                .slice(1, -1);

              const href = URL.createObjectURL(response.data);
              const link = document.createElement("a");
              link.href = href;

              link.setAttribute("download", filename);
              document.body.appendChild(link);
              link.click();

              document.body.removeChild(link);
              URL.revokeObjectURL(href);
              Swal.fire({
                icon: "success",
                title: "ดาวน์โหลดสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                onClose(null, true);
                // filterAllProject();
              });
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
            });
        }
      })
      .catch(function (error) {
        console.error("ExportReportProject ->", error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }
  function Save_Project() {
    Swal.fire({
      title: "คุณต้องการบันทึกข้อมูลนี้หรือไม่ ?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
    })
      .then((result) => {
        if (result.isConfirmed) {
          const error_list = [];
          if (Project_List.pr_project_code.trim() === "") {
            let temp_err = {
              message: "กรุณากรอก Project code",
            };
            error_list.push(temp_err);
          }
          if (Project_List.pr_name.trim() === "") {
            let temp_err = {
              message: "กรุณากรอก Project Name",
            };
            error_list.push(temp_err);
          }
          if (Project_List.pr_id_sl === "") {
            let temp_err = {
              message: "กรุณาเลือก SLA Level",
            };
            error_list.push(temp_err);
          }

          if (Project_List.pr_project_status_id === "") {
            let temp_err = {
              message: "กรุณาเลือก Project Status",
            };
            error_list.push(temp_err);
          }

          if (BoxUser.length <= 0) {
            let temp_err = {
              message: "กรุณาเลือก Member in Project",
            };
            error_list.push(temp_err);
          } else {
            // if (!BoxUser.some(el => el.is_leader))
            let found_leader = false,
              found_dev = false,
              found_tester = false;
            for (let user of BoxUser) {
              if (user.is_leader) found_leader = true;
              if (user.role == "Developer") found_dev = true;
              if (user.role == "Software Tester") found_tester = true;
            }

            if (!found_leader) {
              let temp_err = {
                message: "กรุณาเลือก Member Leader อย่างน้อย 1 คน",
              };
              error_list.push(temp_err);
            }

            if (!found_dev) {
              let temp_err = {
                message: "Member in Project ต้องมี Developer อย่างน้อย 1 คน",
              };
              error_list.push(temp_err);
            }

            if (!found_tester) {
              let temp_err = {
                message:
                  "Member in Project ต้องมี Software tester อย่างน้อย 1 คน",
              };
              error_list.push(temp_err);
            }
          }

          if (error_list.length > 0) {
            var err_message = "";
            for (var e = 0; e < error_list.length; e++) {
              err_message += "<br/>" + error_list[e].message;
            }
            Swal.fire("Error", err_message, "error");
          } else {
            if (Project_List.mode === "add") {
              const sessionDataString = sessionStorage.getItem("user");

              const sessionData = JSON.parse(sessionDataString);
              var fupValue = sessionData.name;

              let temp = {
                pr_name: Project_List.pr_name,
                pr_project_code: Project_List.pr_project_code,
                pr_details: Project_List.pr_details,
                pr_maintenance_start_date:
                  Project_List.pr_maintenance_start_date,
                pr_maintenance_end_date: Project_List.pr_maintenance_end_date,
                pr_id_sl: Project_List.pr_id_sl,
                pr_create_date: Project_List.pr_create_date,
                pr_create_by: fupValue,
                pr_user_id: BoxUser,
                pr_project_status_id: Project_List.pr_project_status_id,
                pr_cus_id: Project_List.pr_cus_id,
              };
              axios({
                method: "post",
                url: `${Configs.API_Ticket}/api/project/addProject`,
                headers: {
                  Authorization: getToken(),
                  "X-TTT": `${Configs.API_TTT}`,
                  "Content-Type": "application/json",
                },
                data: temp,
              })
                .then(function (response) {
                  Swal.fire({
                    icon: "success",
                    title: "บันทึกสำเร็จ",
                    showConfirmButton: false,
                    timer: 1500,
                  }).then((result) => {
                    onClose(null, true);
                    GetTicketDetailsBySelectReport(true);
                    filterAllProject();
                  });
                })
                .catch(function (error) {
                  console.log(error);
                  Swal.fire(
                    "แจ้งเตือน",
                    error.response.data.errorMessage,
                    "error"
                  );
                });
            } else {
              let temp = {
                pr_name: Project_List.pr_name,
                pr_project_code: Project_List.pr_project_code,
                pr_details: Project_List.pr_details,
                pr_maintenance_start_date:
                  Project_List.pr_maintenance_start_date,
                pr_maintenance_end_date: Project_List.pr_maintenance_end_date,
                pr_id_sl: Project_List.pr_id_sl,
                pr_create_by: Project_List.pr_create_by,
                pr_user_id: BoxUser,
                pr_project_status_id: Project_List.pr_project_status_id,
                pr_cus_id: Project_List.pr_cus_id,
              };
              axios({
                method: "put",
                url: `${Configs.API_Ticket}/api/project/editProject/${Project_List.pr_id}`,
                headers: {
                  Authorization: getToken(),
                  "X-TTT": `${Configs.API_TTT}`,
                  "Content-Type": "application/json",
                },
                data: temp,
              })
                .then(function (response) {
                  Swal.fire({
                    icon: "success",
                    title: "บันทึกสำเร็จ",
                    showConfirmButton: false,
                    timer: 1500,
                  }).then((result) => {
                    onClose(null, true);
                    GetTicketDetailsBySelectReport(true);
                    filterAllProject();
                  });
                })
                .catch(function (error) {
                  console.log(error);
                  Swal.fire(
                    "แจ้งเตือน",
                    error.response.data.errorMessage,
                    "error"
                  );
                });
            }
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      fontSize: 16,
      // marginTop: "-18px",
      // marginLeft: "-9px"
    }),
  };

  function CheckRolePage() {
    const id = JSON.parse(sessionStorage.getItem("user"));
    return axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/project/getByidChckeRole/${id.fup}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        // console.log("response: ", response.data[0].iu_ps_id);
        if (response.data.length > 0) {
          return true;
        } else {
          return false;
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
        return false;
      });
  }
  function checkRoleUserTicket() {
    const sessionDataString = sessionStorage.getItem("user");
    const sessionData = JSON.parse(sessionDataString);
    const fupValue = sessionData.fup;
    // console.log("fupValue: ", fupValue);
    let temp = {
      id: fupValue,
    };
    axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/ticket/checkRoleUserTicket`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        console.log("dataRole: ", response.data[0].iu_ps_id);

        setUserPermi({ ...UserPermi, iu_ps_id: response.data[0].iu_ps_id });
        UserPermi.iu_ps_id = response.data[0].iu_ps_id;
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  function filterAllProject() {
    Swal.fire({
      title: "ระบบ",
      text: `ระบบกำลังอัพเดตข้อมูล`,
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    setAllProjectList([]);
    const sessionDataString = sessionStorage.getItem("user");
    const sessionData = JSON.parse(sessionDataString);
    const fupValue = sessionData.fup;

    let temp = {
      search_text: Filter.search_text.trim() || "",
      current: currentPage || 1,
      pageSize: pageSize || 10,
      pr_project_code: Filter.pr_project_code || "",
      user_id: fupValue,
      search_member: Filter.search_member?.trim() || "",
    };
    axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/project/getAll`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        // console.log(response.data[0].pr_user_id);
        // console.log("AllProject: ", response.data);
        Settotal(response.data.AllDataTotal);
        if (Array.isArray(response.data._data)) {
          response.data._data.forEach((item) => {
            const prUserIdArray = item.pr_user_id;
            // console.log("prUserIdArray", prUserIdArray);
            setAllProjectList((prevList) => [
              ...prevList,
              {
                id: item.pr_id,
                project_name: item.pr_name,
                pr_project_code: item.pr_project_code,
                Img_Profile: item.Img_Profile,
                pr_user_id: prUserIdArray,
                pr_create_by: item.pr_create_by,
                pr_status_name: item.pjs_status_name,
              },
            ]);
          });
        }
        Swal.close();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }
  // console.log("total: ", total);
  function selectProjectCode() {
    axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/project/selectProjectCode`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: { user_id: getUser().fup },
    })
      .then(function (response) {
        // console.log("select",response.data);
        const dataW = [];
        // console.log(response.data);
        response.data.forEach((item, index) => {
          const formatData = {
            value: item.pr_project_code,
            label: item.pr_project_code,
          };
          dataW.push(formatData);
        });
        setselectProjectCodeOption(dataW);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  async function delProject(id) {
    Swal.fire({
      title: "คุณต้องการลบข้อมูลนี้หรือไม่ ?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: `${Configs.API_Ticket}/api/project/delProject/${id}`,
          headers: {
            Authorization: getToken(),
            "X-TTT": `${Configs.API_TTT}`,
            "Content-Type": "application/json",
          },
        })
          .then(async function (response) {
            filterAllProject();
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
          });
      }
    });
  }

  function ShowTicketPage(project_name) {
    window.location = `/projectmanagement/TicketAll/${project_name}`;
  }
  // console.log("AllProjectList: ", AllProjectList);

  const cardData = [];
  AllProjectList.forEach((item) => {
    cardData.push({
      id: item.id,
      content: (
        // <div style={{ width: "50%" }}>

        <div
          className="row"
          style={{
            justifyContent: "space-between",
            height: "100%",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <div style={{}}>
              <Checkbox
                onChange={(e) => {
                  let project_list = SelectReport.project_list;
                  if (e.target.checked) {
                    project_list.push({
                      id: item.id,
                      proj_name: item.project_name,
                      proj_code: item.pr_project_code,
                      proj_create_by: item.pr_create_by,
                    });
                  } else {
                    project_list = project_list.filter(
                      (el) => el.id !== item.id
                    );
                  }
                  setSelectReport({
                    ...SelectReport,
                    project_list: project_list,
                  });
                  SelectReport.project_list = project_list;
                  // onChange_Checkbox(e.target.checked, item.id);
                }}
              ></Checkbox>
            </div>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "600px",
              }}
            >
              <a
                href={`/projectmanagement/TicketAll/${item.id}`}
                style={{
                  textDecoration: "none",
                }} /* onClick={setByIdSLA_Project(item.id)} */
              >
                <span
                  className="text-hover-project-name-all-project"
                  style={{
                    whiteSpace: "nowrap",
                    fontSize: "25px",
                    fontWeight: 700,
                  }}
                >
                  {`${item.pr_project_code}-${item.project_name}`}
                </span>
              </a>
              <br />
              <span
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "20px",
                  fontWeight: 600,
                  color: "black",
                }}
              >
                {`Project Status - `}
                <span
                  style={{ color: GetColorStatusProject(item.pr_status_name) }}
                >
                  {item.pr_status_name || ""}
                </span>
              </span>
            </div>
          </div>
          <div className="row" style={{}}>
            <div className="ml-auto d-flex align-items-center">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                {" "}
                {/* ปรับขนาดของ col-md-2 เป็น col-md-3 */}
                <div
                  className=""
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <p style={{ color: "gray" }}>Members</p>
                </div>
                <div
                  className=""
                  style={{
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "flex-end",
                  }}
                >
                  <Avatar.Group
                    maxCount={2}
                    size="small"
                    maxStyle={{
                      color: "#f56a00",
                      backgroundColor: "#fde3cf",
                      // marginRight: "10px",
                      marginBottom: "10px",
                      /* display: 'flex',
                      justifyContent: 'flex-end',
                      width: '100%' */
                    }}
                    style={{ display: "flex" }}
                  >
                    {item.pr_user_id?.map((user, index) => {
                      return (
                        <Tooltip title={`${user.account_name}`} placement="top">
                          <Avatar
                            src={
                              user?.iu_profile_path
                                ? Configs.API_URL_IMG + user?.iu_profile_path
                                : undefined
                            }
                            onError={(e) => {
                              e.target.value = user.account_name
                                .substring(0, 1)
                                .toUpperCase();
                            }}
                            key={user.id_user}
                            style={{
                              backgroundColor: "#f56a00",
                            }}
                          >
                            {user.account_name.substring(0, 1).toUpperCase()}
                          </Avatar>
                        </Tooltip>
                      );
                    })}
                  </Avatar.Group>
                </div>
              </div>
            </div>

            <div className="ml-auto d-flex align-items-center">
              <div
                style={{
                  borderRight: "3px solid #ccc",
                  height: "80px",
                  margin: "0 10px",
                }}
              ></div>
              {/* <Button as="input" type="button" value="Input" style={{ backgroundColor: "#007BFF", border: "none", width: "33px", height: "35px", marginLeft:"20px" }}
                    onClick={() => {
                      ShowTicketPage(item.id); 
                      // setByIdSLA_Project(item.id);
                    }}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-file-earmark-text" viewBox="0 0 16 16" style={{ marginLeft: "-7px", marginTop: "-5px" }}>
                        <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5" />
                        <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                      </svg>
                    </Button> */}
              <div style={{}}>
                {UserPermi.iu_ps_id !==
                "ea57bf2f-232b-40d6-972d-b83e2e952519" /* not super admin */ ? (
                  <></>
                ) : (
                  <>
                    <Dropdown
                      menu={{
                        items: [
                          {
                            label: (
                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "#FF9900" }}
                                onClick={() => {
                                  setModeDrawer("");
                                  setProject_List({
                                    ...Project_List,
                                    mode: "edit",
                                  });
                                  showDrawer();
                                  getByIdProject(item.id);
                                  // setOpen(true);
                                  // settest({...test,test:''});
                                  //       test.test = '';
                                }}
                              >
                                Edit Project
                              </a>
                            ),
                            key: "0",
                          },
                          {
                            label: (
                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "#DD2025" }}
                                onClick={() => {
                                  delProject(item.id);
                                }}
                              >
                                Delete Project
                              </a>
                            ),
                            key: "1",
                          },
                        ],
                      }}
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <Space>
                          <img
                            src={img_Three_dot}
                            width={25}
                            height={25}
                            style={{ margin: "0px 10px" }}
                          />
                        </Space>
                      </a>
                    </Dropdown>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ),
    });
  });

  const cardDataUser = [];
  PermissionAccountList.forEach((item) => {
    cardDataUser.push({
      id: item.id,
      content: (
        <>
          <div
            className="row"
            style={{
              height: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className="d-flex" style={{ gap: "10px" }}>
              <Avatar
                size={48}
                src={
                  item?.iu_profile_path
                    ? Configs.API_URL_IMG + item?.iu_profile_path
                    : undefined
                }
                onError={(e) => {
                  e.target.value = item.account_name
                    .substring(0, 1)
                    .toUpperCase();
                }}
                key={item.id_user}
                style={{
                  backgroundColor: "#f56a00",
                }}
              >
                {item.account_name.substring(0, 1).toUpperCase()}
              </Avatar>
              <div
                style={{
                  marginTop: "8px",
                  maxWidth: "350px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <span
                  style={{
                    marginLeft: "5px",
                    fontSize: "20px",
                    // fontWeight: 700,
                    whiteSpace: "nowrap",
                  }}
                >{`${item.account_name}`}</span>
              </div>
            </div>
            <div
              className="d-flex"
              style={{
                gap: "10px",
                minWidth: "150px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <span style={{ color: "gray" }}>Permission</span>
                <br />
                <span
                  style={{ whiteSpace: "nowrap" }}
                >{`${item.permission_role}`}</span>
              </div>
              <div>
                <Checkbox
                  onChange={(e) => {
                    onChange_CheckboxUser(
                      e.target.checked,
                      item.id,
                      item.account_name,
                      item.permission_role
                    );
                  }}
                  checked={
                    BoxUser &&
                    BoxUser.some(
                      (user) => user.id_user === item.id && user.status
                    )
                  }
                ></Checkbox>
              </div>
            </div>
          </div>
        </>
      ),
    });
  });

  const cardDataAddUser = [];
  BoxUser.forEach((item, item_index) => {
    // console.log("BoxUser item :>> ", item);
    cardDataAddUser.push({
      id: item.id_user,
      content: (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              padding: "5px 10px",
            }}
          >
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Checkbox
                checked={item.is_leader || false}
                onChange={(e) => {
                  let boxUser = [...BoxUser];
                  if (e.target.checked) {
                    boxUser[item_index].is_leader = true;
                    /* boxUser.forEach((el, el_index) => {
                      if (el_index !== item_index) {
                        el.is_leader = false;
                      }
                    }) */
                  } else {
                    boxUser[item_index].is_leader = false;
                  }
                  setBoxUser(boxUser);
                  // onChange_Checkbox(e.target.checked, item.id);
                }}
              ></Checkbox>
              <Avatar
                size={24}
                src={
                  PermissionAccountList.find(
                    (user_permis) => item.id_user === user_permis.id
                  )?.iu_profile_path
                    ? Configs.API_URL_IMG +
                      PermissionAccountList.find(
                        (user_permis) => item.id_user === user_permis.id
                      )?.iu_profile_path
                    : undefined
                }
                onError={(e) => {
                  e.target.value = item.account_name
                    .substring(0, 1)
                    .toUpperCase();
                }}
                key={item.id_user}
                style={{
                  backgroundColor: "#f56a00",
                }}
              >
                {item.account_name.substring(0, 1).toUpperCase()}
              </Avatar>
              <div
                style={{
                  maxWidth: "220px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <span
                  style={{ whiteSpace: "nowrap" }}
                >{`${item.account_name}`}</span>
              </div>
            </div>
            <CloseCircleFilled
              style={{ color: "#b5b5b5" }}
              onClick={() => {
                removeFromBoxUser(item.id_user);
              }}
            />
          </div>
        </>
      ),
    });
  });

  const getImageBlob = (LogoTTT) => {
    fetch(LogoTTT)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.arrayBuffer(); // เปลี่ยนเป็น array buffer
      })
      .then((buffer) => {
        setImageBlob(buffer);
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
  };

  function GetColorStatusProject(status_name) {
    let str_return = "";
    if (status_name === "เสร็จสิ้น") {
      str_return = "#47BA6F";
    } else if (status_name === "ยกเลิก") {
      str_return = "#DD2025";
    } else if (status_name === "กำลังดำเนินการ") {
      str_return = "#007BFF";
    } else if (status_name === "รอดำเนินการ") {
      str_return = "#FEC400";
    }
    return str_return;
  }

  function FormatOld() {
    return (
      <>
        {UserPermi.iu_ps_id === "d9300f9b-e533-47a2-b40c-4039e2e50ea3" ? (
          <></>
        ) : (
          <>
            <table id="table-to-xls" style={{ display: "none" }}>
              <tbody>
                <tr>
                  <th
                    colSpan="10"
                    rowSpan="4"
                    contentEditable="true"
                    style={{ width: "1500px" }}
                  >
                    <div style={{ marginLeft: "100px" }} className="row">
                      {/* {imageBuffer && <img src={`data:image/png;base64,${arrayBufferToBase64(imageBuffer)}`} alt="Image" style={{ height: "60px" }} />} */}
                      เอกสารบันทึกรายชื่อผู้รับผิดชอบข้อผิดพลาด (Document recode
                      responsible person error project)
                    </div>
                  </th>
                </tr>
                <tr></tr>
                <tr></tr>
                <tr></tr>
                <tr colSpan="10">
                  <th
                    colSpan="10"
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#90A4E3",
                    }}
                  >
                    Introduction
                  </th>
                </tr>
                <tr>
                  <th
                    colSpan="10"
                    rowSpan="4"
                    contentEditable="true"
                    style={{
                      border: "1px solid black",
                      textAlign: "left",
                      width: "1500px",
                    }}
                  >
                    เอกสารชุดนี้เรียกว่า Document reocde responsible person
                    error project มีวัถตุประสงค์เพื่อ <br />
                    1.
                    บันทึกข้อมูลรายชื่อของผู้ที่ทำหน้าที่รับผิดชอบการดำเนินการแก้ไข
                    Ticket ที่เกิดขึ้นในระบบ <br />
                    2.
                    เพื่อใช้สำหรับการประเมินผลการทำงานและการดำเนินการแก้ไขปัญหา
                    Ticket <br />
                    3.
                    สำหรับการดำเนินการค้นหาและติดตามการแก้ไขข้อผิดพลาดที่เกิดขึ้นได้ตรงตามบุคคลที่รับผิดชอบ
                  </th>
                </tr>
                <tr></tr>
                <tr></tr>
                <tr></tr>
                <tr></tr>
                <tr colSpan="2">
                  <th
                    style={{
                      backgroundColor: "#90A4E3",
                      border: "1px solid black",
                    }}
                  >
                    Author :
                  </th>
                  <th
                    colSpan="9"
                    style={{
                      textAlign: "left",
                      border: "1px solid black",
                    }}
                  >
                    {projectDataReport.pr_create_by || "-"}
                  </th>
                </tr>
                <tr colSpan="2">
                  <th
                    style={{
                      backgroundColor: "#90A4E3",
                      border: "1px solid black",
                    }}
                  >
                    Project :
                  </th>
                  <th
                    th
                    colSpan="9"
                    style={{
                      textAlign: "left",
                      border: "1px solid black",
                    }}
                  >
                    {projectDataReport.pr_project_code || "-"}
                  </th>
                </tr>
                <tr></tr>
                <tr>
                  <th
                    colSpan="3"
                    style={{
                      textAlign: "left",
                      color: "#FFFFFF",
                      backgroundColor: "#4682B4",
                      border: "1px solid black",
                    }}
                  >
                    Error Project :
                  </th>
                </tr>
                <tr>
                  <th
                    style={{
                      backgroundColor: "#90A4E3",
                      border: "1px solid black",
                    }}
                  >
                    Code.
                  </th>
                  <th
                    style={{
                      backgroundColor: "#90A4E3",
                      border: "1px solid black",
                    }}
                  >
                    Date Start
                  </th>
                  <th
                    colSpan="3"
                    style={{
                      backgroundColor: "#90A4E3",
                      border: "1px solid black",
                    }}
                  >
                    Error Project
                  </th>
                  <th
                    style={{
                      backgroundColor: "#90A4E3",
                      border: "1px solid black",
                    }}
                  >
                    Ticket type
                  </th>
                  <th
                    style={{
                      backgroundColor: "#90A4E3",
                      border: "1px solid black",
                    }}
                  >
                    Severity
                  </th>
                  <th
                    style={{
                      backgroundColor: "#90A4E3",
                      border: "1px solid black",
                    }}
                  >
                    Date Finish
                  </th>
                  <th
                    style={{
                      backgroundColor: "#90A4E3",
                      border: "1px solid black",
                    }}
                  >
                    Problem owner
                  </th>
                  <th
                    style={{
                      backgroundColor: "#90A4E3",
                      border: "1px solid black",
                    }}
                  >
                    responsible person
                  </th>
                </tr>
                {ticketDataReport.map((data, index) => (
                  <tr>
                    <th
                      style={{
                        textAlign: "left",
                        border: "1px solid black",
                      }}
                    >
                      {" "}
                      {data.ticket_code || "-"}
                    </th>
                    <th
                      style={{
                        textAlign: "left",
                        border: "1px solid black",
                      }}
                    >
                      {" "}
                      {moment(data.ticket_start_date).format(
                        "YYYY/MM/DD HH.mm"
                      ) || "-"}
                    </th>
                    <th
                      colSpan="3"
                      style={{
                        textAlign: "left",
                        border: "1px solid black",
                      }}
                    >
                      {data.ticket_description || "-"}
                    </th>
                    <th
                      style={{
                        textAlign: "left",
                        border: "1px solid black",
                      }}
                    >
                      {data.tt_name || (
                        <div style={{ textAlign: "center" }}>{"-"}</div>
                      )}
                    </th>
                    {data.severity_name === "minor" ? (
                      <>
                        <th
                          style={{
                            background: "#DD2025",
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {data.severity_name || "-"}
                        </th>
                      </>
                    ) : (
                      <>
                        {data.severity_name === "average" ? (
                          <>
                            <th
                              style={{
                                background: "#FF9900",
                                textAlign: "center",
                                border: "1px solid black",
                              }}
                            >
                              {data.severity_name || "-"}
                            </th>
                          </>
                        ) : (
                          <>
                            {data.severity_name === "major" ? (
                              <>
                                <th
                                  style={{
                                    background: "#AD00FF",
                                    textAlign: "center",
                                    border: "1px solid black",
                                  }}
                                >
                                  {data.severity_name || "-"}
                                </th>
                              </>
                            ) : (
                              <>
                                {data.severity_name === "critical" ? (
                                  <>
                                    <th
                                      style={{
                                        background: "#007BFF",
                                        textAlign: "center",
                                        border: "1px solid black",
                                      }}
                                    >
                                      {data.severity_name || "-"}
                                    </th>
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    <th
                                      style={{
                                        textAlign: "center",
                                        border: "1px solid black",
                                      }}
                                    >
                                      {"Feedback"}
                                    </th>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}

                    <th
                      style={{
                        textAlign: "left",
                        border: "1px solid black",
                      }}
                    >
                      {moment(data.ticket_start_date)
                        .add(180, "minutes")
                        .format("YYYY/MM/DD HH.mm") || "-"}
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        border: "1px solid black",
                      }}
                    >
                      {/* {data.ticket_customer_name || "-"} */}
                    </th>
                    <th
                      style={{
                        textAlign: "left",
                        border: "1px solid black",
                      }}
                    >
                      {data.manage_by_f + " " + data.manage_by_l || "-"}
                    </th>
                  </tr>
                ))}
                <tr></tr>
                <tr>
                  <th colSpan="3">Project manager</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th colSpan="3">Deverloper</th>
                </tr>
                <tr></tr>
                <tr>
                  <th colSpan="3">
                    ลงชื่อ:
                    (.............................................................)
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th colSpan="3">
                    {" "}
                    ลงชื่อ:
                    (...................................................................){" "}
                  </th>
                </tr>

                <tr>
                  <th>วันที่ :</th>
                  <th /* colSpan="2" style={{textAlign:"left"}} */>
                    {moment(new Date()).format("YYYY/MM/DD")}
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>วันที่ :</th>
                  <th /* colSpan="2" style={{textAlign:"left"}} */>
                    {moment(new Date()).format("YYYY/MM/DD")}
                  </th>
                </tr>

                <tr></tr>
                <tr></tr>

                <tr>
                  <th colSpan="3">Software tester</th>
                </tr>
                <tr></tr>
                <tr>
                  <th colSpan="3">
                    ลงชื่อ:
                    (..........................................................)
                  </th>
                </tr>
                <tr>
                  <th>วันที่ :</th>
                  <th /* style={{textAlign:"left"}} */>
                    {moment(new Date()).format("YYYY/MM/DD")}
                  </th>
                </tr>
              </tbody>
            </table>
            {UserPermi.iu_ps_id === "ea57bf2f-232b-40d6-972d-b83e2e952519" ? ( //Super Admin
              <></>
            ) : (
              <>
                <div className="col-6"></div>
              </>
            )}
            {UserPermi.id_project === "" || true ? (
              <div className="col-6">
                <Button
                  size="large"
                  type={
                    SelectReport.project_list.length > 0 ? "primary" : "default"
                  }
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                  disabled={SelectReport.project_list.length === 0}
                  onClick={() => {
                    setModeDrawer("report");
                    GetTicketDetailsBySelectReport();
                    showDrawer();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                  </svg>{" "}
                  <span style={{ fontSize: "20px", whiteSpace: "nowrap" }}>
                    Export Report
                  </span>
                </Button>
              </div>
            ) : (
              <>
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="btn btn-primary"
                  table="table-to-xls"
                  filename={
                    projectDataReport.pr_project_code +
                    "-" +
                    projectDataReport.pr_name
                  }
                  sheet="tablexls"
                  buttonText={
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-plus-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                      </svg>
                      {"  "}
                      <span>Export Report</span>
                    </>
                  }
                />
              </>
            )}
            {UserPermi.iu_ps_id === "ea57bf2f-232b-40d6-972d-b83e2e952519" ? ( //Super Admin
              <>
                <div className="col-6">
                  <Button
                    size="large"
                    type="primary"
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                    }}
                    onClick={() => {
                      setModeDrawer("");
                      setAddData();
                      showDrawer();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-plus-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                    </svg>
                    {"  "}
                    <span style={{ fontSize: "20px" }}>Add Project</span>
                  </Button>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </>
    );
  }
  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid" style={{ marginTop: "10px" }}>
          <div className="row">
            <div
              className="col-12 col-sm-4 col-xl-6"
              style={{ display: "flex" }}
            >
              <img
                alt="..."
                className="img_header_ticket"
                src={img_header_ticket}
                width={30}
                height={30}
              />
              <h1
                style={{
                  fontWeight: 700,
                  fontSize: "55px",
                  marginTop: "-16px",
                  marginLeft: "18px",
                }}
              >
                All Project
              </h1>
            </div>
            <div className="col-12 col-sm-8 col-xl-6 ">
              <ol className="breadcrumb float-sm-right ">
                <li className="breadcrumb-item active">
                  Home
                  {/* <a href="/Welcome">Home</a> */}
                </li>
                <li className="breadcrumb-item active">Project Management</li>
                <li className="breadcrumb-item">All Project</li>
                {/* <li className="breadcrumb-item active">{mode}</li> */}
              </ol>
            </div>
          </div>

          <div
            className="row"
            style={{ justifyContent: "space-between", alignItems: "end" }}
          >
            <div className="col-12 col-sm-12 col-md-12 col-lg-7 ">
              <div className="row" style={{ alignItems: "flex-end" }}>
                <div
                  className="col-12 col-sm-12 col-md-3"
                  style={{ marginBottom: "10px" }}
                >
                  <span style={{ fontSize: "18px", margin: "0" }}>Search</span>
                  <input
                    // style={{ marginLeft: "-9px" }}
                    type="search"
                    id="search"
                    className="form-control"
                    placeholder="search"
                    value={Filter.search_text}
                    onChange={(e) => {
                      const value_prve = Filter.search_text;
                      setFilter({
                        ...Filter,
                        search_text_prev: value_prve,
                        search_text: e.target.value,
                      });
                      Filter.search_text_prev = value_prve;
                      Filter.search_text = e.target.value;
                    }}
                    /* onClick={() => {
                      setTimeout(() => {
                        if (Filter.search_text_prev && !Filter.search_text) {
                          const value_prve = '';
                          setFilter({
                            ...Filter,
                            search_text_prev: value_prve,
                          });
                          Filter.search_text_prev = value_prve;
                          filterAllProject();
                        }
                      }, 100)
                    }}
                    onKeyPress={handleEnterKeyPress} */
                  />
                </div>
                <div
                  className="col-12 col-sm-12 col-md-3"
                  style={{ marginBottom: "10px" }}
                >
                  <span style={{ fontSize: "18px", margin: "0" }}>
                    Search Member
                  </span>
                  <input
                    style={{}}
                    // type="search"
                    id="search"
                    className="form-control"
                    placeholder="search"
                    value={Filter.search_member}
                    onChange={(e) => {
                      const value_prve = Filter.search_member;
                      setFilter({
                        ...Filter,
                        search_member: e.target.value,
                        search_member_prev: value_prve,
                      });
                      Filter.search_member = e.target.value;
                      Filter.search_member_prev = value_prve;
                    }}
                    /* onClick={() => {
                      setTimeout(() => {
                        if (Filter.search_member_prev && !Filter.search_member) {
                          const value_prve = '';
                          setFilter({
                            ...Filter,
                            search_member_prev: value_prve,
                          });
                          Filter.search_member_prev = value_prve;
                          filterAllProject();
                        }
                      }, 100)
                    }}
                    onKeyPress={handleEnterKeyPress} */
                  />
                </div>
                <div
                  className="col-12 col-sm-12 col-md-2"
                  style={{ marginBottom: "10px" }}
                >
                  <span
                    style={{
                      fontSize: "18px",
                      margin: "0",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Project Code
                  </span>
                  <Select
                    className="select_ProjectCode"
                    placeholder="All"
                    id="select_ProjectCode"
                    styles={customStyles}
                    options={selectProjectCodeOption}
                    value={selectProjectCodeOption.filter((item, key) => {
                      return item.value === Filter.pr_project_code;
                    })}
                    onChange={(e) => {
                      if (e) {
                        setFilter({
                          ...Filter,
                          pr_project_code: e.value,
                        });
                        // filterAllTicket();
                      } else {
                        setFilter({
                          ...Filter,
                          pr_project_code: "",
                        });
                        // filterAllTicket();
                      }
                    }}
                    isClearable={true}
                  />
                </div>
                <div
                  className="col-12 col-sm-12 col-md-2"
                  style={{ marginBottom: "10px" }}
                >
                  <Button
                    size="large"
                    type="primary"
                    style={{
                      border: "none",
                      backgroundColor: "#007BFF",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      height: "45px",
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      setCurrentPage(1);
                      filterAllProject();
                    }}
                  >
                    Search
                  </Button>
                </div>
                <div
                  className="col-12 col-sm-12 col-md-2"
                  style={{ marginBottom: "10px" }}
                >
                  <Button
                    size="large"
                    type="primary"
                    style={{
                      border: "none",
                      backgroundColor: "#007BFF",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      height: "45px",
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      setFilter({
                        ...Filter,
                        search_text: "",
                        search_text_prev: "",
                        search_member: "",
                        search_member_prev: "",
                        pr_project_code: "",
                      });
                      Filter.search_member = "";
                      Filter.search_member_prev = "";
                      Filter.search_text = "";
                      Filter.search_text_prev = "";
                      Filter.pr_project_code = "";
                      setCurrentPage(1);
                      filterAllProject();
                    }}
                  >
                    {/* <span style={{ fontSize: "20px" }}></span> */}
                    Clear
                  </Button>
                </div>
              </div>
            </div>
            <div
              className={`col-12 col-sm-12 col-md-8 col-lg-${
                isResponse.lg ? 4 : 3
              }`}
            >
              <div className="row">
                {UserPermi.iu_ps_id !==
                "ea57bf2f-232b-40d6-972d-b83e2e952519" ? ( // Super Admin
                  <div
                    className="col-12 col-sm-12 col-md-6 col-lg-6"
                    style={{ marginBottom: "10px" }}
                  ></div>
                ) : (
                  <></>
                )}
                <div
                  className="col-12 col-sm-12 col-md-6 col-lg-6"
                  style={{ marginBottom: "10px" }}
                >
                  <Button
                    size="large"
                    type={
                      SelectReport.project_list.length > 0
                        ? "primary"
                        : "default"
                    }
                    style={{
                      backgroundColor:
                        SelectReport.project_list.length > 0
                          ? "#007BFF"
                          : undefined,
                      border: "none",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // marginBottom: '10px',
                      gap: "5px",
                      height: "45px",
                      borderRadius: "5px",
                      whiteSpace: "nowrap",
                    }}
                    disabled={SelectReport.project_list.length === 0}
                    onClick={() => {
                      setModeDrawer("report");
                      GetTicketDetailsBySelectReport();
                      showDrawer();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-plus-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                    </svg>{" "}
                    {/* <span style={{ fontSize: "20px", whiteSpace: 'nowrap' }}>
                    </span> */}
                    Export Report
                  </Button>
                </div>
                {UserPermi.iu_ps_id ===
                "ea57bf2f-232b-40d6-972d-b83e2e952519" ? ( //Super Admin
                  <div
                    className="col-12 col-sm-12 col-md-6 col-lg-6"
                    style={{ marginBottom: "10px" }}
                  >
                    <Button
                      size="large"
                      type="primary"
                      style={{
                        border: "none",
                        backgroundColor: "#007BFF",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        height: "45px",
                        borderRadius: "5px",
                      }}
                      onClick={() => {
                        setModeDrawer("");
                        setAddData();
                        showDrawer();
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-plus-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                      </svg>
                      {"  "}
                      {/* <span style={{ fontSize: "20px" }}></span> */}
                      Add Project
                    </Button>
                  </div>
                ) : (
                  <></>
                )}

                {/* {FormatOld()} */}
              </div>
            </div>
          </div>

          <Table_All_Project
            currentPage={currentPage}
            pageSize={pageSize}
            onChange={onChangePage}
            cardData={cardData}
            onPageChange={handlePageChange}
            onShowSizeChange={onShowSizeChange}
            total={total}
            minWidth={"800px"}
          />
        </div>
      </section>

      <ConfigProvider>
        <Drawer
          title={
            modeDrawer == "report"
              ? "Export Report"
              : Project_List.mode === "add"
              ? "Add Project"
              : "Edit Project"
          }
          placement="right"
          onClose={onClose}
          open={open}
          // style={{ position: "absolute", paddingTop: "55px" }}
          extra={
            <Space>
              <Button
                style={{
                  background: "none",
                  color: "#00000090",
                  borderColor: "#00000060",
                  minWidth: "72px",
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                style={{
                  backgroundColor: "#007BFF",
                  border: "none",
                  minWidth: "72px",
                }}
                onClick={
                  modeDrawer == "report" ? ExportReportProject : Save_Project
                }
              >
                OK
              </Button>
            </Space>
          }
        >
          {modeDrawer == "report" ? (
            <>
              <div>
                {SelectReport.ticket_proj_list?.map((ticket, index_ticket) => (
                  <div
                    key={"SelectReport.ticket_proj_list" + index_ticket}
                    className="row"
                    style={{ /* gap: '10px' */ marginBottom: "1rem" }}
                  >
                    <div className="col-12">
                      <h5>{`${ticket.proj_code}-${ticket.proj_name}`}</h5>
                    </div>
                    <div
                      className="col-12 mb-2"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className="">Ticket Name</div>
                      <div className="">
                        <Button
                          type="primary"
                          style={{
                            backgroundColor: "#007BFF",
                            border: "none",
                          }}
                          onClick={() => {
                            const ticket_proj_list = [
                              ...SelectReport.ticket_proj_list,
                            ];
                            ticket_proj_list[index_ticket].ticket_list = [];
                            setSelectReport({
                              ...SelectReport,
                              ticket_proj_list,
                            });
                            SelectReport.ticket_proj_list = ticket_proj_list;
                          }}
                        >
                          Clear all
                        </Button>
                      </div>
                    </div>
                    {ticket?.ticket_list.map((item, index_item) => (
                      <div
                        className="col-12 mb-1"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className="" style={{ color: "#1677FF" }}>
                          {item.ticket_no}
                        </div>
                        <div className="">
                          <CloseCircleFilled
                            style={{ color: "#b5b5b5" }}
                            onClick={() => {
                              const ticket_proj_list = [
                                ...SelectReport.ticket_proj_list,
                              ];
                              ticket_proj_list[index_ticket].ticket_list.splice(
                                index_item,
                                1
                              );
                              setSelectReport({
                                ...SelectReport,
                                ticket_proj_list,
                              });
                              SelectReport.ticket_proj_list = ticket_proj_list;
                              // ticket.ticket_list = ticket?.ticket_list.filter(item2 => item2.ticket_id !== item.ticket_id)//.splice(index_item, 1);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                    <div></div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <p style={{ margin: "0px 0px 10px 0px" }}>
                  Project Code<font color="red">*</font>
                </p>
                <Input
                  placeholder="Project Code"
                  value={Project_List.pr_project_code}
                  onChange={(e) => {
                    setProject_List({
                      ...Project_List,
                      pr_project_code: e.target.value,
                    });
                  }}
                ></Input>
                <p style={{ margin: "15px 0px 10px 0px" }}>
                  Project Name<font color="red">*</font>
                </p>

                <Input
                  placeholder="Project Name"
                  value={Project_List.pr_name}
                  onChange={(e) => {
                    setProject_List({
                      ...Project_List,
                      pr_name: e.target.value,
                    });
                  }}
                ></Input>

                <p style={{ margin: "15px 0px 10px 0px" }}>
                  Customer{/* <font color="red">*</font> */}
                </p>

                <div style={{ width: "100%" }}>
                  <Select
                    className="select_Customer_addProject"
                    placeholder="Select Customer"
                    id="select_Customer_addProject"
                    /* isClearable
                      isSearchable */
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "100%",
                        height: "40px",
                      }),
                    }}
                    options={selectCustomerList}
                    value={
                      !Project_List.pr_cus_id
                        ? null
                        : selectCustomerList.find((item, key) => {
                            return item.value === Project_List.pr_cus_id;
                          }) || ""
                    }
                    onChange={(e) => {
                      // console.log('e :>> ', e);
                      setProject_List({
                        ...Project_List,
                        pr_cus_id: e.value,
                      });
                      Project_List.pr_cus_id = e.value;
                    }}
                  />
                </div>

                <p style={{ margin: "15px 0px 10px 0px" }}>
                  SLA Level<font color="red">*</font>
                </p>

                <div style={{ width: "100%" }}>
                  <Select
                    className="select_SLA_addProject"
                    placeholder="Select SLA Level"
                    id="select_SLA_addProject"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "100%",
                        height: "40px",
                      }),
                    }}
                    options={selectSLA_LevelList}
                    value={selectSLA_LevelList.filter((item, key) => {
                      return item.value === Project_List.pr_id_sl;
                    })}
                    onChange={(e) => {
                      setProject_List({
                        ...Project_List,
                        pr_id_sl: e.value,
                      });
                    }}
                  />
                </div>
                <p style={{ margin: "15px 0px 10px 0px" }}>
                  Project Status<font color="red">*</font>
                </p>

                <div style={{ width: "100%" }}>
                  <Select
                    className="select_SLA_addProject"
                    placeholder="Select Project Status"
                    id="select_proj_status_addProject"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "100%",
                        height: "40px",
                      }),
                    }}
                    options={selectProjectStatusList}
                    value={selectProjectStatusList.filter((item, key) => {
                      return item.value === Project_List.pr_project_status_id;
                    })}
                    onChange={(e) => {
                      setProject_List({
                        ...Project_List,
                        pr_project_status_id: e.value,
                      });
                    }}
                  />
                </div>
                <p style={{ margin: "15px 0px 10px 0px" }}>
                  Create by<font color="red">*</font>
                </p>

                <Input disabled value={Project_List.pr_create_by}></Input>
                <p style={{ margin: "15px 0px 10px 0px" }}>
                  Create Date<font color="red">*</font>
                </p>

                <Input
                  disabled
                  value={moment(Project_List.pr_create_date).format(
                    "YYYY-MM-DD HH.mm"
                  )}
                ></Input>

                <p style={{ margin: "15px 0px 10px 0px" }}>
                  Maintenance Start Date
                </p>
                <DatePicker
                  style={{ width: "345px", height: "40px" }}
                  // renderExtraFooter={() => "extra footer"}
                  showTime
                  onChange={handleStartDateChange}
                  value={
                    Project_List.pr_maintenance_start_date
                      ? dayjs(Project_List.pr_maintenance_start_date)
                      : null
                  }
                  disabledDate={(current) =>
                    current && current < dayjs().startOf("day")
                  }
                />
                <p style={{ margin: "15px 0px 10px 0px" }}>
                  Maintenance End Date
                </p>
                <DatePicker
                  style={{ width: "345px", height: "40px" }}
                  // renderExtraFooter={() => "extra footer"}
                  showTime
                  onChange={handleEndDateChange}
                  value={
                    Project_List.pr_maintenance_end_date
                      ? dayjs(Project_List.pr_maintenance_end_date)
                      : null
                  }
                  disabledDate={(current) =>
                    current && current < dayjs().startOf("day")
                  }
                />
                <div className="col-12 mt-4">
                  <div
                    className="row"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      Member in Project <font color="red">*</font>
                    </span>
                    <Button
                      type="primary"
                      style={{
                        // backgroundColor: "#007BFF",
                        // border: "none",
                        // marginLeft: "20%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        backgroundColor: "#007BFF",
                        border: "none",
                      }}
                      onClick={() => {
                        SelectPositionFun();
                        showModal();
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-plus-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                      </svg>
                      {"  "}Add Member
                    </Button>
                  </div>
                </div>
                <div className="row mt-2 w-100">
                  {cardDataAddUser.map((card) => (
                    <>{card.content}</>
                  ))}
                  {/* <p></p> */}
                  {/* <Card key={card.id} style={{ width: "340px", height:"30px",marginBottom: "5px", border:"none" }}>
            </Card> */}
                </div>
              </div>
            </>
          )}
        </Drawer>
      </ConfigProvider>

      <Modal
        title={
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <span
              style={{
                fontWeight: "900",
                color: "#007BFF",
                background: "#007BFF",
              }}
            >
              |
            </span>
            <span style={{ fontWeight: "600", fontSize: "20px" }}>
              Add Member in Project
            </span>
          </div>
        }
        open={isModalOpen}
        // cancelButtonProps={{ hidden: true }}
        // cancelText={"Close"}
        cancelButtonProps={{ hidden: true }}
        okButtonProps={{ hidden: true }}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
      >
        <div className="row mt-3" style={{ justifyContent: "flex-end" }}>
          <div className="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5 mb-1">
            <div className="row mt-6">
              <p style={{ fontSize: "12px" }}>Search</p>
            </div>
            <div className="row-4" style={{ marginTop: "-10px" }}>
              <input
                style={{ marginLeft: "-9px" }}
                type="search"
                id="search-modal"
                className="form-control"
                placeholder="search"
                value={FilterUser.search_text}
                onChange={(e) => {
                  setFilterUser({
                    ...FilterUser,
                    search_text: e.target.value,
                  });
                }}
                onKeyPress={handleEnterKeyPressUser}
              />
            </div>
          </div>

          <div className="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 mb-1">
            <div className="row mt-6" style={{ marginLeft: "-6px" }}>
              <p style={{ fontSize: "12px" }}>Position</p>
            </div>
            <div
              className="row-4"
              style={{ marginLeft: "-8px", marginTop: "-10px" }}
            >
              <Select
                className="select_Position"
                placeholder="All"
                id="select_Position"
                styles={{}}
                options={SelectPosition}
                value={SelectPosition.find(
                  (option) => option.label === FilterUser.search_position
                )}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setFilterUser({
                      ...FilterUser,
                      search_position: selectedOption.label,
                    });
                    filterPermission(selectedOption.label);
                  } else {
                    setFilterUser({
                      ...FilterUser,
                      search_position: "",
                    });
                    filterPermission("");
                  }
                }}
                isClearable={true}
              />
            </div>
          </div>
        </div>
        <Table_All_Project
          key={"user"}
          currentPage={currentPageUser}
          pageSize={pageSizeUser}
          onChange={onChangePageUser}
          cardData={cardDataUser}
          onPageChange={handlePageChangeUser}
          onShowSizeChange={onShowSizeChangeUser}
          total={totalUser}
          minWidth={"650px"}
        />
        <div className="row mt-3"></div>
      </Modal>
    </div>
  );
}
export default All_Project;
