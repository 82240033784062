import React, { useEffect, useState, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import NumberFormat from "react-number-format";
import axios from "axios";
import Configs from "../../../../config";
import { getOem, getToken, getUser } from "../../../../Utils/Common";
import Swal from "sweetalert2";
import { Modal, Button } from "reactstrap";

const Tab = ({
  tab,
  handleTabs,
  idx,
  tabOld,
  mode,
  sizeUnit,
  warehouse_id,
}) => {

  // console.log("tab", tab);
  // console.log("tabOld", tabOld);
  // console.log("idx", idx);
  // console.log("mode", mode);
  const [Mode, setMode] = useState(false);
  //set test
  const [test, setTest] = useState("");

  const dropdownRef = useRef(null);
  const [zIndexDD, setZIndexDD] = useState({
    ref_size: 0,
  });

  // เปิด-ปิด modal
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const handleOpenModal1 = async () => {
    setShowModal1(true);
  };
  const handleCloseModal1 = async () => {
    setShowModal1(false);
  };
  const handleOpenModal2 = async () => {
    setShowModal2(true);
  };
  const handleCloseModal2 = async () => {
    setShowModal2(false);
  };

  // dropdown ของ Position Format
  const [positionFormat, setpositionFormat] = useState([
    { value: "1", label: "Alphabet" },
    { value: "2", label: "Number" },
  ]);
  // dropdown ของ ref size
  const [refSize, setRefSize] = useState([
    { value: "2x2", label: "2x2" },
    { value: "2x3", label: "2x3" },
    { value: "2x4", label: "2x4" },
    { value: "3x2", label: "3x2" },
    { value: "3x3", label: "3x3" },
    { value: "3x4", label: "3x4" },
    { value: "4x2", label: "4x2" },
    { value: "4x3", label: "4x3" },
    { value: "4x4", label: "4x4" },
  ]);

  // state เก็บ index ของ position ที่กด add product
  const [positionIndex, setPositionIndex] = useState(0);
  // state เก็บ mode ของ modal
  const [modeModal, setModeModal] = useState("");

  // add product modal section
  // state เก็บ product ที่กด save มาจาก select product modal
  const [saveProduct, setSaveProduct] = useState([]);
  // const [saveProductAll, setSaveProductAll] = useState([]);
  // select product modal section
  // state เก็บ filter product
  const [filterProduct, setFilterProduct] = useState({
    filter: "",
  });
  // state เก็บข้อมูล all product
  const [productData, setProductData] = useState([]);
  // state เก็บ product ที่เลือกเเล้ว
  const [selectedProduct, setSelectedProduct] = useState([]);
  // const [selectedProductAll, setSelectedProductAll] = useState([]);
  const [Axis, setAxis] = useState({
    x: tab?.rack_axis_x || 0,
    y: tab?.rack_axis_y || 0,
    z: tab?.rack_axis_z || 0,
    is_z: tab?.rack_is_axis_z || false,
  });
  function formatNumber(num) {
    if (num === null) {
      return "";
    } else {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
  }

  // Table นอกสุด
  const rowsPositionData = [];
  for (let index = 0; index < tab?.tableArray?.length; index += 1) {
    const rowItem = {};
    rowItem["no"] = <div style={{ textAlign: "left" }}>{index + 1}</div>;
    rowItem["position"] = (
      <div style={{ textAlign: "left" }}>
        {tab?.tableArray[index]?.position}
      </div>
    );
    rowItem["position2"] = tab?.tableArray[index]?.position;
    rowItem["product"] = (
      <div style={{ textAlign: "left", whiteSpace: "pre-line" }}>
        {tab?.tableArray[index]?.product?.length > 0
          ? tab?.tableArray[index]?.product
              ?.map((item) => {
                return item.no + " (" + formatNumber(item.quantity) + ")";
              })
              .join("\n")
          : ""}
      </div>
    );
    rowItem["product2"] = tab?.tableArray[index]?.product?.map((item) => {
      return item.no + " (" + formatNumber(item.quantity) + ")";
    });
    rowItem["ref_size"] = (
      <div style={{ textAlign: "center", minWidth: "100px" }}>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <Select
                isClearable
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                ref={dropdownRef}
                id={"ref_size" + index}
                options={refSize}
                value={
                  refSize.find(
                    (e) => e.value === tab?.tableArray[index]?.ref_size
                  ) || { label: "Select", value: "" }
                }
                onChange={async (e) => {
                  if (e !== null) {
                    await updateTabsData(
                      "tableArray",
                      e.value,
                      "ref_size",
                      index
                    );
                  } else {
                    await updateTabsData("tableArray", null, "ref_size", index);
                  }
                }}
                isDisabled={Mode}
              />
            </div>
          </div>
        </div>
      </div>
    );
    rowItem["management"] = (
      <div className="row" style={{ flexWrap: "nowrap" }}>
        <>
          <div
            className="col-xl-3 col-md-3 col-xs-3 "
            style={{
              alignItems: "center",
            }}
          >
            <a
              id="btn_add"
              // className="btn btn-block btn-primary"
              type="button"
              // data-toggle="modal"
              // data-target={"#modal-add-product" + idx}
              // hidden={disable}
              onClick={async () => {
                setPositionIndex(index);
                setModeModal("view");
                let temp = JSON.parse(
                  JSON.stringify(tab?.tableArray[index]?.product)
                );
                setSaveProduct(temp);
                // let tempAll = JSON.parse(
                //   JSON.stringify(tab?.tableArray.map((e) => e.product).flat())
                // );
                // setSaveProductAll(tempAll);
                handleOpenModal1();
              }}
              style={{
                marginTop: "-4px",
              }}
              hidden={Mode}
            >
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "14px",
                }}
              ></i>
            </a>
          </div>
          <div
            className="col-xl-3 col-md-3 col-xs-3 "
            style={{
              alignItems: "center",
            }}
          >
            <a
              id="btn_add"
              // className="btn btn-block btn-primary"
              type="button"
              // data-toggle="modal"
              // data-target={"#modal-add-product" + idx}
              // hidden={disable}
              onClick={async () => {
                setPositionIndex(index);
                setModeModal("edit");
                let temp = JSON.parse(
                  JSON.stringify(tab?.tableArray[index]?.product)
                );
                setSaveProduct(temp);
                // let tempAll = JSON.parse(
                //   JSON.stringify(tab?.tableArray.map((e) => e.product).flat())
                // );
                // setSaveProductAll(tempAll);
                handleOpenModal1();
              }}
              style={{
                marginTop: "-4px",
              }}
              hidden={Mode}
            >
              <i
                className="fas fa-pencil-alt"
                style={{
                  fontSize: "14px",
                }}
              ></i>
            </a>
          </div>
        </>
      </div>
    );
    rowsPositionData.push(rowItem);
  }
  const colPositionData = {
    columns: [
      {
        label: <div style={{ textAlign: "left" }}>{"No."}</div>,
        field: "no",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Position"}</div>,
        field: "position",
        width: 50,
      },
      {
        label: (
          <div style={{ textAlign: "left" }}>
            {"Product No. ( Max Capacity )"}
          </div>
        ),
        field: "product",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Ref. Size"}</div>,
        field: "ref_size",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Management"}</div>,
        field: "management",
        width: 50,
      },
    ],
    rows: rowsPositionData,
  };

  // Table ใน Modal Add product
  const rowsProductData = [];
  for (let index = 0; index < saveProduct?.length; index += 1) {
    const rowItem = {};
    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;
    rowItem["product"] = (
      <div style={{ textAlign: "center" }}>{saveProduct[index].no}</div>
    );
    rowItem["product_name"] = (
      <div style={{ textAlign: "center" }}>{saveProduct[index].name}</div>
    );
    rowItem["quantity"] = (
      <div style={{ textAlign: "center" }}>
        <NumberFormat
          allowNegative={false}
          thousandSeparator={true}
          thousandsGroupStyle="thousand"
          decimalScale={2}
          type="text"
          className="form-control"
          required
          value={saveProduct[index].quantity}
          onValueChange={(values) => {
            const { formattedValue, value } = values;
            let temp = saveProduct;
            temp[index].quantity = value;
            setSaveProduct(temp);
            setTest(value);
          }}
          readOnly={modeModal === "view" ? true : false}
        />
      </div>
    );
    rowItem["management"] = (
      <div
        className="row justify-content-center"
        style={{ flexWrap: "nowrap" }}
      >
        <a
          id="btn_delete"
          className=" btn btn-xs "
          onClick={() => {
            deleteAddProduct(index);
          }}
          hidden={modeModal === "view" ? true : false}
        >
          <i className="fas fa-trash-alt"></i>
        </a>
      </div>
    );
    rowsProductData.push(rowItem);
  }

  const colProductData = {
    columns: [
      {
        label: <div style={{ textAlign: "center" }}>{"No."}</div>,
        field: "no",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "center" }}>{"Product No."}</div>,
        field: "product",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "center" }}>{"Product Name"}</div>,
        field: "product_name",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "center" }}>{"Quantity"}</div>,
        field: "quantity",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "center" }}>{"Management"}</div>,
        field: "management",
        width: 50,
      },
    ],
    rows: rowsProductData,
  };

  // Table ใน Modal Select product
  const rowsSelectProductData = [];
  for (let index = 0; index < productData?.length; index += 1) {
    const rowItem = {};
    rowItem["select"] = (
      <div style={{ textAlign: "center" }}>
        <input
          type="radio"
          id={`customCheckbox_${idx}_${index}`}
          checked={selectedProduct.some((e) => e.id === productData[index].id)}
          // disabled={selectedProductAll.some((e) => e.id === productData[index].id)}
          onChange={(e) => {
            // if (e.target.checked) {
            //   setSelectedProduct([...selectedProduct, productData[index]]);
            // } else {
            //   setSelectedProduct(
            //     selectedProduct.filter((e) => e.id !== productData[index].id)
            //   );
            // }
            setSelectedProduct([productData[index]]);
          }}
        />
      </div>
    );
    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;
    rowItem["product_no"] = (
      <div style={{ textAlign: "center" }}>{productData[index].no}</div>
    );
    rowItem["product_name"] = (
      <div style={{ textAlign: "center" }}>{productData[index].name}</div>
    );

    rowsSelectProductData.push(rowItem);
  }

  const colSelectProductData = {
    columns: [
      {
        label: <div style={{ textAlign: "center" }}>{"Select"}</div>,
        field: "select",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "center" }}>{"No."}</div>,
        field: "no",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "center" }}>{"Product No."}</div>,
        field: "product_no",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "center" }}>{"Product Name"}</div>,
        field: "product_name",
        width: 50,
      },
    ],
    rows: rowsSelectProductData,
  };

  // ฟังก์ชั่นที่ใช้ในการอัพเดทข้อมูลของแท็บ
  async function updateTabsData(params, value, param2, posIndex) {
    if (params === "tableArray" || params === "tableArrayDup") {
      let posTempIndex = null;
      if (posIndex === undefined) {
        posTempIndex = positionIndex;
      } else {
        posTempIndex = posIndex;
      }
      const tabTemp = tab;
      tabTemp[params][posTempIndex][param2] = value;
      await handleTabs(idx, tabTemp, true);
    } else {
      const tabTemp = tab;
      tabTemp[params] = value;
      await handleTabs(idx, tabTemp);
    }
  }

  // getProduct
  async function getProduct() {
    let tempfilter = {
      filter: filterProduct.filter?.trim() || null,
      // company_id: getUser().com,
      oem_id: getOem(),
    };
    await axios
      .request({
        method: "post",
        url: Configs.API_URL + "/product/filterproductformodal",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: tempfilter,
      })
      .then((response) => {
        setProductData(response.data);
      })
      .catch((error) => {
        Swal.fire("Error", "Cannot get product", "error");
      });
  }

  async function clearFilterProduct() {
    setFilterProduct({
      filter: "",
    });
    filterProduct.filter = "";
    await getProduct();
  }

  async function saveProductFunc() {
    let temp = selectedProduct;
    // let tempAll = selectedProductAll;
    for (let index = 0; index < temp.length; index++) {
      if (temp[index].quantity) {
        temp[index].quantity = parseInt(temp[index].quantity);
      } else {
        temp[index].quantity = "";
      }

      // let checkIsInAll = saveProductAll.findIndex( e => e.id === temp[index].id);
      // if(checkIsInAll === -1){
      //   tempAll.push({
      //     id: temp[index].id,
      //     no: temp[index].no,
      //     name: temp[index].name,
      //     quantity: temp[index].quantity,
      //   });
      // }
    }
    setSaveProduct(temp);
    // setSaveProductAll(tempAll);
  }

  async function compareSelectedProduct() {
    setSelectedProduct(saveProduct);
    // filter all product that have selected in all position
    // setSelectedProductAll(saveProductAll);
  }

  async function confirmProduct() {
    // check quantity is not 0 or null or ""
    let temp = saveProduct;
    let error = [];
    for (let index = 0; index < temp.length; index++) {
      if (
        Number(temp[index].quantity) === 0 ||
        temp[index].quantity === null ||
        temp[index].quantity === ""
      ) {
        error.push(temp[index].no);
      }
    }
    if (error.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: `กรุณากรอกจำนวนของ Product เหล่านี้ ให้มากกว่า 0<br>${error.join(
          "<br>"
        )}`,
      });
    } else {
      let checkHas = await checkHasProductInStock(saveProduct, "quantity");
      if (!checkHas) {
        Swal.fire(
          "Error",
          "ไม่สามารถใส่จำนวน Quantity ต่ำกว่า ของที่มีในสต็อก",
          "error"
        );
      } else {
        await handleCloseModal1();
        await updateTabsData("tableArray", saveProduct, "product");
        await updateTabsData("tableArrayDup", saveProduct, "product");
      }
    }
  }

  async function deleteAddProduct(index) {
    let checkHas = await checkHasProductInStock(saveProduct, "delete");
    if (!checkHas) {
      Swal.fire("Error", "ลบไม่ได้ เพราะ มีของในสต็อกอยู่", "error");
      return;
    } else {
      let prdId = saveProduct[index].id;
      setSaveProduct(saveProduct.filter((e) => e.id !== prdId));
    }
    // setSaveProductAll(saveProductAll.filter((e) => e.id !== prdId));
  }

  async function checkHasProductInStock(data, method, rowsNumber) {
    if (method === "delete") {
      let temp = data;
      for (let index = 0; index < temp.length; index++) {
        if (temp[index].is_in_stock || Number(temp[index].in_stock || 0) > 0) {
          return false;
        }
      }
    }
    if (method === "quantity") {
      let temp = data;
      for (let index = 0; index < temp.length; index++) {
        if (
          Number(temp[index].quantity || 0) < Number(temp[index].in_stock || 0)
        ) {
          return false;
        }
      }
    }
    if (
      method === "rack_axis_x" ||
      method === "rack_axis_y" ||
      method === "rack_axis_z" ||
      method === "rack_is_axis_z"
    ) {
      let temp = data;
      for (let index = 0; index < temp.tableArray.length; index++) {
        if (index > rowsNumber - 1) {
          for (
            let index2 = 0;
            index2 < temp.tableArray[index].product.length;
            index2++
          ) {
            if (
              temp.tableArray[index].product[index2].is_in_stock ||
              Number(temp.tableArray[index].product[index2].in_stock || 0) > 0
            ) {
              return false;
            }
          }
        }
      }
    }
    return true;
  }

  useEffect(() => {
    if (mode === "View") {
      setMode(true);
    } else {
      setMode(false);
    }
  }, [mode]);

  return (
    <section className="content pl-4 pr-4">
      <div className="card card-primary card-outline card-outline-tabs">
        <div className="card-body">
          <div className="tab-content" id="custom-tabs-four-tabContent">
            <div
              className="tab-pane fade show active"
              id={`custom-tabs-four-page-${tab.rack_name}`}
              role="tabpanel"
              aria-labelledby={`custom-tabs-four-page-${tab.rack_name}-tab`}
            >
              <div className="row">
                <div className="col-12 col-md-4 col-xl-4 ">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={tab?.rack_name}
                      onChange={(e) => {
                        updateTabsData("rack_name", e.target.value?.trim());
                      }}
                      required
                      disabled={Mode}
                    />
                    <label>
                      Rack Name{" "}
                      {tab?.rack_name?.trim() === "" ? (
                        <span style={{ color: "red" }}>*</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 col-xl-4 ">
                  <div className="form-group">
                    <NumberFormat
                      allowNegative={false}
                      thousandSeparator={true}
                      thousandsGroupStyle="thousand"
                      decimalScale={2}
                      type="text"
                      className="form-control"
                      required
                      value={tab?.rack_width}
                      onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        updateTabsData("rack_width", value);
                      }}
                      disabled={Mode}
                    />
                    <label>
                      Width({sizeUnit}){" "}
                      {tab?.rack_width?.trim() === "" ? (
                        <span style={{ color: "red" }}>*</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-xl-4 ">
                  <div className="form-group">
                    <NumberFormat
                      allowNegative={false}
                      thousandSeparator={true}
                      thousandsGroupStyle="thousand"
                      decimalScale={2}
                      type="text"
                      className="form-control"
                      required
                      value={tab?.rack_depth}
                      onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        updateTabsData("rack_depth", value);
                      }}
                      disabled={Mode}
                    />
                    <label>
                      Depth({sizeUnit}){" "}
                      {tab?.rack_depth?.trim() === "" ? (
                        <span style={{ color: "red" }}>*</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-xl-4 ">
                  <div className="form-group">
                    <NumberFormat
                      allowNegative={false}
                      thousandSeparator={true}
                      thousandsGroupStyle="thousand"
                      decimalScale={2}
                      type="text"
                      className="form-control"
                      required
                      value={tab?.rack_height}
                      onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        updateTabsData("rack_height", value);
                      }}
                      disabled={Mode}
                    />
                    <label>
                      Height({sizeUnit}){" "}
                      {tab?.rack_height?.trim() === "" ? (
                        <span style={{ color: "red" }}>*</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 col-xl-4">
                  <div className="form-group">
                    <NumberFormat
                      allowNegative={false}
                      thousandSeparator={true}
                      thousandsGroupStyle="thousand"
                      decimalScale={0}
                      type="text"
                      className="form-control"
                      required
                      value={tab?.rack_axis_x}
                      onValueChange={async (values) => {
                        const { formattedValue, value } = values;
                        let json = JSON.parse(JSON.stringify(tab));
                        let jsonOld = tabOld.length > 0 && tabOld[idx] ? JSON.parse(JSON.stringify(tabOld[idx])) : [];
                        let new_rowsNumber =
                          Number(value || 0) *
                          Number(json.rack_axis_y || 0) *
                          (json.rack_is_axis_z
                            ? Number(json.rack_axis_z || 0)
                            : 1);
                        let old_rowsNumber =
                          Number(jsonOld?.rack_axis_x || 0) *
                          Number(jsonOld?.rack_axis_y || 0) *
                          (jsonOld?.rack_is_axis_z
                            ? Number(jsonOld?.rack_axis_z || 0)
                            : 1);
                        if (value !== "") {
                          if (
                            Number(value || 0) <
                              Number(jsonOld?.rack_axis_x || 0) ||
                            new_rowsNumber < old_rowsNumber
                          ) {
                            let checkHas = await checkHasProductInStock(
                              jsonOld,
                              "rack_axis_x",
                              new_rowsNumber
                            );
                            if (!checkHas) {
                              Swal.fire(
                                "Error",
                                "ไม่สามารถเปลี่ยน Axis ได้ เพราะ มีของในสต็อกอยู่",
                                "error"
                              );
                              await updateTabsData(
                                "rack_axis_x",
                                String(Number(Axis.x || 0))
                              );
                            } else {
                              await updateTabsData("rack_axis_x", value);
                              setAxis({
                                x: value,
                                y: tab?.rack_axis_y || 0,
                                z: tab?.rack_axis_z || 0,
                                is_z: tab?.rack_is_axis_z || false,
                              });
                              Axis.x = value;
                            }
                          } else {
                            await updateTabsData("rack_axis_x", value);
                            setAxis({
                              x: value,
                              y: tab?.rack_axis_y || 0,
                              z: tab?.rack_axis_z || 0,
                              is_z: tab?.rack_is_axis_z || false,
                            });
                            Axis.x = value;
                          }
                        } else {
                          await updateTabsData("rack_axis_x", value);
                        }
                      }}
                      disabled={Mode}
                    />
                    <label>
                      Axis-X{" "}
                      {tab?.rack_axis_x?.trim() === "" ? (
                        <span style={{ color: "red" }}>*</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-xl-4">
                  <div className="form-group">
                    <Select
                      value={
                        positionFormat.find(
                          (e) => e.value === tab?.rack_position_format_x
                        ) || { label: "Alphabet", value: "Alphabet" }
                      }
                      options={positionFormat}
                      onChange={(e) => {
                        updateTabsData("rack_position_format_x", e.value);
                      }}
                      isDisabled={Mode}
                    />
                    <label>Position Format</label>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-xl-4 ">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={tab?.rack_separate_char_x}
                      required
                      onChange={(e) => {
                        if (
                          /^[-/!@#$%^&*(),.?":{}|<>]+$/.test(
                            e.target.value?.trim()
                          ) ||
                          e.target.value?.trim() === ""
                        ) {
                          updateTabsData(
                            "rack_separate_char_x",
                            e.target.value?.trim()
                          );
                        }
                      }}
                      disabled={Mode}
                    />
                    <label>Seperate Char</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 col-xl-4">
                  <div className="form-group">
                    <NumberFormat
                      allowNegative={false}
                      thousandSeparator={true}
                      thousandsGroupStyle="thousand"
                      decimalScale={0}
                      type="text"
                      className="form-control"
                      required
                      value={tab?.rack_axis_y}
                      onValueChange={async (values) => {
                        const { formattedValue, value } = values;
                        let json = JSON.parse(JSON.stringify(tab));
                        let jsonOld = tabOld.length > 0 && tabOld[idx] ? JSON.parse(JSON.stringify(tabOld[idx])) : [];
                        let new_rowsNumber =
                          Number(json.rack_axis_x || 0) *
                          Number(value || 0) *
                          (json.rack_is_axis_z
                            ? Number(json.rack_axis_z || 0)
                            : 1);
                        let old_rowsNumber =
                          Number(jsonOld.rack_axis_x || 0) *
                          Number(jsonOld.rack_axis_y || 0) *
                          (jsonOld.rack_is_axis_z
                            ? Number(jsonOld.rack_axis_z || 0)
                            : 1);
                        if (value !== "") {
                          if (
                            Number(value || 0) <
                              Number(jsonOld.rack_axis_y || 0) ||
                            new_rowsNumber < old_rowsNumber
                          ) {
                            let checkHas = await checkHasProductInStock(
                              jsonOld,
                              "rack_axis_y",
                              new_rowsNumber
                            );
                            if (!checkHas) {
                              Swal.fire(
                                "Error",
                                "ไม่สามารถเปลี่ยน Axis ได้ เพราะ มีของในสต็อกอยู่",
                                "error"
                              );
                              await updateTabsData(
                                "rack_axis_y",
                                String(Number(Axis.y || 0))
                              );
                            } else {
                              await updateTabsData("rack_axis_y", value);
                              setAxis({
                                x: tab?.rack_axis_x || 0,
                                y: value,
                                z: tab?.rack_axis_z || 0,
                                is_z: tab?.rack_is_axis_z || false,
                              });
                              Axis.y = value;
                            }
                          } else {
                            await updateTabsData("rack_axis_y", value);
                            setAxis({
                              x: tab?.rack_axis_x || 0,
                              y: value,
                              z: tab?.rack_axis_z || 0,
                              is_z: tab?.rack_is_axis_z || false,
                            });
                            Axis.y = value;
                          }
                        } else {
                          await updateTabsData("rack_axis_y", value);
                        }
                      }}
                      disabled={Mode}
                    />
                    <label>
                      Axis-Y{" "}
                      {tab?.rack_axis_y?.trim() === "" ? (
                        <span style={{ color: "red" }}>*</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-xl-4">
                  <div className="form-group">
                    <Select
                      value={
                        positionFormat.find(
                          (e) => e.value === tab?.rack_position_format_y
                        ) || { label: "Alphabet", value: "Alphabet" }
                      }
                      options={positionFormat}
                      onChange={(e) => {
                        updateTabsData("rack_position_format_y", e.value);
                      }}
                      isDisabled={Mode}
                    />
                    <label>Position Format</label>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-xl-4 ">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={tab?.rack_separate_char_y}
                      onChange={(e) => {
                        if (
                          /^[-/!@#$%^&*(),.?":{}|<>]+$/.test(
                            e.target.value?.trim()
                          ) ||
                          e.target.value?.trim() === ""
                        ) {
                          updateTabsData(
                            "rack_separate_char_y",
                            e.target.value?.trim()
                          );
                        }
                      }}
                      required
                      disabled={Mode}
                    />
                    <label>Seperate Char</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-3 col-xl-3">
                  {/* <div className="form-group"> */}
                  <div className="custom-control custom-checkbox">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      id={"customCheckbox" + idx}
                      key={idx}
                      checked={tab?.rack_is_axis_z}
                      onChange={async (e) => {
                        let temptab = JSON.parse(JSON.stringify(tab));
                        let temptabOld = tabOld.length > 0 ? JSON.parse(JSON.stringify(tabOld)) : [];

                        let new_rowsNumber =
                          Number(temptab?.rack_axis_x || 0) *
                          Number(temptab?.rack_axis_y || 0) *
                          1;
                        let old_rowsNumber =
                          Number(temptabOld[idx]?.rack_axis_x || 0) *
                          Number(temptabOld[idx]?.rack_axis_y || 0) *
                          (temptabOld[idx]?.rack_is_axis_z
                            ? Number(temptabOld[idx]?.rack_axis_z || 0)
                            : 1);
                        if (
                          new_rowsNumber < old_rowsNumber &&
                          temptab?.rack_is_axis_z
                        ) {
                          let checkHas = await checkHasProductInStock(
                            temptabOld.length > 0 ? temptabOld[idx] : {},
                            "rack_is_axis_z",
                            new_rowsNumber
                          );
                          if (!checkHas) {
                            Swal.fire(
                              "Error",
                              "ไม่สามารถเปลี่ยน Axis ได้ เพราะ มีของในสต็อกอยู่",
                              "error"
                            );
                            await updateTabsData("rack_is_axis_z", Axis.is_z);
                          } else {
                            await updateTabsData(
                              "rack_is_axis_z",
                              !e.target.checked || false
                            );
                            setAxis({
                              x: temptab?.rack_axis_x || 0,
                              y: temptab?.rack_axis_y || 0,
                              z: temptab?.rack_axis_z || 0,
                              is_z: !e.target.checked || false,
                            });
                            Axis.is_z = !e.target.checked || false;
                          }
                        } else {
                          await updateTabsData(
                            "rack_is_axis_z",
                            e.target.checked
                          );
                          setAxis({
                            x: temptab?.rack_axis_x || 0,
                            y: temptab?.rack_axis_y || 0,
                            z: temptab?.rack_axis_z || 0,
                            is_z: e.target.checked || false,
                          });
                          Axis.is_z = e.target.checked || false;

                          if (!e.target.checked) {
                            await updateTabsData("rack_axis_z", "");
                          }

                        }
                      }}
                      disabled={Mode}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`customCheckbox${idx}`}
                    >
                      Use Axis-Z
                    </label>
                  </div>
                  {/* </div> */}
                </div>
              </div>

              <br></br>

              {tab?.rack_is_axis_z ? (
                <>
                  <div className="row" style={{ marginTop: "0.5rem" }}>
                    <div className="col-12 col-md-4 col-xl-4">
                      <div className="form-group">
                        <NumberFormat
                          allowNegative={false}
                          thousandSeparator={true}
                          thousandsGroupStyle="thousand"
                          decimalScale={0}
                          type="text"
                          className="form-control"
                          required
                          value={tab?.rack_axis_z}
                          onValueChange={async (values) => {
                            const { formattedValue, value } = values;
                            let json = JSON.parse(JSON.stringify(tab));
                            let jsonOld = tabOld.length > 0 && tabOld[idx] ? JSON.parse(JSON.stringify(tabOld[idx])) : [];
                            let new_rowsNumber =
                              Number(json.rack_axis_x || 0) *
                              Number(json.rack_axis_y || 0) *
                              Number(value || 0);
                            let old_rowsNumber =
                              Number(jsonOld.rack_axis_x || 0) *
                              Number(jsonOld.rack_axis_y || 0) *
                              (jsonOld.rack_is_axis_z
                                ? Number(jsonOld.rack_axis_z || 0)
                                : 1);
                            if (value !== "") {
                              if (
                                Number(value || 0) <
                                  Number(jsonOld.rack_axis_z || 0) ||
                                new_rowsNumber < old_rowsNumber
                              ) {
                                let checkHas = await checkHasProductInStock(
                                  jsonOld,
                                  "rack_axis_z",
                                  new_rowsNumber
                                );
                                if (!checkHas) {
                                  Swal.fire(
                                    "Error",
                                    "ไม่สามารถเปลี่ยน Axis ได้ เพราะ มีของในสต็อกอยู่",
                                    "error"
                                  );
                                  await updateTabsData(
                                    "rack_axis_z",
                                    String(Number(Axis.z || 0))
                                  );
                                } else {
                                  await updateTabsData("rack_axis_z", value);
                                  setAxis({
                                    x: tab?.rack_axis_x || 0,
                                    y: tab?.rack_axis_y || 0,
                                    z: value,
                                    is_z: tab?.rack_is_axis_z || false,
                                  });
                                  Axis.z = value;
                                }
                              } else {
                                await updateTabsData("rack_axis_z", value);
                                setAxis({
                                  x: tab?.rack_axis_x || 0,
                                  y: tab?.rack_axis_y || 0,
                                  z: value,
                                  is_z: tab?.rack_is_axis_z || false,
                                });
                                Axis.z = value;
                              }
                            } else {
                              await updateTabsData("rack_axis_z", value);
                            }
                          }}
                          disabled={Mode}
                        />
                        <label>
                          Axis-Z{" "}
                          {tab?.rack_axis_z?.trim() === "" ? (
                            <span style={{ color: "red" }}>*</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-xl-4">
                      <div className="form-group">
                        <Select
                          value={
                            positionFormat.find(
                              (e) => e.value === tab?.rack_position_format_z
                            ) || { label: "Alphabet", value: "Alphabet" }
                          }
                          options={positionFormat}
                          onChange={(e) => {
                            updateTabsData("rack_position_format_z", e.value);
                          }}
                          isDisabled={Mode}
                        />
                        <label>Position Format</label>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-xl-4 ">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={tab?.rack_separate_char_z}
                          onChange={(e) => {
                            if (
                              /^[-/!@#$%^&*(),.?":{}|<>]+$/.test(
                                e.target.value?.trim()
                              ) ||
                              e.target.value?.trim() === ""
                            ) {
                              updateTabsData(
                                "rack_separate_char_z",
                                e.target.value?.trim()
                              );
                            }
                          }}
                          required
                          disabled={Mode}
                        />
                        <label>Seperate Char</label>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div
                className="table-responsive"
                style={{ whiteSpace: "nowrap" }}
              >
                <div className="row" style={{ marginTop: "2rem" }}>
                  <div className="col-12 col-md-12 col-xl-12">
                    <MDBDataTable
                      sortable={false}
                      className="table table-head-fixed"
                      disableRetreatAfterSorting={true}
                      striped
                      bordered
                      hover
                      data={colPositionData}
                      // noBottomColumns
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal Add Product */}
      <Modal
        isOpen={showModal1}
        toggle={handleCloseModal1}
        className="modal-dialog modal-xl"
        size="xl"
      >
        <div className="modal-header">
          <h4 className="modal-label">Position Detail</h4>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() => {
              handleCloseModal1();
            }}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-4 col-md-2 col-xl-2">
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-block btn-primary"
                  onClick={async () => {
                    setFilterProduct({
                      filter: "",
                    });
                    filterProduct.filter = "";
                    await getProduct();
                    await compareSelectedProduct();
                    await handleOpenModal2();
                  }}
                  hidden={modeModal === "view" ? true : false}
                  disabled={saveProduct.length > 0}
                >
                  Add Product
                </button>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <MDBDataTable
                  sortable={false}
                  className="table table-head-fixed"
                  disableRetreatAfterSorting={true}
                  striped
                  bordered
                  hover
                  data={colProductData}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={async () => {
              await confirmProduct();
            }}
            hidden={modeModal === "view" ? true : false}
          >
            Save
          </button>

          <button
            type="button"
            className="btn btn-danger"
            onClick={() => {
              handleCloseModal1();
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>

      {/* Modal Select Product */}
      <Modal
        isOpen={showModal2}
        toggle={handleCloseModal2}
        className="modal-dialog modal-xl"
        size="xl"
      >
        <div className="modal-header">
          <h4 className="modal-label">Select Product</h4>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() => {
              handleCloseModal2();
            }}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-12 col-md-4 col-xl-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  value={filterProduct.filter}
                  onChange={(e) => {
                    setFilterProduct({
                      ...filterProduct,
                      filter: e.target.value,
                    });
                  }}
                />
                <label>Search</label>
              </div>
            </div>
            <div className="col-4 col-md-2 col-xl-2">
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-block btn-info"
                  onClick={async () => {
                    await getProduct();
                  }}
                >
                  Search
                </button>
              </div>
            </div>
            <div className="col-4 col-md-2 col-xl-2">
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-block btn-info"
                  onClick={async () => {
                    await clearFilterProduct();
                  }}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <MDBDataTable
                  sortable={false}
                  className="table table-head-fixed"
                  disableRetreatAfterSorting={true}
                  striped
                  bordered
                  hover
                  data={colSelectProductData}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={async () => {
              await saveProductFunc();
              await handleCloseModal2();
            }}
          >
            Save
          </button>

          <button
            type="button"
            className="btn btn-danger"
            onClick={() => {
              handleCloseModal2();
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </section>
  );
};

export default Tab;
