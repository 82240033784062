import React, { useState, useEffect } from "react";
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    // Colors,
    Ticks

} from "chart.js";
import { CrosshairPlugin } from "chartjs-plugin-crosshair";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {

    LoadingOutlined,

} from '@ant-design/icons';


function Pie_Chart(params) {
    ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend, ChartDataLabels);
    ChartJS.unregister(CrosshairPlugin);

    let sum = params.dataChart.datasets[0].data?.reduce((acc, val) => acc + val, 0);
    const options = {
        responsive: true,
        plugins: {
            datalabels: {
                formatter: (value, context) => {
                    const total = context.chart.data.datasets[0].data.reduce((sum, value) => sum + (Number(value || 0)), 0);
                    const percentage = ((Number(value || 0)) / total * 100).toFixed(2);
                    return `${percentage}%`;
                },
                color: '#000', // สีของข้อความในแผนภูมิ
                font: {
                    weight: 'bold',
                    size: 12
                }
            },
            legend: {
                display: false,
                position: params.legendPos, // ตั้งตำแหน่งของตำนานไปที่ด้านขวา
                labels: {
                    boxWidth: 20, // ขนาดของสี่เหลี่ยมสีก่อนข้อความ
                    padding: 10 // การจัดระยะห่างระหว่างรายการ
                }
            }
        }
    };

    return (
        <>  
            <div style={{position: 'relative', top: `${params.mode == 'main' ? '20px' : '0px'}`, width: '100%', textAlign: 'center'}}>
                <div style={{ fontSize: `${params.mode == 'main' ? '18px' : '14px'}`, fontWeight: 600 }}>{params.title}</div>
            </div>
            {params.loading ? <>
                <div style={{ height: `${params.mode == 'main' ? '308px' : params.mode == 'sec' ? '247px' : '145px'}`, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <LoadingOutlined />
                </div>
            </> :
                sum > 0 ?
                    <>
                        <Pie data={params.dataChart} options={options} />
                    </>
                    :
                    <>
                        <div style={{ height: `${params.mode == 'main' ? '308px' : params.mode == 'sec' ? '247px' : '145px'}`, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            NO DATA
                        </div>
                    </>}
        </>
    );
}
export default Pie_Chart;
