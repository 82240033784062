import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import NumberFormat from "react-number-format";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser, getAcademy } from "../../Utils/Common";
import Select from "react-select";
import { userdefault_img, logoTTT } from "../../routes/imgRoute/imgUrl";
import moment from "moment";
import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  RightSidebarHeader,
  DateHeader,
} from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import DatePicker, { registerLocale } from "react-datepicker";


function Company_time_attendance(params) {
  const [test, settest] = useState({
    test: "",
  });
  const [company, setCompany] = useState([]);
  const [company_params, setcompany_params] = useState({
    company_id: getUser().com,
    day_hour_start: "day_hour_start",
    day_hour_start_value: new Date('July 1, 1999, 12:00'),
    oem_id: getOem(),
    rate_time_absent: "rate_time_absent",
    rate_time_absent_value: "",
  });
  const [period_time, setperiod_time] = useState({
    data: [],
  });
  const [work_type, setwork_type] = useState();
  const [period_timeDetail, setperiod_timeDetail] = useState([]);
  const [period_time_group, setperiod_time_group] = useState([]);
  const [period_time_item, setperiod_time_item] = useState([]);

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
        data-provide="datepicker"
        data-date-language="th-th"
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>

        {/*  <label>test</label> */}
      </div>
    </div>
  );
  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        /*   console.log(response.data); */
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetCompanyParams = async () => {
    var temp = {
      company_id: getUser().com,
      oem_id: getOem(),
    };
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement + "/api/hrManagement/getHrCompanyParams",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        console.log(response.data.data);
        setcompany_params({
          ...company_params,
          day_hour_start: response.data.data.day_hour_start,
          day_hour_start_value: new Date('July 1, 2023,'+response.data.data.day_hour_start_value),

          rate_time_absent: response.data.data.rate_time_absent,
          rate_time_absent_value: response.data.data.rate_time_absent_value,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function setTimeView(data) {
    /*         console.log("id",data)
        console.log(period_time) */
    var data_target = period_time.data.filter((e) => {
      return e.id === data;
    });
    /*       console.log(data_target); */
    /*   var dataSort = data_target.filter((e)=> e.parent_period_time_id === null);
  var dataSort2 = data_target.filter((e)=> e.parent_period_time_id !== null);
  for(let item of dataSort){
    var nodeSon = dataSort2.filter((e)=>{ return e.parent_period_time_id === item.id});
    item.nodeSon = nodeSon;
  } */

    var tmpArr = [];
    if (data_target.length > 0) {
      setwork_type(data_target[0].period_time_name);
      /*      var temp = {
            id:data_target[0].id,
            name:data_target[0].period_time_name,
            start:data_target[0].begin_time,
            end:data_target[0].finish_time,
          };
          tmpArr.push(temp) */ for (let item of data_target[0].nodeSon) {
        var temp2 = {
          id: item.id,
          name: item.period_time_name,
          start: item.begin_time,
          end: item.finish_time,
        };
        tmpArr.push(temp2);
      }
    }

    setperiod_timeDetail(tmpArr);
  }

  const GetPeriodTime = async () => {
    sessionStorage.setItem("timeline", 0);
    var temp = {
      company_id: getUser().com,
      oem_id: getOem(),
      period_time_name: "",
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/filterConfigPeriodTime",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        var dataSort = response.data.data.filter(
          (e) => e.parent_period_time_id === null
        );
        var dataSort2 = response.data.data.filter(
          (e) => e.parent_period_time_id !== null
        );
        for (let item of dataSort) {
          var nodeSon = dataSort2.filter((e) => {
            return e.parent_period_time_id === item.id;
          });
          item.nodeSon = nodeSon;
        }
        /*      console.log("dataSort",dataSort) */
        setperiod_time({ ...period_time, data: dataSort });
        period_time.data = dataSort;

        /*        console.log(period_time); */
        var data = dataSort;
        var group_ = [];
        var item_ = [];
        let i = 0;
        for (var g of data) {
          let temp_group = {
            id: g.id,
            /*      title:(<a href = "#">{g.period_time_name}</a>), */
            title: (
              <>
                <div className="row">
                  <div className="col-12">
                    {g.period_time_name}
                    {"   "}
                  </div>
                </div>
              </>
            ),
            rightTitle: (
              <>
                <div className="row">
                  <div className="col-4">
                    {/*  <a
                               href={"/Human_Resource/ตั้งค่า/Company_Time_Attendance_Data/read/"+ g.id}
                              key={g.id}
                              className=" btn btn-xs "
                              id = {"view_"+g.id}
                            >
                              <i class="fas fa-eye"></i>{" "}
                            </a> */}
                  </div>

                  <div className="col-4">
                    <a
                      id={"view_" + g.id}
                      key={g.id}
                      className=" btn btn-xs "
                      onClick={setTimeView.bind(this, g.id)}
                      data-toggle="modal"
                      data-target="#modal-detailTime"
                    >
                      {" "}
                      <i class="fas fa-pencil-alt"></i>{" "}
                    </a>
                    {/*   <a
                                href={"/Human_Resource/ตั้งค่า/Company_Time_Attendance_Data/edit/"+ g.id}
                               key={g.id}
                               id = {"edit_"+g.id}
                              className=" btn btn-xs "
                            >
                              {" "}
                              <i class="fas fa-pencil-alt"></i>{" "}
                            </a> */}
                  </div>
                  <div className="col-4">
                    <a
                      id={"del_" + g.id}
                      key={g.id}
                      className=" btn btn-xs "
                      onClick={delPeriodTime.bind(this, g.id)}
                    >
                      <i class="fas fa-trash-alt"></i>
                    </a>
                  </div>
                </div>
              </>
            ),
          };
          group_.push(temp_group);

          /*  var arr_begin = g.begin_time.split(".");
              var arr_finish = g.finish_time.split(".");
              var hour_begin = arr_begin[0];     
              var minute_begin = arr_begin[1];
              var hour_finish = arr_finish[0];     
              var minute_finish = arr_finish[1];
              var colorItem = "#00FF7F"
              if(g.work_color == "red"){
                colorItem = "#CD5C5C";
              }else if(g.work_color == "green"){
                colorItem = "#00FF7F";
              }else if(g.work_color == "yellow"){
                colorItem = "#FFFF00";
              } */

          /*  var total_minute = moment(moment(new Date()).startOf('days').add(hour_finish,'h').add(minute_finish,'m')).diff((moment(new Date()).startOf('days').add(hour_begin,'h').add(minute_begin,'m') ), 'm');
              if(parseFloat(g.finish_time) < parseFloat(g.begin_time)){

                  total_minute = moment(moment(new Date()).startOf('days').add(1,'days').add(hour_finish,'h').add(minute_finish,'m')).diff((moment(new Date()).startOf('days').add(hour_begin,'h').add(minute_begin,'m') ), 'm');
              }
          
           
              if(moment().startOf('days').add(hour_begin, 'h').add(minute_begin, 'm').add(total_minute, 'm').format('YYYYMMDDHHmm') > moment(new Date()).startOf('days').add(1, 'd').format('YYYYMMDDHHmm')){
            */
          /*  var over = moment(moment().startOf('days').add(hour_begin, 'h').add(minute_begin, 'm').add(total_minute, 'm')).diff(moment(new Date()).startOf('days').add(1, 'd') ,'minutes');
                  var hour_new = moment().startOf('days').add(over, 'm');
               
                  let temp_item = {
                      id: "p"+i,
                      group: g.id,
                      title: g.period_time_name+" "+ g.begin_time + " - "+g.finish_time,
                      start_time: moment().startOf('days'),
                      end_time: hour_new,
                      itemProps: {
                        style: {
                          color: "black",
                          background: colorItem
                        }
                      }
                  }
                  item_.push(temp_item);
                  let temp_item2 = {
                      id: i,
                      group: g.id,
                      title: g.period_time_name+" "+ g.begin_time + " - "+g.finish_time,
                      start_time: moment().startOf('days').add(hour_begin, 'h').add(minute_begin, 'm'),
                      end_time: moment().endOf('days'),
                      itemProps: {
                        style: {
                          color: "black",
                          background: colorItem
                        }
                      }
                  }
                  item_.push(temp_item2); */
          /*   }else{ */
          /*  let temp_item = {
                      id: i,
                      group: g.id,
                      title: g.period_time_name+" "+g.begin_time + "-"+g.finish_time,
                      start_time: moment().startOf('days').add(hour_begin, 'h').add(minute_begin, 'm'),
                      end_time: moment().startOf('days').add(hour_finish, 'h').add(minute_finish, 'm'),
                      itemProps: {
                        style: {
                          color: "black",
                          background: colorItem
                        }
                      }
                  }
                  item_.push(temp_item); */
          for (let item of g.nodeSon) {
            if (item.begin_time !== null && item.finish_time !== null) {
              var arr_beginS = item.begin_time.split(".");
              var arr_finishS = item.finish_time.split(".");
              var hour_beginS = arr_beginS[0];
              var minute_beginS = arr_beginS[1];
              var hour_finishS = arr_finishS[0];
              var minute_finishS = arr_finishS[1];

              var colorItemS = "#00FF7F";
              if (item.work_color == "red") {
                colorItemS = "#CD5C5C";
              } else if (item.work_color == "green") {
                colorItemS = "#00FF7F";
              } else if (item.work_color == "yellow") {
                colorItemS = "#FFFF00";
              } else if (item.work_color == "pink") {
                colorItemS = "#FFB6C1";
              } else if (item.work_color == "orange") {
                colorItemS = "#FF4500";
              } else if (item.work_color == "blue") {
                colorItemS = "#0000CD";
              }

              var total_minuteS = moment(
                moment(new Date())
                  .startOf("days")
                  .add(hour_finishS, "h")
                  .add(minute_finishS, "m")
              ).diff(
                moment(new Date())
                  .startOf("days")
                  .add(hour_beginS, "h")
                  .add(minute_beginS, "m"),
                "m"
              );
              if (parseFloat(item.finish_time) < parseFloat(item.begin_time)) {
                total_minuteS = moment(
                  moment(new Date())
                    .startOf("days")
                    .add(1, "days")
                    .add(hour_finishS, "h")
                    .add(minute_finishS, "m")
                ).diff(
                  moment(new Date())
                    .startOf("days")
                    .add(hour_beginS, "h")
                    .add(minute_beginS, "m"),
                  "m"
                );
              }
              if (
                moment()
                  .startOf("days")
                  .add(hour_beginS, "h")
                  .add(minute_beginS, "m")
                  .add(total_minuteS, "m")
                  .format("YYYYMMDDHHmm") >
                moment(new Date())
                  .startOf("days")
                  .add(1, "d")
                  .format("YYYYMMDDHHmm")
              ) {
                var overS = moment(
                  moment()
                    .startOf("days")
                    .add(hour_beginS, "h")
                    .add(minute_beginS, "m")
                    .add(total_minuteS, "m")
                ).diff(
                  moment(new Date()).startOf("days").add(1, "d"),
                  "minutes"
                );
                var hour_newS = moment().startOf("days").add(overS, "m");

                let temp_item = {
                  id: "p" + item.id + i,
                  group: g.id,
                  /*   title: item.period_time_name+" "+item.begin_time + " - "+item.finish_time, */
                  start_time: moment().startOf("days"),
                  end_time: hour_newS,
                  itemProps: {
                    style: {
                      color: colorItemS,
                      background: colorItemS,
                    },
                  },
                };
                item_.push(temp_item);
                let temp_item2 = {
                  id: item.id + i,
                  group: g.id,
                  /*     title: item.period_time_name+" "+item.begin_time + " - "+item.finish_time, */
                  start_time: moment()
                    .startOf("days")
                    .add(hour_beginS, "h")
                    .add(minute_beginS, "m"),
                  end_time: moment().endOf("days"),
                  itemProps: {
                    style: {
                      color: colorItemS,
                      background: colorItemS,
                    },
                  },
                };
                item_.push(temp_item2);
              } else {
                let temp_item2 = {
                  id: item.id + i,
                  group: g.id,
                  /*  title: item.period_time_name+" "+item.begin_time + "-"+item.finish_time, */
                  start_time: moment()
                    .startOf("days")
                    .add(hour_beginS, "h")
                    .add(minute_beginS, "m"),
                  end_time: moment()
                    .startOf("days")
                    .add(hour_finishS, "h")
                    .add(minute_finishS, "m"),
                  itemProps: {
                    style: {
                      color: colorItemS,
                      background: colorItemS,
                    },
                  },
                };
                item_.push(temp_item2);
              }
            }
          } /* } */

          i++;
        }
        setperiod_time_group([]);
        setperiod_time_item([]);

        setperiod_time_group(group_);

        setperiod_time_item(item_);
        /*  console.log("group_",group_);
          console.log("item_",item_); */
        sessionStorage.setItem("timeline", 1);
        settest({ ...test, test: "" });
        test.test = "";
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function delPeriodTime(id) {
    console.log(id);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(id);
        axios({
          method: "get",
          url:
            Configs.API_URL_hrMagenatement +
            "/api/hrManagement/delConfigPeriodTimeByID?id=" +
            id,
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
        })
          .then(function (response) {
            if (response.data.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                GetPeriodTime();
              });
            }
          })
          .catch(function (error) {
            console.log(error);

            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  }

  useEffect(() => {
    console.log(new Date('July 1, 1999, 12:00'));
    sessionStorage.setItem("timeline", 0);
    GetCompany();
    GetCompanyParams();
    GetPeriodTime();
  }, []);

  const defaultTimeStart = moment().startOf("days").toDate();
  const defaultTimeEnd = moment().startOf("days").add(1, "days").toDate();
  const defaultTimeRange = defaultTimeEnd - defaultTimeStart;

  function addPage() {
    window.location.href =
      "/Human_Resource/ตั้งค่า/Company_Time_Attendance_Data/add";
  }

  function saveOption(params) {
    const error_list = [];
    if (company_params.day_hour_start_value == "") {
      let temp_err = {
        message: "Please enter information in the fields >>> [Day Hour Start].",
      };
      error_list.push(temp_err);
    }
    if (company_params.rate_time_absent_value == "") {
      let temp_err = {
        message: "Please enter information in the fields >>> [Time to absent].",
      };
      error_list.push(temp_err);
    }

    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    } else {
    
   const tempData=   {
        company_id: company_params.company_id,
        day_hour_start:  company_params.day_hour_start,
        day_hour_start_value: moment(company_params.day_hour_start_value).format('H:mm') ,
        oem_id: company_params.oem_id,
        rate_time_absent: company_params.rate_time_absent,
        rate_time_absent_value: company_params.rate_time_absent_value,
      }
     /*  console.log("company_params",company_params.day_hour_start_value); */
      Swal.fire({
        title: "Saving",
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 1000,
        onOpen: () => {
          Swal.showLoading();
        },
      }).then((result) => {
        axios({
          method: "post",
          url:
            Configs.API_URL_hrMagenatement +
            "/api/hrManagement/saveHrCompanyParams",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: tempData,
        })
          .then(function (response) {
            if (response.data.data === true) {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                GetCompanyParams();
                sessionStorage.setItem("timeline", 0);
                GetPeriodTime();
                window.location.href =
                  "/Human_Resource/ตั้งค่า/Company_Time_Attendance_Data";
              });
            }

           
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", error.response.data.message, "error");
          });
      });
    }
  }

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Company Time Attendance Data{"  "}
                  <a target="_blank" href={getAcademy()}>
                    <i class="fa fa-info-circle"></i>
                  </a>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">
                    Company Time Attendance Data
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Company Time Attendance Data</h3>
              <div className="card-tools"></div>
            </div>
            <div className="container-fluid">
              <br />
              <div className="row">
                <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    id="btn_save"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    บันทึก
                  </button>
                </div>
                {/*      <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                   
                      //onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    ยกเลิก
                  </button>
                </div> */}
              </div>

              <div className="row">
                <div
                  className="col-4 col-md-2 col-xl-2"
                  style={{ textAlign: "right" }}
                >
                  <div className="form-group ">
                    <p>Day Hour start (h:min): </p>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-xl-3" style={{ "z-index": "99" }}>
                  <div className="form-group ">

                  <DatePicker
                    // disabled={true}
                    selected={company_params.day_hour_start_value}
                    locale="th"
                    id="text_start_work"
                    showTimeSelect
                    showTimeSelectOnly
                    dateFormat="H:mm น."
                    timeIntervals={15}
                    timeCaption="Time"
                    onChange={async (date) => {
                      setcompany_params({...company_params,day_hour_start_value:date})
                    }}
                    customInput={<CustomInput />}
                  ></DatePicker>
                    {/* <NumberFormat
                            placeholder="00:00"
                      allowNegative={false}
                      id="input_day_hour_start"
                      allowNegative={false}
                      decimalScale={0}
                      type="text"
                        format={"##.##"}
                      className="form-control"
                      value={company_params.day_hour_start_value}
                      onChange={(e) => {
                            const myArray1 = e.target.value.split(":");
                        if (myArray1[0] > 23 || myArray1[1] > 59) {
                          setcompany_params({
                            ...company_params,
                            day_hour_start_value: "23.59",
                          });
                        } else {
                        if (e.target.value > 23) {
                          setcompany_params({
                            ...company_params,
                            day_hour_start_value: 23,
                          });
                        } else {
                          setcompany_params({
                            ...company_params,
                            day_hour_start_value: e.target.value,
                          });
                        }

                           } 
                      }}
                    /> */}
                  </div>
                </div>
                <div
                  className="col-4 col-md-2 col-xl-2"
                  style={{ textAlign: "right" }}
                >
                  <div className="form-group ">
                    <p>time to absent (min)</p>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-xl-3">
                  <div className="form-group ">
                    <NumberFormat
                      id="input_time_absent"
                      allowNegative={false}
                      decimalScale={0}
                      type="text"
                      className="form-control"
                      value={company_params.rate_time_absent_value}
                      onChange={(e) => {
                        setcompany_params({
                          ...company_params,
                          rate_time_absent_value: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                {/*    <div className="col-6 col-md-3 col-xl-1">
                <div className="form-group ">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    บันทึก
                  </button>
                </div>     </div> */}
              </div>

              <div className="row">
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    id="btn_add_time_line"
                    onClick={addPage}
                    class="btn btn-block btn-primary "
                  >
                    เพิ่มช่วงเวาการทำงาน
                  </button>
                </div>
              </div>

              <div className="row">
                <div
                  className="col-6 col-md-4 col-xl-2"
                  style={{ padding: "15px" }}
                >
                  <table>
                    <tr>
                      <td
                        style={{ backgroundColor: "#00FF7F", width: "50px" }}
                      ></td>
                      <td> Normal</td>
                    </tr>
                  </table>
                </div>
                <div
                  className="col-6 col-md-4 col-xl-2"
                  style={{ padding: "15px" }}
                >
                  <table>
                    <tr>
                      <td
                        style={{ backgroundColor: "#CD5C5C", width: "50px" }}
                      ></td>
                      <td> Late</td>
                    </tr>
                  </table>
                </div>
                <div
                  className="col-6 col-md-4 col-xl-2"
                  style={{ padding: "15px" }}
                >
                  <table>
                    <tr>
                      <td
                        style={{ backgroundColor: "#FFB6C1", width: "50px" }}
                      ></td>
                      <td> Lunch break</td>
                    </tr>
                  </table>
                </div>
                <div
                  className="col-6 col-md-4 col-xl-2"
                  style={{ padding: "15px" }}
                >
                  <table>
                    <tr>
                      <td
                        style={{ backgroundColor: "#FFFF00", width: "50px" }}
                      ></td>
                      <td> OT</td>
                    </tr>
                  </table>
                </div>
                <div
                  className="col-6 col-md-4 col-xl-2"
                  style={{ padding: "15px" }}
                >
                  <table>
                    <tr>
                      <td
                        style={{ backgroundColor: "#FF4500", width: "50px" }}
                      ></td>
                      <td> Extra OT</td>
                    </tr>
                  </table>
                </div>
                <div
                  className="col-6 col-md-4 col-xl-2"
                  style={{ padding: "15px" }}
                >
                  <table>
                    <tr>
                      <td
                        style={{ backgroundColor: "#0000CD", width: "50px" }}
                      ></td>
                      <td> Other</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div
                className="card-body table-responsive " /*  style={{ whiteSpace: 'nowrap'}} */
              >
                {sessionStorage.getItem("timeline") == "1" ? (
                  <>
                    <Timeline
                      groups={period_time_group}
                      items={period_time_item}
                      defaultTimeStart={moment().startOf("days")}
                      defaultTimeEnd={moment().endOf("days")}
                      minZoom={defaultTimeRange}
                      maxZoom={defaultTimeRange}
                      onTimeChange={(_start, _end, updateScrollCanvas) => {
                        if (_start > defaultTimeStart && _end < defaultTimeEnd)
                          updateScrollCanvas(_start, _end);
                      }}
                      rightSidebarWidth={150}
                      rightSidebarContent={<div>Above The Right</div>}
                      canMove={false}
                      traditionalZoom={false}
                      onClick={(data) => {
                        console.log("data", data);
                      }}
                    >
                      <TimelineHeaders>
                        <SidebarHeader>
                          {() => {
                            return (
                              <div
                                style={{ width: "149px", textAlign: "center" }}
                              >
                                <br />
                                <font color="#FFF">ประเภทการทำงาน</font>
                              </div>
                            );
                          }}
                        </SidebarHeader>

                        <DateHeader unit="primaryHeader" />

                        <DateHeader />
                      </TimelineHeaders>
                    </Timeline>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="modal fade" id="modal-detailTime">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-label">ประเภทการทำงาน {work_type}</h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <table
                      style={{
                        width: "100%",
                        textAlign: "center" /* ,whiteSpace: 'nowrap' */,
                      }}
                    >
                      <tr>
                        <td style={{ border: "1px solid #ddd" }}>ลำดับ</td>
                        <td style={{ border: "1px solid #ddd" }}>
                          ประเภทการทำงาน
                        </td>
                        <td style={{ border: "1px solid #ddd" }}>เวลาเริ่ม</td>
                        <td style={{ border: "1px solid #ddd" }}>
                          เวลาสิ้นสุด
                        </td>
                        <td style={{ border: "1px solid #ddd" }}>การจัดการ</td>
                      </tr>
                      {period_timeDetail.map((e, index) => {
                        console.log(e);
                        if ((e.start !== null) & (e.end !== null)) {
                          return (
                            <>
                              <tr>
                                <td style={{ border: "1px solid #ddd" }}>
                                  {index + 1}
                                </td>
                                <td style={{ border: "1px solid #ddd" }}>
                                  {e.name}
                                </td>
                                <td style={{ border: "1px solid #ddd" }}>
                                  {e.start}
                                </td>
                                <td style={{ border: "1px solid #ddd" }}>
                                  {e.end}
                                </td>

                                <td style={{ border: "1px solid #ddd" }}>
                                  <div className="row">
                                    <div className="col-4">
                                      <a
                                        href={
                                          "/Human_Resource/ตั้งค่า/Company_Time_Attendance_Data/read/" +
                                          e.id
                                        }
                                        key={e.id}
                                        className=" btn btn-xs "
                                        id={"view_" + e.id}
                                      >
                                        <i class="fas fa-eye"></i>{" "}
                                      </a>
                                    </div>

                                    <div className="col-4">
                                      <a
                                        href={
                                          "/Human_Resource/ตั้งค่า/Company_Time_Attendance_Data/edit/" +
                                          e.id
                                        }
                                        key={e.id}
                                        id={"edit_" + e.id}
                                        className=" btn btn-xs "
                                      >
                                        {" "}
                                        <i class="fas fa-pencil-alt"></i>{" "}
                                      </a>
                                    </div>
                                    <div className="col-4">
                                      <a
                                        id={"del_" + e.id}
                                        key={e.id}
                                        className=" btn btn-xs "
                                        onClick={delPeriodTime.bind(this, e.id)}
                                      >
                                        <i class="fas fa-trash-alt"></i>
                                      </a>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        }
                      })}
                    </table>
                  </div>

                  <div className="modal-footer justify-content-between">
                    <div className="row">
                      <div className="col-4"></div>
                      <div className="col-1"> </div>
                      <div className="col-4"></div>
                    </div>

                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}

export default Company_time_attendance;
