import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import "../../assets/css/Supp.css";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import "../../assets/css/table.css";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import { useParams } from "react-router";
import Sidebar from "../../component/Sidebar";
import { getOem, getToken, getUser,getAcademy } from "../../Utils/Common";
import Configs from "../../config";
import FormData from "form-data";
import axios from "axios";
import NumberFormat from "react-number-format";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";

function Model_category_add(mode) {
  const [company, setCompany] = useState([]);
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);
  const [model_category, setmodel_category] = useState({
    oem_id: getOem(),
    model_category: "",
    remark: "",
    
  });
  const { id } = useParams();
  useEffect(() => {
    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);

  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //console.log(response.data);
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  
  useEffect(() => {
    GetCompany();
    // console.log("id:"+id);
    GetMc_view();
  }, []);


  const GetMc_view = async () => {
     
    if ( mode.mode =="read" || mode.mode =="edit"  ) {
      axios({
        method: "get",
        url: Configs.API_URL + "/material/findByIdmc?id="+ id,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          //console.log(response.data);
          setmodel_category({
            oem_id: response.data.oem_id,
            model_category: response.data.name || "",
            remark: response.data.remark || "",
            id: response.data.id || "",
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    
  }
  
  
  function saveOption(params) {
    const error_list = [];
    if(model_category.model_category.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [model_category Abbreviation]."
      }
      error_list.push(temp_err);
    }
    if(error_list.length > 0){
      var err_message = "";
      for(var e =0; e < error_list.length;e++){
        err_message += "<br/>"+error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    }else{
      if (mode.mode === "add") {
        const temp ={
         
          oem_id: model_category.oem_id,
          model_category: model_category.model_category.trim(),
          remark: model_category.remark,
     
        }
        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/material/addmatmodel_category",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: model_category,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Factory_Master_Data/model_category";
                });
              }
  
              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error",error.response.data.message, "error");
            });
        });
      }
      if (mode.mode === "edit") {

        const temp ={
    

          oem_id: model_category.oem_id,
          model_category: model_category.model_category,
          remark: model_category.remark,
          id: model_category.id,
    
        }
        //console.log(temp);
       
        Swal.fire( {
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/material/updatemc",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Factory_Master_Data/model_category";
                });
              }
    
              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
       }
    }
  
  }

  

  function cancleOption(params) {
    Swal.fire({
      title: 'Do you want to cancel and leave the changes?',
      icon:'warning',
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:`No`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href =
        "/Warehouse_Management/Factory_Master_Data/model_category";
      }
    }
    );
  }
  return (
    <div className="wrapper">
   {/*  <Header />
    <Sidebar menu="warehouse" activemenu="suppmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Model&Category {pageMode}{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Model&Category</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row mb-2">
<div className="col-4 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
    <div className="col-4 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-4 col-md-3 col-xl-2">
                  <button
                    type="button"
                    if
                      onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Model&Category {pageMode}</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body">
            <h3 className="mt-5-head">
                Model&Category Detail
                  </h3>
                <div className="tab-custom-content">
                 </div>
                 <br/>
              <div className="row">
              <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={model_category.model_category.trim()}
                      disabled={disable}
                      onChange={(e) => {
                        setmodel_category({
                          ...model_category,
                          model_category: e.target.value.trim(),
                        });
                      }}
                
                    />
                    <label>
                     Model&Category
                      {model_category.model_category.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
              <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      //style={{ height: "100px", resize: "none" }}
                      value={model_category.remark}
                      disabled={disable}
                      onChange={(e) => {
                        setmodel_category({
                          ...model_category,
                          remark: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Remark
                      
                    </label>
                  </div>
                </div>
              </div>

              
            </div>
            
          </div>
        </section>
      </div>
 {/*      <Footter/> */}
      </div>
  );
}
export default Model_category_add;

