import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import "../../assets/css/Supp.css";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import "../../assets/css/table.css";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import { useParams } from "react-router";
import Sidebar from "../../component/Sidebar";
import { getOem, getToken, getUser,getAcademy } from "../../Utils/Common";
import Configs from "../../config";
import FormData from "form-data";
import axios from "axios";
import NumberFormat from "react-number-format";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";
import DatePicker from "react-datepicker";
import Resizer from "react-image-file-resizer";
import moment from "moment";
function Promotion_add(mode) {
  const [company, setCompany] = useState([]);
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);
  const [promotion, setpromotion] = useState({
    company_id: getUser().com,
    oem_id: getOem(),
    topic_promotion: "",
    detail_promotion: "",
    source_promotion: "",
    image: "",
    image_name: "",
    image_path: "",
    
    date_start:"",
    date_end:"",
    
  });
  const { id } = useParams();
  const GetPromotion_view = async () => {
    
    //console.log(filler_project);
    axios({
      method: "get",
      url: Configs.API_URL + "/promotion/findById?id="+id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },

    })
      .then(function (response) {
        console.log(response.data);
        setpromotion({...promotion, 
      
          topic_promotion: response.data.topic_promotion,
          detail_promotion: response.data.detail_promotion,
          source_promotion:response.data.source_promotion,
       
          image_name: response.data.image_promotion,
          image_path: response.data.image_path,
          
          date_start: new Date(moment(response.data.start_promotion)),
          date_end: new Date(moment(response.data.end_promotion)),
        });
        
        //setProject(response.data);
     
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  async function SetDate_start(date){
    setpromotion({ ...promotion, date_start: date})
  
   }
    async function SetDate_end(date){
      setpromotion({ ...promotion, date_end: date})
  
   }

   const CustomInput = ({ value, onClick }) => ( 
      
    <
    div className = "input-group" >
    < input type = "text"
    disabled={disable}
    className = "form-control float-left"
    onClick = { onClick }
    value = { value }
    /> <div className = "input-group-prepend" >
    <span className = "input-group-text" >
    <i className = "far fa-calendar-alt" />
    </span>
  
    { /*  <label>test</label> */ } 
    </div> 
    </div>
  
  
  )





  useEffect(() => {
    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);



  
  useEffect(() => {
    GetPromotion_view();


  }, []);


 
  
  
  function saveOption(params) {
    const error_list = [];
    if(promotion.topic_promotion.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Topic Promotion]."
      }
      error_list.push(temp_err);
    }
    if(promotion.source_promotion.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Source Promotion]."
      }
      error_list.push(temp_err);
    }
    if(promotion.date_start == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Date Start]."
      }
      error_list.push(temp_err);
    }
    if(promotion.date_end == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Date End]."
      }
      error_list.push(temp_err);
    }
    if(error_list.length > 0){
      var err_message = "";
      for(var e =0; e < error_list.length;e++){
        err_message += "<br/>"+error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    }else{
      if (mode.mode === "add") {
       var temp_add = {
        company_id: getUser().com,
        oem_id: getOem(),
        topic_promotion: promotion.topic_promotion,
        detail_promotion: promotion.detail_promotion,
        source_promotion: promotion.source_promotion,
        image: promotion.image,
        image_name: promotion.image_name,
        image_path: promotion.image_path,
        
        date_start: promotion.date_start,
        date_end: promotion.date_end,
       }; 
        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/promotion/add",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp_add,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Promotion";
                });
              }
  
              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error",error.response.data.message, "error");
            });
        });
      }
      else if (mode.mode === "edit") {

        var temp_edit = {
          id:id,
          company_id: getUser().com,
          oem_id: getOem(),
          topic_promotion: promotion.topic_promotion,
          detail_promotion: promotion.detail_promotion,
          source_promotion: promotion.source_promotion,
          image: promotion.image,
          image_name: promotion.image_name,
          image_path: promotion.image_path,
          
          date_start: promotion.date_start,
          date_end: promotion.date_end,
         }; 
        //console.log(temp);
       
        Swal.fire( {
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/promotion/update",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp_edit,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Promotion";
                });
              }
    
              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
       }
    }
  
  }
  function dataURLtoFile(dataurl, filename) {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
}

const resizeFile = (file) =>
new Promise((resolve) => {
  Resizer.imageFileResizer(
    file,
    400,
    300,
    "png",
    100,
    0,
    (uri) => {
      resolve(uri);
    },
  );
});

function isFileImage(file) {
  const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

  return file && acceptedImageTypes.includes(file['type'])
}

 async function setPromoteLogo(e) {
   

      const file = e.target.files[0];
        
        
      const regex = /([\u0E00-\u0E7F]+)/gmu;
      const str = file.name;
      let m = regex.exec(str);
     
     
       if(m !== null || isFileImage(file) === false){
        Swal.fire(
          'Error',
          'File name or type are not correct!!!',
          'error'
        )
       }else{
        const image = await resizeFile(file);
   
     
        var file_image = dataURLtoFile(image,file.name);
        var data = new FormData();
        data.append("Profile", file_image);
        data.append("typeFile", promotion.topic_promotion);

        var config_uploadProfile = {
          method: "post",
          url: Configs.API_URL + "/upload/profile",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios(config_uploadProfile)
          .then(function (response) {
            if (response.data.status) {
              /* console.log(response.data); */

              setpromotion({
                ...promotion,
                image_name: response.data.data.orgin_name,
                image_path: response.data.data.path,
                image: response.data.data,
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
       }
    
   
  }
  

  function cancleOption(params) {
    Swal.fire({
      title: "Do you want to cancel and leave the changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:`No`,
    }).then((result) => {
      if(result.isConfirmed){
        window.location.href =
        "/Warehouse_Management/Promotion";
      }
      
    });
   
  }
  return (
    <div className="wrapper">
   {/*  <Header />
    <Sidebar menu="warehouse" activemenu="suppmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Promotion {pageMode}{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Promotion</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row mb-2">
              <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
              <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    if
                      onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Promotion {pageMode}</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body">
            <h3 className="mt-5-head">
                Promotion Detail
                  </h3>
                <div className="tab-custom-content">
                 </div>
                 <br/>
              <div className="row">
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={promotion.topic_promotion}
                      disabled={disable}
                      onChange={(e) => {
                        setpromotion({
                          ...promotion,
                          topic_promotion: e.target.value,
                        });
                      }}
                
                    />
                    <label>
                      Topic Promotion
                      {promotion.topic_promotion.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
               {/*  <div className="col-md-1"></div> */}
           
               
                 
              </div>
            
              <div className="row">
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <textarea
                      type="text"
                      className="form-control"
                      required
                      style={{ height: "200px", resize: "none" }}
                      value={promotion.detail_promotion}
                      disabled={disable}
                      onChange={(e) => {
                        setpromotion({
                          ...promotion,
                          detail_promotion: e.target.value,
                        });
                      }}
                    />
                    <label>
                    Detail Promotion
                      
                    </label>
                  </div>
                  
                </div>
                <div className="col-12 col-md-6 col-xl-4" style={{ textAlign: "left",width: "300px",height:"200px"}}>
                <div class="form-group">
                  <label htmlFor="exampleInputFile"> </label>
                  <img
                    id="img"
                    alt="..."
                    className="img-fluid rounded shadow border-0"
                    src={
                      promotion.image_path !== "" &&
                      promotion.image_path !== null &&
                      promotion.image_path !== undefined
                        ? Configs.API_URL_IMG + promotion.image_path
                        : userdefault_img.imgs
                    }
                    style={{
                      width: "300px",
                      height: "200px",
                      position: "absolute",
                    }}
                  />
                   </div>
                  
                </div>
               
                
              </div>
                  <br/>
              <div className="row">
              <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={promotion.source_promotion}
                      disabled={disable}
                      onChange={(e) => {
                        setpromotion({
                          ...promotion,
                          source_promotion: e.target.value,
                        });
                      }}
                
                    />
                    <label>
                      Source Promotion
                      {promotion.source_promotion.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>

             {/*  <div>
                <h3>Picture</h3>
                {selectedImage && (
                  <div>
                  <img alt="not fount" width={"50px"} src={URL.createObjectURL(selectedImage)} />
                  <br />
                  
                  </div>
                )}   
                <br />   
                <input
                  type="file"
                  name="myImage"
                  onChange={(event) => {
                    console.log(event.target.files[0]);
                    setSelectedImage(event.target.files[0]);
                  }}
                />
              </div> */}


              <div className="row">
              <div className="col-12 col-md-6 col-xl-4">
                  <label htmlFor="exampleInputFile">Image</label>
                  <span style = {{color:"red"}}> size(400 x 300) px</span>
                  <div className="input-group">
                  
                    <div className="custom-file">
                      
                      <input
                        type="file"
                        className="custom-file-input"
                        id="exampleInputFile"
                        disabled={disable}
                        accept="image/*"
                        onChange={setPromoteLogo}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="exampleInputFile"
                      >
                        {promotion.image_name !== ""
                          ? promotion.image_name
                          : "Select Image"}
                      </label>
                      
                    </div>
                    
                  </div>
                </div>
              </div>
                <br/>
        <div className="container-fluid">
        
            
        <div className="row mb-2">
          <div className="col-2 col-md-1 col-xl-0.5" style ={{textAlign: "right"}}>
            <label > Date &nbsp;</label>
            </div>
          
            <div   className="col-10 col-md-11 col-xl-2" style = {{"z-index":"99"}}>
            <div className="form-group ">
    
            <DatePicker selected = {promotion.date_start}
            dateFormat = {"dd-MM-yyyy"}
           
            onChange = {
              async(date) =>
              setpromotion({ ...promotion, date_start: date})
            //  SetDate_start(date)   
              }
            selectsStart startDate = {promotion.date_start}
            endDate = {promotion.date_end}
            customInput = {<CustomInput/>}
            
            /> 
            </div>
            </div>
            <div className="col-2 col-md-1 col-xl-0.5" style ={{textAlign: "right"}}>
            <label> Between </label> 
            </div>
            <div className="col-10 col-md-11 col-xl-2"  style = {{"z-index":"99"}}>
            <div className="form-group ">
            <DatePicker selected = {promotion.date_end}
            dateFormat = { "dd-MM-yyyy" }
       
            onChange = {
              async(date) => 
              SetDate_end(date)   
              }
            selectsEnd startDate = { promotion.date_start }
            endDate = { promotion.date_end }
            minDate = { promotion.date_start }
            customInput = { <CustomInput/> }
          
            >
            </DatePicker> 
            </div>
            </div>

          </div>
          </div>

        
        





              
            </div>
          </div>
        </section>
      </div>
 {/*      <Footter/> */}
      </div>
  );
}
export default Promotion_add;
