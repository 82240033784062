import React, { useState, cloneElement, useEffect } from "react";
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import th from 'date-fns/locale/th';
import DatePicker from "react-datepicker";
import { Space, TimePicker } from 'antd';
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../../config";
import { getOem, getToken, getUser, getAcademy, setOemlist } from "../../../../Utils/Common";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import "antd/dist/reset.css";
import { addDays, subDays } from 'date-fns';
import TTT_Hall from "../../../../assets/img/booking/TTT_Hall.png"
import TTT_Hotel from "../../../../assets/img/booking/TTT_Hotel.png"
import TTT_Hotel_Meeting from "../../../../assets/img/booking/TTT_Hotel_Meeting.png"
import moment from "moment";
import './Reserve.css'
import dayjs from "dayjs";

function Reserve() {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedStartTime, setSelectedStartTime] = useState(null);
    const [selectedEndTime, setSelectedEndTime] = useState(null);
    const [getDataHall, setGetDataHall] = useState([]);
    const [getDataRoom, setGetDataRoom] = useState([]);
    const [getDataDetailRoom, setGetDataDetailRoom] = useState([]);
    const [getDataHotelMeeting, setGetDataHotelMeeting] = useState([]);
    const [step, setStep] = useState(30);
    const [getDate, setGetDate] = useState({
        startDate: moment(currentDate).format('YYYY-MM-DD') + ' ' + ('00:00:00'),
        endDate: moment(currentDate).format('YYYY-MM-DD') + ' ' + ('23:59:59'),
    });
    const [test, settest] = useState({
        test: "",
    });
    const locales = {
        'en-US': enUS,
        'th-TH': th,
    };

    const localizer = dateFnsLocalizer({
        format,
        parse,
        startOfWeek,
        getDay,
        locales,
    });

    const formats = {
        dateFormat: 'd',
        timeGutterFormat: 'HH:mm',
    };

    function changeStep(start, end) {
        const startTime = moment(start, "HH:mm");
        const endTime = moment(end, "HH:mm");
        const durationInMinutes = moment.duration(endTime.diff(startTime)).asMinutes();
        const durationFormatted = `${Math.floor(durationInMinutes / 60)}:${durationInMinutes % 60 < 10 ? '0' : ''}${durationInMinutes % 60}`;
        const [hours, minutes] = durationFormatted.split(':').map(Number);
        const totalMinutes = (hours * 60) + minutes;
        let step;
        if (totalMinutes <= 60) {
            step = 1;
        } else if (totalMinutes > 60 && totalMinutes <= 180) {
            step = 10;
        } else {
            step = 30; 
        }
    
        return step;
    }

    useEffect(() => {
        if (selectedStartTime != null && selectedEndTime != null) {
            setStep(changeStep(selectedStartTime.format('HH:mm'), selectedEndTime.format('HH:mm')));
        } else {
            setStep(30);
        }
    }, [selectedStartTime, selectedEndTime]);

    const GetFilterHall = async () => {
        await axios({
            method: "post",
            url: Configs.API_URL_hrMagenatement + "/api/bookingRouter/filterHall",
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: getDate
        })
            .then(async function (response) {
                if (Array.isArray(response.data.data)) {
                    setGetDataHall(response.data.data);
                } else {
                    setGetDataHall([]);
                }
            })
            .catch(function (error) {
                console.log(error);
                Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
            });
    };

    const GetFilterRoom = async () => {
        await axios({
            method: "get",
            url: Configs.API_URL_hrMagenatement + "/api/bookingRouter/filterRoom",
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
        })
            .then(async function (response) {
                if (Array.isArray(response.data.data)) {
                    setGetDataRoom(response.data.data);
                } else {
                    setGetDataRoom([]);
                }
            })
            .catch(function (error) {
                console.log(error);
                Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
            });
    };

    const GetFilterDetailRoom = async () => {
        await axios({
            method: "post",
            url: Configs.API_URL_hrMagenatement + "/api/bookingRouter/filterDetailRoom",
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: getDate
        })
            .then(async function (response) {
                if (Array.isArray(response.data.data)) {
                    setGetDataDetailRoom(response.data.data);
                } else {
                    setGetDataDetailRoom([]);
                }
            })
            .catch(function (error) {
                console.log(error);
                Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
            });
    };

    const GetFilterHotelMeeting = async () => {
        await axios({
            method: "post",
            url: Configs.API_URL_hrMagenatement + "/api/bookingRouter/filterHotelMeeting",
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: getDate
        })
            .then(async function (response) {
                if (Array.isArray(response.data.data)) {
                    setGetDataHotelMeeting(response.data.data);
                } else {
                    setGetDataHotelMeeting([]);
                }
            })
            .catch(function (error) {
                console.log(error);
                Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
            });
    };

    useEffect(() => {
        if (getDataDetailRoom.length === 0 && getDataHall.length === 0) {
            // Update selectedStartTime and selectedEndTime
            const selectedStart = moment(currentDate).startOf('d');
            const selectedEnd = moment(currentDate).endOf('d');
            setSelectedStartTime(selectedStart);
            setSelectedEndTime(selectedEnd);
            setTimeout(() => {
                setGetDate({
                    startDate: moment(selectedStart).format('YYYY-MM-DD') + ' ' + selectedStart.format('HH:mm:ss'),
                    endDate: moment(selectedEnd).format('YYYY-MM-DD') + ' ' + selectedEnd.format('HH:mm:ss'),
                });
                getDate.startDate = moment(selectedStart).format('YYYY-MM-DD') + ' ' + selectedStart.format('HH:mm:ss');
                getDate.endDate = moment(selectedEnd).format('YYYY-MM-DD') + ' ' + selectedEnd.format('HH:mm:ss');
            }, 0);
        }
        GetFilterHall();
        GetFilterRoom();
        GetFilterDetailRoom();
        GetFilterHotelMeeting();
    }, [currentDate, getDataDetailRoom.length, getDataHall.length]);


    const transformDataToEvents = (getDataHall, getDataDetailRoom, getDataHotelMeeting) => {
        return [...(getDataHall.map(item => ({
            start: moment(item.bhall_start).toDate(),
            end: moment(item.bhall_end).toDate(),
            data: {
                appointment: {
                    id: item.bhall_id,
                    no: item.emp_no,
                    name: item.account_name,
                    detail: item.bhall_detail,
                },
            },
            resource: "TTT Hall",
        }))),
        ...(getDataHotelMeeting.map(item => ({
            start: moment(item.bhm_start).toDate(),
            end: moment(item.bhm_end).toDate(),
            data: {
                appointment: {
                    id: item.bhm_id,
                    no: item.emp_no,
                    name: item.account_name,
                    detail: item.bhm_detail,
                },
            },
            resource: "TTT Hotel Meeting",
        }))),
        ...(getDataDetailRoom.map(item => ({
            start: moment(item.bh_start).toDate(),
            end: moment(item.bh_end).toDate(),
            data: {
                appointment: {
                    id: item.bh_id,
                    no: item.emp_no,
                    name: item.account_name,
                    detail: item.bh_detail,
                },
            },
            resource: item.bhr_id,
        }))),
        ];
    };

    const initialEvents = transformDataToEvents(getDataHall, getDataDetailRoom, getDataHotelMeeting);

    function splitEventsByDay(events) {
        const splitEvents = [];
        events.forEach((event) => {
            const start = new Date(event.start);
            const end = new Date(event.end);
            const current = new Date(start);
            while (current <= end) {
                let dayStart = null;
                let dayEnd = null;
                if (moment(start).format('DD/MM/YYYY') === moment(currentDate).format('DD/MM/YYYY')) {
                    dayStart = new Date(current);
                    dayEnd = new Date(current);
                    dayEnd.setHours(23, 59, 59, 999);
                } else {
                    dayStart = new Date(end);
                    dayEnd = new Date(end);
                    dayStart.setHours(0, 0, 0, 0);
                }

                if (dayStart < start) dayStart.setTime(start.getTime());
                if (dayEnd > end) dayEnd.setTime(end.getTime());

                splitEvents.push({
                    ...event,
                    start: dayStart,
                    end: dayEnd,
                });

                // Move to the next day
                current.setDate(current.getDate() + 1);
            }
        });

        return splitEvents;
    }

    const processedEvents = splitEventsByDay(initialEvents);

    function removeLastName(name) {
        if (typeof name !== 'string') {
            return '';
        }
        const parts = name.split(' ');
        parts.pop();
        return parts.join(' ');
    }


    function truncateName(name, maxLength) {
        if (name.length > maxLength) {
            return name.slice(0, maxLength) + "...";
        }
        return name;
    }

    function AppointmentEvent({ appointment, start, end, isMonthView, durationFormatted }) {
        const firstName = removeLastName(appointment.name);
        const truncatedName = truncateName(firstName, 10);

        let lines = 0;
        if (durationFormatted <= 60) {
            lines = 2;
        } else if (durationFormatted >= 60 && durationFormatted <= 80) {
            lines = 3;
        } else {
            lines = 4;
        }

        return (
            <div style={{ backgroundColor: "rgb(246, 246, 246)" }}>
                <div style={{ color: "white", backgroundColor: "#3579F6" }}>
                    {appointment.no} / {truncatedName}
                </div>
                <div style={{ backgroundColor: "rgb(246, 246, 246)" }}>{start} - {end}</div>
                {!isMonthView && (
                    <div style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: `${lines}`,
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        margin: '-2px 5px 0 5px',
                    }}>
                        {appointment.detail}
                    </div>
                )}
            </div>
        );
    }

    const components = {
        event: ({ event }) => {
            const data = event?.data;
            if (data?.appointment) {
                const startTime = moment(event.start);
                const endTime = moment(event.end);
                const durationInMinutes = moment.duration(endTime.diff(startTime)).asMinutes();
                const durationFormatted = `${Math.floor(durationInMinutes / 60)}:${durationInMinutes % 60 < 10 ? '0' : ''}${durationInMinutes % 60}`;
                const [hours, minutes] = durationFormatted.split(':').map(Number);
                const totalMinutes = (hours * 60) + minutes;

                return (
                    <AppointmentEvent
                        appointment={data.appointment}
                        start={moment(event.start).format('HH:mm')}
                        end={moment(event.end).format('HH:mm')}
                        durationFormatted={parseInt(totalMinutes)}
                        isMonthView={event.view === Views.MONTH}
                    />
                );
            }
            return null;
        },
        // timeSlotWrapper: ({ children, value, resource }) => {
        //     return cloneElement(children, {
        //         onContextMenu: (e) => {
        //             e.preventDefault();
        //             setContextMenuInfo({
        //                 xPosition: e.clientX,
        //                 yPosition: e.clientY,
        //                 selectedTime: value,
        //                 resourceId: resource,
        //             });
        //         },
        //     });
        // },
    };

    const resources = [
        { id: "TTT Hall", title: "TTT Hall" },
        { id: "TTT Hotel Meeting", title: "TTT Hotel Meeting" },
        ...getDataRoom.map((data) => ({ id: data.bhr_id, title: `TTT Hotel ${data.bhr_name}` })),
    ];

    const handleDateChange = (date) => {
        if (date) {
            setCurrentDate(date);
            setSelectedStartTime(prevStartTime => {
                const newStart = prevStartTime ? moment(date).set({ hour: prevStartTime.hour(), minute: prevStartTime.minute() }) : null;
                return newStart;
            });
            setSelectedEndTime(prevEndTime => {
                const newEnd = prevEndTime ? moment(date).set({ hour: prevEndTime.hour(), minute: prevEndTime.minute() }) : null;
                return newEnd;
            });

            setGetDate({
                startDate: moment(date).format('YYYY-MM-DD') + ' ' + (selectedStartTime ? selectedStartTime.format('HH:mm:ss') : '00:00:00'),
                endDate: moment(date).format('YYYY-MM-DD') + ' ' + (selectedEndTime ? selectedEndTime.format('HH:mm:ss') : '23:59:59'),
            });
        }
    };

    const handleRangePickerChange = (times) => {
        if (times && times.length === 2) {
            const [start, end] = times;
            setSelectedStartTime(start);
            setSelectedEndTime(end);
            const formattedStart = dayjs(start).format("YYYY-MM-DD HH:mm:ss");
            const formattedEnd = dayjs(end).format("YYYY-MM-DD HH:mm:ss");
            setGetDate({
                ...getDate,
                startDate: formattedStart,
                endDate: formattedEnd
            });
            getDate.startDate = formattedStart;
            getDate.endDate = formattedEnd;
        } else {
            setSelectedStartTime(null);
            setSelectedEndTime(null);
            setGetDate({
                startDate: moment(currentDate).format('YYYY-MM-DD') + ' ' + ('00:00:00'),
                endDate: moment(currentDate).format('YYYY-MM-DD') + ' ' + ('23:59:59'),
            });
            getDate.startDate = moment(currentDate).format('YYYY-MM-DD') + ' ' + ('00:00:00');
            getDate.endDate = moment(currentDate).format('YYYY-MM-DD') + ' ' + ('23:59:59');
        }
        GetFilterHall();
        GetFilterRoom();
        GetFilterDetailRoom();
        GetFilterHotelMeeting();
    };

    const CustomDateInput = ({ value, onClick }) => (
        <div className="input-group" style={{ cursor: 'pointer', zIndex: "99" }}>
            <div className="input-group-prepend" onClick={onClick} style={{ cursor: 'pointer' }}>
                <i className="far fa-calendar-alt" style={{ marginLeft: "10px" }} />
            </div>
        </div>
    );

    const getMinTime = () => {
        if (selectedStartTime) {
            const min = new Date(currentDate);
            min.setHours(selectedStartTime.hour(), selectedStartTime.minute(), 0, 0);
            return min;
        }
        const min = new Date(currentDate);
        min.setHours(0, 0, 0, 0);
        return min;
    };

    const getMaxTime = () => {
        if (selectedEndTime) {
            const max = new Date(currentDate);
            max.setHours(selectedEndTime.hour(), selectedEndTime.minute(), 0, 0);
            return max;
        }
        const max = new Date(currentDate);
        max.setHours(23, 59, 59, 999);
        return max;
    };


    const handleEventClick = (event) => {
        const id = event.data.appointment.id;
        let targetPath;
        if (event.resource === "TTT Hall") {
            targetPath = `/Human_Resource/booking/booking_AEV_Hall_Reserve/${id}`;
        } else if (event.resource === "TTT Hotel Meeting") {
            targetPath = `/Human_Resource/booking/booking_AEV_Hotel_Meet_Reserve/${id}`;
        } else {
            targetPath = `/Human_Resource/booking/booking_AEV_Hotel_Reserve/${id}`;
        }
        window.location.href = targetPath;
    };

    const handleClickAdd = (event) => {
        let targetPath;
        if (event === "TTT_Hall") {
            targetPath = `/Human_Resource/booking/booking_AEV_Hall_Add`;
        } else if (event === "TTT_Hotel_Meeting") {
            targetPath = `/Human_Resource/booking/booking_AEV_Hotel_Meet_Add`;
        } else if (event === "TTT_Hotel") {
            targetPath = `/Human_Resource/booking/booking_AEV_Hotel_Add`;
        }
        window.location.href = targetPath;
    };

    return (
        <div className="wrapper">
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 style={{ fontWeight: "bold" }}>จองห้อง</h1>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3 d-flex align-items-stretch">
                                    <div
                                        className="card"
                                        style={{ width: '100%', cursor: 'pointer' }}
                                        onClick={() => handleClickAdd("TTT_Hall")}
                                    >
                                        <div className="card-body" style={{ display: 'flex', backgroundColor: 'white', borderRadius: '10px', flexDirection: 'column', alignItems: 'center', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                            <img alt="..." className="img-fluid" style={{ width: "auto", height: "110px", objectFit: "cover", marginBottom: '10px' }} src={TTT_Hall} />
                                            <h3>TTT Hall</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3 d-flex align-items-stretch">
                                    <div
                                        className="card"
                                        style={{ width: '100%', cursor: 'pointer' }}
                                        onClick={() => handleClickAdd("TTT_Hotel_Meeting")}
                                    >
                                        <div className="card-body" style={{ display: 'flex', backgroundColor: 'white', borderRadius: '10px', flexDirection: 'column', alignItems: 'center', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                            <img alt="..." className="img-fluid" style={{ width: "auto", height: "110px", objectFit: "cover", marginBottom: '10px' }} src={TTT_Hotel_Meeting} />
                                            <h3>TTT Hotel Meeting</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3 d-flex align-items-stretch">
                                    <div
                                        className="card"
                                        style={{ width: '100%', cursor: 'pointer' }}
                                        onClick={() => handleClickAdd("TTT_Hotel")}
                                    >
                                        <div className="card-body" style={{ display: 'flex', backgroundColor: 'white', borderRadius: '10px', flexDirection: 'column', alignItems: 'center', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                            <img alt="..." className="img-fluid" style={{ width: "auto", height: "110px", objectFit: "cover", marginBottom: '10px' }} src={TTT_Hotel} />
                                            <h3>TTT Hotel</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="container-fluid">
                        <div className="row">
                        </div>
                    </div> */}

                    <section className="content">
                        <div className="card">
                            <div className="card-header" style={{ margin: 0, padding: '10px' }}>
                                <div className="form-group row" style={{ margin: 0, padding: 0 }}>
                                    <div className="col-12" style={{ padding: 0, zIndex: 99 }}>
                                        <div className="d-flex justify-content-between align-items-center flex-wrap">
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn"
                                                    onClick={() => handleDateChange(subDays(currentDate, 1))}
                                                >
                                                    <span className="fa fa-chevron-left" style={{ verticalAlign: 'middle' }} />
                                                </button>
                                                <span style={{ margin: '0 10px', verticalAlign: 'middle', fontSize: '24px' }}>
                                                    {format(currentDate, 'd MMM yyyy', { locale: th })}
                                                </span>
                                                <button
                                                    type="button"
                                                    className="btn"
                                                    onClick={() => handleDateChange(addDays(currentDate, 1))}
                                                >
                                                    <span className="fa fa-chevron-right" style={{ verticalAlign: 'middle' }} />
                                                </button>
                                                <span style={{ marginLeft: '30px', verticalAlign: 'middle', fontSize: '24px' }}>วันนี้</span>
                                                <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '10px' }}>
                                                    <DatePicker
                                                        selected={currentDate}
                                                        onChange={handleDateChange}
                                                        customInput={<CustomDateInput value={format(currentDate, 'dd MMM yyyy')} />}
                                                    />
                                                </div>
                                            </div>
                                            <div style={{ marginTop: '10px', marginLeft: 'auto' }}>
                                                <Space direction="vertical">
                                                    <TimePicker.RangePicker
                                                        format="HH:mm"
                                                        value={[
                                                            selectedStartTime ? dayjs(selectedStartTime) : null,
                                                            selectedEndTime ? dayjs(selectedEndTime) : null,
                                                        ]}
                                                        onChange={handleRangePickerChange}
                                                    />
                                                </Space>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body table-responsive">
                                <Calendar
                                    localizer={localizer}
                                    events={processedEvents}
                                    resources={resources}
                                    resourceAccessor="resource"
                                    resourceIdAccessor="id"
                                    resourceTitleAccessor="title"
                                    selectable={true}
                                    toolbar={false}
                                    views={["day"]}
                                    defaultView="day"
                                    style={{ height: 700, width: '100%' }}
                                    startAccessor="start"
                                    endAccessor="end"
                                    formats={formats}
                                    date={currentDate}
                                    onNavigate={(date) => {
                                        setCurrentDate(date);
                                    }}
                                    onSelectEvent={handleEventClick}
                                    components={components}
                                    min={getMinTime()}
                                    max={getMaxTime()}
                                    step={step}
                                    timeslots={1}
                                    defaultDate={currentDate}
                                    showCurrentTimeIndicator={false}
                                    className="custom-calendar"
                                />
                            </div>
                        </div>
                    </section>
                </section>
            </div>
        </div>
    );
}

export default Reserve;
