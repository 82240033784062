import React, { Component, useEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import "../../assets/css/Supp.css";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import "../../assets/css/table.css";
import "../../assets/css/slider.css";
import { MDBTable, MDBTableBody, MDBTableFoot, MDBTableHead } from "mdbreact";
import { useParams } from "react-router";

import { getOem, getToken, getUser, getAcademy } from "../../Utils/Common";
import Configs from "../../config";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import axios from "axios";
import NumberFormat from "react-number-format";

import moment from "moment";

function Mat_stc_add(mode) {
  const { id, date } = useParams();
  const [stcData, setStcData] = useState([]);
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);
  const [month, setMounth] = useState(moment(date, "YYYY-MM").daysInMonth());
  const [currentViewYearMonth, setCurrentViewYearMonth] = useState({month:moment(date,"YYYY-MM").startOf("month").format('MMMM'),year:moment(date, "YYYY").year()});
  const [month_th , setmonth_th]= useState({
    month:"",
  });
  
                 

  const [year, setYear] = useState(null);
  const [mat_type, setmat_type] = useState({
    company_id: getUser().com,
    mat_type: "",
    remark: "",
  });
  const [highlightToday, sethighlightToday] = useState({date_toda: moment(new Date(), "YYYY-MM-DD").date(),
  });
  const [stc_data_select, setstc_data_select] = useState({
    id: "",
    mortar_silo_1: "",
    mortar_silo_2: "",
    fly_ash_silo: "",
    mortar_ssm_1: "",
    mortar_ssm_2: "",
    flyAsh_ssm: "",
    surface_retarder_ssm: "",
    sand_ssm: "",
    rock_ssm: "",
    mortar_express: "",
    flyAss_express: "",
    surface_retarder_express: "",
    sand_express: "",
    rock_express: "",
    day: "",
    item_material_date_id: "",
    number_in: "",
    mortart_in:"",
    fly_ash_kg:"",
    rock_3_8_in:"",
    rock_3_4_in:"",
    sand_in:"",
    surface_retarder_in_2:"",
    remark:"",
    is_up: false,
  });
  console.log("state = ",stc_data_select);

  const stock_month = [
    {"th":"มกราคม","eng":"January"},
    {"th":"กุมภาพันธ์","eng":"February"},
    {"th":"มีนาคม","eng":"March"},
    {"th":"เมษายน","eng":"April"},
    {"th":"พฤษภาคม","eng":"May"},
    {"th":"มิถุนายน","eng":"June"},
    {"th":"กรกฎาคม","eng":"July"},
    {"th":"สิงหาคม","eng":"August"},
    {"th":"กันยายน","eng":"September"},
    {"th":"ตุลาคม","eng":"October"},
    {"th":"พฤศจิกายน","eng":"November"},
    {"th":"ธันวาคม","eng":"December"},
  ];

  
  useEffect(() => {
    var month_th = stock_month.filter((e)=>{return e.eng === currentViewYearMonth.month});
    setmonth_th({...month_th,month:currentViewYearMonth.month});

    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }

   /*  console.log(highlightToday); */
  }, []);

  const GetAllMaterialValue = async () => {
    await axios({
      method: "get",
      url: Configs.API_URL + "/materialSTC/getAllMaterialValue?id=" + id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        let _temp_data = [];
         console.log("viewwwww",response.data); 
        for (
          let index_data = 0;
          index_data < response.data.length;
          index_data++
        ) {
          let element = {};
          element["id"] = response.data[index_data].id;
          element["mortar_silo_1"] = response.data[index_data].mortar_silo_1;
          element["mortar_silo_2"] = response.data[index_data].mortar_silo_2;
          element["fly_ash_silo"] = response.data[index_data].fly_ash_silo;
          element["mortar_ssm_1"] = response.data[index_data].mortar_ssm_1;
          element["mortar_ssm_2"] = response.data[index_data].mortar_ssm_2;
          element["flyAsh_ssm"] = response.data[index_data].flyAsh_ssm;
          element["surface_retarder_ssm"] =
            response.data[index_data].surface_retarder_ssm;
          element["sand_ssm"] = response.data[index_data].sand_ssm;
          element["rock_ssm"] = response.data[index_data].rock_ssm;
          element["mortar_express"] = response.data[index_data].mortar_express;
          element["flyAss_express"] = response.data[index_data].flyAss_express;
          element["surface_retarder_express"] =
            response.data[index_data].surface_retarder_express;
          element["sand_express"] = response.data[index_data].sand_express;
          element["rock_express"] = response.data[index_data].rock_express;
          element["day"] = response.data[index_data].day;
          element["item_material_date_id"] =
            response.data[index_data].item_material_date_id;
          element["mortar_elephant_in"] =
            response.data[index_data].mortar_elephant_in ;
          element["mortar_eagle_in"] =
            response.data[index_data].mortar_eagle_in ;
          element["fly_Ash_in"] = response.data[index_data].fly_Ash_in ;
          element["surface_retarder_in"] =
            response.data[index_data].surface_retarder_in;
          element["number_in"] = response.data[index_data].number_in ;
          element["mortart_in"] = response.data[index_data].mortart_in ;
          element["fly_ash_kg"] = response.data[index_data].fly_ash_kg ;
          element["rock_3_8_in"] = response.data[index_data].rock_3_8_in ;
          element["rock_3_4_in"] = response.data[index_data].rock_3_4_in ;
          element["sand_in"] = response.data[index_data].sand_in ;
          element["surface_retarder_in_2"] =
            response.data[index_data].surface_retarder_in_2;
          element["update_by"] = response.data[index_data].update_by ;
          element["remark"] = response.data[index_data].remark;

          _temp_data.push(element);
        }
        console.log("resport",response.data);
         console.log("TempFromBase",_temp_data); 

        /*  for (
          let index_data = 0;
          index_data < 5;
          index_data++
        ) {
          let element = {};
          element["id"] = index_data+1;
          element["mortar_silo_1"] = index_data+1;
          element["mortar_silo_2"] = index_data+1;
          element["fly_ash_silo"] = index_data+1;
          element["mortar_ssm_1"] = index_data+1;
          element["mortar_ssm_2"] = index_data+1;
          element["flyAsh_ssm"] = index_data+1;
          element["surface_retarder_ssm"] = index_data+1;
          element["sand_ssm"] =index_data+1;
          element["rock_ssm"] = index_data+1;
          element["mortar_express"] = index_data+1;
          element["flyAss_express"] = index_data+1;
          element["surface_retarder_express"] = index_data+1;
          element["sand_express"] =index_data+1;
          element["rock_express"] = index_data+1;
          element["day"] =index_data+1;
          element["item_material_date_id"] =index_data+1;
          element["mortar_elephant_in"] =index_data+1;
          element["mortar_eagle_in"] =index_data+1;
          element["fly_Ash_in"] =index_data+1;
          element["surface_retarder_in"] =index_data+1;
          element["number_in"] =index_data+1;
          element["mortart_in"] =index_data+1;
          element["fly_ash_kg"] =index_data+1;
          element["rock_3_8_in"] =index_data+1;
          element["rock_3_4_in"] =index_data+1;
          element["sand_in"] =index_data+1;
          element["surface_retarder_in_2"] =index_data+1;
          element["update_by"] =index_data+1;
          element["remark"] =index_data+1;

          _temp_data.push(element);
        }*/
        setStcData(_temp_data);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    GetAllMaterialValue();
  }, []);
  function form(day, id) {
    let fine_day = stcData.filter((el) => el.day === day);
    if (fine_day.length > 0) {
      setstc_data_select({ ...fine_day[0] });
    } else {
      setstc_data_select({ ...stc_data_select, id: id, day: day });
    }
  }
const fixedHeader = {
  "height": "100px",
  "min-height": "100px",
  "position": "sticky",
  "position": "-webkit-sticky",
  "background": "white",
  "top": "0"
}
  function save_selected() {
    let filter_seq_data = stcData.filter((e) => e.day === stc_data_select.day);
    console.log("filter_seq_data",filter_seq_data);
    if (filter_seq_data.length > 0) {
      var index = filter_seq_data.findIndex((e) => {
        return e.day === stc_data_select.day;
      
      });
      console.log("sssq",index);
      filter_seq_data[index].mortar_silo_1 = stc_data_select.mortar_silo_1;
      filter_seq_data[index].mortar_silo_2 = stc_data_select.mortar_silo_2;
      filter_seq_data[index].fly_ash_silo = stc_data_select.fly_ash_silo;

      filter_seq_data[index].mortar_express = stc_data_select.mortar_express;
      filter_seq_data[index].flyAss_express = stc_data_select.flyAss_express;
      filter_seq_data[index].surface_retarder_express =
        stc_data_select.surface_retarder_express;
      filter_seq_data[index].sand_express = stc_data_select.sand_express;
      filter_seq_data[index].rock_express = stc_data_select.rock_express;

      filter_seq_data[index].number_in = stc_data_select.number_in;
      filter_seq_data[index].mortart_in = stc_data_select.mortart_in;
      filter_seq_data[index].fly_ash_kg = stc_data_select.fly_ash_kg;
      filter_seq_data[index].rock_3_8_in = stc_data_select.rock_3_8_in;
      filter_seq_data[index].rock_3_4_in = stc_data_select.rock_3_4_in;
      filter_seq_data[index].sand_in = stc_data_select.sand_in;
      filter_seq_data[index].surface_retarder_in_2 = stc_data_select.surface_retarder_in_2;
      filter_seq_data[index].remark = stc_data_select.remark;
      filter_seq_data[index].is_up = true;

      /* 
      stcData[index].mortar_silo_1 = stc_data_select.mortar_silo_1;
      stcData[index].mortar_silo_2 = stc_data_select.mortar_silo_2;
      stcData[index].fly_ash_silo = stc_data_select.fly_ash_silo;
   
      stcData[index].mortar_express = stc_data_select.mortar_express;
      stcData[index].flyAss_express = stc_data_select.flyAss_express;
      stcData[index].surface_retarder_express = stc_data_select.surface_retarder_express;
      stcData[index].sand_express = stc_data_select.sand_express;
      stcData[index].rock_express = stc_data_select.rock_express;
 */


      console.log("old_data", filter_seq_data);

      clear_data();
    } else {
      var element = {};

      element["mortar_silo_1"] = stc_data_select.mortar_silo_1;
      element["mortar_silo_2"] = stc_data_select.mortar_silo_2;
      element["fly_ash_silo"] = stc_data_select.fly_ash_silo;

      element["mortar_express"] = stc_data_select.mortar_express;
      element["flyAss_express"] = stc_data_select.flyAss_express;
      element["surface_retarder_express"] =
        stc_data_select.surface_retarder_express;
      element["sand_express"] = stc_data_select.sand_express;
      element["rock_express"] = stc_data_select.rock_express;
      element["day"] = stc_data_select.day;
      element["item_material_date_id"] = id;


      element["number_in"] = stc_data_select.number_in;
      element["mortart_in"] = stc_data_select.mortart_in;
      element["fly_ash_kg"] = stc_data_select.fly_ash_kg;
      element["rock_3_8_in"] = stc_data_select.rock_3_8_in;
      element["rock_3_4_in"] = stc_data_select.rock_3_4_in;
      element["sand_in"] = stc_data_select.sand_in;
      element["surface_retarder_in_2"] = stc_data_select.surface_retarder_in_2;
      element["remark"] = stc_data_select.remark;
      // element["is_up"] = stc_data_select.is_up;
      
      /*      
      filter_seq_data[index] = element; */

      stcData.push(element);

      clear_data();
    }
  }

  let tableData = [];

  for (let index = 0; index < month; index++) {
    const element = {};

    let filter_seq_data = stcData.filter((e) => e.day === index + 1);
    /*    console.log("dataInMount",filter_seq_data); */
    if (filter_seq_data.length > 0) {
      /*       console.log(); */

      element["id"] = filter_seq_data[0].id;
      element["mortar_silo_1"] = filter_seq_data[0].mortar_silo_1;
      element["mortar_silo_2"] = filter_seq_data[0].mortar_silo_2;
      element["fly_ash_silo"] = filter_seq_data[0].fly_ash_silo;
      element["mortar_ssm_1"] = filter_seq_data[0].mortar_ssm_1;
      element["mortar_ssm_2"] = filter_seq_data[0].mortar_ssm_2;
      element["flyAsh_ssm"] = filter_seq_data[0].flyAsh_ssm;
      element["surface_retarder_ssm"] = filter_seq_data[0].surface_retarder_ssm;
      element["sand_ssm"] = filter_seq_data[0].sand_ssm;
      element["rock_ssm"] = filter_seq_data[0].rock_ssm;
      element["mortar_express"] = filter_seq_data[0].mortar_express;
      element["flyAss_express"] = filter_seq_data[0].flyAss_express;
      element["surface_retarder_express"] =
        filter_seq_data[0].surface_retarder_express;
      element["sand_express"] = filter_seq_data[0].sand_express;
      element["rock_express"] = filter_seq_data[0].rock_express;
      element["day"] = filter_seq_data[0].day;
      element["item_material_date_id"] =
        filter_seq_data[0].item_material_date_id;
      element["mortar_elephant_in"] = filter_seq_data[0].mortar_elephant_in;
      element["mortar_eagle_in"] = filter_seq_data[0].mortar_eagle_in;
      element["fly_Ash_in"] = filter_seq_data[0].fly_Ash_in;
      element["surface_retarder_in"] = filter_seq_data[0].surface_retarder_in;
      element["number_in"] = filter_seq_data[0].number_in;
      element["mortart_in"] = filter_seq_data[0].mortart_in;
      element["fly_ash_kg"] = filter_seq_data[0].fly_ash_kg;
      element["rock_3_8_in"] = filter_seq_data[0].rock_3_8_in;
      //element["rock_3_8_in"] = filter_seq_data[0].rock_3_8_in;
      element["rock_3_4_in"] = filter_seq_data[0].rock_3_4_in;
      element["sand_in"] = filter_seq_data[0].sand_in;
      element["surface_retarder_in_2"] =
        filter_seq_data[0].surface_retarder_in_2;
      element["update_by"] = filter_seq_data[0].update_by;
      element["remark"] = filter_seq_data[0].remark;
      
      /* element = filter_seq_data[0]; */
    } else {
      element["id"] = "";
      element["mortar_silo_1"] = "";
      element["mortar_silo_2"] = "";
      element["fly_ash_silo"] = "";
      element["mortar_ssm_1"] = "";
      element["mortar_ssm_2"] = "";
      element["flyAsh_ssm"] = "";
      element["surface_retarder_ssm"] = "";
      element["sand_ssm"] = "";
      element["rock_ssm"] = "";
      element["mortar_express"] = "";
      element["flyAss_express"] = "";
      element["surface_retarder_express"] = "";
      element["sand_express"] = "";
      element["rock_express"] = "";
      element["day"] = index + 1;
      element["item_material_date_id"] = "";
      element["mortar_elephant_in"] = "";
      element["mortar_eagle_in"] = "";
      element["fly_Ash_in"] = "";
      element["surface_retarder_in"] = "";
      element["number_in"] = "";
      element["mortart_in"] = "";
      element["fly_ash_kg"] = "";
      element["rock_3_8_in"] = "";
      element["rock_3_4_in"] = "";
      element["sand_in"] = "";
      element["surface_retarder_in_2"] = "";
      element["update_by"] = "";
      element["remark"] = "";
    }
    tableData.push(element);
  }

  function clear_data(params) {
    setstc_data_select({
      id: "",
      mortar_silo_1: "",
      mortar_silo_2: "",
      fly_ash_silo: "",
      mortar_ssm_1: "",
      mortar_ssm_2: "",
      flyAsh_ssm: "",
      surface_retarder_ssm: "",
      sand_ssm: "",
      rock_ssm: "",
      mortar_express: "",
      flyAss_express: "",
      surface_retarder_express: "",
      sand_express: "",
      rock_express: "",
      day: "",
      item_material_date_id: "",
      number_in: "",
      mortart_in:"",
      fly_ash_kg:"",
      rock_3_8_in:"",
      rock_3_4_in:"",
      sand_in:"",
      surface_retarder_in_2:"",
      remark:"",
    });
  }
  const tableHeader = {
    headerColums: [
      {
        label: "วัน",
        field: "day",
        colspan: "",
        rowspan: "2",
        style: {
          textAliment: "center",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "STC (Silo)",
        field: "stc_cilo",
        colspan: "3",
        rowspan: "",
        style: {
          textAliment: "center",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "SSM",
        field: "ssm",
        colspan: "6",
        rowspan: "",
        style: {
          textAliment: "center",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "Express",
        field: "express",
        colspan: "5",
        rowspan: "",
        style: {
          textAliment: "center",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "Mgt",
        field: "mgt",
        colspan: "",
        rowspan: "2",
        style: {
          textAliment: "center",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "Daily Update",
        field: "daily_update",
        colspan: "14",
        style: {
          textAliment: "center",
          whiteSpace: "nowrap",
        },
      },
    ],
    columns: [
      {
        label: "ปูนผง 1 (kg)",
        field: "mortar_silo_1",
        colspan: "",

        style: {
          textAliment: "center",
          width: "9 rem",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "ปูนผง 2 (kg)",
        field: "mortar_silo_2",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "Fly Ash(kg)",
        field: "fly_ash_silo",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "ปูนผง 1 (kg)",
        field: "mortar_ssm_1",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "ปูนผง 2 (kg)",
        field: "mortar_ssm_2",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "Fly Ash (kg)",
        field: "flyAsh_ssm",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "น้ำยาหน่วง(ml)",
        field: "surface_retarder_ssm",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "ทรายหยาบ (kg)",
        field: "sand_ssm",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "หิน 3/4 (kg)",
        field: "rock_ssm",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "ปูนผง(kg)",
        field: "mortar_express",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "Fly Ash (kg)",
        field: "flyAss_express",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "น้ำยาหน่วง (ml)",
        field: "surface_retarder_express",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "ทรายหยาบ (kg)",
        field: "sand_express",
        colspan: "",
        style: {
          textAliment: "center",
          whiteSpace: "nowrap",
          width: "",
        },
      },
      {
        label: "หิน 3/4 (kg)",
        field: "rock_express",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "ปูนช้าง (kg)",
        field: "mortar_elephant_in",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "ปูนอินทรีย์ (kg)",
        field: "mortar_eagle_in",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "Fly Ash(kg)",
        field: "fly_Ash_in",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "น้ำยาหน่วง(ml)",
        field: "surface_retarder_in",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },

      {
        label: "เลขที่ (TK)",
        field: "number_in",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "ปูนผง(kg)",
        field: "mortart_in",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "Fly Ash (kg)",
        field: "fly_ash_kg",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "หิน 3/8 (kg)",
        field: "rock_3_8_in",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "หิน 3/4 (kg)",
        field: "rock_3_4_in",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "ทราบหยาบ(kg)",
        field: "sand_in",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "น้ำยาหน่วง(ml)",
        field: "surface_retarder_in_2",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "Mgt",
        field: "mgt",
        colspan: "",
        rowspan: "2",
        style: {
          textAliment: "center",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "ผู้บันทึก",
        field: "update_by",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "หมายเหตุ",
        field: "remark",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
    ],
    rows: tableData,
  };

  useEffect(() => {}, []);

  const GetSupp_view = async () => {
    if (mode.mode == "read" || mode.mode == "edit") {
    }
  };

  function saveOption(params) {
    const temp = {
      company_id: getUser().com,
      oem_id: getOem(),
      data_list: stcData,
    };
    console.log(temp);
    Swal.fire({
      title: "Saving",
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 1000,
      onOpen: () => {
        Swal.showLoading();
      },
    }).then((result) => {
      axios({
        method: "post",
        url: Configs.API_URL + "/materialSTC/updateAllStcMaterail",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: temp,
      })
        .then(function (response) {
          if (response.data) {
            Swal.fire({
              icon: "success",
              title: "Save",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              window.location.href = "/Warehouse_Management/Mat_stc";
            });
          }
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", error.response.data.message, "error");
        });
    });
  }
  /* 
  function cancleOption(params) {
    Swal.fire("Do you want to cancel?", "Wait", "warning").then(() => {
      window.location.href = "/Warehouse_Management/Mat_stc";
    });
  } */

  function cancleOption(params) {
    Swal.fire({
      title: "Do you want to cancel and leave the changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "/Warehouse_Management/Mat_stc";
      }
    });
  }

  return (
    <div className="wrapper">
      {/*  <Header />
    <Sidebar menu="warehouse" activemenu="suppmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
            
                <h1>วัตถุดิบ STC {pageMode} {"ปี : "+currentViewYearMonth.year + "     เดือน:  " +month_th.month/* currentViewYearMonth.month */}{"  "}<a target="_blank" href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">วัตถุดิบ STC</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row mb-2">
                  <div className="col-4 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                       <div className="col-4 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-4 col-md-3 col-xl-2">
                  <button
                    type="button"
                    if
                    onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">รายงานวัตถุดิบประจำวัน (รายเดือน)</h3>
              <div className="card-tools">
                    <ReactHTMLTableToExcel
                          id="test-table-xls-button"
                          className="btn btn-block btn-primary"
                          table="table-to-xls"
                          filename={"รายงาน Stock ประจำวัน " + moment(new Date()).format('DD-MM-yyyy')}
                          sheet="tablexls"
                          buttonText="รายงาน Stock"
                        />
              </div>
            </div>
          {/* <div className="card-body table-responsive p-0" style={{height: 300}}>
  <table className="table table-head-fixed text-nowrap">
    <thead>
      <tr>
        <th>ID</th>
        <th>User</th>
        <th>Date</th>
        <th>Status</th>
        <th>Reason</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>183</td>
        <td>John Doe</td>
        <td>11-7-2014</td>
        <td><span className="tag tag-success">Approved</span></td>
        <td>Bacon ipsum dolor sit amet salami venison chicken flank fatback doner.</td>
      </tr>
      <tr>
        <td>219</td>
        <td>Alexander Pierce</td>
        <td>11-7-2014</td>
        <td><span className="tag tag-warning">Pending</span></td>
        <td>Bacon ipsum dolor sit amet salami venison chicken flank fatback doner.</td>
      </tr>
      <tr>
        <td>657</td>
        <td>Bob Doe</td>
        <td>11-7-2014</td>
        <td><span className="tag tag-primary">Approved</span></td>
        <td>Bacon ipsum dolor sit amet salami venison chicken flank fatback doner.</td>
      </tr>
      <tr>
        <td>175</td>
        <td>Mike Doe</td>
        <td>11-7-2014</td>
        <td><span className="tag tag-danger">Denied</span></td>
        <td>Bacon ipsum dolor sit amet salami venison chicken flank fatback doner.</td>
      </tr>
      <tr>
        <td>134</td>
        <td>Jim Doe</td>
        <td>11-7-2014</td>
        <td><span className="tag tag-success">Approved</span></td>
        <td>Bacon ipsum dolor sit amet salami venison chicken flank fatback doner.</td>
      </tr>
      <tr>
        <td>494</td>
        <td>Victoria Doe</td>
        <td>11-7-2014</td>
        <td><span className="tag tag-warning">Pending</span></td>
        <td>Bacon ipsum dolor sit amet salami venison chicken flank fatback doner.</td>
      </tr>
      <tr>
        <td>832</td>
        <td>Michael Doe</td>
        <td>11-7-2014</td>
        <td><span className="tag tag-primary">Approved</span></td>
        <td>Bacon ipsum dolor sit amet salami venison chicken flank fatback doner.</td>
      </tr>
      <tr>
        <td>982</td>
        <td>Rocky Doe</td>
        <td>11-7-2014</td>
        <td><span className="tag tag-danger">Denied</span></td>
        <td>Bacon ipsum dolor sit amet salami venison chicken flank fatback doner.</td>
      </tr>
    </tbody>
  </table>
</div> */}

            <div
              className="card-body table-responsive p-0 "
              style={{ textAlign: "center", height: "820px",whiteSpace: 'nowrap'}}
            >
              <MDBTable bordered className="scrollbar table  text-nowrap " >
                <MDBTableHead >
                  <tr>
                    {tableHeader.headerColums.map((el) => {
                      return (
                        <th
                        
                          key={el.field}
                          colspan={el.colspan}
                          rowSpan={el.rowspan}
                          style={{
                            textAlign: el.style.textAliment,
                            whiteSpace: el.style.whiteSpace,
                            marginLeft: el.style.marginLeft,
                            marginRight: el.style.marginRight,
                            "position": "sticky",
                            "top": "0"
                          }}
                        >
                          {el.label}
                        </th>
                      );
                    })}
                  </tr>
                  <tr >
                    {tableHeader.columns.map((el) => {
                      return (
                        <th
                        className="fixHeader-pmrp"
                          key={el.field}
                          colspan={el.colspan}
                          style={{
                            textAlign: el.style.textAliment,
                            whiteSpace: el.style.whiteSpace,
                            "position": "sticky",
                            "top": "54.3px",
                     
                          }}
                        >
                          {el.label}
                        </th>
                      );
                    })}
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {tableHeader.rows.map((el, i) => {
                    console.log(el)
                    return (
                      <tr key={i} style={{backgroundColor:highlightToday.date_toda === el.day ? "#6dabd1":""}}>
                        <td>{el.day}</td>
                        <td>
                          <NumberFormat
                            value={el.mortar_silo_1}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.mortar_silo_2}
                            displayType="text"
                            thousandSeparator={true}
                          />{" "}
                        </td>
                        <td>
                          <NumberFormat
                            value={el.fly_ash_silo}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.mortar_ssm_1}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.mortar_ssm_2}
                            displayType="text"
                            thousandSeparator={true}
                          />{" "}
                        </td>
                        <td>
                          <NumberFormat
                            value={el.flyAsh_ssm}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.surface_retarder_ssm}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.sand_ssm}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.rock_ssm}
                            displayType="text"
                            thousandSeparator={true}
                          />{" "}
                        </td>
                        <td>
                          <NumberFormat
                            value={el.mortar_express}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.flyAss_express}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.surface_retarder_express}
                            displayType="text"
                            thousandSeparator={true}
                          />{" "}
                        </td>
                        <td>
                          <NumberFormat
                            value={el.sand_express}
                            displayType="text"
                            thousandSeparator={true}
                          />{" "}
                        </td>
                        <td>
                          <NumberFormat
                            value={el.rock_express}
                            displayType="text"
                            thousandSeparator={true}
                          />{" "}
                        </td>

                        <td>
                    
                              <a
                                className=" btn btn-xs "
                                data-toggle="modal"
                                data-target="#modal-add_mat_stc"
                                onClick={form.bind(this, el.day, el.id)}
                              >
                                {" "}
                                <i class="fas fa-pencil-alt"></i>
                              </a>
                      
                        </td>

                        <td>
                          <NumberFormat
                            value={el.mortar_elephant_in}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.mortar_eagle_in}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.fly_Ash_in}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.surface_retarder_in}
                            displayType="text"
                            thousandSeparator={true}
                          />{" "}
                        </td>
                        <td>
                          <NumberFormat
                            value={el.number_in}
                            displayType="text"
                            thousandSeparator={true}
                          />{" "}
                        </td>
                        <td>
                          <NumberFormat
                            value={el.mortart_in}
                            displayType="text"
                            thousandSeparator={true}
                          />{" "}
                        </td>
                        <td>
                          <NumberFormat
                            value={el.fly_ash_kg}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.rock_3_8_in}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.rock_3_4_in}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.sand_in}
                            displayType="text"
                            thousandSeparator={true}
                          />{" "}
                        </td>
                        <td>
                          <NumberFormat
                            value={el.surface_retarder_in_2}
                            displayType="text"
                            thousandSeparator={true}
                          />{" "}
                        </td>
                        <td>
                        
                              <a
                                className=" btn btn-xs "
                                data-toggle="modal"
                                data-target="#modal-add_mat_stc_daily_update"
                                onClick={form.bind(this, el.day, el.id)}
                              >
                                {" "}
                                <i class="fas fa-pencil-alt"></i>
                              </a>
                          
                        </td>
                        <td>{el.update_by} </td>
                        <td>{el.remark} </td>
                        {/* <td>
                          <input
                            value={el.remark}
                            displayType="text"
                            thousandSeparator={true}
                          />{" "}
                        </td> */}

                       
                      </tr>
                    );
                  })}
                </MDBTableBody>
              </MDBTable>

              


              
            </div>

            <div
              className="card-body table-responsive table-bordered "
              style={{ textAlign: "center", maxHeight: "820px","display":"none", "z-index": "99" }}
            >
              <MDBTable bordered className="scrollbar"    id="table-to-xls">
                <MDBTableHead  >
                  <tr >
                   {/*  <th key={}
                          rowSpan={}>
                          </th> */}
                    {tableHeader.headerColums.map((el) => {
                  /*      if (el.field != "mgt") { */ return (
                        
                        <th
                          key={el.field}
                          colspan={el.colspan}
                          rowSpan={el.rowspan}
                          style={{
                            textAlign: el.style.textAliment,
                            whiteSpace: el.style.whiteSpace,
                            marginLeft: el.style.marginLeft,
                            marginRight: el.style.marginRight,
                          }}
                        >
                          {el.label}
                        </th>
                      );/* } */
                    })}
                  </tr>
                  <tr>
                    {tableHeader.columns.map((el) => {
                      return (
                        <th
                          key={el.field}
                          colspan={el.colspan}
                          style={{
                            textAlign: el.style.textAliment,
                            whiteSpace: el.style.whiteSpace,
                          }}
                        >
                          {el.label}
                        </th>
                      );
                    })}
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {tableHeader.rows.map((el, i) => {
                    return (
                      <tr key={i} style={{backgroundColor:highlightToday.date_toda === el.day ? "#6dabd1":""}}>
                        <td>{el.day}</td>
                        <td>
                          <NumberFormat
                            value={el.mortar_silo_1}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.mortar_silo_2}
                            displayType="text"
                            thousandSeparator={true}
                          />{" "}
                        </td>
                        <td>
                          <NumberFormat
                            value={el.fly_ash_silo}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.mortar_ssm_1}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.mortar_ssm_2}
                            displayType="text"
                            thousandSeparator={true}
                          />{" "}
                        </td>
                        <td>
                          <NumberFormat
                            value={el.flyAsh_ssm}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.surface_retarder_ssm}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.sand_ssm}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.rock_ssm}
                            displayType="text"
                            thousandSeparator={true}
                          />{" "}
                        </td>
                        <td>
                          <NumberFormat
                            value={el.mortar_express}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.flyAss_express}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.surface_retarder_express}
                            displayType="text"
                            thousandSeparator={true}
                          />{" "}
                        </td>
                        <td>
                          <NumberFormat
                            value={el.sand_express}
                            displayType="text"
                            thousandSeparator={true}
                          />{" "}
                        </td>
                        <td>
                          <NumberFormat
                            value={el.rock_express}
                            displayType="text"
                            thousandSeparator={true}
                          />{" "}
                        </td>
                        <td></td>
                        <td>
                          <NumberFormat
                            value={el.mortar_elephant_in}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.mortar_eagle_in}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.fly_Ash_in}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.surface_retarder_in}
                            displayType="text"
                            thousandSeparator={true}
                          />{" "}
                        </td>
                        <td>
                          <NumberFormat
                            value={el.number_in}
                            displayType="text"
                            thousandSeparator={true}
                          />{" "}
                        </td>
                        <td>
                          <NumberFormat
                            value={el.mortart_in}
                            displayType="text"
                            thousandSeparator={true}
                          />{" "}
                        </td>
                        <td>
                          <NumberFormat
                            value={el.fly_ash_kg}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.rock_3_8_in}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.rock_3_4_in}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={el.sand_in}
                            displayType="text"
                            thousandSeparator={true}
                          />{" "}
                        </td>
                        <td>
                          <NumberFormat
                            value={el.surface_retarder_in_2}
                            displayType="text"
                            thousandSeparator={true}
                          />{" "}
                        </td>
                        <td></td>
                        <td>{el.update_by} </td>
                        {/* <td>
                          <input
                            value={el.remark}
                            displayType="text"
                            thousandSeparator={true}
                          />{" "}
                        </td> */}
                        <td>{el.remark} </td>

                       
                      </tr>
                    );
                  })}
                </MDBTableBody>
              </MDBTable>

              


              
            </div>


{/* โมเดล หน้าแอด พี่วุธ*/}
            <div className="modal fade" id="modal-add_mat_stc">
              <div className="modal-dialog modal-xl">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-label">Add</h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={clear_data.bind(this)}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>

                  {/*  <div className="container-fluid">
                    <div className="row">
                      <div className="col-sm-10"></div>
                      <div className="col-sm-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                         
                            data-dismiss="modal"
                          >
                            อัพเดท SSM
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="modal-body">
                    <div class="row">
                      <div class="ccol-sm-6 col-lg-6 col-md-6">
                        <div class="card">
                          <div class="card-body">
                            <h1>STC</h1>
                            <p class="card-text"></p>

                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-sm-8 col-lg-8 col-md-8">
                                  <div class="form-group">
                                    <NumberFormat
                                      className="form-control"
                                      allowNegative={false}
                                      decimalScale = {2}
                                      value={stc_data_select.mortar_silo_1}
                                      disabled={disable}
                                      onChange={(e) => {
                                        setstc_data_select({
                                          ...stc_data_select,
                                          mortar_silo_1: e.target.value,
                                        });
                                      }}
                                      thousandSeparator={true}
                                    />

                                    <label>ปูนผง 1 (ตัน)</label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-sm-8 col-lg-8 col-md-8">
                                  <div class="form-group">
                                    <NumberFormat
                                      className="form-control"
                                      allowNegative={false}
                                      decimalScale = {2}
                                      value={stc_data_select.mortar_silo_2}
                                      disabled={disable}
                                      onChange={(e) => {
                                        setstc_data_select({
                                          ...stc_data_select,
                                          mortar_silo_2: e.target.value,
                                        });
                                      }}
                                      thousandSeparator={true}
                                    />

                                    <label>ปูนผง 2 (ตัน)</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-sm-8 col-lg-8 col-md-8">
                                  <div class="form-group">
                                    <NumberFormat
                                      className="form-control"
                                      value={stc_data_select.fly_ash_silo}
                                      allowNegative={false}
                                      decimalScale = {2}
                                      disabled={disable}
                                      onChange={(e) => {
                                        setstc_data_select({
                                          ...stc_data_select,
                                          fly_ash_silo: e.target.value,
                                        });
                                      }}
                                      thousandSeparator={true}
                                    />

                                    <label>Fly Ash</label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-sm-6 col-lg-6 col-md-6">
                                <div class="form-group">
                                  <label></label>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-sm-6 col-lg-6 col-md-6">
                                <div class="form-group">
                                  <label></label>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-sm-6 col-lg-6 col-md-6">
                                <div class="form-group">
                                  <label></label>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-sm-6 col-lg-6 col-md-6">
                                <div class="form-group">
                                  <label></label>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-sm-6 col-lg-6 col-md-6">
                                <div class="form-group">
                                  <label></label>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-sm-6 col-lg-6 col-md-6">
                                <div class="form-group">
                                  <label></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div class="col-sm-4">
                        <div class="card">
                          <div class="card-body">
                            <h3 class="card-title">SSM</h3>
                            <p class="card-text"></p>

                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-sm-6 col-lg-6 col-md-6">
                                  <div class="form-group">
                                  <NumberFormat
                                    className="form-control"
                                  
                                   value={stc_data_select.mortar_ssm_1}
                                      disabled={disable}
                                      onChange={(e) => {
                                        setstc_data_select({
                                          ...stc_data_select,
                                          mortar_ssm_1: e.target.value,
                                        });
                                      }}
                                    
                                    thousandSeparator={true}
                                  />
                                    <input
                                    displayType="text"
                                      className="form-control"
                                      required
                                     
                                    />
                                    <label>ปูนผง 1 (kg)</label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-sm-6 col-lg-6 col-md-6">
                                  <div class="form-group">
                                    <input
                                    displayType="text"
                                      className="form-control"
                                      required
                                      value={stc_data_select.ssm2}
                                      disabled={disable}
                                      onChange={(e) => {
                                        setstc_data_select({
                                          ...stc_data_select,
                                          ssm2: e.target.value,
                                        });
                                      }}
                                    />
                                    <label>ปูนผง 2 (kg)</label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-sm-6 col-lg-6 col-md-6">
                                  <div class="form-group">
                                    <input
                                    displayType="text"
                                      className="form-control"
                                      required
                                      value={stc_data_select.ssm3}
                                      disabled={disable}
                                      onChange={(e) => {
                                        setstc_data_select({
                                          ...stc_data_select,
                                          ssm3: e.target.value,
                                        });
                                      }}
                                    />
                                    <label>Fly Ash (kg)</label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-sm-6 col-lg-6 col-md-6">
                                  <div class="form-group">
                                    <input
                                    displayType="text"
                                      className="form-control"
                                      required
                                      value={stc_data_select.ssm4}
                                      disabled={disable}
                                      onChange={(e) => {
                                        setstc_data_select({
                                          ...stc_data_select,
                                          ssm4: e.target.value,
                                        });
                                      }}
                                    />
                                    <label>น้ำยาหน่วง (ลิตร)</label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-sm-6 col-lg-6 col-md-6">
                                  <div class="form-group">
                                    <input
                                    displayType="text"
                                      className="form-control"
                                      required
                                      value={stc_data_select.ssm5}
                                      disabled={disable}
                                      onChange={(e) => {
                                        setstc_data_select({
                                          ...stc_data_select,
                                          ssm5: e.target.value,
                                        });
                                      }}
                                    />
                                    <label>ทรายหยาบ (kg)</label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-sm-6 col-lg-6 col-md-6">
                                  <div class="form-group">
                                    <input
                                    displayType="text"
                                      className="form-control"
                                      required
                                      value={stc_data_select.ssm6}
                                      disabled={disable}
                                      onChange={(e) => {
                                        setstc_data_select({
                                          ...stc_data_select,
                                          ssm6: e.target.value,
                                        });
                                      }}
                                    />
                                    <label>หิน 3/4 (kg)</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      <div class="col-sm-6 col-lg-6 col-md-6">
                        <div class="card">
                          <div class="card-body">
                          <h1>Express</h1>
                    
                            <p class="card-text"></p>

                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-sm-8 col-lg-8 col-md-8">
                                  <div class="form-group">
                                    <NumberFormat
                                      allowNegative={false}
                                      decimalScale = {2}
                                      className="form-control"
                                      value={stc_data_select.mortar_express}
                                      disabled={disable}
                                      onChange={(e) => {
                                        setstc_data_select({
                                          ...stc_data_select,
                                          mortar_express: e.target.value,
                                        });
                                      }}
                                      thousandSeparator={true}
                                    />

                                    <label>ปูน (kg)</label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-sm-8 col-lg-8 col-md-8">
                                  <div class="form-group">
                                    <NumberFormat
                                      allowNegative={false}
                                      decimalScale = {2}
                                      className="form-control"
                                      value={stc_data_select.flyAss_express}
                                      disabled={disable}
                                      onChange={(e) => {
                                        setstc_data_select({
                                          ...stc_data_select,
                                          flyAss_express: e.target.value,
                                        });
                                      }}
                                      thousandSeparator={true}
                                    />

                                    <label>Fly Ash (kg)</label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-sm-8 col-lg-8 col-md-8">
                                  <div class="form-group">
                                    <NumberFormat
                                        allowNegative={false}
                                        decimalScale = {2}
                                      
                                      className="form-control"
                                      value={
                                        stc_data_select.surface_retarder_express
                                      }
                                      disabled={disable}
                                      onChange={(e) => {
                                        setstc_data_select({
                                          ...stc_data_select,
                                          surface_retarder_express:
                                            e.target.value,
                                        });
                                      }}
                                      thousandSeparator={true}
                                    />

                                    <label>น้ำยาหน่วง (ลิตร)</label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-sm-8 col-lg-8 col-md-8">
                                  <div class="form-group">
                                    <NumberFormat
                                      allowNegative={false}
                                      decimalScale = {2}
                                      className="form-control"
                                      value={stc_data_select.sand_express}
                                      disabled={disable}
                                      onChange={(e) => {
                                        setstc_data_select({
                                          ...stc_data_select,
                                          sand_express: e.target.value,
                                        });
                                      }}
                                      thousandSeparator={true}
                                    />

                                    <label>ทรายหยาบ (kg)</label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-sm-8 col-lg-8 col-md-8">
                                  <div class="form-group">
                                    <NumberFormat
                                      allowNegative={false}
                                      decimalScale = {2}
                                      className="form-control"
                                      value={stc_data_select.rock_express}
                                      disabled={disable}
                                      onChange={(e) => {
                                        setstc_data_select({
                                          ...stc_data_select,
                                          rock_express: e.target.value,
                                        });
                                      }}
                                      thousandSeparator={true}
                                    />

                                    <label>หิน 3/4 (kg)</label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-sm-6 col-lg-6 col-md-6">
                                <div class="form-group">
                                  <label></label>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-sm-6 col-lg-6 col-md-6">
                                <div class="form-group">
                                  <label></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-1"></div>

                      <div className="col-1"></div>
                    </div>
                  </div>
                  <div className="modal-footer justify-content-between">
                    {mode.mode == "edit" ? (
                      <>
                        <button
                          type="button"
                          data-dismiss="modal"
                          className="btn btn-primary"
                          onClick={save_selected.bind(this)}
                        >
                          Save Change
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          onClick={save_selected.bind(this)}
                          data-dismiss="modal"
                          className="btn btn-primary"
                        >
                          Save
                        </button>
                      </>
                    )}

                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                      onClick={clear_data.bind()}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>


            {/* โมเดลแอด ซีรอท*/}
            <div className="modal fade" id="modal-add_mat_stc_daily_update">
              <div className="modal-dialog modal-md">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-label">Add</h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={clear_data.bind(this)}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <div class="row">
                      <div class="ccol-sm-12 col-lg-12 col-md-12">
                        <div class="card">
                          <div class="card-body">
                            <h1>Daily Update</h1>
                            <p class="card-text"></p>

                            <div className="container-fluid">
                              <div className="row">

                                <div className="col-sm-6 col-lg-6 col-md-6">
                                  <div class="form-group">
                                    <NumberFormat
                                      className="form-control"
                                      value={stc_data_select.number_in}
                                      allowNegative={false}
                                      decimalScale = {0}
                                      disabled={disable}
                                      onChange={(e) => {
                                        setstc_data_select({
                                          ...stc_data_select,
                                          number_in: e.target.value,
                                        });
                                      }}
                                      thousandSeparator={true}
                                    />

                                    <label>เลขที่ (TK)</label>
                                  </div>
                                </div>
                                 <div className="col-sm-6 col-lg-6 col-md-6">
                                  <div class="form-group">
                                    <NumberFormat
                                      className="form-control"
                                      value={stc_data_select.mortart_in}
                                      allowNegative={false}
                                      decimalScale = {2}
                                      disabled={disable}
                                      onChange={(e) => {
                                        setstc_data_select({
                                          ...stc_data_select,
                                          mortart_in: e.target.value,
                                        });
                                      }}
                                      thousandSeparator={true}
                                    />

                                    <label>ปูนผง</label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <div className="container-fluid">
                              <div className="row">
                                <div className="col-sm-6 col-lg-6 col-md-6">
                                  <div class="form-group">
                                    <NumberFormat
                                      className="form-control"
                                      value={stc_data_select.mortart_in}
                                      disabled={disable}
                                      onChange={(e) => {
                                        setstc_data_select({
                                          ...stc_data_select,
                                          mortart_in: e.target.value,
                                        });
                                      }}
                                      thousandSeparator={true}
                                    />

                                    <label>ปูนผง</label>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-sm-6 col-lg-6 col-md-6">
                                  <div class="form-group">
                                    <NumberFormat
                                      className="form-control"
                                      value={stc_data_select.fly_ash_kg}
                                      allowNegative={false}
                                      decimalScale = {2}
                                      disabled={disable}
                                      onChange={(e) => {
                                        setstc_data_select({
                                          ...stc_data_select,
                                          fly_ash_kg: e.target.value,
                                        });
                                      }}
                                      thousandSeparator={true}
                                    />

                                    <label>Fly Ash (kg)</label>
                                  </div>
                                </div>
                                <div className="col-sm-6 col-lg-6 col-md-6">
                                  <div class="form-group">
                                    <NumberFormat
                                      className="form-control"
                                      value={stc_data_select.rock_3_8_in}
                                      allowNegative={false}
                                      decimalScale = {2}
                                      disabled={disable}
                                      onChange={(e) => {
                                        setstc_data_select({
                                          ...stc_data_select,
                                          rock_3_8_in: e.target.value,
                                        });
                                      }}
                                      thousandSeparator={true}
                                    />

                                    <label>หิน 3/8 (kg)</label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <div className="container-fluid">
                              <div className="row">
                                <div className="col-sm-8 col-lg-8 col-md-8">
                                  <div class="form-group">
                                    <NumberFormat
                                      className="form-control"
                                      value={stc_data_select.rock_3_8_in}
                                      disabled={disable}
                                      onChange={(e) => {
                                        setstc_data_select({
                                          ...stc_data_select,
                                          rock_3_8_in: e.target.value,
                                        });
                                      }}
                                      thousandSeparator={true}
                                    />

                                    <label>หิน 3/8 (kg)</label>
                                  </div>
                                </div>
                              </div>
                            </div> */}

                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-sm-6 col-lg-6 col-md-6">
                                  <div class="form-group">
                                    <NumberFormat
                                      className="form-control"
                                      value={stc_data_select.rock_3_4_in}
                                      allowNegative={false}
                                      decimalScale = {2}
                                      disabled={disable}
                                      onChange={(e) => {
                                        setstc_data_select({
                                          ...stc_data_select,
                                          rock_3_4_in: e.target.value,
                                        });
                                      }}
                                      thousandSeparator={true}
                                    />

                                    <label>หิน 3/4 (kg)</label>
                                  </div>
                                </div>
                                <div className="col-sm-6 col-lg-6 col-md-6">
                                  <div class="form-group">
                                    <NumberFormat
                                      className="form-control"
                                      allowNegative={false}
                                      decimalScale = {2}
                                      value={stc_data_select.sand_in}
                                      disabled={disable}
                                      onChange={(e) => {
                                        setstc_data_select({
                                          ...stc_data_select,
                                          sand_in: e.target.value,
                                        });
                                      }}
                                      thousandSeparator={true}
                                    />

                                    <label>ทราบหยาบ</label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <div className="container-fluid">
                              <div className="row">
                                <div className="col-sm-6 col-lg-6 col-md-6">
                                  <div class="form-group">
                                    <NumberFormat
                                      className="form-control"
                                      value={stc_data_select.sand_in}
                                      disabled={disable}
                                      onChange={(e) => {
                                        setstc_data_select({
                                          ...stc_data_select,
                                          sand_in: e.target.value,
                                        });
                                      }}
                                      thousandSeparator={true}
                                    />

                                    <label>ทราบหยาบ</label>
                                  </div>
                                </div>
                              </div>
                            </div> */}

                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-sm-6 col-lg-6 col-md-6">
                                  <div class="form-group">
                                    <NumberFormat
                                      className="form-control"
                                      allowNegative={false}
                                      decimalScale = {2}
                                      value={stc_data_select.surface_retarder_in_2}
                                      disabled={disable}
                                      onChange={(e) => {
                                        setstc_data_select({
                                          ...stc_data_select,
                                          surface_retarder_in_2: e.target.value,
                                        });
                                      }}
                                      thousandSeparator={true}
                                    />

                                    <label>น้ำยาหน่วง</label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-sm-12 col-lg-12 col-md-12">
                                  <div class="form-group">
                                    <input
                                      className="form-control"
                                      value={stc_data_select.remark}
                                      disabled={disable}
                                      onChange={(e) => {
                                        setstc_data_select({
                                          ...stc_data_select,
                                          remark: e.target.value,
                                        });
                                      }}
                                      thousandSeparator={true}
                                    />

                                    <label>หมายเหตุ</label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <div className="row">
                              <div className="col-sm-6 col-lg-6 col-md-6">
                                <div class="form-group">
                                  <label></label>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-sm-6 col-lg-6 col-md-6">
                                <div class="form-group">
                                  <label></label>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-sm-6 col-lg-6 col-md-6">
                                <div class="form-group">
                                  <label></label>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-sm-6 col-lg-6 col-md-6">
                                <div class="form-group">
                                  <label></label>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-sm-6 col-lg-6 col-md-6">
                                <div class="form-group">
                                  <label></label>
                                </div>
                              </div>
                            </div> */}

                            {/* <div className="row">
                              <div className="col-sm-6 col-lg-6 col-md-6">
                                <div class="form-group">
                                  <label></label>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>

                    {/* สิ้นสุดตรงนี้ Daily Update */}
                    </div>

                    {/* <div className="row">
                      <div className="col-1"></div>

                      <div className="col-1"></div>
                    </div> */}
                  </div>
                  <div className="modal-footer justify-content-between">
                    {mode.mode == "edit" ? (
                      <>
                        <button
                          type="button"
                          data-dismiss="modal"
                          className="btn btn-primary"
                          onClick={save_selected.bind(this)}
                        >
                          Save Change
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          onClick={save_selected.bind(this)}
                          data-dismiss="modal"
                          className="btn btn-primary"
                        >
                          Save
                        </button>
                      </>
                    )}

                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                      onClick={clear_data.bind()}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/*      <Footter/> */}
    </div>
  );
}
export default Mat_stc_add;
