import React, { Component, useEffect, useState } from "react";
import { MDBDataTable ,MDBTable, MDBTableBody, MDBTableFoot, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import "../../assets/css/Supp.css";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import "../../assets/css/table.css";
import { useParams } from "react-router";
import { getOem, getToken, getUser,getAcademy } from "../../Utils/Common";
import Configs from "../../config";
import FormData from "form-data";
import axios from "axios";
import NumberFormat from "react-number-format";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";
import DatePicker from "react-datepicker";
import { Zoom } from "react-slideshow-image";
import image1 from "../../assets/img/map/car1.jpg";
import image2 from "../../assets/img/map/car2.jpg";
import image3 from "../../assets/img/map/car3.jpg";
import image4 from "../../assets/img/map/car4.jpg";
import image5 from "../../assets/img/map/car5.jpg";
import moment from "moment";
import "react-slideshow-image/dist/styles.css";
import Resizer from "react-image-file-resizer";

function Logistic_add(mode) {
  const [pageMode, setPageMode] = useState("");
  const [logistic_type, setlogistic_type] = useState([]);
  const [disable, setdisable] = useState(null);
  const [logistic_basic, setlogistic_basic] = useState({
    logistic_name: "",
    logistic_sim: "",
    logistic_type: "",
    license_plate: "",
    gps_id: "",
    mileage: "",
    oem_id: getOem(),
    company_id: getUser().com,
    is_dtc: false,
  });
  const [logistic_advance, setlogistic_advance] = useState({
    logistic_capacity: "",
    logistic_type: "",
    tax_lasted: "",
    tax_next: "",
    vin: "",
    imei: "",
    logistic_year: "",
    brand: "",
    fuel_consumption: "",
    model: "",
    logistic_code: "",
    image_car: [],
    officer_name: "",
    officer_lastname: "",
    officer_mobile: "",
    officer_image: {
      officer_img: "",
      officer_img_name: "",
      officer_img_path: "",
    },
    kpi: "",
    tow_truck: "",
    logistic_group: "",
  });
  const [maintenaceHistory, setMaintenaceHistory] = useState([]);
  
  const [tow, settow] = useState({
    isTow: false,
  });

  const [tow_truck, settow_truck] = useState([]);
  const [tax_date, settax_date] = useState({
    start_date: "",
    end_date: "",
  });

  const { id } = useParams();
  useEffect(() => {
    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);

  const GetTowTruck = async () => {
    const filter_towTruck = {
      plate_number: "",
      chassis_number: "",
      model_car: "",
      car_type_id: "",
      oem_id: getOem(),
      company_id: getUser().com,
    };

    await axios({
      method: "post",
      url: Configs.API_URL + "/logistic/filter_towTruck",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_towTruck,
    })
      .then(async (response) => {
        /* console.log(response.data); */
        settow_truck(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };
  const GetLogistic_type = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/logistic/logistic_type",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        /* console.log("logisticeType",response.data); */
        setlogistic_type(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const GetLogisticMaintenaceHistory = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/logistic/fineMaintenaceHistoryByCarID?id=" + id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
      //  console.log("fineMaintenaceHistoryByCarID",response.data);
        setMaintenaceHistory(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    // GetCompany();
    //console.log("id:"+id);
    GetTowTruck();
    GetLogistic_view();
    GetLogistic_type();
  }, []);

  const GetLogistic_view = async () => {
    if (mode.mode == "read" || mode.mode == "edit") {
      axios({
        method: "get",
        url: Configs.API_URL + "/logistic/fineLogisticById?id=" + id,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
    /*       console.log("logis ID", response.data); */

          setlogistic_basic({
            logistic_name: response.data.logistic_name,
            logistic_sim: response.data.logistic_sim,
            logistic_type: response.data.car_type_id || "",
            license_plate: response.data.license_plate,
            gps_id: response.data.gps_id,
            mileage: response.data.mileage,
            oem_id: response.data.oem_id,
            company_id: response.data.company_id,
            logistic_code: response.data.logistic_code,
            is_dtc: response.data.logistic_code,
          });

          setlogistic_advance({
            logistic_capacity: response.data.logistic_capacity,
            logistic_type: response.data.car_type_name,
            tax_lasted:
              response.data.tax_lasted != null
                ? moment(new Date(response.data.tax_lasted), "DD-MM-YYYY")._d
                : moment(new Date(), "DD-MM-YYYY")._d,
            tax_next:
              response.data.tax_next != null
                ? moment(new Date(response.data.tax_next), "DD-MM-YYYY")._d
                : moment(new Date(), "DD-MM-YYYY")._d,
            vin: response.data.vin,
            imei: response.data.imei,
            logistic_year: response.data.logistic_year,
            brand: response.data.brand,
            fuel_consumption: response.data.fuel_consumption,
            model: response.data.model,
            logistic_code: response.data.logistic_code,
            image_car: response.data.image_car,
            officer_name: response.data.officer_name,
            officer_lastname: response.data.officer_lastname,
            officer_mobile: response.data.officer_mobile,
            officer_image: {
              officer_img: response.data.officer_image.officer_img,
              officer_img_name: response.data.officer_image.officer_img_name,
              officer_img_path: response.data.officer_image.officer_img_path,
            },
            kpi: response.data.kpi,
            tow_truck: response.data.tow_truck,
            logistic_group: response.data.logistic_group,
            type_id: response.data.car_type_id,
          });
    let _tempImg = [];
          if (response.data.image_car.length > 0) {
           
            for (const img of response.data.image_car) {
              _tempImg.push(Configs.API_URL_IMG + img.image_car_path);
            }

            setimage(_tempImg);
          }

          /*  setlogistic({
            logistic_id: response.data.id,
            logistic_type: response.data.logistic_type_id,
            deliver: response.data.deliver || "",
            deliver_content: response.data.deliver_content || "",
            logistic_no: response.data.logistic_no || "",
            logistic_name: response.data.logistic_name || "",
            is_use: response.data.is_use,
          }); */
        })
        .catch(function (error) {
          console.log(error);
        });


        GetLogisticMaintenaceHistory();
    }
  };

  function saveOption(params) {
    const error_list = [];
    
    if (logistic_basic.logistic_name == "") {
      let temp_err = {
        message: "Please enter information in the fields >>> [Logistic Name].",
      };
      error_list.push(temp_err);
    }

    /*     console.log("basic", logistic_basic);
    console.log("advance", logistic_advance); */

    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    } else {
      if (mode.mode === "add") {
        const temp = {
          logistic_name: logistic_basic.logistic_name,
          logistic_sim: logistic_basic.logistic_sim,

          logistic_type: logistic_basic.logistic_type,
          license_plate: logistic_basic.license_plate,
          gps_id: logistic_basic.logistic_code,
          mileage: Number(String(logistic_basic.mileage).replace(/,/g, "")),
          oem_id: getOem(),
          company_id: getUser().com,
          logistic_capacity: Number(
            String(logistic_advance.logistic_capacity).replace(/,/g, "")
          ),
          tax_lasted: logistic_advance.tax_lasted,
          tax_next: logistic_advance.tax_next,
          vin: logistic_advance.vin,
          imei: logistic_advance.imei,
          logistic_year: logistic_advance.logistic_year,
          brand: logistic_advance.brand,
          fuel_consumption: Number(
            String(logistic_advance.fuel_consumption).replace(/,/g, "")
          ),
          model: logistic_advance.model,
          logistic_group: logistic_advance.logistic_group,
          image_car: logistic_advance.image_car,
          officer_name: logistic_advance.officer_name,
          officer_lastname: logistic_advance.officer_lastname,
          officer_mobile: logistic_advance.officer_mobile,
          officer_image: {
            officer_img: logistic_advance.officer_image.officer_img,
            officer_img_name: logistic_advance.officer_image.officer_img_name,
            officer_img_path: logistic_advance.officer_image.officer_img_path,
          },
          kpi: Number(String(logistic_advance.kpi).replace(/,/g, "")),
          is_truck: tow.isTow,
          tow_truck: logistic_advance.tow_truck || null,
        };
        //console.log(temp);
        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/logistic/logistic_car/add",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Factory_Master_Data/Logistic";
                });
              }
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
      }
      if (mode.mode === "edit") {
        const temp = {
          id: id,
          logistic_name: logistic_basic.logistic_name,
          logistic_sim: logistic_basic.logistic_sim,

          logistic_type: logistic_basic.logistic_type || null,
          license_plate: logistic_basic.license_plate,
          gps_id: logistic_basic.logistic_code,
          mileage: Number(String(logistic_basic.mileage).replace(/,/g, "")),
          oem_id: getOem(),
          company_id: getUser().com,
          logistic_capacity: Number(
            String(logistic_advance.logistic_capacity).replace(/,/g, "")
          ),
          tax_lasted: logistic_advance.tax_lasted,
          tax_next: logistic_advance.tax_next,
          vin: logistic_advance.vin,
          imei: logistic_advance.imei,
          logistic_year: logistic_advance.logistic_year,
          brand: logistic_advance.brand,
          fuel_consumption: Number(
            String(logistic_advance.fuel_consumption).replace(/,/g, "")
          ),
          model: logistic_advance.model,
          logistic_group: logistic_advance.logistic_group,
          image_car: logistic_advance.image_car,
          officer_name: logistic_advance.officer_name,
          officer_lastname: logistic_advance.officer_lastname,
          officer_mobile: logistic_advance.officer_mobile,
          officer_image: {
            officer_img: logistic_advance.officer_image.officer_img,
            officer_img_name: logistic_advance.officer_image.officer_img_name,
            officer_img_path: logistic_advance.officer_image.officer_img_path,
          },
          kpi: Number(String(logistic_advance.kpi).replace(/,/g, "")),
          is_truck: tow.isTow,
          tow_truck: logistic_advance.tow_truck || null,
        };
        //console.log("edit", temp);
        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/logistic/update",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Factory_Master_Data/Logistic";
                });
              }
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
      }
    }
  }

  function cancleOption(params) {
    Swal.fire({
      title: "Do you want to cancel and leave the changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href =
          "/Warehouse_Management/Factory_Master_Data/Logistic";
      }
    });
  }

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>

        {/*  <label>test</label> */}
      </div>
    </div>
  );

  async function SetDate_start(date) {
    // console.log(date)
    var next = null; 
    if (date != null) {
      next = moment(date, "DD-MM-YYYY").add(1, "year");
    }
    //console.log(next)
    setlogistic_advance({
      ...logistic_advance,
      tax_lasted: date,
      tax_next: next?._d,
    });
  }
  async function SetDate_end(date) {
    setlogistic_advance({ ...logistic_advance, tax_next: date });
  }
  const zoomOutProperties = {
    duration: 900000,
    transitionDuration: 300,
    defaulIndex:0,
    infinite: false,
    indicators: true,
    scale: 0.4,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
      },]
  };

  const [image, setimage] = useState([
    userdefault_img.imgs,
    userdefault_img.imgs,
  ]);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        400,
        300,
        "png",
        100,
        0,
        (uri) => {
          resolve(uri);
        }
        // "base64"
      );
    });

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  function isFileImage(file) {
    const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png"];

    return file && acceptedImageTypes.includes(file["type"]);
  }
  async function setofficer_image(e) {
    if (e.target.files[0]) {
      const file = e.target.files[0];

      const regex = /([\u0E00-\u0E7F]+)/gmu;
      const str = file.name;
      let m = regex.exec(str);

      if (m !== null || isFileImage(file) === false) {
        Swal.fire("Error", "File name or type are not correct!!!", "error");
      } else {
        const image = await resizeFile(file);
        var file_image = dataURLtoFile(image, file.name);
        var data = new FormData();
        data.append("Profile", file_image);
        data.append("typeFile", logistic_basic.logistic_name);

        var config_uploadProfile = {
          method: "post",
          url: Configs.API_URL + "/upload/profile",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios(config_uploadProfile)
          .then(function (response) {
           // console.log("ผู้รับผิดชอบ", response.data.data);

            setlogistic_advance({
              ...logistic_advance,
              officer_image: {
                officer_img: response.data.data,
                officer_img_name: response.data.data.orgin_name,
                officer_img_path: response.data.data.path,
              },
            });

            /*       logistic_advance.officer_image.officer_img = response.data.data;
              logistic_advance.officer_image.officer_img_name =response.data.data.orgin_name;
              logistic_advance.officer_image.officer_img_path =response.data.data.path; */
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  }

  async function uploadImageTruck(e) {
    // console.log(e.target.files);
    if (e.target.files.length > 0) {
     //  console.log("up");
      let temp_image = image.filter((el)=>{return(el !='/static/media/user.e17fd2f9.jpg')});
      let _temp_image = logistic_advance.image_car;
     /*  console.log(e.target.files); */
      for (let index = 0; index < e.target.files.length; index++) {
        const file = e.target.files[index];
        /*   console.log(e.target.files[index]); */

        const regex = /([\u0E00-\u0E7F]+)/gmu;
        const str = file.name;
        let m = regex.exec(str);

        if (isFileImage(file) === false) {
          Swal.fire("Error", "File name or type are not correct!!!", "error");
        } else {
          const image = await resizeFile(file);
          var file_image = dataURLtoFile(image, file.name);
          var data = new FormData();
          data.append("Profile", file_image);
          data.append("typeFile", logistic_basic.logistic_name);

          var config_uploadProfile = {
            method: "post",
            url: Configs.API_URL + "/upload/profile",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: data,
          };
          /*  console.log(config_uploadProfile); */

          await axios(config_uploadProfile)
            .then(function (response) {
              let res_img = {
                image_car: response.data.data,
                image_car_name: response.data.data.orgin_name,
                image_car_path: response.data.data.path,
              };
              _temp_image.push(res_img);
              temp_image.push(Configs.API_URL_IMG + response.data.data.path);
            })
            .catch(function (error) {
              console.log(error);
            });
            
        }
      }

    // console.log("รูปรวม",temp_image);
      
     
      setimage(temp_image);
     
      setlogistic_advance({ ...logistic_advance, image_car: _temp_image });
      logistic_advance.image_car = _temp_image;
     /*  console.log(logistic_advance); */
    }
  }

  const rowsData_repair = [];
  
  for (let index = 0; index < maintenaceHistory.length; index++) {
    //console.log("cm_list",maintenaceHistory[index].cm_list)
    const element = {};
    element["no"] = index+1 ;
    element["oem_id"] = maintenaceHistory[index].oem_id ;
    element["maintenance_number"] = maintenaceHistory[index].maintenance_number;
  
    //element["plate_number"] = maintenaceHistory[index].logistic_car_id;
    element["plate_number"] = maintenaceHistory[index].logistic_car_id;
    //element["mileage_number"] = maintenaceHistory[index].mileage_number ;
    element["mileage_number"] = maintenaceHistory[index].mileage_number;
    // element["document_date"] = moment(maintenaceHistory[index].document_date).format('DD/MM/yyyy') ;
    element["document_date"] = maintenaceHistory[index].document_date !== null ? moment(maintenaceHistory[index].document_date).format('DD/MM/yyyy') : "ไม่ระบุ";
    ;

    // element["begin_date"] = moment(maintenaceHistory[index].begin_date).format('DD/MM/yyyy') ;
    
    element["begin_date"] = maintenaceHistory[index].begin_date !== null ? moment(maintenaceHistory[index].begin_date).format('DD/MM/yyyy') : "ไม่ระบุ";

    //element["location_maintenance"] = maintenaceHistory[index].location_maintenance ;
    element["location_maintenance"] = maintenaceHistory[index].location_maintenance;
    // element["finish_date"] = moment(maintenaceHistory[index].finish_date).format('DD/MM/yyyy') ;
    element["finish_date"] =  maintenaceHistory[index].finish_date !== null ? moment(maintenaceHistory[index].finish_date).format('DD/MM/yyyy') : "ไม่ระบุ";;


    //element["type_maintenance"] = maintenaceHistory[index].type_maintenance ;
    element["type_maintenance"] = maintenaceHistory[index].type_maintenance;

    
    //element["detail_order"] = maintenaceHistory[index].detail_order ;
    element["detail_order"] = maintenaceHistory[index].car_maintenance_list.map((el, i) => {
      return (
        <div style={{whiteSpace: 'nowrap'}}>   
            <>
          {el.detail_order}
       </>
       </div>
      );
    });
    //element["spare_part"] = maintenaceHistory[index].spare_part ;
    element["spare_part"] = maintenaceHistory[index].car_maintenance_list.map((el, i) => {
      return (
        <div style={{whiteSpace: 'nowrap'}}>   
            <>
          {el.spare_part}{" "}
       </>
        </div>
      );
    });
    //element["spare_part_amount"] = maintenaceHistory[index].spare_part_amount ;
    element["spare_part_amount"] = maintenaceHistory[index].car_maintenance_list.map((el, i) => {
      return (
        <div style={{whiteSpace: 'nowrap'}}>   
            <>
          {el.spare_part_amount}{" "}
       </>
        </div>
      );
    });
   // element["spare_part_unit"] = maintenaceHistory[index].spare_part_unit ;
   element["spare_part_unit"] = maintenaceHistory[index].car_maintenance_list.map((el, i) => {
    return (
      <div style={{whiteSpace: 'nowrap'}}>   
          <>
        {el.spare_part_unit}{" "}
     </>
      </div>
    );
  });
    //element["price_per_unit"] = maintenaceHistory[index].price_per_unit ;
    element["price_per_unit"] = maintenaceHistory[index].car_maintenance_list.map((el, i) => {
      return (
        <div style={{whiteSpace: 'nowrap'}}>   
            <>
          {el.price_per_unit}{" "}
       </>
        </div>
      );
    });
    //element["price"] = maintenaceHistory[index].price ;
    element["price"] = maintenaceHistory[index].car_maintenance_list.map((el, i) => {
      return (
        <div style={{whiteSpace: 'nowrap'}}>   
            <>
          {el.price}{" "}
       </>
        </div>
      );
    });
    rowsData_repair.push(element);
  }
  
  const data_repair = {
    columns: [
      {
        label: "ลำดับ",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "สาขา",
        field: "oem_id",
        sort: "asc",
        width: 50,
      },
      {
        label: "เลขที่ใบขออนุมัติซ่อม",
        field: "maintenance_number",
        sort: "asc",
        width: 50,
      },
      {
        label: "ทะเบียนรถ",
        field: "plate_number",
        sort: "asc",
        width: 50,
      },
      {
        label: "เลขไมล์(กม.)",
        field: "mileage_number",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันที่แจ้งซ่อม",
        field: "document_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันที่เข้าซ่อม",
        field: "begin_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "ศูนย์ซ่อม",
        field: "location_maintenance",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันที่ซ่อมเสร็จ",
        field: "finish_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "ประเภทการซ่อม",
        field: "type_maintenance",
        sort: "asc",
        width: 50,
      },
      {
        label: "รายละเอียดการซ่อม",
        field: "detail_order",
        sort: "asc",
        width: 50,
      },
      {
        label: "อะไหล่ที่ใช้/ค่าแรง	",
        field: "spare_part",
        sort: "asc",
        width: 50,
      },
      {
        label: "จำนวน",
        field: "spare_part_amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "หน่วย",
        field: "spare_part_unit",
        sort: "asc",
        width: 50,
      },
      {
        label: "ราคาต่อหน่วย",
        field: "price_per_unit",
        sort: "asc",
        width: 50,
      },
      {
        label: "จำนวนเงิน",
        field: "price",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData_repair,
  };

  const image_list = [];

  for (let index = 0; index < logistic_advance.image_car.length; index++) {
/*     console.log( logistic_advance.image_car[index]); */
    const element = {};

    element["no"] = index + 1;
    element["img_name"] = logistic_advance.image_car[index].image_car_name;
    element["mgt"] = (
      <dev rows>
        <div className="col-md-4 col-lg-4 col-sm-4">
          <a
            key={index}
            className=" btn btn-xs "
            onClick={deldata_image.bind(this, logistic_advance.image_car[index].image_car_name,index)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </dev>
    );

    image_list.push(element);
  }
  const data_image = {
    columns: [
      {
        label: "ลำดับ",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อ",
        field: "img_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Mgt",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: image_list,
  };

  function deldata_image(params,index) {
  /*   console.log("ก่อนลบ",logistic_advance.image_car); */
  
      let temp_img = logistic_advance.image_car;
    
    var ima_data = temp_img.filter((element,i)=>{ 
      return (i !=index);
    })

/*    console.log("ลบออกแล้ว",temp_img.splice(index, 1));  */

   /*  console.log("หลงลบ",feature_data); */
 setlogistic_advance({...logistic_advance,image_car:ima_data});
 logistic_advance.image_car = ima_data;

 let temp_img_list = [] ;

 ima_data.forEach(element => {
  temp_img_list.push(Configs.API_URL_IMG+element.image_car_path);
 });

 
 if (temp_img_list.length >0) {
   setimage(temp_img_list);
   //image =temp_img_list;
   //console.log("new add",temp_img_list); 
 }else{
  setimage([
    userdefault_img.imgs,
    userdefault_img.imgs,
  ]);
 // image = [
 //   userdefault_img.imgs,
 //   userdefault_img.imgs,
 // ];
 }

  
  

/* image = temp_img_list; */

  }
  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row ">
              <div className="col-sm-6">
                <h1>Logistic {pageMode}{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Logistic</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
            <div className="row">
            <div className="col-sm-6">
                <h1>ชื่อรถ : {logistic_basic.logistic_name} </h1>
              </div>
            
            </div>
          </div>
          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row ">
                <div className="col-6 col-md-3 col-xl-2" >
                  <button
                    type="button"
                    onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="row ">
              <div className="col-6 col-md-3 col-xl-2" >
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-2" >
                  <button
                    type="button"
                    if
                    onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>
        <section className="content">
          <div className="card card-primary card-outline card-outline-tabs">
            <div className="card-header p-0 border-bottom-0">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-four-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="custom-tabs-four-Basic-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-Basic"
                    role="tab"
                    aria-controls="custom-tabs-four-Basic"
                    aria-selected="false"
                  >
                    Basic Information
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-four-Advance-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-Advance"
                    role="tab"
                    aria-controls="custom-tabs-four-Advance"
                    aria-selected="false"
                  >
                    Advance Information
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-four-Repair-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-Repair"
                    role="tab"
                    aria-controls="custom-tabs-four-Repair"
                    aria-selected="false"
                  >
                    Repair History
                  </a>
                </li>
              </ul>
            </div>

            <div className="tab-content" id="custom-tabs-four-tabContent">
              <div
                className="tab-pane fade show active"
                id="custom-tabs-four-Basic"
                role="tabpanel"
                aria-labelledby="custom-tabs-four-Basic-tab"
              >
                <div className="card-header">
                  <h3 className="card-title">Basic Information</h3>
                  <div className="card-tools"></div>
                </div>

                <div className="card-body">
                  <div className="container-fluid">
                    <div className="row ">
                      <div className="col-md-4 col-sm-4 col-lg-4">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={logistic_basic.logistic_name}
                            onChange={(el) => {
                              setlogistic_basic({
                                ...logistic_basic,
                                logistic_name: el.target.value,
                              });
                            }}
                            disabled={disable || logistic_basic.is_dtc}
                          />
                          <label htmlFor="">ชื่อ</label>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-4 col-lg-4">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={logistic_basic.logistic_sim}
                            onChange={(el) => {
                              setlogistic_basic({
                                ...logistic_basic,
                                logistic_sim: el.target.value,
                              });
                            }}
                            disabled={disable || logistic_basic.is_dtc}
                          />
                          <label htmlFor="">เบอร์ซิม</label>
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-md-4 col-sm-4 col-lg-4">
                        <div className="form-group ">
                          <select
                            className="custom-select select2 form-control"
                            value={logistic_basic.logistic_type}
                            disabled={disable || logistic_basic.is_dtc}
                            onChange={(e) => {
                              setlogistic_basic({
                                ...logistic_basic,
                                logistic_type: e.target.value,
                              });
                              logistic_basic.logistic_type = e.target.value;
                            }}
                          >
                            <option value="" selected disabled>
                              Select Model
                            </option>
                            {logistic_type.map((e, i) => {
                              return (
                                <option
                                  key={i}
                                  value={e.id}
                                  selected={
                                    logistic_basic.logistic_type === e.id
                                      ? true
                                      : false
                                  }
                                >
                                  {e.name}
                                </option>
                              );
                            })}
                          </select>

                          <label htmlFor="">ประเภทรถ</label>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-4 col-lg-4">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={logistic_basic.logistic_code}
                            onChange={(el) => {
                              setlogistic_basic({
                                ...logistic_basic,
                                logistic_code: el.target.value,
                              });
                            }}
                            disabled={disable || logistic_basic.is_dtc}
                          />
                          <label htmlFor="">เบอร์กล่อง</label>
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-md-4 col-sm-4 col-lg-4">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={logistic_basic.license_plate}
                            onChange={(el) => {
                              setlogistic_basic({
                                ...logistic_basic,
                                license_plate: el.target.value,
                              });
                            }}
                            disabled={disable || logistic_basic.is_dtc}
                          />
                          <label htmlFor="">เลขทะเบียน</label>
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-md-4 col-sm-4 col-lg-4">
                        <div className="form-group ">
                          {/*  <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={logistic_basic.mileage}
                            onChange={(el) => {
                              setlogistic_basic({
                                ...logistic_basic,
                                mileage: el.target.value,
                              });
                            }}
                            disabled={disable}
                          /> */}

                          <NumberFormat
                            thousandSeparator={true}
                            allowNegative={false}
                            decimalScale={2}
                            type="text"
                            className="form-control"
                            value={logistic_basic.mileage}
                            onChange={(el) => {
                              setlogistic_basic({
                                ...logistic_basic,
                                mileage: el.target.value,
                              });
                            }}
                            disabled={disable || logistic_basic.is_dtc}
                            required
                          />
                          <label htmlFor="">เลขไมล์ปัจจุบัน</label>
                        </div>
                      </div>
                    </div>

                    {/*   <div className="row ">
                      <div className="col-md-4 col-sm-4 col-lg-4">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={logistic_basic.logistic_group}
                            onChange={(el) => {
                              setlogistic_basic({
                                ...logistic_basic,
                                logistic_group: el.target.value,
                              });
                            }}
                            disabled={disable || logistic_basic.is_dtc}
                          />
                          <label htmlFor="">กลุ่มรถ</label>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="custom-tabs-four-Advance"
                role="tabpanel"
                aria-labelledby="custom-tabs-four-Advance-tab"
              >
                <div className="card-header">
                  <h3 className="card-title">Advance Information</h3>
                  <div className="card-tools"></div>
                </div>

                <div className="card-body">
                  <div className="container-fluid">
                    <div className="row ">
                      <div
                        className="col-md-4 col-lg-4 col-sm-4"
                        style={{ "z-index": "99" }}
                      >
                        <div className="form-group ">
                          <NumberFormat
                            thousandSeparator={true}
                            allowNegative={false}
                            decimalScale={2}
                            type="text"
                            className="form-control"
                            value={logistic_advance.logistic_capacity}
                            onChange={(el) => {
                              setlogistic_advance({
                                ...logistic_advance,
                                logistic_capacity: el.target.value,
                              });
                            }}
                            disabled={disable}
                            required
                          />
                          <label htmlFor="">Capacity (ตัน)</label>
                        </div>

                        <table>
                          <tr>
                            <td>ต่อภาษีล่าสุด</td>
                            <td>
                              <DatePicker
                                selected={logistic_advance.tax_lasted}
                                className="form-control"
                                dateFormat={"dd-MM-yyyy"}
                                onChange={async (date) => SetDate_start(date)}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: "60%" }}>ต่อภาษีครั้งถัดไป</td>
                            <td>
                              <DatePicker
                                selected={logistic_advance.tax_next}
                                className="form-control"
                                dateFormat={"dd-MM-yyyy"}
                                onChange={async (date) => SetDate_end(date)}
                              />
                            </td>
                          </tr>
                        </table>

                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={logistic_advance.vin}
                            onChange={(el) => {
                              setlogistic_advance({
                                ...logistic_advance,
                                vin: el.target.value,
                              });
                            }}
                            disabled={disable}
                          />
                          <label htmlFor="">เลขถัง</label>
                        </div>

                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={logistic_advance.imei}
                            onChange={(el) => {
                              setlogistic_advance({
                                ...logistic_advance,
                                imei: el.target.value,
                              });
                            }}
                            disabled={disable}
                          />
                          <label htmlFor="">เลขเครื่อง</label>
                        </div>

                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={logistic_advance.logistic_year}
                            onChange={(el) => {
                              setlogistic_advance({
                                ...logistic_advance,
                                logistic_year: el.target.value,
                              });
                            }}
                            disabled={disable}
                          />
                          <label htmlFor="">รุ่นปี ค.ศ.</label>
                        </div>

                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={logistic_advance.model}
                            onChange={(el) => {
                              setlogistic_advance({
                                ...logistic_advance,
                                model: el.target.value,
                              });
                            }}
                            disabled={disable}
                          />
                          <label htmlFor="">Model รถ</label>
                        </div>

                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={logistic_advance.brand}
                            onChange={(el) => {
                              setlogistic_advance({
                                ...logistic_advance,
                                brand: el.target.value,
                              });
                            }}
                            disabled={disable}
                          />
                          <label htmlFor="">Brand รถ</label>
                        </div>

                        <div className="form-group ">
                          <NumberFormat
                            thousandSeparator={true}
                            allowNegative={false}
                            decimalScale={2}
                            type="text"
                            className="form-control"
                            value={logistic_advance.fuel_consumption}
                            onChange={(el) => {
                              setlogistic_advance({
                                ...logistic_advance,
                                fuel_consumption: el.target.value,
                              });
                            }}
                            disabled={disable}
                            required
                          />
                          <label htmlFor="">
                            อัตราการกินน้ำมัน(กิโลเมตร/ลิตร)
                          </label>
                        </div>

                        <div className="form-group ">
                          <NumberFormat
                            thousandSeparator={true}
                            allowNegative={false}
                            decimalScale={2}
                            type="text"
                            className="form-control"
                            value={logistic_advance.kpi}
                            onChange={(el) => {
                              setlogistic_advance({
                                ...logistic_advance,
                                kpi: el.target.value,
                              });
                            }}
                            disabled={disable}
                            required
                          />
                          <label htmlFor="">KPI</label>{" "}
                        </div>
                        <hr />
                        <h3>ผู้รับผิดชอบ</h3>

                        <div className="row">
                          <div className=" col-md-12 col-lg-12 col-sm-12">
                            <div className="form-group ">
                              <input
                                type="text"
                                className="form-control"
                                required="false"
                                value={logistic_advance.officer_name}
                                onChange={(el) => {
                                  setlogistic_advance({
                                    ...logistic_advance,
                                    officer_name: el.target.value,
                                  });
                                }}
                                disabled={disable}
                              />
                              <label htmlFor="">ชื่อ</label>
                            </div>
                          </div>
                          <div className=" col-md-12 col-lg-12 col-sm-12">
                            <div className="form-group ">
                              <input
                                type="text"
                                className="form-control"
                                required="false"
                                value={logistic_advance.officer_lastname}
                                onChange={(el) => {
                                  setlogistic_advance({
                                    ...logistic_advance,
                                    officer_lastname: el.target.value,
                                  });
                                }}
                                disabled={disable}
                              />
                              <label htmlFor="">นามสกุล</label>
                            </div>
                          </div>{" "}
                          <div className=" col-md-12 col-lg-12 col-sm-12">
                            <div className="form-group ">
                              <input
                                type="text"
                                className="form-control"
                                required="false"
                                value={logistic_advance.officer_mobile}
                                onChange={(el) => {
                                  setlogistic_advance({
                                    ...logistic_advance,
                                    officer_mobile: el.target.value,
                                  });
                                }}
                                disabled={disable}
                              />
                              <label htmlFor="">เบอร์โทร</label>
                            </div>
                          </div>
                          <div className=" col-md-12 col-lg-12 col-sm-12">
                            <div class="custom-control custom-switch">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="customSwitches"
                                onClick={(e) => {
                                  if (e.target.checked === true) {
                                    settow({ ...tow, isTow: true });
                                    tow.isTow = true;
                                  } else {
                                    settow({ ...tow, isTow: false });
                                    tow.isTow = false;
                                  }
                                }}
                              />
                              <label
                                class="custom-control-label"
                                for="customSwitches"
                              >
                                พ่วงได้
                              </label>
                            </div>
                            <div className=" col-md-12 col-lg-12 col-sm-12"></div>
                            {tow.isTow === true ? (
                              <>
                                <div className="form-group ">
                                  <select
                                    className="custom-select select2 form-control"
                                    value={logistic_advance.tow_truck}
                                    onChange={(e) => {
                                      setlogistic_advance({
                                        ...logistic_advance,
                                        tow_truck: e.target.value,
                                      });
                                    }}
                                  >
                                    <option value="" disabled>
                                      --เลือก--
                                    </option>
                                    {tow_truck.map((el) => {
                                      return (
                                        <option value={el.id}>
                                          {el.plate_number}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <label htmlFor="">ส่วนพ่วง</label>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="row">
                            <div
                              className="col-md-12 col-lg-12 col-sm-12"
                              style={{ textAlign: "center" }}
                            >
                              <img
                                id="img"
                                alt="..."
                                className="img-fluid rounded shadow border-0"
                                src={
                                  logistic_advance.officer_image
                                    .officer_img_path !== "" &&
                                  logistic_advance.officer_image
                                    .officer_img_path !== null &&
                                  logistic_advance.officer_image
                                    .officer_img_path !== undefined
                                    ? Configs.API_URL_IMG +
                                      logistic_advance.officer_image
                                        .officer_img_path
                                    : userdefault_img.imgs
                                }
                                style={{ width: "300px", height: "200px" }}
                              />
                            </div>
                            <div className="col-md-12 col-lg-12 col-sm-12">
                              <label htmlFor="exampleInputFile">
                                รูปผู้รับผิดชอบ{" "}
                                <span style={{ color: "red" }}>
                                  {" "}
                                  ขนาด(400 x 300) px
                                </span>
                              </label>
                              <div className="input-group">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                    id="exampleInputFile"
                                    disabled={
                                      mode.mode === "read" ? true : false
                                    }
                                    accept="image/*"
                                    onChange={setofficer_image}
                                  />
                                  <label
                                    className="custom-file-label"
                                    htmlFor="exampleInputFile"
                                  >
                                    {logistic_advance.officer_image
                                      .officer_img_name !== ""
                                      ? logistic_advance.officer_image
                                          .officer_img_name
                                      : "Select Image"}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-1 col-lg-1 col-md-1"></div>
                      <div className="col-sm-6 col-lg-6 col-md-6">
                        <div>
                          <div
                            className="slide-container"
                            style={{ textAlign: "center" }}
                          >
                            <Zoom  {...zoomOutProperties}>
                              {image.map((each, index) => (
                                <img
                                  key={index}
                                  className="img-fluid rounded shadow border-0"
                                  style={{ width: "100%", height: "600px" }}
                                  src={each}
                                />
                              ))}
                            </Zoom>
                          </div>
                        </div>

                        <div className="row">
                          <div className="input-group">
                            <div className="custom-file">
                              <input
                                type="file"
                                className="custom-file-input"
                                id="exampleInputFileMultile"
                                disabled={mode.mode === "read" ? true : false}
                                accept="image/*"
                                multiple={true}
                                onChange={ uploadImageTruck}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="exampleInputFileMultile"
                              >
                                Uplaod Car Image
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                        <div
                              className="card-body table-responsive " /*  style={{ height: "100%" }} */
                            >
                            {/*   <MDBDataTable
                                className="table table-head-fixed"
                                striped
                                bordered
                                hover
                                fixedHeader
                                data={data_image}
                              /> */}

                       <MDBTable striped hover bordered small searching={true}>
                          <MDBTableHead>
                            <tr>
                           {data_image.columns.map((el,i)=>{
                             return (
                               <th key={el.field}>{el.label}</th>
                             );
                           })}
                            </tr>
                          </MDBTableHead>
                          <MDBTableBody>
                          {data_image.rows.map((el,i)=>{
                             return (
                               <tr key={i}>
                               <th >{el.no}</th>
                               <th >{el.img_name}</th>
                               <th >{el.mgt}</th>
                               </tr>
                             );
                           })}
                            
                          </MDBTableBody>
                        </MDBTable>
                            </div>
                        </div>

                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="custom-tabs-four-Repair"
                role="tabpanel"
                aria-labelledby="custom-tabs-four-Repair-tab"
              >
                <div className="card-header">
                  <h3 className="card-title">Repair History</h3>
                  <div className="card-tools"></div>
                </div>

                <div className="card-body">
                  <div className="container-fluid">
                    <div
                      className="card-body table-responsive " style={{ textAlign: "center", height: "820px",whiteSpace: "nowrap"}}
                    >
                      <MDBDataTable
                      
                        className="table table-head-fixed text-nowrap"
                        striped
                        bordered
                        hover
                        fixedHeader
                        // data={data}
                        data={data_repair}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/*   <Footter/> */}
    </div>
  );
}
export default Logistic_add;
