import React, { Component, useEffect, useState } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser, getFeature } from "../../Utils/Common";
import { useParams } from "react-router";
import NumberFormat from "react-number-format";

function Spare_part_add(mode) {
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);
  const [type_select, settype_select] = useState({
    status: "plant_item",
  });
  const [model, setModel] = useState([]);


  const [feature, setfeature] = useState([]);
  const [filter_product, setfilter_product] = useState({
    no: "",
    name: "",
    product_model_id: "",
    oem_id: getOem(),
  });
  const [test, settest] = useState({
    test: "",
  });
  const [MDBT,setMDBT] =useState(true);
  const [product,setProduct] = useState([]);
  const [product_select,setProduct_select] = useState([]);

  const { id } = useParams();





  useEffect(() => {
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "Spare Part";
    });
    setfeature(feature_data);

    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);

  const [SparePart, setSparePart] = useState({
    spare_part_no: "",
    spare_part_name: "",
    product_ref: "",
    description: "",
    company_id: getUser().com,
    amount: 0,
  });

  const GetProduct = async () => {
   
    await axios({
      method: "post",
      url: Configs.API_URL + "/product/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_product,
    })
      .then(async (response) => {
        console.log(response.data)
        //setProduct([]);
        for(let item of response.data){
          var check = product_select.filter((e)=>{
            return e.product_id === item.id
          });
          if(check.length > 0){
            item.isPush = true;
          }else{
            item.isPush = false;
          }
      
        }
        console.log(response.data)

         setProduct(response.data);
         setMDBT(true);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  function getSparePart_view(){
    axios({
      method: "get",
      url: Configs.API_URL + "/sparePart/findById?id=" + id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        console.log(response.data);
        setSparePart({
          spare_part_no: response.data.spare_part_no,
          spare_part_name: response.data.spare_part_name || "",
          product_ref: response.data.product_ref,
          description: response.data.description || "",
          amount:
            response.data.amount ||
            Number(response.data.toString().replace(/,/g, "")),
        });
        setProduct_select(response.data.product_ref)
        axios({
         method: "post",
         url: Configs.API_URL + "/product/filter",
         headers: {
           Authorization: getToken(),
           "X-TTT": Configs.API_TTT,
           "Content-Type": "application/json",
         },
         data: filter_product,
       })
         .then(async (response2) => {
           //console.log(response.data)
           setProduct([]);
           for(let item of response2.data){
             var check = response.data.product_ref.filter((e)=>{
               return e.product_id === item.id
             });
             if(check.length > 0){
               item.isPush = true;
             }else{
               item.isPush = false;
             }
         
           }
           //console.log(response.data)
   
            setProduct(response2.data);
          
         })
         .catch(async (error) => {
           console.log(error);
         });
  
       
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const Getmodel = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/product/getProductModel?id=" + getOem(),
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        //console.log(response.data);
        setModel(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    GetProduct();
    Getmodel();
    if(mode.mode !== "add"){
      getSparePart_view();
    }
  }, []);

  function cancleOption(params) {
    Swal.fire({
      title: "Do you want to cancel and leave the changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes?`,
      denyButtonText: `Don't save`,
    }).then((result) => {
      window.location.href =
        "/Warehouse_Management/Factory_Master_Data/Spare_Part";
    });
  }

  function saveOption(params) {
    Swal.fire({
      title: "Do you want to save the changes?",
      icon: "warning",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        
        if(mode.mode ==='add'){
          var temp_save = {
            spare_part_no: SparePart.spare_part_no,
            spare_part_name: SparePart.spare_part_name,
            product_ref: product_select,
            description: SparePart.description,
            company_id: getUser().com,
            amount: Number(SparePart.amount),
          };

          axios({
            method: "post",
            url: Configs.API_URL + "/sparePart/add",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp_save,
          })
            .then(function (response) {
              console.log(response.data);
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Spare_Part";
              });
            })
            .catch(async (error) => {
              console.log(error);
  
              Swal.fire("Error", error.response.data.message, "error");
            });
        }
        else{
          var temp_save = {
            spare_part_id:id,
            spare_part_no: SparePart.spare_part_no,
            spare_part_name: SparePart.spare_part_name,
            product_ref: product_select,
            description: SparePart.description,
            company_id: getUser().com,
            amount: Number(SparePart.amount),
          };
          console.log(temp_save);
          axios({
            method: "post",
            url: Configs.API_URL + "/sparePart/update",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp_save,
          })
            .then(async (response) => {
              console.log(response.data);
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Spare_Part";
              });
            })
            .catch(async (error) => {
              console.log(error);
  
              Swal.fire("Error", error.response.data.message, "error");
            });
        }
       

        
        
      } else if (result.isDenied) {
        Swal.fire({
          icon: "warning",
          title: "Canceling",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          window.location.href =
            "/Warehouse_Management/Factory_Master_Data/Spare_Part";
        });
      }
    });
  }

 
  
  function pushProduct(e){
    const product_select_temp = product_select;
      var data = product.filter((element)=>{ 
        return (element.id === e.target.id);
      })
      const product_p = product;
      setProduct([]);
      for(let i of product_p){
        if(i.id === e.target.id){
          i.isPush = true;
        }
      }
      setProduct(product_p);
      //console.log(product_select_temp);
      var checked = product_select_temp.filter((e2)=>{
        return e2.product_id === data[0].id;
      })
      //console.log(checked)
      if(checked.length > 0){
        console.log("ซ้ำ")
      }else{
        let temp = {
          id:"",
          product_id:data[0].id,
          sparepart_id:"",
          name:data[0].name,
          no:data[0].no,
        };
        product_select_temp.push(temp);
      }
       setProduct_select([]);
       setProduct_select(product_select_temp);
       settest({...test,test:""});
       test.test = "";
    }
  
    function removeProduct(e){
      //console.log(e.target.id);
      const product_p = product;
      setProduct([]);
      for(let i of product_p){
        if(i.id === e.target.id){
          i.isPush = false;
        }
      }
      setProduct(product_p);
      const product_select_temp = product_select;
      setProduct_select([]);
      let indexOf = product_select_temp.findIndex((element)=> element.product_id ===  e.target.id) 
      product_select_temp.splice(indexOf, 1);
      setProduct_select(product_select_temp);
      settest({...test,test:""});
       test.test = "";
      //console.log(data[0]);    
    }
    console.log(product_select);
    const Product_data = [];
    for(var index = 0; index < product.length;index++){
      const rowItem = {};
  
      rowItem["no"] = index +1 ;
      rowItem["id"] = product[index].id;
      rowItem["product_no"] = product[index].no;
      rowItem["product_name"] = product[index].name;
      rowItem["mgt"] = (
        <>
        {product[index].isPush === true ? (
            <>
            <div className="col-12">
                    <button
                      type="button"
                      id = {product[index].id}
                      onClick={removeProduct.bind(this)}
                      class="btn btn-block btn-danger "
                    >
                      Remove
                    </button>
                  </div>
          </>
        ):(
          <>
          <div className="col-12">
          <button
            type="button"
            id = {product[index].id}
            onClick={pushProduct.bind(this)}
            class="btn btn-block btn-primary "
          >
            Push
          </button>
        </div>
        </>
        )}
       
        </>
      );
      Product_data.push(rowItem);
    }
  
    const TableProduct = {
      columns: [
        {
          label: "No",
          field: "no",
          sort: "asc",
          width: 50,
        },
        {
          label: "Product No",
          field: "product_no",
          sort: "asc",
          width: 50,
        },
        {
         label: "Product Name",
         field: "product_name",
         sort: "asc",
         width: 50,
       },
        
        {
          label: "Management",
          field: "mgt",
          sort: "asc",
          width: 50,
        },
      ],
      rows: Product_data,
    };
  
  
    const Product_data_select = [];
  
    for(var index = 0; index < product_select.length;index++){
      const rowItem = {};
  
      rowItem["no"] = index +1 ;
      rowItem["id"] = product_select[index].id;
      rowItem["product_no"] = product_select[index].no;
      rowItem["product_name"] = product_select[index].name;
      rowItem["mgt"] = (
        <>
        <div className="col-6">
                    <button
                      type="button"
                      disabled={disable}
                      id = {product_select[index].product_id}
                      onClick={removeProduct.bind(this)}
                      class="btn btn-block btn-danger "
                    >
                      Remove
                    </button>
                  </div>
        </>
      );
      Product_data_select.push(rowItem);
    }
   
   
    
  
    const TableProduct_select = {
      columns: [
        {
          label: "No",
          field: "no",
          sort: "asc",
          width: 50,
        },
        {
          label: "Product No",
          field: "product_no",
          sort: "asc",
          width: 50,
        },
        {
         label: "Product Name",
         field: "product_name",
         sort: "asc",
         width: 50,
       },
        
        {
          label: "Management",
          field: "mgt",
          sort: "asc",
          width: 50,
        },
      ],
      rows: Product_data_select,
    };
  
  
    const clearFilter = async () => {
       setfilter_product({
        ...filter_product,
        no: "",
        name: "",
        product_model_id: "",
        oem_id: getOem()
      }); 
      filter_product.no = "";
      filter_product.name = "";
      filter_product.product_model_id = "";
     
      GetProduct();
     
    }; 






  return (
    <div className="wrapper">
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Spare Part {pageMode}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Spare_Part</li>
                  <li className="breadcrumb-item active">
                    Spare_Part {pageMode}{" "}
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row mb-2">
                <div className="col-sm-1">
                  <button
                    type="button"
                    onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                <div className="col-sm-1">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-sm-1">
                  <button
                    type="button"
                    if
                    onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>

        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Spare Part {pageMode}</h3>
            </div>
            <div className="card-body">
              <h3 className="mt-5-head">Spare Part Detail</h3>
              <div className="tab-custom-content"></div>
 

              <div className="row">
                <div className="col-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={SparePart.spare_part_no}
                      disabled={disable}
                      onChange={(e) => {
                        setSparePart({
                          ...SparePart,
                          spare_part_no: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Spare Part No
                      {SparePart.spare_part_no.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>

                <div className="col-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={SparePart.spare_part_name}
                      disabled={disable}
                      onChange={(e) => {
                        setSparePart({
                          ...SparePart,
                          spare_part_name: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Spare Part Name
                      {SparePart.spare_part_name.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                .
                <div className="col-4">
                  <div className="form-group">
                    <NumberFormat
                      className="form-control"
                      required
                      thousandSeparator={true}
                      allowNegative={false}
                      value={SparePart.amount}
                      disabled={disable}
                      onChange={(e) => {
                        setSparePart({
                          ...SparePart,
                          amount: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Amount
                      {SparePart.amount === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-8">
                  <div class="form-group">
                    <textarea
                      type="text"
                      className="form-control"
                      required
                      style={{ height: "100px", resize: "none" }}
                      value={SparePart.description}
                      disabled={disable}
                      onChange={(e) => {
                        setSparePart({
                          ...SparePart,
                          description: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Description
                      {SparePart.description === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-12">
                  <h3 className="mt-5 mt-5-head">
                    Product ref.
                  </h3>
                  <div className="tab-custom-content">
                  </div>
                  <div className="row">
                    <div className="col-12">
                     {/*  <label htmlFor="">Product Select</label> */}
                      <div className = "row">
                      <div className="col-2">
                        <button
                          type="button"
                          class="btn btn-block btn-info "
                          disabled={mode.mode === "read" ? true : false}
                          data-toggle="modal"
                          data-target="#modal-product"
                        // onClick={testassy}
                        >
                          Select Product
                        </button>
                      </div>
                      {feature.map((e) => {
                        if (e.feature === "Import Spare Part") {
                          return (
                            <div className="col-2">
                              
                              <button
                                type="button"
                                class="btn btn-block btn-info "
                                disabled={mode.mode === "read" ? true : false}
                                data-toggle="modal"
                                data-target="#modal-subassy"
                                // onClick={testassy}
                              >
                                Import Spare Part
                              </button>
                            </div>
                          );
                        }
                      })}
                      {/* {product_select_temp.length > 0 && mode.mode != "read" ? (
                        <>
                        <div className="col-2">
                          <button
                            type="button"
                            class="btn btn-block btn-danger "
                            onClick={clear_restore}
                          >
                            Clear
                          </button>
                        </div>
                        </>
                      ):(
                     <></>)} */}
                     
                    
                      </div>


                    </div>

                  </div>
                  <br />

                







                  <div className="row">

                    <div className="col-12">


                      <div className="table-responsive" style={{ height: "600px" }} >
                        <MDBDataTable
                        sortable = {false}
                          className="table table-head-fixed"
                          striped
                          bordered
                          hover
                          fixedHeader
                          data={TableProduct_select}
                        />
                      </div>
                    </div>


                  </div>
                  <br />
                  {/* ------------------------------------------------------------------------------------------------------------------ */}
                  <div className="modal fade" id="modal-product">
                    <div className="modal-dialog modal-xl">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">Product</h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            //onClick={set_bomlist_excel}
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-12 ">
                              <div className="none-nolmalinput">


                              </div>
                            </div>
                          </div>
                          <br />
                          <div className="row">

                            <div className="col-12">
                              <div className="row">
                              <div className="col-md-3">
                <div className="form-group ">
                  
                <input
                     type="text"
                    className="form-control"
                    required="false"    
                    value={filter_product.no.trim()}
                    onChange={(e) => {
                      setfilter_product({
                        ...filter_product,
                        no: e.target.value.trim(),
                      });
                    }} 
                  />
                  <label htmlFor="">Product No</label>{" "}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group ">
                  
                <input
                     type="text"
                    className="form-control"
                    required="false"    
                    value={filter_product.name.trim()}
                    onChange={(e) => {
                      setfilter_product({
                        ...filter_product,
                        name: e.target.value.trim(),
                      });
                    }} 
                  />
                  <label htmlFor="">Product Name</label>{" "}
                </div>
              </div>


              <div className="col-md-3">
                <div className="form-group ">
                                  <button
                                    type="button"
                                    class="btn btn-block btn-info "
                                    disabled={mode.mode === "read" ? true : false}
                                    onClick={() =>{
                                      setMDBT(false);
                                      GetProduct();
                                      //setMDBT(true);
                                      }}
                                  >
                                    Search
                                  </button>
                                </div>
                                </div>

                                <div className="col-md-3">
                            <div className="form-group ">
                                  <button
                                    type="button"
                                    class="btn btn-block btn-info "
                                    disabled={mode.mode === "read" ? true : false}
                                    onClick={()=>{
                                      setMDBT(false);
                                      clearFilter();
                                      //setMDBT(true);
                                      
                                    }
                                     }
                                  >
                                    Clear
                                  </button>
                                </div>
                                </div>
                               




                              </div>
                              <br />

                    

                              <div
                                className="table-responsive"
                                style={{ height: "500px" }}
                              >
                                <MDBDataTable
                                sortable = {false}
                                  className="table table-head-fixed"
                                  //paging={false}
                                  disableRetreatAfterSorting={MDBT}
                                  
                                  onPageChange={(page)=>{
                                    console.log(page)
                                  }}
                                  striped
                                  bordered
                                  hover
                                  fixedHeader
                                  data={TableProduct}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h3>
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                          <button
                            type="button"
                            className="btn btn-default"
                            data-dismiss="modal"
                          >
                            Close
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
         
           
              </div>
              
              </div>
        </section>

        <div className="modal fade" id="modal-product">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Product</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  //onClick={set_bomlist_excel}
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 ">
                    <div className="none-nolmalinput"></div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            value={filter_product.no}
                            disabled={mode.mode === "read" ? true : false}
                            onChange={(e) =>
                              setfilter_product({
                                ...filter_product,
                                no: e.target.value,
                              })
                            }
                            required
                          />
                          <label htmlFor="">Product No</label>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          {" "}
                          <input
                            type="text"
                            className="form-control"
                            value={filter_product.name}
                            disabled={mode.mode === "read" ? true : false}
                            onChange={(e) =>
                              setfilter_product({
                                ...filter_product,
                                name: e.target.value,
                              })
                            }
                            required
                          />
                          <label htmlFor="">Product Name</label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <br />
                        <button
                          type="button"
                          class="btn btn-block btn-info "
                          disabled={mode.mode === "read" ? true : false}
                          //onClick={fillterdata}
                        >
                          Search
                        </button>
                      </div>
                      <div className="col-3">
                        <br />
                        <button
                          type="button"
                          class="btn btn-block btn-info "
                          disabled={mode.mode === "read" ? true : false}
                        /*   onClick={() => {
                            clear_fillter();
                          }} */
                        >
                          Clear
                        </button>
                      </div>
                      {/* {feature.map((e) => {
                        if (e.feature === "Import Spare Part") {
                          return (
                            <div className="col-3">
                              <br />
                              <button
                                type="button"
                                class="btn btn-block btn-info "
                                disabled={mode.mode === "read" ? true : false}
                                data-toggle="modal"
                                data-target="#modal-import"
                                // onClick={testassy}
                              >
                                Import Spare Part
                              </button>
                            </div>
                          );
                        }
                      })} */}
                    </div>
                    <br />
                    <div
                      className="table-responsive"
                      style={{ height: "300px" }}
                    >
                      <MDBDataTable
                        className="table table-head-fixed"
                        sortable = {false}
                        striped
                        bordered
                        hover
                        fixedHeader
                        //data={data}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <h3></h3>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Spare_part_add;
