import React, { useEffect, useState } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../../config";
import { getOem, getToken, getUser, getAcademy, setOemlist } from "../../../../Utils/Common";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import {
    Progress,
    Tooltip,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
} from "reactstrap";
function SaveMessage(params) {
    const [filterteam, setFilterTeam] = useState({
        file_name: "",
        cat_name: "",
        rc_id: "",
    });

    const [filCategory, setFilterCategory] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [dataFileByCate, setDataFileByCate] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredItems, setFilteredItems] = useState(dataFileByCate);
    useEffect(() => {
        const results = dataFileByCate.filter(item =>
            item.file_name.toLowerCase().trim().includes(searchTerm.toLowerCase().trim())
        );
        setFilteredItems(results);
    }, [searchTerm, dataFileByCate]);


    const GetAllCatgory = async () => {
        await axios({
            method: "post",
            url: Configs.API_URL_hrMagenatement + "/api/companyRegulations/filterManageMessageType",
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: filterteam,
        })
            .then(async function (response) {
                const activeItems = response.data.data.filter(item => {
                    return item.rc_is_active === true;
                });

                if (Array.isArray(response.data.data) && activeItems.length > 0) {
                    setFilterCategory(activeItems);
                } else {
                    setFilterCategory([]);
                }
            })
            .catch(function (error) {
                console.log(error);
                Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
            });
    };

    async function filterFileByCategory(id) {
        let data = {
            rc_id: id,
        }
        await axios({
            method: "post",
            url:
                Configs.API_URL_hrMagenatement + "/api/companyRegulations/filterFileByCategory",
            headers: {
                Authorization: getToken(),
                "Content-Type": "application/json",
                "X-TTT": Configs.API_TTT,
            },
            data: data,
        })
            .then(function (response) {

                const data = response.data.data.map(row => {
                    return {
                        file_name: row.rf_name,
                        file_path: row.rf_path_file,
                        file_path_name: row.rf_name_file,
                    }
                })
                setDataFileByCate(data);
            })
            .catch(function (error) {
                console.log(error);
                Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
            });
    }

    useEffect(() => {
        GetAllCatgory();
    }, []);

    const handleCardClick = (item) => {
        setSelectedItem(item);
        setShowModal(true);
        filterFileByCategory(item.rc_id);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedItem(null);
        setSearchTerm('');
    };

    function generateDataTable(Message, index) {
        const element = {};
        element["no"] = index + 1;
        element["file_name"] = (
            <div style={{ minWidth: '250px' }}>
                {Message.file_name}
            </div>
        );
        element["file_name2"] = Message.file_name
        element["mgt"] = (
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ width: "100%" }}>
                    <a
                        // href={
                        //     Message.file_path && Message.file_path_name
                        //         ? encodeURI(Configs.API_URL_IMG + `${Message.file_path}`)
                        //         : ""
                        // }
                        onClick={() => {
                            DownloadFile(encodeURI(Configs.API_URL_IMG + `${Message.file_path}`), Message.file_path_name);
                        }}
                        download={Message.file_path_name ? encodeURIComponent(Message.file_path_name) : "default-filename"}
                        id="btn_read"
                        className="btn btn-block btn-primary"
                        style={{ minWidth: "130px", display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}
                    >
                        Download
                    </a>
                </div>
            </div>
        );
        return element;
    }

    const dataMess = [];
    for (let index = 0; index < filteredItems.length; index++) {
        const Message = filteredItems[index];
        dataMess.push(generateDataTable(Message, index));
    }

    const data = {
        columns: [
            {
                label: "No",
                field: "no",
                // sort: "asc",
                width: 50,
            },
            {
                label: "File name",
                field: "file_name",
                // sort: "asc",
                width: 100,
            },
            {
                label: "Management",
                field: "mgt",
                // sort: "asc",
                // width: 50,
            },
        ],
        rows: dataMess,
    };

    const [heightOfContent, setHeightOfContent] = useState({
        data: 0
    });
    useEffect(() => {
        setTimeout(() => {
            const sidebar = document.querySelector('.main-sidebar');
            if (sidebar) {
                const height = sidebar.offsetHeight - 50;
                setHeightOfContent({ data: height });
                heightOfContent.data = height;
            }
        }, 1000)
    }, []);

    async function DownloadFile(url, fileName) {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('File could not be downloaded');
            }
            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = fileName || 'downloaded_file';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error('Error downloading the file:', error.message);
        }
    }

    return (
        <div className="wrapper">
            <div className="content-wrapper" style={heightOfContent.data ? { minHeight: `${heightOfContent.data}px` } : {}}>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>บันทึกข้อความ</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Human Resource</li>
                                    <li className="breadcrumb-item active">กฏระเบียบบริษัท</li>
                                    <li className="breadcrumb-item active">บันทึกข้อความ</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="row">
                            {filCategory.map((el, index) => (
                                <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3 d-flex align-items-stretch">
                                    <div
                                        className="card"
                                        style={{ width: '100%', cursor: 'pointer' }}
                                        onClick={() => handleCardClick(el)}
                                    >
                                        <div className="card-body" style={{ display: 'flex', backgroundColor: 'white', borderRadius: '10px', flexDirection: 'column', alignItems: 'center', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                            {/* Customize the card content as needed */}
                                            <img alt="..." className="img-fluid" style={{ width: "auto", height: "110px", objectFit: "cover", marginBottom: '10px' }} src={Configs.API_URL_IMG + el.rc_path_image} />
                                            <h3>{el.rc_name}</h3>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Modal */}
                    <Modal isOpen={showModal} toggle={handleCloseModal} style={{ maxWidth: '90%', minWidth: '70%', width: '70%' }}>
                        <ModalHeader toggle={handleCloseModal} >
                        </ModalHeader>
                        <ModalBody>
                            <div className="row">
                                <div className="col-md-2 d-flex align-items-center justify-content-center">
                                    <img
                                        alt="..."
                                        className="img-fluid"
                                        style={{ width: "auto", height: "130px", marginRight: '10px', objectFit: "cover" }}
                                        src={Configs.API_URL_IMG + selectedItem?.rc_path_image}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <h1 style={{ margin: 0, fontWeight: 'bold' }}>{selectedItem?.rc_name}</h1>
                                    <div className="form-group mt-2 mb-0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            style={{ width: '240px' }}
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                        <label htmlFor="">File Name</label>
                                    </div>
                                </div>
                            </div>

                            <section className="content">
                                <div className="card">
                                    <div
                                        className="card-body table-responsive "
                                        style={{ whiteSpace: "nowrap" }}
                                    >
                                        <MDBDataTable
                                            sortable={false}
                                            className="table table-head-fixed"
                                            striped
                                            bordered
                                            hover
                                            fixedHeader
                                            data={data}
                                            search={false}
                                        />
                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer"></div>
                                    {/* /.card-footer*/}
                                </div>
                                {/* /.card */}
                            </section>
                        </ModalBody>
                        {/* <ModalFooter>
                            <Button color="secondary" onClick={handleCloseModal}>
                                Close
                            </Button>
                        </ModalFooter> */}
                    </Modal>

                </section>
            </div>
        </div>
    );
}

export default SaveMessage;
