import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import axios from "axios";
import Configs from "../../../../../config";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../../../Utils/Common";

import NumberFormat from "react-number-format";
import Swal from "sweetalert2";

import Input_Certificate_license from "./Component/Input_Certificate_license";
import History_Certificate_license from "./Component/History_Certificate_license";

function AEV_Certificate({ mode }) {
  const page = "Certificate";

  const { id } = useParams();

  const [pageMode, setPageMode] = useState("");

  const [isDisabled, setIsDisabled] = useState(false);

  const [inputData, setInputData] = useState({
    Certificate_Id: "",
    Certificate_License: "",
    Certificate_Type: "",
    Expire_Date: "",
    Extend_Date: "",
    Address: "",
    Con_Name: "",
    Con_Tel: "",
    Remark: "",
    File_Name: "",
    File_Path: "",
    company_id: getUser().com,
    oem_id: getOem(),
    user_id: getUser().fup,
    user_name : `${getUser().name} ${getUser().surname}`,
  });

  const [historyData, setHistoryData] = useState([]);

  const [file, setFile] = useState(null);

  const [oldFile, setOldFile] = useState({
    File_Name: "",
    File_Path: "",
    statusFile: false,
  });

  const [cerType, setCerType] = useState({
    data: [],
  });

  async function GetCertificateData() {
    let tempData = {
      Certificate_Id: id,
      // company_id: getUser().com,
      // oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/Certificate/getCertificateData",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setInputData({
          ...inputData,
          Certificate_Id: response.data[0].cer_id,
          Certificate_License: response.data[0].cer_name,
          Certificate_Type: response.data[0].cer_ctt_id,
          Expire_Date: response.data[0].cer_exp_date
            ? new Date(response.data[0].cer_exp_date)
            : "",
          Extend_Date: response.data[0].cer_extend_date
            ? new Date(response.data[0].cer_extend_date)
            : "",
          Address: response.data[0].cer_address,
          Remark: response.data[0].cer_remark,
          File_Name: response.data[0].cer_file_name,
          File_Path: response.data[0].cer_file_path,
          Con_Name: response.data[0].cer_contact_person,
          Con_Tel: response.data[0].cer_tel,
        });

        setOldFile({
          ...oldFile,
          File_Name: response.data[0].cer_file_name,
          File_Path: response.data[0].cer_file_path,
        });
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetCertificateHistoryData() {
    let tempData = {
      Certificate_Id: id,
      // company_id: getUser().com,
      // oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus + "/api/Certificate/getCertificateHistoryData",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        const formatData = [];

        for (let item of response.data) {
          formatData.push({
            Certificate_License: item.lc_cer_name,
            Create_Date: item.lc_created_date,
            Expire_Date: item.lc_exp_date,
            File_Name: item.lc_file_name,
            File_Path: item.lc_file_path,
            Type: item.ctt_name
          });
        }

        setHistoryData(formatData);
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function saveOption() {
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (inputData.Certificate_License) {
          inputData.Certificate_License = inputData.Certificate_License.trim();
        }

        if (inputData.Address) {
          inputData.Address = inputData.Address.trim();
        }

        if (inputData.Remark) {
          inputData.Remark = inputData.Remark.trim();
        }

        const error_list = [];

        if (
          inputData.Certificate_License === "" ||
          inputData.Certificate_License === null
        ) {
          let temp_err =
            page === "Certificate"
              ? {
                  message: "กรุณากรอก Certificate",
                }
              : {
                  message: "กรุณากรอก License",
                };

          error_list.push(temp_err);
        }

        if (inputData.Expire_Date === "" || inputData.Expire_Date === null) {
          let temp_err = {
            message: "กรุณากรอก Expire Date",
          };

          error_list.push(temp_err);
        }

        if (inputData.Extend_Date === "" || inputData.Extend_Date === null) {
          let temp_err = {
            message: "กรุณากรอก Extend Date",
          };

          error_list.push(temp_err);
        }

        if (page === "Certificate") {
          if (inputData.Certificate_Type === "" || inputData.Certificate_Type === null) {
            let temp_err = {
              message: "กรุณาเลือก Certificate Type",
            };

            error_list.push(temp_err);
          }

          if (inputData.Address === "" || inputData.Address === null) {
            let temp_err = {
              message: "กรุณากรอก Address",
            };

            error_list.push(temp_err);
          }
        }

        if (mode === "add") {
          if (inputData.File_Name === "" || inputData.File_Name === null) {
            let temp_err =
              page === "Certificate"
                ? {
                    message: "กรุณาเลือก Certificate Document",
                  }
                : {
                    message: "กรุณาเลือก License Document",
                  };

            error_list.push(temp_err);
          }
        }

        if (error_list.length > 0) {
          var err_message = "";
          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }
          Swal.fire("Error", err_message, "error");
        } else {
          Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
              Swal.showLoading();
            },
          }).then(async () => {
            let axiosData = {};
            const temp = inputData;

            if (mode === "add") {
              axiosData = {
                method: "post",
                url:
                  Configs.API_URL_cuteplus +
                  "/api/certificate/checkAddCertificate",
                headers: {
                  Authorization: "Bearer " + getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: temp,
              };
            }

            if (mode === "edit") {
              axiosData = {
                method: "post",
                url:
                  Configs.API_URL_cuteplus +
                  "/api/certificate/checkEditCertificate",
                headers: {
                  Authorization: "Bearer " + getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: temp,
              };
            }

            await axios(axiosData)
              .then(async (response) => {
                let cer_id = response.data;

                if (oldFile.statusFile) {
                  let data = new FormData();
                  data.append("company_id", getUser().com);
                  data.append("oem_id", getOem());
                  data.append("user_id", getUser().fup);
                  data.append(
                    "user_name",
                    `${getUser().name} ${getUser().surname}`
                  );
                  data.append("Project", "Cute_Products_Plus");
                  data.append("Menu", "Certificate");
                  data.append("Ref", cer_id);
                  data.append("Profile", file);
                  data.append("ProfileName", file.name);

                  await axios({
                    method: "post",
                    url:
                      Configs.API_URL_IMG_cuteplus + "/api/upload/uploadFile",
                    headers: {
                      Authorization: getToken(),
                      "X-TTT": Configs.API_TTT,
                      "Content-Type": "multipart/form-data",
                    },
                    data: data,
                  })
                    .then(async (response) => {
                      temp.File_Path = response.data[0].path;
                      if (mode === "add") {
                        axiosData = {
                          method: "post",
                          url:
                            Configs.API_URL_cuteplus +
                            "/api/certificate/AddCertificate",
                          headers: {
                            Authorization: "Bearer " + getToken(),
                            "X-TTT": Configs.API_TTT,
                            "Content-Type": "application/json",
                          },
                          data: temp,
                        };
                      }

                      if (mode === "edit") {
                        axiosData = {
                          method: "post",
                          url:
                            Configs.API_URL_cuteplus +
                            "/api/certificate/EditCertificate",
                          headers: {
                            Authorization: "Bearer " + getToken(),
                            "X-TTT": Configs.API_TTT,
                            "Content-Type": "application/json",
                          },
                          data: temp,
                        };
                      }
                      await axios(axiosData)
                        .then(async (response) => {
                          Swal.fire({
                            icon: "success",
                            title: "บันทึกข้อมูลสำเร็จ",
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            showConfirmButton: false,
                            timer: 2000,
                          }).then(() => {
                            window.location.href =
                              "/Warehouse_Management/Factory_Master_Data_Cuteplus/Certificate_license";
                          });
                        })
                        .catch(async (error) => {
                          Swal.fire({
                            title: "Error",
                            text: error.response.data.error.message,
                            icon: "error",
                          });
                        });
                    })
                    .catch(async (error) => {
                      Swal.fire({
                        icon: "error",
                        title: "ไม่สามารถอัปโหลดเอกสารได้",
                      });
                    });
                } else {
                  if (mode === "add") {
                    axiosData = {
                      method: "post",
                      url:
                        Configs.API_URL_cuteplus +
                        "/api/certificate/AddCertificate",
                      headers: {
                        Authorization: "Bearer " + getToken(),
                        "X-TTT": Configs.API_TTT,
                        "Content-Type": "application/json",
                      },
                      data: temp,
                    };
                  }

                  if (mode === "edit") {
                    axiosData = {
                      method: "post",
                      url:
                        Configs.API_URL_cuteplus +
                        "/api/certificate/EditCertificate",
                      headers: {
                        Authorization: "Bearer " + getToken(),
                        "X-TTT": Configs.API_TTT,
                        "Content-Type": "application/json",
                      },
                      data: temp,
                    };
                  }
                  await axios(axiosData)
                    .then(async (response) => {
                      Swal.fire({
                        icon: "success",
                        title: "บันทึกข้อมูลสำเร็จ",
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        timer: 2000,
                      }).then(() => {
                        window.location.href =
                          "/Warehouse_Management/Factory_Master_Data_Cuteplus/Certificate_license";
                      });
                    })
                    .catch(async (error) => {
                      Swal.fire({
                        title: "Error",
                        text: error.response.data.error.message,
                        icon: "error",
                      });
                    });
                }
              })
              .catch(async (error) => {
                Swal.fire({
                  title: "Error",
                  text: error.response.data.error.message,
                  icon: "error",
                });
              });
          });
        }
      }
    });
  }

  function cancleOption() {
    if (mode === "add" || mode === "edit") {
      Swal.fire({
        title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `ใช่`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `ไม่`,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href =
            "/Warehouse_Management/Factory_Master_Data_Cuteplus/Certificate_license";
        }
      });
    } else {
      window.location.href =
        "/Warehouse_Management/Factory_Master_Data_Cuteplus/Certificate_license";
    }
  }

  const GetCertificateType = async () => {
    // let temp = {
    //   company_id: getUser().com,
    //   oem_id: getOem(),
    // };
    axios({
      method: "get",
      url: Configs.API_URL_cuteplus + "/api/certificate/getCertificateType",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      // data: temp,
    })
      .then(function (response) {
        setCerType({ ...cerType, data: response.data });
        cerType.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  useEffect(async () => {
    await GetCertificateType();
    if (mode === "add") {
      setPageMode("Add");
    } else if (mode === "edit") {
      setPageMode("Edit");
      setIsDisabled(false);
      await GetCertificateData();
      await GetCertificateHistoryData();
    } else if (mode === "view") {
      setPageMode("Read");
      setIsDisabled(true);
      await GetCertificateData();
      await GetCertificateHistoryData();
    }
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-3">
              <div className="col-sm-6">
                <h1>{pageMode} Certificate</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">
                    Certificate license
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {mode === "view" ? (
              <div className="row mb-3">
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-danger"
                    onClick={() => {
                      cancleOption();
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-3">
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-success"
                    onClick={async () => {
                      await saveOption();
                    }}
                  >
                    บันทึก
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-danger"
                    onClick={() => {
                      cancleOption();
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>

        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card card-primary card-outline card-outline-tabs">
                  {mode !== "add" ? (
                    <div className="card-header p-0 border-bottom-0">
                      <ul
                        className="nav nav-tabs"
                        id="custom-tabs-four-tab"
                        role="tablist"
                      >
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            id={"tab-1"}
                            data-toggle="pill"
                            href={"#Certificate-tab"}
                            role="tab"
                            aria-controls={"Certificate-tab"}
                            aria-selected="true"
                          >
                            Certificate
                          </a>
                        </li>

                        <li className="nav-item">
                          <a
                            className="nav-link"
                            id={"tab-2"}
                            data-toggle="pill"
                            href={"#History-tab"}
                            role="tab"
                            aria-controls={"History-tab"}
                            aria-selected="false"
                          >
                            History
                          </a>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="card-body">
                    <div
                      className="tab-content"
                      id="custom-tabs-four-tabContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id={"Certificate-tab"}
                        role="tabpanel"
                        aria-labelledby={"tab-1"}
                      >
                        <Input_Certificate_license
                          fromPage={page}
                          isDisabled={isDisabled}
                          inputData={inputData}
                          setInputData={setInputData}
                          setFile={setFile}
                          oldFile={oldFile}
                          setOldFile={setOldFile}
                          cerType={cerType}
                        />
                      </div>

                      <div
                        className="tab-pane fade"
                        id={"History-tab"}
                        role="tabpanel"
                        aria-labelledby={"tab-2"}
                      >
                        <History_Certificate_license
                          fromPage={page}
                          historyData={historyData}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AEV_Certificate;
