import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import "../../../assets/css/Dashboard.css";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

function Horizontal_Bar_Chart(params) {
  return (
    <>
      <div
        style={{
          display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          flexDirection: "column",
          width: "100%",
          // height: "80px",
          backgroundColor: params.BackgroundColor,
        }}
      >
        <label
          style={{
            color: params.Color,
            fontSize: "23px",
            lineHeight: "normal",
            margin: "0px",
            display: "block",
            textAlign: "center",
          }}
        >
          {params.Text}
        </label>
        <div style={{ width: "100%", height: "100%" }}>
          <Bar
            data={params.DataChart}
            options={{
              indexAxis: "y",
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  beginAtZero: true,
                  stacked: true,
                },
              },
              plugins: {
                legend: {
                  display: false,
                  // onClick: (evt, legendItem, legend) => {
                  //   // console.log(evt);
                  //   // console.log(legendItem.text);
                  //   // console.log(legend.chart.data.labels);
                  //   const index = legend.chart.data.labels.indexOf(
                  //     legendItem.text
                  //   );
                  //   legend.chart.toggleDataVisibility(index);
                  //   legend.chart.update();
                  // },

                  // labels: {
                  //   generateLabels: (chart) => {
                  //     // console.log(chart);
                  //     let visibility = [];
                  //     for (let i = 0; i < chart.data.labels.length; i++) {
                  //       if (chart.getDataVisibility(i) === true) {
                  //         visibility.push(false);
                  //       } else {
                  //         visibility.push(true);
                  //       }
                  //     }
                  //     return chart.data.labels.map((label, index) => ({
                  //       text: label,
                  //       strokeStyle: chart.data.datasets[0].borderColor[index],
                  //       fillStyle:
                  //         chart.data.datasets[0].backgroundColor[index],
                  //       hidden: visibility[index],
                  //     }));
                  //   },
                  // },
                },
              },
            }}
          />
        </div>
      </div>
    </>
  );
}
export default Horizontal_Bar_Chart;
