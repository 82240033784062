import React, { Component, useEffect, useState, useRef } from "react";
import axios from "axios";
import Configs from "../../config";
import {
  getOem,
  getToken,
  getUser,
  getFeature,
  getAcademy,
} from "../../Utils/Common";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import NumberFormat from "react-number-format";
import Sidebar from "../../component/Sidebar";
import BTN from "../../component/btn_feature";
import TABLE from "../../component/table";

// or using the css provided

import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import QRCode2 from "qrcode";
import { Get_font_pdf } from "../../assets/font/pdf_font";
import jsPDF from "jspdf";
let date = new Date();
let dateformatYear = date.getFullYear();
let dateformatMonth = date.getMonth() + 1;
function Product_Approve(params) {
  const [fillter, setfillter] = useState({
    oem_id: getOem(),
    year: dateformatYear,
    month: dateformatMonth,
    suggestion: 0,
  });

  const [approve_list, setapprove_list] = useState([]);
  const [all_page, setall_page] = useState([]);
  const [this_page, setthis_page] = useState({
    page: 1,
  });
  const [show_entries, setshow_entries] = useState({
    entries: 10,
  });

  const [approve_list_update, setapprove_list_update] = useState([]);
  const [capacity, setcapacity] = useState(0);
  const [sumwip, setsumwip] = useState(0);
  const [wip_line, setwip_line] = useState({
    sum_wip: 0,
    per_sum_wip: 0,
  });
  const [sumnew, setsumnew] = useState(0);
  const [new_order_line, setnew_order_line] = useState({
    sum_new_order: 0,
    per_sum_new_order: 0,
  });
  const [remain, setremain] = useState(0);
  const [remain_line, setremain_line] = useState({
    remain: 0,
    per_remain: 0,
  });
  const [year_now, setyear_now] = useState(moment(new Date()).format("YYYY"));
  const [month_now, setmonth_now] = useState(moment(new Date()).format("MM"));
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [modal, setModal] = useState(false);
  const toggle_modal = () => setModal(!modal);
  /*   const year_now = moment().format('YYYY');
  const month_now = moment().format('YYYY'); */
  const [feature, setfeature] = useState([]);
  const [n0Change, setn0Change] = useState("");
  const [n1Change, setn1Change] = useState("");
  const [n2Change, setn2Change] = useState("");
  const [n3Change, setn3Change] = useState("");
  const [n4Change, setn4Change] = useState("");

  var config_com = {
    method: "get",
    url: Configs.API_URL + "/company/findById?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    ////////permission feature//////////

    montchange();
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "Approve Production Item";
    });
    setfeature(feature_data);
    ////////permission feature//////////

    axios(config_com)
      .then(function (response) {
        /* console.log(response.data.factory_capacity); */
        setcapacity(response.data.factory_capacity);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  function load_suggrest() {
    if (fillter.year !== "" && fillter.month !== "") {
      const temp = {
        oem_id: getOem(),
        year: fillter.year,
        month: fillter.month,
        suggestion: Number(fillter.suggestion),
      };
      /* console.log("ใหม่",temp); */

      var config_filler = {
        method: "post",
        url: Configs.API_URL + "/productPlaning/FilterOrderForcast",
        headers: {
          Authorization: getToken(),
          "Content-Type": "application/json",
          "X-TTT": Configs.API_TTT,
        },
        data: temp,
      };
      let timerInterval;
      axios(config_filler)
        .then(function (response) {
          Swal.fire({
            title: "Loading",
            html: "Downloading Data <b></b>",
            //timer: response.data.length,
            timer: response.data.length * 10,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              timerInterval = setInterval(() => {
                const content = Swal.getContent();
                if (content) {
                  const b = content.querySelector("b");
                  if (b) {
                    b.textContent = Swal.getTimerLeft() / 10;
                  }
                }
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
            }
          });

          //console.log(response.data);
          let sumwiptemp = 0;
          let sumnew_temp = 0;
          let wip_all = 0;
          let new_order_all = 0;
          const temp_data = [];
          // console.log(response.data);
          for (let index = 0; index < response.data.length; index++) {
            const element = {};
            element["id"] = response.data[index].id;
            element["no"] = response.data[index].no;
            element["name"] = response.data[index].name;
            element["wip"] = response.data[index].wip;
            element["fg"] = response.data[index].fg;
            element["delivery"] = response.data[index].delivery;
            element["amount_forcast"] = response.data[index].amount_forcast;
            element["in_hand"] = response.data[index].in_hand;
            element["pending"] = response.data[index].pending;
            let steel_bar = response.data[index].steel_bar || 0;
            if (response.data[index].steel_bar < 0) {
              steel_bar = 0;
            }
            element["steel_bar"] = steel_bar;
            element["n1"] = response.data[index].n1;
            element["n2"] = response.data[index].n2;
            element["n3"] = response.data[index].n3;
            element["n4"] = response.data[index].n4;
            let suggestion = response.data[index].suggestion;
            if (response.data[index].suggestion < 0) {
              suggestion = 0;
            }
            element["suggestion"] = suggestion;
            let actual_suggestion = response.data[index].actual_suggestion;
            if (response.data[index].actual_suggestion < 0) {
              actual_suggestion = 0;
            }
            element["actual_suggestion"] = actual_suggestion;
            element["mat_no"] = response.data[index].mat_no;
            element["amount_mat"] = response.data[index].amount_mat;
            //element["nmo"] = suggestion;
            element["nmo"] = actual_suggestion || 0;
            element["chack_sum_amount"] = response.data[index].chack_sum_amount;

            element["firstMonth"] = "";
            element["secondMonth"] = "";
            element["thirdMonth"] = "";
            element["fourthMonth"] = "";
            element["nextMonthSave"] = actual_suggestion || 0;
            element["nextMonth"] = actual_suggestion || 0;

            temp_data.push(element);

            sumwiptemp = sumwiptemp + response.data[index].in_hand;
            sumnew_temp = sumnew_temp + response.data[index].actual_suggestion;

            wip_all = wip_all + response.data[index].wip;

            if (response.data[index].actual_suggestion > 0) {
              new_order_all =
                new_order_all + (response.data[index].actual_suggestion || 0);
            }
          }
          //console.log(new_order_all);

          setwip_line({
            ...wip_line,
            sum_wip: wip_all,
            per_sum_wip: Number(((wip_all / capacity) * 100).toFixed(2)),
          });
          setnew_order_line({
            new_order_line,
            sum_new_order: new_order_all,
            per_sum_new_order: Number(
              ((new_order_all / capacity) * 100).toFixed(2)
            ),
          });
          var remain_ = Number(capacity - (wip_all + new_order_all));
          var per_remain_ = Number(
            100 -
              (Number(((wip_all / capacity) * 100).toFixed(2)) +
                Number(((new_order_all / capacity) * 100).toFixed(2)))
          ).toFixed(2);

          setremain_line({
            ...remain_line,
            remain: remain_,
            per_remain: per_remain_,
          });

          //  console.log(remain_,per_remain_);

          let sorted = temp_data.sort((a, b) => b.nmo - a.nmo);
          // console.log(sorted);
          setapprove_list(sorted);

          const page_arr = [];
          let page = 1;
          for (let i = 0; i < sorted.length; i++) {
            if (Math.ceil(i / 10) >= page) {
              // console.log(Math.ceil(i / 10), page);
              let temp = {
                page: page,
              };
              page_arr.push(temp);
              page++;
            }
          }

          setall_page(page_arr);

          // let sum_page = Math.ceil(sorted.length/10);
          //console.log(sum_page);
          //setpage_number({...page_number,page_all:sum_page});
          /*  var approve_list_test = [];
          var page_arr = [];
          let page = 1;

          let stack_page = 10;
          for(let i = 0; i < sorted.length;i++){
            let check = i+1;
            if(Math.ceil(i/10) < stack_page){
              let temp = {
                page:page,
                id:sorted[i].id,
                no:sorted[i].no,
                name:sorted[i].name,
                wip:sorted[i].wip,
                fg:sorted[i].fg,
                delivery:sorted[i].delivery,
                amount_forcast:sorted[i].amount_forcast,
                in_hand:sorted[i].in_hand,
                pending:sorted[i].pending,
                n1:sorted[i].n1,
                n2:sorted[i].n2,
                n3:sorted[i].n3,
                n4:sorted[i].n4,
                suggestion:sorted[i].suggestion,
                nmo:sorted[i].nmo,
              }
              approve_list_test.push(temp);
            }
            if(check === stack_page){
              stack_page = stack_page+10;
              page++;    
            }       
        
          }
          let page_ =1;
          for(var t =0; t < approve_list_test.length; t++){
            var index = t-1;
            if(index > -1){
              if(approve_list_test[index].page !== approve_list_test[t].page){
                  let temp ={
                    page:page_++,
                  }
                  page_arr.push(temp);
              }
              if(t+1 === approve_list_test.length){
                let temp ={
                  page:page_++,
                }
                page_arr.push(temp);
              }
            }
          }
          setapprove_list_test(approve_list_test);
          setall_page(page_arr);
          console.log(approve_list_test);
          console.log(page_arr); */

          // console.log(approve_list_test);

          setsumnew(Number(((sumnew_temp / capacity) * 100).toFixed(2)));
          setsumwip(Number(((sumwiptemp / capacity) * 100).toFixed(2)));
          setremain(
            Number(
              100 -
                (Number((sumwiptemp / capacity) * 100) +
                  Number((sumnew_temp / capacity) * 100))
            ).toFixed(2)
          );

          document.getElementById("test-table-xls-button").click();
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      Swal.fire("Warning", "Please Select Year and Month", "warning");
    }
  }

  const Summary = () => {
    if (approve_list.length !== 0) {
      let sum_new = 0;
      for (let index = 0; index < approve_list.length; index++) {
        sum_new =
          sum_new +
          Number(approve_list[index].nmo.toString().replace(/,/g, ""));
      }
      /*  console.log(Number(sum_new)); */

      setsumnew(Number(((sum_new / capacity) * 100).toFixed(2)));
      setremain(
        Number(
          100 - (Number(sumwip) + Number((sum_new / capacity) * 100))
        ).toFixed(2)
      );
      /* console.log(
        Number(
          100 - (Number(sumwip) + Number((sum_new / capacity) * 100))
        ).toFixed(2)
      ); */
    }
  };

  function approve_product() {
    // console.log(approve_list_test);
    if (
      fillter.year !== "" &&
      fillter.month !== "" &&
      approve_list.length !== 0
    ) {
      const data_update = [];

      for (let index = 0; index < approve_list.length; index++) {
        const element = {};

        element["id"] = approve_list[index].id;
        element["no"] = approve_list[index].no;
        element["name"] = approve_list[index].name;
        element["year"] = fillter.year;
        element["month"] = fillter.month;
        // element["order"] = Number(
        //   approve_list[index].nmo.toString().replace(/,/g, "")
        // );
        element["order"] = Number(
          approve_list[index].nextMonth.toString().replace(/,/g, "") || 0
        );
        element["m_suggession"] = fillter.suggestion;
        element["n1"] = approve_list[index].n1 || 0;
        element["n2"] = approve_list[index].n2 || 0;
        element["n3"] = approve_list[index].n3 || 0;
        element["n4"] = approve_list[index].n4 || 0;
        element["steel_bar"] = approve_list[index].steel_bar || 0;
        element["oem_id"] = getOem();
        var steel = Math.ceil(parseFloat(approve_list[index].steel_bar));
        var check = parseInt(approve_list[index].amount_mat) - steel;
        if (check >= 0) {
          data_update.push(element);
        }
      }
      // console.log(data_update);
      axios({
        method: "post",
        url: Configs.API_URL + "/productPlaning/updateRealOrder",
        headers: {
          Authorization: getToken(),
          "Content-Type": "application/json",
          "X-TTT": Configs.API_TTT,
        },
        data: data_update,
      })
        .then(function (response) {
          //console.log(response.data);

          let temp1 = response.data.filter((x) => (x.status = true));
          let temp2 = temp1.filter((x) => x.order > 0);
          setapprove_list_update(temp2);
          Swal.fire(
            "Order Success Fully",
            "The production order has been saved.",
            "success"
          ).then(() => {
            toggle_modal();
          });
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", error.response.data.message, "error");
        });
    } else {
      Swal.fire(
        "Warning",
        "Please Select Year and Month or Suggression",
        "warning"
      );
    }
  }

  async function approve_update(e, data) {
    // console.log(e.target.name);

    approve_list[e.target.name].nmo = e.target.value;

    Summary();
    // Summary();
  }

  function montchange(monthnumber2) {
    var monthList = [
      { i: 1, v: "Jan" },
      { i: 2, v: "Feb" },
      { i: 3, v: "Mar" },
      { i: 4, v: "Apr" },
      { i: 5, v: "May" },
      { i: 6, v: "Jun" },
      { i: 7, v: "Jul" },
      { i: 8, v: "Aug" },
      { i: 9, v: "Sep" },
      { i: 10, v: "Oct" },
      { i: 11, v: "Nov" },
      { i: 12, v: "Dec" },
    ];
    let finai = [];
    /*  load_suggrest(); */
    let setss = date.getMonth() + 1;
    let monthnumber = "";
    if (monthnumber2 === "" || monthnumber2 === undefined) {
      monthnumber = setss;
    } else {
      monthnumber = monthnumber2;
    }
    if (parseInt(monthnumber) < 9) {
      for (let item = parseInt(monthnumber); item <= monthList.length; item++) {
        var list = monthList.filter((e) => {
          return e.i === item;
        });
        finai.push(list[0]);
      }
    } else {
      if (parseInt(monthnumber) === 9) {
        finai.push(
          { i: 9, v: "Sep" },
          { i: "10", v: "Oct" },
          { i: "11", v: "Nov" },
          { i: "12", v: "Dec" },
          { i: "1", v: "Jan" }
        );
      } else if (parseInt(monthnumber) === 10) {
        finai.push(
          { i: "10", v: "Oct" },
          { i: "11", v: "Nov" },
          { i: "12", v: "Dec" },
          { i: "1", v: "Jan" },
          { i: "2", v: "Feb" }
        );
        /* finai.push(list[0].v) */
      } else if (parseInt(monthnumber) === 11) {
        finai.push(
          { i: "11", v: "Nov" },
          { i: "12", v: "Dec" },
          { i: "1", v: "Jan" },
          { i: "2", v: "Feb" },
          { i: "3", v: "Mar" }
        );
        /* finai.push(list[0].v) */
      } else if (parseInt(monthnumber) === 12) {
        finai.push(
          { i: "12", v: "Dec" },
          { i: "1", v: "Jan" },
          { i: "2", v: "Feb" },
          { i: "3", v: "Mar" },
          { i: "4", v: "Apr" }
        );
        /* finai.push(list[0].v) */
      }
    }

    let temp = [];
    if (finai.length > 0) {
      for (let i1 = 1; i1 < 5; i1++) {
        /* console.log([i1],"เช็ค i1",finai[i1]); */
        temp.push(finai[i1].v);
      }
    }

    /* mountChean.N1 */
    if (temp.length > 0) {
      setn0Change(monthList.filter((i) => i.i == monthnumber)[0].v);
      setn1Change(temp[0]);
      setn2Change(temp[1]);
      setn3Change(temp[2]);
      setn4Change(temp[3]);
    }
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const rowsData = [];
  for (var index = 0; index < approve_list.length; index++) {
    const rowItem = {};
    if (approve_list[index].chack_sum_amount === true) {
      var color_alert = "#ff0000";
    } else {
      var color_alert = "#000";
    }
    rowItem["Item"] = <div style={{ color: color_alert }}>{index + 1}</div>;
    /*  rowItem["page"] = (
      <div style={{ color: color_alert }}>
        {approve_list[index].page}
      </div>); */
    rowItem["Part_No"] = (
      <div style={{ color: color_alert }}>{approve_list[index].no}</div>
    );
    rowItem["Part_Name"] = (
      <div style={{ color: color_alert }}>{approve_list[index].name}</div>
    );
    rowItem["amount_mat"] = (
      <div style={{ color: color_alert }}>{approve_list[index].amount_mat}</div>
    );
    rowItem["WIP"] = (
      <div style={{ color: color_alert }}>
        {numberWithCommas(approve_list[index].wip)}
      </div>
    );
    rowItem["FG"] = (
      <div style={{ color: color_alert }}>
        {numberWithCommas(approve_list[index].fg)}
      </div>
    );
    rowItem["In_Hand"] = (
      <div style={{ color: color_alert }}>
        {numberWithCommas(approve_list[index].in_hand)}
      </div>
    );

    rowItem["N1P"] = (
      <div style={{ color: "indianred" }}>
        <NumberFormat
          key={"N1P" + index}
          dataKey={index}
          type="text"
          className="form-control"
          allowNegative={false}
          allowLeadingZeros={false}
          allowEmptyFormatting={false}
          thousandSeparator={true}
          thousandsGroupStyle="thousand"
          decimalScale={0}
          required
          style={{ minWidth: "100px" }}
          value={approve_list[index].firstMonth}
          onValueChange={async (values, e) => {
            const { formattedValue, value } = values;
            const temp = JSON.parse(JSON.stringify(approve_list));

            // const sum =
            //   Number(
            //     temp[e.event.target.getAttribute("dataKey")].nextMonthSave || 0
            //   ) >=
            //   Number(value || 0) +
            //     Number(
            //       temp[e.event.target.getAttribute("dataKey")].secondMonth || 0
            //     ) +
            //     Number(
            //       temp[e.event.target.getAttribute("dataKey")].thirdMonth || 0
            //     ) +
            //     Number(
            //       temp[e.event.target.getAttribute("dataKey")].fourthMonth || 0
            //     )
            //     ? Number(
            //         temp[e.event.target.getAttribute("dataKey")]
            //           .nextMonthSave || 0
            //       ) -
            //       (Number(value || 0) +
            //         Number(
            //           temp[e.event.target.getAttribute("dataKey")]
            //             .secondMonth || 0
            //         ) +
            //         Number(
            //           temp[e.event.target.getAttribute("dataKey")].thirdMonth ||
            //             0
            //         ) +
            //         Number(
            //           temp[e.event.target.getAttribute("dataKey")]
            //             .fourthMonth || 0
            //         ))
            //     : 0;

            const sum = Number(
              temp[e.event.target.getAttribute("dataKey")].nextMonthSave
            ) + Number(value || 0);

            temp[e.event.target.getAttribute("dataKey")].firstMonth = value;
            temp[e.event.target.getAttribute("dataKey")].nextMonth = sum;
            await setapprove_list(temp);
          }}
        />
      </div>
    );
    // rowItem["N2P"] = (
    //   <div style={{ color: "indianred" }}>
    //     <NumberFormat
    //       key={"N2P" + index}
    //       dataKey={index}
    //       type="text"
    //       className="form-control"
    //       allowNegative={false}
    //       allowLeadingZeros={false}
    //       allowEmptyFormatting={false}
    //       thousandSeparator={true}
    //       thousandsGroupStyle="thousand"
    //       decimalScale={0}
    //       required
    //       style={{ minWidth: "100px" }}
    //       value={approve_list[index].secondMonth}
    //       onValueChange={async (values, e) => {
    //         const { formattedValue, value } = values;
    //         const temp = JSON.parse(JSON.stringify(approve_list));

    //         const sum =
    //           Number(
    //             temp[e.event.target.getAttribute("dataKey")].nextMonthSave || 0
    //           ) >=
    //           Number(value || 0) +
    //             Number(
    //               temp[e.event.target.getAttribute("dataKey")].firstMonth || 0
    //             ) +
    //             Number(
    //               temp[e.event.target.getAttribute("dataKey")].thirdMonth || 0
    //             ) +
    //             Number(
    //               temp[e.event.target.getAttribute("dataKey")].fourthMonth || 0
    //             )
    //             ? Number(
    //                 temp[e.event.target.getAttribute("dataKey")]
    //                   .nextMonthSave || 0
    //               ) -
    //               (Number(value || 0) +
    //                 Number(
    //                   temp[e.event.target.getAttribute("dataKey")].firstMonth ||
    //                     0
    //                 ) +
    //                 Number(
    //                   temp[e.event.target.getAttribute("dataKey")].thirdMonth ||
    //                     0
    //                 ) +
    //                 Number(
    //                   temp[e.event.target.getAttribute("dataKey")]
    //                     .fourthMonth || 0
    //                 ))
    //             : 0;

    //         temp[e.event.target.getAttribute("dataKey")].secondMonth = value;
    //         temp[e.event.target.getAttribute("dataKey")].nextMonth = sum;
    //         await setapprove_list(temp);
    //       }}
    //     />
    //   </div>
    // );
    // rowItem["N3P"] = (
    //   <div style={{ color: "indianred" }}>
    //     <NumberFormat
    //       key={"N3P" + index}
    //       dataKey={index}
    //       type="text"
    //       className="form-control"
    //       allowNegative={false}
    //       allowLeadingZeros={false}
    //       allowEmptyFormatting={false}
    //       thousandSeparator={true}
    //       thousandsGroupStyle="thousand"
    //       decimalScale={0}
    //       required
    //       style={{ minWidth: "100px" }}
    //       value={approve_list[index].thirdMonth}
    //       onValueChange={async (values, e) => {
    //         const { formattedValue, value } = values;
    //         const temp = JSON.parse(JSON.stringify(approve_list));

    //         const sum =
    //           Number(
    //             temp[e.event.target.getAttribute("dataKey")].nextMonthSave || 0
    //           ) >=
    //           Number(value || 0) +
    //             Number(
    //               temp[e.event.target.getAttribute("dataKey")].firstMonth || 0
    //             ) +
    //             Number(
    //               temp[e.event.target.getAttribute("dataKey")].secondMonth || 0
    //             ) +
    //             Number(
    //               temp[e.event.target.getAttribute("dataKey")].fourthMonth || 0
    //             )
    //             ? Number(
    //                 temp[e.event.target.getAttribute("dataKey")]
    //                   .nextMonthSave || 0
    //               ) -
    //               (Number(value || 0) +
    //                 Number(
    //                   temp[e.event.target.getAttribute("dataKey")].firstMonth ||
    //                     0
    //                 ) +
    //                 Number(
    //                   temp[e.event.target.getAttribute("dataKey")]
    //                     .secondMonth || 0
    //                 ) +
    //                 Number(
    //                   temp[e.event.target.getAttribute("dataKey")]
    //                     .fourthMonth || 0
    //                 ))
    //             : 0;

    //         temp[e.event.target.getAttribute("dataKey")].thirdMonth = value;
    //         temp[e.event.target.getAttribute("dataKey")].nextMonth = sum;
    //         await setapprove_list(temp);
    //       }}
    //     />
    //   </div>
    // );
    // rowItem["N4P"] = (
    //   <div style={{ color: "indianred" }}>
    //     <NumberFormat
    //       key={"N4P" + index}
    //       dataKey={index}
    //       type="text"
    //       className="form-control"
    //       allowNegative={false}
    //       allowLeadingZeros={false}
    //       allowEmptyFormatting={false}
    //       thousandSeparator={true}
    //       thousandsGroupStyle="thousand"
    //       decimalScale={0}
    //       required
    //       style={{ minWidth: "100px" }}
    //       value={approve_list[index].fourthMonth}
    //       onValueChange={async (values, e) => {
    //         const { formattedValue, value } = values;
    //         const temp = JSON.parse(JSON.stringify(approve_list));

    //         const sum =
    //           Number(
    //             temp[e.event.target.getAttribute("dataKey")].nextMonthSave || 0
    //           ) >=
    //           Number(value || 0) +
    //             Number(
    //               temp[e.event.target.getAttribute("dataKey")].firstMonth || 0
    //             ) +
    //             Number(
    //               temp[e.event.target.getAttribute("dataKey")].secondMonth || 0
    //             ) +
    //             Number(
    //               temp[e.event.target.getAttribute("dataKey")].thirdMonth || 0
    //             )
    //             ? Number(
    //                 temp[e.event.target.getAttribute("dataKey")]
    //                   .nextMonthSave || 0
    //               ) -
    //               (Number(value || 0) +
    //                 Number(
    //                   temp[e.event.target.getAttribute("dataKey")].firstMonth ||
    //                     0
    //                 ) +
    //                 Number(
    //                   temp[e.event.target.getAttribute("dataKey")]
    //                     .secondMonth || 0
    //                 ) +
    //                 Number(
    //                   temp[e.event.target.getAttribute("dataKey")].thirdMonth ||
    //                     0
    //                 ))
    //             : 0;

    //         temp[e.event.target.getAttribute("dataKey")].fourthMonth = value;
    //         temp[e.event.target.getAttribute("dataKey")].nextMonth = sum;
    //         await setapprove_list(temp);
    //       }}
    //     />
    //   </div>
    // );

    rowItem["N1"] = (
      <div style={{ color: "indianred" }}>
        {approve_list[index].n1 !== null
          ? numberWithCommas(approve_list[index].n1)
          : 0}
      </div>
    );
    rowItem["N2"] = (
      <div style={{ color: "indianred" }}>
        {approve_list[index].n2 !== null
          ? numberWithCommas(approve_list[index].n2)
          : 0}
      </div>
    );
    rowItem["N3"] = (
      <div style={{ color: "indianred" }}>
        {approve_list[index].n3 !== null
          ? numberWithCommas(approve_list[index].n3)
          : 0}
      </div>
    );
    rowItem["N4"] = (
      <div style={{ color: "indianred" }}>
        {approve_list[index].n4 !== null
          ? numberWithCommas(approve_list[index].n4)
          : 0}
      </div>
    );

    var color = "violet";
    if (parseFloat(approve_list[index].steel_bar).toFixed(2) == 0) {
      color = "DarkGreen";
    }
    rowItem["steel_bar"] = (
      <>
        <div style={{ color: color, width: "max-content" }}>
          {numberWithCommas(
            parseFloat(approve_list[index].steel_bar).toFixed(2)
          ) +
            " ≈ " +
            numberWithCommas(
              Math.ceil(parseFloat(approve_list[index].steel_bar))
            )}
        </div>
      </>
    );

    rowItem["nm"] = (
      <div style={{ color: "indianred" }}>
        <NumberFormat
          key={"N4P" + index}
          dataKey={index}
          type="text"
          className="form-control"
          allowNegative={false}
          allowLeadingZeros={false}
          allowEmptyFormatting={false}
          thousandSeparator={true}
          thousandsGroupStyle="thousand"
          decimalScale={0}
          required
          style={{ minWidth: "150px" }}
          value={approve_list[index].nextMonth}
          onValueChange={async (values, e) => {
            const { formattedValue, value } = values;
            const temp = JSON.parse(JSON.stringify(approve_list));

            const sum =
              Number(value || 0) -
              Number(
                temp[e.event.target.getAttribute("dataKey")].firstMonth || 0
              ) -
              Number(
                temp[e.event.target.getAttribute("dataKey")].secondMonth || 0
              ) -
              Number(
                temp[e.event.target.getAttribute("dataKey")].thirdMonth || 0
              ) -
              Number(
                temp[e.event.target.getAttribute("dataKey")].fourthMonth || 0
              );

            temp[e.event.target.getAttribute("dataKey")].nextMonthSave = sum >= 0 ? sum : 0;
            temp[e.event.target.getAttribute("dataKey")].nextMonth = value;
            await setapprove_list(temp);
          }}
        />
      </div>
    );

    rowItem["nms"] = (
      <div style={{ color: color }}>
        {numberWithCommas(approve_list[index].actual_suggestion || 0)}
      </div>
    );
    rowItem["nm_order"] = (
      <div style={{ color: "violet" }}>
        {numberWithCommas(approve_list[index].actual_suggestion || 0)}
      </div>
    );

    rowItem["nm_order"] = (
      <NumberFormat
        type="text"
        className="form-control"
        required
        allowNegative={false}
        name={index}
        thousandSeparator={true}
        value={approve_list[index].nmo}
        onChange={approve_update.bind(this)}
      />
    );

    rowsData.push(rowItem);
  }

  // console.log(rowsData)
  /*   const onButton_modal_Click = () => {
    modal_click.current.click();
  }; */

  const data = {
    columns: [
      {
        label: "Item",
        field: "Item",
        sort: "asc",
        width: 50,
      },
      {
        label: "Part_No",
        field: "Part_No",
        sort: "asc",
        width: 50,
      },
      {
        label: "Part_Name",
        field: "Part_Name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Material",
        field: "amount_mat",
        sort: "asc",
        width: 50,
      },
      {
        label: "WIP",
        field: "WIP",
        sort: "asc",
        width: 50,
      },
      {
        label: "FG",
        field: "FG",
        sort: "asc",
        width: 50,
      },
      {
        label: "In_Hand",
        field: "In_Hand",
        sort: "asc",
        width: 50,
      },
      {
        label: /* "N1" */ n0Change + " (Pending)",
        field: "N1P",
        sort: "asc",
        width: 50,
      },
      // {
      //   label: /* "N2" */ n2Change + " (Pending)",
      //   field: "N2P",
      //   sort: "asc",
      //   width: 50,
      // },
      // {
      //   label: /* "N3" */ n3Change + " (Pending)",
      //   field: "N3P",
      //   sort: "asc",
      //   width: 50,
      // },
      // {
      //   label: /* "N4" */ n4Change + " (Pending)",
      //   field: "N4P",
      //   sort: "asc",
      //   width: 50,
      // },
      {
        label: /* "N1" */ n1Change,
        field: "N1",
        sort: "asc",
        width: 50,
      },
      {
        label: /* "N2" */ n2Change,
        field: "N2",
        sort: "asc",
        width: 50,
      },
      {
        label: /* "N3" */ n3Change,
        field: "N3",
        sort: "asc",
        width: 50,
      },
      {
        label: /* "N4" */ n4Change,
        field: "N4",
        sort: "asc",
        width: 50,
      },
      {
        label: "Steel Bar",
        field: "steel_bar",
        sort: "asc",
        width: 50,
      },
      {
        label: "Next_Month",
        field: "nm",
        sort: "asc",
        width: 50,
      },
      {
        label: "Next_Month_Suggestion",
        field: "nms",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };

  const data_xls = {
    columns: [
      {
        label: "Item",
        field: "Item",
        sort: "asc",
        width: 50,
      },
      {
        label: "Part_No",
        field: "Part_No",
        sort: "asc",
        width: 50,
      },
      {
        label: "Part_Name",
        field: "Part_Name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Material",
        field: "amount_mat",
        sort: "asc",
        width: 50,
      },
      {
        label: "WIP",
        field: "WIP",
        sort: "asc",
        width: 50,
      },
      {
        label: "FG",
        field: "FG",
        sort: "asc",
        width: 50,
      },
      {
        label: "In_Hand",
        field: "In_Hand",
        sort: "asc",
        width: 50,
      },
      {
        label: /* "N1" */ n1Change,
        field: "N1",
        sort: "asc",
        width: 50,
      },
      {
        label: /* "N2" */ n2Change,
        field: "N2",
        sort: "asc",
        width: 50,
      },
      {
        label: /* "N3" */ n3Change,
        field: "N3",
        sort: "asc",
        width: 50,
      },
      {
        label: /* "N4" */ n4Change,
        field: "N4",
        sort: "asc",
        width: 50,
      },
      {
        label: "Steel Bar",
        field: "steel_bar",
        sort: "asc",
        width: 50,
      },
      {
        label: "Next_Month_Suggestion",
        field: "nms",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };

  const [tagtono, settagtono] = useState([]);
  function add_tag(params) {
    let track_list = [];
    for (let i = 0; i < approve_list.length; i++) {
      if (approve_list[i].nmo > 0) {
        track_list.push(approve_list[i]);
      }
    }
    var tracking_list = [];
    for (let index = 0; index < track_list.length; index++) {
      const element = {};

      element["tracking_name"] = track_list[index].no;
      element["oem_id"] = getOem();

      tracking_list.push(element);
    }

    axios({
      method: "post",
      url: Configs.API_URL + "/trackingTag/add",
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
      },
      data: tracking_list,
    })
      .then(function (response) {
        settagtono(response.data);
        //console.log("api");
        tracking(response.data);
        //console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function tracking(tagtono2) {
    //console.log('here')
    //console.log(approve_list)
    let track_list = [];
    for (let i = 0; i < approve_list.length; i++) {
      if (approve_list[i].nmo > 0) {
        track_list.push(approve_list[i]);
      }
    }
    /*  var tracking_list = []
    for (let index = 0; index < track_list.length; index++) {
      const element = {};

      element["tracking_name"] = track_list[index].no;
      element["oem_id"] = getOem();
      
      
      tracking_list.push(element);
    } */

    //console.log(tagtono2);

    //console.log("here");

    const doc = new jsPDF("p", "mm", "a4");
    let img_qr = "";

    for (let index = 0; index < track_list.length; index = index + 8) {
      if (index < track_list.length) {
        QRCode2.toDataURL(track_list[index].no.toString(), function (err, url) {
          img_qr = url;
        });

        doc.addImage(img_qr, "JPEG", 58, 10, 50, 60);

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(83, 15, track_list[index].no.toString(), "center");
        doc.rect(0, 10, 105, 60);

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(5, 20, "Tag No :  " + tagtono2[index].tag_no);
        doc.rect(0, 10, 105, 60);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(5, 25, "Amount");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(36);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(12, 38, track_list[index].nmo.toString());

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 45, "Cutting");
        doc.rect(10, 42, 3, 3);
        doc.line(40, 45, 60, 45);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 52, "m/c");
        doc.rect(10, 49, 3, 3);
        doc.line(40, 52, 60, 52);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 59, "WIP");
        doc.rect(10, 56, 3, 3);
        doc.line(40, 59, 60, 59);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 66, "QC");
        doc.rect(10, 63, 3, 3);
        doc.line(40, 66, 60, 66);
      }
      //box 2
      if (index + 1 < track_list.length) {
        QRCode2.toDataURL(
          track_list[index + 1].no.toString(),
          function (err, url) {
            img_qr = url;
          }
        );

        doc.addImage(img_qr, "JPEG", 163, 10, 50, 60);

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(188, 15, track_list[index + 1].no.toString(), "center");
        doc.rect(0, 10, 105, 60);

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(110, 20, "Tag No : " + tagtono2[index + 1].tag_no);
        doc.rect(105, 10, 105, 60);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(110, 25, "Amount");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(36);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(117, 38, track_list[index + 1].nmo.toString());

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 45, "Cutting");
        doc.rect(115, 42, 3, 3);
        doc.line(145, 45, 165, 45);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 52, "m/c");
        doc.rect(115, 49, 3, 3);
        doc.line(145, 52, 165, 52);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 59, "WIP");
        doc.rect(115, 56, 3, 3);
        doc.line(145, 59, 165, 59);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 66, "QC");
        doc.rect(115, 63, 3, 3);
        doc.line(145, 66, 165, 66);
      }
      //box 3
      if (index + 2 < track_list.length) {
        QRCode2.toDataURL(
          track_list[index + 2].no.toString(),
          function (err, url) {
            img_qr = url;
          }
        );

        doc.addImage(img_qr, "JPEG", 58, 70, 50, 60);

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(83, 75, track_list[index + 2].no.toString(), "center");
        doc.rect(0, 10, 105, 60);

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(5, 80, "Tag No : " + tagtono2[index + 2].tag_no);
        doc.rect(0, 70, 105, 60);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(5, 85, "Amount");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(36);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(12, 98, track_list[index + 2].nmo.toString());

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 105, "Cutting");
        doc.rect(10, 102, 3, 3);
        doc.line(40, 105, 60, 105);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 112, "m/c");
        doc.rect(10, 109, 3, 3);
        doc.line(40, 112, 60, 112);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 119, "WIP");
        doc.rect(10, 116, 3, 3);
        doc.line(40, 119, 60, 119);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 126, "QC");
        doc.rect(10, 123, 3, 3);
        doc.line(40, 126, 60, 126);
      }
      //box 4
      if (index + 3 < track_list.length) {
        QRCode2.toDataURL(
          track_list[index + 3].no.toString(),
          function (err, url) {
            img_qr = url;
          }
        );

        doc.addImage(img_qr, "JPEG", 163, 70, 50, 60);

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(188, 75, track_list[index + 3].no.toString(), "center");

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(110, 80, "Tag No : " + tagtono2[index + 3].tag_no);
        doc.rect(105, 70, 105, 60);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(110, 85, "Amount");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(36);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(117, 98, track_list[index + 3].nmo.toString());

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 105, "Cutting");
        doc.rect(115, 102, 3, 3);
        doc.line(145, 105, 165, 105);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 112, "m/c");
        doc.rect(115, 109, 3, 3);
        doc.line(145, 112, 165, 112);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 119, "WIP");
        doc.rect(115, 116, 3, 3);
        doc.line(145, 119, 165, 119);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 126, "QC");
        doc.rect(115, 123, 3, 3);
        doc.line(145, 126, 165, 126);
      }

      //box 5
      if (index + 4 < track_list.length) {
        QRCode2.toDataURL(
          track_list[index + 4].no.toString(),
          function (err, url) {
            img_qr = url;
          }
        );

        doc.addImage(img_qr, "JPEG", 58, 130, 50, 60);

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(83, 135, track_list[index + 4].no.toString(), "center");

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(5, 140, "Tag No : " + tagtono2[index + 4].tag_no);
        doc.rect(0, 130, 105, 60);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(5, 145, "Amount");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(36);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(12, 158, track_list[index + 4].nmo.toString());

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 165, "Cutting");
        doc.rect(10, 162, 3, 3);
        doc.line(40, 165, 60, 165);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 172, "m/c");
        doc.rect(10, 169, 3, 3);
        doc.line(40, 172, 60, 172);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 179, "WIP");
        doc.rect(10, 176, 3, 3);
        doc.line(40, 179, 60, 179);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 186, "QC");
        doc.rect(10, 183, 3, 3);
        doc.line(40, 186, 60, 186);
      }
      //box 6
      if (index + 5 < track_list.length) {
        QRCode2.toDataURL(
          track_list[index + 5].no.toString(),
          function (err, url) {
            img_qr = url;
          }
        );

        doc.addImage(img_qr, "JPEG", 163, 130, 50, 60);

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(188, 135, track_list[index + 5].no.toString(), "center");

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(110, 140, "Tag No : " + tagtono2[index + 5].tag_no);
        doc.rect(105, 130, 105, 60);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(110, 145, "Amount");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(36);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(117, 158, track_list[index + 5].nmo.toString());

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 165, "Cutting");
        doc.rect(115, 162, 3, 3);
        doc.line(145, 165, 165, 165);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 172, "m/c");
        doc.rect(115, 169, 3, 3);
        doc.line(145, 172, 165, 172);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 179, "WIP");
        doc.rect(115, 176, 3, 3);
        doc.line(145, 179, 165, 179);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 186, "QC");
        doc.rect(115, 183, 3, 3);
        doc.line(145, 186, 165, 186);
      }
      //box 7
      if (index + 6 < track_list.length) {
        QRCode2.toDataURL(
          track_list[index + 6].no.toString(),
          function (err, url) {
            img_qr = url;
          }
        );

        doc.addImage(img_qr, "JPEG", 58, 190, 50, 60);

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(83, 195, track_list[index + 6].no.toString(), "center");

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(5, 200, "Tag No : " + tagtono2[index + 6].tag_no);
        doc.rect(0, 190, 105, 60);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(5, 205, "Amount");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(36);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(12, 218, track_list[index + 6].nmo.toString());

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 225, "Cutting");
        doc.rect(10, 222, 3, 3);
        doc.line(40, 225, 60, 225);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 232, "m/c");
        doc.rect(10, 229, 3, 3);
        doc.line(40, 232, 60, 232);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 239, "WIP");
        doc.rect(10, 236, 3, 3);
        doc.line(40, 239, 60, 239);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 246, "QC");
        doc.rect(10, 243, 3, 3);
        doc.line(40, 246, 60, 246);
      }
      //box 8

      if (index + 7 < track_list.length) {
        QRCode2.toDataURL(
          track_list[index + 7].no.toString(),
          function (err, url) {
            img_qr = url;
          }
        );

        doc.addImage(img_qr, "JPEG", 163, 190, 50, 60);

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(188, 195, track_list[index + 7].no.toString(), "center");

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(110, 200, "Tag No : " + tagtono2[index + 7].tag_no);
        doc.rect(105, 190, 105, 60);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(110, 205, "Amount");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(36);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(117, 218, track_list[index + 7].nmo.toString());

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 225, "Cutting");
        doc.rect(115, 222, 3, 3);
        doc.line(145, 225, 165, 225);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 232, "m/c");
        doc.rect(115, 229, 3, 3);
        doc.line(145, 232, 165, 232);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 239, "WIP");
        doc.rect(115, 236, 3, 3);
        doc.line(145, 239, 165, 239);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 246, "QC");
        doc.rect(115, 243, 3, 3);
        doc.line(145, 246, 165, 246);
      }
      if (index + 8 < track_list.length) {
        doc.addPage("a4", "h");
      }
    }
    window.open(doc.output("bloburl"));
  }

  function setPage(page) {
    console.log("this page : ", page);
    setthis_page({ ...this_page, page: page });
  }

  async function setEntries(entries) {
    await setshow_entries({ ...show_entries, entries: entries });
    var page_arr = [];
    let page = 1;
    for (let i = 0; i < approve_list.length; i++) {
      if (Math.ceil(i / entries) >= page) {
        console.log(Math.ceil(i / entries), page);
        let temp = {
          page: page,
        };
        page_arr.push(temp);
        page++;
      }
    }
    await setall_page(page_arr);
    await setPage(1);
  }

  function previous_page() {
    let page_ = this_page.page - 1;
    if (page_ >= 1) {
      setthis_page({ ...this_page, page: page_ });
    }
  }

  function next_page() {
    let page_ = this_page.page + 1;
    if (page_ <= all_page.length) {
      setthis_page({ ...this_page, page: page_ });
    }
  }

  var BN = 0;
  var FN = 0;

  //const head = ['Item','Product No','Product Name','WIP','FG','In Hand','N1','N2','N3','N4','Next Month Suggession','Next Month Order'];
  //const body = ['Item','Part_No','Part_Name','WIP','FG','In_Hand','N1','N2','N3','N4','nms','nm_order'];

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Approve Production Item{"  "}
                  <a target="_blank" href={getAcademy()}>
                    <i class="fa fa-info-circle"></i>
                  </a>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Approve Production Item
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={fillter.year}
                    onChange={(e) =>
                      setfillter({ ...fillter, year: e.target.value })
                    }
                  >
                    <option value="">Select</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                    <option value="2029">2029</option>
                    <option value="2030">2030</option>
                  </select>
                  <label htmlFor="">Year</label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={fillter.month}
                    onChange={(e) => {
                      setfillter({ ...fillter, month: e.target.value });
                      montchange(e.target.value);
                    }}
                  >
                    <option value="Select">Select</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>
                  <label htmlFor="">Month</label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={fillter.suggestion}
                    onChange={(e) =>
                      setfillter({ ...fillter, suggestion: e.target.value })
                    }
                  >
                    <option value="0">Select Month</option>
                    <option value="1">1 Month</option>
                    <option value="2">2 Month</option>
                    <option value="3">3 Month</option>
                    <option value="4">4 Month</option>
                  </select>
                  <label htmlFor="">Month For Suggession</label>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={load_suggrest}
                  >
                    Load Suggestion
                  </button>
                </div>
              </div>

              <div className="col-2">
                <div className="form-group ">
                  <ReactHTMLTableToExcel
                    className="btn btn-block btn-primary"
                    table="table-to-xls_all"
                    filename={"Approve_Production_Item" + " " + Date()}
                    sheet="tablexls"
                    buttonText="Download as XLS"
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={approve_product}
                  >
                    Save To Database
                  </button>
                </div>
              </div>
              <div className="col-2" style={{ display: "none" }}>
                <div className="form-group ">
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-block btn-primary"
                    table="table_negative_list"
                    filename={
                      "Material_missing" +
                      " " +
                      moment(new Date()).format("DD-MM-YYYY")
                    }
                    sheet="tablexls"
                    buttonText="Download as XLS"
                  />
                </div>
              </div>
              {/* {feature.map((e) => {
                if (e.feature === "Report Tracking") {
                  return <BTN onClick={add_tag} name={e.feature_name} />;
                }
              })} */}
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Approve Production Item</h3>
              <div className="card-tools">
                Status Capacity by %{" "}
                <span style={{ color: "green" }}>
                  Max:
                  <NumberFormat
                    displayType="text"
                    name={"capacity"}
                    thousandSeparator={true}
                    value={capacity}
                  />
                  {/*  {capacity}  */}piecse/month
                </span>
              </div>
              <br />
              <Progress multi>
                <Progress
                  bar
                  color="success"
                  value={wip_line.per_sum_wip}
                  id="Tooltip-sumwip"
                >
                  {"WIP:" + wip_line.per_sum_wip + "%"}
                </Progress>
                <Progress
                  bar
                  color="info"
                  value={new_order_line.per_sum_new_order}
                  id="Tooltip-New-Order"
                >
                  {"New Order:" + new_order_line.per_sum_new_order + "%"}
                </Progress>
                <Progress
                  bar
                  color={remain_line.per_remain < 0 ? "danger" : "warning"}
                  value={remain_line.per_remain}
                  id="Tooltip-Remain-Cap"
                >
                  {"Remain Cap:" + remain_line.per_remain + "%"}
                </Progress>
              </Progress>
              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target="Tooltip-sumwip"
                toggle={toggle}
              >
                WIP
              </Tooltip>
              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target="Tooltip-New-Order"
                toggle={toggle}
              >
                New Order
              </Tooltip>
              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target="Tooltip-Remain-Cap"
                toggle={toggle}
              >
                Remain Cap
              </Tooltip>
              <div className="card-tools"> </div>
              <div className="card-tools">
                WIP:{numberWithCommas(wip_line.sum_wip)} | New Order:
                {numberWithCommas(new_order_line.sum_new_order)} | Remain cap:
                {numberWithCommas(remain_line.remain)}
              </div>
            </div>

            <div className="card-body ">
              {/*  <div class="col-1">
                <div class="form-group">
                  <select
                    className="form-control custom-select select2"
                    type="text"
                    required
                    onChange={(e) => setEntries(e.target.value)}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={rowsData.length}>
                      {rowsData.length} (ALL)
                    </option>
                  </select>
                  <label htmlFor="">Show entries</label>
                </div>
              </div>

              <div className="table-responsive">
                <MDBTable
                  className="table table-head-fixed"
                  striped
                  bordered
                  hover
                  fixedHeader
                >
                  <MDBTableHead>
                    <tr>
                      <th>Item</th>
                      <th>Product No</th>
                      <th>Product Name</th>
                      <th>WIP</th>
                      <th>FG</th>
                      <th>In Hand</th>
                      <th>N1</th>
                      <th>N2</th>
                      <th>N3</th>
                      <th>N4</th>
                      <th>Steel Bar</th>
                      <th>Next Month Suggession</th>
                      <th>Next Month Order</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {rowsData.map((el, index) => {
                      //console.log(rowsData);
                      if (
                        (this_page.page - 1) * show_entries.entries <= index &&
                        index < this_page.page * show_entries.entries
                      ) {
                        if (index < this_page.page * show_entries.entries) {
                          FN = index + 1;
                          if (FN == rowsData.length) {
                            BN =
                              (this_page.page - 1) * show_entries.entries + 1;
                          } else {
                            BN = FN - show_entries.entries + 1;
                          }
                        }

                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{el.Part_No}</td>
                            <td>{el.Part_Name}</td>
                            <td>{el.WIP}</td>
                            <td>{el.FG}</td>
                            <td>{el.In_Hand}</td>
                            <td>{el.N1}</td>
                            <td>{el.N2}</td>
                            <td>{el.N3}</td>
                            <td>{el.N4}</td>
                            <td>{el.steel_bar}</td>
                            <td>{el.nms}</td>
                            <td>{el.nm_order}</td>
                          </tr>
                        );
                      }
                    })}
                  </MDBTableBody>
                </MDBTable>
              </div>
              <div className="row">
                <div className="col-5">
                  Showing {BN} to {FN} of {rowsData.length} entries
                </div>
                <div className="col-0.5">
                  <button
                    type="button"
                    className="btn btn-block btn-outline-primary  border"
                    onClick={previous_page}
                  >
                    Previous
                  </button>
                </div>
                {all_page.map((e, index) => {
                  if (
                    e.page + 1 == this_page.page ||
                    e.page + 2 == this_page.page ||
                    this_page.page == e.page - 1 ||
                    this_page.page == e.page - 2 ||
                    this_page.page == e.page
                  ) {
                    if (this_page.page == e.page) {
                      return (
                        <>
                          <div className="col-0.5">
                            <button
                              type="button"
                              className="btn btn-block btn-primary border"
                              key={index}
                              onClick={setPage.bind(this, e.page)}
                            >
                              {index + 1}
                            </button>
                          </div>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <div className="col-0.5">
                            <button
                              type="button"
                              className="btn btn-block btn-outline-primary border"
                              
                              onClick={setPage.bind(this, e.page)}
                            >
                              {index + 1}
                            </button>
                          </div>
                        </>
                      );
                    }
                  }
                })}
                <div className="col-0.5">
                  <button
                    type="button"
                    className="btn btn-block btn-outline-primary  border"
                    onClick={next_page}
                  >
                    Next
                  </button>
                </div>
              </div> */}

              <div className="table-responsive">
                <div style={{ display: "none" }}>
                  <table id="table_negative_list">
                    <tr>
                      <td>Product No</td>
                      <td>Material No</td>
                      <td>Mat in Stock</td>
                      <td>Steel bar</td>
                      <td>Missing in number</td>
                    </tr>
                    {approve_list.map((e) => {
                      var steel = Math.ceil(parseFloat(e.steel_bar));
                      var check = parseInt(e.amount_mat) - steel;
                      if (check < 0 && e.steel_bar > 0) {
                        return (
                          <>
                            <tr>
                              <td>{e.no}</td>
                              <td>{e.mat_no}</td>
                              <td>{e.amount_mat}</td>
                              <td>{steel}</td>
                              <td>{check * -1}</td>
                            </tr>
                          </>
                        );
                      }
                    })}
                  </table>
                  <MDBDataTable
                    className="table table-head-fixed"
                    sortable={false}
                    striped
                    entries={4096}
                    id="table-to-xls_all"
                    bordered
                    hover
                    fixedHeader
                    data={data_xls}
                  />
                </div>
              </div>

              <TABLE
                data={data}
                pageControl={all_page.length === 0 ? false : true}
              />
            </div>
            <div className="card-footer">
              {/* <Button ref={modal_click} color="info" onClick={toggle_modal}>111111111</Button> */}
            </div>
          </div>
        </section>
        <Modal isOpen={modal} toggle={toggle_modal} /* className={className} */>
          <ModalHeader toggle={toggle_modal}>
            Approve Production Item
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-4">
                <label>Year: {fillter.year}</label>
              </div>
              <div className="col-4">
                <label>Month: {fillter.month}</label>
              </div>
              <div className="col-4">
                <label>Month Suggestion: {fillter.suggestion}</label>
              </div>
            </div>
            <div className="table-responsive" style={{ height: "500px" }}>
              <MDBTable striped hover bordered small searching={true}>
                <MDBTableHead>
                  <tr>
                    <th>No.</th>
                    <th>Product No.</th>
                    <th>Amount</th>
                    <th>Status</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {approve_list_update.map((el, index) => {
                    return (
                      <tr id={index}>
                        <td>{index + 1}</td>
                        <td>{el.no}</td>
                        <td>{el.order}</td>
                        <td>{el.status ? "True" : "False"}</td>
                      </tr>
                    );
                  })}
                </MDBTableBody>
              </MDBTable>
            </div>
          </ModalBody>
          <ModalFooter>
            {/*  <Button color="primary" onClick={toggle_modal}>Do Something</Button>{' '} */}
            <Button color="secondary" onClick={toggle_modal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      {/* <Footter /> */}
    </div>
  );
}
export default Product_Approve;
/* Approve Production Item */
