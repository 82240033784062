import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";

import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser,getAcademy } from "../../Utils/Common";
import NumberFormat from "react-number-format";
import { useParams } from "react-router";

function System_Parameter(mode) {

  const [company, setCompany] = useState([]);
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);
  
  const [system_parameter, set_system_parameter] = useState({
    oem_id: getOem(),
    company_id: getUser().com,
    config_name:"",
    confirm_value:"",
 
  });

//   const { id } = useParams();
//   useEffect(() => {
//     if (mode.mode === "read") {
//       setdisable(true);
//       setPageMode("Read");
//     } else if (mode.mode === "add") {
//       setdisable(false);
//       setPageMode("Add");
//     } else {
//       setdisable(false);
//       setPageMode("Edit");
//     }
//   }, []);


  
  const getview = async () => {

      axios({
        method: "post",
        url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getSocialSecurity",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data:system_parameter,
      })
        .then(function (response) {
          console.log("edit_view[0]",response.data.data);
          console.log("edit_view[1]",response.data);
          set_system_parameter({
              config_id: response.data.data.id,
              oem_id: response.data.data.oem_id,
              company_id: response.data.data.company_id,
              config_name:response.data.data.config_name,
              confirm_value:response.data.data.config_value,
           
          });

        })
        .catch(function (error) {
          console.log(error);
        });
   
    
  }
  

  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //console.log(response.data);
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  
  useEffect(() => {
    GetCompany();
    // GetemployeeTyer();
    getview();
    //console.log("id:"+id);
    // GetProject_view();
  }, []);


  

  
  function saveOption(params) {

    const error_list = [];
    
    if(system_parameter.confirm_value == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [อัตราส่วนประกันสังคม]."
      }
      error_list.push(temp_err);
    }

    if(system_parameter.confirm_value > 5){
      let temp_err ={
        message:"ไม่สามารถกรอกประกันสังคมเกิน 5% ตามมาตรฐานได้"
      }
      error_list.push(temp_err);
    }


    if(error_list.length > 0){
      var err_message = "";
      for(var e = 0; e < error_list.length;e++){
        err_message += "<br/>"+error_list[e].message;
      }
     Swal.fire("Error", err_message, "error");
    }
      else{
   

        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL_hrMagenatement + "/api/hrManagement/saveSocialSecurity",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: system_parameter,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  getview();
                  // window.location.href =
                  // "/Human_Resource/ตั้งค่า/System_Parameter";
                });
              }
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
      
   
    }
  
  }


  

  function cancleOption(params) {
    // Swal.fire({
    //   title: 'Do you want to cancel and leave the changes?',
    //   icon:'warning',
    //   showCancelButton: true,
    //   confirmButtonText: `Yes`,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   cancelButtonText:`No`,
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     window.location.href =
    //     "/Human_Resource/ตั้งค่า";
    //   }
    // }
    // );
  }
  return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>social security{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  {/* <li className="breadcrumb-item active">social security</li> */}
                  {/* <li className="breadcrumb-item active">{pageMode}</li> */}
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row mb-2">
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                     <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    id = "btn_save"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    บันทึก
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-1">
                  {/* <button
                    type="button"
                    if
                      onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    ยกเลิก
                  </button> */}
                </div>
              </div>
            )}
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">social security </h3>
           
            </div>

            <div className="card-body">
              <div className="row">
                <div>
                        <h2>
                        &nbsp;   อัตราส่วนประกันสังคม(%):  &nbsp;&nbsp;
                        </h2>
                </div>
              <div className="col-12 col-md-4 col-xl-3">
                  <div class="form-group">
                  <NumberFormat
                      type="text"
                      className="form-control"
                      id = "text_calcurate_social"
                      required
                      allowNegative={false}
                      decimalScale = {2}
                      value={system_parameter.confirm_value}
                      disabled={disable}
                      onChange={(e) => {
                        set_system_parameter({
                          ...system_parameter,
                          confirm_value: e.target.value,
                        });
                      }}
                    />
                    <label>
                    {/* ชื่อช่วงงาน */}
                    {/* {system_parameter.confirm_value.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
              
                {/* <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                  <NumberFormat
                     // meter_oil
                     placeholder="0"
                      type="text"
                      className="form-control"
                      //format={"##:##"} 
                      allowNegative={false}
                      decimalScale = {2}
                      //thousandSeparator={true}
                      required
                      value={system_config.extra_budget}
                      disabled={disable}
                      onChange={(e) => {
                        set_system_config({
                          ...system_config,
                          extra_budget: e.target.value,
                        });
                      }}
                    /> 
                    
                    <label>
                     เงินบวกเพิ่ม  
                      {system_config.extra_budget === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>{" "}
                  </div>
                </div> */}
             
                {/* <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                
                  <NumberFormat
                     // meter_oil
                      type="text"
                      placeholder="00.00"
                      className="form-control"
                      allowNegative={false}
                      format={"##.##"}
                      decimalScale = {0}
                      required
                      value={system_config.begin_time}
                      disabled={disable}
                      // onChange={(e) => {
                      //   set_system_config({
                      //     ...system_config,
                      //     begin_time: e.target.value,
                      //   });
                      // }}
                      onChange={(e) => {
                        console.log(e.target.value)
                        const myArray = e.target.value.split(".");
                        
                          console.log(myArray)
                        if(myArray[0] > 23 || myArray[1] > 59){
                          set_system_config({
                            ...system_config,
                            begin_time: "23.59",
                          });
                        }else{
                          set_system_config({
                            ...system_config,
                            begin_time: e.target.value,
                          });
                        }
                       
                      }}
                    /> 
                    <label>
                     ชั่วโมงเริ่มต้น  
                      {system_config.begin_time === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>{" "}
                  </div>
                </div> */}
                {/* <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                
                  <NumberFormat
                     // meter_oil
                      type="text"
                      placeholder="00.00"
                      className="form-control"
                      format={"##.##"}
                      allowNegative={false}
                      decimalScale = {0}
                      required
                      value={system_config.finish_time}
                      disabled={disable}
                      // onChange={(e) => {
                      //   set_system_config({
                      //     ...system_config,
                      //     finish_time: e.target.value,
                      //   });
                      // }}
                      onChange={(e) => {
                        console.log(e.target.value)
                        const myArray = e.target.value.split(".");
                        
                          console.log(myArray)
                        if(myArray[0] > 23 || myArray[1] > 59){
                          set_system_config({
                            ...system_config,
                            finish_time: "23.59",
                          });
                        }else{
                          set_system_config({
                            ...system_config,
                            finish_time: e.target.value,
                          });
                        }
                       
                      }}
                    /> 
                    <label>
                     ชั่วโมงสุดท้าย  
                      {system_config.finish_time === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>{" "}
                  </div>
                </div> */}
                {/* <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                
                  <NumberFormat
                     // meter_oil
                      type="text"
                      className="form-control"
                      allowNegative={false}
                      decimalScale = {0}
                      required
                      value={system_config.time_to_late}
                      disabled={disable}
                      onChange={(e) => {
                        set_system_config({
                          ...system_config,
                          time_to_late: e.target.value,
                        });
                      }}
                    /> 
                    <label>
                     สาย  
                      {system_config.time_to_late === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>{" "}
                  </div>
                </div> */}
              

              </div>
              <div className="row">
         
                {/* <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                
                  <NumberFormat
                     // meter_oil
                      type="text"
                      className="form-control"
                      allowNegative={false}
                      decimalScale = {0}
                      required
                      value={system_config.break_time_minue}
                      disabled={disable}
                      onChange={(e) => {
                        set_system_config({
                          ...system_config,
                          break_time_minue: e.target.value,
                        });
                      }}
                    /> 
                    <label>
                     พัก(นาที)  
                      {system_config.break_time_minue === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>{" "}
                  </div>
                </div> */}
                {/* <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                
                  <NumberFormat
                     // meter_oil
                      type="text"
                      className="form-control"
                      allowNegative={false}
                      decimalScale = {2}
                      required
                      value={system_config.ratio}
                      disabled={disable}
                      onChange={(e) => {
                        set_system_config({
                          ...system_config,
                          ratio: e.target.value,
                        });
                      }}
                    /> 
                    <label>
                     อัตราส่วน  
                      {system_config.ratio === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>{" "}
                  </div>
                </div> */}
                </div>
                
            </div>
            
          </div>
        </section>
      </div>
     {/*  <Footter/> */}
      </div>
  );
}

export default System_Parameter;
