import React, { Component, useEffect, useState, useRef } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import NumberFormat from "react-number-format";
import { getOem, getToken, getUser, getAcademy } from "../../Utils/Common";
import axios from "axios";
import Configs from "../../config";
import Swal from "sweetalert2";
import moment from "moment";
import { useParams } from "react-router";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";

function Employee_train(mode) {
  const CloseEmpOutModal = useRef();
  const clickCloseEmpOutModal = () => {
    CloseEmpOutModal.current.click();
  };
  const [modal, setModal] = useState({
    check: false,
    index: -1,
  });
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);
  const [trainCourse, setTrainCourse] = useState({
    course_name: "",
    course_type: "",
    course_type_id: "",
    lecturer_name: "",
    begin_date: "",
    end_date: "",
    day_amount: "",
    begin_time: "",
    end_time: "",
    learn_type: "",
    learn_type_id: "",
    location: "",
    course_tel: "",
    facebook: "",
    website: "",
    email: "",
    course_img_name: "",
    course_img_path: "",
    remark: "",
    // tab 2
    // Employee_In: [],
    Employee_Out: [],
    // tab3
    register_cost: "",
    fee_cost: "",
    total_course: "",
    travel_cost: "",
    hotel_cost: "",
    other_cost: [],
    total_other_cost: "",
    grand_total: "",
  });
  const inputFileimage = useRef(null);
  const inputFiledoc = useRef(null);
  function cancleOption(params) {
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "/Human_Resource/HRD/External_dev_train";
      }
    });
  }

  const SaveCourseEmpApi = async () => {
    const error_list = [];
    if (trainCourse.course_name == "") {
      let temp_err = {
        message: "กรุณากรอก ชื่อคอร์ส",
      };
      error_list.push(temp_err);
    }
    if (trainCourse.course_type_id === "") {
      let temp_err = {
        message: "กรุณาเลือก ประเภทคอร์ส",
      };
      error_list.push(temp_err);
    }
    if (trainCourse.begin_date == "") {
      let temp_err = {
        message: "กรุณาเลือก วันที่เริ่ม",
      };
      error_list.push(temp_err);
    }
    if (trainCourse.end_date == "") {
      let temp_err = {
        message: "กรุณาเลือก วันจบ",
      };
      error_list.push(temp_err);
    }
    if (trainCourse.begin_time == "") {
      let temp_err = {
        message: "กรุณาเลือก เวลาเริ่ม",
      };
      error_list.push(temp_err);
    }
    if (trainCourse.end_time == "") {
      let temp_err = {
        message: "กรุณาเลือก เวลาจบ",
      };
      error_list.push(temp_err);
    }
    if (trainCourse.learn_type_id == "") {
      let temp_err = {
        message: "กรุณาเลือกรูปแบบการอบรม",
      };
      error_list.push(temp_err);
    }
    let arrList = [];
    arrList = addEleCost.filter(
      (el) => el.costOtherName === "" || el.costOtherPrice === ""
    );
    if (arrList.length > 0) {
      let temp_err = {
        message: "กรุณากรอกข้อมูลในช่องค่าใช้จ่ายเพิ่มเติม ให้ครบถ้วนด้วยครับ",
      };
      error_list.push(temp_err);
    }

    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    } else {
      if (mode.mode === "add") {
        Swal.fire({
          title: "ต้องการที่จะบันทึกข้อมูล ใช่หรือไม่",
          icon: "info",
          showCancelButton: true,
          confirmButtonText: `ใช่`,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: `ไม่`,
        }).then((result) => {
          if (result.isConfirmed) {
            const temp = {
              course_name: trainCourse.course_name.trim() || null,
              course_type: trainCourse.course_type || null,
              course_type_id: trainCourse.course_type_id || null,
              lecturer_name:
                trainCourse.lecturer_name === ""
                  ? null
                  : trainCourse.lecturer_name.trim(),
              begin_date: trainCourse.begin_date || null,
              end_date: trainCourse.end_date || null,
              day_amount: trainCourse.day_amount || null,
              begin_time: trainCourse.begin_time || null,
              end_time: trainCourse.end_time || null,
              learn_type_id: trainCourse.learn_type_id || null,
              location:
                trainCourse.location === ""
                  ? null
                  : trainCourse.location.trim(),
              course_tel: trainCourse.course_tel || null,
              facebook:
                trainCourse.facebook === ""
                  ? null
                  : trainCourse.facebook.trim(),
              website:
                trainCourse.website === "" ? null : trainCourse.website.trim(),
              email: trainCourse.email === "" ? null : trainCourse.email.trim(),
              course_img_name: trainCourse.course_img_name || null,
              course_img_path: trainCourse.course_img_path || null,
              remark:
                trainCourse.remark === "" ? null : trainCourse.remark.trim(),
              company_id: getUser().com,
              oem_id: getOem(),
              fup_id: getUser().fup,

              register_cost:
                parseFloat(
                  trainCourse.register_cost.replace(/[^0-9.-]+/g, "")
                ) || null,
              fee_cost:
                parseFloat(trainCourse.fee_cost.replace(/[^0-9.-]+/g, "")) ||
                null,
              total_course: parseFloat(trainCourse.total_course) || null,
              travel_cost:
                parseFloat(trainCourse.travel_cost.replace(/[^0-9.-]+/g, "")) ||
                null,
              hotel_cost:
                parseFloat(trainCourse.hotel_cost.replace(/[^0-9.-]+/g, "")) ||
                null,
              other_cost:
                addEleCost.filter(
                  (el) => el.costOtherName !== "" && el.costOtherPrice !== ""
                ) || null,
              total_other_cost:
                parseFloat(trainCourse.total_other_cost) || null,
              grand_total: parseFloat(trainCourse.grand_total) || null,

              Employee_In: Employee_select.length > 0 ? Employee_select : null,
              Employee_Out:
                trainCourse.Employee_Out.length > 0
                  ? trainCourse.Employee_Out
                  : null,
            };
            axios({
              method: "post",
              url:
                Configs.API_URL_hrMagenatement +
                "/api/hrManagement/saveCourseAlltap",
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp,
            })
              .then(function (response) {
                if (response.data) {
                  // console.log(response.data);
                  Swal.fire({
                    icon: "success",
                    title: "Save",
                    showConfirmButton: false,
                    timer: 1500,
                  }).then((result) => {
                    console.log(response.data);
                    window.location.href = "/Human_Resource/HRD/External_dev_train";
                  });
                }
              })
              .catch(function (error) {
                console.log(error);
                Swal.fire({
                  icon: "warning",
                  title: "กรุณากรอกข้อมูลใหม่",
                  text: "มี รายการที่ซ้ำกันในระบบ",
                });
              });
          }
        });
      } else if (mode.mode === "edit") {
        Swal.fire({
          title: "ต้องการที่จะแก้ไขข้อมูล ใช่หรือไม่",
          icon: "info",
          showCancelButton: true,
          confirmButtonText: `ใช่`,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: `ไม่`,
        }).then((result) => {
          if (result.isConfirmed) {
            const temp = {
              id: id, // wait edit
              course_name: trainCourse.course_name.trim() || null,
              course_type: trainCourse.course_type || null,
              course_type_id: trainCourse.course_type_id || null,
              lecturer_name:
                trainCourse.lecturer_name === null
                  ? null
                  : trainCourse.lecturer_name.trim(),
              begin_date: trainCourse.begin_date || null,
              end_date: trainCourse.end_date || null,
              day_amount: trainCourse.day_amount || null,
              begin_time: trainCourse.begin_time || null,
              end_time: trainCourse.end_time || null,
              learn_type_id: trainCourse.learn_type_id || null,
              location:
                trainCourse.location === null
                  ? null
                  : trainCourse.location.trim(),
              course_tel: trainCourse.course_tel || null,
              facebook:
                trainCourse.facebook === null
                  ? null
                  : trainCourse.facebook.trim(),
              website:
                trainCourse.website === null
                  ? null
                  : trainCourse.website.trim(),
              email:
                trainCourse.email === null ? null : trainCourse.email.trim(),
              course_img_name: trainCourse.course_img_name || null,
              course_img_path: trainCourse.course_img_path || null,
              remark:
                trainCourse.remark === null ? null : trainCourse.remark.trim(),
              company_id: getUser().com,
              oem_id: getOem(),
              fup_id: getUser().fup,

              register_cost:
                trainCourse.register_cost === null
                  ? null
                  : parseFloat(
                      trainCourse.register_cost.replace(/[^0-9.-]+/g, "")
                    ) || null,
              fee_cost:
                trainCourse.fee_cost === null
                  ? null
                  : parseFloat(
                      trainCourse.fee_cost.replace(/[^0-9.-]+/g, "")
                    ) || null,
              total_course:
                trainCourse.total_course === null
                  ? null
                  : parseFloat(trainCourse.total_course) || null,
              travel_cost:
                trainCourse.travel_cost === null
                  ? null
                  : parseFloat(
                      trainCourse.travel_cost.replace(/[^0-9.-]+/g, "")
                    ) || null,
              hotel_cost:
                trainCourse.hotel_cost === null
                  ? null
                  : parseFloat(
                      trainCourse.hotel_cost.replace(/[^0-9.-]+/g, "")
                    ) || null,
              other_cost:
                addEleCost.filter(
                  (el) => el.costOtherName !== "" && el.costOtherPrice !== ""
                ) || null,
              total_other_cost:
                trainCourse.total_other_cost === null
                  ? null
                  : parseFloat(trainCourse.total_other_cost) || null,
              grand_total:
                trainCourse.grand_total === null
                  ? null
                  : parseFloat(trainCourse.grand_total) || null,

              Employee_In: Employee_select.length > 0 ? Employee_select : null,
              Employee_Out:
                trainCourse.Employee_Out.length > 0
                  ? trainCourse.Employee_Out
                  : null,
            };
            console.log(temp);
            axios({
              method: "post",
              url:
                Configs.API_URL_hrMagenatement +
                "/api/hrManagement/saveEditCourseAlltap",
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp,
            })
              .then(function (response) {
                if (response.data) {
                  Swal.fire({
                    icon: "success",
                    title: "Save",
                    showConfirmButton: false,
                  }).then((result) => {
                    window.location.href = "/Human_Resource/HRD/External_dev_train";
                  });
                  console.log(response.data);
                } else {
                }
                Swal.close();
              })
              .catch(function (error) {
                console.log(error);
                Swal.fire({
                  icon: "warning",
                  title: "กรุณากรอกข้อมูลใหม่",
                  text: "มีบางอย่างผิดพลาด...",
                });
              });
          }
        });
      }
    }
  };
  const { id } = useParams();
  const getAllCourseApi = async () => {
    if (mode.mode == "edit" || mode.mode == "read") {
      // console.log(id);
      Swal.fire({
        title: "Loading...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 1500,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      axios({
        method: "post",
        url:
          Configs.API_URL_hrMagenatement +
          "/api/hrManagement/GetCourseAlltap?id=" +
          id,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          if (response.data.data === null) {
            Swal.fire({
              icon: "Error",
              text: "ไม่พบข้อมูล",
            }).then(() => {
              window.location.href = "/Human_Resource/HRD/External_dev_train";
            });
            // console.log('log in getEmployeeHistory-- ไม่มีข้อมูลลลลลลลลลล!!!!')
            // window.location.href = "/Human_Resource/ข้อมูลพนักงาน/employee_history";
            // Swal.fire("Error", "ไม่พบข้อมูล", "error");
          }
          console.log("log in GetCourseAlltap--", response.data.data);
          const train_course = response.data.data.data1[0];
          let cost_course = null;
          let cost_course_other = null;
          let check = true;
          let check_table_select = true;
          let table_out = [];
          if (response.data.data.data2 !== null) {
            cost_course = response.data.data.data2[0];
          }
          if (response.data.data.data3 === null) {
            check = false;
          } else {
            cost_course_other = response.data.data.data3;
          }
          if (response.data.data.data4 === null) {
            check_table_select = false;
          }
          if (response.data.data.data5 === null) {
            table_out = [];
          } else {
            table_out = response.data.data.data5;
          }
          // console.log('cost_course_other', cost_course_other);
          setTrainCourse({
            ...trainCourse,
            course_name: train_course.course_name,
            course_type: train_course.course_type_name,
            course_type_id: train_course.course_type_id,
            lecturer_name: train_course.lecturer_name,
            begin_date: new Date(train_course.begin_date),
            end_date: new Date(train_course.end_date),
            day_amount: train_course.day_amount,
            begin_time: new Date(train_course.begin_time),
            end_time: new Date(train_course.end_time),
            learn_type: train_course.learn_type_name,
            learn_type_id: train_course.learn_type_id,
            location: train_course.location,
            course_tel: train_course.course_tel,
            facebook: train_course.facebook,
            website: train_course.website,
            email: train_course.email,
            course_img_name: train_course.course_img_name,
            course_img_path: train_course.course_img_path,
            remark: train_course.remark,

            register_cost:
              cost_course === null ? "" : cost_course.register_cost,
            fee_cost: cost_course === null ? "" : cost_course.fee_cost,
            total_course: cost_course === null ? "" : cost_course.total_course,
            travel_cost: cost_course === null ? "" : cost_course.travel_cost,
            hotel_cost: cost_course === null ? "" : cost_course.hotel_cost,
            other_cost: cost_course_other,
            total_other_cost:
              cost_course === null ? "" : cost_course.total_other_cost,
            grand_total: cost_course === null ? "" : cost_course.grand_total,

            Employee_Out: table_out,
          });
          if (check) {
            const arrList = addEleCost;
            setAddEleCost([]);
            for (let i = 0; i < cost_course_other.length; i++) {
              const temp = {
                costOtherName:
                  cost_course_other[i].course_order === NaN
                    ? 0
                    : cost_course_other[i].course_cost === ""
                    ? 0
                    : cost_course_other[i].course_order,
                costOtherPrice:
                  cost_course_other[i].course_cost === ""
                    ? 0
                    : cost_course_other[i].course_cost === NaN
                    ? 0
                    : cost_course_other[i].course_cost,
              };
              arrList.push(temp);
            }
            setAddEleCost(arrList);
            settest({ ...test, test: "" });
          }
          let arrList = Employee_select;
          if (check_table_select) {
            for (
              let index = 0;
              index < response.data.data.data4.length;
              index++
            ) {
              const element = response.data.data.data4[index];
              arrList.push(element);
            }
            console.log("Table1", arrList);
            setEmployee_select(arrList);
            console.log("Employee_select", Employee_select);
            getEmployeeModal(arrList);
            settest({ ...test, test: "" });
            test.test = "";
          }

          // console.log(response.data.data.data5);
          trainCourse.Employee_Out = table_out;
          // console.log("Table2", trainCourse.Employee_Out);
          settest({ ...test, test: "" });
          test.test = "";

          Swal.close();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const [GetCourseType, setGetCourseType] = useState([]);
  const GetCourseTypeApi = async () => {
    axios({
      method: "get",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getCourseType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        /* console.log(response.data.data,"xx",); */
        // console.log("data response --------->" + response.data);
        setGetCourseType(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [GetLearnType, setGetLearnType] = useState([]);
  const GetLearnTypeApi = async () => {
    axios({
      method: "get",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getLearnType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        /* console.log(response.data.data,"xx",); */
        // console.log("data response --------->" + response.data);
        setGetLearnType(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    GetCourseTypeApi();
    GetLearnTypeApi();
    getAllCourseApi();
  }, []);

  const [addEleCost, setAddEleCost] = useState([]);
  const [test, settest] = useState({
    test: "",
  });
  // const [otherCostArr, setOtherCostArr] = useState([]);
  function addElementCost() {
    const arrList = addEleCost;
    setAddEleCost([]);
    const temp = {
      costOtherName: "",
      costOtherPrice: "",
    };
    arrList.push(temp);
    setAddEleCost(arrList);
    settest({ ...test, test: "" });
  }

  function deleteElementCost(index) {
    const arrList = addEleCost;
    arrList.splice(index, 1);

    setAddEleCost(arrList);
    settest({ ...test, test: "" });
  }
  function calGantTotal() {
    let sum = 0;
    let total_courseCheck = 0;
    let total_other_costCheck = 0;
    // console.log('trainCourse.total_course', trainCourse.total_course);
    if (
      trainCourse.total_course === "" ||
      trainCourse.total_course === null ||
      trainCourse.total_course === 0
    ) {
      trainCourse.total_course = 0;
      total_courseCheck = 0;
    } else {
      total_courseCheck = parseFloat(trainCourse.total_course);
    }
    if (
      trainCourse.total_other_cost === "" ||
      trainCourse.total_other_cost === null ||
      trainCourse.total_other_cost === 0
    ) {
      trainCourse.total_other_cost = 0;
      total_other_costCheck = 0;
    } else {
      total_other_costCheck = parseFloat(trainCourse.total_other_cost);
    }
    //((trainCourse.total_course === "" ? 0 : parseInt(trainCourse.total_course.replace(",", ""))) + (trainCourse.total_other_cost === "" ? 0 : parseInt(trainCourse.total_other_cost(",", ""))))
    sum = total_courseCheck + total_other_costCheck;
    setTrainCourse({ ...trainCourse, grand_total: sum });
    trainCourse.grand_total = sum;
  }
  useEffect(() => {
    calGantTotal();
  }, [trainCourse.total_course, trainCourse.total_other_cost]);

  function calTotalCourseCost() {
    let sum = 0;
    let register_costCheck = 0;
    let fee_costCheck = 0;
    if (
      trainCourse.register_cost === "" ||
      trainCourse.register_cost === null ||
      trainCourse.register_cost === 0
    ) {
      // trainCourse.register_cost = 0;
      register_costCheck = 0;
    } else {
      // console.log('trainCourse.register_cost', trainCourse.register_cost);
      register_costCheck = parseFloat(
        trainCourse.register_cost.replace(/[^0-9.-]+/g, "")
      );
      // console.log('register_costCheck', register_costCheck);
    }
    if (
      trainCourse.fee_cost === "" ||
      trainCourse.fee_cost === null ||
      trainCourse.fee_cost === 0
    ) {
      // trainCourse.fee_cost = 0;
      fee_costCheck = 0;
    } else {
      fee_costCheck = parseFloat(
        trainCourse.fee_cost.replace(/[^0-9.-]+/g, "")
      );
      // console.log('fee_costCheck', fee_costCheck);
    }
    sum = fee_costCheck + register_costCheck;
    setTrainCourse({ ...trainCourse, total_course: sum });
    trainCourse.total_course = sum;
  }
  useEffect(() => {
    calTotalCourseCost();
  }, [trainCourse.register_cost, trainCourse.fee_cost]);

  function calTotalOtherCost() {
    let sum = 0;
    let travel_costCheck = 0;
    let hotel_costCheck = 0;
    if (
      trainCourse.travel_cost === "" ||
      trainCourse.travel_cost === null ||
      trainCourse.travel_cost === 0
    ) {
      // trainCourse.travel_cost = 0;
      travel_costCheck = 0;
    } else {
      travel_costCheck = parseFloat(
        trainCourse.travel_cost.replace(/[^0-9.-]+/g, "")
      );
    }
    if (
      trainCourse.hotel_cost === "" ||
      trainCourse.hotel_cost === null ||
      trainCourse.hotel_cost === 0
    ) {
      // trainCourse.hotel_cost = 0;
      hotel_costCheck = 0;
    } else {
      hotel_costCheck = parseFloat(
        trainCourse.hotel_cost.replace(/[^0-9.-]+/g, "")
      );
    }

    sum = hotel_costCheck + travel_costCheck;
    // console.log('sum in total other cost1', sum);
    let totalCostOtherPrice = 0;
    if (addEleCost.length > 0)
      totalCostOtherPrice = addEleCost.reduce(
        (total, current) =>
          total +
          parseFloat(current.costOtherPrice.replace(/[^0-9.-]+/g, "") || 0),
        0
      );

    sum += totalCostOtherPrice;
    // console.log('sum in total other cost2', sum);
    setTrainCourse({ ...trainCourse, total_other_cost: sum });
    trainCourse.total_other_cost = sum;
  }
  useEffect(() => {
    calTotalOtherCost();
  }, [trainCourse.travel_cost, trainCourse.hotel_cost, addEleCost]);

  function calAmountDay() {
    if (trainCourse.end_date !== "") {
      const date_dif = moment(trainCourse.end_date).diff(
        trainCourse.begin_date,
        "days"
      );
      setTrainCourse({ ...trainCourse, day_amount: date_dif + 1 });
    }
  }

  useEffect(() => {
    calAmountDay();
  }, [trainCourse.end_date]);

  useEffect(() => {
    // console.log('mode', mode.mode)
    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "edit") {
      setPageMode("Edit");
      setdisable(false);
    } else {
      setPageMode("Add");
      setdisable(false);
    }
  }, []);

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
        data-provide="datepicker"
        data-date-language="th-th"
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );
  /******************************************************* Tab 2 ****************************************************/
  const [Change, setChange] = useState({
    change: true,
    index: -1,
  });
  useEffect(() => {
    // filterNameTitle();
    getEmployeeModal();
    filterDepartment();
  }, []);

  useEffect(() => {
    setEmployeeOut(editEmployeeOut);
  }, [Change]);

  /* const [getNameTitle, setgetNameTitle] = useState([])
    const filterNameTitle = async () => {
        axios({
            method: "GET",
            url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getNameTitle",
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
        }).then((response) => {
            console.log(response.data.data);
            setgetNameTitle(response.data.data);
        }).catch((error) => {
            console.log(error);
        });
    }; */
  const [getDepartment, setgetDepartment] = useState([]);
  const filterDepartment = async () => {
    axios({
      method: "GET",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getDepartment",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        // console.log(response.data.data);
        setgetDepartment(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [EmployeeOut, setEmployeeOut] = useState({
    // emp_out_name_title: "",
    emp_name: "",
    emp_nickname: "",
    emp_no: "",
    emp_department: "",
    emp_position: "",
    emp_email: "",
    emp_tel: "",
  });
  const resetEmployeeOut = {
    // emp_out_name_title: "",
    emp_name: "",
    emp_nickname: "",
    emp_no: "",
    emp_department: "",
    emp_position: "",
    emp_email: "",
    emp_tel: "",
  };
  const [editEmployeeOut, seteditEmployeeOut] = useState([]);

  function inputAllFiled() {
    const error_list = [];
    /* if (EmployeeOut.emp_out_name_title === "") {
            let temp_err = {
                message: "กรุณาเลือก คำนำหน้าชื่อ",
            };
            error_list.push(temp_err);
        } */
    if (EmployeeOut.emp_name === "") {
      let temp_err = {
        message: "กรุณากรอก ชื่อ",
      };
      error_list.push(temp_err);
    }
    if (EmployeeOut.emp_nickname === "") {
      let temp_err = {
        message: "กรุณากรอก ชื่อเล่น",
      };
      error_list.push(temp_err);
    }
    if (EmployeeOut.emp_no === "") {
      let temp_err = {
        message: "กรุณากรอก รหัสพนักงาน",
      };
      error_list.push(temp_err);
    }
    if (EmployeeOut.emp_department === "") {
      let temp_err = {
        message: "กรุณากรอก แผนก",
      };
      error_list.push(temp_err);
    }
    if (EmployeeOut.emp_position === "") {
      let temp_err = {
        message: "กรุณากรอก ตำแหน่ง",
      };
      error_list.push(temp_err);
    }
    if (EmployeeOut.emp_email === "") {
      let temp_err = {
        message: "กรุณากรอก อีเมล",
      };
      error_list.push(temp_err);
    }
    if (EmployeeOut.emp_tel === "") {
      let temp_err = {
        message: "กรุณากรอก เบอร์โทรศัพท์",
      };
      error_list.push(temp_err);
    }
    if (error_list.length > 0) {
      let err_message = "";
      for (let e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    }
    return error_list.length > 0 ? true : false;
  }

  const rowsData2 = [];
  for (let index = 0; index < trainCourse.Employee_Out.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["emp_out_name"] = trainCourse.Employee_Out[index].emp_name || null;
    rowItem["emp_out_nickname"] =
      trainCourse.Employee_Out[index].emp_nickname || null;
    rowItem["emp_out_id"] = trainCourse.Employee_Out[index].emp_no || null;
    rowItem["emp_out_department"] =
      trainCourse.Employee_Out[index].emp_department || null;
    rowItem["emp_out_position"] =
      trainCourse.Employee_Out[index].emp_position || null;
    rowItem["emp_out_email"] =
      trainCourse.Employee_Out[index].emp_email || null;
    rowItem["emp_out_mobile"] = trainCourse.Employee_Out[index].emp_tel || null;
    rowItem["mgt"] = (
      <div className="row" style={{ textAlign: "center" }}>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          {/* <a
                        href={"#"}
                        id="btn_read"
                        // key={Employee[index].emp_id}
                        className="btn btn-xs "
                    >
                        <i class="fas fa-eye"></i>
                        {"   "}
                    </a> */}
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          {mode.mode === "read" ? (
            ""
          ) : (
            <a
              // id="btn_edit"
              // key={Employee[index].emp_id}
              className=" btn btn-xs "
              data-toggle="modal"
              data-target="#modal-trainee-out"
              onClick={() => {
                seteditEmployeeOut(trainCourse.Employee_Out[index]);
                setChange({ change: false, index: index });
              }}
            >
              <i class="fas fa-pencil-alt"></i>
            </a>
          )}
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          {mode.mode === "read" ? (
            ""
          ) : (
            <a
              // key={Course[index].emp_id}
              // id="btn_delete"
              className=" btn btn-xs "
              // onClick={deldata.bind(this, allCourse[index].course_id)}
              onClick={() => {
                const emp_out_row = trainCourse.Employee_Out;
                emp_out_row.splice(index, 1);
                setTrainCourse({ ...trainCourse, Employee_Out: emp_out_row });
              }}
            >
              <i class="fas fa-trash-alt"></i>
            </a>
          )}
        </div>
      </div>
    );
    rowsData2.push(rowItem);
  }

  const dataTraineeOut = {
    columns: [
      {
        label: "ลำดับ",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อ-นามสกุล",
        field: "emp_out_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อเล่น",
        field: "emp_out_nickname",
        sort: "asc",
        width: 50,
      },
      {
        label: "รหัสพนักงาน",
        field: "emp_out_id",
        sort: "asc",
        width: 50,
      },
      {
        label: "แผนก",
        field: "emp_out_department",
        sort: "asc",
        width: 50,
      },
      {
        label: "ตำแหน่ง",
        field: "emp_out_position",
        sort: "asc",
        width: 50,
      },
      {
        label: "Email",
        field: "emp_out_email",
        sort: "asc",
        width: 50,
      },
      {
        label: "เบอร์โทรศัพท์",
        field: "emp_out_mobile",
        sort: "asc",
        width: 50,
      },
      {
        label: "จัดการ",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData2,
  };
  const [Employee_select, setEmployee_select] = useState([]);
  const rowsData = [];
  for (let index = 0; index < Employee_select.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["emp_name"] = Employee_select[index].emp_name || null;
    rowItem["emp_id"] = Employee_select[index].emp_no || null;
    rowItem["emp_position"] = Employee_select[index].emp_position || null;
    rowItem["emp_department"] = Employee_select[index].emp_department || null;
    rowItem["mgt"] = (
      <div className="row" style={{ textAlign: "center" }}>
        <div className="col-xl-12 col-md-12 col-xs-12 ">
          <button
            type="button"
            disabled={disable}
            id={Employee_select[index].employee_id}
            onClick={removeEmp.bind(this)}
            class="btn btn-block btn-danger "
          >
            Remove
          </button>
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }

  const dataTraineeIn = {
    columns: [
      {
        label: "ลำดับ",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อ-นามสกุล",
        field: "emp_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "รหัสพนักงาน",
        field: "emp_id",
        sort: "asc",
        width: 50,
      },
      {
        label: "แผนก",
        field: "emp_department",
        sort: "asc",
        width: 50,
      },
      {
        label: "ตำแหน่ง",
        field: "emp_position",
        sort: "asc",
        width: 50,
      },
      {
        label: "จัดการ",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };
  const [MDBT, setMDBT] = useState(true);
  let [Employee, setEmployee] = useState([]);

  const [filter_employee, setfilter_employee] = useState({
    oem_id: getOem(),
    company_id: getUser().com,
    emp_no: "",
    emp_name_th: "",
    department_id: "",
  });

  async function getEmployeeModal(select_emp) {
    var get_filter_employee = {
      oem_id: getOem(),
      company_id: getUser().com,
      emp_no: filter_employee.emp_no.trim(),
      emp_name_th: filter_employee.emp_name_th.trim(),
      department_id: filter_employee.department_id.trim(),
    };
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getEmployeeForModalSelect",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filter_employee,
    })
      .then(async function (response) {
        const EmpAll = [];
        setEmployee([]);
        for (let i of response.data.data) {
          let check = [];
          if (select_emp !== null && select_emp !== undefined) {
            check = select_emp.filter((e) => e.employee_id === i.id);
          } else {
            check = Employee_select.filter((e) => e.employee_id === i.id);
          }
          if (check.length > 0) {
            i.isPush = false;
          } else {
            i.isPush = true;
          }
          EmpAll.push(i);
        }
        setEmployee(EmpAll);
        Employee = EmpAll;
        setMDBT(true);
        settest({ ...test, test: "" });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function clearFilter() {
    setfilter_employee({
      oem_id: getOem(),
      company_id: getUser().com,
      emp_no: "",
      emp_name_th: "",
      department_id: "",
    });
    filter_employee.emp_no = "";
    filter_employee.emp_name_th = "";
    filter_employee.department_id = "";
    getEmployeeModal();
  }

  function removeEmp(e) {
    const employee_p = Employee;
    setEmployee([]);
    for (let i of employee_p) {
      if (i.id === e.target.employee_id || i.id === e.target.id) {
        i.isPush = true;
      }
    }
    setEmployee(employee_p);
    const emp_select_temp = Employee_select;
    setEmployee_select([]);
    let indexOf = emp_select_temp.findIndex(
      (element) =>
        element.employee_id === e.target.employee_id ||
        element.employee_id === e.target.id
    );
    emp_select_temp.splice(indexOf, 1);
    setEmployee_select(emp_select_temp);
    settest({ ...test, test: "" });
    test.test = "";
  }

  function pushEmp(e) {
    const emp_select_copy = Employee_select;
    let data = Employee.filter((item) => {
      return item.id === e.target.id;
    });
    const emp_copy = Employee;
    for (let i of emp_copy) {
      if (i.id === e.target.id) {
        i.isPush = false;
        i.employee_id = e.target.id;
      }
    }
    setEmployee(emp_copy);
    let check = emp_select_copy.filter((item2) => {
      return item2.employee_id === data[0].id;
    });
    if (check.length > 0) {
      console.log("มีอยู่แล้ว");
    } else {
      emp_select_copy.push(data[0]);
    }
    setEmployee_select([]);
    setEmployee_select(emp_select_copy);
    settest({ ...test, test: "" });
    test.test = "";
  }

  const rowsDataEmployee = [];
  for (let index = 0; index < Employee.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["emp_name_th"] = Employee[index].emp_name || null;
    rowItem["emp_no"] = Employee[index].emp_no || null;
    rowItem["emp_department"] = Employee[index].emp_department || null;
    rowItem["emp_position"] = Employee[index].emp_position || null;
    rowItem["mgt"] = (
      <div className="row" style={{ textAlign: "center" }}>
        {Employee[index].isPush === false ? (
          <div className="col-xl-12 col-md-12 col-xs-12 ">
            <button
              type="button"
              className="btn btn-block btn-success"
              id={Employee[index].id}
              onClick={removeEmp.bind(this)}
            >
              ถูกเลือกเข้าอบรมแล้ว
            </button>
          </div>
        ) : (
          <div className="col-xl-12 col-md-12 col-xs-12 ">
            <button
              type="button"
              className="btn btn-block btn-danger"
              id={Employee[index].id}
              onClick={pushEmp.bind(this)}
            >
              ยังไม่ถูกเลือกเข้าอบรม
            </button>
          </div>
        )}
      </div>
    );
    rowsDataEmployee.push(rowItem);
  }

  const dataEmployee = {
    columns: [
      {
        label: "ลำดับ",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อ-นามสกุล",
        field: "emp_name_th",
        sort: "asc",
        width: 50,
      },
      {
        label: "รหัสพนักงาน",
        field: "emp_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "แผนก",
        field: "emp_department",
        sort: "asc",
        width: 50,
      },
      {
        label: "ตำแหน่ง",
        field: "emp_position",
        sort: "asc",
        width: 50,
      },
      {
        label: "สถานะ",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsDataEmployee,
  };

  const [closeModal, setCloseModal] = useState({
    checkData: null,
  });

  async function uploadfileImage(e) {
    const file = e.target.files[0];

    var data = new FormData();
    data.append("File", file);

    console.log(file);
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/boxUpload",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then(function (response) {
        setGetCourseType(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  /*  async function uploadfileDoc(file) {
       axios({
           method: "post",
           url: Configs.API_URL_hrMagenatement + "/api/hrManagement/boxUpload",
           headers: {
             Authorization: getToken(),
             "X-TTT": Configs.API_TTT,
             "Content-Type": "application/json",
           },
         })
           .then(function (response) {
          
             setGetCourseType(response.data.data);
           })
           .catch(function (error) {
             console.log(error);
           });
     }
    */

  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>เพิ่มข้อมูลรายละเอียดคอร์ส</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Employee</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
            {/* button บันทึก และ ยกเลิก ยังไม่link */}
            <div className="container-fluid">
              {mode.mode === "read" ? (
                <div className="row mb-2">
                  {/*  <div className="col-6 col-md-3 col-xl-1">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                window.location.href =
                                                    "/Human_Resource/employee_train/edit/" + id;
                                            }}
                                            class="btn btn-block btn-success "
                                        >
                                            แก้ไข
                                        </button>
                                    </div> */}
                  <div className="col-6 col-md-3 col-xl-1">
                    <button
                      type="button"
                      onClick={() => {
                        window.location.href =
                          "/Human_Resource/HRD/External_dev_train";
                      }}
                      class="btn btn-block btn-danger "
                    >
                      ยกเลิก
                    </button>
                  </div>
                </div>
              ) : mode.mode === "edit" ? (
                <div className="row mb-2">
                  <div className="col-6 col-md-3 col-xl-1">
                    <button
                      type="button"
                      onClick={SaveCourseEmpApi}
                      class="btn btn-block btn-success "
                    >
                      บันทึก
                    </button>
                  </div>
                  <div className="col-6 col-md-3 col-xl-1">
                    <button
                      type="button"
                      onClick={() => {
                        cancleOption();
                      }}
                      class="btn btn-block btn-danger "
                    >
                      ยกเลิก
                    </button>
                  </div>
                </div>
              ) : (
                <div className="row mb-2">
                  <div className="col-6 col-md-3 col-xl-2">
                    <button
                      type="button"
                      onClick={SaveCourseEmpApi}
                      class="btn btn-block btn-success "
                    >
                      บันทึก
                    </button>
                  </div>
                  <div className="col-6 col-md-3 col-xl-2">
                    <button
                      type="button"
                      onClick={() => {
                        cancleOption();
                      }}
                      class="btn btn-block btn-danger "
                    >
                      ยกเลิก
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card card-primary card-outline card-outline-tabs">
            <div className="card-header p-0 border-bottom-0">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-four-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="custom-tabs-four-page-1-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-page-1"
                    role="tab"
                    aria-controls="custom-tabs-four-page-1"
                    aria-selected="true"
                  >
                    รายละเอียดคอร์ส
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-four-page-2-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-page-2"
                    role="tab"
                    aria-controls="custom-tabs-four-page-2"
                    aria-selected="false"
                  >
                    รายละเอียดผู้อบรม
                  </a>
                  {/* {mode.mode === "read" || mode.mode === "edit" ?
                                        : (isSecondTabEnabled === true) ?
                                            <a className={"nav-link " + activeTab.ActiveTab2} id="custom-tabs-four-page-2-tab" data-toggle="pill" href="#custom-tabs-four-page-2" role="tab" aria-controls="custom-tabs-four-page-2" aria-selected="false" >ประวัติการศึกษา</a>
                                            :
                                            <a className="nav-link disabled" id="custom-tabs-four-page-2-tab" data-toggle="pill" href="#custom-tabs-four-page-2" role="tab" aria-controls="custom-tabs-four-page-2" aria-selected="false" >ประวัติการศึกษา</a>
                                    } */}
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-four-page-3-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-page-3"
                    role="tab"
                    aria-controls="custom-tabs-four-page-3"
                    aria-selected="false"
                  >
                    ค่าใช้จ่าย
                  </a>
                  {/* {mode.mode === "read" || mode.mode === "edit" ?
                                        : (isThridTabEnabled === true) ?
                                            <a className={"nav-link " + activeTab.ActiveTab3} id="custom-tabs-four-page-3-tab" data-toggle="pill" href="#custom-tabs-four-page-3" role="tab" aria-controls="custom-tabs-four-page-3" aria-selected="false" >ประวัติการฝึกงาน/ทำงาน</a>
                                            :
                                            <a className="nav-link disabled" id="custom-tabs-four-page-3-tab" data-toggle="pill" href="#custom-tabs-four-page-3" role="tab" aria-controls="custom-tabs-four-page-3" aria-selected="false" >ประวัติการฝึกงาน/ทำงาน</a>
                                    } */}
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-four-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="custom-tabs-four-page-1"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-page-1-tab"
                >
                  {/* ส่วนแรก */}
                  <div className="">
                    <h3 className="mb-2  mt-5-head">รายละเอียดคอร์ส</h3>
                  </div>
                  {/* start tag image */}
                  <div className="row">
                    <div className="col-12 col-md-12 col-xl-4">
                      <label htmlFor="exampleInputFile"> </label>
                      <img
                        id="img"
                        alt="..."
                        className="img-fluid rounded shadow border-0"
                        src={userdefault_img.imgs}
                        style={{
                          width: "300px",
                          height: "200px",
                          position: "relative",
                        }}
                      />
                      <br />
                      <span style={{ color: "red", fontSize: "12px" }}>
                        Recommend Size:400x300px{" "}
                      </span>
                    </div>
                    <div className="col-12 col-md-12 col-xl-4"></div>

                    {/* <div className="col-12 col-md-12 col-xl-8"></div>
                                        <div className="col-12 col-md-12 col-xl-1"></div> */}
                  </div>
                  <div className="row">
                    <div className="col-6 col-md-4 col-xl-4">
                      <div className="form-group ">
                        <input
                          style={{ display: "none" }}
                          type="file"
                          className="custom-file-input"
                          id="exampleInputFile"
                          ref={inputFileimage}
                          /*  disabled={addRoedit} */
                          accept="image/*"
                          onChange={uploadfileImage}
                        />
                        <button
                          type="button"
                          id="uploadImages"
                          className="btn btn-block btn-info"
                          onClick={() => {
                            inputFileimage.current.click();
                          }}
                        >
                          อัพโหลดรูปภาพ
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 col-md-4 col-xl-4">
                      <div className="form-group ">
                        <input
                          style={{ display: "none" }}
                          type="file"
                          className="custom-file-input"
                          id="exampleInputFile"
                          ref={inputFiledoc}
                          /*  disabled={addRoedit} */
                          accept="*"
                          onChange={uploadfileImage}
                        />
                        <button
                          type="button"
                          id="btn_add"
                          className="btn btn-block btn-info"
                          onClick={() => {
                            inputFiledoc.current.click();
                          }}
                        >
                          ลิ้งค์เอกสารเพิ่มเติม
                        </button>
                      </div>
                    </div>
                  </div>
                  {/*  end tag image */}
                  {/* course name and course type */}
                  <div className="row">
                    <div className="col-12 col-md-6 col-xl-6">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          required
                          id="text_position"
                          value={trainCourse.course_name}
                          disabled={disable}
                          onChange={(e) => {
                            setTrainCourse({
                              ...trainCourse,
                              course_name: e.target.value,
                            });
                          }}
                        />
                        <label>
                          ชื่อคอร์ส
                          {trainCourse.course_name === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-2 col-xl-">
                      <div class="form-group">
                        <select
                          className="form-control custom-select select2"
                          style={{ fontSize: "20px", padding: "5px" }}
                          type="text"
                          required
                          id="name_title_th"
                          value={trainCourse.course_type_id}
                          disabled={disable}
                          onChange={(e) => {
                            const _find = GetCourseType.find(
                              (where) => where.id === e.target.value
                            );
                            setTrainCourse({
                              ...trainCourse,
                              course_type_id: e.target.value,
                              course_type: _find?.name ?? null,
                            });
                          }}
                        >
                          <option value="" disabled selected>
                            --เลือก--
                          </option>
                          {GetCourseType.map((el) => {
                            return <option value={el.id}>{el.name}</option>;
                          })}
                        </select>
                        <label htmlFor="">
                          ประเภทคอร์ส
                          {trainCourse.course_type_id === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* lecturer by  */}
                  <div className="row">
                    <div className="col-12 col-md-6 col-xl-6">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          required
                          id="text_position"
                          value={trainCourse.lecturer_name}
                          disabled={disable}
                          onChange={(e) => {
                            setTrainCourse({
                              ...trainCourse,
                              lecturer_name: e.target.value,
                            });
                          }}
                        />
                        <label>
                          จัดโดยวิทยากร
                          {/* {employee.emp_position === "" ? (
                                                        <span style={{ color: "red" }}> *</span>
                                                    ) : (
                                                        ""
                                                    )} */}
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* begin_date , end_date and date between */}
                  <div className="row">
                    <div className="col-12 col-md-3 col-xl-3">
                      <div class="form-group">
                        <DatePicker
                          selected={trainCourse.begin_date}
                          disabled={disable}
                          wrapperClassName="w-100"
                          id="text_birthday"
                          dateFormat={"dd-MM-yyyy"}
                          locale="th"
                          onChange={async (date) => {
                            setTrainCourse({
                              ...trainCourse,
                              begin_date: date,
                            });
                          }}
                          // startDate={employee.emp_birthday}
                          showYearDropdown
                          showMonthDropdown
                          customInput={<CustomInput />}
                        ></DatePicker>
                        <label>
                          วันที่เริ่ม
                          {trainCourse.begin_date === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 col-xl-3">
                      <div class="form-group">
                        <DatePicker
                          selected={trainCourse.end_date}
                          disabled={
                            mode.mode === "read"
                              ? true
                              : trainCourse.begin_date === ""
                              ? true
                              : false
                          }
                          wrapperClassName="w-100"
                          id="text_national_exp_date"
                          dateFormat={"dd-MM-yyyy"}
                          locale="th"
                          onChange={async (date) => {
                            setTrainCourse({
                              ...trainCourse,
                              end_date: date,
                            });
                          }}
                          //   startDate={employee.emp_national_id_exp_date}
                          showYearDropdown
                          showMonthDropdown
                          minDate={trainCourse.begin_date}
                          customInput={<CustomInput />}
                        ></DatePicker>
                        <label>
                          ถึงวันที่
                          {trainCourse.end_date === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-3">
                      <div class="form-group">
                        <input
                          style={{ textAlign: "right", paddingRight: "15px" }}
                          type="text"
                          className="form-control"
                          required
                          id="text_position"
                          value={trainCourse.day_amount}
                          disabled="true"
                          onChange={(e) => {
                            setTrainCourse({
                              ...trainCourse,
                              day_amount: e.target.value,
                            });
                          }}
                        />
                        <label>
                          จำนวนวันทั้งหมด (วัน)
                          {/* {employee.emp_position === "" ? (
                                                        <span style={{ color: "red" }}> *</span>
                                                    ) : (
                                                        ""
                                                    )} */}
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* time_begin and time_end */}
                  <div className="row">
                    <div className="col-6 col-md-3 col-xl-2">
                      <div class="form-group">
                        <DatePicker
                          selected={trainCourse.begin_time}
                          wrapperClassName="w-100"
                          id="text_national_exp_date1"
                          disabled={disable}
                          locale="th"
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={5}
                          timeCaption="Time"
                          dateFormat="HH:mm น."
                          customInput={<CustomInput />}
                          onChange={async (date) => {
                            setTrainCourse({
                              ...trainCourse,
                              begin_time: date,
                            });
                          }}
                        />
                        <label>
                          เวลาเริ่ม
                          {trainCourse.begin_time === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 col-xl-2">
                      <div class="form-group">
                        <DatePicker
                          selected={trainCourse.end_time}
                          wrapperClassName="w-100"
                          id="text_national_exp_date2"
                          locale="th"
                          showTimeSelect
                          showTimeSelectOnly
                          disabled={disable}
                          timeIntervals={5}
                          timeCaption="Time"
                          dateFormat="HH:mm น."
                          customInput={<CustomInput />}
                          // minTime={trainCourse.begin_time instanceof Date ? trainCourse.begin_time : undefined}
                          // maxTime={trainCourse.begin_time instanceof Date ? new Date(trainCourse.begin_time.getTime() + (22 * 60 * 60 * 1000)) : undefined}
                          required
                          onChange={async (date) => {
                            setTrainCourse({
                              ...trainCourse,
                              end_time: date,
                            });
                          }}
                        />
                        <label>
                          ถึง
                          {trainCourse.end_time === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* learn type */}
                  <div className="row">
                    <div className="col-5 col-md-5 col-xl-4">
                      <div class="form-group">
                        <select
                          className="form-control custom-select select2"
                          style={{ fontSize: "20px" }}
                          type="text"
                          required
                          id="text_nation_id"
                          value={trainCourse.learn_type_id}
                          disabled={disable}
                          onChange={(e) => {
                            const _find = GetLearnType.find(
                              (where) => where.id === e.target.value
                            );
                            setTrainCourse({
                              ...trainCourse,
                              learn_type_id: e.target.value,
                              learn_type: _find?.name ?? null,
                            });
                          }}
                        >
                          <option value="" disabled selected>
                            ----เลือก----
                          </option>
                          {GetLearnType.map((el) => {
                            return <option value={el.id}>{el.name}</option>;
                          })}
                        </select>
                        <label>
                          รูปแบบการอบรม
                          {trainCourse.learn_type_id === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* location */}
                  <div className="row">
                    <div className="col-12 col-md-12 col-xl-5">
                      <div className="form-group">
                        {" "}
                        <textarea
                          className="form-control"
                          rows={2}
                          style={{ height: "150px", fontSize: "25px" }}
                          // placeholder="enter"
                          disabled={disable}
                          value={trainCourse.location}
                          onChange={(e) =>
                            setTrainCourse({
                              ...trainCourse,
                              location: e.target.value,
                            })
                          }
                          defaultValue={""}
                          required
                        />
                        <label>สถานที่</label>
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-xl-4">
                      <div className="form-group">
                        {" "}
                        <textarea
                          className="form-control"
                          rows={2}
                          style={{ height: "150px", fontSize: "25px" }}
                          // placeholder="enter"
                          disabled={disable}
                          value={trainCourse.remark}
                          onChange={(e) =>
                            setTrainCourse({
                              ...trainCourse,
                              remark: e.target.value,
                            })
                          }
                          defaultValue={""}
                          required
                        />
                        <label>Remark</label>
                      </div>
                    </div>

                    {/* <div className="col-12 col-md-12 col-xl-8"></div>
                                        <div className="col-12 col-md-12 col-xl-1"></div> */}
                  </div>
                  <hr />
                  <div className="">
                    <h3 className="mb-2  mt-5-head">ข้อมูลติดต่อ</h3>
                  </div>
                  {/* phone and email */}
                  <div className="row">
                    <div className="col-6 col-md-4 col-xl-3">
                      <div class="form-group">
                        <NumberFormat
                          className="form-control"
                          required
                          thousandSeparator={false}
                          format={"##-###-####"}
                          id="text_address_tel"
                          value={trainCourse.course_tel}
                          disabled={disable}
                          onChange={(e) => {
                            setTrainCourse({
                              ...trainCourse,
                              course_tel: e.target.value,
                            });
                          }}
                        />
                        <label>
                          เบอร์โทรศัพท์
                          {/* {employee.emp_address_tel === "" ? (
                                                        <span style={{ color: "red" }}> *</span>
                                                    ) : (
                                                        ""
                                                    )} */}
                        </label>
                      </div>
                    </div>
                    <div className="col-6 col-md-12 col-xl-5">
                      <div class="form-group">
                        <input
                          type="email"
                          className="form-control"
                          required
                          id="text_address_email"
                          value={trainCourse.email}
                          disabled={disable}
                          onChange={(e) => {
                            setTrainCourse({
                              ...trainCourse,
                              email: e.target.value,
                            });
                          }}
                        />
                        <label>
                          E-mail
                          {/* {employee.emp_email === "" ? (
                                                        <span style={{ color: "red" }}> *</span>
                                                    ) : (
                                                        ""
                                                    )} */}
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* facebook and website */}
                  <div className="row">
                    <div className="col-6 col-md-12 col-xl-5">
                      <div class="form-group">
                        <input
                          type="email"
                          className="form-control"
                          required
                          id="text_address_facebook"
                          value={trainCourse.facebook}
                          disabled={disable}
                          onChange={(e) => {
                            setTrainCourse({
                              ...trainCourse,
                              facebook: e.target.value,
                            });
                          }}
                        />
                        <label>
                          Facebook
                          {/* {employee.emp_email === "" ? (
                                                        <span style={{ color: "red" }}> *</span>
                                                    ) : (
                                                        ""
                                                    )} */}
                        </label>
                      </div>
                    </div>
                    <div className="col-6 col-md-12 col-xl-5">
                      <div class="form-group">
                        <input
                          type="webSite"
                          className="form-control"
                          required
                          id="text_address_webSite"
                          value={trainCourse.website}
                          disabled={disable}
                          onChange={(e) => {
                            setTrainCourse({
                              ...trainCourse,
                              website: e.target.value,
                            });
                          }}
                        />
                        <label>
                          Website
                          {/* {employee.emp_email === "" ? (
                                                        <span style={{ color: "red" }}> *</span>
                                                    ) : (
                                                        ""
                                                    )} */}
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* {(mode.mode !== "read" && mode.mode !== "edit") ?
                                        <div className="row">
                                            <div className="col-1 col-md-5 col-xl-5"></div>
                                            <div className="col-10 col-md-2 col-xl-2">
                                                <div className="form-group">
                                                    <button
                                                        type="button"
                                                        class="btn btn-block btn-info"
                                                        htmlFor="custom-tabs-four-page-2-tab"
                                                    // onClick={checkRequireTab1}
                                                    >
                                                        ถัดไป
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-1 col-md-5 col-xl-5"></div>
                                        </div>
                                        : (" ")} */}
                </div>
                {/* ************************** End tab 1 ********************************** */}

                <div
                  className="tab-pane fade"
                  id="custom-tabs-four-page-2"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-page-2-tab"
                >
                  <div>
                    <h3 className="mb-2 mt-5-head mb-2">รายละเอียดผู้อบรม</h3>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-xl-4">
                      <div class="form-group">
                        <button
                          className="btn btn-block btn-info"
                          type="button"
                          data-toggle="modal"
                          data-target="#modal-trainee-in"
                          disabled={disable}
                          onClick={() => {
                            "";
                          }}
                        >
                          เพิ่มผู้เข้าอบรมที่มีในระบบ
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3 className="mb-2 mt-5-head mb-2">
                      ผู้เข้าอบรมที่มีอยู่ในระบบ
                    </h3>
                  </div>
                  <section className="content">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-tools"></div>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive ">
                          <MDBDataTable
                            sortable={false}
                            className="table table-head-fixed"
                            striped
                            bordered
                            hover
                            fixedHeader
                            data={dataTraineeIn}
                          />
                        </div>
                      </div>
                      <div className="card-footer"></div>
                    </div>
                  </section>
                  <div className="modal fade" id="modal-trainee-in">
                    <div className="modal-dialog modal-xl">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">
                            เพิ่มข้อมูลพนักงานที่มีอยู่ในระบบ
                          </h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            onClick={() => {
                              // setModal({ check: false, index: -1 });
                            }}
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="container-fluid">
                            <div className="row mb-2">
                              <div className="col-md-2">
                                <div className="form-group ">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="text_empid"
                                    required="false"
                                    value={filter_employee.emp_no}
                                    onChange={(e) => {
                                      setfilter_employee({
                                        ...filter_employee,
                                        emp_no: e.target.value,
                                      });
                                    }}
                                  />
                                  <label htmlFor="">รด/รหัส</label>{" "}
                                </div>
                              </div>

                              <div className="col-md-2">
                                <div className="form-group ">
                                  <input
                                    type="text"
                                    className="form-control"
                                    required="false"
                                    id="text_empname"
                                    value={filter_employee.emp_name_th}
                                    onChange={(e) => {
                                      setfilter_employee({
                                        ...filter_employee,
                                        emp_name_th: e.target.value,
                                      });
                                    }}
                                  />
                                  <label htmlFor="">ชื่อพนักงาน</label>{" "}
                                </div>
                              </div>

                              <div
                                className="col-md-2 col-sm-12 col-lg-2"
                                style={{ "z-index": "99" }}
                              >
                                <div className="form-group ">
                                  <select
                                    className="form-control custom-select select2"
                                    type="text"
                                    required
                                    id="drop_emptype"
                                    value={filter_employee.department_id}
                                    onChange={(e) => {
                                      setfilter_employee({
                                        ...filter_employee,
                                        department_id: e.target.value,
                                      });
                                    }}
                                  >
                                    <option value="" disabled selected></option>
                                    {getDepartment.map((el) => {
                                      return (
                                        <option value={el.id}>
                                          {el.dep_name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <label htmlFor="">แผนก</label>
                                </div>
                              </div>
                              <div className="col-6 col-md-4 col-xl-2">
                                <div className="form-group ">
                                  <button
                                    type="button"
                                    id="btn_search"
                                    className="btn btn-block btn-info  "
                                    onClick={() => {
                                      getEmployeeModal();
                                      setMDBT(false);
                                    }}
                                  >
                                    Search
                                  </button>
                                </div>
                              </div>

                              <div className="col-6 col-md-4 col-xl-2">
                                <div className="form-group ">
                                  <button
                                    type="button"
                                    id="btn_clear"
                                    className="btn btn-block btn-info"
                                    onClick={() => {
                                      clearFilter();
                                      setMDBT(false);
                                    }}
                                  >
                                    Clear
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12 col-md-12 col-xl-12">
                              <MDBDataTable
                                sortable={false}
                                className="table table-head-fixed"
                                striped
                                bordered
                                hover
                                fixedHeader
                                disableRetreatAfterSorting={MDBT}
                                data={dataEmployee}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-10 col-md-10 col-xl-10"></div>
                            <div className="col-2 col-md-2 col-xl-2">
                              <div className="form-group">
                                <button
                                  type="button"
                                  className="btn btn-block btn-outline-dark"
                                  data-dismiss="modal"
                                  onClick={() => {
                                    "";
                                  }}
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 col-xl-4">
                      <div class="form-group">
                        <button
                          className="btn btn-block btn-info"
                          type="button"
                          data-toggle="modal"
                          data-target="#modal-trainee-out"
                          disabled={disable}
                          onClick={() => {
                            setEmployeeOut(resetEmployeeOut);
                            setCloseModal({ checkData: true });
                            closeModal.checkData = true;
                            settest({ ...test, test: "" });
                          }}
                        >
                          เพิ่มผู้เข้าอบรมที่ไม่มีในระบบ
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3 className="mb-2 mt-5-head mb-2">
                      ผู้เข้าอบรมที่ไม่มีอยู่ในระบบ
                    </h3>
                  </div>
                  <section className="content">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-tools"></div>
                      </div>

                      <div className="card-body">
                        <div className="table-responsive ">
                          <MDBDataTable
                            sortable={false}
                            className="table table-head-fixed"
                            striped
                            bordered
                            hover
                            fixedHeader
                            data={dataTraineeOut}
                          />
                        </div>
                      </div>
                      <div className="card-footer"></div>
                    </div>
                  </section>
                  <div className="modal fade" id="modal-trainee-out">
                    <div className="modal-dialog modal-xl">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">
                            เพิ่มข้อมูลพนักงานที่ไม่มีอยู่ในระบบ
                          </h4>
                          <button
                            type="button"
                            className="close"
                            ref={CloseEmpOutModal}
                            data-dismiss="modal"
                            onClick={() => {
                              setChange({ change: true, index: -1 });
                            }}
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-12 ">
                              <div className="none-nolmalinput"></div>
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-12">
                              <div className="row">
                                {/* <div className="col-12 col-md-6 col-xl-3">
                                                                    <div className="form-group ">
                                                                        <select
                                                                            type="text"
                                                                            className="form-control"
                                                                            required
                                                                            value={EmployeeOut.emp_out_name_title}
                                                                            onChange={(e) => {
                                                                                // const _find = getNameTitle.find(where => where.id === e.target.value);
                                                                                setEmployeeOut({
                                                                                    ...EmployeeOut,
                                                                                    emp_out_name_title: e.target.value,
                                                                                });
                                                                            }}
                                                                        >
                                                                            <option value="">เลือกคำนำหน้าชื่อ</option>
                                                                            {getNameTitle.map((el) => (
                                                                                <option value={el.id}>
                                                                                    {el.title_name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                        <label htmlFor="">คำนำหน้าชื่อ</label>{" "}
                                                                    </div>
                                                                </div> */}
                                <div className="col-12 col-md-6 col-xl-3">
                                  <div className="form-group ">
                                    <input
                                      type="text"
                                      className="form-control"
                                      required
                                      value={EmployeeOut.emp_name}
                                      onChange={(e) => {
                                        setEmployeeOut({
                                          ...EmployeeOut,
                                          emp_name: e.target.value,
                                        });
                                      }}
                                    />
                                    <label htmlFor="">
                                      ชื่อ-นามสกุล
                                      {EmployeeOut.emp_name === "" ? (
                                        <span style={{ color: "red" }}> *</span>
                                      ) : (
                                        ""
                                      )}
                                    </label>{" "}
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 col-xl-3">
                                  <div className="form-group ">
                                    <input
                                      type="text"
                                      className="form-control"
                                      required
                                      value={EmployeeOut.emp_nickname}
                                      onChange={(e) => {
                                        setEmployeeOut({
                                          ...EmployeeOut,
                                          emp_nickname: e.target.value,
                                        });
                                      }}
                                    />
                                    <label htmlFor="">
                                      ชื่อเล่น
                                      {EmployeeOut.emp_nickname === "" ? (
                                        <span style={{ color: "red" }}> *</span>
                                      ) : (
                                        ""
                                      )}
                                    </label>{" "}
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 col-xl-3">
                                  <div className="form-group ">
                                    <input
                                      type="text"
                                      className="form-control"
                                      required
                                      value={EmployeeOut.emp_no}
                                      onChange={(e) => {
                                        setEmployeeOut({
                                          ...EmployeeOut,
                                          emp_no: e.target.value,
                                        });
                                      }}
                                    />
                                    <label htmlFor="">
                                      รหัสพนักงาน
                                      {EmployeeOut.emp_no === "" ? (
                                        <span style={{ color: "red" }}> *</span>
                                      ) : (
                                        ""
                                      )}
                                    </label>{" "}
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 col-xl-3">
                                  <div className="form-group ">
                                    <input
                                      type="text"
                                      className="form-control"
                                      required
                                      value={EmployeeOut.emp_department}
                                      onChange={(e) => {
                                        setEmployeeOut({
                                          ...EmployeeOut,
                                          emp_department: e.target.value,
                                        });
                                      }}
                                    />
                                    <label htmlFor="">
                                      แผนก
                                      {EmployeeOut.emp_department === "" ? (
                                        <span style={{ color: "red" }}> *</span>
                                      ) : (
                                        ""
                                      )}
                                    </label>{" "}
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 col-xl-3">
                                  <div className="form-group ">
                                    <input
                                      type="text"
                                      className="form-control"
                                      required
                                      value={EmployeeOut.emp_position}
                                      onChange={(e) => {
                                        setEmployeeOut({
                                          ...EmployeeOut,
                                          emp_position: e.target.value,
                                        });
                                      }}
                                    />
                                    <label htmlFor="">
                                      ตำแหน่ง
                                      {EmployeeOut.emp_position === "" ? (
                                        <span style={{ color: "red" }}> *</span>
                                      ) : (
                                        ""
                                      )}
                                    </label>{" "}
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 col-xl-3">
                                  <div className="form-group ">
                                    <input
                                      type="email"
                                      className="form-control"
                                      required
                                      value={EmployeeOut.emp_email}
                                      onChange={(e) => {
                                        setEmployeeOut({
                                          ...EmployeeOut,
                                          emp_email: e.target.value,
                                        });
                                      }}
                                    />
                                    <label htmlFor="">
                                      E-mail
                                      {EmployeeOut.emp_email === "" ? (
                                        <span style={{ color: "red" }}> *</span>
                                      ) : (
                                        ""
                                      )}
                                    </label>{" "}
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 col-xl-3">
                                  <div className="form-group ">
                                    <NumberFormat
                                      format={"###-####-####"}
                                      type="text"
                                      className="form-control"
                                      required
                                      value={EmployeeOut.emp_tel}
                                      onChange={(e) => {
                                        setEmployeeOut({
                                          ...EmployeeOut,
                                          emp_tel: e.target.value,
                                        });
                                      }}
                                    />
                                    <label htmlFor="">
                                      เบอร์โทร
                                      {EmployeeOut.emp_tel === "" ? (
                                        <span style={{ color: "red" }}> *</span>
                                      ) : (
                                        ""
                                      )}
                                    </label>{" "}
                                  </div>
                                </div>

                                <div className="col-6 col-md-4 col-xl-3">
                                  <div className="form-group ">
                                    <button
                                      type="button"
                                      class="btn btn-block btn-info "
                                      // data-dismiss="modal"
                                      disabled={disable}
                                      onClick={() => {
                                        const checkAllField = inputAllFiled();
                                        setCloseModal({ checkAllField });
                                        closeModal.checkData = checkAllField;
                                        if (closeModal.checkData === true) {
                                          return;
                                        } else if (
                                          !checkAllField &&
                                          Change.change
                                        ) {
                                          trainCourse.Employee_Out.push(
                                            EmployeeOut
                                          );
                                          setEmployeeOut(resetEmployeeOut);
                                        } else if (
                                          !checkAllField &&
                                          !Change.change
                                        ) {
                                          const emp_out =
                                            trainCourse.Employee_Out;
                                          emp_out[Change.index] = EmployeeOut;
                                          setTrainCourse({
                                            ...trainCourse,
                                            Employee_Out: emp_out,
                                          });
                                        }
                                        setChange({ change: true, index: -1 });
                                        setCloseModal({ checkData: false });
                                        closeModal.checkData = false;
                                        settest({ ...test, test: "" });
                                        if (!checkAllField) {
                                          clickCloseEmpOutModal();
                                        }
                                      }}
                                    >
                                      บันทึก
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*************************************************** Tap 3 ****************************************************/}
                <div
                  className="tab-pane fade"
                  id="custom-tabs-four-page-3"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-page-3-tab"
                >
                  <div>
                    <h3 className="mb-2 mt-5-head mb-2"> ค่าใช้จ่ายคอร์ส</h3>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-xl-4">
                      <div class="form-group">
                        <NumberFormat
                          type="text"
                          className="form-control"
                          // thousandsGroupStyle='thousand'
                          thousandSeparator=","
                          decimalScale={2}
                          allowNegative={false}
                          required
                          id="text_money_want"
                          value={trainCourse.register_cost}
                          disabled={disable}
                          onChange={(e) => {
                            setTrainCourse({
                              ...trainCourse,
                              register_cost: e.target.value,
                            });
                          }}
                        />
                        <label>
                          ค่าลงทะเบียน
                          {/* {employee.emp_money_want === "" ? (
                                                        <span style={{ color: "red" }}> *</span>
                                                    ) : (
                                                        ""
                                                    )} */}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                      <div class="form-group">
                        <NumberFormat
                          type="text"
                          className="form-control"
                          // thousandsGroupStyle='thousand'
                          thousandSeparator=","
                          decimalScale={2}
                          allowNegative={false}
                          required
                          id="text_money_want"
                          value={trainCourse.fee_cost}
                          disabled={disable}
                          onChange={(e) => {
                            setTrainCourse({
                              ...trainCourse,
                              fee_cost: e.target.value,
                            });
                          }}
                        />
                        <label>
                          ค่าธรรมเนียม
                          {/* {employee.emp_money_want === "" ? (
                                                        <span style={{ color: "red" }}> *</span>
                                                    ) : (
                                                        ""
                                                    )} */}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-xl-3">
                      <div class="form-group">
                        <NumberFormat
                          style={{ textAlign: "right", paddingRight: "15px" }}
                          type="text"
                          className="form-control"
                          // thousandsGroupStyle='thousand'
                          thousandSeparator=","
                          decimalScale={2}
                          allowNegative={false}
                          required
                          disabled={true}
                          id="text_money_want"
                          value={trainCourse.total_course}
                          onChange={(e) => {
                            setTrainCourse({
                              ...trainCourse,
                              total_course: e.target.value,
                            });
                          }}
                        />
                        <label>
                          ยอดรวมค่าคอร์ส (บาท)
                          {/* {employee.emp_money_want === "" ? (
                                                        <span style={{ color: "red" }}> *</span>
                                                    ) : (
                                                        ""
                                                    )} */}
                        </label>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <h3 className="mb-2 mt-5-head mb-2"> ค่าใช้จ่ายอื่น ๆ </h3>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-xl-4">
                      <div class="form-group">
                        <NumberFormat
                          type="text"
                          className="form-control"
                          // thousandsGroupStyle='thousand'
                          thousandSeparator=","
                          decimalScale={2}
                          allowNegative={false}
                          required
                          id="text_money_want"
                          value={trainCourse.travel_cost}
                          disabled={disable}
                          onChange={(e) => {
                            setTrainCourse({
                              ...trainCourse,
                              travel_cost: e.target.value,
                            });
                          }}
                        />
                        <label>
                          ค่าเดินทาง
                          {/* {employee.emp_money_want === "" ? (
                                                        <span style={{ color: "red" }}> *</span>
                                                    ) : (
                                                        ""
                                                    )} */}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                      <div class="form-group">
                        <NumberFormat
                          type="text"
                          className="form-control"
                          // thousandsGroupStyle='thousand'
                          thousandSeparator=","
                          decimalScale={2}
                          allowNegative={false}
                          required
                          id="text_money_want"
                          value={trainCourse.hotel_cost}
                          disabled={disable}
                          onChange={(e) => {
                            setTrainCourse({
                              ...trainCourse,
                              hotel_cost: e.target.value,
                            });
                          }}
                        />
                        <label>
                          ค่าที่พัก
                          {/* {employee.emp_money_want === "" ? (
                                                        <span style={{ color: "red" }}> *</span>
                                                    ) : (
                                                        ""
                                                    )} */}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-10 col-md-2 col-xl-2">
                      <div className="form-group">
                        <button
                          type="button"
                          class="btn btn-block btn-info"
                          htmlFor="custom-tabs-four-page-2-tab"
                          disabled={disable}
                          onClick={addElementCost}
                        >
                          ค่าใช้จ่ายเพิ่มเติม
                        </button>
                      </div>
                    </div>
                  </div>
                  {addEleCost.map((el, index) => {
                    return (
                      <>
                        <div className="row" key={index}>
                          <div className="col-12 col-md-6 col-xl-5">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                id="text_position"
                                value={el.costOtherName}
                                disabled={disable}
                                onChange={(e) => {
                                  const updatedAddEleCost = [...addEleCost];
                                  updatedAddEleCost[index].costOtherName =
                                    e.target.value;
                                  setAddEleCost(updatedAddEleCost);
                                  // setAddEleCost({
                                  //     ...addEleCost,
                                  //     costOtherName: e.target.value,
                                  // });
                                }}
                              />
                              <label>
                                ค่าใช้จ่ายอื่น ๆ
                                {/* {employee.emp_position === "" ? (
                                                        <span style={{ color: "red" }}> *</span>
                                                    ) : (
                                                        ""
                                                    )} */}
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div class="form-group">
                              <NumberFormat
                                type="text"
                                className="form-control"
                                // thousandsGroupStyle='thousand'
                                thousandSeparator=","
                                decimalScale={2}
                                allowNegative={false}
                                required
                                id="text_money_want"
                                value={el.costOtherPrice}
                                disabled={disable}
                                onChange={(e) => {
                                  const updatedAddEleCost = [...addEleCost];
                                  updatedAddEleCost[index].costOtherPrice =
                                    e.target.value;
                                  setAddEleCost(updatedAddEleCost);
                                }}
                              />
                              <label>
                                จำนวนเงิน (บาท)
                                {/* {employee.emp_money_want === "" ? (
                <span style={{ color: "red" }}> *</span>
            ) : (
                ""
            )} */}
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-1 mt-2">
                            <button
                              style={{ marginTop: "2px" }}
                              disabled={disable}
                              type="button"
                              onClick={() => {
                                deleteElementCost(index);
                              }}
                              class="btn btn-block btn-danger "
                            >
                              ลบ
                            </button>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  <div className="row">
                    <div className="col-12 col-md-6 col-xl-3">
                      <div class="form-group">
                        <NumberFormat
                          style={{ textAlign: "right", paddingRight: "15px" }}
                          type="text"
                          className="form-control"
                          // thousandsGroupStyle='thousand'
                          thousandSeparator=","
                          decimalScale={2}
                          allowNegative={false}
                          required
                          disabled={true}
                          id="text_money_want"
                          value={trainCourse.total_other_cost}
                          onChange={(e) => {
                            setTrainCourse({
                              ...trainCourse,
                              total_other_cost: e.target.value,
                            });
                          }}
                        />
                        <label>
                          ยอดรวมค่าใช้จ่ายอื่น ๆ (บาท)
                          {/* {employee.emp_money_want === "" ? (
                                                        <span style={{ color: "red" }}> *</span>
                                                    ) : (
                                                        ""
                                                    )} */}
                        </label>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-12 col-md-6 col-xl-3">
                      <div class="form-group">
                        <NumberFormat
                          style={{ textAlign: "right", paddingRight: "15px" }}
                          type="text"
                          className="form-control"
                          // thousandsGroupStyle='thousand'
                          thousandSeparator=","
                          decimalScale={2}
                          allowNegative={false}
                          required
                          disabled={true}
                          id="text_money_want"
                          value={trainCourse.grand_total}
                          onChange={(e) => {
                            setTrainCourse({
                              ...trainCourse,
                              grand_total: e.target.value,
                            });
                          }}
                        />
                        <label>
                          สรุปค่าใช้จ่ายทั้งหมด (บาท)
                          {/* {employee.emp_money_want === "" ? (
                                                        <span style={{ color: "red" }}> *</span>
                                                    ) : (
                                                        ""
                                                    )} */}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {/*************************************************** End ********************************************************/}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Employee_train;
