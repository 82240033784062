import React, { useState, useEffect } from "react";

import "../../../../assets/css/ProductService.css";
import axios from "axios";
import Configs from "../../../../config";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import logo_talent_point from "../Incentive_User_icon_img/logo talent point_jpg 1.png";
import {
  getOem,
  getToken,
  getUser,
  getAcademy,
} from "../../../../Utils/Common";
import { Cart } from "../Incentive_User_icon_img/All_Icon";
import NumberFormat from "react-number-format";
import moment from "moment";

function Point_Cart_Line({ fetch }) {
  let [data, setData] = useState({
    current_point: "",
    last_date_update: "",
    last_time_update: "",
    cart_count: 0,
  });
  let [Reload, setReload] = useState(false);
  async function GetCountCart() {
    let tempData = {
      sc_emp_id: getUser().fup,
      sc_company_id: getUser().com,
      sc_oem_id: getOem(),
    };

    await axios({
      method: "post",
      url: Configs.API_URL_incentive + "/api/cartManagement/GetCountCart",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        // console.log("GetCountCart", response.data);
        setData({ ...data, cart_count: Number(response.data.cart_count) });
        data.cart_count = Number(response.data.cart_count);
        // console.log(" GetCountCart data", data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetEmpPoint() {
    let tempData = {
      sc_emp_id: getUser().fup,
      sc_company_id: getUser().com,
      sc_oem_id: getOem(),
    };

    await axios({
      method: "post",
      url: Configs.API_URL_incentive + "/api/cartManagement/GetEmpPoint",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        // console.log("GetEmpPoint", response.data);
        setData({
          ...data,
          current_point:
            response.data.current_point === null
              ? 0
              : response.data.current_point,
          last_date_update: moment(new Date(response.data.last_update)).format(
            "DD/MM/YYYY"
          ),
          last_time_update: moment(new Date(response.data.last_update)).format(
            "HH:MM"
          ),
        });
        data.current_point =
          response.data.current_point === null
            ? 0
            : response.data.current_point;
        data.last_date_update = moment(
          new Date(response.data.last_update)
        ).format("DD/MM/YYYY");
        data.last_time_update = moment(
          new Date(response.data.last_update)
        ).format("HH:MM");
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  window.addEventListener("pageshow", (event) => {
    if (event.persisted) {
      setReload(true);
    }
  });

  if (Reload) {
    GetCountCart();
    GetEmpPoint();
    setReload(false);
  }
  useEffect(() => {
    // console.log("fetData", fetch);
    ///* setfatch({...fatch,fatch:params.fatch}); */
    GetCountCart();
    GetEmpPoint();

    //console.log("fetData", params);
  }, [fetch]);

  useEffect(() => {
    setData({
      ...data,
      cart_count: Number(data.cart_count),
      current_point: data.current_point,
      last_date_update: data.last_date_update,
      last_time_update: data.last_time_update,
    });
    data.cart_count = Number(data.cart_count);
    data.current_point = data.current_point;
    data.last_date_update = data.last_date_update;
    data.last_time_update = data.last_time_update;
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "56px",
          backgroundImage:
            "linear-gradient(180deg, #0094FF 0%, rgba(38, 177, 255, 0.511719) 100%)",
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignContent: "center",
          }}
        >
          <div style={{ minWidth: "fit-content", maxWidth: "fit-content" }}>
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                margin: "0px 8px",
              }}
            >
              <img src={logo_talent_point} style={{ width: "40px" }} />
            </div>
          </div>

          <div className="col" style={{ marginTop: "15px" }}>
            <div
              style={{
                display: "flex",
              }}
            >
              <label
                className="Point_Cart_Line_font_1"
                style={{
                  margin: "0px 10px",
                }}
              >
                คุณมีคะแนนสะสม
              </label>

              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "100px",
                    backgroundColor: "white",
                  }}
                >
                  <label
                    className="Point_Cart_Line_font_1"
                    style={{
                      color: "#003583",
                      margin: "0px",
                    }}
                  >
                    <NumberFormat
                      thousandSeparator={true}
                      value={data.current_point}
                      displayType="text"
                    />
                  </label>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <label
                    className="Point_Cart_Line_font_2"
                    style={{
                      margin: "0px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    อัพเดตล่าสุด {data.last_date_update} เวลา{" "}
                    {data.last_time_update} น.
                  </label>
                </div>
              </div>

              <label
                className="Point_Cart_Line_font_1"
                style={{
                  margin: "0px 10px",
                }}
              >
                คะแนน
              </label>
            </div>
          </div>

          <div
            style={{
              minWidth: "56px",
              maxWidth: "56px",
              margin: "0px 5px 0px 0px",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <a
                href={
                  "/Human_Resource/incentive_user/product_service/cart_product"
                }
                style={{ color: "white", position: "relative" }}
              >
                <Cart width="30px" height="30px" fill="white" />
                {data.cart_count > 0 ? (
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#FF0000",
                      borderRadius: "100px",
                      position: "absolute",
                      top: "-5px",
                      right: "-10px",
                    }}
                  >
                    <label
                      style={{
                        fontSize: ".8rem",
                        lineHeight: "normal",
                        cursor: "pointer",
                        margin: "0px",
                      }}
                    >
                      {data.cart_count}
                    </label>
                  </div>
                ) : (
                  <></>
                )}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Point_Cart_Line;
