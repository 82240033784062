import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import "../../assets/css/Supp.css";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import "../../assets/css/table.css";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import { useParams } from "react-router";
import Sidebar from "../../component/Sidebar";
import { getOem, getToken, getUser,getAcademy } from "../../Utils/Common";
import Configs from "../../config";
import FormData from "form-data";
import axios from "axios";
import NumberFormat from "react-number-format";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";
import DatePicker from "react-datepicker";
import moment from "moment";
import GoogleMapReact from "google-map-react";
import { BrowserRouter, Link, Route } from "react-router-dom";
function LogisticInOut_add(mode) {
  const [company, setCompany] = useState([]);
  const [allOEM, setallOEM] = useState([]);
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);

  const [car_Detail, setcar_Detail] = useState({
    plate_number:"",
    car_type:"",
  });
  const [logistic, setlogistic] = useState({
    row:"",
    date:"",
    doc_order:"",
    customer: "",
    target: "",
    latitude: "",
    longitude: "",
  });
  const [cancleBill,setcancleBill] = useState({
    id:"",
    index:"",
    delivery_sort:"",
    date:"",
    plate_number: "",
    bill_no:"",
    mode:"",
    remark:"",
  });
  const [filter_bill, setfilter_bill] = useState({
    oem_id: getOem(),
   
  });
  const [test, settest] = useState({
    test: "",
   
  });
  const [dateSelect, setdateSelect] = useState({
    date:new Date(),
  });
  const [bill, setbill] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);

  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //console.log(response.data);
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const GetAllOEM = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/findOEMByCompanyId?id="+ getUser().com,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //console.log(response.data);
        setallOEM(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  
  useEffect(() => {
    GetCompany();
    GetAllOEM();
    GetLogisticData();
    GetBill();
  /* let tempdata = [];
    let temp ={
      no:"IV0001",
      oem: "STS",
      date:"",
      customer:"",
      employee:"",
      status:"",
    }
    let temp2 ={
      no:"IV0002",
      oem: "STP",
      date:"",
      customer:"",
      employee:"",
      status:"",
    }
    let temp3 ={
      no:"IV0003",
      oem: "STC",
      date:"",
      customer:"",
      employee:"",
      status:"",
    }

    tempdata.push(temp);
    tempdata.push(temp2);
    tempdata.push(temp3);
    setbill(tempdata); */
   
  }, []);


  const GetBill = async () => {
     
    
      axios({
        method: "get",
        url: Configs.API_URL + "/logistic/GetBill?id="+ filter_bill.oem_id+'&logis_id='+id,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {

          //setbill(response.data);
     /*     console.log(logistic);
         console.log(dynamic_table); */
    /*       var bill_p = bill; */
          
        //var tempData = dynamic_table.filter((e)=>{ return e.row === logistic.row && moment(e.date).format('DD/MM/yyyy') === moment(logistic.date).format('DD/MM/yyyy')})

       
/*         console.log("dynamic_table",dynamic_table); */
        console.log("DataReal",DataReal);
        
    
          for(let i of dynamic_table){
   
            for(let i2 of i.table){
           
              for(let k of response.data){
                var cancle_check = DataReal.filter((e1)=>{return (e1.remark !== null && e1.remark !== "" )&& e1.bill_id === k.id && e1.delivery_sort === i.row && moment(e1.logistic_date).format("DD/MM/yyyy") === moment(i.date).format("DD/MM/yyyy") });
                        
                            //เดวไปเพิ่ม id ของ driver ติดมาใน e.table
                            /* if(cancle_check.length > 0){
                              if(cancle_check[0].remark !== null && cancle_check[0].remark !== ""){
                                status = "ยกเลิก";
                                color = "#FF0000";
                              }
                            } */
                           /*  console.log("cancle_check",cancle_check) */
            
                            if(cancle_check.length > 0){
                           
                            
                            }
                if(k.id === i2.id && cancle_check.length === 0){
                  console.log(i.row,i.date ,i2.bill_no)
                  k.isPush = true;
                  k.row_ = i.row;
                  k.date_ = i.date;
                  
                  //console.log(k)
                }
              }
            }
              
           
    
            
          
         }
         setbill(response.data);
     
          /* if(tempData[0].table.length > 0){
            for(let k of tempData[0].table){
              for(let i of bill_p){
                
                if(i.id === k.id  && k.remark === null ){
                  i.isPush = true;
            
                  //  i.row_ = k.row;
                    //i.date_ = k.date;
              
          
                }
              }
            }
          } */
          //setbill(bill_p);
          settest({...test,test:""});
          test.test = "";
          //console.log(bill_p)
      
        })
        .catch(function (error) {
          console.log(error);
        });
    
    
  }

const[DataReal,setDataReal] = useState([]);
  const GetLogisticData = async () => {
     
    
    axios({
      method: "get",
      url: Configs.API_URL + "/logistic/getLogisticInOut?id="+ id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
          setcar_Detail({...car_Detail,plate_number:response.data.car_detail.plate_number,car_type:response.data.car_detail.car_type});
          var tempSort = response.data.dataSort;
          for(let sort of tempSort){
            sort.x = moment(sort.date).format('yyyy') + moment(sort.date).format('MM') + moment(sort.date).format('DD') + sort.row;
          }
     
         
          //console.log(bill_p)
          tempSort.sort((a,b)=> a.x - b.x);
          for(let item of tempSort){
            item.table.sort((a,b)=> moment(a.delivery_bill) - moment(b.delivery_bill));
          }
          setdynamic_table(tempSort);
          setDataReal(response.data.dataReal);
          axios({
            method: "get",
            url: Configs.API_URL + "/logistic/GetBill?id="+ getOem()+'&logis_id='+id,
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
          })
            .then(function (response2) {
              var sort = response2.data.sort((a,b)=>(b.bill_no).localeCompare(a.bill_no));
              setbill(sort);
              const bill_p = sort;
              setbill([]);
              for(let k of response.data.dataReal){
                for(let i of bill_p){
                  if(i.id === k.bill_id && k.remark === null){
                    i.isPush = true;
                    i.row_ = k.delivery_sort;
                    i.date_ = k.logistic_date;
                  }
                }
              }
              //console.log(response.data.dataReal)
              setbill(bill_p);
          
            })
            .catch(function (error) {
              console.log(error);
            });
       
    
      })
      .catch(function (error) {
        console.log(error);
      });
  
  
}
  
  
   function saveOption(params) {
     var data_Ligistic = dynamic_table.filter((e)=>{
       return e.table.length > 0;
     })
  
     var temp = {
       id:id,
       data_now:data_Ligistic,
       data_old:DataReal,
       company_id:getUser().com,
       oem_id:getOem(),
     }
    //console.log(temp)
    Swal.fire({
      title: "Saving",
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 1000,
      onOpen: () => {
        Swal.showLoading();
      },
    }).then((result) => {
     axios({
      method: "post",
      url: Configs.API_URL + "/logistic/SaveLogisticInOut",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data:temp,
    })
      .then(function (response) {

        if (response.data) {
          Swal.fire({
            icon: "success",
            title: "Save",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            window.location.href =
              "/Warehouse_Management/Logistic_IN_OUT/";
          });
        }
    
      })
      .catch(function (error) {
        console.log(error);
      });
    });
  } 

  

  function cancleOption(params) {
    Swal.fire({
      title: 'Do you want to cancel and leave the changes?',
      icon:'warning',
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:`No`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href =
        "/Warehouse_Management/Logistic_IN_OUT";
      }
    }
    );
    
  }

  const CustomInput = ({ value, onClick }) => ( 
      
    <
    div className = "input-group" >
    < input type = "text"
    className = "form-control float-left"
    onClick = { onClick }
    value = { value }
    /> <div className = "input-group-prepend" >
    <span className = "input-group-text" >
    <i className = "far fa-calendar-alt" />
    </span>
  
    { /*  <label>test</label> */ } 
    </div> 
    </div>
  
  
  )
 const [dynamic_table,setdynamic_table]= useState([]);

  function add_row(){
    const tempdata = dynamic_table;
    setdynamic_table([]);
    var check_date = tempdata.filter((e)=>{
      return moment(e.date).format('DD/MM/yyyy') === moment(dateSelect.date).format('DD/MM/yyyy');
    });
    if(check_date.length > 0){
      //console.log("in")
      let temp = {
        row:check_date.length+1,
        date:dateSelect.date,
        oem:"",
        oem_id:getOem(),
        company_id: getUser().com,
        table:[],
      }
      tempdata.push(temp);
    }else{
     // console.log("out")
      let temp = {
        row:1,
        date:dateSelect.date,
        oem:"",
        oem_id:getOem(),
        company_id: getUser().com,
        table:[],
      }
      tempdata.push(temp);
    }
    const sorter = (a, b) => {
      if(moment(a.date).format('DD/MM/yyyy') !== moment(b.date).format('DD/MM/yyyy')){
         return moment(a.date) -  moment(b.date);
      }else{
        /*  return months.indexOf(a.month) - months.indexOf(b.month); */
         return tempdata.indexOf(a.delivery_sort) - tempdata.indexOf(b.delivery_sort);
      };
   };
    //console.log(bill_p)
    tempdata.sort(sorter);
    setdynamic_table(tempdata);
    settest({...test,test:""});
    test.test = "";
  }


  function del_row(e){
   //console.log(e.target.id);
   Swal.fire({
    title: 'Do you want to Remove this?',
    icon:'warning',
    showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:`No`,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      const tempdata2 = dynamic_table;
      setdynamic_table([]);
    
      const bill_p = bill;
      //console.log(bill_p)
      const DataReal_ = DataReal;
      var row = tempdata2[e.target.id].row;
    
      
      setbill([]);
    
      for(let i of tempdata2[e.target.id].table){
        if(DataReal.filter((e)=>{return e.bill_id === i.id}).length > 0){
          var index_real = DataReal_.findIndex((find)=>find.bill_id === i.id);
          if(DataReal_[index_real].delivery_sort == row){
            DataReal_[index_real].delivery_sort = "";
          }
        
        
        }
      
        for(let i2 of bill_p){
          var check = DataReal.findIndex((e)=> e.bill_id === i.id && e.remark === null);

          if(check > -1){
            DataReal.splice(check, 1);
          }
          if(i.id === i2.id){
            
           // console.log('in');
            i2.row_= "";
            i2.isPush = false;
          }
        }
      }
      tempdata2.splice(e.target.id, 1);

      var num = 1;
      var check = [];
      //console.log(tempdata2);
      for(let item of tempdata2){
       // check.push(item);
       // console.log(check)
        var check2 = check.filter((e)=>{return moment(e.date).format('DD/MM/yyyy') ==  moment(item.date).format('DD/MM/yyyy')});
        if(check2.length === 0){
          num = 1;
          check.push(item);
         // console.log('hererererere')
        }
        
        for(let i of item.table){
          if(DataReal.filter((e)=>{return e.bill_id === i.id}).length > 0){

            var index_real = DataReal_.findIndex((find)=>find.bill_id === i.id);
            DataReal_[index_real].delivery_sort = num;
          
          
          }
          for(let k of bill_p){
            if(k.id === i.id){
              k.row_ = num;
            }
          }
          i.row_ = num;
        
        }
        
       
        item.row = num;
  /*       for(let item2 of item.table){
          
        } */

        num++;
      }
      const sorter = (a, b) => {
        if(moment(a.date).format('DD/MM/yyyy') !== moment(b.date).format('DD/MM/yyyy')){
           return moment(a.date) -  moment(b.date);
        }else{
          /*  return months.indexOf(a.month) - months.indexOf(b.month); */
           return tempdata2.indexOf(a.delivery_sort) - tempdata2.indexOf(b.delivery_sort);
        };
     };
      //(bill_p)
      tempdata2.sort(sorter);
      setDataReal([]);
      setbill(bill_p);
      setDataReal(DataReal_);
      setdynamic_table(tempdata2);
      settest({...test,test:""});
      test.test = "";
      Swal.fire('Delete!', '', 'success')
    } 
  })
   
  }
//console.log(bill)

  function del_docOrder(row,date,id,status,e){
    //console.log("id",id,"row",row,"date",date,e)
    Swal.fire({
      title: 'Do you want to Remove this?',
      icon:'warning',
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:`No`,
    }).then((result) => {
      if (result.isConfirmed) {

        var tempdata2 = dynamic_table;
        const DataReal_ = DataReal;
        setdynamic_table([]);
        for(let item of tempdata2){
          if(item.row == row && moment(item.date).format('DD/MM/yyyy') === moment(date).format('DD/MM/yyyy')){
              var index_ = item.table.findIndex((e)=> e.id === id);
            //console.log("here",item.row, " = ",row )
            item.table.splice(index_, 1);
    
            const bill_p = bill;
         
            /* if(DataReal.filter((e)=>{return e.bill_id === id  && e.remark === null}).length > 0){
            var index_real = DataReal_.findIndex((find)=>find.bill_id === id);
                DataReal_[index_real].delivery_sort = "";
            } */
            setbill([]);
            for(let i of bill_p){
              //for(let k2 of DataReal){
                var check = DataReal.findIndex((e)=> e.bill_id === i.id && e.remark === null);

                if(check > -1){
                  DataReal.splice(check, 1);
                }
              /*   for(let k2 of DataReal){
                  if(k2.bill_id === i.id && k2.remark === null){
                   
                  }
                } */
      
              if(i.id === id){
                i.isPush = false;
              }
            }
            
         // }
            setbill(bill_p);
          }
        }
        setDataReal([]);
        setDataReal(DataReal_);
        //console.log(tempdata2);
        setdynamic_table(tempdata2);
        settest({...test,test:""});
        test.test = "";
        Swal.fire('Delete!', '', 'success')
      }
    });
    
  
  }
  function add_docOrder(e){
  
    var tempdata2 = dynamic_table;
    setdynamic_table([]);
    for(let item of tempdata2){
      //(item.row,item.date,"       ",logistic.row,logistic.date)
      if(item.row == logistic.row && item.date === logistic.date){
        var data = bill.filter((element)=>{ 
          return (element.bill_no === e.target.id);
        })
     //console.log("here",item, " = ",logistic )
        item.table.push(data[0]);
        item.table.sort((a,b)=> moment(a.delivery_bill) - moment(b.delivery_bill));
        const bill_p = bill;
        setbill([]);
        for(let i of bill_p){
          if(i.bill_no === e.target.id){
            i.isPush = true;
            i.row_ = logistic.row;
            i.date_ = logistic.date;
          }
        }
        setbill(bill_p);
      }
    }
    //console.log(tempdata2);
    setdynamic_table(tempdata2);
    settest({...test,test:""});
    test.test = "";
  }
  //console.log(dynamic_table);
  const markerStyle = {
    position: "absolute",
    top: "100%",
    left: "50%",
    transform: "translate(-50%, -100%)"
  };

 




  
const  rowsData =[];
  for(let i = 0;i < bill.length; i++){
    const rowItem = {};
    rowItem['num'] = i+1;
    rowItem['bill_no'] = bill[i].bill_no;
    var  oem_name = "";
    var OEM = allOEM.filter((w)=>{return w.id === bill[i].bill_oem_id});
    if(OEM.length >0){
      oem_name = OEM[0].name;
    }
    rowItem['bill_oem_id'] = oem_name;
    rowItem['create_bill'] = moment(bill[i].create_bill).format('DD/MM/yyyy');
    rowItem['delivery_bill']= bill[i].delivery_bill !== null ? moment(bill[i].delivery_bill).format('DD/MM/yyyy') : "";
    rowItem['customer_name'] = bill[i].customer_name;
    rowItem['bill_destination'] = bill[i].bill_destination;
    rowItem['bill_values'] = bill[i].bill_values !== null ? bill[i].bill_values.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
    var status = "รอจัดส่ง";
    if(bill[i].is_delivery === true){
      status = "จัดส่งแล้ว";
    }

    rowItem['status'] = status;
    var dis = true;
    if(bill[i].row_ === logistic.row && moment(bill[i].date_).format('DD/MM/yyyy') === moment(logistic.date).format('DD/MM/yyyy')){
      dis = false;
      //console.log("herererere")
    }
    rowItem["mgt"] = (
      <>
      {bill[i].isPush === true ? (
          <>
          <div className="col-12">
                  <button
                  disabled = {dis}
                    type="button"
                  id = {i}
                  onClick={del_docOrder.bind(this,logistic.row,logistic.date,bill[i].id)}
                  className="btn btn-block btn-danger "
                  >
                    เอาออก
                  </button>
                </div>
        </>
      ):(
        <>
        <div className="col-12">
        <button
          type="button"
          id = {bill[i].bill_no}
          onClick={add_docOrder.bind(this)}
          class="btn btn-block btn-primary "
        >
          เอาเข้า
        </button>
      </div>
      </>
      )}
     
      </>
    );
    rowsData.push(rowItem);
  }
  const data_docOrder = {
    columns: [
      {
        label: "ลำดับ",
        field: "num",
        sort: "asc",
        width: 50,
      },{
        label: "เลขที่บิล",
        field: "bill_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "กลุ่มบริษัท",
        field: "bill_oem_id",
        sort: "asc",
        width: 50,
      },
      {
       label: "วันที่ออกบิล",
       field: "create_bill",
       sort: "asc",
       width: 50,
     },
     {
      label: "วันที่นัดส่งสินค้า",
      field: "delivery_bill",
      sort: "asc",
      width: 50,
    },
      
      {
        label: "ลูกค้า",
        field: "customer_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "หน้างาน",
        field: "bill_destination",
        sort: "asc",
        width: 50,
      },
      {
        label: "มูลค่า",
        field: "bill_values",
        sort: "asc",
        width: 50,
      },
      {
        label: "สถานะ",
        field: "status",
        sort: "asc",
        width: 50,
      },
  
      {
        label: "จัดการ",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };


  function save_cancleBill(index,e){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, confirm it!'

    }).then((result) => {
 

      if (result.isConfirmed) {
        
        var tempData = DataReal;
        var temp = {
          driver:tempData[index],
          remark:cancleBill.remark,
        };
        //console.log(index,temp);
        axios({
          method: "post",
          url: Configs.API_URL + "/driver/cancleBillDriver",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp
        })
          .then(function (response) {
        
            if (response.data) {
           
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                GetLogisticData();
              });
            }
            
         
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      })
   
    
  }



  function form_cancle(bill_id,delivery_sort,logistic_date,ex){ 
    //console.log(bill_id,delivery_sort,logistic_date) 
   

   var index = DataReal.findIndex((e)=>e.bill_id === bill_id && e.delivery_sort === delivery_sort && moment(e.logistic_date).format('DD/MM/yyyy') === moment(logistic_date).format('DD/MM/yyyy') )
   
   // console.log(index)
     setcancleBill({...cancleBill,
      id:DataReal[index].id,
      index:index,
      delivery_sort:DataReal[index].delivery_sort,
      date:DataReal[index].logistic_date,
     
      bill_no:DataReal[index].bill_no,
      remark:DataReal[index].remark || "",
    })
    cancleBill.id = DataReal[index].id;
    cancleBill.index = index;
    cancleBill.delivery_sort = DataReal[index].delivery_sort;
    cancleBill.date = DataReal[index].logistic_date;

    cancleBill.bill_no = DataReal[index].bill_no;
    cancleBill.remark = DataReal[index].remark || "";
  
  
  
    }





  return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Logistic IN/OUT{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>

              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Project</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row mb-2">
                    <div className="col-4 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
              <div className="col-4 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-4 col-md-3 col-xl-2">
                  <button
                    type="button"
                    if
                      onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>
        <section className="content">
          <div className="card">
   

            <div className="card-body">
              <div className = "row">
              <div className="col-12 col-md-5 col-xl-3">
              <h1>ทะเบียน : {car_Detail.plate_number}</h1>
              </div>
              <div className="col-12 col-md-5 col-xl-3">
               <h1>ประเภท {car_Detail.car_type}</h1>
              </div>
              </div>
           
                <div className="tab-custom-content">
                 </div>
                 <br/>
                 <div className="row mb-2">
                
                 <div className="col-6 col-md-4 col-xl-2">
                 <div className="form-group ">
                  <button
                    type="button"
                    onClick={add_row}
                    class="btn btn-block btn-primary "
                  >
                    เพิ่มเที่ยวรถ
                  </button>
                </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
              <table>
                <tr>
                  <td>วันที่</td>
                  <td> 
                    <DatePicker 
        selected = {dateSelect.date}
        dateFormat = {"dd-MM-yyyy"}
        onChange = {
          async(date) =>{
            setdateSelect({...dateSelect,date:date});
            dateSelect.date = date;
          }
         
   
          }
          minDate = {new Date()}
     
        customInput = {<CustomInput/>}
        
        /> 
        </td>
                </tr>
               
              </table>
           

              </div>
              </div>
              {dynamic_table.map((e,index)=>{
                var dis = true;
                if(e.oem !=""){
                  dis = false;
                }
                var status_ = "";
                var corlor_ = "";
                if(e.table.filter((d)=>{return d.is_delivery === true}).length > 0){
                  status_ = "ระหว่างการจัดส่ง";
                  corlor_ = "#FFA500";
                }
                var cancle_check1 = DataReal.filter((e1)=>{return e1.delivery_sort === e.row && moment(e1.logistic_date).format("DD/MM/yyyy") === moment(e.date).format("DD/MM/yyyy") && (e1.remark !== null  && e1.remark !== "" ) });
                //console.log(cancle_check1,e.row);
                if(e.table.filter((df)=>{ return df.deivered_finish}).length+cancle_check1.length === e.table.length && e.table.length > 0 ){
                  status_ = "จัดส่งเรียบร้อยแล้ว";
                  corlor_ = "#2E8B57";
       
                }

                if(cancle_check1.length === e.table.length && e.table.length > 0 ){
                  status_ = "ยกเลิกทั้งหมด";
                  corlor_ = "#FF0000";
       
                }
                  return (
                      <>
                      <div className="row">
                     <div className="col-12">
              

                <h3>เที่ยวที่ {e.row} [{moment(e.date).format('DD/MM/yyyy')}] <font /* className = "btn-block" */ color = {corlor_}>{status_}</font></h3>
                <div className="card-body table-responsive" style={{ whiteSpace: "nowrap" }}>
              <table style = {{ border: "1px solid #ddd", bordercollapse: "collapse", width: "100%"}}>
                  <tr>
                    <td>
                      <br/>
                    <div className="row">
                    <div className="col-1"></div>
                    <div className="col-4 col-md-3 col-xl-2">
                      <button
                    type="button"
                    disabled={e.table.filter((d)=>{return d.is_delivery === true}).length > 0 || (cancle_check1.length === e.table.length && e.table.length > 0)  ? true : false}
                    onClick={()=>{
              
                      setlogistic({ ...logistic,row:e.row,date:e.date,doc_order:"",customer:"",target:"",latitude: "",longitude: ""});
                      setfilter_bill({...filter_bill,oem_id: getOem()});
                      filter_bill.oem_id =getOem();
                       GetBill();
                    }}
                   data-toggle="modal"
                   data-target="#modal-add_doc_order"
                    class="btn btn-block btn-primary"
                  >
                    เพิ่มใบส่งของ
                  </button>
                        </div>
                       {/*  <div className="col-md-1" style={{textAlign:"right"}}>ใช้โดยบริษัท
                   {e.oem === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                        </div>
                        <div className="col-md-2">
         
                  <select
                   disabled={e.table.filter((d)=>{return d.is_delivery === true}).length > 0  || (cancle_check1.length === e.table.length && e.table.length > 0) ? true : false}
                    className="custom-select select2 form-control"
                    value={e.oem}
                    onChange={(data)=>{
                      //console.log(data.target.value)
                      setfilter_bill({...filter_bill,oem_id:data.target.value, });
                      filter_bill.oem_id = data.target.value;
                      e.oem = data.target.value;
                      settest({...test,test:""});
                      test.test = "";
                  
                    }}
                  >

                    <option value="">--เลือกกลุ่มบริษัท--</option>
                    {allOEM.map((oem) =>{
                      return  ( <option value={oem.id}>{oem.name}</option>);
                    })}
       
            
                  </select>

      
              </div> */}
                    </div>
                    <br/>
                    <div className="row">
                      <div className="col-md-1">
                        </div>
                        <div className="col-md-10">
               
                        <table style = {{ border: "1px solid #ddd", bordercollapse: "collapse", width: "100%"}}>
                          <tr>
                            <td style = {{ border: "1px solid #ddd", bordercollapse: "collapse",textAlign: "center"}}>ลำดับ</td>
                            <td style = {{ border: "1px solid #ddd", bordercollapse: "collapse",textAlign: "center"}}>เลขที่บิล</td>
                            <td style = {{ border: "1px solid #ddd", bordercollapse: "collapse",textAlign: "center"}}>กลุ่มบริษัท</td>
                  {/*           <td style = {{ border: "1px solid #ddd", bordercollapse: "collapse",textAlign: "center"}}>วันที่ออกบิล</td> */}
                            <td style = {{ border: "1px solid #ddd", bordercollapse: "collapse",textAlign: "center"}}>วันที่นัดส่งสินค้า</td>
                            <td style = {{ border: "1px solid #ddd", bordercollapse: "collapse",textAlign: "center"}}>ลูกค้า</td>
                            <td style = {{ border: "1px solid #ddd", bordercollapse: "collapse",textAlign: "center"}}>หน้างาน</td>
                            <td style = {{ border: "1px solid #ddd", bordercollapse: "collapse",textAlign: "center"}}>มูลค่า</td>
                            <td style = {{ border: "1px solid #ddd", bordercollapse: "collapse",textAlign: "center"}}>สถานะ</td>
                          {/*   <td style = {{ border: "1px solid #ddd", bordercollapse: "collapse",textAlign: "center"}}>remark</td> */}
                            <td style = {{ border: "1px solid #ddd", bordercollapse: "collapse",textAlign: "center"}}>เอาออก</td>
                            <td style = {{ border: "1px solid #ddd", bordercollapse: "collapse",textAlign: "center"}}>ยกเลิก</td>
                          </tr>
                          {e.table.map((e2,index2)=>{
                            //console.log(e2)
                            var status = "รอจัดส่ง";
                      
                            var color = "#00008B";
                            
                            if(e2.is_delivery === true){
                              status = "กำลังจัดส่ง";
                              color = "#FFA500";
                            }
                            if(e2.deivered_finish){
                              status = "ส่งแล้ว";
                              color = "#2E8B57";
                            }

                            var cancle_check = DataReal.filter((e1)=>{return e1.bill_id === e2.id && e1.delivery_sort === e.row && moment(e1.logistic_date).format("DD/MM/yyyy") === moment(e.date).format("DD/MM/yyyy")});
                            var check_test = bill.filter((e)=>{return e.id === e2.id});
                            //เดวไปเพิ่ม id ของ driver ติดมาใน e.table
                            if(cancle_check.length > 0){
                              if(cancle_check[0].remark !== null && cancle_check[0].remark !== ""){
                                status = "ยกเลิก";
                                color = "#FF0000";
                              }
                            }
                          
                          var  oem_name = "";
                          var OEM = allOEM.filter((w)=>{return w.id === e2.bill_oem_id});
                          if(OEM.length >0){
                            oem_name = OEM[0].name;
                          }

                  return (
                      <>
                          <tr>
                            <td style = {{ border: "1px solid #ddd", bordercollapse: "collapse",textAlign: "center"}}>{index2+1}</td>
                            <td style = {{ border: "1px solid #ddd", bordercollapse: "collapse",textAlign: "center"}}>{e2.bill_no}</td>
                            <td style = {{ border: "1px solid #ddd", bordercollapse: "collapse",textAlign: "center"}}>{oem_name}</td>
{/*                             <td style = {{ border: "1px solid #ddd", bordercollapse: "collapse",textAlign: "center"}}>{moment(e2.create_bill).format('DD/MM/yyyy')}</td> */}
                            <td style = {{ border: "1px solid #ddd", bordercollapse: "collapse",textAlign: "center"}}>{e2.delivery_bill !== null ? moment(e2.delivery_bill).format('DD/MM/yyyy') : ""}</td>
                            <td style = {{ border: "1px solid #ddd", bordercollapse: "collapse",textAlign: "center"}}>{e2.customer_name}</td>
                            <td style = {{ border: "1px solid #ddd", bordercollapse: "collapse",textAlign: "center"}}>{e2.bill_destination}</td>
                            <td style = {{ border: "1px solid #ddd", bordercollapse: "collapse",textAlign: "center"}}>{e2.bill_values.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                            <td style = {{ border: "1px solid #ddd", bordercollapse: "collapse",textAlign: "center"}}><font color = {color}>{status}</font></td>
                    {/*         <td style = {{ border: "1px solid #ddd", bordercollapse: "collapse",textAlign: "center"}}>{""}</td> */}
                        
                            <td style = {{ border: "1px solid #ddd", bordercollapse: "collapse",textAlign: "center"}}>
                        
                                             <button
                                              type="button"
                                              className="btn btn-block btn-danger"
                                              disabled={e.table.filter((d)=>{return d.is_delivery === true}).length > 0 || (cancle_check1.length === e.table.length && e.table.length > 0) ? true : false}
                                             /*  disabled  ={e2.deivered_finish ? true : false} */
                                          
                                              id = {index2}
                                              onClick={del_docOrder.bind(this,e.row,e.date,e2.id,status)}
                                              >
                                              <i
                                          
                                                className="fa fa-trash  icon-sm"
                                                
                                              ></i>
                                            </button>
                                             
                                       
                            </td>
                            <td style = {{ border: "1px solid #ddd", bordercollapse: "collapse",textAlign: "center"}}>
                            <button
                        type="button"
                               disabled={status === "ส่งแล้ว" || status === "ยกเลิก" || e.table.filter((d)=>{return d.is_delivery === true}).length === 0 ? true : false} 
                              data-toggle="modal"
                              data-target="#modal-cancleBill"
                              className="btn btn-block btn-danger"
                     /*  id = {driver[index].bill_no} */
          
                      onClick={form_cancle.bind(
                        this,
                        e2.id,
                        e.row,
                        e.date,
                       
                      )}
                    >
                <i class="fas fa-times-circle"></i>
                    
                    </button>
                    </td>
                          </tr>
                          </>
                           );
                            })}
                        
                          </table>
                     
                          <br/>
                        </div>
                        <div className="col-md-1">
                        </div>
                        </div>
                        <div className="row mb-2">
                        <div className="col-8 col-md-10 col-xl-11">
                      
                          </div>
                          <div className="col-4 col-md-2 col-xl-1">
                  
                  <button
                    type="button"
                    disabled={e.table.filter((d)=>{return d.is_delivery === true}).length > 0 || (cancle_check1.length === e.table.length && e.table.length > 0)   ? true : false}
                            id = {index}
                    onClick={del_row.bind(
                 this
                    )}
                    className="btn btn-block btn-danger "
                  >
                    Remove
                  </button>
                </div>  
                </div>
                    </td>
                  </tr>
                       </table>
                       </div>       </div>      
              </div>
            
              <br/>
                      </>
                  );
              })}
              




            </div>
            
          </div>
        </section>
      </div>


      <div className="modal fade" id="modal-add_doc_order">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-label">เพิ่มใบสั่งของ เที่ยวที่ {logistic.row}   [{moment(logistic.date).format('DD/MM/yyyy')}]</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                 <div className="col-md-2">
         
                  <select
                  // disabled={e.table.filter((d)=>{return d.is_delivery === true}).length > 0  || (cancle_check1.length === e.table.length && e.table.length > 0) ? true : false}
                    className="custom-select select2 form-control"
                    value={filter_bill.oem_id}
                    onChange={(data)=>{
                      //console.log(data.target.value)
                      setfilter_bill({...filter_bill,oem_id:data.target.value, });
                      filter_bill.oem_id = data.target.value;
                       GetBill();
                  
                    }}
                  >

                    <option value="">--เลือกกลุ่มบริษัท--</option>
                    {allOEM.map((oem) =>{
                      return  ( <option value={oem.id}>{oem.name}</option>);
                    })}
       
            
                  </select>
                  </div>
              </div>
            <div className="table-responsive" style={{ whiteSpace: "nowrap" }}>
                <MDBDataTable
                sortable = {false}
                  className="table table-head-fixed"
                  striped
                  disableRetreatAfterSorting={true}
                  bordered
                  hover
                  fixedHeader
                  data={data_docOrder}
                />
              </div>
  
               
              <div className="row">
                <div className="col-1"></div>
                
                <div className="col-1"></div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
            
                    <button
      
                    type="button"
           
                    data-dismiss="modal"
                    className="btn btn-primary"
                  >
                    Comfrim
                  </button>
                  
                 {/*  </>
                  )}
                      */}
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
          
             
            </div>
          </div>
         
        </div>
       
      </div>






  





      <div className="modal fade" id="modal-cancleBill">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-label">ยกเลิกการส่ง   บิล [{cancleBill.bill_no}]</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
            <div className="row">
            <div className="col-md-12">
            <div className="form-group ">
                  
                  <input
                      disabled = {cancleBill.dis}
                      type="text"
                      className="form-control"
                      
                      value={cancleBill.remark}
                      //id = {index}
    
                     onChange={(e)=>{setcancleBill({...cancleBill,remark:e.target.value})}}
                    />
                    <label htmlFor="">เหตุผลในการยกเลิก</label>{" "}
                  </div>
  
              </div>
              </div>
              
            






            </div>
            <div className="modal-footer justify-content-between">
            
              
                    <button
         
                    type="button"
                    disabled = {cancleBill.remark.trim() === "" ? true : false}
                     onClick={save_cancleBill.bind(this,cancleBill.index)} 
                   data-dismiss="modal"
                    className="btn btn-primary"
                  >
                  บันทึก
                  </button>
                  
           
              <button
                type="button"
                className="btn btn-default"
              data-dismiss="modal"
              >
                Close
              </button>
          
             
            </div>
          </div>
         
        </div>
       
      </div>


     {/*  <Footter/> */}
      </div>
  );
}
export default LogisticInOut_add;
