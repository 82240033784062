import React, { Component, useEffect, useState, useRef } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import "../../../assets/css/Supp.css";
import "../../../assets/css/Content.css";
import "../../../assets/css/Input.css";
import "../../../assets/css/table.css";
import Footter from "../../../component/Footter";
import Header from "../../../component/Header";
import sortJsonArray from "sort-json-array";
import { useParams } from "react-router";
import Sidebar from "../../../component/Sidebar";
import { getOem, getToken, getUser, getAcademy } from "../../../Utils/Common";
import jsPDF from "jspdf";
import Configs from "../../../config";
import FormData from "form-data";
import axios from "axios";
import NumberFormat from "react-number-format";
import { userdefault_img } from "../../../routes/imgRoute/imgUrl";
import DatePicker from "react-datepicker";
import Select from "react-select";
import INF from "../../../component/input_feature";
import QRCode2 from "qrcode";
import QRCode from "qrcode.react";
import { Production_order_CTS_report } from "../../../component/Report";
var moment = require("moment");
function Production_order_CTS_add(mode) {
  const [filter_product, setfilter_product] = useState({
    no: "",
    name: "",
    product_model_id: "",
    oem_id: getOem(),
  });
  const [test, settest] = useState({
    test: "",
  });
  const [MDBT, setMDBT] = useState(true);
  const [customer, setcustomer] = useState({
    company_id: getUser().com,
    oem_id: getOem(),
    name: "",
    full_name: "",
    is_use: "",
    sort: "",
    detail: "",
    contact_tel: "",
    contact_fax: "",
    contact_name: "",
    customer_email: "",
    relate_product_id: "",
    remark: "",
    line_id: "",
    customer_id: "",
  });

  const [part_noAndpart_name, setpart_noAndpart_name] = useState({
    part_no: "",
    part_name: "",
  });

  const [product, setProduct] = useState([]);
  const [product_select, setProduct_select] = useState([]);
  const [pageMode, setPageMode] = useState("");
  const [checkMode, setCheckMode] = useState(null);
  const [disable, setdisable] = useState(null);

  const { id } = useParams();
  const [calender_data, setcalender_data] = useState({
    /*  gen_name_po:"PO", */
    exp: "",
    now_date: new Date(),
  });
  const [search_product_end, setsearch_product_end] = useState({
    result_product: "",
    result_end: "",
  });

  const [search_mat, setsearch_mat] = useState({
    materail_id: "",
    materail_name: "",
  });
  const [order_production, setorder_production] = useState({
    company_id: getUser().com,
    oem_id: getOem(),
    order_number: "" /* เลขที่ใบผลิต */,
    customer_id: "" /* customer*/,
    part_no: "" /*  part_no */,
    part_name: "" /* part_name */,
    plated_surface: "" /* ผิวชุบ */,
    materail_id: "" /* materail*/,
    Quantity_to_be_produced: "" /* จำนวนที่ต้องการผลิต */,
    use_date_per_piece: "" /* เวลาที่ใช้ผลิตต่อชิ้น */,
    use_date_all_piece: 0 /* เวลาที่ใช้ผลิตทั้งหมด */,
    manufacturer: "" /* ผู้ผลิต */,
    date_of_production: new Date() /* วันที่ผลิตเสร็จ */,
    remark: "" /*  หมายเหตุ */,
    ng_record: "" /*  setup ng record */,
    document_issuer: "" /* ผู้ออกเอกสาร */,
    producer_date: new Date(),
    produ_id: "",
    dead_line_date: new Date(),
  });
  const [selectCustomer, setselectCustomer] = useState([]);
  const [ProductMap, setProductMap] = useState([]);

  const [MartailIN, setMartailIN] = useState([]);

  const [use_date_per, setuse_date_per] = useState({
    use_date_per_piece: "",
  });
  const [be_produced, setbe_produced] = useState({
    Quantity_to_be_produced: "",
  });

  const [mm, setmm] = useState({
    use_date_per_piece: "",
  });

  const [all_piece, setall_piece] = useState({
    use_date_all_piece: 0,
  });

  const [Product_surfac, setProduct_surfac] = useState({
    surface: "",
  });

  async function getuse_date_per_piece(date) {
    //console.log("หน้าบวก",date);
    if (mode.mode === "add") {
      setorder_production({
        ...order_production,
        use_date_per_piece: date || 0,
      });
    }
    if (mode.mode === "edit") {
      //console.log("หน้าบวก",date);
      // setorder_production({...order_production, use_date_per_piece: date || 0})
      // order_production.use_date_per_piece = date || 0
      // console.log("หน้าบวก",date);
    }

    setuse_date_per({ ...order_production, use_date_per_piece: date });
    //return date;
  }

  async function getQuantity_to_be_produced(date) {
    setbe_produced({ ...order_production, Quantity_to_be_produced: date });
    //return date;
  }

  async function getPartName(date) {
    // console.log("paa",date);
    setbe_produced({ ...order_production, part_name: date });
    //return date;
  }

  const GetCustomer = async () => {
    let temp = {
      companyGroup_id: "",
      company_id: getUser().com,
      oem_id: getOem(),
    };
    await axios({
      method: "post",
      url: Configs.API_URL + "/customer/filterOEM",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        setselectCustomer(response.data);
        //console.log("customer",response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  function fc_sum_time(Quantity, PerPiece, sumll) {
    //ดาต้าที่เข้า",parseInt(Quantity),":",parseInt(PerPiece),":",sumll);
    let sumPriceAll = 0;
    if (mode.mode === "add") {
      if (Quantity === "") {
        Quantity = 0;
      }
      if (PerPiece === "") {
        PerPiece = 0;
      }
      sumPriceAll = parseInt(Quantity) * parseInt(PerPiece);
      setall_piece({ ...all_piece, use_date_all_piece: sumPriceAll });
    }
    if (mode.mode === "edit") {
      sumPriceAll = parseInt(Quantity) * parseInt(PerPiece);
      setall_piece({ ...all_piece, use_date_all_piece: sumPriceAll || 0 });
    }
    //setorder_production({...order_production,use_date_all_piece:sumPriceAll})
    //console.log("Quantity",Quantity); /* จำนวน */
    //console.log("PerPiece",PerPiece); /* เวลาต่อชิ้น */

    // console.log("sumPriceAll",sumPriceAll);
  }

  // console.log("คาถาอยู่ยงคงกะพัน",Product_surfac.surface);

  const GetProduct = async (product_ids, key) => {
    console.log("เช็คคีย์", key, "+", product_ids);
    let product_id = "";
    let customer = "";
    /* let mat = ''; */
    if (key === "product") {
      product_id = product_ids;
    }

    if (key === "customer") {
      customer = product_ids;
    } else {
      customer = order_production.customer_id;
    }

    /*    if(key === 'mat'){
      mat = product_ids
    } */
    const filler_product = {
      no: "",
      name: "",
      product_model_id: "",
      oem_id: getOem(),
      customer_id: customer,
    };
    await axios({
      method: "post",
      url: Configs.API_URL + "/api/productionOrderCTS/filterProductByCustomer",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_product,
    })
      .then(async (response) => {
        console.log("เลยสปอน = 1", response.data);

        if (response.data.length === 0) {
          setorder_production({
            ...order_production,
            company_id: getUser().com,
            oem_id: getOem(),
            order_number: order_production.order_number /* เลขที่ใบผลิต */,
            customer_id: customer /* customer*/,
            part_no: "" /*  part_no */,
            part_name: "" /* part_name */,
            plated_surface: "" /* ผิวชุบ */,
            materail_id: order_production.materail_id /* materail*/,
            Quantity_to_be_produced:
              order_production.Quantity_to_be_produced /* จำนวนที่ต้องการผลิต */,
            use_date_per_piece:
              order_production.use_date_per_piece /* เวลาที่ใช้ผลิตต่อชิ้น */,
            use_date_all_piece:
              order_production.use_date_all_piece /* เวลาที่ใช้ผลิตทั้งหมด */,
            manufacturer: order_production.manufacturer /* ผู้ผลิต */,
            date_of_production:
              order_production.date_of_production /* วันที่ผลิตเสร็จ */,
            remark: order_production.remark /*  หมายเหตุ */,
            ng_record: order_production.ng_record /*  setup ng record */,
            document_issuer:
              order_production.document_issuer /* ผู้ออกเอกสาร */,
            producer_date: order_production.producer_date,
            produ_id: "",
            dead_line_date: order_production.dead_line_date,
          });
          order_production.part_no = "";
          setProduct_surfac({ ...Product_surfac, surface: "" });
          Product_surfac.surface = "";
          setsearch_product_end({ ...search_product_end, result_product: "" });
          search_product_end.result_product = "";
          setsearch_mat({ ...search_mat, materail_id: "", materail_name: "" });
          search_mat.materail_id = "";
          search_mat.materail_name = "";
        } else {
          order_production.part_no = "";
        }
        const options = [];
        if (response.data) {
          response.data.forEach((el) => {
            var element = {};
            element["value1"] = el.id;
            element["value2"] = el.name;
            element["label"] = el.no;
            options.push(element);
          });
          setProduct_select(options);
          if (mode.mode === "add") {
            setorder_production({
              ...order_production,
              part_no: /* response.data[0].id */ "",
              customer_id: customer,
              plated_surface: /* response.data[0].surfac */ "",
            });
            order_production.part_no = /* response.data[0].id */ "";
            order_production.customer_id = customer;
            order_production.plated_surface = /* response.data[0].surfac */ "";

            setsearch_product_end({
              ...search_product_end,
              result_product: /* response.data[0].name */ "",
            });
            search_product_end.result_product = /* response.data[0].name */ "";

            setsearch_mat({
              ...search_mat,
              materail_id: "",
              materail_name: "",
            });
            search_mat.materail_id = "";
            search_mat.materail_name = "";
          }

          if (mode.mode === "edit") {
            Product_surfac.surface = "";
            order_production.part_no = /* response.data[0].id */ "";

            search_product_end.result_product = /* response.data[0].name */ "";
            setsearch_mat({
              ...search_mat,
              materail_id: "",
              materail_name: "",
            });
            search_mat.materail_id = "";
            search_mat.materail_name = "";
          }
        } else {
          console.log("online", response.data);
          var element = {};
          element["value1"] = "";
          element["value2"] = "";
          element["label"] = "";
          options.push(element);

          setProduct_select(options);
        }
        if (options !== []) {
          let list = options.filter((e) => {
            return e.value1 === product_id;
          });
          setsearch_product_end({
            ...search_product_end,
            result_product: list[0].value2,
          });
          setbe_produced({ ...order_production, part_name: list[0].value2 });
        }
        if (mode.mode === "add") {
          genLotNo();
        }
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  const GetMaterialProduct = async (pro_id) => {
    await axios({
      method: "get",
      url: Configs.API_URL + "/product/findById?id=" + pro_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      /*       data: temp, */
    })
      .then(async (response) => {
        if (mode.mode === "add") {
          if (response.data.surface) {
            order_production.plated_surface = response.data.surface;
            var sumsurface = response.data.surface;
            setProduct_surfac({
              surface: sumsurface,
            });
            setorder_production({
              ...order_production,
              plated_surface: sumsurface,
            });
          } else {
            order_production.plated_surface = "";
            setProduct_surfac({
              surface: "",
            });
          }
        }

        if (mode.mode === "edit" || mode.mode === "read") {
          /* if(response.data.surface){} */

          setsearch_product_end({
            ...search_product_end,
            result_product: response.data.name,
          });

          /*  setorder_production({...order_production,
            plated_surface:response.data.surface
          }) */
          setProduct_surfac({
            ...Product_surfac,
            surface: response.data.surface,
          });
          /* order_production.plated_surface = response.data.surface
        
        Product_surfac.surface = response.data.surface */
        }

        if (response.data.process_cycle_time.length > 0) {
          let array_time = [];
          for (
            let count = 0;
            count < response.data.process_cycle_time.length;
            count++
          ) {
            array_time.push(
              response.data.process_cycle_time[count].cycle_time || []
            );
          }
          if (array_time.length > 0) {
            response.data.process_cycle_time = array_time.reduce(
              (a, b) => a + b,
              0
            );
          } else {
            response.data.process_cycle_time = 0;
          }
        } else {
          response.data.process_cycle_time = 0;
        }
        if (response.data.process_cycle_time === 0) {
          order_production.use_date_per_piece = 0;
        }
        getuse_date_per_piece(response.data.process_cycle_time);
        setmm({ ...mm, use_date_per_piece: response.data.process_cycle_time });
        const options = [];
        if (response.data.multiple_mat.length > 0) {
          response.data.multiple_mat.forEach((el) => {
            var element = {};
            element["value1"] = el.material_id;
            element["label"] = el.no;
            options.push(element);
          });
          setProductMap(options);
        } else {
          var element = {};
          element["value1"] = "";
          element["label"] = "";
          options.push(element);
          setProductMap(options);
        }
        if (options !== []) {
          let id_mat = "";
          if (response.data.multiple_mat.length > 0) {
            id_mat = response.data.multiple_mat[0].material_id;
          }
          let list = options.filter((e) => {
            return e.value1 === id_mat;
          });

          setsearch_mat({
            ...search_mat,
            materail_name: list[0].label,
          });
          setsearch_mat({ ...search_mat, materail_name: list[0].label });
          if (mode.mode === "add") {
            console.log("listmART", list);
            setorder_production({
              ...order_production,
              materail_id: list[0].value1,
            });
            /* genLotNo() */
          }
        } else {
          setsearch_mat({
            ...search_mat,
            materail_name: "",
          });
          setsearch_mat({ ...search_mat, materail_name: "" });
        }
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (mode.mode === "read") {
      setdisable(true);
      setCheckMode(true);
      setPageMode("Read");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);

  useEffect(() => {
    GetProduct();
    GetCustomer();

    if (mode.mode === "read" || mode.mode === "edit") {
      //console.log("ProductMap",product_select);
      // console.log("order_production",order_production.part_no);

      GetCus_view();
    } else {
      genLotNo();
    }
  }, []);

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = data.color;
      return {
        ...styles,
        backgroundColor: isSelected ? "#87CEFA" : "#FFF",
        color: "#000",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };

  /*  ----------------- */
  async function genLotNo() {
    let dateExt = parseInt(moment(new Date()).format("YYYY")) + 543;
    let datefinal =
      "LOT" +
      dateExt.toString().slice(2) +
      "-"; /* + "-" + moment(new Date()).format('MMDD') */
    axios({
      method: "get",
      url:
        Configs.API_URL +
        "/api/productionOrderCTS/genProductionNo?order_number=" +
        datefinal,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        console.log("lotNOMM", response.data);
        setcalender_data({
          ...calender_data,
          exp: response.data,
        });
        /* setorder_production({
          ...order_production,
          order_number: response.data,
        }) */
        // setamount({ ...amount, lot_no: response.data });
        // amount.lot_no = response.data;
        //console.log(response.data)
      })
      .catch(function (e) {
        console.log(e);
      });
  }
  /* --------------- */

  function saveOption(params) {
    const error_list = [];
  /*   console.log("temp_save", order_production); */
   if (calender_data.exp.trim() == "") {
      let temp_err = {
        massage:
          "Please enter information in the fields >>> [เลขที่ใบสั่งผลิต].",
      };
      error_list.push(temp_err);
    }
    if(calender_data.exp.toString().trim()[0] !== 'L' || 
    calender_data.exp.toString().trim()[1] !== 'O' || 
    calender_data.exp.toString().trim()[2] !== 'T' || 
/*     typeof calender_data.exp.toString().trim()[3] == 'number' && 
    typeof calender_data.exp.toString().trim()[4] == 'number' &&  */
    calender_data.exp.toString().trim()[5] !== '-' ||
    calender_data.exp.toString().trim().length !== 10
    ){
      let temp_err = {
        massage:
          "Information in the fields [เลขที่ใบสั่งผลิต] is incorrect.",
      };
      error_list.push(temp_err);
    }
   
    if (order_production.customer_id == "") {
      let temp_err = {
        massage: "Please enter information in the fields >>> [Customer].",
      };
      error_list.push(temp_err);
    }
    if (order_production.part_no == "") {
      let temp_err = {
        massage: "Please enter information in the fields >>> [Part No].",
      };
      error_list.push(temp_err);
    }
    if (Product_surfac.surface == "") {
      let temp_err = {
        massage: "Please enter information in the fields >>> [ผิวชุบ].",
      };
      error_list.push(temp_err);
    }
    if (order_production.manufacturer == "") {
      let temp_err = {
        massage: "Please enter information in the fields >>> [ผู้ผลิต].",
      };
      error_list.push(temp_err);
    }

    if (order_production.Quantity_to_be_produced == "") {
      let temp_err = {
        massage:
          "Please enter information in the fields >>> [จำนวนที่ต้องการผลิต].",
      };
      error_list.push(temp_err);
    }

    if (order_production.document_issuer == "") {
      let temp_err = {
        massage: "Please enter information in the fields >>> [ผู้ออกเอกสาร].",
      };
      error_list.push(temp_err);
    }

    if (error_list.length > 0) {
      var err_massage = "";
      for (var e = 0; e < error_list.length; e++) {
        err_massage += "<br>" + error_list[e].massage;
      }
      Swal.fire("Error", err_massage, "error");
    } else {
      const temp_save = {
        company_id: order_production.company_id,
        oem_id: order_production.oem_id,
        order_number: (calender_data.exp || '').toString().trim(),
        customer_id: order_production.customer_id,
        part_no: order_production.part_no,
        part_name: search_product_end.result_product,
        plated_surface: Product_surfac.surface,
        materail_id: order_production.materail_id,
        Quantity_to_be_produced: order_production.Quantity_to_be_produced,
        use_date_per_piece: order_production.use_date_per_piece,
        use_date_all_piece: all_piece.use_date_all_piece,
        manufacturer: order_production.manufacturer,
        date_of_production: moment(
          new Date(order_production.date_of_production)
        ),
        dead_line_date: moment(new Date(order_production.dead_line_date)),
        producer_date: moment(new Date(order_production.producer_date)),
        remark: order_production.remark,
        ng_record: order_production.ng_record,
        document_issuer: order_production.document_issuer,
      };
        console.log(temp_save);
      if (mode.mode === "add") {
        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/api/productionOrderCTS/productionCTSadd",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp_save,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href = "/Warehouse_Management/ใบสั่งผลิต/";
                });
              }
            })
            .catch(function (error) {
              console.log(error.response.data.message);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
      }
      if (mode.mode === "edit") {
        const temp_edit = {
          id: id,
          company_id: order_production.company_id,
          oem_id: order_production.oem_id,
          order_number: calender_data.exp,
          customer_id: order_production.customer_id,
          part_no: order_production.part_no,
          part_name: search_product_end.result_product,
          plated_surface: Product_surfac.surface,
          materail_id: order_production.materail_id,
          Quantity_to_be_produced: order_production.Quantity_to_be_produced,
          use_date_per_piece: mm.use_date_per_piece,
          use_date_all_piece: all_piece.use_date_all_piece,
          manufacturer: order_production.manufacturer,
          date_of_production:
            order_production.date_of_production !== null
              ? moment(new Date(order_production.date_of_production))
              : null,
          dead_line_date: moment(new Date(order_production.dead_line_date)),
          producer_date: moment(new Date(order_production.producer_date)),
          remark: order_production.remark,
          ng_record: order_production.ng_record,
          document_issuer: order_production.document_issuer,
        };
        console.log("tempEdit", temp_edit);
        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url:
              Configs.API_URL + "/api/productionOrderCTS/updateProductionOrder",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp_edit,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href = "/Warehouse_Management/ใบสั่งผลิต/";
                });
              }

              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
      }
    }
  }

  const GetCus_view = async () => {
    if (mode.mode == "read" || mode.mode == "edit") {
      axios({
        method: "get",
        url:
          Configs.API_URL +
          "/api/productionOrderCTS/getProductionByID?id=" +
          id,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          console.log("view", response.data);

          if (mode.mode === "edit") {
            GetProduct(response.data.customer_id, "customer");
          } else {
            GetProduct(response.data.product_id, "product");
          }
          //  GetProduct(response.data.product_id,"product");
          //  GetProduct(response.data.customer_id,"customer");
          GetMaterialProduct(response.data.product_id);
          // setall_piece({
          //   ...all_piece,
          all_piece.use_date_all_piece = response.data.total_time_producer;
          //}//);
          setcalender_data({
            ...calender_data,
            exp: response.data.no,
          });
          fc_sum_time(
            response.data.amount,
            response.data.time_per_product,
            response.data.total_time_producer
          );

          if (response.data.is_success === true) {
            setCheckMode(true);
            if (mode.mode === "edit") {
              setdisable(true);
            }
          }

          /* console.log("customer_id",response.data); */
          //fc_sum_time(response.data.amount || 0,response.data.time_per_product);
          //GetMaterialProduct(response.data.product_id);
          setorder_production({
            ...order_production,
            //   company_id: response.data.company_id,
            //   oem_id: response.data.oem_id,
            order_number: response.data.no,
            customer_id: response.data.customer_id,
            part_no: response.data.product_id,
            //   part_name: response.data.result_product,
            plated_surface: response.data.plated,
            materail_id: response.data.material_id,
            Quantity_to_be_produced: response.data.amount,
            use_date_per_piece: response.data.time_per_product,
            use_date_all_piece: response.data.total_time_producer,
            manufacturer: response.data.user_producer,
            date_of_production: new Date(response.data.finish_date_producer),
            dead_line_date: response.data.finish_date_plan
              ? new Date(response.data.finish_date_plan)
              : new Date(response.data.finish_date_producer),
            producer_date: new Date(response.data.producer_date),
            remark: response.data.remark,
            ng_record: response.data.setup_ng_record,
            document_issuer: response.data.user_document,
            produ_id: response.data.id,
          });
        })
        .finally(function (response) {
          GetProduct(response.data.product_id, "product");
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  /* console.log("customer_id นะครับ",order_production); */

  function cancleOption(params) {
    Swal.fire({
      title: "Do you want to cancel and leave the changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "/Warehouse_Management/ใบสั่งผลิต/";
      }
    });
  }

  const linkreport = async () => {
    console.log(order_production.produ_id);
    const temp = {
      produ_id: order_production.produ_id,
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL +
        "/api/productionOrderCTS/getProduction_in_reportByID?id=",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        console.log(response.data);

        var img_qr = "";

        QRCode2.toDataURL(
          response.data[0].no +
            "," +
            response.data[0].product_list[0].no +
            "," +
            response.data[0].amount,
          function (err, url) {
            img_qr = url;
          }
        );

        Production_order_CTS_report(response.data, img_qr);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function status_order() {
    console.log("จะเตตัส", id);

    Swal.fire({
      title: "ดำเนินการเสร็จสิ้น?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url:
            Configs.API_URL + "/api/productionOrderCTS/updateSatatus?id=" + id,
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          } /* 
          data: temp_edit, */,
        })
          .then(function (response) {
            if (response.data) {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.href = "/Warehouse_Management/ใบสั่งผลิต/";
              });
            }

            //console.log(response.data);
          })
          .catch(function (error) {
            console.log("eeee", error);
            console.log(
              "error.response.data.message",
              error.response.data.message
            );
            Swal.fire("Error", error.response.data.message, "error");
          });
      }
    });
  }

  const rowsData = [];

  for (let index = 0; index < MartailIN.length; index++) {
    console.log("logHist", MartailIN[index]);
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["Production_no"] = MartailIN[index].no;
    rowItem["customer"] = MartailIN[index].customer;
    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "เลขใบสั่งผลิต",
        field: "Production_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Customer",
        field: "customer",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };

  return (
    <div className="wrapper">
      {/*    <Header />
      <Sidebar menu="warehouse" activemenu="customer" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  {pageMode} ใบสั่งผลิต{"  "}
                  {/* <a target="_blank" href={getAcademy()}><i class='fa fa-info-circle' ></i></a> */}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">ใบสั่งผลิต</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row">
                <div className="col-9">
                  <div className="col-4 col-md-4 col-xl-2">
                    <button
                      type="button"
                      onClick={cancleOption}
                      class="btn btn-block btn-danger "
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                {/*  <div className="col-3">
                <div className="col-4 col-md-4 col-xl-4">
                  <button
                    type="button"
                    onClick={linkreport}
                    class="btn btn-primary"
                  >
                    Report ใบสั่งผลิต
                  </button>
                </div>
                </div> */}
              </div>
            ) : (
              <div className="row mb-2">
                <div className="col-4 col-md-2 col-xl-1">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-4 col-md-2 col-xl-1">
                  <button
                    type="button"
                    if
                    onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <h3 className="mt-5-head">ใบสั่งผลิต Detail</h3>
                </div>
                <div className="col-3">
                  <div
                    className="col-12 col-md-12 col-xl-12"
                    style={{ textAlign: "right" }} /* align="right" */
                  >
                    {mode.mode === "read" ? (
                      <>
                        <button
                          type="button"
                          onClick={linkreport}
                          class="btn btn-primary"
                        >
                          Report ใบสั่งผลิต
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="tab-custom-content"></div>
              <br />
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 col-md-4 col-xl-4">
                    <INF
                      value={calender_data.exp}
              /*         disabled={true} */
              disabled={mode.mode === "add" ? false : true}
                      label={"เลขที่ใบสั่งผลิต"}
                       onChange={(e) => {
                 /*      setorder_production({
                        ...order_production,
                        order_number: e.target.value,
                      }) */
                      setcalender_data({
                        ...calender_data,
                        exp: e.target.value,
                      })
                    }} 
                    />
                  </div>
                  <div
                    className="col-12 col-md-4 col-xl-4"
                    style={{ "z-index": "99" }}
                  >
                    <div className="form-group">
                      <select
                        className="custom-select select2 form-control"
                        value={order_production.customer_id}
                        disabled={disable}
                        //onChange={cahecklocation}
                        onChange={(e) => {
                          setorder_production({
                            ...order_production,
                            customer_id: e.target.value,
                          });

                          GetProduct(e.target.value, "customer");
                        }}
                        /*  onClick={(e1) => {GetProductByDrows(e1.target.value)}} */
                      >
                        <option value="">Select</option>
                        {selectCustomer.map((el) => {
                          return (
                            <option key={el.id} value={el.id}>
                              {el.full_name}
                            </option>
                          );
                        })}
                      </select>
                      <label>
                        Customer
                        {order_production.customer_id === "" ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-xl-4">
                    <div class="form-group">
                      <input
                        className="form-control"
                        required
                        allowNegative={false}
                        decimalScale={0}
                        //thousandSeparator={false}
                        //format={"###-###-################"}
                        value={Product_surfac.surface}
                        disabled={true}
                        onChange={(e) => {
                          setorder_production({
                            ...order_production,
                            plated_surface: e.target.value,
                          });

                          setProduct_surfac({
                            ...Product_surfac,
                            surface: e.target.value,
                          });
                        }}
                      />

                      <label>
                        ผิวชุบ
                        {Product_surfac.surface === "" ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div
                    className="col-12 col-md-4 col-xl-4 mt-2"
                    style={{ "z-index": "98" }}
                  >
                    <div class="form-group">
                      <Select
                        placeholder=""
                        styles={colourStyles}
                        isDisabled={disable}
                        //disabled={disable}
                        options={product_select}
                        //var check = tempData.filter((e)=>{ return e.bill_no === bl.bill_no});

                        //console.log("ProductMap",ProductMap);
                        //console.log("order_production",order_production.part_no);
                        value={product_select.filter((e) => {
                          return e.value1 === order_production.part_no;
                        })}
                        //defaultValue={order_production.part_no }
                        onChange={(e) => {
                          // console.log("ew",e);

                          setorder_production({
                            ...order_production,
                            part_no: e.value1,
                          });
                          order_production.part_no = e.value1;

                          setsearch_product_end({
                            ...search_product_end,
                            result_product: e.value2,
                          });
                          //search_product_end.result_product = e.value2;
                          getPartName(e.value2);
                          GetMaterialProduct(e.value1);
                        }}
                      />
                      <label>
                        Product NO.
                        {order_production.part_no === "" ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-xl-4">
                    <div class="form-group">
                      <INF
                        value={search_product_end.result_product}
                        disabled={true}
                        label={"Product Name"}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-xl-4">
                    <div class="form-group mt-10">
                      <INF
                        value={search_mat.materail_name}
                        disabled={true}
                        label={"Materail"}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-4 col-xl-4">
                    <div class="form-group">
                      <NumberFormat
                        type="text"
                        className="form-control"
                        required
                        value={order_production.Quantity_to_be_produced}
                        allowNegative={false}
                        decimalScale={0}
                        disabled={disable}
                        onChange={(e) => {
                          fc_sum_time(
                            e.target.value,
                            use_date_per.use_date_per_piece
                          );
                          getQuantity_to_be_produced(e.target.value);
                          setorder_production({
                            ...order_production,
                            Quantity_to_be_produced: e.target.value,
                          });
                        }}
                      />
                      <label>
                        จำนวนที่ต้องการผลิต
                        {order_production.Quantity_to_be_produced === "" ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-xl-4">
                    <div class="form-group">
                      <input
                        type="text"
                        className="form-control"
                        required
                        //value={order_production.use_date_per_piece === 0 ? order_production.use_date_per_piece : mm.use_date_per_piece}
                        value={mm.use_date_per_piece}
                        disabled={true}
                        onChange={(e) => {
                          //sum_time(e.target.value)
                          setorder_production({
                            ...order_production,
                            use_date_per_piece: e.target.value,
                          });
                          //order_production.use_date_per_piece = e.target.value;
                        }}
                      />
                      <label>เวลาที่ใช้ผลิตต่อชิ้น</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-xl-4">
                    <div class="form-group">
                      <input
                        type="text"
                        className="form-control"
                        required
                        // defaultValue={console.log("WHAT",all_piece.use_date_all_piece)}
                        value={all_piece.use_date_all_piece}
                        disabled={true}
                        onChange={(e) => {
                          setorder_production({
                            ...order_production,
                            use_date_all_piece: all_piece.use_date_all_piece,
                          });
                          all_piece.use_date_all_piece = e.value1;
                        }}
                      />
                      <label>เวลาที่ใช้ผลิตทั้งหมด</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-4 col-xl-4">
                    <div class="form-group">
                      <input
                        type="text"
                        className="form-control"
                        required
                        value={order_production.manufacturer}
                        disabled={disable}
                        onChange={(e) => {
                          setorder_production({
                            ...order_production,
                            manufacturer: e.target.value,
                          });
                        }}
                      />
                      <label>
                        ผู้ผลิต
                        {order_production.manufacturer === "" ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                  </div>

                  <div
                    className="col-6 col-md-2 col-xl-2 "
                    style={{ "z-index": "100" }}
                  >
                    <div class="form-group">
                      <DatePicker
                        selected={order_production.producer_date}
                        value={order_production.producer_date}
                        disabled={disable}
                        className="form-control"
                        required
                        dateFormat={"dd-MM-yyyy"}
                        showMonthDropdown
                        onChange={async (e) => {
                          setorder_production({
                            ...order_production,
                            producer_date: e,
                          });
                        }}
                        selectsStart
                        startDate={order_production.producer_date}
                        // minDate = {new Date()}
                        maxDate={order_production.date_of_production}
                        /* endDate = {order_production.date_of_production} */
                      />
                      <label htmlFor="">วันที่ผลิต</label>
                      <label></label>
                    </div>
                  </div>
                  <div
                    className="col-6 col-md-2 col-xl-2"
                    style={{ "z-index": "101" }}
                  >
                    <div class="form-group">
                      <DatePicker
                        selected={order_production.date_of_production}
                        value={order_production.date_of_production}
                        disabled={disable}
                        className="form-control"
                        required
                        dateFormat={"dd-MM-yyyy"}
                        showMonthDropdown
                        onChange={async (e) => {
                          setorder_production({
                            ...order_production,
                            date_of_production: e,
                          });
                        }}
                        selectsEnd
                        startDate={order_production.producer_date}
                        endDate={order_production.date_of_production}
                        minDate={order_production.producer_date}
                        /*  maxDate = {order_production.dead_line_date} */
                      />
                      <label htmlFor="">วันที่ผลิตเสร็จ</label>
                      <label></label>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-xl-4">
                    <div class="form-group">
                      <input
                        type="text"
                        className="form-control"
                        required
                        value={order_production.ng_record}
                        disabled={disable}
                        onChange={(e) => {
                          setorder_production({
                            ...order_production,
                            ng_record: e.target.value,
                          });
                        }}
                      />
                      <label>Setup NG Record </label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6 col-md-4 col-xl-4">
                    <div class="form-group">
                      <input
                        type="text"
                        className="form-control"
                        required
                        value={order_production.document_issuer}
                        disabled={disable}
                        onChange={(e) => {
                          setorder_production({
                            ...order_production,
                            document_issuer: e.target.value,
                          });
                        }}
                      />
                      <label>
                        ผู้ออกเอกสาร
                        {order_production.document_issuer === "" ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="col-6 col-md-4 col-xl-4">
                    <div class="form-group">
                      <input
                        type="text"
                        className="form-control"
                        required
                        value={order_production.remark}
                        disabled={disable}
                        onChange={(e) => {
                          setorder_production({
                            ...order_production,
                            remark: e.target.value,
                          });
                        }}
                      />
                      <label>หมายเหตุ</label>
                    </div>
                  </div>
                  <div
                    className="col-6 col-md-2 col-xl-2 "
                    style={{ "z-index": "102" }}
                  >
                    <div class="form-group">
                      <DatePicker
                        selected={order_production.dead_line_date}
                        value={order_production.dead_line_date}
                        disabled={disable}
                        className="form-control"
                        required
                        dateFormat={"dd-MM-yyyy"}
                        showMonthDropdown
                        onChange={async (e) => {
                          setorder_production({
                            ...order_production,
                            dead_line_date: e,
                          });
                        }}
                        selectsStart
                        startDate={order_production.dead_line_date}
                        /*  minDate = {new Date()} */
                        /* maxDate = {order_production.date_of_production} */
                        /* endDate = {order_production.date_of_production} */
                      />
                      <label htmlFor="">กำหนดเสร็จ</label>
                      <label></label>
                    </div>
                  </div>
                  <div
                    className="col-6 col-md-2 col-xl-2 mt-2"
                    style={{
                      textAlign: "center",
                      marginTop: "1px",
                      width: "auto",
                    }}
                  >
                    {mode.mode === "add" ? (
                      <></>
                    ) : (
                      <>
                        <button
                          type="button"
                          disabled={checkMode}
                          /* data-toggle="modal"
                    data-target="#modal-chack-satatus" */
                          /* onClick={modal-chack-satatus} */
                          onClick={() => {
                            status_order();
                          }}
                          class="btn btn-block btn-success"
                        >
                          ดำเนินการเรียบร้อย
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <Footter /> */}
    </div>
  );
}
export default Production_order_CTS_add;
