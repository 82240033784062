import React, { Component, useEffect, useState } from "react";

import Swal from "sweetalert2";


import { useParams } from "react-router";

import { getOem, getToken, getUser,getAcademy } from "../../../Utils/Common";
import Configs from "../../../config";
import FormData from "form-data";
import axios from "axios";
import NumberFormat from "react-number-format";


function Factory_Choup_add(mode) {
  const [company, setCompany] = useState([]);
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);
  const [factoryChoup, setfactoryChoup] = useState({
    company_id: getUser().com,
    fac_choup_no: "",
    fac_choup_name: "",
    fac_choup_tel: "", 
  });
  const { id } = useParams();
  useEffect(() => {
    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);

  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //console.log(response.data);
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  
  useEffect(() => {
    GetCompany();

    GetFactoryChoup_view();
  }, []);


  
  

  const GetFactoryChoup_view = async () => {
     
    if ( mode.mode =="read" || mode.mode =="edit"  ) {
      axios({
        method: "get",
        url: Configs.API_URL + "/api/factory_choup/getFactoryChoup?id="+ id,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          //console.log(response.data);
          setfactoryChoup({
            
            fac_choup_no: response.data.code,
            fac_choup_name: response.data.name,
            fac_choup_tel: response.data.telnumber,
         
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    
  }
  
  
  function saveOption(params) {
    Swal.fire({
      title: 'Do you want to Save?',
      icon:'warning',
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:`No`,
    }).then((result) => {
      if (result.isConfirmed) {
    const error_list = [];
    if(factoryChoup.fac_choup_no.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [รหัสโรงงานชุบ]."
      }
      error_list.push(temp_err);
    }
    if(factoryChoup.fac_choup_name.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [โรงงานชุบ]."
      }
      error_list.push(temp_err);
    }
    if(factoryChoup.fac_choup_tel == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [เบอร์โทร]."
      }
      error_list.push(temp_err);
    }
   
    if(error_list.length > 0){
      var err_message = "";
      for(var e = 0; e < error_list.length;e++){
        err_message += "<br/>"+error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    }else{
      if (mode.mode === "add") {
        const temp ={
        
          company_id: getUser().com,
          oem_id:getOem(),
          fac_choup_no: factoryChoup.fac_choup_no.trim(),
          fac_choup_name: factoryChoup.fac_choup_name.trim(),
          fac_choup_tel: factoryChoup.fac_choup_tel,
        }
        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/api/factory_choup/addFactoryChoup",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Factory_Master_Data/Factory_Choup";
                });
              }
  
              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
      }
      if (mode.mode === "edit") {

        const temp ={
          fac_choup_id:id,
          company_id: getUser().com,
          oem_id:getOem(),
          fac_choup_no: factoryChoup.fac_choup_no.trim(),
          fac_choup_name: factoryChoup.fac_choup_name.trim(),
          fac_choup_tel: factoryChoup.fac_choup_tel,
    
        }
        //console.log(temp);
       
        Swal.fire( {
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/api/factory_choup/updateFactoryChoup",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Factory_Master_Data/Factory_Choup";
                });
              }
    
              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
       }
    }
  

  }
}
);
  }

  

  function cancleOption(params) {
    Swal.fire({
      title: 'Do you want to cancel and leave the changes?',
      icon:'warning',
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:`No`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href =
        "/Warehouse_Management/Factory_Master_Data/Factory_Choup";
      }
    }
    );
  }
  return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>โรงงานชุบ {pageMode}{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">โรงงานชุบ</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row mb-2">
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                     <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    if
                      onClick={cancleOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">โรงงานชุบ {pageMode}</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body">
            <h3 className="mt-5-head">
                  ข้อมูลโรงงานชุบ
                  </h3>
                <div className="tab-custom-content">
                 </div>
                 <br/>
              <div className="row">
              
                     

                  
              <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={factoryChoup.fac_choup_no}
                      disabled={disable}
                      onChange={(e) => {
                        setfactoryChoup({
                          ...factoryChoup,
                          fac_choup_no: e.target.value,
                        });
                      }}
                
                    />
                    <label>
                      ชื่อย่อโรงงานชุบ
                      {factoryChoup.fac_choup_no.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>

               
              </div>

              <div className="row">
              
                     

                  
              <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={factoryChoup.fac_choup_name}
                      disabled={disable}
                      onChange={(e) => {
                        setfactoryChoup({
                          ...factoryChoup,
                          fac_choup_name: e.target.value,
                        });
                      }}
                
                    />
                    <label>
                      โรงงานชุบ
                      {factoryChoup.fac_choup_name.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>

               
              </div>
              <div className="row">

              <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                
                    <NumberFormat
                      className="form-control"
                      required
                      thousandSeparator={false}
                      format={"###-###-################"}
                      value={factoryChoup.fac_choup_tel}
                      disabled={disable}
                      onChange={(e) => {
                        setfactoryChoup({
                          ...factoryChoup,
                          fac_choup_tel: e.target.value,
                        });
                      }}
                    />
                    <label>
                    Contact Tel
                    {factoryChoup.fac_choup_tel === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>

       

             
          
            
            
           
            </div>
            
          </div>
        </section>
      </div>
     {/*  <Footter/> */}
      </div>
  );
}
export default Factory_Choup_add;
