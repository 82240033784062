import { useState } from "react";
import { userdefault_img } from "../../../routes/imgRoute/imgUrl";
import {
  getOem,
  getToken,
  getUser,
  getAcademy,
  getFeature,
} from "../../../Utils/Common";
import moment from "moment";

function Assign_Work_Card({ data, DeleteAssign, CheckWork, saveCurrentDate }) {
  const [clickCollapse, setClickCollapse] = useState(true);

  return (
    <div
      style={{
        maxWidth: "419px",
        // minHeight: clickCollapse ? "256px" : "180px",
        height: "fit-content",
        width: "100%",
        backgroundColor: "white",
        boxShadow: "0 0 1px 0px",
        padding: "17px",
        borderRadius: "10px",
        transition: "all .5s ease-in-out 0s",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex" }}>
          <div
            style={{
              background: `${
                data.assign_by_status_id ===
                "f46730e7-9980-46b5-a4e5-7967550e4ea2"
                  ? "#AEAEAE"
                  : new Date(saveCurrentDate.startDate).setHours(0, 0, 0, 0) >
                    new Date(data.due_date).setHours(0, 0, 0, 0)
                  ? "#FF415C"
                  : new Date(saveCurrentDate.endDate).setHours(0, 0, 0, 0) <
                    new Date(data.due_date).setHours(0, 0, 0, 0)
                  ? "#3579F6"
                  : "#E9B500"
              }`,
              borderRadius: "28px",
              width: "25px",
              height: "43px",
              flexShrink: "0",
            }}
          />

          <div style={{ paddingLeft: "10px" }}>
            <span
              className="textVerticalOverflow-3"
              style={{
                color: `${
                  data.assign_by_status_id ===
                  "f46730e7-9980-46b5-a4e5-7967550e4ea2"
                    ? "#AEAEAE"
                    : new Date(saveCurrentDate.startDate).setHours(0, 0, 0, 0) >
                      new Date(data.due_date).setHours(0, 0, 0, 0)
                    ? "#FF415C"
                    : new Date(saveCurrentDate.endDate).setHours(0, 0, 0, 0) <
                      new Date(data.due_date).setHours(0, 0, 0, 0)
                    ? "#3579F6"
                    : "#E9B500"
                }`,
                fontSize: "16px",
                lineHeight: "normal",
                wordBreak: "break-word",
                userSelect: "none",
                // marginRight: "10px",
              }}
            >
              {data.assign_by_status_id ===
              "f46730e7-9980-46b5-a4e5-7967550e4ea2"
                ? "SUCCESSED TASK"
                : new Date(saveCurrentDate.startDate).setHours(0, 0, 0, 0) >
                  new Date(data.due_date).setHours(0, 0, 0, 0)
                ? "LATE TASK"
                : new Date(saveCurrentDate.endDate).setHours(0, 0, 0, 0) <
                  new Date(data.due_date).setHours(0, 0, 0, 0)
                ? "FUTURE TASK"
                : "CURRENT TASK"}
            </span>

            <span
              className="textVerticalOverflow-3"
              style={{
                color: `${
                  data.assign_by_status_id ===
                  "f46730e7-9980-46b5-a4e5-7967550e4ea2"
                    ? "#AEAEAE"
                    : new Date(saveCurrentDate.startDate).setHours(0, 0, 0, 0) >
                      new Date(data.due_date).setHours(0, 0, 0, 0)
                    ? "#FF415C"
                    : new Date(saveCurrentDate.endDate).setHours(0, 0, 0, 0) <
                      new Date(data.due_date).setHours(0, 0, 0, 0)
                    ? "#3579F6"
                    : "#E9B500"
                }`,
                fontSize: "24px",
                lineHeight: "normal",
                wordBreak: "break-word",
                userSelect: "none",
                // marginRight: "10px",
              }}
            >
              {moment(data.due_date).locale("en").format("MMMM DD, YYYY")}
            </span>
          </div>
        </div>

        <div
          style={{
            marginLeft: "5px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex" }}>
              <a
                href={`/Human_Resource/to_do_list/assign_work/read/${data.task_id}`}
                id="btn_read"
                key={data.task_id + "_Eye"}
                className="btn btn-xs"
                style={{
                  padding: "0px",
                  margin: "0px 0.25rem",
                  height: "15px",
                }}
              >
                {/* <i class="fas fa-eye" /> */}
                <i
                  style={{ width: "14px", aspectRatio: "1/1" }}
                  class="customSvgEye"
                />
              </a>
            </div>

            {data.assign_by_id === getUser().fup &&
            data.assign_by_status_id !==
              "f46730e7-9980-46b5-a4e5-7967550e4ea2" ? (
              <>
                <div
                  style={{
                    borderLeft: "1px solid #D9D9D9",
                    borderRight: "1px solid #D9D9D9",
                    height: "15px",
                    display: "flex",
                  }}
                >
                  <a
                    href={`/Human_Resource/to_do_list/assign_work/edit/${data.task_id}`}
                    id="btn_edit"
                    key={data.task_id + "_Pencil"}
                    className="btn btn-xs"
                    style={{
                      padding: "0px",
                      margin: "0px 0.25rem",
                      height: "15px",
                    }}
                  >
                    {/* <i class="fas fa-pencil-alt" /> */}
                    <i
                      style={{ width: "19px", aspectRatio: "1/1" }}
                      class="customSvgPencil"
                    />
                  </a>
                </div>

                <div style={{ display: "flex" }}>
                  <a
                    id="btn_del"
                    key={data.task_id + "_Bin"}
                    className="btn btn-xs"
                    style={{
                      padding: "0px",
                      margin: "0px 0.25rem",
                      height: "15px",
                    }}
                    onClick={() => {
                      DeleteAssign(data.task_id);
                    }}
                  >
                    {/* <i class="fas fa-trash-alt" /> */}
                    <i
                      style={{ width: "14px", aspectRatio: "1/1" }}
                      class="customSvgBin"
                    />
                  </a>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          borderBottom: "solid 1px #000",
          opacity: "0.1",
          margin: "5px 0px",
        }}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span
          className="textVerticalOverflow-3"
          style={{
            color: "#353A3F",
            // maxWidth: "245px",
            height: "72px",
            fontSize: "20px",
            lineHeight: "normal",
            wordBreak: "break-word",
            // marginRight: "5px",
          }}
        >
          {data.task}
        </span>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          style={{
            color: "#3579F6",
            fontSize: "20px",
            lineHeight: "normal",
            wordBreak: "break-word",
            userSelect: "none",
            cursor: "pointer",
            marginRight: "3px",
          }}
          onClick={async () => {
            setClickCollapse(!clickCollapse);
          }}
        >
          Description
        </span>
        <i
          style={{ width: "13px", aspectRatio: "1/1", marginLeft: "3px" }}
          className={`customSvgChevron ${clickCollapse ? "active" : ""}`}
          onClick={async () => {
            setClickCollapse(!clickCollapse);
          }}
        />
      </div>

      <div
        style={{
          display: "flex",
          transition: "all .5s ease-in-out 0s",
          height: clickCollapse ? "78px" : "0px",
        }}
      >
        <span
          className="textVerticalOverflow-3"
          style={{
            color: "#353A3F",
            fontSize: "20px",
            lineHeight: "normal",
            wordBreak: "break-word",
            userSelect: "none",
            whiteSpace: "pre-line",
          }}
        >
          {data.task_detail}
        </span>
      </div>

      <div
        className="row changeDirectionToDoList"
        style={{ alignItems: "center" }}
      >
        <div
          className="col"
          style={{
            flex: "1 0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            <i
              style={{ width: "12px", aspectRatio: "1/1", marginRight: "3px" }}
              className="customSvgHourglass"
            />

            <span
              className="textVerticalOverflow-3"
              style={{
                color: "#353A3F",
                fontSize: "14px",
                lineHeight: "normal",
                wordBreak: "break-word",
                userSelect: "none",
                marginRight: "10px",
                fontWeight: "700",
              }}
            >
              Due Date
            </span>

            <i
              style={{ width: "10px", aspectRatio: "1/1", marginRight: "3px" }}
              className="customSvgCalender calenderRed"
            />

            <span
              className="textVerticalOverflow-3"
              style={{
                color: "#FF415C",
                fontSize: "14px",
                lineHeight: "normal",
                wordBreak: "break-word",
                userSelect: "none",
                marginRight: "10px",
              }}
            >
              {moment(data.due_date).locale("en").format("MMMM DD, YYYY")}
            </span>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            <i
              style={{ width: "12px", aspectRatio: "1/1", marginRight: "3px" }}
              className="customSvgHourglass"
            />

            <span
              className="textVerticalOverflow-3"
              style={{
                color: "#353A3F",
                fontSize: "14px",
                lineHeight: "normal",
                wordBreak: "break-word",
                userSelect: "none",
                marginRight: "10px",
                fontWeight: "700",
              }}
            >
              Create Date
            </span>

            <i
              style={{ width: "10px", aspectRatio: "1/1", marginRight: "3px" }}
              className="customSvgCalender calenderBlue"
            />

            <span
              className="textVerticalOverflow-3"
              style={{
                color: "#3579F6",
                fontSize: "14px",
                lineHeight: "normal",
                wordBreak: "break-word",
                userSelect: "none",
                marginRight: "10px",
              }}
            >
              {moment(data.created_date).locale("en").format("MMMM DD, YYYY")}
            </span>
          </div>
        </div>

        <div
          className="col paddingButtonToDoList"
          style={{
            flex: "1 0",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "space-around",
          }}
        >
          <span
            style={{
              color: "#353A3F",
              fontSize: "13px",
              fontWeight: "700",
              lineHeight: "normal",
              paddingBottom: "3px",
            }}
          >
            Status :&nbsp;
            <span
              style={{
                color: "#353A3F",
                fontSize: "13px",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              {data.assign_by_status_name +
                (data.task_count === 0 ? "" : ` ( ${data.task_count} )`)}
            </span>
          </span>

          {data.assign_by_id === getUser().fup ? (
            data.assign_by_status_id ===
            "ffaa9180-e258-4aaa-96ab-afd4f991a572" ? (
              data.task_count === 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <button
                      type="button"
                      id="btn_search"
                      className="btn btn-block btn-secondary"
                      style={{
                        // width: "62px",
                        width: "100%",
                        height: "26px",
                        lineHeight: "normal",
                        padding: "0px",
                        margin: "0px 2.5px 0px 0px",
                        fontSize: "18px",
                      }}
                      disabled
                    >
                      Pass
                    </button>

                    <button
                      type="button"
                      id="btn_search"
                      className="btn btn-block btn-secondary"
                      style={{
                        // width: "62px",
                        width: "100%",
                        height: "26px",
                        lineHeight: "normal",
                        padding: "0px",
                        margin: "0px 0px 0px 2.5px",
                        fontSize: "18px",
                      }}
                      disabled
                    >
                      Fail
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-danger"
                    style={{
                      // width: "127px",
                      width: "100%",
                      height: "26px",
                      lineHeight: "normal",
                      padding: "0px",
                      fontSize: "18px",
                    }}
                    disabled
                  >
                    Fail
                  </button>
                </>
              )
            ) : data.assign_by_status_id ===
              "7a2a0ab3-cc81-4b17-9129-c95347337dd0" ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-success"
                    style={{
                      // width: "62px",
                      width: "100%",
                      height: "26px",
                      lineHeight: "normal",
                      padding: "0px",
                      margin: "0px 2.5px 0px 0px",
                      fontSize: "18px",
                    }}
                    onClick={() =>
                      CheckWork({
                        task_id: data.task_id,
                        assign_to_status_id:
                          "22bb6045-d8b6-44dd-8f2f-be7330c17aa9",
                        assign_by_status_id:
                          "f46730e7-9980-46b5-a4e5-7967550e4ea2",
                        task_count: data.task_count,
                      })
                    }
                  >
                    Pass
                  </button>

                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-danger"
                    style={{
                      // width: "62px",
                      width: "100%",
                      height: "26px",
                      lineHeight: "normal",
                      padding: "0px",
                      margin: "0px 0px 0px 2.5px",
                      fontSize: "18px",
                    }}
                    onClick={() =>
                      CheckWork({
                        task_id: data.task_id,
                        assign_to_status_id:
                          data.task_count === 0 || data.task_count === 1
                            ? "53140a82-3fdb-44d8-a726-77492c63c800"
                            : "dd65b3a6-9946-491f-8fd9-688113f52daf",
                        assign_by_status_id:
                          "ffaa9180-e258-4aaa-96ab-afd4f991a572",
                        task_count: data.task_count + 1,
                      })
                    }
                  >
                    Fail
                  </button>
                </div>
              </>
            ) : data.assign_by_status_id ===
              "f46730e7-9980-46b5-a4e5-7967550e4ea2" ? (
              <>
                <button
                  type="button"
                  id="btn_search"
                  className="btn btn-block btn-success"
                  style={{
                    // width: "127px",
                    width: "100%",
                    height: "26px",
                    lineHeight: "normal",
                    padding: "0px",
                    fontSize: "18px",
                  }}
                  disabled
                >
                  Passed
                </button>
              </>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>
      </div>

      <div
        style={{
          borderBottom: "solid 1px #000",
          opacity: "0.1",
          margin: "5px 0px",
        }}
      />

      <div className="row" style={{ alignItems: "center" }}>
        <div
          className="col"
          style={{
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
          }}
        >
          <i
            style={{ width: "12px", aspectRatio: "1/1", marginRight: "3px" }}
            className="customSvgClip"
          />

          <span
            className="textVerticalOverflow-3"
            style={{
              color: "#353A3F",
              fontSize: "14px",
              lineHeight: "normal",
              wordBreak: "break-word",
              userSelect: "none",
              marginRight: "10px",
              fontWeight: "700",
            }}
          >
            Assign by
          </span>

          <span
            className="textVerticalOverflow-3"
            style={{
              color: "#353A3F",
              fontSize: "14px",
              lineHeight: "normal",
              wordBreak: "break-word",
              userSelect: "none",
              marginRight: "10px",
            }}
          >
            {data.assign_by_no || ""}&nbsp;
            {data.assign_by_nickname
              ? "( " + data.assign_by_nickname + " )"
              : ""}
          </span>
        </div>

        <div
          className="col"
          style={{
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
          }}
        >
          <i
            style={{ width: "12px", aspectRatio: "1/1", marginRight: "3px" }}
            className="customSvgClip"
          />

          <span
            className="textVerticalOverflow-3"
            style={{
              color: "#353A3F",
              fontSize: "14px",
              lineHeight: "normal",
              wordBreak: "break-word",
              userSelect: "none",
              marginRight: "10px",
              fontWeight: "700",
            }}
          >
            Assign To
          </span>

          <span
            className="textVerticalOverflow-3"
            style={{
              color: "#353A3F",
              fontSize: "14px",
              lineHeight: "normal",
              wordBreak: "break-word",
              userSelect: "none",
              marginRight: "10px",
            }}
          >
            {data.assign_to_no || ""}&nbsp;
            {data.assign_by_nickname
              ? "( " + data.assign_to_nickname + " )"
              : ""}
          </span>
        </div>
      </div>
    </div>
  );
}
export default Assign_Work_Card;
