import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2"; 

import "../../../assets/css/Dashboard.css";

ChartJS.register(ArcElement, Tooltip, Legend);

function Pie_Chart(params) {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <label
          style={{
            fontSize: "23px",
            lineHeight: "normal",
            margin: "0px",
            display: "block",
            textAlign: "center",
            margin: "0px 0px 15px 0px",
          }}
        >
          {params.Text}
        </label>
        <div style={{ width: "100%", height: "100%" }}>
          <Pie data={params.DataChart} />
        </div>
      </div>
    </>
  );
}
export default Pie_Chart;
