import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import Select from "react-select";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import {
  getOem,
  getToken,
  getUser,
  getAcademy,
  getFeature,
} from "../../Utils/Common";
import NumberFormat from "react-number-format";
import { useParams } from "react-router";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import th from "date-fns/locale/th";
import { useRef } from "react";
registerLocale("th", th);
function Employee_add_Info(mode) {
  const modalRef = useRef();
  const closeModalRef = () => {
    modalRef.current.click();
  };
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(false);
  const [test, settest] = useState({
    test: "",
  });
  const [employee, setemployee] = useState({
    oem_id: getOem(),
    company_id: getUser().com,
    emp_id: "",
    emp_name_th: "",
    emp_name_eng: "",
    emp_email_company: "",
    emp_mobile: "",
    emp_birthday: "",
    emp_nickname: "",
    emp_department_id: "",
    emp_position: "",
    emp_no: "",
    emp_start_work: "",
    emp_end_work: "",
    emp_work_year: "",
    emp_work_month: "",
    emp_work_day: "",
    emp_social_security_date: "",
    emp_hospital: "",
    emp_ent_sick_leave: "",
    emp_sick_leave: "",
    emp_balance_leave: "",
    emp_ent_personal_leave: "",
    emp_person_leave: "",
    emp_balanee_pesonal: 0,
    emp_ent_vacation_leave: "",
    emp_vacation_leave: "",
    emp_balanee_vacalion: "",
    emp_period_time_id: "",
    account_name: "",
    account_number: "",
    emp_nation_id: "",
    emp_line_id: "",
    emp_salary: "",
    emp_citizen_id: "",
    employee_type: "",
    emp_level_id: "",
    emp_position_id: "",
    emp_start_work_day: "",
    emp_end_work_day: "",
    isGenAccount: null,
    employee_contract_type_id: "",
    employee_is_active: null,
    employee_status_type_id: "",
  });
  const [chaek_DMY, setchaek_DMY] = useState({
    sum_day: "",
    sum_monyh: "",
    sum_yaeh: "",
  });
  const [check_sick_leave, setcheck_sick_leave] = useState({
    sum_sick_ent: "",
    sum_sick: "",
    sum_sick_balanee: 0,
  });
  const [check_personal_leave, setcheck_personal_leave] = useState({
    sum_personal_ent: "",
    sum_personal: "",
    sum_personal_balanee: 0,
  });
  const [check_vacation_leave, setcheck_vacation_leave] = useState({
    sum_vacation_ent: "",
    sum_vacation: "",
    sum_vacation_balanee: 0,
  });
  const [YearStock, setYearStock] = useState([]);
  const [filler_start_work, setfiller_start_work] = useState({
    start_work: "",
  });
  const [filler_end_work, setfiller_end_work] = useState({
    end_work: "",
  });
  async function SetDate_start(date) {
    setfiller_start_work({ ...filler_start_work, start_work: date });
    checkYMD(date, true);
  }
  async function SetDate_end(date) {
    setfiller_end_work({ ...filler_end_work, end_work: date });
    checkYMD(date, false);
  }
  const [getemployeeType, setgetemployeeType] = useState([]);
  const [getMasterEmployeeType, setgetMasterEmployeeType] = useState([]);
  const [department, setdepartment] = useState([]);
  const [level, setlevel] = useState([]);
  const [position, setposition] = useState([]);
  const [position_dropdown, setposition_dropdown] = useState([]);
  const [getEmployeeWorkType, setgetEmployeeWorkType] = useState([]);
  const [getEmpWorkType, setgetEmpWorkType] = useState([]);
  const [getEmpStatusType, setgetEmpStatusType] = useState([]);
  const [feature, setfeature] = useState([]);
  let [leaveAllYear, setleaveAllYear] = useState([]);
  const [leavecurrent, setleavecurrent] = useState({
    ent_year: moment(new Date()).format("YYYY"),
    ent_sick_leave: "",
    ent_personal_leave: "",
    ent_vacation_leave: "",
    mode: null,
    id: "",
  });

  const { id } = useParams();
  useEffect(() => {
    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);
  const [empployee_id, setEmpployee_id] = useState({
    data: "",
  });
  useEffect(() => {
    // console.log(mode.DATA);
    if (sessionStorage.getItem("emTTT")) {
      const idEmp = sessionStorage.getItem("emTTT");
      setEmpployee_id({
        data: idEmp,
      });
      empployee_id.data = idEmp;
      // sessionStorage.removeItem("emTTT");
    }

    // console.log(mode);
    if (mode.saveStatus === false && mode.clearStatus === true) {
      if (mode.DATA.employee_type_status_id === "9437e6c7-68aa-4ee6-b7da-4b54b14c07ba") {
        setchaek_DMY({
          sum_day: mode.DATA.emp_work_day || 0,
          sum_monyh: mode.DATA.emp_work_month || 0,
          sum_yaeh: mode.DATA.emp_work_year || 0,
        });  
      } else {
        checkYMD(mode.DATA.start_work_day, true)
      }

      setcheck_sick_leave({
        sum_sick: mode.DATA.emp_sick_leave,
        sum_sick_ent: mode.DATA.emp_ent_sick_leave,
      });

      setcheck_personal_leave({
        sum_personal: mode.DATA.emp_personal_leave,
        sum_personal_ent: mode.DATA.emp_ent_personal_leave,
      });

      setcheck_vacation_leave({
        sum_vacation: mode.DATA.emp_vacation_leave,
        sum_vacation_ent: mode.DATA.emp_ent_vacation_leave,
      });

      if (mode.DATA.emp_department_id) {
        let temp = position.filter(
          (el) => el.dep_id === mode.DATA.emp_department_id || ""
        );
        // console.log('temp', temp);
        setposition_dropdown(temp);
      }

      setemployee({
        ...employee,
        emp_id: mode.DATA.id,
        emp_name_th: mode.DATA.emp_name_th,
        emp_name_eng: mode.DATA.emp_name_eng || null,
        emp_email_company: mode.DATA.emp_email_company || "",
        emp_mobile: mode.DATA.emp_mobile || "",
        emp_birthday: mode.DATA.emp_birthday
          ? new Date(mode.DATA.emp_birthday)
          : "",
        emp_nickname: mode.DATA.emp_nickname || "",
        emp_department_id: mode.DATA.emp_department_id,
        emp_position: mode.DATA.emp_position || "",
        emp_no: mode.DATA.emp_no || "",
        emp_start_work: mode.DATA.emp_start_work
          ? new Date(mode.DATA.emp_start_work)
          : "",
        emp_end_work: mode.DATA.emp_end_work
          ? new Date(mode.DATA.emp_end_work)
          : "",
        emp_social_security_date: mode.DATA.emp_social_security_date
          ? new Date(mode.DATA.emp_social_security_date)
          : "",
        emp_hospital: mode.DATA.emp_hospital || "",
        emp_ent_sick_leave: mode.DATA.emp_ent_sick_leave || "0",
        emp_sick_leave: mode.DATA.emp_sick_leave || "0",
        emp_balance_leave: parseFloat(mode.DATA.emp_balance_leave || "0"),
        emp_ent_personal_leave: mode.DATA.emp_ent_personal_leave || "0",
        emp_person_leave: mode.DATA.emp_person_leave || "0",
        emp_balanee_pesonal: parseFloat(mode.DATA.emp_balance_personal || "0"),
        emp_ent_vacation_leave: mode.DATA.emp_ent_vacation_leave || "0",
        emp_vacation_leave: mode.DATA.emp_vacation_leave || "0",
        emp_balanee_vacalion: parseFloat(mode.DATA.emp_balance_vacation || "0"),
        emp_period_time_id: mode.DATA.emp_period_time_id,
        account_name: mode.DATA.account_name || "",
        account_number: mode.DATA.account_number || "",
        emp_nation_id: mode.DATA.emp_nation_id || "",
        emp_line_id: mode.DATA.emp_line_id || "",
        emp_salary: mode.DATA.emp_salary || "",
        emp_citizen_id: mode.DATA.citizen_id || "",
        employee_type: mode.DATA.employee_type || "",
        emp_level_id: mode.DATA.level_id || "",
        emp_position_id: mode.DATA.position_id || "",
        emp_start_work_day: mode.DATA.start_work_day
          ? new Date(mode.DATA.start_work_day)
          : "",
        employee_contract_type_id: mode.DATA.employee_contract_type_id || "",
        employee_is_active: mode.DATA.is_active || false,
        employee_status_type_id: mode.DATA.employee_type_status_id || "",
      });

      employee.emp_id = mode.DATA.id;
      employee.emp_name_th = mode.DATA.emp_name_th;
      employee.emp_name_eng = mode.DATA.emp_name_eng || null;
      employee.emp_email_company = mode.DATA.emp_email_company || "";
      employee.emp_mobile = mode.DATA.emp_mobile || "";
      employee.emp_birthday = mode.DATA.emp_birthday
        ? new Date(mode.DATA.emp_birthday)
        : "";
      employee.emp_nickname = mode.DATA.emp_nickname || "";
      employee.emp_department_id = mode.DATA.emp_department_id;
      employee.emp_position = mode.DATA.emp_position || "";
      employee.emp_no = mode.DATA.emp_no || "";
      employee.emp_start_work = mode.DATA.emp_start_work
        ? new Date(mode.DATA.emp_start_work)
        : "";
      employee.emp_end_work = mode.DATA.emp_end_work
        ? new Date(mode.DATA.emp_end_work)
        : "";
      employee.emp_social_security_date = mode.DATA.emp_social_security_date
        ? new Date(mode.DATA.emp_social_security_date)
        : "";
      employee.emp_hospital = mode.DATA.emp_hospital || "";
      employee.emp_ent_sick_leave = mode.DATA.emp_ent_sick_leave || "0";
      employee.emp_sick_leave = mode.DATA.emp_sick_leave || "0";
      employee.emp_balance_leave = parseFloat(
        mode.DATA.emp_balance_leave || "0"
      );
      employee.emp_ent_personal_leave = mode.DATA.emp_ent_personal_leave || "0";
      employee.emp_person_leave = mode.DATA.emp_person_leave || "0";
      employee.emp_balanee_pesonal = parseFloat(
        mode.DATA.emp_balance_personal || "0"
      );
      employee.emp_ent_vacation_leave = mode.DATA.emp_ent_vacation_leave || "0";
      employee.emp_vacation_leave = mode.DATA.emp_vacation_leave || "0";
      employee.emp_balanee_vacalion = parseFloat(
        mode.DATA.emp_balance_vacation || "0"
      );
      employee.emp_period_time_id = mode.DATA.emp_period_time_id;
      employee.account_name = mode.DATA.account_name || "";
      employee.account_number = mode.DATA.account_number || "";
      employee.emp_nation_id = mode.DATA.emp_nation_id || "";
      employee.emp_line_id = mode.DATA.emp_line_id || "";
      employee.emp_salary = mode.DATA.emp_salary || "";
      employee.emp_citizen_id = mode.DATA.citizen_id || "";
      employee.employee_type = mode.DATA.employee_type || "";
      employee.emp_level_id = mode.DATA.level_id || "";
      employee.emp_position_id = mode.DATA.position_id || "";
      employee.emp_start_work_day = mode.DATA.start_work_day
        ? new Date(mode.DATA.start_work_day)
        : "";
      employee.employee_contract_type_id =
        mode.DATA.employee_contract_type_id || "";
      employee.employee_is_active = mode.DATA.is_active || false;

      Check_sick_leave();
    }
    // if (employee.emp_id) {
    //   CheckGenAccount();
    // }

    if (mode.saveStatus === true) {
      // console.log("mode.saveStatus", mode.saveStatus);
      setemployee({
        ...employee,
        emp_name_th: mode.DataEdit.emp_name_th,
        emp_name_eng: mode.DataEdit.emp_name_eng || null,
        emp_mobile: mode.DataEdit.emp_mobile || "",
        emp_birthday: mode.DataEdit.emp_birthday
          ? new Date(mode.DataEdit.emp_birthday)
          : "",
        emp_nickname: mode.DataEdit.emp_nickname || "",
        emp_nation_id: mode.DataEdit.emp_nation_id || "",
        emp_line_id: mode.DataEdit.emp_line_id || "",
        emp_citizen_id: mode.DataEdit.citizen_id || "",
      });
      employee.emp_name_th = mode.DataEdit.emp_name_th;
      employee.emp_name_eng = mode.DataEdit.emp_name_eng || null;
      employee.emp_mobile = mode.DataEdit.emp_mobile || "";
      employee.emp_birthday = mode.DataEdit.emp_birthday
        ? new Date(mode.DataEdit.emp_birthday)
        : "";
      employee.emp_nickname = mode.DataEdit.emp_nickname || "";
      employee.emp_nation_id = mode.DataEdit.emp_nation_id || "";
      employee.emp_line_id = mode.DataEdit.emp_line_id || "";
      employee.emp_citizen_id = mode.DataEdit.citizen_id || "";
      saveLeaveAllYearByEmp(empployee_id.data);
      saveOption();
      mode.setClearStatus(false);
      // mode.setSaveStatus(false);
    }
  }, [mode.DATA, mode.saveStatus]);

  useEffect(() => {
    mode.setfunctionSaveHR({
      ...mode.functionSaveHR,
      data: Error,
    });
  }, [employee]);

  useEffect(() => {
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "ข้อมูลพนักงาน";
    });
    setfeature(feature_data);
    GetMasterEmployeeType();
    GetEmployeeType();
    Getdepartment();
    GetEmployeeWorkType();
    getEmpWorkTypefilter();
    getLeaveAllYearByEmp();
    getEmpStatusTypefilter();
    let lvFea = feature_data.filter((e) => {
      return e.feature === "employee_level";
    });
    if (lvFea.length !== 0) {
      Getlevel();
      GetPosition();
    }

    var this_yaer = parseInt(moment(new Date()).format("YYYY"));

    var temp_year = [];
    temp_year.push(this_yaer);
    for (var index = 0; index < 5; index++) {
      //var old_year = this_yaer - (index + 1);
      var next_year = this_yaer + (index + 1);
      // temp_year.push(old_year);
      temp_year.push(next_year);
    }

    temp_year.sort((a, b) => a - b);
    setYearStock(temp_year);
  }, []);

  var lvFea = feature.filter((e) => {
    return e.feature === "employee_level";
  });

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
        data-provide="datepicker"
        data-date-language="th-th"
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>

        {/*  <label>test</label> */}
      </div>
    </div>
  );

  const CustomInput2 = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
        data-provide="datepicker"
        data-date-language="th-th"
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>

        {/*  <label>test</label> */}
      </div>
    </div>
  );

  const Getdepartment = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
    };

    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement + "/api/hrManagement/filterDepartment",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        /* console.log(response.data.data,"sadj",); */
        const options = [{ value: "", label: "Select" }];
        response.data.data.forEach((el) => {
          let element = {};
          // console.log('el', el);
          element["value"] = el.department_id;
          element["label"] = el.department_name;
          options.push(element);
        });
        setdepartment(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const Getlevel = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
    };

    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterLevel",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        /* console.log(response.data.data,"sadj",); */
        setlevel(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetPosition = async () => {
    let get_com_oem = {
      company_id: getUser().com,
    };

    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterPosition",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        // console.log(response.data.data);
        const options = [{ value: "", label: "Select", dep_id: "" }];
        response.data.data.forEach((el) => {
          let element = {};
          // console.log('el', el);
          element["value"] = el.id;
          element["label"] = el.position_name_th;
          element["dep_id"] = el.department_id;
          options.push(element);
        });
        setposition(options);
        setposition_dropdown(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetEmployeeType = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
    };

    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getEmployeeType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        /* console.log(response.data.data,"ddd",); */
        setgetemployeeType(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetMasterEmployeeType = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
    };

    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getMasterEmployeeType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        /* console.log(response.data.data,"xx",); */
        setgetMasterEmployeeType(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetEmployeeWorkType = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
    };

    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getEmployeeWorkType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        // console.log(response.data.data,"ddd",);
        setgetEmployeeWorkType(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [startWork, setStartWork] = useState({
    start1: false,
    start2: false,
  });

  /*  console.log(employee,"sasssd"); */
  function getEmpWorkTypefilter() {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getEmpWorkType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        // console.log(response.data.data);
        setgetEmpWorkType(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getEmpStatusTypefilter() {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement + "/api/hrManagement/getStatusWorkType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        // console.log(response.data.data);
        setgetEmpStatusType(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  async function Error() {
    const error_list = [];

    if (employee.emp_citizen_id == "") {
      let temp_err = {
        message: "กรุณากรอก รหัสบัตรประชาชน",
      };
      error_list.push(temp_err);
    }

    if (employee.emp_no == "") {
      let temp_err = {
        message: "กรุณากรอก รหัสพนักงาน",
      };
      error_list.push(temp_err);
    }

    if (employee.level_id == "") {
      let temp_err = {
        message: "กรุณาเลือก ระดับ",
      };
      error_list.push(temp_err);
    }

    if (lvFea.length !== 0) {
      if (employee.emp_position_id == "") {
        let temp_err = {
          message: "กรุณาเลือก ตำแหน่ง",
        };
        error_list.push(temp_err);
      }
    } else {
      if (employee.emp_nickname?.trim() == "") {
        let temp_err = {
          message: "กรุณากรอก ชื่อเล่น",
        };
        error_list.push(temp_err);
      }

      if (employee.emp_position?.trim() == "") {
        let temp_err = {
          message: "กรุณากรอก ตำแหน่ง",
        };
        error_list.push(temp_err);
      }
    }

    if (
      employee.emp_department_id == "" ||
      employee.emp_department_id == null
    ) {
      let temp_err = {
        message: "กรุณาเลือก แผนก",
      };
      error_list.push(temp_err);
    }

    if (employee.emp_department_id == "") {
      let temp_err = {
        message: "กรุณาเลือก ฝ่าย",
      };
      error_list.push(temp_err);
    }

    if (employee.emp_start_work_day == "") {
      let temp_err = {
        message: "กรุณาเลือก วันที่เริ่มงาน",
      };
      error_list.push(temp_err);
    }

    if (
      employee.employee_status_type_id ===
        "9437e6c7-68aa-4ee6-b7da-4b54b14c07ba" &&
      employee.emp_end_work === ""
    ) {
      let temp_err = {
        message: "กรุณาเลือก วันที่สิ้นสุดบรรจุพนักงาน",
      };
      error_list.push(temp_err);
    }
    mode.setfunctionCheckErrorHR({
      ...mode.functionCheckErrorHR,
      HR: error_list,
    });
    mode.functionCheckErrorHR.HR = error_list;
  }
  async function saveOption(params) {
    const error_list = [];

    /* if (employee.emp_ent_sick_leave == "") {
      let temp_err = {
        message: "กรุณากรอก สิทธิ์วันลาป่วย",
      };
      error_list.push(temp_err);
    } */

    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      // mode.setSaveStatus(false);
      mode.setSaveStatus(false);
      mode.setGenLink({ data: false });
      mode.genLink.data = false;

      Swal.fire("Error", err_message, "error");
    } else {
      let is_active_emp = true;
      if (
        "9437e6c7-68aa-4ee6-b7da-4b54b14c07ba" ===
        employee.employee_status_type_id
      ) {
        is_active_emp = false;
      }
      if (mode.mode === "add") {
        const temp = {
          oem_id: getOem(),
          company_id: getUser().com,
          emp_name_th: employee.emp_name_th.trim(),
          emp_name_eng: employee.emp_name_eng || null,
          emp_email_company: employee.emp_email_company.trim() || null,
          emp_mobile: employee.emp_mobile.trim() || null,
          emp_birthday: employee.emp_birthday || null,
          emp_nickname: employee.emp_nickname.trim() || null,
          emp_department_id: employee.emp_department_id || null,
          emp_position: employee.emp_position.trim(),
          emp_no: employee.emp_no.trim(),
          emp_start_work: employee.emp_start_work || null,
          emp_end_work: employee.emp_end_work || null,
          emp_work_year: chaek_DMY.sum_yaeh || "0",
          emp_work_month: chaek_DMY.sum_monyh || "0",
          emp_work_day: chaek_DMY.sum_day || "0",
          emp_social_security_date: employee.emp_social_security_date || null,
          emp_hospital: employee.emp_hospital.trim(),
          emp_ent_sick_leave: employee.emp_ent_sick_leave,
          emp_ent_personal_leave: employee.emp_ent_personal_leave,
          emp_ent_vacation_leave: employee.emp_ent_vacation_leave,

          emp_period_time_id: employee.emp_period_time_id,
          account_name: employee.account_name.trim(),
          account_number: employee.account_number.trim(),
          employee_nation_id: employee.emp_nation_id,
          emp_line_id: employee.emp_line_id.trim(),
          emp_salary: Number(String(employee.emp_salary).replace(/,/g, "")),
          emp_citizen_id: employee.emp_citizen_id,

          employee_type: employee.employee_type || null,
          emp_level_id: employee.emp_level_id || null,
          emp_position_id: employee.emp_position_id || null,
          emp_start_work_day: employee.emp_start_work_day || null,
          emp_end_work_day: employee.emp_end_work_day || null,
          employee_contract_type_id: employee.employee_contract_type_id || null,
          employee_is_active: is_active_emp,
          employee_status_type_id: employee.employee_status_type_id || null,
        };
        // console.log(employee.emp_work_month, employee.emp_work_year);
        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url:
              Configs.API_URL_hrMagenatement + "/api/hrManagement/addEmployee",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  // window.location.reload();
                  mode.setSaveStatus(false);
                  window.location.href =
                    "/Human_Resource/ข้อมูลพนักงาน/Employee";
                });
              }
            })
            .catch(function (error) {
              console.log(error);
              // mode.setSaveStatus(false);
              Swal.fire({
                icon: "warning",
                title: "กรุณากรอกข้อมูลใหม่",
                text: error.response.data.message,
              });
            });
        });
      }
      if (mode.mode === "edit") {
        const temp = {
          emp_id: id,
          oem_id: getOem(),
          company_id: getUser().com,
          emp_name_th: employee.emp_name_th.trim(),
          emp_name_eng: employee.emp_name_eng || null,
          emp_email_company: employee.emp_email_company.trim() || null,
          emp_mobile: employee.emp_mobile.trim() || null,
          emp_birthday: employee.emp_birthday,
          emp_nickname: employee.emp_nickname.trim() || null,
          emp_department_id: employee.emp_department_id,
          emp_position: employee.emp_position.trim() || null,
          emp_no: employee.emp_no.trim() || null,
          emp_start_work: employee.emp_start_work,
          emp_end_work: employee.emp_end_work,
          emp_work_year: chaek_DMY.sum_yaeh || "0",
          emp_work_month: chaek_DMY.sum_monyh || "0",
          emp_work_day: chaek_DMY.sum_day || "0",
          emp_social_security_date: employee.emp_social_security_date,
          emp_hospital: employee.emp_hospital.trim() || null,
          emp_ent_sick_leave: employee.emp_ent_sick_leave,
          emp_ent_personal_leave: employee.emp_ent_personal_leave,
          emp_ent_vacation_leave: employee.emp_ent_vacation_leave,

          emp_period_time_id: employee.emp_period_time_id,
          account_name: employee.account_name.trim() || null,
          account_number: employee.account_number.trim() || null,
          emp_nation_id: employee.emp_nation_id,
          emp_line_id: employee.emp_line_id.trim() || null,
          emp_salary: Number(String(employee.emp_salary).replace(/,/g, "")),
          emp_citizen_id: employee.emp_citizen_id.trim() || null,

          employee_type: employee.employee_type || null,
          emp_level_id: employee.emp_level_id || null,
          emp_position_id: employee.emp_position_id || null,
          emp_start_work_day: employee.emp_start_work_day || null,
          emp_end_work_day: employee.emp_end_work_day || null,
          employee_contract_type_id: employee.employee_contract_type_id || null,
          employee_is_active: is_active_emp,
          employee_status_type_id: employee.employee_status_type_id || null,
        };
        // console.log("Ss", temp);
        // Swal.fire({
        //   title: "Saving",
        //   allowEscapeKey: false,
        //   allowOutsideClick: false,
        //   timer: 1000,
        //   onOpen: () => {
        //     Swal.showLoading();
        //   },
        // }).then((result) => {
        axios({
          method: "post",
          url:
            Configs.API_URL_hrMagenatement + "/api/hrManagement/editEmployee",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(async function (response) {
            if (response.data) {
              // window.location.href = "/Human_Resource/ข้อมูลพนักงาน/Employee";
              if (mode.genLink.data === true) {
                mode.Generate_Account();
                mode.setGenLink({ data: false });
                mode.setSaveStatus(false);
                mode.genLink.data = false;
              } else {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then(async (result) => {
                  mode.setSaveStatus(false);
                  window.location.href =
                    "/Human_Resource/ข้อมูลพนักงาน/Employee";
                  // window.location.reload();
                });
              }
            }
            mode.setGenLink({ data: false });
            mode.genLink.data = false;
            //console.log(response.data);
          })
          .catch(function (error) {
            console.log(error);
            // mode.setSaveStatus(false);

            Swal.fire({
              icon: "warning",
              title: "อัพเดทข้อมูลไม่สำเร็จ",
              text:
                error.response.data.error.message == "no has already!!!"
                  ? "มีรหัสพนักงานซ้ำกันในระบบ"
                  : "ไม่พบรหัสบัตรประชาชนที่ตรงกัน",
            });
            mode.setGenLink({ data: false });
            mode.genLink.data = false;
            mode.setSaveStatus(false);
            return;
          });
        // });
      }
    }
  }

  function checkYMD(date, check) {
    var sum_day = 0;
    var sum_monyh = 0;
    var sum_yaeh = 0;
    if (check === false) {
      employee.emp_end_work = moment(date).format();
      var emp_s_d = moment(
        filler_start_work.start_work || employee.emp_start_work
      ).format("DD-MM-YYYY");
      var emp_e_y = moment(date).format("DD-MM-YYYY");
    } else {
      if (filler_end_work.end_work !== "" || employee.emp_end_work !== "") {
        employee.emp_start_work = moment(date).format();
        var emp_s_d = moment(date).format("DD-MM-YYYY");
        var emp_e_y = moment(
          filler_end_work.end_work || employee.emp_end_work
        ).format("DD-MM-YYYY");
      } else {
        var emp_s_d = moment(date).format("DD-MM-YYYY");
        var emp_e_y = moment(new Date()).format("DD-MM-YYYY");
      }
      // employee.emp_start_work = moment(date).format();
      // var emp_s_d = moment(date).format("DD-MM-YYYY");
      // var emp_e_y = moment(
      //   filler_end_work.end_work || employee.emp_end_work
      // ).format("DD-MM-YYYY");
    }
    var date1 = moment(emp_s_d, "DD-MM-YYYY");
    var date2 = moment(emp_e_y, "DD-MM-YYYY");

    var sum_yaeh = date2.diff(date1, "years");
    date1.add(sum_yaeh, "years");
    var sum_monyh = date2.diff(date1, "months");
    date1.add(sum_monyh, "months");
    var sum_day = date2.diff(date1, "days");

    setchaek_DMY({
      sum_day: sum_day,
      sum_monyh: sum_monyh,
      sum_yaeh: sum_yaeh,
    });
    /* console.log(years,months,days,"sss") */
  }

  function Check_sick_leave(num1, check) {
    if (check === true) {
      var sum_sick = parseFloat(num1 || 0);
      var sum_sick_ent = 0;
    } else {
      var sum_sick = 0;
      var sum_sick_ent = parseFloat(num1 || 0);
    }

    /* console.log(sum_sick,sum_sick_ent,check_sick_leave.sum_sick_ent,check_sick_leave.sum_sick_balanee,"sss2")  */
    if (sum_sick_ent === 0 && sum_sick === 0) {
      if (check_sick_leave.sum_sick !== 0 && check === true) {
        var sum_sick_balanee = parseFloat(0 - check_sick_leave.sum_sick_ent);
      } else if (check_sick_leave.sum_sick_ent !== 0 && check === false) {
        var sum_sick_balanee =
          parseFloat(check_sick_leave.sum_sick_ent) +
          parseFloat(employee.emp_balance_leave);
      } else {
        var sum_sick_balanee = 0;
      }
    } else if (sum_sick === 0 && sum_sick_ent !== 0) {
      var sum_sick_balanee = parseFloat(
        check_sick_leave.sum_sick - sum_sick_ent
      );
    } else if (sum_sick_ent === 0 && sum_sick !== 0) {
      var sum_sick_balanee = parseFloat(
        sum_sick - check_sick_leave.sum_sick_ent
      );
    }

    if (sum_sick == 0 && sum_sick_ent !== 0) {
      employee.emp_ent_sick_leave = sum_sick_ent;
      employee.emp_sick_leave = check_sick_leave.sum_sick;
      employee.emp_balance_leave = sum_sick_balanee;
    } else if (sum_sick_ent == 0 && sum_sick !== 0) {
      employee.emp_ent_sick_leave = check_sick_leave.sum_sick_ent;
      employee.emp_sick_leave = sum_sick;
      employee.emp_balance_leave = sum_sick_balanee;
    } else if (
      sum_sick_ent == 0 &&
      sum_sick === 0 &&
      check_sick_leave.sum_sick_ent !== 0
    ) {
      employee.emp_ent_sick_leave = sum_sick_ent;
      employee.emp_sick_leave = check_sick_leave.sum_sick;
      employee.emp_balance_leave = sum_sick_balanee;
    } else if (
      sum_sick_ent == 0 &&
      sum_sick === 0 &&
      check_sick_leave.sum_sick !== 0
    ) {
      employee.emp_ent_sick_leave = check_sick_leave.sum_sick_ent;
      employee.emp_sick_leave = sum_sick;
      employee.emp_balance_leave = sum_sick_balanee;
    } else if (
      sum_sick_ent == 0 &&
      sum_sick === 0 &&
      (check_sick_leave.sum_sick_ent === 0 ||
        check_sick_leave.sum_sick_ent == 0)
    ) {
      employee.emp_ent_sick_leave = sum_sick_ent;
      employee.emp_sick_leave = sum_sick;
      employee.emp_balance_leave = sum_sick_balanee;
    }

    check_sick_leave.sum_sick_balanee = sum_sick_balanee;
    check_sick_leave.sum_sick = sum_sick;
    check_sick_leave.sum_sick_ent = sum_sick_ent;
    // console.log(check_sick_leave, "sss");
  }

  function Check_personal_leave(num1, check) {
    if (check === true) {
      var sum_personal = parseFloat(num1 || 0);
      var sum_personal_ent = 0;
    } else {
      var sum_personal = 0;
      var sum_personal_ent = parseFloat(num1 || 0);
    }

    if (sum_personal_ent === 0 && sum_personal === 0) {
      if (check_personal_leave.sum_personal !== 0 && check === true) {
        var sum_personal_balanee = parseFloat(
          0 - check_personal_leave.sum_personal_ent
        );
      } else if (
        check_personal_leave.sum_personal_ent !== 0 &&
        check === false
      ) {
        var sum_personal_balanee =
          parseFloat(check_personal_leave.sum_personal_ent) +
          parseFloat(employee.emp_balanee_pesonal);
      } else {
        var sum_personal_balanee = 0;
      }
    } else if (sum_personal === 0 && sum_personal_ent !== 0) {
      var sum_personal_balanee = parseFloat(
        check_personal_leave.sum_personal - sum_personal_ent
      );
    } else if (sum_personal_ent === 0 && sum_personal !== 0) {
      var sum_personal_balanee = parseFloat(
        sum_personal - check_personal_leave.sum_personal_ent
      );
    }
    if (sum_personal == 0 && sum_personal_ent !== 0) {
      employee.emp_ent_personal_leave = sum_personal_ent;
      employee.emp_person_leave = check_personal_leave.sum_personal;
      employee.emp_balanee_pesonal = sum_personal_balanee;
    } else if (sum_personal_ent == 0 && sum_personal !== 0) {
      employee.emp_ent_personal_leave = check_personal_leave.sum_personal_ent;
      employee.emp_person_leave = sum_personal;
      employee.emp_balanee_pesonal = sum_personal_balanee;
    } else if (
      sum_personal_ent == 0 &&
      sum_personal === 0 &&
      check_personal_leave.sum_personal_ent !== 0
    ) {
      employee.emp_ent_personal_leave = sum_personal_ent;
      employee.emp_person_leave = check_personal_leave.sum_personal;
      employee.emp_balanee_pesonal = sum_personal_balanee;
    } else if (
      sum_personal_ent == 0 &&
      sum_personal === 0 &&
      check_personal_leave.sum_personal !== 0
    ) {
      employee.emp_ent_personal_leave = check_personal_leave.sum_personal_ent;
      employee.emp_person_leave = sum_personal;
      employee.emp_balanee_pesonal = sum_personal_balanee;
    } else if (
      sum_personal_ent == 0 &&
      sum_personal === 0 &&
      (check_personal_leave.sum_personal === 0 ||
        check_personal_leave.sum_personal_ent === 0)
    ) {
      employee.emp_ent_personal_leave = sum_personal_ent;
      employee.emp_person_leave = sum_personal;
      employee.emp_balanee_pesonal = sum_personal_balanee;
    }
    check_personal_leave.sum_personal_balanee = sum_personal_balanee;
    check_personal_leave.sum_personal = sum_personal;
    check_personal_leave.sum_personal_ent = sum_personal_ent;
  }

  function Check_vacation_leave(num1, check) {
    if (check === true) {
      var sum_vacation = parseFloat(num1 || 0);
      var sum_vacation_ent = 0;
    } else {
      var sum_vacation = 0;
      var sum_vacation_ent = parseFloat(num1 || 0);
    }

    if (sum_vacation_ent === 0 && sum_vacation === 0) {
      if (check_vacation_leave.sum_vacation !== 0 && check === true) {
        var sum_vacation_balanee = parseFloat(
          0 - check_vacation_leave.sum_vacation_ent
        );
      } else if (
        check_vacation_leave.sum_vacation_ent !== 0 &&
        check === false
      ) {
        var sum_vacation_balanee =
          parseFloat(check_vacation_leave.sum_personal_ent) +
          parseFloat(employee.sum_vacation_balanee);
      } else {
        var sum_vacation_balanee = 0;
      }
    } else if (sum_vacation === 0 && sum_vacation_ent !== 0) {
      var sum_vacation_balanee = parseFloat(
        check_vacation_leave.sum_vacation - sum_vacation_ent
      );
    } else if (sum_vacation_ent === 0 && sum_vacation !== 0) {
      var sum_vacation_balanee = parseFloat(
        sum_vacation - check_vacation_leave.sum_vacation_ent
      );
    }
    if (sum_vacation == 0 && sum_vacation_ent !== 0) {
      employee.emp_ent_vacation_leave = sum_vacation_ent;
      employee.emp_vacation_leave = check_vacation_leave.sum_vacation;
      employee.emp_balanee_vacalion = sum_vacation_balanee;
    } else if (sum_vacation_ent == 0 && sum_vacation !== 0) {
      employee.emp_ent_vacation_leave = check_vacation_leave.sum_vacation_ent;
      employee.emp_vacation_leave = sum_vacation;
      employee.emp_balanee_vacalion = sum_vacation_balanee;
    } else if (
      sum_vacation_ent == 0 &&
      sum_vacation === 0 &&
      check_vacation_leave.sum_vacation_ent !== 0
    ) {
      employee.emp_ent_vacation_leave = sum_vacation_ent;
      employee.emp_vacation_leave = check_vacation_leave.sum_vacation;
      employee.emp_balanee_vacalion = sum_vacation_balanee;
    } else if (
      sum_vacation_ent == 0 &&
      sum_vacation === 0 &&
      check_vacation_leave.sum_vacation !== 0
    ) {
      employee.emp_ent_vacation_leave = check_vacation_leave.sum_vacation_ent;
      employee.emp_vacation_leave = sum_vacation;
      employee.emp_balanee_vacalion = sum_vacation_balanee;
    } else if (
      sum_vacation_ent == 0 &&
      sum_vacation === 0 &&
      (check_vacation_leave.sum_vacation === 0 ||
        check_vacation_leave.sum_vacation_ent === 0)
    ) {
      employee.emp_ent_vacation_leave = sum_vacation_ent;
      employee.emp_vacation_leave = sum_vacation;
      employee.emp_balanee_vacalion = sum_vacation_balanee;
    }

    check_vacation_leave.sum_vacation_balanee = sum_vacation_balanee;
    check_vacation_leave.sum_vacation = sum_vacation;
    check_vacation_leave.sum_vacation_ent = sum_vacation_ent;
  }

  function cancleOption(params) {
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "/Human_Resource/ข้อมูลพนักงาน/Employee";
      }
    });
  }

  // const CheckGenAccount = async () => {
  //   await axios({
  //     method: "get",
  //     url:
  //       Configs.API_URL +
  //       "/users/checkGenerateAccountHR/?id=" +
  //       employee.emp_id,
  //     headers: {
  //       Authorization: getToken(),
  //       "X-TTT": Configs.API_TTT,
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then(function (response) {
  //       // console.log(response.data);
  //       setemployee({
  //         ...employee,
  //         isGenAccount: response.data.isGenAccount,
  //       });
  //       employee.isGenAccount = response.data.isGenAccount;
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  // const Generate_Account = async () => {
  //   Swal.fire({
  //     title: "ต้องการที่จะ Generate Account ใช่หรือไม่",
  //     icon: "info",
  //     showCancelButton: true,
  //     confirmButtonText: `ใช่`,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     cancelButtonText: `ไม่`,
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       // console.log(emp_list);
  //       var temp = {
  //         emp_id: [
  //           {
  //             emp_id: employee.emp_id,
  //             emp_name_eng: employee.emp_name_eng,
  //             emp_email_company: employee.emp_email_company,
  //             emp_mobile: employee.emp_mobile,
  //           },
  //         ],
  //         company_id: getUser().com,
  //         oem_id: getOem(),
  //       };
  //       console.log(temp);
  //       axios({
  //         method: "post",
  //         url: Configs.API_URL + "/users/Generate_Account",
  //         headers: {
  //           Authorization: getToken(),
  //           "X-TTT": Configs.API_TTT,
  //           "Content-Type": "application/json",
  //         },
  //         data: temp,
  //       })
  //         .then(function (response) {
  //           Swal.fire({
  //             icon: "success",
  //             title: "Save",
  //             showConfirmButton: false,
  //             timer: 1500,
  //           }).then((result) => {
  //             window.location.reload();
  //           });
  //         })
  //         .catch(function (error) {
  //           Swal.fire("Message", error.response.data.message, "warning");
  //           console.log(error);
  //         });
  //     }
  //   });
  // };

  // function resetPassword(params) {
  //   Swal.fire({
  //     title: "ต้องการที่จะ Reset Password ใช่หรือไม่",
  //     icon: "info",
  //     showCancelButton: true,
  //     confirmButtonText: `ใช่`,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     cancelButtonText: `ไม่`,
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       axios({
  //         method: "get",
  //         url: Configs.API_URL + "/users/resetPassword/?id=" + id,
  //         headers: {
  //           Authorization: getToken(),
  //           "X-TTT": Configs.API_TTT,
  //           "Content-Type": "application/json",
  //         },
  //       })
  //         .then(function (response) {
  //           if (response.data === true) {
  //             Swal.fire({
  //               icon: "success",
  //               title: "Save",
  //               showConfirmButton: false,
  //               timer: 1500,
  //             }).then((result) => {
  //               window.location.reload();
  //             });
  //           }
  //         })
  //         .catch(function (error) {
  //           Swal.fire("Message", error.response.data.message, "warning");
  //           console.log(error);
  //         });
  //     }
  //   });
  // }

  async function setdelYearLeave(mode, id, year) {
    Swal.fire({
      title: "ระบบ",
      text: "คุณต้องการลบสิทธิ์ลาใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่",
    }).then((result) => {
      if (result.isConfirmed) {
        const listOfleave = leaveAllYear;
        // console.log('id', id);
        setleaveAllYear([]);
        // console.log("leavecurrent_del", leavecurrent);

        if (mode === "del") {
          const indexOf1 = listOfleave.findIndex((e) => {
            return e.id === id;
          });

          const indexOf2 = listOfleave.findIndex((e) => {
            return e.ent_year === year;
          });

          if (indexOf1) {
            listOfleave.splice(indexOf1, 1);
          } else {
            listOfleave.splice(indexOf2, 1);
          }
        }

        setleaveAllYear(
          listOfleave.sort((a, b) => Number(b.ent_year) - Number(a.ent_year))
        );

        // settest({ ...test, test: "" });
        // test.test = "";

        // console.log("setleaveAllYear_del", leaveAllYear);
      }
    });
  }

  async function setAddEditYearLeave(mode, ent_year) {
    /*   let TempData = {
        ent_year:moment(new Date()).format("YYYY") ,
        ent_sick_leave:"",
        ent_personal_leave:"",
        ent_vacation_leave:"",
        mode:mode,
        id:id,
      } */

    if (mode === "edit") {
      const temp = leaveAllYear.filter((el) => {
        return el.ent_year === ent_year;
      });

      if (temp.length > 0) {
        setleavecurrent({
          ...leavecurrent,
          mode: mode,
          id: temp[0].id,
          ent_sick_leave: temp[0].ent_sick_leave,
          ent_year: temp[0].ent_year,
          ent_personal_leave: temp[0].ent_personal_leave,
          ent_vacation_leave: temp[0].ent_vacation_leave,
        });

        leavecurrent.mode = mode;
        leavecurrent.id = temp[0].id;
        leavecurrent.ent_sick_leave = temp[0].ent_sick_leave;
        leavecurrent.ent_year = temp[0].ent_year;
        leavecurrent.ent_personal_leave = temp[0].ent_personal_leave;
        leavecurrent.ent_vacation_leave = temp[0].ent_vacation_leave;
        /* 
        TempData.ent_year  = temp[0].ent_year;
        TempData.ent_sick_leave=temp[0].ent_sick_leave;
        TempData.ent_personal_leave=temp[0].ent_personal_leave;
        TempData.ent_vacation_leave= temp[0].ent_vacation_leave;
        TempData.id  = temp[0].id; */
      }
    } else if (mode === "add") {
      setleavecurrent({
        ...leavecurrent,
        mode: mode,
      });
      leavecurrent.mode = mode;
    }
    /* 
      setleavecurrent({
        ...leavecurrent,
        mode: TempData.mode,
        id:TempData.id,
        ent_sick_leave:TempData.ent_sick_leave,
        ent_year:TempData.ent_year,
        ent_personal_leave:TempData.ent_personal_leave,
        ent_vacation_leave:TempData.ent_vacation_leave,
      });
    
      leavecurrent.mode= TempData.mode;
      leavecurrent.id=TempData.id;
      leavecurrent.ent_sick_leave=TempData.ent_sick_leave;
      leavecurrent.ent_year=TempData.ent_year;
      leavecurrent.ent_personal_leave=TempData.ent_personal_leave;
      leavecurrent.ent_vacation_leave=TempData.ent_vacation_leave;
     */
    // console.log("mode", leavecurrent);
  }

  async function saveChangeYearLeave(params) {
    // const listOfleave_temp = leaveAllYear;
    let listOfleave = [];

    for (const iterator of leaveAllYear) {
      listOfleave.push(iterator);
    }

    // console.log("leavecurrent", leavecurrent);

    if (leavecurrent.mode === "add") {
      var checked = listOfleave.filter((e) => {
        return e.ent_year === leavecurrent.ent_year;
      });

      if (checked.length > 0) {
        console.log("ซ้ำ");
      } else {
        // leavecurrent.ent_sick_leave = leavecurrent.ent_sick_leave ? parseInt(leavecurrent.ent_sick_leave) : 0;
        // leavecurrent.ent_personal_leave = leavecurrent.ent_personal_leave ? parseInt(leavecurrent.ent_personal_leave) : 0;
        // leavecurrent.ent_vacation_leave = leavecurrent.ent_vacation_leave ? parseInt(leavecurrent.ent_vacation_leave) : 0;
        leavecurrent.ent_sick_leave = leavecurrent.ent_sick_leave || 0;
        leavecurrent.ent_personal_leave = leavecurrent.ent_personal_leave || 0;
        leavecurrent.ent_vacation_leave = leavecurrent.ent_vacation_leave || 0;
        listOfleave.push(leavecurrent);

        // console.log("leavecurrent_add", leavecurrent);
      }
    }

    if (leavecurrent.mode === "edit") {
      const indexOf = listOfleave.findIndex((e) => {
        return e.id === leavecurrent.id;
      });
      // console.log("edit", listOfleave[indexOf]);
      listOfleave[indexOf].mode = leavecurrent.mode;
      // listOfleave[indexOf].ent_sick_leave = leavecurrent.ent_sick_leave ? parseInt(leavecurrent.ent_sick_leave) : 0;
      // listOfleave[indexOf].ent_personal_leave = leavecurrent.ent_personal_leave ? parseInt(leavecurrent.ent_personal_leave) : 0;
      // listOfleave[indexOf].ent_vacation_leave = leavecurrent.ent_vacation_leave ? parseInt(leavecurrent.ent_vacation_leave) : 0;
      listOfleave[indexOf].ent_sick_leave = leavecurrent.ent_sick_leave || 0;
      listOfleave[indexOf].ent_personal_leave =
        leavecurrent.ent_personal_leave || 0;
      listOfleave[indexOf].ent_vacation_leave =
        leavecurrent.ent_vacation_leave || 0;
    }
    setleaveAllYear([]);
    const tempRearange = listOfleave.sort(
      (a, b) => Number(b.ent_year) - Number(a.ent_year)
    );
    setleaveAllYear(tempRearange);
    leaveAllYear = tempRearange;

    setleavecurrent({
      ent_year: moment(new Date()).format("YYYY"),
      ent_sick_leave: "",
      ent_personal_leave: "",
      ent_vacation_leave: "",
      mode: null,
      id: "",
    });

    // console.log("listOfleave", tempRearange);
    // console.log("setleaveAllYear", leaveAllYear);
  }

  async function saveLeaveAllYearByEmp(emp_id) {
    var temp = {
      emp_id: emp_id,
      company_id: getUser().com,
      oem_id: getOem(),
      leaveAllYear: leaveAllYear,
    };
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/addeditdelLeaveAllYearByEmp",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        //  response.data
        // console.log("Save", response.data.data);
        // setleaveAllYear(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function getLeaveAllYearByEmp() {
    var temp = {
      emp_id: empployee_id.data,
      company_id: getUser().com,
      oem_id: getOem(),
    };
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getLeaveAllYearByEmp",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        //  response.data
        // console.log("newwwww", response.data.data);

        setleaveAllYear(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  let tempLeave_rows = [];
  for (let index = 0; index < leaveAllYear.length; index++) {
    const element = {};
    element["year"] = (
      <>
        <div style={{ textAlign: "center" }}>
          {leaveAllYear[index].ent_year}
        </div>
      </>
    );
    element["sick_leave"] = (
      <>
        <div style={{ textAlign: "end" }}>
          {leaveAllYear[index].ent_sick_leave}
        </div>
      </>
    );
    element["event_leave"] = (
      <>
        <div style={{ textAlign: "end" }}>
          {leaveAllYear[index].ent_personal_leave}
        </div>
      </>
    );
    element["holiday_leave"] = (
      <>
        <div style={{ textAlign: "end" }}>
          {leaveAllYear[index].ent_vacation_leave}
        </div>
      </>
    );

    element["mgt"] =
      Number(moment(new Date()).format("YYYY")) <=
      Number(leaveAllYear[index].ent_year) ? (
        <div className="row" style={{ flexWrap: "nowrap" }}>
          <div className="col-xl-3 col-md-3 col-xs-3 ">
            <a
              // href={"/Human_Resource/ข้อมูลพนักงาน/Employee/edit/" + Employee[index].emp_id}
              onClick={setAddEditYearLeave.bind(
                this,
                "edit",
                leaveAllYear[index].ent_year
              )}
              id="btn_edit"
              hidden={disable}
              key={leaveAllYear[index].id + "editYearLeave"}
              className=" btn btn-xs "
              data-toggle="modal"
              data-target="#modal-leave-detail"
            >
              {"   "}
              <i class="fas fa-pencil-alt"></i>
              {"   "}
            </a>
          </div>
          <div className="col-xl-3 col-md-3 col-xs-3 ">
            <a
              key={leaveAllYear[index].id + "delYearLeave"}
              id="btn_delete"
              hidden={disable}
              className=" btn btn-xs "
              onClick={setdelYearLeave.bind(
                this,
                "del",
                leaveAllYear[index].id,
                leaveAllYear[index].ent_year
              )}
            >
              <i class="fas fa-trash-alt"></i>
            </a>
          </div>
        </div>
      ) : (
        ""
      );

    tempLeave_rows.push(element);
  }
  const leaveTable = {
    columns: [
      {
        label: "ปี",
        field: "year",
        sort: "asc",
        width: 50,
      },
      {
        label: "ลาป่วย(ได้สิทธิ์)",
        field: "sick_leave",
        sort: "asc",
        width: 50,
      },
      {
        label: "ลากิจ(ได้สิทธิ์)",
        field: "event_leave",
        sort: "asc",
        width: 50,
      },
      {
        label: "ลาพักร้อน(ได้สิทธิ์)",
        field: "holiday_leave",
        sort: "asc",
        width: 50,
      },
      {
        label: "จัดการ",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: tempLeave_rows, //ยังไม่ได้ใส่ข้อมูล
  };
  return (
    <div>
      {/* <section className="content-header">
          <div className="container-fluid">
            {mode.mode === "read" ? (
              ""
            ) : (
              <div className="row mb-2">
                <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    บันทึก
                  </button>
                </div>
                {employee.isGenAccount === false ? (
                  <div className="col-6 col-md-4 col-xl-3">
                    <button
                      type="button"
                      // disabled={
                      //   employee.emp_name_eng == "" ||
                      //     employee.emp_citizen_id == "" ||
                      //     employee.level_id == "" ||
                      //     employee.emp_nickname == "" ||
                      //     employee.emp_department_id == "" ||
                      //     employee.emp_position_id == "" ||
                      //     employee.emp_ent_sick_leave == ""
                      //     ? true
                      //     : false
                      // }
                      onClick={() => Generate_Account()}
                      class="btn btn-block btn-primary "
                    >
                      Generate Account
                    </button>
                  </div>
                ) : (
                  <div className="col-6 col-md-4 col-xl-3">
                    <button
                      type="button"
                      onClick={() => resetPassword(employee)}
                      class="btn btn-block btn-danger "
                    >
                      Reset Password
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </section> */}
      <section className="content">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Employee {pageMode}</h3>
          </div>

          <div className="card-body">
            <h3 className="mb-2  mt-5-head">ข้อมูลพนักงาน</h3>
            <div className="row">
              {/* <div className="col-12 col-md-6 col-xl-4">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    id="text_name_th"
                    value={employee.emp_name_th}
                    placeholder="ตัวอย่าง สมศรี เรืองศักดา,Somsri Ruangsakda"
                    disabled={disable}
                    onChange={(e) => {
                      setemployee({
                        ...employee,
                        emp_name_th: e.target.value,
                      });
                    }}
                  />
                  <label>
                    ชื่อ - นามสกุล (ไทย หรือ อังกฤษ)
                    {/* {employee.emp_name_th === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} *
                  </label>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4">
                <div class="form-group">
                  <NumberFormat
                    className="form-control"
                    required
                    id="text_mobile"
                    thousandSeparator={false}
                    format={"#-####-#####-##-#"}
                    value={employee.emp_citizen_id}
                    disabled={disable}
                    onChange={(e) => {
                      setemployee({
                        ...employee,
                        emp_citizen_id: e.target.value,
                      });
                    }}
                  />
                  <label>
                    หมายเลขบัตรประชาชน
                    {employee.emp_citizen_id === "" ? (
                      <span style={{ color: "red" }}> *</span>
                    ) : (
                      ""
                    )}
                  </label>
                </div>
              </div> */}
              {lvFea.length !== 0 ? (
                <>
                  <div className="col-12 col-md-6 col-xl-2">
                    <div class="form-group">
                      <input
                        type="text"
                        className="form-control"
                        required
                        id="text_emp_no"
                        value={employee.emp_no}
                        disabled={disable}
                        onChange={(e) => {
                          setemployee({
                            ...employee,
                            emp_no: e.target.value,
                          });
                        }}
                      />
                      <label>
                        รหัสพนักงาน
                        {employee.emp_no === "" ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-2">
                    <div className="form-group ">
                      <select
                        className="form-control custom-select "
                        type="text"
                        required
                        id="drop_level"
                        value={employee.emp_level_id}
                        disabled={disable}
                        onChange={(e) => {
                          setemployee({
                            ...employee,
                            emp_level_id: e.target.value,
                          });
                        }}
                      >
                        <option value="" disabled selected>
                          ----เลือก----
                        </option>
                        {level.map((el) => {
                          return <option value={el.id}>{el.level}</option>;
                        })}
                      </select>
                      <label htmlFor="">Level</label>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-12 col-md-6 col-xl-2">
                    <div class="form-group">
                      <input
                        type="text"
                        className="form-control"
                        required
                        id="text_emp_no"
                        value={employee.emp_no}
                        disabled={disable}
                        onChange={(e) => {
                          setemployee({
                            ...employee,
                            emp_no: e.target.value,
                          });
                        }}
                      />
                      <label>
                        รหัสพนักงาน
                        {employee.emp_no === "" ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                  </div>
                  {/* <div className="col-12 col-md-6 col-xl-2">
                    <div class="form-group">
                      <input
                        type="text"
                        className="form-control"
                        required
                        id="text_nickname"
                        value={employee.emp_nickname}
                        disabled={disable}
                        onChange={(e) => {
                          setemployee({
                            ...employee,
                            emp_nickname: e.target.value,
                          });
                        }}
                      />
                      <label>
                        ชื่อเล่น
                        {employee.emp_nickname === "" ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                  </div> */}
                </>
              )}
              <div className="col-12 col-md-6 col-xl-4">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    id="text_email"
                    value={employee.emp_email_company}
                    disabled={disable}
                    onChange={(e) => {
                      setemployee({
                        ...employee,
                        emp_email_company: e.target.value,
                      });
                    }}
                  />
                  <label>E-mail บริษัท</label>
                </div>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-12 col-md-6 col-xl-2">
                <div class="form-group">
                  <DatePicker
                    selected={employee.emp_birthday}
                    disabled={disable}
                    id="text_birthday"
                    dateFormat={"dd-MM-yyyy"}
                    locale="th"
                    onChange={async (date) => {
                      setemployee({
                        ...employee,
                        emp_birthday: date,
                      });
                    }}
                    showYearDropdown
                    showMonthDropdown
                    customInput={<CustomInput />}
                  />
                  <label>วันเกิด</label>
                </div>
              </div>

              <div className="col-12 col-md-6 col-xl-2">
                <div class="form-group ">
                  <select
                    className="form-control custom-select select2"
                    type="text"
                    required
                    id="text_nation_id"
                    value={employee.emp_nation_id}
                    disabled={disable}
                    onChange={(e) => {
                      setemployee({
                        ...employee,
                        emp_nation_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      ----เลือก----
                    </option>
                    {getMasterEmployeeType.map((el) => {
                      return (
                        <option value={el.emp_nation_id}>
                          {el.emp_nation}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor=""> สัญชาติ</label>
                </div>
              </div> */}

              {lvFea.length !== 0 ? (
                <>
                  {/* <div className="col-12 col-md-6 col-xl-2">
                    <div class="form-group">
                      <input
                        type="text"
                        className="form-control"
                        required
                        id="text_line_id"
                        value={employee.emp_line_id}
                        disabled={disable}
                        onChange={(e) => {
                          setemployee({
                            ...employee,
                            emp_line_id: e.target.value,
                          });
                        }}
                      />
                      <label>Line id</label>
                    </div>
                  </div> */}
                  {/* <div className="col-12 col-md-6 col-xl-2">
                    <div class="form-group">
                      <input
                        type="text"
                        className="form-control"
                        required
                        id="text_nickname"
                        value={employee.emp_nickname}
                        disabled={disable}
                        onChange={(e) => {
                          setemployee({
                            ...employee,
                            emp_nickname: e.target.value,
                          });
                        }}
                      />
                      <label>
                        ชื่อเล่น
                        {employee.emp_nickname === "" ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                  </div> */}
                </>
              ) : (
                <>
                  {/* <div className="col-12 col-md-6 col-xl-4">
                    <div class="form-group">
                      <input
                        type="text"
                        className="form-control"
                        required
                        id="text_line_id"
                        value={employee.emp_line_id}
                        disabled={disable}
                        onChange={(e) => {
                          setemployee({
                            ...employee,
                            emp_line_id: e.target.value,
                          });
                        }}
                      />
                      <label>Line id</label>
                    </div>
                  </div> */}
                </>
              )}
            </div>
            <div className="row">
              {/* <div className="col-12 col-md-6 col-xl-4">
                <div class="form-group">
                  <NumberFormat
                    className="form-control"
                    required
                    id="text_mobile"
                    thousandSeparator={false}
                    format={"###-###-####"}
                    value={employee.emp_mobile}
                    disabled={disable}
                    onChange={(e) => {
                      setemployee({
                        ...employee,
                        emp_mobile: e.target.value,
                      });
                    }}
                  />

                  <label>เบอร์โทรศัพท์</label>
                </div>
              </div> */}
              <div className="col-12 col-md-6 col-xl-4">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    id="text_account_name"
                    value={employee.account_name}
                    disabled={disable}
                    onChange={(e) => {
                      setemployee({
                        ...employee,
                        account_name: e.target.value,
                      });
                    }}
                  />
                  <label>ชื่อบัญชี</label>
                </div>
              </div>

              <div className="col-12 col-md-6 col-xl-4">
                <div class="form-group">
                  <NumberFormat
                    type="text"
                    className="form-control"
                    required
                    id="text_account_number"
                    value={employee.account_number}
                    disabled={disable}
                    onChange={(e) => {
                      setemployee({
                        ...employee,
                        account_number: e.target.value,
                      });
                    }}
                  />
                  <label>เลขบัญชี</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-12 col-md-6 col-xl-4"
                style={{ "z-index": "98" }}
                onClick={() => {
                  setStartWork({
                    start1: false,
                    start2: false,
                  });
                  startWork.start1 = false;
                  startWork.start2 = false;
                }}
              >
                <div className="form-group ">
                  <Select
                    // className="form-control custom-select "
                    type="text"
                    required
                    id="drop_department"
                    value={department.find(
                      (item) => item.value === employee.emp_department_id
                    )}
                    isDisabled={disable}
                    options={department}
                    onChange={(e) => {
                      if (e !== null) {
                        setemployee({
                          ...employee,
                          emp_department_id: e.value || "",
                        });
                        // setposition([]);
                        /* console.log('position', position);
                        console.log('e.value', e.value); */
                        let temp = position.filter(
                          (el) => el.dep_id === e.value || ""
                        );
                        // console.log('temp', temp);
                        setposition_dropdown(temp);
                      }
                    }}
                  >
                    {/* <option value="" disabled selected>
                      ----เลือก----
                    </option>
                    {department.map((el) => {
                      return (
                        <option value={el.department_id}>
                          {el.department_name}
                        </option>
                      );
                    })} */}
                  </Select>
                  <label htmlFor="">
                    {" "}
                    แผนก
                    {!employee.emp_department_id ? (
                      <span style={{ color: "red" }}> *</span>
                    ) : (
                      ""
                    )}
                  </label>
                </div>
              </div>
              {lvFea.length !== 0 ? (
                <>
                  <div
                    className="col-12 col-md-6 col-xl-4"
                    style={{ "z-index": "98" }}
                    onClick={() => {
                      setStartWork({
                        start1: false,
                        start2: false,
                      });
                      startWork.start1 = false;
                      startWork.start2 = false;
                    }}
                  >
                    <div class="form-group">
                      <Select
                        // className="form-control custom-select "
                        type="text"
                        required
                        id="drop_level"
                        value={position_dropdown.find(
                          (item) => item.value === employee.emp_position_id
                        )}
                        isDisabled={disable}
                        options={position_dropdown}
                        onChange={(e) => {
                          if (e !== null) {
                            setemployee({
                              ...employee,
                              emp_position_id: e.value || "",
                            });
                          }
                        }}
                      >
                        {/* <option value="" disabled selected>
                          ----เลือก----
                        </option>
                        {position.map((el) => {
                          return (
                            <option value={el.id}>{el.position_name_th}</option>
                          );
                        })} */}
                      </Select>
                      <label htmlFor="">
                        ตำแหน่ง
                        {employee.emp_position_id === "" ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-12 col-md-6 col-xl-4">
                    <div class="form-group">
                      <input
                        type="text"
                        className="form-control"
                        required
                        id="text_position"
                        value={employee.emp_position}
                        disabled={disable}
                        onChange={(e) => {
                          setemployee({
                            ...employee,
                            emp_position: e.target.value,
                          });
                        }}
                      />
                      <label>
                        ตำแหน่ง
                        {employee.emp_position === "" ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                  </div>
                </>
              )}
              <div className="col-12 col-md-6 col-xl-4">
                <div className="form-group ">
                  <select
                    className="form-control custom-select "
                    type="text"
                    required
                    id="drop_department"
                    value={employee.employee_type}
                    disabled={disable}
                    onChange={(e) => {
                      setemployee({
                        ...employee,
                        employee_type: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      ----เลือก----
                    </option>
                    {getEmployeeWorkType.map((el) => {
                      return (
                        <option value={el.employee_work_type_id}>
                          {el.employee_work_type_name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">
                    {" "}
                    รายวัน/รายเดือน
                    {/* {employee.employee_type === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-xl-4">
                <div class="form-group">
                  <select
                    className="form-control custom-select select2"
                    type="text"
                    required
                    id="drop_emptype"
                    disabled={disable}
                    value={employee.emp_period_time_id}
                    onChange={(e) => {
                      setemployee({
                        ...employee,
                        emp_period_time_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      ----เลือก----
                    </option>
                    {getemployeeType.map((el) => {
                      return (
                        <option value={el.period_time_id}>
                          {el.period_time}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">รูปแบบการทำงาน</label>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4">
                <div className="row">
                  <div className="col-6 col-md-6 col-xl-6">
                    <div
                      class="form-group"
                      style={{ zIndex: startWork.start1 === true ? 1000 : 20 }}
                      onClick={() => {
                        setStartWork({
                          start1: true,
                          start2: false,
                        });
                        startWork.start1 = true;
                        startWork.start2 = false;
                      }}
                    >
                      <DatePicker
                        // disabled={true}

                        selected={employee.emp_start_work_day}
                        disabled={disable}
                        locale="th"
                        id="text_start_work_day"
                        dateFormat={"dd-MM-yyyy"}
                        onChange={async (date) => {
                          setemployee({
                            ...employee,
                            emp_start_work_day: date,
                          });
                          // SetDate_start(date);
                        }}
                        selectsStart
                        showYearDropdown
                        showMonthDropdown
                        startDate={employee.emp_start_work_day}
                        // endDate={employee.emp_end_work_day}
                        // maxDate={employee.emp_end_work_day}
                        customInput={<CustomInput />}
                      />
                      <label>
                        วันที่เริ่มทำงาน
                        {employee.emp_start_work_day === "" ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="col-6 col-md-6 col-xl-6">
                    <div
                      class="form-group"
                      style={{ zIndex: startWork.start2 === true ? 1000 : 20 }}
                      onClick={() => {
                        setStartWork({
                          start1: false,
                          start2: true,
                        });
                        startWork.start1 = false;
                        startWork.start2 = true;
                      }}
                    >
                      <DatePicker
                        // disabled={true}

                        selected={employee.emp_start_work}
                        disabled={disable}
                        locale="th"
                        id="text_start_work"
                        dateFormat={"dd-MM-yyyy"}
                        onChange={async (date) => {
                          setemployee({
                            ...employee,
                            emp_start_work: date,
                          });
                          SetDate_start(date);
                        }}
                        selectsStart
                        showYearDropdown
                        showMonthDropdown
                        startDate={employee.emp_start_work}
                        endDate={employee.emp_start_work_day}
                        minDate={employee.emp_start_work_day}
                        maxDate={new Date()}
                        customInput={<CustomInput />}
                      />
                      <label>เริ่มบรรจุพนักงาน</label>
                    </div>
                  </div>
                  {/* <div className="col-12 col-md-6 col-xl-4"> */}
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4">
                <div className="row">
                  <div className="col-6 col-md-6 col-xl-6">
                    <div class="form-group" style={{ zIndex: 20 }}>
                      <DatePicker
                        // disabled={true}
                        selected={employee.emp_end_work}
                        locale="th"
                        disabled={
                          disable ||
                          employee.employee_status_type_id !==
                            "9437e6c7-68aa-4ee6-b7da-4b54b14c07ba"
                        }
                        id="text_end_work"
                        dateFormat={"dd-MM-yyyy"}
                        onChange={async (date) => {
                          setemployee({
                            ...employee,
                            emp_end_work: date,
                          });
                          SetDate_end(date);
                        }}
                        selectsStart
                        showYearDropdown
                        showMonthDropdown
                        endDate={employee.emp_end_work}
                        minDate={employee.emp_start_work}
                        customInput={<CustomInput2 />}
                      />
                      <label>
                        สิ้นสุดบรรจุพนักงาน{" "}
                        {employee.employee_status_type_id ===
                          "9437e6c7-68aa-4ee6-b7da-4b54b14c07ba" &&
                        employee.emp_end_work === "" ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="col-6 col-md-6 col-xl-6">
                    <div class="form-group" style={{ zIndex: 20 }}>
                      <DatePicker
                        selected={employee.emp_social_security_date}
                        disabled={disable}
                        id="text_social_security_date"
                        dateFormat={"dd-MM-yyyy"}
                        locale="th"
                        onChange={async (date) => {
                          setemployee({
                            ...employee,
                            emp_social_security_date: date,
                          });
                          //SetDate_end(date)
                        }}
                        showYearDropdown
                        showMonthDropdown
                        startDate={employee.emp_social_security_date}
                        customInput={<CustomInput />}
                      />
                      <label>แจ้งเข้า ประกันสังคม</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-xl-4">
                <div class="form-group">
                  <NumberFormat
                    className="form-control"
                    required
                    id="text_salary"
                    thousandSeparator={true}
                    allowNegative={false}
                    value={employee.emp_salary}
                    decimalScale={2}
                    disabled={disable}
                    onChange={(e) => {
                      setemployee({
                        ...employee,
                        emp_salary: e.target.value,
                      });
                    }}
                  />

                  <label>อัตราเงินเดือน (บาท)</label>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required
                    id="text_hospitai"
                    value={employee.emp_hospital}
                    disabled={disable}
                    onChange={(e) => {
                      setemployee({
                        ...employee,
                        emp_hospital: e.target.value,
                      });
                    }}
                  />
                  <label>โรงพยาบาล</label>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-2">
                <div className="form-group ">
                  <select
                    className="form-control"
                    required
                    id="employee_contract_type_id"
                    value={employee.employee_contract_type_id}
                    disabled={disable}
                    onChange={(e) => {
                      setemployee({
                        ...employee,
                        employee_contract_type_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      ----เลือก----
                    </option>
                    {getEmpWorkType.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.contract_type_name}
                        </option>
                      );
                    })}
                  </select>
                  <label>ประเภทพนักงาน</label>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-2">
                <div className="form-group ">
                  <select
                    className="form-control"
                    required
                    id="employee_status_type_id"
                    value={employee.employee_status_type_id}
                    disabled={disable}
                    onChange={(e) => {
                      setemployee({
                        ...employee,
                        employee_status_type_id: e.target.value,
                      });
                      if (
                        e.target.value !==
                        "9437e6c7-68aa-4ee6-b7da-4b54b14c07ba"
                      ) {
                        console.log("check");
                        setemployee({
                          ...employee,
                          emp_end_work: "",
                          employee_status_type_id: e.target.value,
                        });
                      }
                    }}
                  >
                    <option value="" disabled selected>
                      ----เลือก----
                    </option>
                    {getEmpStatusType.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.status_name}
                        </option>
                      );
                    })}
                  </select>
                  <label>สถานะพนักงาน</label>
                </div>
              </div>
              {/* <div className="col-12 col-md-6 col-xl-2">
                  <div className="" style={{ padding: "0.6rem 0" }}>
                    <div className="form-control">
                      <div className="custom-switch custom-switch-off custom-switch-on-success">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitch1"
                          value={employee.employee_is_active}
                          checked={employee.employee_is_active}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              employee_is_active: e.target.checked,
                            });
                            employee.employee_is_active = e.target.checked;
                          }}
                       value={ManageProduct.ev_is_active}
                      checked={ManageProduct.ev_is_active}
                      disabled={Disable}
                      onChange={(e) => {
                        console.log(e);
                        setManageProduct({
                          ...ManageProduct,
                          ev_is_active: e.target.checked,
                        });
                        ManageProduct.ev_is_active =
                          e.target.checked;
                        settest({ test: "" });
                        test.test = "";
                      }} 
                        ></input>
                        <label
                          className="custom-control-label"
                          htmlFor="customSwitch1"
                        >
                          Status
                        </label>
                      </div>
                    </div>
                  </div>
                </div> */}
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-xl-4">
                <div class="form-group">
                  <NumberFormat
                    type="text"
                    className="form-control"
                    required
                    id="text_work_year"
                    value={chaek_DMY.sum_yaeh}
                    disabled={true}
                    onChange={(e) => {
                      setemployee({
                        ...employee,
                        emp_work_year: e.target.value,
                      });
                    }}
                  />
                  <label>อายุงานปี</label>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4">
                <div class="form-group">
                  <NumberFormat
                    type="text"
                    className="form-control"
                    required
                    id="text_work_month"
                    value={chaek_DMY.sum_monyh}
                    disabled={true}
                    onChange={(e) => {
                      setemployee({
                        ...employee,
                        emp_work_month: e.target.value,
                      });
                    }}
                  />
                  <label>อายุงานเดือน</label>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4">
                <div class="form-group">
                  <NumberFormat
                    type="text"
                    className="form-control"
                    required
                    id="text_work_day"
                    value={chaek_DMY.sum_day}
                    disabled={true}
                    onChange={(e) => {
                      setemployee({
                        ...employee,
                        emp_work_day: e.target.value,
                      });
                    }}
                  />
                  <label>อายุงานวัน</label>
                </div>
              </div>
            </div>
            {/*  <h3 className="mb-2 mt-5-head">สิทธิ์สวัสดิการ</h3>
              <div className="row">
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <NumberFormat
                      type="text"
                      className="form-control"
                      required
                      id="text_sick_leave"
                      value={employee.emp_ent_sick_leave}
                      disabled={disable}
                      onChange={(e) => {
                        setemployee({
                          ...employee,
                          emp_ent_sick_leave: e.target.value,
                        });
                      }}
                    />
                    <label>
                      ลาป่วย (ได้สิทธิ์)
                      {employee.emp_ent_sick_leave === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <NumberFormat
                      type="text"
                      className="form-control"
                      required
                      id="text_ent_personal_leave"
                      value={employee.emp_ent_personal_leave}
                      disabled={disable}
                      onChange={(e) => {
                        setemployee({
                          ...employee,
                          emp_ent_personal_leave: e.target.value,
                        });
                      }}
                    />
                    <label>ลากิจ (ได้สิทธิ์)</label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <NumberFormat
                      type="text"
                      className="form-control"
                      required
                      id="text_vacation_leave"
                      value={employee.emp_ent_vacation_leave}
                      disabled={disable}
                      onChange={(e) => {
                        setemployee({
                          ...employee,
                          emp_ent_vacation_leave: e.target.value,
                        });
                      }}
                    />
                    <label>ลาพักร้อน (ได้สิทธิ์)</label>
                  </div>
                </div>
              </div> */}
            <div className="row">
              <div className="col-2 col-md-2 col-xl-2">
                <button
                  type="button"
                  class="btn btn-block btn-info"
                  disabled={disable}
                  data-toggle="modal"
                  data-target="#modal-leave-detail"
                  onClick={() => {
                    setleavecurrent({
                      ent_year: moment(new Date()).format("YYYY"),
                      ent_sick_leave: 0,
                      ent_personal_leave: 0,
                      ent_vacation_leave: 0,
                      mode: null,
                      id: "",
                    });
                    leavecurrent.ent_year = moment(new Date()).format("YYYY");
                    leavecurrent.ent_sick_leave = 0;
                    leavecurrent.ent_personal_leave = 0;
                    leavecurrent.ent_vacation_leave = 0;
                    leavecurrent.mode = null;
                    leavecurrent.id = "";
                    setAddEditYearLeave("add", null);
                    /*   setEmp_History_Work(resetEmp_History_Work);
                      setCloseModal({ checkData: true });
                      closeModal.checkData = true;
                      settest({ ...test, test: "" }); */
                  }}
                >
                  เพิ่มสิทธิ์ลา
                </button>
              </div>
              <div className="col-12 col-md-12 col-xl-12">
                <MDBDataTable
                  className="table table-head-fixed"
                  sortable={false}
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={leaveTable}
                />
              </div>

              <div className="modal fade" id="modal-leave-detail">
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title">สิทธิ์สวัสดิการ</h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        ref={modalRef}
                        /*    ref={closeWorkModal}
                                onClick={() => setModal({ check: false, index: -1 })} */
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12 ">
                          <div className="none-nolmalinput"></div>
                        </div>
                      </div>
                      <br />
                      {/*  <h3 className="mb-2 mt-5-head">สิทธิ์สวัสดิการ</h3> */}

                      {/* <div className="col-6 col-md-4 col-xl-3">
                          <div className="form-group ">
                            <select
                              className="custom-select select2 form-control"
                              value={leavecurrent.year}
                              onChange={(e) =>
                                setleavecurrent({
                                  ...leavecurrent,
                                  year: e.target.value,
                                })
                              }
                            >
                              {YearStock.map((e) => {
                                return (
                                  <option
                                    value={e}
                                    selected={
                                      leavecurrent.year === e ? true : false
                                    }
                                  >
                                    {e + 543}
                                  </option>
                                );
                              })}
                            </select>
                            <label htmlFor="">ข้อมูลลาของปี</label>
                          </div>
                        </div> */}
                      <br />
                      {/*  <h3 className="mb-2 mt-5-head">สิทธิ์สวัสดิการ</h3> */}
                      <div className="row">
                        <div className="col-6 col-md-4 col-xl-3">
                          <div className="form-group ">
                            <select
                              className="custom-select select2 form-control"
                              value={leavecurrent.ent_year}
                              onChange={(e) =>
                                setleavecurrent({
                                  ...leavecurrent,
                                  ent_year: e.target.value,
                                })
                              }
                            >
                              {YearStock.map((e) => {
                                return (
                                  <option
                                    value={e}
                                    selected={
                                      leavecurrent.ent_year === e ? true : false
                                    }
                                  >
                                    {e}
                                  </option>
                                );
                              })}
                            </select>
                            <label htmlFor="">ข้อมูลลาของปี</label>
                          </div>
                        </div>
                        <div className="col-6 col-md-4 col-xl-3">
                          <div class="form-group">
                            <NumberFormat
                              type="text"
                              className="form-control"
                              required
                              id="text_sick_leave"
                              value={leavecurrent.ent_sick_leave}
                              // decimalScale={1}
                              allowNegative={false}
                              disabled={disable}
                              onChange={(e) => {
                                setleavecurrent({
                                  ...leavecurrent,
                                  ent_sick_leave: e.target.value,
                                });
                              }}
                            />
                            <label>
                              ลาป่วย (ได้สิทธิ์)
                              {leavecurrent.ent_sick_leave === "" ? (
                                <span style={{ color: "red" }}> *</span>
                              ) : (
                                ""
                              )}
                            </label>
                          </div>
                        </div>

                        <div className="col-6 col-md-4 col-xl-3">
                          <div class="form-group">
                            <NumberFormat
                              type="text"
                              className="form-control"
                              required
                              id="text_ent_personal_leave"
                              value={leavecurrent.ent_personal_leave}
                              // decimalScale={1}
                              allowNegative={false}
                              disabled={disable}
                              onChange={(e) => {
                                setleavecurrent({
                                  ...leavecurrent,
                                  ent_personal_leave: e.target.value,
                                });
                              }}
                            />
                            <label>ลากิจ (ได้สิทธิ์)</label>
                          </div>
                        </div>
                        <div className="col-6 col-md-4 col-xl-3">
                          <div class="form-group">
                            <NumberFormat
                              type="text"
                              className="form-control"
                              required
                              id="text_vacation_leave"
                              value={leavecurrent.ent_vacation_leave}
                              // decimalScale={1}
                              allowNegative={false}
                              disabled={disable}
                              onChange={(e) => {
                                setleavecurrent({
                                  ...leavecurrent,
                                  ent_vacation_leave: e.target.value,
                                });
                              }}
                            />
                            <label>ลาพักร้อน (ได้สิทธิ์)</label>
                          </div>

                          <br />
                        </div>
                        {/* <div className="row">
                              <div className="col-md-3">
                                <div className="form-group ">
                                  <button
                                    type="button"
                                    class="btn btn-block btn-info "
                                    id="modal-close1"
                                     disabled={disable}
                                          onClick={() => {
                                            const checkData = checkDataEmployee_Work(emp_history_work, employee.emp_work_history);
                                            setCloseModal({ checkData });
                                            closeModal.checkData = checkData;
                                            addAndEditTable_WorkHistory(checkData, emp_history_work);
                                            if (!checkData) {
                                              clickCloseWorkModal();
                                            }
                                          }}
                                  >
                                    เพิ่มข้อมูล
                                  </button>
                                </div>
                              </div>
                            </div> */}
                      </div>

                      <div className="modal-footer justify-content-between">
                        <button
                          type="button"
                          className="btn btn-success"
                          // data-dismiss="modal"
                          onClick={() => {
                            /* if (!leavecurrent.ent_sick_leave) {
                              Swal.fire({
                                icon: "warning",
                                title: "เพิ่มข้อมูลไม่สำเร็จ",
                                text: "กรุณากรอกช่อง วันลาป่วย",
                              });
                              return;
                            } else { */
                            saveChangeYearLeave();
                            closeModalRef();
                            // }
                          }}
                        >
                          เพิ่มข้อมูล
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Employee_add_Info;
