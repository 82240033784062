import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import QRCode from "qrcode.react";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import { userdefault_img, logoTTT } from "../../routes/imgRoute/imgUrl";
import "../../assets/css/table.css";
import { useParams } from "react-router";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken } from "../../Utils/Common";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";

function Factory_parameter_add(mode) {
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);

  const [machine, setmachine] = useState({
    company_id: getOem(),
    id: "",
    machine_name: "",
    machine_detail: "",
    is_use: "",
    sort: "",
  });
  const [process, setprocess] = useState([]);
  const [process_select, setprocess_select] = useState([]);
  const [filler_process, setFiller_process] = useState({
    oem_id: getOem(),
    section_id: "",
    section_name: "",
    process_name: "",
  });

  const { id } = useParams();
  useEffect(() => {
    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);

  useEffect(() => {
  //  console.log("id:" + id);
    GetMachine_view();
    GetProcess();
  }, []);

  //console.log(process_select);

  const GetMachine_view = async () => {
    if (mode.mode == "read" || mode.mode == "edit") {
      axios({
        method: "get",
        url:
          Configs.API_URL +
          "/factoryParameter/machine/findMachineById?id=" +
          id,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          //console.log(response.data);
          setmachine({
            company_id: response.data.company_id,
            machine_name: response.data.machine_name,
            machine_detail: response.data.machine_detail,
            is_use: response.data.is_use,
            sort: response.data.sort,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const GetProcess = async () => {
    if (mode.mode === "add") {
      axios({
        method: "post",
        url: Configs.API_URL + "/factoryParameter/process/filter",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: filler_process,
      })
        .then(function (response) {
        /*   console.log(response.data); */
          setprocess(response.data);

          const temp_process = [];
          for (let index = 0; index < response.data.length; index++) {
            const element = {};
            element["id"] = response.data[index].id;
            element["process_name"] = response.data[index].process_name;
            element["isSelect"] = false;
            temp_process.push(element);
          }
          setprocess_select(temp_process);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
     
   
        axios({
          method: "post",
          url: Configs.API_URL + "/factoryParameter/process/filter",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: filler_process,
        })
          .then(function (response) {
         

              axios({
                method: "get",
                url: Configs.API_URL + "/factoryParameter/machine/findMachineProcessById?id=" + id,
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
              })
                .then(function (response2) {

                  const temp_process = [];
                  for (let index = 0; index < response.data.length; index++) {
                    const element = {};
                    element["id"] = response.data[index].id;
                    element["process_name"] = response.data[index].process_name;
              
                    for (let k = 0; k < response2.data.length; k++) {
                      
                      if (response.data[index].id === response2.data[k].process_id) {
                        /* console.log(k); */
                        element["isSelect"] = true;
                      }
                    
                    }
                    temp_process.push(element)
                  }
                  setprocess_select(temp_process);
                })
                .catch(function (error) {
                  console.log(error);
                });
            
          
          })
          .catch(function (error) {
            console.log(error);
          });

    }
  };

  /* const GetProcess = async () => {
    axios({
      method: "post",
      url: Configs.API_URL + "/factoryParameter/process/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_process,
    })
      .then(function (response) {
        console.log(response.data);
        setprocess(response.data);

        const temp_process = [];
        for (let index = 0; index < response.data.length; index++) {
          const element = {};
          element["id"] = response.data[index].id;
          element["process_name"] = response.data[index].process_name;
          element["isSelect"] = false;
          temp_process.push(element);
        }
        setprocess_select(temp_process);
      })
      .catch(function (error) {
        console.log(error);
      });
  }; */
  const process_select_item = async (el) => {

    const tempData = process_select;
 
      if (tempData[el.target.id].isSelect) {
        tempData[el.target.id].isSelect = false;
        //tempData[el.target.id].process_name = "test"
      }else{
        tempData[el.target.id].isSelect =true;
        //tempData[el.target.id].process_name = "test"
      }
      //console.log(tempData[el.target.id].isSelect);

      setprocess_select(tempData)
    
  }
/*   function process_select_item(el) {
    for (let index = 0; index < process_select.length - 1; index++) {
      if (el.target.value === process_select[index].id) {
        process_select[index].isSelect = el.target.checked;
      }
    }
  } */
  function saveOption(params) {
  let process_select_temp = process_select.filter((element)=>{ 
    return (element.isSelect === true);
})
  const error_list = [];
  if(machine.machine_name.trim() == ""){
    let temp_err ={
      message:"Please enter information in the fields >>> [Machine Name]."
    }
    error_list.push(temp_err);
  }
  if(process_select_temp.length < 1){
    let temp_err ={
      message:"Please enter information in the fields >>> [Select Process]."
    }
    error_list.push(temp_err);
  }
  if(error_list.length > 0){
    var err_message = "";
    for(var e = 0; e < error_list.length;e++){
      err_message += "<br/>"+error_list[e].message;
    }
    Swal.fire("Error", err_message, "error");
  }else{
    if (mode.mode === "add") {
      const temp_save_add = {
        oem_id: getOem(),
        machine_name: machine.machine_name.trim(),
        process_id: process_select_temp,
      };
      Swal.fire({
        title: "Saving",
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 1000,
        onOpen: () => {
          Swal.showLoading();
        },
      }).then((result) => {
        axios({
          method: "post",
          url: Configs.API_URL + "/factoryParameter/machine/add",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp_save_add,
        })
          .then(function (response) {
            if (response.data) {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Factory_Parameter/status/machine";
              });
            }

            //console.log(response.data);
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", error.response.data.message, "error");
          });
      });
    }

    if (mode.mode === "edit") {

      const temp_save_edit = {
        oem_id: getOem(),
        machine_id: id,
        machine_name: machine.machine_name.trim(),
        process_id: process_select_temp,
      };
      //console.log(temp_save_edit)
      Swal.fire( {
        title: "Saving",
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 1000,
        onOpen: () => {
          Swal.showLoading();
        },
      }).then((result) => {
        axios({
          method: "post",
          url: Configs.API_URL + "/factoryParameter/machine/update",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp_save_edit,
        })
          .then(function (response) {
            if (response.data) {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Factory_Parameter/status/machine";
              });
            }

            //console.log(response.data);
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", error.response.data.message, "error");
          });
      });
     } 
  }
 
}
  function cancleOption(params) {

      Swal.fire({
        title: 'Do you want to cancel and leave the changes?',
        icon:'warning',
        showCancelButton: true,
        confirmButtonText: `Yes`,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:`No`,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href =
          "/Warehouse_Management/Factory_Master_Data/Factory_Parameter/status/machine";
        }
      }
      );
  }

  const process_radio = [];
  for (var index = 0; index < process.length; index++) {
    var process_r = (
      <div className="col-3">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            name={process[index].process_name}
            id={process[index].process_name}
            value={process[index].id}
          />
          <label className="form-check-label" for={process[index].process_name}>
            {process[index].process_name}
          </label>
        </div>
      </div>
    );
    process_radio.push(process_r);
  }

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="facpara" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="card card-primary card-outline card-outline-tabs">
            <div className="card-header p-0 border-bottom-0">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-four-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="custom-tabs-four-machine-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-machine"
                    role="tab"
                    aria-controls="custom-tabs-four-machine"
                    aria-selected="false"
                  >
                    Machine
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-four-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="custom-tabs-four-machine"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-machine-tab"
                >
                  <h3 class="title ng-binding">{pageMode} Machine</h3>
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-md-4">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            value={machine.machine_name.trim()}
                            disabled={disable}
                            onChange={(e) => {
                              setmachine({
                                ...machine,
                                machine_name: e.target.value.trim(),
                              });
                            }}
                          />
                          <label htmlFor="">Machine name
                          {machine.machine_name.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                          </label>{" "}
                        </div>
                      </div>
                      <div className="col-md-12"></div>
                
                      <div className="col-md-12"></div>
                  
                      <div className="col-8"></div>
                      <div className="col-12">
                        <label>Select Process <span style={{ color: "red" }}> *</span></label>
                      </div>

               

                  {process_select.map((el,index) => {
                    
                    return (
                      <div className="col-3">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={index}
                            name={el.process_name}
                            value={el.id}
                            defaultChecked={el.isSelect}
                            onChange={process_select_item.bind(this)}
                            disabled={mode.mode === "read" ? true : false}
                          />
                          <label
                            className="form-check-label"
                            for={index}
                          >
                            {el.process_name /* ? "true":"false" */}
                          </label>
                        </div>
                      </div>
                    );
                 
                  
                })}

                    </div>
                  </div>

                  {mode.mode === "read" ? (
                    <div className="row mb-2">
                      <div className="col-sm-1">
                        <button
                          type="button"
                          onClick={cancleOption}
                          class="btn btn-block btn-danger "
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="row mb-2">
                      <div className="col-sm-1">
                        <button
                          type="button"
                           onClick={saveOption}
                          class="btn btn-block btn-success "
                        >
                          Save
                        </button>
                      </div>
                      <div className="col-sm-1">
                        <button
                          type="button"
                          onClick={cancleOption}
                          class="btn btn-block btn-danger "
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
     {/*  <Footter /> */}
    </div>
  );
}
export default Factory_parameter_add;
